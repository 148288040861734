<div id="profile-section-patents" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Patents</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add certificate"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!patentList || patentList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your patents or click the [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()"
              aria-label="verify none" role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="patentList">
        <div class="profile__section__list--item" *ngFor="let item of patentList; index as i;">
          <div *ngIf="item.title != 'NONE' || patentList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div role="list item property value">
              <span>Patent:</span>
              <span class="fw500 pl05r">{{ item.title }}</span>
            </div>
            <div *ngIf="item.patentNumber" role="list item property value">
              <span>Patent Number:</span>
              <span class="fw500 pl05r">{{ item.patentNumber }}</span>
            </div>
            <div *ngIf="item.obtainedDateTimeFlag" role="list item property value">
              <div *ngIf="item.obtainedDateTimeFlag.year">
                <span>Date Obtained:</span>
                <span class="fw500 pl05r"></span>
                {{ item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '' }}
                {{ item.obtainedDateTimeFlag.month ? months[+item.obtainedDateTimeFlag.month - 1] : '' }}
                {{ item.obtainedDateTimeFlag.year ? item.obtainedDateTimeFlag.year : '' }}
              </div>
            </div>
          </div>
          <div *ngIf="item.title != 'NONE' || patentList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.title !== 'NONE'" type="button" class="button button--edit" type="submit"
                (click)="gotoEditClick(item, true)" aria-label="edit certifications button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete certifications button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Patents</div>
        <div *ngIf="patentModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- PATENT -->
        <div class="profile__form--patent">
          <label class="form__label">Patent <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_title" name="currentItem_toNode_title"
            [ngModel]="patentModel.title" placeholder="Ex. Pressure intensifier unit for vehicle braking system"
            (ngModelChange)="patentModel.title = this.escape.it($event)" #toNode_title="ngModel"
            [disabled]="deleteMode" aria-label="patent - required" />
        </div>
        <!-- PATENT NUMBER -->
        <div class="profile__form--patent-number">
          <label class="form__label">Patent Number</label>
          <input class="form__control" id="currentItem_toNode_patentNumber" name="currentItem_toNode_patentNumber"
            [ngModel]="patentModel.patentNumber" placeholder="Ex. 94826582" aria-label="patent number"  
            (ngModelChange)="patentModel.patentNumber = this.escape.it($event)" [disabled]="deleteMode" />
        </div>
        <!-- YEAR -->
        <div class="profile__form--year">
          <label class="form__label">Award Year <span class="form__required">*</span></label>
          <select class="form__control" #awardYear (change)="onDateChange('obtainedDateTime', 'year', awardYear.value)" required
            [(ngModel)]="patentModel.obtainedDateTime.year" name="certificate_year" [disabled]="deleteMode"
            aria-label="year - requirement"  
            [ngClass]="{'ng-invalid': isAwardYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="patentModel.obtainedDateTime.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- MONTH -->
        <div class="profile__form--month" *ngIf="patentModel.obtainedDateTime.year">
          <label class="form__label">Award Month</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'month')"
            [(ngModel)]="patentModel.obtainedDateTime.month" name="certificate_month" [disabled]="deleteMode"
            aria-label="month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- DAY -->
        <div class="profile__form--day" *ngIf="patentModel.obtainedDateTime.year && patentModel.obtainedDateTime.month">
          <label class="form__label">Award Day</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'day')"
            [(ngModel)]="patentModel.obtainedDateTime.day" name="certificate_day" [disabled]="deleteMode"
            aria-label="day"  >
            <option value="">Select Day</option>
            <option *ngFor="let item of dayValue" [selected]="
              patentModel.obtainedDateTime.day == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- SUMMARY -->
        <div class="profile__form--summary">
          <label class="form__label">Summary</label>
          <textarea class="form__control text-area" id="currentItem_toNode_summary" name="currentItem_toNode_summary"
            placeholder="Ex. This unit intensifies the pressure of vehicular breaking systems to decrease the delay in a more cost effective way"
            (ngModelChange)="patentModel.summary = this.escape.it($event)" [ngModel]="patentModel.summary"
            [disabled]="deleteMode" aria-label="summary"  >
          </textarea>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && patentModel.dataSource === 'User') ||
                (thisForm.form.invalid && patentModel.dataSource !== 'User')" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-patent" *ngIf="toNode_title.invalid && (toNode_title.dirty || toNode_title.touched)">
          <strong *ngIf="toNode_title.errors.required">Patent is required</strong>
        </div>
        <div class="row tac profile__form--error-award-year" *ngIf="isAwardYearRequired">
          <strong>Award Year is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>