import { DomSanitizer } from '@angular/platform-browser'
import { Pipe, PipeTransform } from "@angular/core"
import { orderBy } from "lodash"

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(commenter: any[], key: string, value: string): any {
    return commenter.filter((i) => i[key] === value)
  }
}

@Pipe({ name: "sortBy" })
export class SortByPipe implements PipeTransform {
  transform(value: any[], order: any = "", column: string = ""): any[] {
    if (!value || order === "" || !order) {
      return value
    } // no array
    if (value.length <= 1) {
      return value
    } // array with only one item
    if (!column || column === "") {
      if (order === "asc") {
        return value.sort()
      } else {
        return value.sort().reverse()
      }
    } // sort 1d array
    return orderBy(value, [column], [order])
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
