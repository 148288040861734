import { Component, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../../dialogs/login/login.component';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {

  @Output() toggleSideBar: EventEmitter<any> = new EventEmitter();

  pages: any;
  searchTerm: string;
  homeTerms = [ 'home', 'dashboard', 'newsfeed', 'geo' ];
  facultyTerms = [ 'faculty' ];
  alumniTerms = [ 'alumni' ];
  academicTerms = [ 'academic family tree' ];
  mentoringRelationTerms = [ 'mentoring relation'];
  publicationTerms = [ 'publication' ];
  pidayTerms = [ 'piday', 'profound impact day' ];
  profoundInsightTerms = ['piinsight', 'profound insight'];
  profileTerms = [
    'profile',
    'basic',
    'experience',
    'education',
    'certifications',
    'volunteer',
    'skills',
    'languages',
    'awards',
    'projects',
    'research',
    'publications',
    'patents',
    'academic ancestry',
    'mentoring relation',
    'social media'
  ];
  importTerms = [ 'import profile', 'upload profile' ];
  settingsTerms = [ 'settings' ];
  privacyTerms = [ 'privacy policy' ];
  contactUsTerms = [ 'contact us' ];
  aboutTerms = [ 'about us' ];
  joinUsTerms = ['join us'];
  requirementsTerms = [ 'system requirements' ];
  assessmentToolTerms = ['assessment tool'];
  mobileSearchBarActive = false;
  bucket: string;
  @ViewChild('mainSearch') searchInputText: ElementRef;
  constructor(
    config: NgbDropdownConfig,
    private modalService: NgbModal,
    public amplifyService: AmplifyService,
    private router: Router,
    public data: DataService,
    public util: UtilityService,
    private renderer: Renderer2
  ) {
    this.bucket = environment.publicBucket;
    config.placement = 'bottom-right';
  }
  ngAfterViewInit(): void {
    if (this.searchInputText !== undefined ) {
      this.renderer.removeAttribute(this.searchInputText.nativeElement, 'aria-multiline');      
  }
  }

  // toggles sidebar
  toggleFoldingSidebar() {
    this.toggleSideBar.emit();
    this.data.graphsOpen = true;
    this.data.publicGroupsOpen = true;
    this.data.privateGroupsOpen = true;
  }

  // toggles searchbar on mobile
  toggleMobileSearchbar() {
    if (this.mobileSearchBarActive) {
      this.search();
    }
    if (!this.mobileSearchBarActive) {
      this.mobileSearchBarActive = true;
    }
  }

  // toggles the global accessibility mode
  toggleAccessibilityMode() {
    if (this.data.accessibilityMode) {
      this.data.theme = 'acc';
      this.util.setCookie('accessibilityMode', 'enabled');
    } else {
      this.data.theme = 'light';
      this.util.setCookie('accessibilityMode', 'disabled');
    }
  }

  // filters the pages for searchbar
  pageSuggestions = (text$: Observable<string>) =>
  text$
    .debounceTime(200)
    .distinctUntilChanged()
    .map((term) =>
      (term === ''
        ? this.data.pages
        : this.data.pages.filter(
          (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      ).slice(0, 10)
    )

  // triggers the proper routing logic based on the searchbar
  search() {
    console.log('SEARCHING');
    let val;
    setTimeout(() => {
      val = this.searchTerm.toLocaleLowerCase();
    }, 250);
    setTimeout(() => {
      this.homeTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/']);
        }
      });
      this.facultyTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/faculty']);
        }
      });
      this.alumniTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/alumni']);
        }
      });
      this.academicTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/academic-family-tree']);
        }
      });
      this.mentoringRelationTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/mentoring-relation']);
        }
      });
      this.publicationTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/publication']);
        }
      });
      this.pidayTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/profound-impact-day']);
        }
      });
      this.profoundInsightTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['//profound-insights']);
        }
      });
      this.profileTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated && val !== 'publication' && val !== 'mentoring relation') {
          this.router.navigate(['/profile']);
        }
      });
      this.importTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated && val !== 'profile') {
          this.router.navigate(['/import']);
        }
      });
      this.settingsTerms.forEach(element => {
        if (element.includes(val) && this.data.authenticated) {
          this.router.navigate(['/settings']);
        }
      });
      this.privacyTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/privacy-policy']);
        }
      });
      this.contactUsTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/contact-us']);
        }
      });
      this.aboutTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/about']);
        }
      });
      this.joinUsTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/join-us']);
        }
      });
      this.requirementsTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/requirements']);
        }
      });
      this.assessmentToolTerms.forEach(element => {
        if (element.includes(val)) {
          this.router.navigate(['/assessment']);
        }
      });
      this.mobileSearchBarActive = false;
      this.searchTerm = '';
    }, 500);
  }

  // opens login dialog for user to sign in
  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then((data) => {
      this.data.logger(data, 'OPEN LOGIN');
    });
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }

  route(route: string) {
    this.router.navigate([route]);
  }

  goToJoin() {
    this.router.navigate(['/join-us']);
  }

  goHome() {
    this.router.navigate(['/']);
  }

}
