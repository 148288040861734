import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor() {}

    private _configuration: {} = {
        light: {
            navbarBackgroundColor: '#000000',
            navbarTextColor: 'rgb(218, 218, 218)',
            footerBackgroundColor: 'white',
            footerTextColor: 'rgb(145, 145, 145)',
            primaryIconColor: '#ffffff',
            secondaryIconColor: '#404040',
            primaryBackgroundColor: '#fafafa',
            blueBackgroundColor: '#2196f3',
            primaryTextColor: '#404040',
            secondaryTextColor: '#ffffff',
            cardTextColor: '#818181',
            cardSecondaryTextColor: '#585858',
            miscPageTextColor: '#000000',
            systemRequirementsTextColor: '#555',
            contributionTextColor: '#E0E0E0',
            profoundInsightsTextColor: 'rgb(23, 131, 150)',
            warning: 'rgb(255, 100, 100)',
            success: '#238d1f',
            unverified: '#2196f3',
            borderColor: '#cfcfcf',
            checkbuttonActive: 'rgb(38, 209, 89)',
            pinkBadge: 'rgba(230, 69, 202, 0.795)',
            yellowBadge: 'rgba(213, 162, 0, 0.796)',
            btnMagenta: 'rgb(223, 36, 152)',
            btnCyan: 'rgb(0, 156, 199)',
            btnGrey: 'rgb(126, 126, 126)',
            btnPink: 'rgba(255, 99, 169, 0.795)',
            btnGreen: '#2e8b57',
            btnGreenPiInauguralDayEvent: 'rgb(45,205,22)',
            btnOrange: 'rgb(185, 121, 0)',
            btnBlue: 'rgb(0, 117, 153)',
            btnBlueLogin: '#0056B3',
            btnRed: '#dd2929',
            btnDarkGrey: '#646464',
            bluePiDayAgendaBackground: '#1B5D6F',
            lightPurpleGradientBackground: 'linear-gradient(315deg, #f8ceec 0%, #a88beb 74%)',
            lightPinkSecondaryGradientBackground:'linear-gradient(to right, #752f72, #d69ed2)',
            lightPinkGradientBackground: 'linear-gradient(270deg, rgba(255, 99, 169, 0.308) 0%, rgba(223, 36, 151, 0.541) 100%)',
            darkPinkGradientBackground: 'linear-gradient(270deg,rgba(255, 99, 169, 0.795) 0%, rgba(223, 36, 151, 0.795) 100%)',
            lightGreyGradientBackground: 'linear-gradient(90deg, rgba(71, 71, 71, 0.445) 0%, rgba(136, 136, 136, 0.438) 67%, rgb(223, 223, 223) 100%)',
            yellowGradientBackground: 'linear-gradient( 90deg, rgba(213, 162, 0, 0.795) 0%,rgba(255, 213, 79, 0.795) 100% )',
            cyanGradientBackground: 'linear-gradient(90deg, rgba(0, 127, 162, 1) 0%, rgba(0, 184, 235, 1) 35%, rgba(0, 212, 255, 1) 100%)',
            gainsboroGradientBackground: 'linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(237, 237, 237, 1) 100% )',
            blueTwitterGradientBackground: 'linear-gradient(90deg, rgba(29, 161, 242, 1) 0%,rgb(95, 194, 255) 100%)',
            blueJobBoardGradientBackground: 'linear-gradient(90deg, rgba(0,127,162,1) 0%, rgba(0,184,235,1) 35%, rgba(0,212,255,1) 100%)',
            greyGeoMapGradientBackground: 'radial-gradient(circle,rgba(215, 215, 215, 1) 0%, rgba(98, 98, 98, 1) 100%)',
            orangeGradientBackground: 'linear-gradient(90deg,rgba(255, 150, 29, 0.795) 0%, rgba(231, 161, 21, 0.795) 67%, rgba(245, 203, 51, 0.795) 100%)',
            blueGradientBackground: 'linear-gradient(90deg, rgba(0, 90, 122, 0.79) 0%, rgba(0, 214, 218, 0.79) 100%)',
            greenGradientBackground: 'linear-gradient(180deg, rgba(16, 103, 24, 0.79) 0%, rgba(147, 245, 19, 0.79) 100% )',
            greenPiInauguralDayGradientBackground: 'linear-gradient(90deg, rgb(155,232,85) 0%, rgb(111,183,81) 67%, rgb(63,138,77) 100%)',
            greenContributionGradientBackground: 'linear-gradient(90deg, rgba(46,138,89,1) 0%, rgba(111,183,81,1) 100%)',
            greenProfoundInsightsPresents: 'linear-gradient(90deg, rgba(39,128,150,0.4894736842105263) 0%, rgba(36,158,150,0.5184210526315789) 49%, rgba(100,213,118,0.5342105263157895) 100%)',
            redInstagramFeedGradientbackground: 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
            profoundGradientBackground: 'linear-gradient(90deg, rgba(4,168,157,1) 0%, rgba(22,144,138,1) 39%, rgba(38,157,123,1) 89%)',
            mediumLightGrey: '#a3a3a3',
            formTextColor: 'rgb(133, 133, 133)',
            formPlaceholderColor: 'rgb(190, 190, 190)',
            formCtrlBorderColor: 'rgb(201, 201, 201)',
            darkPinkLegend: '#df2498',
            bannerTextColor: '#ffffff'
        },
        dark: {
            navbarBackgroundColor: 'red',
            navbarTextColor: 'blue',
            footerBackgroundColor: 'white',
            footerTextColor: 'rgb(145, 145, 145)',
            primaryIconColor: 'green',
            secondaryIconColor: 'brown',
            primaryBackgroundColor: '#fafafa',
            blueBackgroundColor: '#2196f3',
            primaryTextColor: '#404040',
            secondaryTextColor: '#000000',
            cardTextColor: '#fafafa',
            cardSecondaryTextColor: '#585858',
            miscPageTextColor: '#000000',
            systemRequirementsTextColor: '#555',
            contributionTextColor: '#E0E0E0',
            profoundInsightsTextColor: 'rgb(23, 131, 150)',
            warning: 'rgb(255, 100, 100)',
            success: '#238d1f',
            unverified: '#2196f3',
            borderColor: '#cfcfcf',
            checkbuttonActive: 'rgb(38, 209, 89)',
            pinkBadge: 'rgba(230, 69, 202, 0.795)',
            yellowBadge: 'rgba(213, 162, 0, 0.796)',
            btnMagenta: 'rgb(223, 36, 152)',
            btnCyan: 'rgb(0, 156, 199)',
            btnGrey: 'rgb(126, 126, 126)',
            btnPink: 'rgba(255, 99, 169, 0.795)',
            btnGreen: 'seagreen',
            btnGreenPiInauguralDayEvent: 'rgb(45,205,22)',
            btnOrange: 'rgb(185, 121, 0)',
            btnBlue: 'rgb(0, 117, 153)',
            btnBlueLogin: '#0056B3',
            btnRed: '#dd2929',
            btnDarkGrey: '#646464',
            bluePiDayAgendaBackground: '#1B5D6F',
            lightPurpleGradientBackground: 'linear-gradient(315deg, #f8ceec 0%, #a88beb 74%)',
            lightPinkSecondaryGradientBackground:'linear-gradient(to right, #752f72, #d69ed2)',
            lightPinkGradientBackground: 'linear-gradient(270deg, rgba(255, 99, 169, 0.308) 0%, rgba(223, 36, 151, 0.541) 100%)',
            darkPinkGradientBackground: 'linear-gradient(270deg,rgba(255, 99, 169, 0.795) 0%, rgba(223, 36, 151, 0.795) 100%)',
            lightGreyGradientBackground: 'linear-gradient(90deg, rgba(71, 71, 71, 0.445) 0%, rgba(136, 136, 136, 0.438) 67%, rgb(223, 223, 223) 100%)',
            yellowGradientBackground: 'linear-gradient( 90deg, rgba(213, 162, 0, 0.795) 0%,rgba(255, 213, 79, 0.795) 100% )',
            cyanGradientBackground: 'linear-gradient(90deg, rgba(0, 127, 162, 1) 0%, rgba(0, 184, 235, 1) 35%, rgba(0, 212, 255, 1) 100%)',
            gainsboroGradientBackground: 'linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(237, 237, 237, 1) 100% )',
            blueTwitterGradientBackground: 'linear-gradient(90deg,rgba(29, 161, 242, 1) 0%,rgb(95, 194, 255) 100%)',
            blueJobBoardGradientBackground: 'linear-gradient(90deg, rgba(0,127,162,1) 0%, rgba(0,184,235,1) 35%, rgba(0,212,255,1) 100%)',
            greyGeoMapGradientBackground: 'radial-gradient(circle,rgba(215, 215, 215, 1) 0%, rgba(98, 98, 98, 1) 100%)',
            orangeGradientBackground: 'linear-gradient(90deg,rgba(255, 150, 29, 0.795) 0%, rgba(231, 161, 21, 0.795) 67%, rgba(245, 203, 51, 0.795) 100%)',
            blueGradientBackground: 'linear-gradient(90deg, rgba(0, 90, 122, 0.79) 0%, rgba(0, 214, 218, 0.79) 100%)',
            greenGradientBackground: 'linear-gradient(180deg, rgba(16, 103, 24, 0.79) 0%, rgba(147, 245, 19, 0.79) 100% )',
            greenPiInauguralDayGradientBackground: 'linear-gradient(90deg, rgb(155,232,85) 0%, rgb(111,183,81) 67%, rgb(63,138,77) 100%)',
            greenContributionGradientBackground: 'linear-gradient(90deg, rgba(46,138,89,1) 0%, rgba(111,183,81,1) 100%)',
            greenProfoundInsightsPresents: 'linear-gradient(90deg, rgba(39,128,150,0.4894736842105263) 0%, rgba(36,158,150,0.5184210526315789) 49%, rgba(100,213,118,0.5342105263157895) 100%)',
            redInstagramFeedGradientbackground: 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
            profoundGradientbackground: 'linear-gradient(90deg, rgba(4,168,157,1) 0%, rgba(22,144,138,1) 39%, rgba(38,157,123,1) 89%)',
            mediumLightGrey: '#a3a3a3',
            formCtrlBorderColor: 'rgb(201, 201, 201)',
            formTextColor: 'rgb(133, 133, 133)',
            formPlaceholderColor: 'rgb(190, 190, 190)',
            darkPinkLegend: '#df2498',
            bannerTextColor: '#ffffff'
        },
        acc: {
            navbarBackgroundColor: '#000000',
            navbarTextColor: '#ffffff',
            footerBackgroundColor: 'white',
            footerTextColor: 'black',
            primaryIconColor: '#ffffff',
            secondaryIconColor: '#404040',
            primaryBackgroundColor: '#fafafa',
            blueBackgroundColor: '#fafafa',
            primaryTextColor: '#404040',
            secondaryTextColor: '#000000',
            cardTextColor: 'black',
            cardSecondaryTextColor: 'black',
            miscPageTextColor: '#000000',
            systemRequirementsTextColor: '#000000',
            contributionTextColor: '#000000',
            profoundInsightsTextColor: '#000000',
            warning: 'rgb(148, 37, 37)',
            success: '#145211',
            unverified: 'rgb(0, 45, 89)',
            borderColor: '#cfcfcf',
            checkbuttonActive: 'black',
            pinkBadge: 'black',
            yellowBadge: 'black',
            btnMagenta: 'black',
            btnCyan: 'black',
            btnGrey: 'black',
            btnPink: 'black',
            btnGreen: 'black',
            btnGreenPiInauguralDayEvent: 'black',
            btnOrange: 'black',
            btnBlue: 'black',
            btnBlueLogin: 'black',
            btnRed: 'black',
            btnDarkGrey: 'black',
            lightPurpleGradientBackground: 'rgb(240, 240, 240)',
            lightPinkSecondaryGradientBackground: 'linear-gradient(to right, rgb(240, 240, 240), rgb(240, 240, 240))',
            bluePiDayAgendaBackground: 'rgb(240, 240, 240)',
            lightPinkGradientBackground: 'rgb(240, 240, 240)',
            darkPinkGradientBackground: 'rgb(240, 240, 240)',
            lightGreyGradientBackground: 'rgb(240, 240, 240)',
            yellowGradientBackground: 'rgb(240, 240, 240)',
            cyanGradientBackground: 'rgb(240, 240, 240)',
            gainsboroGradientBackground: 'rgb(240, 240, 240)',
            blueTwitterGradientBackground: 'rgb(240, 240, 240)',
            blueJobBoardGradientBackground: 'rgb(240, 240, 240)',
            greyGeoMapGradientBackground: 'rgb(240, 240, 240)',
            orangeGradientBackground: 'rgb(240, 240, 240)',
            blueGradientBackground: 'rgb(240, 240, 240)',
            greenGradientBackground: 'rgb(240, 240, 240)',
            greenPiInauguralDayGradientBackground: 'rgb(240, 240, 240)',
            greenContributionGradientBackground: 'rgb(240, 240, 240)',
            greenProfoundInsightsPresents: 'rgb(240, 240, 240)',
            redInstagramFeedGradientackground: 'rgb(240, 240, 240)',
            profoundGradientBackground: 'black',
            mediumLightGrey: 'black',
            formCtrlBorderColor: 'black',
            formTextColor: 'black',
            formPlaceholderColor: 'black',
            darkPinkLegend: '#490a31',
            bannerTextColor: '#000000'
        }
    };

    loadConfiguration(value: string) {
        return this._configuration[value] || this._configuration['light'];
    }

}