import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-info',
  templateUrl: './pdf-info.component.html',
  styleUrls: ['./pdf-info.component.scss'],
  providers: [NgbCarouselConfig]   // add NgbCarouselConfig to the component providers
})
export class PdfInfoComponent implements OnInit {


  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 7000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  dataSource = [
    { title: 'Step 1', src: '../../../../../assets/images/dialog/pdf/PDFStep1.png', description: 'Click on the Personal CV tab on the left' },
    { title: 'Step 2', src: '../../../../../assets/images/dialog/pdf/PDFStep2.png', description: 'Next Click on the Select File Button in the middle of the screen' },
    { title: 'Step 3', src: '../../../../../assets/images/dialog/pdf/PDFStep3.png', description: 'After that a window will popup letting you choose the file you want to upload' },
    { title: 'Step 4', src: '../../../../../assets/images/dialog/pdf/PDFStep4.png', description: 'Double click on the file and it will add the file' },
    { title: 'Step 5', src: '../../../../../assets/images/dialog/pdf/PDFStep5.png', description: 'Finally Click the upload button and wait for the file to be extracted' },
    
  ];

  ngOnInit(): void {
  }

}
