<mat-tab-group class="main-content" mat-align-tabs="center">
  <mat-tab label="Self Assessment Tool">
    <div id="assessment">
      <!-- 
        ██╗   ██╗███╗   ██╗ █████╗ ██╗   ██╗████████╗██╗  ██╗███████╗███╗   ██╗████████╗██╗ ██████╗ █████╗ ████████╗███████╗██████╗ 
        ██║   ██║████╗  ██║██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔════╝████╗  ██║╚══██╔══╝██║██╔════╝██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
        ██║   ██║██╔██╗ ██║███████║██║   ██║   ██║   ███████║█████╗  ██╔██╗ ██║   ██║   ██║██║     ███████║   ██║   █████╗  ██║  ██║
        ██║   ██║██║╚██╗██║██╔══██║██║   ██║   ██║   ██╔══██║██╔══╝  ██║╚██╗██║   ██║   ██║██║     ██╔══██║   ██║   ██╔══╝  ██║  ██║
        ╚██████╔╝██║ ╚████║██║  ██║╚██████╔╝   ██║   ██║  ██║███████╗██║ ╚████║   ██║   ██║╚██████╗██║  ██║   ██║   ███████╗██████╔╝
         ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝ 
       -->
      <div class="assessment__container__auth" *ngIf="!ds.authenticated">
        <mat-card class="assessment__auth__card mat-elevation-z8">
          <h1 class="prompt__heading tac">Please login or join to access the assessment</h1>
          <div class="assessment__auth">
            <button class="button button--dark-grey" (click)="openLogin()" role="button" aria-label="log in button">LOG IN</button>
            <button class="button button--dark-grey" (click)="goToJoin()" role="button" aria-label="join us button">JOIN US</button>
          </div>
        </mat-card>
      </div>
      <!-- 
        ██████╗ ███████╗███████╗ ██████╗ ██████╗ ███████╗    ██╗   ██╗ ██████╗ ██╗   ██╗    ███████╗████████╗ █████╗ ██████╗ ████████╗
        ██╔══██╗██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔════╝    ╚██╗ ██╔╝██╔═══██╗██║   ██║    ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝
        ██████╔╝█████╗  █████╗  ██║   ██║██████╔╝█████╗       ╚████╔╝ ██║   ██║██║   ██║    ███████╗   ██║   ███████║██████╔╝   ██║   
        ██╔══██╗██╔══╝  ██╔══╝  ██║   ██║██╔══██╗██╔══╝        ╚██╔╝  ██║   ██║██║   ██║    ╚════██║   ██║   ██╔══██║██╔══██╗   ██║   
        ██████╔╝███████╗██║     ╚██████╔╝██║  ██║███████╗       ██║   ╚██████╔╝╚██████╔╝    ███████║   ██║   ██║  ██║██║  ██║   ██║   
        ╚═════╝ ╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝╚══════╝       ╚═╝    ╚═════╝  ╚═════╝     ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
       -->
      <div *ngIf="ds.authenticated && !acceptedTerms && !isSubmitted" class="assessment__explainer"
        style="width: 80%; margin-left: 10%; text-align: justify; font-size: 1.4rem;">
        Welcome to Profound Impact's Self-Assessment Tool. The information gathered will enable us to help your
        organization evaluate and implement strategic drivers for constituent engagement. Your input will be reviewed by Barney Ellis-Perry, Profound Impact’s
        nationally renowned strategist, who will provide you a personal report via video conference, as well as a written report with his recommendations.<br><br>
        We take your privacy very seriously. Your information will only be used for this purpose and will not be shared
        outside of our organization.<br><br>
        If you have questions or concerns about this assessment tool, please email <a class="link"
          href="mailto:assessment@profoundimpact.com" role="link" aria-label="email address">assessment@profoundimpact.com</a>
      </div>
      <button *ngIf="ds.authenticated && !acceptedTerms  && !isSubmitted" class="button button--dark-grey"
        (click)="agreementClick()" style="width: 20%; margin-left: 40%; margin-top: 50px" role="button" aria-label="start button">Start</button>
      <!-- 
        ███████╗██╗  ██╗ ██████╗██╗      █████╗ ██╗███╗   ███╗███████╗██████╗ 
        ██╔════╝╚██╗██╔╝██╔════╝██║     ██╔══██╗██║████╗ ████║██╔════╝██╔══██╗
        █████╗   ╚███╔╝ ██║     ██║     ███████║██║██╔████╔██║█████╗  ██████╔╝
        ██╔══╝   ██╔██╗ ██║     ██║     ██╔══██║██║██║╚██╔╝██║██╔══╝  ██╔══██╗
        ███████╗██╔╝ ██╗╚██████╗███████╗██║  ██║██║██║ ╚═╝ ██║███████╗██║  ██║
        ╚══════╝╚═╝  ╚═╝ ╚═════╝╚══════╝╚═╝  ╚═╝╚═╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═╝
       -->
      <p class="assessment__explainer" *ngIf="ds.authenticated && acceptedTerms"
        style="background-color: white; width: 98%; margin-left: 1%">
        Your answers to the questions in this self-assessment tool will guide us in making recommendations to help you achieve your constituent engagement goals. 
        The survey is divided into five sections and you can enter data for any of the sections, save and come back to review and edit any time prior to submitting.
        All information submitted is confidential and will be used only for the purpose of preparing recommendations for improving constituent engagement.
      </p>
      <form [formGroup]="assessment" *ngIf="ds.authenticated && acceptedTerms">
        <div class="assessment__container">
          <mat-card class="assessment__card mat-elevation-z8">
            <mat-horizontal-stepper class="w100" #stepper>
              <!-- 
                ███████╗████████╗██████╗  █████╗ ████████╗███████╗ ██████╗ ██╗ ██████╗     █████╗ ██╗     ██╗ ██████╗ ███╗   ██╗███╗   ███╗███████╗███╗   ██╗████████╗
                ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔════╝ ██║██╔════╝    ██╔══██╗██║     ██║██╔════╝ ████╗  ██║████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
                ███████╗   ██║   ██████╔╝███████║   ██║   █████╗  ██║  ███╗██║██║         ███████║██║     ██║██║  ███╗██╔██╗ ██║██╔████╔██║█████╗  ██╔██╗ ██║   ██║   
                ╚════██║   ██║   ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║   ██║██║██║         ██╔══██║██║     ██║██║   ██║██║╚██╗██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   
                ███████║   ██║   ██║  ██║██║  ██║   ██║   ███████╗╚██████╔╝██║╚██████╗    ██║  ██║███████╗██║╚██████╔╝██║ ╚████║██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   
                ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝ ╚═════╝    ╚═╝  ╚═╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
               -->
              <mat-step [stepControl]="alignment" [completed]="completed" state="alignment"
                formGroupName="strategicAlignment">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>{{ ds.LT_LG ? 'Strategy' : 'Strategic Alignment' }}</ng-template>
                  <div *ngFor="let questions of alignmentQuestion.controls; let i = index">
                    <ng-container [formGroup]="questions">
                      <mat-form-field appearance="outline" floatLabel="auto" class="w100">
                        <mat-label>{{questions.value.question}}</mat-label>
                        <textarea matInput rows="3" style="font-size: 1.4rem;" formControlName="answer" aria-label="answers"></textarea>
                      </mat-form-field>
                    </ng-container>
                  </div>
                </div>
                <div class="assessment__footer">
                  <div>
                    <button class="button button--dark-grey" (click)="saveAssessment()" [disabled]="isSubmitted" role="button" aria-label="save button">Save</button>
                  </div>
                  <div>
                    <button class="button button--dark-grey" matStepperNext (click)="saveAssessment()"
                      [disabled]="isSubmitted" role="button" aria-label="save and comtinue button">Save & Continue</button>
                  </div>
                </div>
              </mat-step>
              <!-- 
                ██████╗ ███████╗███╗   ███╗ ██████╗ ███╗   ██╗███████╗████████╗██████╗  █████╗ ████████╗ █████╗ ██████╗ ██╗     ███████╗    ███╗   ██╗███████╗███████╗██████╗ ███████╗
                ██╔══██╗██╔════╝████╗ ████║██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝    ████╗  ██║██╔════╝██╔════╝██╔══██╗██╔════╝
                ██║  ██║█████╗  ██╔████╔██║██║   ██║██╔██╗ ██║███████╗   ██║   ██████╔╝███████║   ██║   ███████║██████╔╝██║     █████╗      ██╔██╗ ██║█████╗  █████╗  ██║  ██║███████╗
                ██║  ██║██╔══╝  ██║╚██╔╝██║██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██╗██╔══██║   ██║   ██╔══██║██╔══██╗██║     ██╔══╝      ██║╚██╗██║██╔══╝  ██╔══╝  ██║  ██║╚════██║
                ██████╔╝███████╗██║ ╚═╝ ██║╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║██║  ██║   ██║   ██║  ██║██████╔╝███████╗███████╗    ██║ ╚████║███████╗███████╗██████╔╝███████║
                ╚═════╝ ╚══════╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═══╝╚══════╝╚══════╝╚═════╝ ╚══════╝                                                                                                                                                     
               -->
              <mat-step [stepControl]="need" state="need" [completed]="completed" formGroupName="demonstrableNeed">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>{{ ds.LT_LG ? 'Needs' : 'Demonstratable Need' }}</ng-template>
                  <div
                    *ngFor="let questions of this.assessment.get('demonstrableNeed').get('questions').controls; let i = index">
                    <ng-container [formGroup]="questions">
                      <mat-form-field appearance="outline" floatLabel="auto" class="w100">
                        <mat-label>{{questions.value.question}}</mat-label>
                        <textarea matInput rows="3" *ngIf="i < 1" formControlName="answer" aria-label="answers"></textarea>
                        <textarea matInput rows="3" *ngIf="i === 1" formControlName="answer" aria-label="answers" placeholder="Examples might include:
                        Advocacy – your constituents are better informed about the impact of your organizations research or?
                        Student support – your constituents have clear pathways to become mentors, classroom speakers, provide internships etc..
                        Participation – your constituents are easily able to participate in webinars and lectures or research studies.
                        "></textarea>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <hr>
                  <p class="p20 assessment__explainer__mini">
                    The following chart is designed for you to input your programs and assess on a scale of 1-5 (1 low –
                    5
                    high) the success you and your team feel you are having. This is totally subjective and there are no
                    right answers. Ideally please work through this with a few colleagues to gather a range of
                    opinoions.
                    You might ask each of them for a score and then average it.
                  </p>
                  <!-- 
                    TABLE
                   -->
                  <table class="table table-responsive" style="border: 1px grey solid" formGroupName="programChart">
                    <thead>
                      <tr>
                        <th scope="col">Program</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="Alignment
                        with Institutional Priorities table header">Alignment
                          with<br>Institutional<br>Priorities</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="Alignment
                        with Alumni Association Priorities table header">Alignment
                          with<br>Alumni Association<br>Priorities</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="Number of alumni engaged table header">Number of<br>alumni
                          engaged</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="staff time table header">Staff<br>Time</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="budget table header">Budget</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="Partnership and opportunities table header">
                          Partnership<br>Opportunities</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="Technology Utilization table header">
                          Technology<br>Utilization</th>
                        <th scope="col" style="width: 130px; max-width: 130px; min-width: 130px;" aria-label="measurable table header">Measurable</th>
                        <th scope="col" style="width: 50px; max-width: 50px; min-width: 50px;" aria-label="Avg score table header">Avg.<br>Score</th>
                        <th scope="col">Effort/Impact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr formGroupName="advocacy">
                        <th scope="row">Advocacy</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('advocacy')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('advocacy')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('advocacy')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('advocacy')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('advocacy')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('advocacy')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('advocacy')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('advocacy')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled aria-label="avg score">
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="alumniHiringAlumni">
                        <th scope="row" aria-label="alumni hiring alumni">Alumni<br>Hiring<br> Alumni</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional"
                            (change)="calculateAverage('alumniHiringAlumni')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('alumniHiringAlumni')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged"
                            (change)="calculateAverage('alumniHiringAlumni')" appearance="outline"
                            class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('alumniHiringAlumni')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('alumniHiringAlumni')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('alumniHiringAlumni')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('alumniHiringAlumni')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('alumniHiringAlumni')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore" aria-label="avg score"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="alumniChapters">
                        <th scope="row" aria-label="alumni chapters">Alumni<br>Chapters</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional"
                            (change)="calculateAverage('alumniChapters')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('alumniChapters')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('alumniChapters')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('alumniChapters')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('alumniChapters')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('alumniChapters')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('alumniChapters')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('alumniChapters')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            aria-label="avg score"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="careerSupport">
                        <th scope="row" aria-label="career support">Career<br>Support</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional"
                            (change)="calculateAverage('careerSupport')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('careerSupport')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('careerSupport')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('careerSupport')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('careerSupport')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('careerSupport')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('careerSupport')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('careerSupport')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="givingDay">
                        <th scope="row" aria-label="giving day">Giving Day</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('givingDay')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('givingDay')" appearance="outline"
                            class="assessment__table__field" aria-label="alignmnet with alumni association">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('givingDay')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('givingDay')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('givingDay')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('givingDay')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('givingDay')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('givingDay')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore" aria-label="avg score"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="eNews">
                        <th scope="row">eNews</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('eNews')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('eNews')" appearance="outline" class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('eNews')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('eNews')" appearance="outline"
                            class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('eNews')" appearance="outline" class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline" (change)="calculateAverage('eNews')"
                            class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('eNews')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline" (change)="calculateAverage('eNews')"
                            class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore" aria-label="avg score"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="homecoming">
                        <th scope="row" aria-label="homecoming">Homecoming</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('homecoming')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('homecoming')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('homecoming')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('homecoming')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('homecoming')" appearance="outline"
                            class="assessment__table__field" aria-label="budget Partnership Opportunities Technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('homecoming')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('homecoming')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('homecoming')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="mentoring">
                        <th scope="row" aria-label="mentoring">Mentoring</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('mentoring')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('mentoring')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('mentoring')"
                            appearance="outline" class="assessment__table__field" aria-label="number of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('mentoring')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('mentoring')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('mentoring')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('mentoring')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('mentoring')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore" aria-label="avg score"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="scholarships">
                        <th scope="row" aria-label="scholarships">Scholarships</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional"
                            (change)="calculateAverage('scholarships')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('scholarships')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('scholarships')"
                            appearance="outline" class="assessment__table__field" aria-label="number of Alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('scholarships')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('scholarships')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('scholarships')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('scholarships')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('scholarships')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none;padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            aria-label="avg score" disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                      <tr formGroupName="webinars">
                        <th scope="row" aria-label="webinars">Webinars</th>
                        <td>
                          <select formControlName="alignmentWithInstitutional" (change)="calculateAverage('webinars')"
                            appearance="outline" class="assessment__table__field" aria-label="alignment with institutional">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="alignmentWithAlumniAssociationPriorities"
                            (change)="calculateAverage('webinars')" appearance="outline"
                            class="assessment__table__field" aria-label="alignment with alumni association priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="numberOfAlumniEngaged" (change)="calculateAverage('webinars')"
                            appearance="outline" class="assessment__table__field" aria-label="number Of alumni engaged">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="staffTime" (change)="calculateAverage('webinars')"
                            appearance="outline" class="assessment__table__field" aria-label="staff time">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="budgetPartnershipOpportunitiesTechnology"
                            (change)="calculateAverage('webinars')" appearance="outline"
                            class="assessment__table__field" aria-label="budget partnership opportunities technology">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="priorities" appearance="outline"
                            (change)="calculateAverage('webinars')" class="assessment__table__field" aria-label="priorities">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="utilization" appearance="outline"
                            (change)="calculateAverage('webinars')" class="assessment__table__field" aria-label="utilization">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td>
                          <select formControlName="measurable" appearance="outline"
                            (change)="calculateAverage('webinars')" class="assessment__table__field" aria-label="measurable">
                            <option value=null>Select</option>
                            <option *ngFor="let value of scale" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                        <td style="width: 50px; max-width: 50px; min-width: 50px;">
                          <input formControlName="avgScore"
                            style="text-align: center; border: none; padding: 10px 0px; width: 30px; max-width: 30px; min-width: 30px;"
                            aria-label="avg score" disabled>
                        </td>
                        <td>
                          <select formControlName="effortImpact" appearance="outline" class="assessment__table__field" aria-label="effort impact">
                            <option value=null>Select</option>
                            <option *ngFor="let value of impact" [value]="value.value">{{value.viewValue}}</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <p class="p20 assessment__explainer__mini">
                    Now please take the program areas on your left and drag them them in the table according to how you
                    ranked them in the far right column.
                  </p>
                  <!-- 
                    DRAG AND DROP
                   -->
                  <div class="assessment__quadrant__container" formGroupName="priorityMatrix">
                    <div class="assessment__quadrant__options">
                      <div class="example-container assessment__quadrant__0">
                        <div class="dd__header">PROGRAM AREA</div>
                        <div cdkDropList #q0List="cdkDropList" [cdkDropListData]="q0"
                          [cdkDropListConnectedTo]="[q1List, q2List, q3List, q4List]" class="example-list"
                          (cdkDropListDropped)="drop($event)" aria-label="list of program areas">
                          <div class="example-box" *ngFor="let item of q0" cdkDrag>{{item}}</div>
                        </div>
                      </div>
                    </div>
                    <div class=" assessment__quadrant">
                      <div class="assessment__quadrant__top">
                        <div class="example-container assessment__quadrant__1">
                          <div class="dd__header q1">HIGH IMPACT/HIGH EFFORT</div>
                          <div cdkDropList #q1List="cdkDropList" [cdkDropListData]="q1"
                            [cdkDropListConnectedTo]="[q0List, q2List, q3List, q4List]" class="example-list"
                            (cdkDropListDropped)="drop($event)" aria-label="high impact/high effort program area">
                            <div class="example-box" style="background-color: rgb(254, 255, 179);"
                              *ngFor="let item of q1" cdkDrag>{{item}}</div>
                          </div>
                        </div>
                        <div class="example-container assessment__quadrant__2">
                          <div class="dd__header q2">HIGH IMPACT/LOW EFFORT</div>
                          <div cdkDropList #q2List="cdkDropList" [cdkDropListData]="q2"
                            [cdkDropListConnectedTo]="[q0List, q1List, q3List, q4List]" class="example-list"
                            (cdkDropListDropped)="drop($event)" aria-label="high impact/low effort program area">
                            <div class="example-box" style="background-color: rgb(188, 255, 179);"
                              *ngFor="let item of q2" cdkDrag>{{item}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="assessment__quadrant__bottom">
                        <div class="example-container assessment__quadrant__3">
                          <div class="dd__header q3">LOW IMPACT/HIGH EFFORT</div>
                          <div cdkDropList #q3List="cdkDropList" [cdkDropListData]="q3"
                            [cdkDropListConnectedTo]="[q0List, q1List, q2List, q4List]" class="example-list"
                            (cdkDropListDropped)="drop($event)" aria-label="low impact/high effort program area">
                            <div class="example-box" style="background-color: rgb(255, 179, 179);"
                              *ngFor="let item of q3" cdkDrag>{{item}}</div>
                          </div>
                        </div>
                        <div class="example-container assessment__quadrant__4">
                          <div class="dd__header q4">LOW IMPACT/LOW EFFORT</div>
                          <div cdkDropList #q4List="cdkDropList" [cdkDropListData]="q4"
                            [cdkDropListConnectedTo]="[q0List, q1List, q2List, q3List]" class="example-list"
                            (cdkDropListDropped)="drop($event)" aria-label="low impact/low effort program area">
                            <div class="example-box" style="background-color: rgb(255, 228, 179);"
                              *ngFor="let item of q4" cdkDrag>{{item}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="assessment__back">
                  <button class="button button--dark-grey" matStepperPrevious [disabled]="isSubmitted" role="button" aria-label="back button">Back</button>
                  <div class="assessment__save">
                    <div>
                      <button class="button button--dark-grey" (click)="saveAssessment()" [disabled]="isSubmitted" role="button" aria-label="save button">Save</button>
                    </div>
                    <div>
                      <button class="button button--dark-grey" matStepperNext (click)="saveAssessment()"
                        [disabled]="isSubmitted" role="button" aria-label="save and continue button">Save & Continue</button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <!-- 
                ██████╗ ██╗   ██╗██████╗  ██████╗ ███████╗████████╗         ██╗██╗   ██╗███████╗████████╗██╗███████╗██╗ ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
                ██╔══██╗██║   ██║██╔══██╗██╔════╝ ██╔════╝╚══██╔══╝         ██║██║   ██║██╔════╝╚══██╔══╝██║██╔════╝██║██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
                ██████╔╝██║   ██║██║  ██║██║  ███╗█████╗     ██║            ██║██║   ██║███████╗   ██║   ██║█████╗  ██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
                ██╔══██╗██║   ██║██║  ██║██║   ██║██╔══╝     ██║       ██   ██║██║   ██║╚════██║   ██║   ██║██╔══╝  ██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
                ██████╔╝╚██████╔╝██████╔╝╚██████╔╝███████╗   ██║       ╚█████╔╝╚██████╔╝███████║   ██║   ██║██║     ██║╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
                ╚═════╝  ╚═════╝ ╚═════╝  ╚═════╝ ╚══════╝   ╚═╝        ╚════╝  ╚═════╝ ╚══════╝   ╚═╝   ╚═╝╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
               -->
              <mat-step [stepControl]="secondFormGroup" [completed]="completed" state="budget"
                formGroupName="budgetJustification">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>{{ ds.LT_LG ? 'Budget' : 'Budget Justification' }}</ng-template>
                  <div *ngFor="let questions of budgetQuestion.controls; let i = index">
                    <ng-container [formGroup]="questions">
                      <mat-form-field appearance="outline" floatLabel="auto" class="w100">
                        <mat-label>{{questions.value.question}}</mat-label>
                        <textarea matInput rows="3" formControlName="answer" aria-label="answers"></textarea>
                      </mat-form-field>
                    </ng-container>
                  </div>
                </div>
                <div class="assessment__back ">
                  <button class="button button--dark-grey" matStepperPrevious [disabled]="isSubmitted" role="button" aria-label="back button">Back</button>
                  <div class="assessment__save">
                    <div>
                      <button class="button button--dark-grey" (click)="saveAssessment()" [disabled]="isSubmitted" role="button" aria-label="save button">Save</button>
                    </div>
                    <div>
                      <button class="button button--dark-grey" matStepperNext (click)="saveAssessment()"
                        [disabled]="isSubmitted" role="button" aria-label="save and continue button">Save & Continue</button>
                    </div>
                  </div>

                </div>
              </mat-step>
              <!-- 
                ██╗███╗   ██╗████████╗███████╗ ██████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗     ██████╗ ██████╗ ██████╗  ██████╗ ██████╗ ████████╗██╗   ██╗███╗   ██╗██╗████████╗██╗███████╗███████╗
                ██║████╗  ██║╚══██╔══╝██╔════╝██╔════╝ ██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║    ██╔═══██╗██╔══██╗██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝██║   ██║████╗  ██║██║╚══██╔══╝██║██╔════╝██╔════╝
                ██║██╔██╗ ██║   ██║   █████╗  ██║  ███╗██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║    ██║   ██║██████╔╝██████╔╝██║   ██║██████╔╝   ██║   ██║   ██║██╔██╗ ██║██║   ██║   ██║█████╗  ███████╗
                ██║██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║    ██║   ██║██╔═══╝ ██╔═══╝ ██║   ██║██╔══██╗   ██║   ██║   ██║██║╚██╗██║██║   ██║   ██║██╔══╝  ╚════██║
                ██║██║ ╚████║   ██║   ███████╗╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║    ╚██████╔╝██║     ██║     ╚██████╔╝██║  ██║   ██║   ╚██████╔╝██║ ╚████║██║   ██║   ██║███████╗███████║
                ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝     ╚═════╝ ╚═╝     ╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝
               -->
              <mat-step [stepControl]="secondFormGroup" [completed]="completed" state="integration">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>{{ ds.LT_LG ? 'Integration' : 'Integration Opportunities' }}</ng-template>
                  <div *ngFor="let questions of integrationQuestion.controls; let i = index">
                    <ng-container [formGroup]="questions">
                      <mat-form-field appearance="outline" floatLabel="auto" class="w100">
                        <mat-label>{{questions.value.question}}</mat-label>
                        <textarea matInput rows="3" formControlName="answer" aria-label="answers"></textarea>
                      </mat-form-field>
                    </ng-container>
                  </div>
                </div>
                <div class="assessment__back">
                  <button class="button button--dark-grey" matStepperPrevious [disabled]="isSubmitted" role="button" aria-label="back button">Back</button>
                  <div class="assessment__save">
                    <div>
                      <button class="button button--dark-grey" (click)="saveAssessment()" [disabled]="isSubmitted" role="button" aria-label="save button">Save</button>
                    </div>
                    <div>
                      <button class="button button--dark-grey" matStepperNext (click)="saveAssessment()"
                        [disabled]="isSubmitted" role="button" aria-label="save and continue button">Save &
                        Continue</button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <!-- 
                ██████╗  █████╗ ████████╗ █████╗ 
                ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
                ██║  ██║███████║   ██║   ███████║
                ██║  ██║██╔══██║   ██║   ██╔══██║
                ██████╔╝██║  ██║   ██║   ██║  ██║
                ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
               -->
              <mat-step [stepControl]="secondFormGroup" [completed]="completed" state="data">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>Data</ng-template>
                  <div *ngFor="let questions of dataQuestion.controls; let i = index">
                    <ng-container [formGroup]="questions">
                      <mat-form-field appearance="outline" floatLabel="auto" class="w100">
                        <mat-label>{{questions.value.question}}</mat-label>
                        <textarea matInput rows="3" formControlName="answer" aria-label="answers"></textarea>
                      </mat-form-field>
                    </ng-container>
                  </div>
                </div>
                <div class="assessment__back">
                  <button class="button button--dark-grey" matStepperPrevious [disabled]="isSubmitted" role="button" aria-label="back button">Back</button>
                  <div class="assessment__save">
                    <div>
                      <button class="button button--dark-grey" (click)="saveAssessment()" [disabled]="isSubmitted" role="button" aria-label="save button">Save</button>
                    </div>
                    <div>
                      <button class="button button--dark-grey" matStepperNext (click)="saveAssessment()"
                        [disabled]="isSubmitted" role="button" aria-label="save and continue button">Save &
                        Continue</button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <!-- 
                ███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
                ██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
                ███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║   
                ╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║   
                ███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║   
                ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝   
               -->
              <mat-step state="submit" [completed]="completed">
                <div class="assessment__form">
                  <ng-template *ngIf="!ds.LT_SM" matStepLabel>Submit</ng-template>
                  <h5 *ngIf="!isSubmitted"
                    style="text-align: center;font-size: 17px;position: absolute;width: 97%;margin-top: -10px;z-index: 9;background-color: #FFF;">
                    Please review your answers and submit.</h5>
                  <div class="overview">
                    <div class="alignment_overview">
                      <h4 class="mt-2 mb-2">Stratgic Alignment</h4>
                      <ng-container
                        *ngFor="let item of this.assessment.get('strategicAlignment').get('questions').value">
                        <div class="question">
                          {{item.question}}
                        </div>
                        <div class="answer" *ngIf="item.answer != null">
                          {{item.answer}}
                        </div>
                        <div class="answer" *ngIf="item.answer == null">
                          N/A
                        </div>
                      </ng-container>
                    </div>
                    <div class="alignment_overview">
                      <h4 class="mt-2 mb-2">Demonstrable Need</h4>
                      <ng-container *ngFor="let item of this.assessment.get('demonstrableNeed').get('questions').value">
                        <div class="question">
                          {{item.question}}
                        </div>
                        <div class="answer" *ngIf="item.answer != null">
                          {{item.answer}}
                        </div>
                        <div class="answer" *ngIf="item.answer == null">
                          N/A
                        </div>
                      </ng-container>
                      <ng-container>
                        <div style="width:100%">
                          <h4 class="mt-2 mb-2">Program Chart</h4>
                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Advocacy</h5>
                            <div>
                              <p *ngIf="advocacy.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{advocacy.alignmentWithInstitutional}}</p>
                              <p *ngIf="!advocacy.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.alignmentWithAlumniAssociationPriorities"><span>Alignment with Alumni
                                  Association Priorities: </span> {{advocacy.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!advocacy.alignmentWithAlumniAssociationPriorities"><span>Alignment with Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{advocacy.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!advocacy.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.staffTime"><span>Staff Time: </span>
                                {{advocacy.staffTime}}
                              </p>
                              <p *ngIf="!advocacy.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{advocacy.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!advocacy.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.priorities"><span>Partnership Opportunities: </span>
                                {{advocacy.priorities}}
                              </p>
                              <p *ngIf="!advocacy.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.utilization"><span>Technology Utilization: </span>
                                {{advocacy.utilization}}
                              </p>
                              <p *ngIf="!advocacy.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.measurable"><span>Measurable: </span>
                                {{advocacy.measurable}}
                              </p>
                              <p *ngIf="!advocacy.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.avgScore"><span>Avg. Score: </span>
                                {{advocacy.avgScore}}
                              </p>
                              <p *ngIf="!advocacy.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="advocacy.effortImpact"><span>Effort/Impact: </span>
                                {{advocacy.effortImpact}}
                              </p>
                              <p *ngIf="!advocacy.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Alumni Chapters</h5>
                            <div>
                              <p *ngIf="alumniChapters.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{alumniChapters.alignmentWithInstitutional}}</p>
                              <p *ngIf="!alumniChapters.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{alumniChapters.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!alumniChapters.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{alumniChapters.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!alumniChapters.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.staffTime"><span>Staff Time: </span>
                                {{alumniChapters.staffTime}}
                              </p>
                              <p *ngIf="!alumniChapters.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{alumniChapters.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!alumniChapters.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.priorities"><span>Partnership Opportunities: </span>
                                {{alumniChapters.priorities}}
                              </p>
                              <p *ngIf="!alumniChapters.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.utilization"><span>Technology Utilization: </span>
                                {{alumniChapters.utilization}}
                              </p>
                              <p *ngIf="!alumniChapters.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.measurable"><span>Measurable: </span>
                                {{alumniChapters.measurable}}
                              </p>
                              <p *ngIf="!alumniChapters.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.avgScore"><span>Avg. Score: </span>
                                {{alumniChapters.avgScore}}
                              </p>
                              <p *ngIf="!alumniChapters.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniChapters.effortImpact"><span>Effort/Impact: </span>
                                {{alumniChapters.effortImpact}}
                              </p>
                              <p *ngIf="!alumniChapters.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Alumni Hiring</h5>
                            <div>
                              <p *ngIf="alumniHiring.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{alumniHiring.alignmentWithInstitutional}}</p>
                              <p *ngIf="!alumniHiring.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{alumniHiring.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!alumniHiring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{alumniHiring.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!alumniHiring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.staffTime"><span>Staff Time: </span>
                                {{alumniHiring.staffTime}}
                              </p>
                              <p *ngIf="!alumniHiring.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{alumniHiring.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!alumniHiring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.priorities"><span>Partnership Opportunities: </span>
                                {{alumniHiring.priorities}}
                              </p>
                              <p *ngIf="!alumniHiring.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.utilization"><span>Technology Utilization: </span>
                                {{alumniHiring.utilization}}
                              </p>
                              <p *ngIf="!alumniHiring.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.measurable"><span>Measurable: </span>
                                {{alumniHiring.measurable}}
                              </p>
                              <p *ngIf="!alumniHiring.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.avgScore"><span>Avg. Score: </span>
                                {{alumniHiring.avgScore}}
                              </p>
                              <p *ngIf="!alumniHiring.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="alumniHiring.effortImpact"><span>Effort/Impact: </span>
                                {{alumniHiring.effortImpact}}
                              </p>
                              <p *ngIf="!alumniHiring.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Career Support</h5>
                            <div>
                              <p *ngIf="careerSupport.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{careerSupport.alignmentWithInstitutional}}</p>
                              <p *ngIf="!careerSupport.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{careerSupport.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!careerSupport.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{careerSupport.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!careerSupport.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.staffTime"><span>Staff Time: </span>
                                {{careerSupport.staffTime}}
                              </p>
                              <p *ngIf="!careerSupport.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{careerSupport.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!careerSupport.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.priorities"><span>Partnership Opportunities: </span>
                                {{careerSupport.priorities}}
                              </p>
                              <p *ngIf="!careerSupport.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.utilization"><span>Technology Utilization: </span>
                                {{careerSupport.utilization}}
                              </p>
                              <p *ngIf="!careerSupport.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.measurable"><span>Measurable: </span>
                                {{careerSupport.measurable}}
                              </p>
                              <p *ngIf="!careerSupport.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.avgScore"><span>Avg. Score: </span>
                                {{careerSupport.avgScore}}
                              </p>
                              <p *ngIf="!careerSupport.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="careerSupport.effortImpact"><span>Effort/Impact: </span>
                                {{careerSupport.effortImpact}}
                              </p>
                              <p *ngIf="!careerSupport.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">eNews</h5>
                            <div>
                              <p *ngIf="eNews.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{eNews.alignmentWithInstitutional}}</p>
                              <p *ngIf="!eNews.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{eNews.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!eNews.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{eNews.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!eNews.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="eNews.staffTime"><span>Staff Time: </span>
                                {{eNews.staffTime}}
                              </p>
                              <p *ngIf="!eNews.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{eNews.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!eNews.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.priorities"><span>Partnership Opportunities: </span>
                                {{eNews.priorities}}
                              </p>
                              <p *ngIf="!eNews.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.utilization"><span>Technology Utilization: </span>
                                {{eNews.utilization}}
                              </p>
                              <p *ngIf="!eNews.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.measurable"><span>Measurable: </span>
                                {{eNews.measurable}}
                              </p>
                              <p *ngIf="!eNews.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.avgScore"><span>Avg. Score: </span>
                                {{eNews.avgScore}}
                              </p>
                              <p *ngIf="!eNews.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="eNews.effortImpact"><span>Effort/Impact: </span>
                                {{eNews.effortImpact}}
                              </p>
                              <p *ngIf="!eNews.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Giving Day</h5>
                            <div>
                              <p *ngIf="givingDay.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{givingDay.alignmentWithInstitutional}}</p>
                              <p *ngIf="!givingDay.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{givingDay.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!givingDay.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{givingDay.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!givingDay.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.staffTime"><span>Staff Time: </span>
                                {{givingDay.staffTime}}
                              </p>
                              <p *ngIf="!givingDay.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{givingDay.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!givingDay.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.priorities"><span>Partnership Opportunities: </span>
                                {{givingDay.priorities}}
                              </p>
                              <p *ngIf="!givingDay.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.utilization"><span>Technology Utilization: </span>
                                {{givingDay.utilization}}
                              </p>
                              <p *ngIf="!givingDay.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.measurable"><span>Measurable: </span>
                                {{givingDay.measurable}}
                              </p>
                              <p *ngIf="!givingDay.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.avgScore"><span>Avg. Score: </span>
                                {{givingDay.avgScore}}
                              </p>
                              <p *ngIf="!givingDay.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="givingDay.effortImpact"><span>Effort/Impact: </span>
                                {{givingDay.effortImpact}}
                              </p>
                              <p *ngIf="!givingDay.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Homecoming</h5>
                            <div>
                              <p *ngIf="homecoming.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{homecoming.alignmentWithInstitutional}}</p>
                              <p *ngIf="!homecoming.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{homecoming.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!homecoming.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{homecoming.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!homecoming.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.staffTime"><span>Staff Time: </span>
                                {{homecoming.staffTime}}
                              </p>
                              <p *ngIf="!homecoming.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{homecoming.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!homecoming.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.priorities"><span>Partnership Opportunities: </span>
                                {{homecoming.priorities}}
                              </p>
                              <p *ngIf="!homecoming.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.utilization"><span>Technology Utilization: </span>
                                {{homecoming.utilization}}
                              </p>
                              <p *ngIf="!homecoming.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.measurable"><span>Measurable: </span>
                                {{homecoming.measurable}}
                              </p>
                              <p *ngIf="!homecoming.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.avgScore"><span>Avg. Score: </span>
                                {{homecoming.avgScore}}
                              </p>
                              <p *ngIf="!homecoming.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="homecoming.effortImpact"><span>Effort/Impact: </span>
                                {{homecoming.effortImpact}}
                              </p>
                              <p *ngIf="!homecoming.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Mentoring</h5>
                            <div>
                              <p *ngIf="mentoring.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{mentoring.alignmentWithInstitutional}}</p>
                              <p *ngIf="!mentoring.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{mentoring.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!mentoring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{mentoring.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!mentoring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.staffTime"><span>Staff Time: </span>
                                {{mentoring.staffTime}}
                              </p>
                              <p *ngIf="!mentoring.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{mentoring.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!mentoring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.priorities"><span>Partnership Opportunities: </span>
                                {{mentoring.priorities}}
                              </p>
                              <p *ngIf="!mentoring.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.utilization"><span>Technology Utilization: </span>
                                {{mentoring.utilization}}
                              </p>
                              <p *ngIf="!mentoring.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.measurable"><span>Measurable: </span>
                                {{mentoring.measurable}}
                              </p>
                              <p *ngIf="!mentoring.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.avgScore"><span>Avg. Score: </span>
                                {{mentoring.avgScore}}
                              </p>
                              <p *ngIf="!mentoring.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="mentoring.effortImpact"><span>Effort/Impact: </span>
                                {{mentoring.effortImpact}}
                              </p>
                              <p *ngIf="!mentoring.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Schaloarships</h5>
                            <div>
                              <p *ngIf="scholarships.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{scholarships.alignmentWithInstitutional}}</p>
                              <p *ngIf="!scholarships.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{scholarships.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!scholarships.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{scholarships.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!scholarships.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.staffTime"><span>Staff Time: </span>
                                {{scholarships.staffTime}}
                              </p>
                              <p *ngIf="!scholarships.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{scholarships.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!scholarships.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.priorities"><span>Partnership Opportunities: </span>
                                {{scholarships.priorities}}
                              </p>
                              <p *ngIf="!scholarships.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.utilization"><span>Technology Utilization: </span>
                                {{scholarships.utilization}}
                              </p>
                              <p *ngIf="!scholarships.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.measurable"><span>Measurable: </span>
                                {{scholarships.measurable}}
                              </p>
                              <p *ngIf="!scholarships.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.avgScore"><span>Avg. Score: </span>
                                {{scholarships.avgScore}}
                              </p>
                              <p *ngIf="!scholarships.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="scholarships.effortImpact"><span>Effort/Impact: </span>
                                {{scholarships.effortImpact}}
                              </p>
                              <p *ngIf="!scholarships.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>

                          <div class="view-overview">
                            <h5 style="font-weight: 500; font-size: 17px;">Webinars</h5>
                            <div>
                              <p *ngIf="webinars.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> {{webinars.alignmentWithInstitutional}}</p>
                              <p *ngIf="!webinars.alignmentWithInstitutional"><span>Alignment with Institutional
                                  Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span>
                                {{webinars.alignmentWithAlumniAssociationPriorities}}
                              </p>
                              <p *ngIf="!webinars.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                                  Alumni
                                  Association Priorities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
                                {{webinars.numberOfAlumniEngaged}}
                              </p>
                              <p *ngIf="!webinars.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
                              </p>
                            </div>
                            <div>
                              <p *ngIf="webinars.staffTime"><span>Staff Time: </span>
                                {{scholarships.staffTime}}
                              </p>
                              <p *ngIf="!webinars.staffTime"><span>Staff Time: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                {{webinars.budgetPartnershipOpportunitiesTechnology}}
                              </p>
                              <p *ngIf="!webinars.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
                                n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.priorities"><span>Partnership Opportunities: </span>
                                {{webinars.priorities}}
                              </p>
                              <p *ngIf="!webinars.priorities"><span>Partnership Opportunities: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.utilization"><span>Technology Utilization: </span>
                                {{webinars.utilization}}
                              </p>
                              <p *ngIf="!webinars.utilization"><span>Technology Utilization: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.measurable"><span>Measurable: </span>
                                {{webinars.measurable}}
                              </p>
                              <p *ngIf="!webinars.measurable"><span>Measurable: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.avgScore"><span>Avg. Score: </span>
                                {{webinars.avgScore}}
                              </p>
                              <p *ngIf="!webinars.avgScore"><span>Avg. Score: </span> n/a</p>
                            </div>
                            <div>
                              <p *ngIf="webinars.effortImpact"><span>Effort/Impact: </span>
                                {{webinars.effortImpact}}
                              </p>
                              <p *ngIf="!webinars.effortImpact"><span>Effort/Impact </span> n/a</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container>
                        <div style="width:100%">
                          <h4 class="mt-2 mb-2">Priority Matrix</h4>
                        </div>
                        <div style="margin-bottom: 10px;">
                          <p *ngIf="q1.length > 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/HIGH
                              EFFORT:
                            </span>
                            <span *ngFor="let item of q1; let i = index"> <span
                                class="matrix-item">{{item}}</span></span>
                          </p>
                          <p *ngIf="q1.length === 0"><span>HIGH IMPACT/HIGH EFFORT </span> <span
                              class="matrix-item">N/A</span></p>
                        </div>

                        <div style="margin-bottom: 10px;">
                          <p *ngIf="q2.length > 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/LOW
                              EFFORT:
                            </span>
                            <span *ngFor="let item of q2; let i = index">
                              <span class="matrix-item">{{item}}</span>
                            </span>
                          </p>
                          <p *ngIf="q2.length === 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/LOW
                              EFFORT
                            </span> <span class="matrix-item">N/A</span></p>
                        </div>

                        <div style="margin-bottom: 10px;">
                          <p *ngIf="q3.length > 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/HIGH
                              EFFORT:
                            </span>
                            <span *ngFor="let item of q3; let i = index"> <span
                                class="matrix-item">{{item}}</span></span>
                          </p>
                          <p *ngIf="q3.length === 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/HIGH
                              EFFORT
                            </span> <span class="matrix-item">N/A</span></p>
                        </div>

                        <div style="margin-bottom: 10px;">
                          <p *ngIf="q4.length > 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/LOW EFFORT:
                            </span>
                            <span *ngFor="let item of q4; let i = index"> <span
                                class="matrix-item">{{item}}</span></span>
                          </p>
                          <p *ngIf="q4.length === 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/LOW
                              EFFORT
                            </span> <span class="matrix-item">N/A</span></p>
                        </div>
                      </ng-container>

                    </div>
                    <hr class="mt-2 mb-2">
                    <div class="budget_overview">
                      <h4 class="mt-2 mb-2">Budget Justification</h4>
                      <ng-container
                        *ngFor="let item of this.assessment.get('budgetJustification').get('questions').value">
                        <div class="question">
                          {{item.question}}
                        </div>
                        <div class="answer" *ngIf="item.answer != null">
                          {{item.answer}}
                        </div>
                        <div class="answer" *ngIf="item.answer == null">
                          N/A
                        </div>
                      </ng-container>
                    </div>
                    <hr class="mt-2 mb-2">
                    <div class="integration_overview">
                      <h4 class="mt-2 mb-2">Integration Opportunities</h4>
                      <ng-container
                        *ngFor="let item of this.assessment.get('integrationOpportunities').get('questions').value">
                        <div class="question">
                          {{item.question}}
                        </div>
                        <div class="answer" *ngIf="item.answer != null">
                          {{item.answer}}
                        </div>
                        <div class="answer" *ngIf="item.answer == null">
                          N/A
                        </div>
                      </ng-container>
                    </div>
                    <hr class="mt-2 mb-2">
                    <div class="data_overview">
                      <h4 class="mt-2 mb-2">Data</h4>
                      <ng-container *ngFor="let item of this.assessment.get('data').get('questions').value">
                        <div class="question">
                          {{item.question}}
                        </div>
                        <div class="answer" *ngIf="item.answer != null">
                          {{item.answer}}
                        </div>
                        <div class="answer" *ngIf="item.answer == null">
                          N/A
                        </div>
                      </ng-container>
                    </div>

                  </div>

                  <div class="assessment__form">
                    <!-- <p>You are now done.</p>
                  <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="stepper.reset()">Reset</button>
                  </div> -->
                  </div>
                </div>
                <div class="assessment__back">
                  <button class="button button--dark-grey" matStepperPrevious [disabled]="isSubmitted"
                    *ngIf="!isSubmitted" role="button" aria-label="back button">Back</button>
                  <p style="max-width: 80%; color: #59595a;" *ngIf="isSubmitted">
                    Thank you for submitting your input. The information gathered from Profound Impact's Online
                    Assessment Tool has been successfully submitted. Please download the PDF. Same has been sent to
                    you
                    by email. We will review your answers and get back to you soon.
                  </p>
                  <div class="assessment__save">
                    <div>
                      <button class="button button--dark-grey download-pdf-button" style="margin-right: 17px;"
                      role="button" aria-label="download as pdf button" matStepperNext *ngIf="isSubmitted" (click)="pdfDownload()"> <i class="fa fa-file-pdf-o"
                          aria-hidden="true" style="font-size: 18px;"></i> Download PDF</button>
                      <button class="button button--dark-grey" matStepperNext [disabled]="isSubmitted" *ngIf="!isSubmitted"
                        (click)="submitAssessment()" role="button" aria-label="submit button">Submit</button>
                    </div>
                  </div>
                </div>

              </mat-step>

              <!-- Icon overrides -->
              <ng-template matStepperIcon="alignment">
                <mat-icon aria-label="insights icon">insights</mat-icon>
              </ng-template>
              <ng-template matStepperIcon="need">
                <mat-icon aria-label="need icon">accessibility</mat-icon>
              </ng-template>
              <ng-template matStepperIcon="budget">
                <mat-icon aria-label="budget icon">attach_money</mat-icon>
              </ng-template>
              <ng-template matStepperIcon="integration">
                <mat-icon aria-label="integration icon">donut_large</mat-icon>
              </ng-template>
              <ng-template matStepperIcon="data">
                <mat-icon aria-label="data">assessment</mat-icon>
              </ng-template>
              <ng-template matStepperIcon="submit">
                <mat-icon *ngIf="!isSubmitted" aria-label="not submit icon">exit_to_app</mat-icon>
                <mat-icon *ngIf="isSubmitted" aria-label="submit icon">done</mat-icon>
              </ng-template>
            </mat-horizontal-stepper>
          </mat-card>
        </div>
      </form>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="overview" style="display:none" #overview>
  <div class="alignment_overview">
    <h4 class="mt-2 mb-2">Stratgic Alignment</h4>
    <ng-container *ngFor="let item of this.assessment.get('strategicAlignment').get('questions').value">
      <div class="question">
        {{item.question}}
      </div>
      <div class="answer" *ngIf="item.answer != null">
        {{item.answer}}
      </div>
      <div class="answer" *ngIf="item.answer == null">
        N/A
      </div>
    </ng-container>
  </div>
  <div class="budget_overview">
    <h4 class="mt-2 mb-2">Budget Justification</h4>
    <ng-container *ngFor="let item of this.assessment.get('budgetJustification').get('questions').value">
      <div class="question">
        {{item.question}}
      </div>
      <div class="answer" *ngIf="item.answer != null">
        {{item.answer}}
      </div>
      <div class="answer" *ngIf="item.answer == null">
        N/A
      </div>
    </ng-container>
  </div>
  <div class="alignment_overview">
    <h4 class="mt-2 mb-2">Demonstrable Need</h4>
    <ng-container *ngFor="let item of this.assessment.get('demonstrableNeed').get('questions').value">
      <div class="question">
        {{item.question}}
      </div>
      <div class="answer" *ngIf="item.answer != null">
        {{item.answer}}
      </div>
      <div class="answer" *ngIf="item.answer == null">
        N/A
      </div>
    </ng-container>
    <ng-container>
      <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
      <div style="width:100%;">
        <br>
        <br><br>
        <h4 class="mt-2 mb-2">Program Chart</h4>
        <div class="view-overview" style="display: table;width: 100%;padding: 10px 5px;">
          <h5 style="font-weight: 500; font-size: 17px;">Advocacy</h5>
          <div>
            <p style="float: left;background-color: whitesmoke;padding: 3px;"
              *ngIf="advocacy.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{advocacy.alignmentWithInstitutional}}</p>
            <p *ngIf="!advocacy.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.alignmentWithAlumniAssociationPriorities"><span>Alignment with Alumni
                Association Priorities: </span> {{advocacy.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!advocacy.alignmentWithAlumniAssociationPriorities"><span>Alignment with Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{advocacy.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!advocacy.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.staffTime"><span>Staff Time: </span>
              {{advocacy.staffTime}}
            </p>
            <p *ngIf="!advocacy.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{advocacy.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!advocacy.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="advocacy.priorities"><span>Partnership Opportunities: </span>
              {{advocacy.priorities}}
            </p>
            <p *ngIf="!advocacy.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.utilization"><span>Technology Utilization: </span>
              {{advocacy.utilization}}
            </p>
            <p *ngIf="!advocacy.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.measurable"><span>Measurable: </span>
              {{advocacy.measurable}}
            </p>
            <p *ngIf="!advocacy.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.avgScore"><span>Avg. Score: </span>
              {{advocacy.avgScore}}
            </p>
            <p *ngIf="!advocacy.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="advocacy.effortImpact"><span>Effort/Impact: </span>
              {{advocacy.effortImpact}}
            </p>
            <p *ngIf="!advocacy.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Alumni Chapters</h5>
          <div>
            <p *ngIf="alumniChapters.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{alumniChapters.alignmentWithInstitutional}}</p>
            <p *ngIf="!alumniChapters.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{alumniChapters.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!alumniChapters.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{alumniChapters.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!alumniChapters.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              n/a
            </p>
          </div>
          <div>
            <p *ngIf="alumniChapters.staffTime"><span>Staff Time: </span>
              {{alumniChapters.staffTime}}
            </p>
            <p *ngIf="!alumniChapters.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{alumniChapters.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!alumniChapters.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.priorities"><span>Partnership Opportunities: </span>
              {{alumniChapters.priorities}}
            </p>
            <p *ngIf="!alumniChapters.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.utilization"><span>Technology Utilization: </span>
              {{alumniChapters.utilization}}
            </p>
            <p *ngIf="!alumniChapters.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.measurable"><span>Measurable: </span>
              {{alumniChapters.measurable}}
            </p>
            <p *ngIf="!alumniChapters.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.avgScore"><span>Avg. Score: </span>
              {{alumniChapters.avgScore}}
            </p>
            <p *ngIf="!alumniChapters.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniChapters.effortImpact"><span>Effort/Impact: </span>
              {{alumniChapters.effortImpact}}
            </p>
            <p *ngIf="!alumniChapters.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Alumni Hiring</h5>
          <div>
            <p *ngIf="alumniHiring.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{alumniHiring.alignmentWithInstitutional}}</p>
            <p *ngIf="!alumniHiring.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{alumniHiring.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!alumniHiring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{alumniHiring.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!alumniHiring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="alumniHiring.staffTime"><span>Staff Time: </span>
              {{alumniHiring.staffTime}}
            </p>
            <p *ngIf="!alumniHiring.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{alumniHiring.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!alumniHiring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.priorities"><span>Partnership Opportunities: </span>
              {{alumniHiring.priorities}}
            </p>
            <p *ngIf="!alumniHiring.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.utilization"><span>Technology Utilization: </span>
              {{alumniHiring.utilization}}
            </p>
            <p *ngIf="!alumniHiring.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.measurable"><span>Measurable: </span>
              {{alumniHiring.measurable}}
            </p>
            <p *ngIf="!alumniHiring.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.avgScore"><span>Avg. Score: </span>
              {{alumniHiring.avgScore}}
            </p>
            <p *ngIf="!alumniHiring.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="alumniHiring.effortImpact"><span>Effort/Impact: </span>
              {{alumniHiring.effortImpact}}
            </p>
            <p *ngIf="!alumniHiring.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Career Support</h5>
          <div>
            <p *ngIf="careerSupport.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{careerSupport.alignmentWithInstitutional}}</p>
            <p *ngIf="!careerSupport.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{careerSupport.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!careerSupport.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{careerSupport.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!careerSupport.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              n/a
            </p>
          </div>
          <div>
            <p *ngIf="careerSupport.staffTime"><span>Staff Time: </span>
              {{careerSupport.staffTime}}
            </p>
            <p *ngIf="!careerSupport.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{careerSupport.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!careerSupport.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.priorities"><span>Partnership Opportunities: </span>
              {{careerSupport.priorities}}
            </p>
            <p *ngIf="!careerSupport.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.utilization"><span>Technology Utilization: </span>
              {{careerSupport.utilization}}
            </p>
            <p *ngIf="!careerSupport.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.measurable"><span>Measurable: </span>
              {{careerSupport.measurable}}
            </p>
            <p *ngIf="!careerSupport.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.avgScore"><span>Avg. Score: </span>
              {{careerSupport.avgScore}}
            </p>
            <p *ngIf="!careerSupport.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="careerSupport.effortImpact"><span>Effort/Impact: </span>
              {{careerSupport.effortImpact}}
            </p>
            <p *ngIf="!careerSupport.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">eNews</h5>
          <div>
            <p *ngIf="eNews.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{eNews.alignmentWithInstitutional}}</p>
            <p *ngIf="!eNews.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{eNews.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!eNews.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{eNews.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!eNews.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="eNews.staffTime"><span>Staff Time: </span>
              {{eNews.staffTime}}
            </p>
            <p *ngIf="!eNews.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{eNews.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!eNews.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.priorities"><span>Partnership Opportunities: </span>
              {{eNews.priorities}}
            </p>
            <p *ngIf="!eNews.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.utilization"><span>Technology Utilization: </span>
              {{eNews.utilization}}
            </p>
            <p *ngIf="!eNews.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.measurable"><span>Measurable: </span>
              {{eNews.measurable}}
            </p>
            <p *ngIf="!eNews.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.avgScore"><span>Avg. Score: </span>
              {{eNews.avgScore}}
            </p>
            <p *ngIf="!eNews.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="eNews.effortImpact"><span>Effort/Impact: </span>
              {{eNews.effortImpact}}
            </p>
            <p *ngIf="!eNews.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Giving Day</h5>
          <div>
            <p *ngIf="givingDay.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{givingDay.alignmentWithInstitutional}}</p>
            <p *ngIf="!givingDay.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{givingDay.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!givingDay.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{givingDay.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!givingDay.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="givingDay.staffTime"><span>Staff Time: </span>
              {{givingDay.staffTime}}
            </p>
            <p *ngIf="!givingDay.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{givingDay.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!givingDay.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.priorities"><span>Partnership Opportunities: </span>
              {{givingDay.priorities}}
            </p>
            <p *ngIf="!givingDay.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.utilization"><span>Technology Utilization: </span>
              {{givingDay.utilization}}
            </p>
            <p *ngIf="!givingDay.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.measurable"><span>Measurable: </span>
              {{givingDay.measurable}}
            </p>
            <p *ngIf="!givingDay.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.avgScore"><span>Avg. Score: </span>
              {{givingDay.avgScore}}
            </p>
            <p *ngIf="!givingDay.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="givingDay.effortImpact"><span>Effort/Impact: </span>
              {{givingDay.effortImpact}}
            </p>
            <p *ngIf="!givingDay.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Homecoming</h5>
          <div>
            <p *ngIf="homecoming.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{homecoming.alignmentWithInstitutional}}</p>
            <p *ngIf="!homecoming.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{homecoming.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!homecoming.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{homecoming.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!homecoming.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="homecoming.staffTime"><span>Staff Time: </span>
              {{homecoming.staffTime}}
            </p>
            <p *ngIf="!homecoming.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{homecoming.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!homecoming.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.priorities"><span>Partnership Opportunities: </span>
              {{homecoming.priorities}}
            </p>
            <p *ngIf="!homecoming.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.utilization"><span>Technology Utilization: </span>
              {{homecoming.utilization}}
            </p>
            <p *ngIf="!homecoming.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.measurable"><span>Measurable: </span>
              {{homecoming.measurable}}
            </p>
            <p *ngIf="!homecoming.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.avgScore"><span>Avg. Score: </span>
              {{homecoming.avgScore}}
            </p>
            <p *ngIf="!homecoming.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="homecoming.effortImpact"><span>Effort/Impact: </span>
              {{homecoming.effortImpact}}
            </p>
            <p *ngIf="!homecoming.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Mentoring</h5>
          <div>
            <p *ngIf="mentoring.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{mentoring.alignmentWithInstitutional}}</p>
            <p *ngIf="!mentoring.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{mentoring.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!mentoring.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{mentoring.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!mentoring.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="mentoring.staffTime"><span>Staff Time: </span>
              {{mentoring.staffTime}}
            </p>
            <p *ngIf="!mentoring.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{mentoring.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!mentoring.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.priorities"><span>Partnership Opportunities: </span>
              {{mentoring.priorities}}
            </p>
            <p *ngIf="!mentoring.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.utilization"><span>Technology Utilization: </span>
              {{mentoring.utilization}}
            </p>
            <p *ngIf="!mentoring.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.measurable"><span>Measurable: </span>
              {{mentoring.measurable}}
            </p>
            <p *ngIf="!mentoring.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.avgScore"><span>Avg. Score: </span>
              {{mentoring.avgScore}}
            </p>
            <p *ngIf="!mentoring.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="mentoring.effortImpact"><span>Effort/Impact: </span>
              {{mentoring.effortImpact}}
            </p>
            <p *ngIf="!mentoring.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Schaloarships</h5>
          <div>
            <p *ngIf="scholarships.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{scholarships.alignmentWithInstitutional}}</p>
            <p *ngIf="!scholarships.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{scholarships.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!scholarships.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{scholarships.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!scholarships.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="scholarships.staffTime"><span>Staff Time: </span>
              {{scholarships.staffTime}}
            </p>
            <p *ngIf="!scholarships.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{scholarships.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!scholarships.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.priorities"><span>Partnership Opportunities: </span>
              {{scholarships.priorities}}
            </p>
            <p *ngIf="!scholarships.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.utilization"><span>Technology Utilization: </span>
              {{scholarships.utilization}}
            </p>
            <p *ngIf="!scholarships.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.measurable"><span>Measurable: </span>
              {{scholarships.measurable}}
            </p>
            <p *ngIf="!scholarships.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.avgScore"><span>Avg. Score: </span>
              {{scholarships.avgScore}}
            </p>
            <p *ngIf="!scholarships.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="scholarships.effortImpact"><span>Effort/Impact: </span>
              {{scholarships.effortImpact}}
            </p>
            <p *ngIf="!scholarships.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
        <div class="view-overview">
          <h5 style="font-weight: 500; font-size: 17px;">Webinars</h5>
          <div>
            <p *ngIf="webinars.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> {{webinars.alignmentWithInstitutional}}</p>
            <p *ngIf="!webinars.alignmentWithInstitutional"><span>Alignment with Institutional
                Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span>
              {{webinars.alignmentWithAlumniAssociationPriorities}}
            </p>
            <p *ngIf="!webinars.alignmentWithAlumniAssociationPriorities"><span>Alignment with
                Alumni
                Association Priorities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.numberOfAlumniEngaged"><span>Number of alumni engaged: </span>
              {{webinars.numberOfAlumniEngaged}}
            </p>
            <p *ngIf="!webinars.numberOfAlumniEngaged"><span>Number of alumni engaged: </span> n/a
            </p>
          </div>
          <div>
            <p *ngIf="webinars.staffTime"><span>Staff Time: </span>
              {{scholarships.staffTime}}
            </p>
            <p *ngIf="!webinars.staffTime"><span>Staff Time: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              {{webinars.budgetPartnershipOpportunitiesTechnology}}
            </p>
            <p *ngIf="!webinars.budgetPartnershipOpportunitiesTechnology"><span>Budget: </span>
              n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.priorities"><span>Partnership Opportunities: </span>
              {{webinars.priorities}}
            </p>
            <p *ngIf="!webinars.priorities"><span>Partnership Opportunities: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.utilization"><span>Technology Utilization: </span>
              {{webinars.utilization}}
            </p>
            <p *ngIf="!webinars.utilization"><span>Technology Utilization: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.measurable"><span>Measurable: </span>
              {{webinars.measurable}}
            </p>
            <p *ngIf="!webinars.measurable"><span>Measurable: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.avgScore"><span>Avg. Score: </span>
              {{webinars.avgScore}}
            </p>
            <p *ngIf="!webinars.avgScore"><span>Avg. Score: </span> n/a</p>
          </div>
          <div>
            <p *ngIf="webinars.effortImpact"><span>Effort/Impact: </span>
              {{webinars.effortImpact}}
            </p>
            <p *ngIf="!webinars.effortImpact"><span>Effort/Impact </span> n/a</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div style="width:100%">
        <h4 class="mt-2 mb-2">Priority Matrix</h4>
      </div>
      <div style="margin-bottom: 10px;">
        <p *ngIf="q1.length > 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/HIGH
            EFFORT:
          </span>
          <span *ngFor="let item of q1; let i = index"> <span class="matrix-item">{{item}}</span></span>
        </p>
        <p *ngIf="q1.length === 0"><span>HIGH IMPACT/HIGH EFFORT </span> <span class="matrix-item">N/A</span></p>
      </div>

      <div style="margin-bottom: 10px;">
        <p *ngIf="q2.length > 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/LOW
            EFFORT:
          </span>
          <span *ngFor="let item of q2; let i = index">
            <span class="matrix-item">{{item}}</span>
          </span>
        </p>
        <p *ngIf="q2.length === 0"><span style="font-weight: 500;color: #565656;">HIGH IMPACT/LOW
            EFFORT
          </span> <span class="matrix-item">N/A</span></p>
      </div>

      <div style="margin-bottom: 10px;">
        <p *ngIf="q3.length > 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/HIGH
            EFFORT:
          </span>
          <span *ngFor="let item of q3; let i = index"> <span class="matrix-item">{{item}}</span></span>
        </p>
        <p *ngIf="q3.length === 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/HIGH
            EFFORT
          </span> <span class="matrix-item">N/A</span></p>
      </div>

      <div style="margin-bottom: 10px;">
        <p *ngIf="q4.length > 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/LOW EFFORT:
          </span>
          <span *ngFor="let item of q4; let i = index"> <span class="matrix-item">{{item}}</span></span>
        </p>
        <p *ngIf="q4.length === 0"><span style="font-weight: 500;color: #565656;">LOW IMPACT/LOW
            EFFORT
          </span> <span class="matrix-item">N/A</span></p>
      </div>
    </ng-container>

  </div>
  <hr class="mt-2 mb-2">

  <hr class="mt-2 mb-2">
  <br><br>
  <div class="integration_overview">
    <h4 class="mt-2 mb-2">Integration Opportunities</h4>
    <ng-container *ngFor="let item of this.assessment.get('integrationOpportunities').get('questions').value">
      <div class="question">
        {{item.question}}
      </div>
      <div class="answer" *ngIf="item.answer != null">
        {{item.answer}}
      </div>
      <div class="answer" *ngIf="item.answer == null">
        N/A
      </div>
    </ng-container>
  </div>
  <hr class="mt-2 mb-2">
  <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  <div class="data_overview">
    <h4 class="mt-2 mb-2">Data</h4>
    <ng-container *ngFor="let item of this.assessment.get('data').get('questions').value">
      <div class="question">
        {{item.question}}
      </div>
      <div class="answer" *ngIf="item.answer != null">
        {{item.answer}}
      </div>
      <div class="answer" *ngIf="item.answer == null">
        N/A
      </div>
    </ng-container>
  </div>
</div>