<div class="modal-header pt-2 pb-2 z8000">
  <button
    type="button"
    class="close"
    aria-label="Close button"
    ngbAutofocus
    (click)="activeModal.close('Cross click')"
    role="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <div class="container">
    <div class="row">
      <div class="col-12 border-bottom-1">
        <iframe
          *ngIf="data.isMobile"
          width="100%"
          height="300vw"
          [src]="url"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          aria-label="video player"
          role="figure"
        ></iframe>
        <iframe
          *ngIf="!data.isMobile"
          width="560"
          height="315"
          [src]="url"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          aria-label="video player"
          role="figure"
        ></iframe>
      </div>
    </div>
  </div>
</div>
