<div id="system-requirements-page" class="fill-view" role="region" aria-label="system-requirements page">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="System Requirements">
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="displayWarning" role="region" aria-label="warning message">
                <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="40px">
                  <i class="fa fa-chrome"></i>
                  <i class="fa fa-safari"></i>
                  <i class="fa fa-firefox"></i>
                </div>
                <p class="">{{ message }}</p>
              </div>
            </div>  
            <div role="region" aria-label="current system specifications" class="system-requirements-page-content">
              <p class="card--system-requirements-config fw600">
                <span *ngIf="!displayWarning" class="fa system-requirements-page-current-config">&#xf00c;</span>
                Current Operating System: {{ this.deviceInfo.os }} | Version:
                {{ this.deviceInfo.os_version }}
              </p>
              <p class="card--system-requirements-config fw600">
                <span *ngIf="!displayWarning" class="fa system-requirements-page-current-config">&#xf00c;</span>
                Current Browser: {{ this.deviceInfo.browser }} | Version:
                {{ this.deviceInfo.browser_version }}
              </p>
              <p class="card--system-requirements-message text-center mb-0"> Below are the minimum system specs required </p>
              <p class="card--system-requirements-message text-center mb-0"> to properly run the Profound Impact Platform</p>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 system-requirements-page-table">

              <ng-container matColumnDef="requirement">
                <th mat-header-cell *matHeaderCellDef> Requirement </th>
                <td mat-cell *matCellDef="let element"> {{element.requirement}} </td>
              </ng-container>
  
              <ng-container matColumnDef="windows">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> {{element.windows}} </td>
              </ng-container>
  
              <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> {{element.mac}} </td>
              </ng-container>
  
              <ng-container matColumnDef="linux">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> {{ element.linux }} </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
      </div> 
    </mat-tab>   
  </mat-tab-group>
</div>   