import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SiteConstants } from '../../services/site-constants.service';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { Auth } from 'aws-amplify';
import { UtilityService } from 'src/app/services/utility.service';
import { faLinkedin, faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TitleCasePipe } from '../../shared/pipes/pipe.component';
import jwt_decode from 'jwt-decode';

// import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import { faPi } from "../../shared/components/custom-icons/pi-logo";

enum SettingStatus {
  Default,
  VerifyEmail,
  VerifyPassword,
}

interface SocialAccount {
  email: string;
  verified: boolean;
  type: string;
  uuidPerson: string;
  icon?: any[];
  src?: string;
}

interface SocialBrand {
  logo: [];
  name: string;
  color: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants]
})

export class SettingsComponent implements OnInit {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  uuid = '';
  socialConnectForm: FormGroup;
  dataSource = [];
  isTableDataLoaded: boolean;
  displayedColumns: string[] = ['email', 'connectionType', 'status'];
  faLinkedIn = faLinkedin;
  faInstagram = faInstagram;
  faFacebook = faFacebookSquare;
  faEnvelope = faEnvelope;
  // faPi = faPi;
  socialMedia = [
    {logo: this.faEnvelope, name: 'Email', color: '#646464' },
    { logo: this.faLinkedIn, name: 'LinkedIn', color: '#0077b5' },
    // {logo: this.faInstagram, name: 'Instagram', color: '#8a3ab9'},
    // { logo: this.faFacebook, name: 'Facebook', color: '#3b5998' },
    // {logo: this.faPi, name: 'Profound Impact', color: '#3b5998'},

  ];
  socialConnect = [];
  socialConnectEmail = '';
  socialConnectEmailValid = false;
  root: string;
  user: any;
  autoVerify = true;
  httpClientCallMessage = '';
  settingStatus = SettingStatus;
  currentEmailStatus = SettingStatus.Default;
  currentPasswordStatus = SettingStatus.Default;
  password = '';
  newPassword = '';
  newPasswordVerified = '';
  code: string;
  alternateEmail: string;
  changeNewEmail = '';
  changePasswordForm: any = [];
  newEmailValid = false;
  alternateEmailValid = false;
  selectedSettingTab = 1;
  fullname: any;
  hide = true;
  ifChange = false;
  systemEmailAddress: string;
  publicFieldsPrivacy = [
    {
      name: 'Full Name',
      value: true
    },
    {
      name: 'Profile Picture',
      value: true
    },
    {
      name: 'Email',
      value: true
    },
    {
      name: 'Phone',
      value: true
    },
    {
      name: 'Address',
      value: true
    },
    {
      name: 'Social Media',
      value: true
    },
    {
      name: 'Allow Private Messages',
      value: true
    }
  ];
  tabs = [
    {
      name: 'CONNECT ACCOUNTS',
      key: 1,
      active: true,
    },
    {
      name: 'CHANGE EMAIL',
      key: 2,
      active: false,
    },
    {
      name: 'CHANGE PASSWORD',
      key: 3,
      active: false,
    },
    {
      name: 'CHANGE PRIVACY MODE',
      key: 4,
      active: false,
    },
  ];
  showInfoMsg = false;
  connectionTypeValueSubscription: any;
  infoMessageOnConnectionChange = '';

  constructor(
    private http: HttpClient,
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public _toaster: ToastrManager,
    public data: DataService,
    private api: HttpService,
    public siteConstant: SiteConstants,
    private util: UtilityService,
    private formBuilder: FormBuilder,
    private titleCase: TitleCasePipe
  ) {
    this.siteConstant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
  }

  createAccountForm() {
    this.socialConnectForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      connectionType: ['', Validators.required]
    });
  }

  get f() { return this.socialConnectForm.controls; }

  // updates primary email
  async updateEmail() {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
      email: this.changeNewEmail,
    });
    if (result === 'SUCCESS') {
      this.util.getUser();
      this.data.currentUser.email = this.changeNewEmail;
      this.currentEmailStatus = this.settingStatus.VerifyEmail;
      if (this.user !== undefined && this.user.attributes !== undefined) {
        this.user.attributes['email'] = this.changeNewEmail;
      }
      this.httpClientCallMessage = `Please check ${this.changeNewEmail} for verification code`;
      this._toaster.successToastr(this.httpClientCallMessage, 'Change Email');
      if (this.autoVerify) {
        const verifyRequest = await Auth.verifyUserAttribute(user, 'email');
      }
    } else {
      this.httpClientCallMessage = result;
      this._toaster.errorToastr(this.httpClientCallMessage, '@Change Email');
    }
  }

  // Settings initializer logic
  async ngOnInit() {
    const token = localStorage.getItem('jwt');
    const decodedToken: any = jwt_decode(token);
    this.systemEmailAddress = decodedToken.email;
    this.createAccountForm();
    this.onConnectionTypeChange();
    // sets the pages title
    this.data.setPageTitle('Settings');
    // sets loading spinner logic
    this.spinner.show();

    // sets form field defaults
    this.changePasswordForm.oldPassword = '';
    this.changePasswordForm.newPassword = '';
    this.changePasswordForm.confirmPassword = '';
    this.changePasswordForm.passwordStrength = {};
    this.changePasswordForm.passwordStrength['newPassword'] = {};
    this.changePasswordForm.passwordStrength['confirmPassword'] = {};

    // get privacy settings
    this.getPrivacySettings();

    // gets user data
    await this.util.getUser();

    // get user's uuid from the local storage
    this.uuid = localStorage.getItem('uuidPerson');
    // get all social account's data of the logged in user
    this.getSocialAccountsData(this.uuid);

    // removes loading spinner & shows content
    this.spinner.hide();
  }

  // checks email validity
  checkEmail() {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    this.newEmailValid = pattern.test(this.changeNewEmail);
    this.alternateEmailValid = pattern.test(this.alternateEmail);
  }

  // changes the settings tab
  tabChange(selectedTab) {
    this.selectedSettingTab = selectedTab.key;
    for (const tab of this.tabs) {
      if (tab.key === selectedTab.key) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    }
  }

  // checks password
  checkPassword(event: any, type: string) {
    const theObject = event;
    const theValue = theObject.target.value;
    this.changePasswordForm.passwordStrength[type] = {};
    this.changePasswordForm.passwordStrength[type]['pcLengthValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcUpperValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcLowerValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcNumberValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 0;
    const lengthCharacters = event.target.value.length >= 8;
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numberCharacters = /[0-9]+/g;
    const specialCharacters = /[\^!@#$%&*()_\[\]{};:"\\|,\.<>/?~`]+/;
    if (lengthCharacters) {
      this.changePasswordForm.passwordStrength[type]['pcLengthValue'] = 1;
    }
    if (upperCaseCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcUpperValue'] = 1;
    }
    if (lowerCaseCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcLowerValue'] = 1;
    }
    if (numberCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcNumberValue'] = 1;
    }
    if (specialCharacters.test(theValue) === true) {
      if (
        event.target.value.includes('-') ||
        // tslint:disable-next-line: quotemark
        event.target.value.includes("'") ||
        event.target.value.includes('-') ||
        event.target.value.includes('+') ||
        event.target.value.includes('=')
      ) {
        this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 0;
      } else {
        this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 1;
        this.changePasswordForm.passwordStrength[type]['checkPassword'] = 1;
      }
    }
  }

  // checks validity to control the check in UI
  validCheckPassword(type: string) {
    let checkClass = '';
    switch (type) {
      case 'newPassword':
        if (
          this.changePasswordForm.passwordStrength[type].checkPassword === 1
        ) {
          checkClass = 'checkbutton checkactive';
        }
        if (
          this.changePasswordForm.newPassword === '' ||
          !this.changePasswordForm.passwordStrength[type].checkPassword ||
          !this.checkStrongPasswordType(type)
        ) {
          checkClass = 'checkbutton';
        }
        break;
      case 'confirmPassword':
        if (
          this.changePasswordForm.passwordStrength[type].checkPassword === 1 &&
          this.changePasswordForm.confirmPassword ===
          this.changePasswordForm.newPassword
        ) {
          checkClass = 'checkbutton checkactive';
        }
        if (
          this.changePasswordForm.confirmPassword === '' ||
          !this.changePasswordForm.passwordStrength[type].checkPassword ||
          !this.checkStrongPasswordType(type) ||
          this.changePasswordForm.confirmPassword !==
          this.changePasswordForm.newPassword
        ) {
          checkClass = 'checkbutton';
        }
        break;
    }
    return checkClass;
  }

  // checks if password is strong enough
  checkStrongPasswordType(type: string) {
    const checkedType = true;
    const typeObject = this.changePasswordForm.passwordStrength[type];
    for (const [key, value] of Object.entries(typeObject)) {
      if (!value && key !== undefined) {
        return false;
      }
    }
    return checkedType;
  }

  // checks if password is valid
  isValid() {
    if (
      this.changePasswordForm.oldPassword === '' ||
      this.changePasswordForm.newPassword === '' ||
      this.changePasswordForm.confirmPassword === '' ||
      this.changePasswordForm.confirmPassword !==
      this.changePasswordForm.newPassword
    ) {
      return false;
    }
    if (!this.checkStrongPasswordType('newPassword')) {
      return false;
    }
    if (!this.checkStrongPasswordType('confirmPassword')) {
      return false;
    }
    return true;
  }

  // submits form
  async onSubmit() {
    if (this.isValid()) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.changePassword(
          user,
          this.changePasswordForm.oldPassword,
          this.changePasswordForm.newPassword
        );
        if (result === 'SUCCESS') {
          this.api.refreshProfileCompletion();
          this.httpClientCallMessage = 'Password Change successfully';
          this._toaster.successToastr(
            this.httpClientCallMessage,
            'Update Password'
          );
          this.util.logout();
        } else {
          this.httpClientCallMessage = result;
          this._toaster.errorToastr(
            this.httpClientCallMessage,
            'Validation Error'
          );
          this.changePasswordForm.oldPassword = '';
          this.changePasswordForm.confirmPassword = '';
          this.changePasswordForm.newPassword = '';
        }
      } catch (e) {
        this.httpClientCallMessage = e.message;
        this._toaster.errorToastr(
          this.httpClientCallMessage,
          'Validation Error'
        );
      }
      return true;
    } else {
      this._toaster.errorToastr(
        'Please enter password properly',
        'Validation Error'
      );
      return false;
    }
  }

  // verifies users email with code
  async verifyEmail() {
    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        this.code
      );
      if (result === 'SUCCESS') {
        this.util.getUser();
        this.currentEmailStatus = this.settingStatus.Default;
        this.httpClientCallMessage =
          'Email updated successfully, please log back in.';
        const headersSend = new HttpHeaders({
          Authorization: `Bearer ${this.data.jwt}`,
          'Content-Type': 'application/json',
        });
        const postData = {};
        if (this.user !== undefined && this.user.attributes !== undefined) {
          postData['cognitoUserName'] = this.user.username;
          postData['profileEmail'] = this.user.attributes.email;
        }
        const apiUrl = `${this.root}/userProfileEmailUpdate?operation=Update-primary`;
        // tslint:disable-next-line: max-line-length
        const emailUpdate = this.http.post<any>(
          apiUrl,
          { email: this.changeNewEmail, isPrimary: true },
          { headers: headersSend }
        );
        if (emailUpdate) {
          emailUpdate.subscribe((rec) => {
            this.api.refreshProfileCompletion();
            if (rec.status === 'OK' && rec.data !== undefined) {
              this._toaster.successToastr(
                this.httpClientCallMessage,
                'Change Email'
              );
              Auth.signOut({ global: true }).then((data) => { });
              this.util.logout();
            }
          });
        }
      } else {
        this.httpClientCallMessage = result;
        this._toaster.errorToastr(this.httpClientCallMessage, 'Change Email');
      }
    } catch (e) {
      this.httpClientCallMessage = e.message;
      this._toaster.errorToastr(this.httpClientCallMessage, 'Change Email');
    }
  }

  // Updates user's profile status to private or public
  updateProfileStatus() {
    this.api.updateProfileStatus({ profileStatus: this.data.userProfileStatusFlag ? 'public' : 'private' }).subscribe((response: any) => {
      if (response !== undefined && response.status === 'OK') {
        this._toaster.successToastr(response.message, 'Status Change');
        this.util.getUser();
      }
      if (response !== undefined && response.status !== 'OK') {
        this._toaster.errorToastr(response.message, 'Status Change');
      }
    });
  }

  // get privacy settings
  getPrivacySettings() {
    this.spinner.show;
    this.api.getPrivateSettings(this.data.jwt)
      .subscribe(res => {
        this.publicFieldsPrivacy = res.data;
      },
        (err) => {
          this.spinner.hide;
          console.log(err);
          this._toaster.errorToastr('Something Wrong');
        },
        () => {
          this.spinner.hide();
        });
  }

  // save public information
  updatePrivacyInfo() {
    this.updateProfileStatus();
    this.api.updatePrivateSettings(this.data.jwt, this.publicFieldsPrivacy)
      .subscribe(res => {
        // this._toaster.successToastr('Settings Updated');
        this.getPrivacySettings();
        this.ifChange = false;
      },
        (err) => {
          console.log(err);
          // this._toaster.errorToastr('Something Wrong');
        },
        () => {
          console.log('complete');
        });
  }

  // detect if there is any change in the privacy settings
  statusChange(item, publicFieldsPrivacy) {
    this.ifChange = true;
    if (item.value) {
      this.data.userProfileStatusFlag = true;
    } else {
      const arrayWithPrivateFieldsPrivacy = publicFieldsPrivacy.filter((field) => field.value === false);
      if (arrayWithPrivateFieldsPrivacy.length === publicFieldsPrivacy.length) {
        this.data.userProfileStatusFlag = false;
      }
    }
  }

  // delete social account
  deleteEmail(email, connectionType) {
    const result = confirm('Do you want to delete this email?');
    if (result) {
      this.api.deleteAccount(email, connectionType).subscribe((res) => {
        this._toaster.successToastr(`${this.titleCase.transform(connectionType)} account has been deleted successfully`, 'Secondary Account');
        setTimeout(() => {
          this.getSocialAccountsData(this.uuid);
        }, 3000);
      }, (error) => {
        this._toaster.errorToastr(`Something went wrong`, 'Error');
      });
    }
  }

  // add accounts
  addAccount() {
    const connectionType = this.socialConnectForm.get('connectionType').value;
    const email = this.socialConnectForm.get('email').value;
    let toastErrorMessage = '';
    let allowAddingNewConnection = false;

    if (connectionType.toLowerCase() === 'email') {
      // console.log('here');
      const accountWithSelectedEmail = this.socialConnect.filter((account) => account.email === email && account.connectionType === connectionType.toLowerCase())[0];
      if (accountWithSelectedEmail) {
        // console.log('here found email with same addresss');
        toastErrorMessage = 'You can\'t add 2 accounts with same email';
        allowAddingNewConnection = false;
      } else {
        allowAddingNewConnection = true;
      }
    } else {
      // console.log('there');
      const accountWithSelectedConnection = this.socialConnect.filter((account) => account.connectionType === connectionType.toLowerCase())[0];
      if (accountWithSelectedConnection) {
        // console.log('here found email with same connection type');
        toastErrorMessage = `You can\'t add 2 ${connectionType} accounts`;
        allowAddingNewConnection = false;
      } else {
        allowAddingNewConnection = true;
      }
    }

    if (!allowAddingNewConnection) {
        this._toaster.errorToastr(toastErrorMessage, 'Error');
    } else {
      const socialAccount = {
        email: email,
        connectionType: connectionType.toLowerCase(),
        type: 'secondary'
      };
      this.api.addAccount(socialAccount).subscribe((response: any) => {
        if (response.status === 'OK' && response.data !== undefined) {
          this._toaster.successToastr(`${this.titleCase.transform(connectionType)} account has been added successfully`, 'Secondary Account');
          setTimeout(() => {
            this.getSocialAccountsData(this.uuid);
          }, 3000);
          this.formGroupDirective.resetForm();
          this.infoMessageOnConnectionChange = '';
        }
      }, (err) => {
        console.log('error', err);
        if (err.error.error.includes('Email already exists for operation: Add')) {
          this._toaster.errorToastr(`This email is already linked with ${connectionType}`, 'Error');
        } else {
          this._toaster.errorToastr(`Something went wrong`, 'Error');
        }
      });
    }
  }

  getSocialAccountsData(uuidPerson: string) {
    this.api.getAccountsData(uuidPerson).subscribe((response: any) => {
      const socialAccounts = response.data;

      socialAccounts.forEach((socialConnectAccount) => {
        // if (socialConnectAccount.connectionType !== 'profound-impact') {
          const filteredBrand = this.socialMedia.filter((account) => account.name.toUpperCase() === socialConnectAccount.connectionType.toUpperCase())[0];
          socialConnectAccount.icon = filteredBrand.logo;
          socialConnectAccount.color = filteredBrand.color;
          socialConnectAccount.name = filteredBrand.name;
        // }
      });

      socialAccounts.sort((a: any, b: any) => (a.type > b.type ? 1 : -1));

      const unverifiedAccounts = socialAccounts.filter((socialAccount) => socialAccount.isVerified === false);
      if (unverifiedAccounts.length > 0) {
        this.showInfoMsg = true;
      } else {
        this.showInfoMsg = false;
      }

      this.socialConnect = socialAccounts;
      this.dataSource = socialAccounts;
      this.isTableDataLoaded = true;
    });
  }

  onConnectionTypeChange() {
    this.connectionTypeValueSubscription = this.socialConnectForm.get('connectionType').valueChanges.subscribe(val => {
      if (val !== null) {
        if (val.toLowerCase() === 'email') {
          this.infoMessageOnConnectionChange = 'Please Enter Your Secondary Email Address';
        } else {
          this.infoMessageOnConnectionChange = 'Please enter email address associated with your LinkedIn. This will enable you to login to Profound impact site using your LinkedIn credentials';
        }
      }
    });
  }
}
