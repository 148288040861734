import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { ImagePreviewDialogComponent } from 'src/app/shared/components/dialogs/image-preview-dialog/image-preview-dialog.component';
import { ServiceStatus, ServiceResponseMessage } from 'src/app/shared/utilities/app-framework';
import { Escape, Neo4jEscapeUnescapeModule } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, AfterViewInit {
  profileCompletion: any;
  completionWidth: string;
  ariaValue: any;
  imageUploadMessage: string;
  saveDisabled: boolean;
  preSignedurl: any;
  base64textString: string;
  currentCountry: any;
  currentState: any;
  currentCity: any;
  cityDisabled: boolean;
  currentStateID: any;
  countryID: any;
  userFullName: string;
  userEmail: any;
  userProfileStatus: any;
  importedItemUsed = false;
  specialityImported: boolean;
  titleImported: boolean;
  firstNameImported: boolean;
  lastNameImported: boolean;
  displayNameImported: boolean;
  impactStoryImported: boolean;
  countryImported: boolean;
  stateImported: boolean;
  cityImported: boolean;
  postalImported: boolean;
  phoneImported: boolean;
  // bioImported: boolean;

  constructor(
    private userProfileService: UserProfileService,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public _toaster: ToastrManager,
    public _constant: SiteConstants,
    public _constantApi: SiteApiConstants,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private ng2ImgMax: Ng2ImgMaxService,
    private escape: Escape,
    private neo4junescape: Neo4jEscapeUnescapeModule,
    private modalService: NgbModal,
    public data: DataService,
    private api: HttpService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.isMobile = this.data.isMobile;
    this.isTablet = this.data.isTablet;
    this.isDesktop = this.data.isDesktopDevice;
    this.bucket = environment.publicBucket;
    this.jwtToken = this._constantApi.getLoginToken();
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
  }

  bucket: string;
  cognitoUserSub: string;
  idPoolUID: string;
  status: ServiceStatus;
  viewmodel: UserProfile;
  isLoading = true;
  userBasicModel: any = {};
  formData: FormData = new FormData();
  imagePreview: any;
  imagePreviewLoader = false;
  uploadedImage: File;
  imagePrevUrl: any = null;
  uploadFileSel: string;
  files: FileList;
  singleFile: File;
  fileChange = false;
  fileName = '';
  jwtToken = '';
  headers: HttpHeaders;
  fileData: File = null;
  httpClientCallMessage = '';
  selectedUserTab = 1;
  tabs = this.data.profileTabs;
  root: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  countries: any;
  states: any;
  cities: any;
  isStateRequired = false;
  @ViewChild('cityText') cityInputText: ElementRef;
  async ngOnInit() {
    this.isLoading = true;
    this.spinner.show();
    // if (!this.data.currentUser) {
    await this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then((data) => {
        const cognitoUsername = data.username;
        const token = data.signInUserSession.idToken.jwtToken;
        this.data.authenticated =
          data.signInUserSession !== undefined && data.signInUserSession != null
            ? true
            : false;

        if (this.data.authenticated) {
          this.api.getProfileCompletion(token).subscribe((res) => {
            const response: any = res;
            if (response.status === 2 && response.message === 'ForceLogout') {
              Auth.signOut({ global: true }).then(() => { });
              this.data.currentUser = null;
              this.data.authenticated = false;
              this.router.navigate(['/']);
              console.log('PROFILE FORCED LOGOUT');
              // this.modalService.open(LoginComponent, {
              //   windowClass: 'mdModal',
              // });
            }
            this.data.currentUser = response.data.person;
            this.data.logger(response.data.person, 'PROFILE COMPLETION');
            this.profileCompletion = response.data;
            this.data.profileCompletion = this.profileCompletion;
            this.completionWidth = this.profileCompletion.totalCompleted + '%';
            this.data.completionWidth = this.completionWidth;
            this.ariaValue = this.profileCompletion.totalCompleted;
            this.data.ariaValue = this.ariaValue + 1;
            this.userFullName = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
            this.userEmail = this.profileCompletion.person.email;
            this.userProfileStatus = this.profileCompletion.person.profileStatus;
            this.data.jwt = token;
            this.data.profileTabs = [
              {
                name: 'BASIC INFO',
                key: 0,
                active: this.data.currentProfileTab === 0 ? true : false,
                completion: this.data.profileCompletion.basic,
              },
              {
                name: 'Experience',
                key: 1,
                active: this.data.currentProfileTab === 1 ? true : false,
                completion: this.data.profileCompletion.experience,
              },
              {
                name: 'Education',
                key: 2,
                active: this.data.currentProfileTab === 2 ? true : false,
                completion: this.data.profileCompletion.education,
              },
              {
                name: 'Certifications',
                key: 3,
                active: this.data.currentProfileTab === 3 ? true : false,
                completion: this.data.profileCompletion.certification,
              },
              {
                name: 'Volunteer Experience',
                key: 4,
                active: this.data.currentProfileTab === 4 ? true : false,
                completion: this.data.profileCompletion.volunteer,
              },
              {
                name: 'Skills',
                key: 5,
                active: this.data.currentProfileTab === 5 ? true : false,
                completion: this.data.profileCompletion.skill,
              },
              {
                name: 'Languages',
                key: 6,
                active: this.data.currentProfileTab === 6 ? true : false,
                completion: this.data.profileCompletion.language,
              },
              {
                name: 'Publications',
                key: 7,
                active: this.data.currentProfileTab === 7 ? true : false,
                completion: this.data.profileCompletion.publication,
              },
              {
                name: 'Awards',
                key: 8,
                active: this.data.currentProfileTab === 8 ? true : false,
                completion: this.data.profileCompletion.awards,
              },
              {
                name: 'Projects',
                key: 9,
                active: this.data.currentProfileTab === 9 ? true : false,
                completion: this.data.profileCompletion.projects,
              },
              {
                name: 'Research',
                key: 10,
                active: this.data.currentProfileTab === 10 ? true : false,
                completion: this.data.profileCompletion.research,
              },
              {
                name: 'Patents',
                key: 11,
                active: this.data.currentProfileTab === 11 ? true : false,
                completion: this.data.profileCompletion.patent,
              },
              {
                name: 'Academic Ancestry',
                key: 12,
                active: this.data.currentProfileTab === 12 ? true : false,
                completion: this.data.profileCompletion.academicAncestry,
              },
              {
                name: 'Mentoring Relation',
                key: 13,
                active: this.data.currentProfileTab === 13 ? true : false,
                completion: this.data.profileCompletion.mentoringRelation,
              },
              // {
              //   name: 'Mentor',
              //   key: 4,
              //   active: false,
              //   completion: this.data.profileCompletion.mentor
              // },
              // {
              //   name: 'Mentoree',
              //   key: 16,
              //   active: false,
              //   completion: this.data.profileCompletion.mentoree
              // },
              {
                name: 'Social Media',
                key: 14,
                active: this.data.currentProfileTab === 14 ? true : false,
                completion: this.data.profileCompletion.socialMedia,
              },
              {
                name: 'Impact Story',
                key: 15,
                active: this.data.currentProfileTab === 15 ? true : false,
                completion: this.data.profileCompletion.impactStory,
              },
              // {
              //   name: 'Ancestry',
              //   key: 12,
              //   active: false,
              //   completion: this.data.profileCompletion.socialMedia
              // }
            ];
            this.getUserProfile();
            this.tabs = this.data.profileTabs;
          });
        }
      });
    // } else {
    //   this.profileCompletion = this.data.profileCompletion;
    //   this.completionWidth =
    //   this.profileCompletion.totalCompleted + '%';
    //   this.ariaValue = this.profileCompletion.totalCompleted;
    //   this.userFullName = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
    //   this.userEmail = this.profileCompletion.person.email;
    //   this.userProfileStatus = this.profileCompletion.person.profileStatus;
    //   this.init();
    // }
  }

  ngAfterViewInit() {
    if (this.cityInputText !== undefined ) {
      this.renderer.removeAttribute(this.cityInputText.nativeElement, 'aria-multiline');
  }
    setTimeout(() => {
      this.checkTab();
    }, 100);

  }

  onStateChange(state) {
    if (state == -1) {
      this.isStateRequired = true;
    } else {
      this.isStateRequired = false;
    }
  }

  checkTab() {
    if (this.data.currentProfileTab === 0) {
      this.tabChange({
        name: 'BASIC INFO',
        key: 0,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 1) {
      this.tabChange({
        name: 'Experience',
        key: 1,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 2) {
      this.tabChange({
        name: 'Education',
        key: 2,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 3) {
      this.tabChange({
        name: 'Certifications',
        key: 3,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 4) {
      this.tabChange({
        name: 'Volunteer Experience',
        key: 4,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 5) {
      this.tabChange({
        name: 'Skills',
        key: 5,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 6) {
      this.tabChange({
        name: 'Languages',
        key: 6,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 7) {
      this.tabChange({
        name: 'Publications',
        key: 7,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 8) {
      this.tabChange({
        name: 'Awards',
        key: 8,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 9) {
      this.tabChange({
        name: 'Projects',
        key: 9,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 10) {
      this.tabChange({
        name: 'Research',
        key: 10,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 11) {
      this.tabChange({
        name: 'Patents',
        key: 11,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 12) {
      this.tabChange({
        name: 'Academic Ancestry',
        key: 12,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 13) {
      this.tabChange({
        name: 'Mentoring Relation',
        key: 13,
        active: true,
      });
    }
    if (this.data.currentProfileTab === 14) {
      this.tabChange({
        name: 'Social Media',
        key: 14,
        active: true,
      });
    }
  }

  init() {
    this.api.getCountries().subscribe((data) => {
      const response: any = data;
      this.countries = response.data;
      this.countries.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      this.data.logger(this.countries, 'COUNTRIES RESPONSE');
      this.initStates(this.data.currentUser.country);
    });

    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.isLoading = true;
    const res: any = this.data.userProfileData;
    let personData: any;
    this.status = res.status;
    personData = res.data.person;
    let importBasic: any = {
      bio: '',
      bipoc: '',
      lgbt: '',
      city: '',
      country: '',
      dataSource: '',
      displayName: '',
      email: '',
      firstName: '',
      importStatus: '',
      lastName: '',
      logoUrl: '',
      phone: '',
      postalCode: '',
      secondaryEmail: '',
      speciality: '',
      state: '',
      title: '',
      uuid: '',
      uuidPerson: '',
    };
    if (res.status === ServiceStatus.OK) {
      console.log('RES DATA', res.data);
      console.log(res.data.importedBasic);
      if (res.data.importedBasic) {
        console.log('IMPORT BASIC EXISTS');
        importBasic = res.data.importedBasic;
        console.log(importBasic);
      }
      const viewMode = this.userProfileService.assign(
        res.data.person,
        res.data.reside_in_Location,
        res.data.work_in_Organization,
        res.data.volunteer_in_Organization,
        res.data.earns_Award,
        res.data.academic_ancestry,
        res.data.mentoring_relation,
        res.data.study_in_University,
        res.data.work_in_University,
        res.data.wrote_Publication,
        res.data.work_in_Project,
        res.data.obtained_Certificate,
        res.data.focus_on_AreaOfResearch,
        res.data.obtained_Degree,
        res.data.specialized_in_Skill,
        res.data.speaks_Language,
        res.data.have_Patent,
        res.data.connect_to_SocialMedia,
        importBasic
      );
      this.viewmodel = viewMode;
      this.viewmodel.importBasic = importBasic;
      this.data.viewmodel = this.viewmodel;
    }

    console.log('VIEWMODEL', this.viewmodel);

    this.userBasicModel.title = this.viewmodel.person.title
      ? this.viewmodel.person.title
      : this.viewmodel.importBasic.title;
    if (
      !this.viewmodel.person.title &&
      this.viewmodel.importBasic.title !== ''
    ) {
      this.importedItemUsed = true;
      this.titleImported = true;
    }

    this.userBasicModel.firstName = this.viewmodel.person.firstName
      ? this.viewmodel.person.firstName
      : this.viewmodel.importBasic.firstName;
    if (
      !this.viewmodel.person.firstName &&
      this.viewmodel.importBasic.firstName !== ''
    ) {
      this.importedItemUsed = true;
      this.firstNameImported = true;
    }

    this.userBasicModel.lastName = this.viewmodel.person.lastName
      ? this.viewmodel.person.lastName
      : this.viewmodel.importBasic.lastName;
    if (
      !this.viewmodel.person.lastName &&
      this.viewmodel.importBasic.lastName !== ''
    ) {
      this.importedItemUsed = true;
      this.lastNameImported = true;
    }

    this.userBasicModel.logoUrl = this.viewmodel.person.logoUrl
      ? this.viewmodel.person.logoUrl
      : this.viewmodel.importBasic.logoUrl;
    if (
      !this.viewmodel.person.logoUrl &&
      this.viewmodel.importBasic.logoUrl !== ''
    ) {
      this.importedItemUsed = true;
      this.lastNameImported = true;
    }

    this.userBasicModel.displayName = this.viewmodel.person.displayName
      ? this.viewmodel.person.displayName
      : this.viewmodel.importBasic.displayName;
    if (
      !this.viewmodel.person.displayName &&
      this.viewmodel.importBasic.displayName !== ''
    ) {
      this.importedItemUsed = true;
      this.displayNameImported = true;
    }

    this.userBasicModel.bio = this.viewmodel.person.bio
      ? this.viewmodel.person.bio
      : this.viewmodel.importBasic.bio;
    if (!this.viewmodel.person.bio && this.viewmodel.importBasic.bio !== '') {
      this.importedItemUsed = true;
      this.impactStoryImported = true;
    }

    this.userBasicModel.bipoc = this.viewmodel.person.bipoc
      ? this.viewmodel.person.bipoc
      : this.viewmodel.importBasic.bipoc;
    if (!this.viewmodel.person.bipoc && this.viewmodel.importBasic.bipoc !== '') {
      this.importedItemUsed = true;
      this.impactStoryImported = true;
    }

    this.userBasicModel.lgbt = this.viewmodel.person.lgbt
      ? this.viewmodel.person.lgbt
      : this.viewmodel.importBasic.lgbt;
    if (!this.viewmodel.person.lgbt && this.viewmodel.importBasic.lgbt !== '') {
      this.importedItemUsed = true;
      this.impactStoryImported = true;
    }


    this.userBasicModel.speciality = this.viewmodel.person.speciality
      ? this.viewmodel.person.speciality
      : this.viewmodel.importBasic.speciality;
    if (
      !this.viewmodel.person.speciality &&
      this.viewmodel.importBasic.speciality !== ''
    ) {
      this.importedItemUsed = true;
      this.specialityImported = true;
    }

    this.userBasicModel.city = '';
    this.userBasicModel.state = '';
    this.userBasicModel.country = '';

    this.userBasicModel.phone = this.viewmodel.person.phone
      ? this.viewmodel.person.phone
      : this.viewmodel.importBasic.phone;
    if (
      !this.viewmodel.person.phone &&
      this.viewmodel.importBasic.phone !== ''
    ) {
      this.importedItemUsed = true;
      this.phoneImported = true;
    }

    this.userBasicModel.postalCode = this.viewmodel.person.postalCode
      ? this.viewmodel.person.postalCode
      : this.viewmodel.importBasic.postalCode;
    if (
      !this.viewmodel.person.postalCode &&
      this.viewmodel.importBasic.postalCode !== ''
    ) {
      this.importedItemUsed = true;
      this.postalImported = true;
    }

    this.userBasicModel.country = personData.country
      ? personData.country
      : this.viewmodel.importBasic.country;
    if (!personData.country && this.viewmodel.importBasic.country !== '') {
      this.importedItemUsed = true;
      this.countryImported = true;
    }

    this.currentCountry = personData.country
      ? personData.country
      : this.viewmodel.importBasic.country;

    this.userBasicModel.state = personData.state
      ? personData.state
      : this.viewmodel.importBasic.state;

    if (!personData.state && this.viewmodel.importBasic.state !== '') {
      this.importedItemUsed = true;
      this.stateImported = true;
    }

    this.currentState = personData.state
      ? personData.state
      : this.viewmodel.importBasic.state;

    this.userBasicModel.city = personData.city
      ? personData.city
      : this.viewmodel.importBasic.city;

    if (!personData.city && this.viewmodel.importBasic.city !== '') {
      this.importedItemUsed = true;
      this.cityImported = true;
    }

    this.currentCity = personData.city
      ? personData.city
      : this.viewmodel.importBasic.city;

    this.data.userBasicModel = this.userBasicModel;

    this.isLoading = false;
    this.spinner.hide();
    console.log(this.userBasicModel.city);
  }

  getStates(e: any) {
    this.cityDisabled = true;
    this.data.logger(e, 'GET STATES PARAM');
    this.data.logger(this.countries, 'COUNTRIES ARRAY');
    this.userBasicModel.country = e;
    this.countries.forEach((element) => {
      if (element.name === e) {
        this.api.getStates(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'STATES RESPONSE');
          this.states = response.data;
          this.states.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        });
      }
    });
    this.data.logger(this.userBasicModel.country, 'CURRENT COUNTRY');
  }
  initStates(e: any) {
    this.data.logger(e, 'GET STATES PARAM');
    this.userBasicModel.country = e;
    this.countries.forEach((element) => {
      if (element.name === e) {
        this.countryID = element.id;
        this.api.getStates(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'STATES RESPONSE');
          this.states = response.data;
          this.states.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          this.initCities(this.userBasicModel.state);
        });
      }
    });
    this.data.logger(this.userBasicModel.country, 'CURRENT COUNTRY');
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.cities
          : this.cities.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  getCities(e: any) {
    this.data.logger(e, 'GET CITIES PARAM');
    this.userBasicModel.state = e;
    this.currentState = e;
    this.states.forEach((element) => {
      if (element.name === e) {
        this.currentStateID = element.id;
        this.api.getCities(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'CITIES RESPONSE');
          this.cities = response.data;
          this.cities.sort();
          this.cityDisabled = false;
        });
      }
    });
  }

  initCities(e: any) {
    this.data.logger(e, 'GET CITIES PARAM');
    this.states.forEach((element) => {
      if (element.name === e) {
        this.api.getCities(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'CITIES RESPONSE');
          this.cities = response.data;
          this.cities.sort();
          this.cityDisabled = false;
        });
      }
    });
  }

  setCity(e: any) {
    this.userBasicModel.city = e;
    this.currentCity = e;
  }

  getUserProfile() {
    this.api.getUserProfile().subscribe((data) => {
      const res: any = data;
      this.data.logger(res, 'User Profile Response');
      this.data.userProfileData = res;
      if (res.data.importedBasic) {
        this.userBasicModel.importUuid = res.data.importedBasic.uuid;
      }
      this.init();
    });
  }

  // addImageUploadMessage() {
  //   this.imageUploadMessage =
  //     'Please click the SAVE button after uploading a picture.';
  // }

  getCompletionWidth() {
    return this.completionWidth;
  }

  log(e) {
    this.userBasicModel.speciality = this.escape.it(e);
  }

  validateFileExtension(ext: String) {
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  addUserData = async (userData: any) => {
    const routhServPath = 'userAdd';
    const apiUrl = `${this.root}${routhServPath}`;
    return this.http.post<ServiceResponseMessage>(
      apiUrl,
      JSON.stringify(userData),
      { headers: this.headers }
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  importFile(event) {
    const file = event.target.files[0];
    this.fileData = file;
  }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    this.imagePreviewLoader = true;
    reader.onload = () => {
      this.imagePrevUrl = this.getSantizeUrl(reader.result.toString());
      this.imagePreview = true;
      this.imagePreviewLoader = false;
      this.spinnerService.hide();
    };
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  fileChangeEvent(event) {
    this.uploadFileSel = '';
    this.imagePreview = false;
    this.imagePreviewLoader = true;
    this.fileChange = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.data.logger(typeof file, 'TYPE OF FILE');
      this.data.logger(file, 'FILE');
      this.files = file;
      this.singleFile = file;
      const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
      this.fileName = window.btoa(new Date().getTime() + '_profile.' + ext);
      if (file.size > 1000000) {
        this.imagePreviewLoader = false;
        this._toaster.errorToastr('Image size should be 1MB or less', 'Error');
        return false;
      } else if (!this.validateFileExtension(ext)) {
        this.imagePreviewLoader = false;
        this._toaster.errorToastr(
          'Image Type should be jpg, png, jpeg',
          'Error'
        );
        return false;
      } else {
        this.uploadFileSel = file;
        this.spinnerService.show();
        this.ng2ImgMax.resize([file], 150, 100).subscribe((result) => {
          this.uploadedImage = new File([result], result.name);
          this.getImagePreview(this.uploadedImage);
          // this.isImageSelected = true;
        });
      }
    }
  }

  updateTabs(key: number) {
    setTimeout(() => {
      this.tabs = [
        {
          name: 'BASIC INFO',
          key: 0,
          active: this.data.currentProfileTab === 0 ? true : false,
          completion: this.data.profileCompletion.basic,
        },
        {
          name: 'Experience',
          key: 1,
          active: this.data.currentProfileTab === 1 ? true : false,
          completion: this.data.profileCompletion.experience,
        },
        {
          name: 'Education',
          key: 2,
          active: this.data.currentProfileTab === 2 ? true : false,
          completion: this.data.profileCompletion.education,
        },
        {
          name: 'Certifications',
          key: 3,
          active: this.data.currentProfileTab === 3 ? true : false,
          completion: this.data.profileCompletion.certification,
        },
        {
          name: 'Volunteer',
          key: 4,
          active: this.data.currentProfileTab === 4 ? true : false,
          completion: this.data.profileCompletion.volunteer,
        },
        {
          name: 'Skills',
          key: 5,
          active: this.data.currentProfileTab === 5 ? true : false,
          completion: this.data.profileCompletion.skill,
        },
        {
          name: 'Languages',
          key: 6,
          active: this.data.currentProfileTab === 6 ? true : false,
          completion: this.data.profileCompletion.language,
        },
        {
          name: 'Publications',
          key: 7,
          active: this.data.currentProfileTab === 7 ? true : false,
          completion: this.data.profileCompletion.publication,
        },
        {
          name: 'Awards',
          key: 8,
          active: this.data.currentProfileTab === 8 ? true : false,
          completion: this.data.profileCompletion.awards,
        },
        {
          name: 'Projects',
          key: 9,
          active: this.data.currentProfileTab === 9 ? true : false,
          completion: this.data.profileCompletion.projects,
        },
        {
          name: 'Research',
          key: 10,
          active: this.data.currentProfileTab === 10 ? true : false,
          completion: this.data.profileCompletion.research,
        },
        {
          name: 'Patents',
          key: 11,
          active: this.data.currentProfileTab === 11 ? true : false,
          completion: this.data.profileCompletion.patent,
        },
        {
          name: 'Academic Ancestry',
          key: 12,
          active: this.data.currentProfileTab === 12 ? true : false,
          completion: this.data.profileCompletion.academicAncestry,
        },
        {
          name: 'Mentoring Relation',
          key: 13,
          active: this.data.currentProfileTab === 13 ? true : false,
          completion: this.data.profileCompletion.mentoringRelation,
        },
        {
          name: 'Social Media',
          key: 14,
          active: this.data.currentProfileTab === 14 ? true : false,
          completion: this.data.profileCompletion.socialMedia,
        },
        {
          name: 'Impact Story',
          key: 15,
          active: this.data.currentProfileTab === 15 ? true : false,
          completion: this.data.profileCompletion.impactStory,
        }
      ];
      this.selectedUserTab = this.data.currentProfileTab;
    }, 2000);
    this.selectedUserTab = this.data.currentProfileTab;
    console.log('SELECTED USER TAB', this.selectedUserTab);
    console.log('UPDATING TABS');
  }

  saveBasicInfo() {
    this.data.userBasicModel = this.userBasicModel;
    const existingCity = this.cities.includes(this.userBasicModel.city);
    let stateID;
    let countryID;
    if (this.currentStateID) {
      stateID = this.currentStateID;
    } else {
      this.states.forEach((element) => {
        if (element.name === this.currentState) {
          stateID = element.id;
        }
      });
    }
    if (this.countryID) {
      countryID = this.countryID;
    } else {
      this.countries.forEach((element) => {
        if (element.name === this.currentCountry) {
          countryID = element.id;
        }
      });
    }
    if (countryID === -1 || countryID === '-1') {
      countryID = '';
    }
    if (stateID === -1 || stateID === '-1') {
      stateID = '';
    }
    let newCity;
    if (!existingCity) {
      newCity = {
        countryId: countryID,
        stateId: stateID,
        city: this.userBasicModel.city,
      };
    }
    this.data.logger(existingCity, 'CITY ALREADY EXISTS');
    this.data.logger(newCity, 'NEW CITY');
    this.saveDisabled = true;
    setTimeout(() => {
      this.saveDisabled = false;
    }, 2500);
    const personData = this.viewmodel.person;
    const formData = new FormData();
    this.userBasicModel.cognitoUserName = personData.cognitoUserName;
    this.userBasicModel.email = personData.email;
    if (
      this.imagePrevUrl !== undefined &&
      this.imagePrevUrl !== null &&
      this.imagePrevUrl !== ''
    ) {
      this.userBasicModel.avatar = this.fileName;
    } else {
      this.userBasicModel.avatar = this.data.currentUser.logoUrl;
    }
    if (this.fileName !== '') {
      this.userBasicModel.logoUrl = this.fileName;
    } else {
      this.userBasicModel.logoUrl = this.data.currentUser.logoUrl;
    }
    this.data.logger(this.userBasicModel, 'USER BASIC MODLE');
    const apiUrl = `${this.root}/userProfileUpdateBasic?operation=update`;
    const userProfileBasicData = this.http.post<any>(
      apiUrl,
      {
        data: this.userBasicModel,
        newCity: newCity,
      },
      { headers: this.headers }
    );
    // gets the signed url and uploads the user profile to s3
    userProfileBasicData.subscribe((response) => {
      this.importedItemUsed = false;
      this.specialityImported = false;
      this.titleImported = false;
      this.firstNameImported = false;
      this.lastNameImported = false;
      this.displayNameImported = false;
      this.impactStoryImported = false;
      this.countryImported = false;
      this.stateImported = false;
      this.cityImported = false;
      this.postalImported = false;
      this.phoneImported = false;
      if (response !== undefined && response.status === 1) {
        if (this.fileName) {
          this.api.uploadImage(this.fileName).subscribe(
            (data) => {
              const res: any = data;
              this.data.logger(data, 'IMAGE UPLOAD 2.0');
              this.preSignedurl = res.data;
              this.api
                .uploadImageSignedUrl(this.singleFile, this.preSignedurl)
                // tslint:disable-next-line: no-shadowed-variable
                .subscribe((data) => {
                  this.data.logger(data, 'SIGNED URL RESPONSE');
                  this.api.refreshProfileCompletion();
                });
            },
            (error) => {
              this.data.logger(error, 'IMAGE UPLOAD ERROR');
            }
          );
        }
        this._toaster.successToastr(response.message, 'Success');
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.body, 'Error');
      }
    });
  }

  /**
   * Changes the active tab styling and displays the corresponding tab content
   * @param selectedTab The current selected tab
   */
  tabChange(selectedTab) {
    this.selectedUserTab = selectedTab.key;
    this.data.currentProfileTab = selectedTab.key;
    for (const tab of this.tabs) {
      if (tab.key === selectedTab.key) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    }
  }

  chooseBIPOC(e) {
    this.userBasicModel.bipoc = e
  }

  chooseLGBT(e) {
    this.userBasicModel.lgbt = e
  }
}

