import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NodeRef, ServiceStatus } from '../../../utilities/app-framework';
import { Organization } from '../../../utilities/app-db-nodes';
import { EmploymentEdge } from '../../../utilities/app-db-edges';
import { UserProfile } from '../../../utilities/user-profile.viewmodel';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { UserProfileService } from 'src/app/pages/profile/user-profile.service';

@Component({
  selector: 'app-user-profile-static',
  templateUrl: './user-profile-static.component.html',
  styleUrls: ['./user-profile-static.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [UserProfileService],
})
export class UserProfileStaticComponent {
  status: ServiceStatus;
  viewmodel: any;
  isLoading = true;
  isDisplayAll = false;
  chartType: string;
  current_WORK_IN_Organization: {
    edge: EmploymentEdge;
    toNodeRef: NodeRef;
    toNode: Organization;
  };
  bucket: any;
  person: any;

  constructor(
    private userProfileService: UserProfileService,
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private data: DataService
  ) {}

  initialize(userData: any, isDisplayAll: boolean, chartType: string) {
    // console.log('I am initializing modal');

    this.isDisplayAll = isDisplayAll;
    this.bucket = environment.publicBucket;
    this.chartType = chartType;
    this.isLoading = true;
    this.spinner.show();
    this.person = {
      logoUrl: userData.logoUrl,
      profileStatus: userData.profileStatus,
      displayName: userData.displayName,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };
    this.viewmodel = {
      person: this.person,
    };
    // this.viewmodel.person.logoUrl = userData.logoUrl;
    // this.viewmodel.person.profileStatus = userData.profileStatus;
    // this.viewmodel.person.displayName = userData.displayName;
    // this.viewmodel.person.firstName = userData.firstName;
    // this.viewmodel.person.lastName = userData.lastName;
    this.isLoading = false;
    this.spinner.hide();

    // this.amplifyService.auth().currentAuthenticatedUser().then(data => {

    //   const token = data.signInUserSession.idToken.jwtToken;

    //   try {
    //     this.userProfileService.doSearchProfile(token).subscribe(res => {
    //       this.status = res.status;
    //       this.data.logger(res, 'PERSON CLICKED');
    //       if (res.status === ServiceStatus.OK) {
    //         const viewMode = this.userProfileService.assign(
    //           res.data.person,
    //           res.data.reside_in_Location,
    //           res.data.work_in_Organization,
    //           res.data.earns_Award,
    //           res.data.mentor_Person,
    //           res.data.study_in_University,
    //           res.data.work_in_University,
    //           res.data.wrote_Publication,
    //           res.data.work_in_Project,
    //           res.data.obtained_Certificate,
    //           res.data.focus_on_AreaOfResearch,
    //           res.data.obtained_Degree,
    //           res.data.specialized_in_Skill,
    //           res.data.speaks_Language,
    //           res.data.have_Patent,
    //           res.data.connect_to_SocialMedia,
    //           res.data.mentor_to_Person
    //         );
    //         this.viewmodel = viewMode;
    //       }

    //       this.isLoading = false;
    //       this.spinner.hide();
    //     }
    //       , err => {
    //         this.spinner.hide();
    //       }
    //     );
    //   } catch (err) {
    //     this.isLoading = false;
    //     this.spinner.hide();
    //   }
    // });
  }
}
