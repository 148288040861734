import { BAD_REQUEST_ERR_MSG } from './config';

export class Escape {

    constructor() {
    }

    /**
    * Escapes HTML entities from a string.
    * @param value The value
    * @param arg The argument
    * @param options The options
    * @return The string returned
    */
    it(value: any, arg?: string | number) {
        if (value === null || value === undefined) {
            throw BAD_REQUEST_ERR_MSG;
        }
        value = value.toString();
        return this.escapeString(value);

        throw BAD_REQUEST_ERR_MSG;
    }

    /**
    * Escapes HTML entities from a string.
    * @param rawInput The raw input
    * @return The string returned
    */
    escapeString(rawInput: string): string {
        let escaped = rawInput;
        // escaped = escaped.replace(/&/g, '&#x26;');  // &
        // escaped = escaped.replace(/`/g, '&#x60;');  // `
        // escaped = escaped.replace(/'/g, '&#x27;');  // '
        // escaped = escaped.replace(/"/g, '&#x22;');  // ":"
        // escaped = escaped.replace(/\//g, '&#x2F;'); // /
        // escaped = escaped.replace(/\\/g, '&#x5C;'); // \
        // escaped = escaped.replace(/\n/g, '&#x0A;'); // (new line)
        return escaped;
    }
}
