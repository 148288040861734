import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { ImagePreviewDialogComponent } from 'src/app/shared/components/dialogs/image-preview-dialog/image-preview-dialog.component';
import { ServiceStatus, ServiceResponseMessage } from 'src/app/shared/utilities/app-framework';
import { Escape, Neo4jEscapeUnescapeModule } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';


@Component({
  selector: 'app-impact-story',
  templateUrl: './impact-story.component.html',
  styleUrls: ['./impact-story.component.scss']
})
export class ImpactStoryComponent implements OnInit {
  profileCompletion: any;
  completionWidth: string;
  ariaValue: any;
  imageUploadMessage: string;
  saveDisabled: boolean;
  preSignedurl: any;
  base64textString: string;
  currentCountry: any;
  currentState: any;
  currentCity: any;
  cityDisabled: boolean;
  currentStateID: any;
  countryID: any;
  userFullName: string;
  userEmail: any;
  userProfileStatus: any;
  importedItemUsed = false;
  specialityImported: boolean;
  titleImported: boolean;
  firstNameImported: boolean;
  lastNameImported: boolean;
  displayNameImported: boolean;
  impactStoryImported: boolean;
  countryImported: boolean;
  stateImported: boolean;
  cityImported: boolean;
  postalImported: boolean;
  phoneImported: boolean;
  bioImported: boolean;
  root: string
  constructor(
    private userProfileService: UserProfileService,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public _toaster: ToastrManager,
    public _constant: SiteConstants,
    public _constantApi: SiteApiConstants,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private ng2ImgMax: Ng2ImgMaxService,
    private escape: Escape,
    private neo4junescape: Neo4jEscapeUnescapeModule,
    private modalService: NgbModal,
    public data: DataService,
    private api: HttpService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.isMobile = this.data.isMobile;
    this.isTablet = this.data.isTablet;
    this.isDesktop = this.data.isDesktopDevice;
    this.bucket = environment.publicBucketUrl;
    this.jwtToken = this._constantApi.getLoginToken();
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
  }

  bucket: string;
  cognitoUserSub: string;
  idPoolUID: string;
  isLoading = true;
  files: FileList;
  singleFile: File;
  fileChange = false;
  fileName = '';
  jwtToken = '';
  tabs = this.data.profileTabs;
  headers: HttpHeaders;
  fileData: File = null;
  httpClientCallMessage = '';
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  bio: string

  ngOnInit() {
    this.isLoading = true;
    this.spinner.show();
    this.root = environment.baseUrl

    if(this.data.userProfileData.data.person.bio) {
      this.bio = this.data.userProfileData.data.person.bio
    }
  }
  
  updateTabs() {
    this.api.getProfileCompletion(this.data.jwt).subscribe((res) => {
      const response: any = res;
      this.data.profileCompletion = response.data;
      setTimeout(() => {
        this.data.profileTabs = [
          {
            name: 'BASIC INFO',
            key: 0,
            active: this.data.currentProfileTab === 0 ? true : false,
            completion: this.data.profileCompletion.basic,
          },
          {
            name: 'Experience',
            key: 1,
            active: this.data.currentProfileTab === 1 ? true : false,
            completion: this.data.profileCompletion.experience,
          },
          {
            name: 'Education',
            key: 2,
            active: this.data.currentProfileTab === 2 ? true : false,
            completion: this.data.profileCompletion.education,
          },
          {
            name: 'Certifications',
            key: 3,
            active: this.data.currentProfileTab === 3 ? true : false,
            completion: this.data.profileCompletion.certification,
          },
          {
            name: 'Volunteer',
            key: 4,
            active: this.data.currentProfileTab === 4 ? true : false,
            completion: this.data.profileCompletion.volunteer,
          },
          {
            name: 'Skills',
            key: 5,
            active: this.data.currentProfileTab === 5 ? true : false,
            completion: this.data.profileCompletion.skill,
          },
          {
            name: 'Languages',
            key: 6,
            active: this.data.currentProfileTab === 6 ? true : false,
            completion: this.data.profileCompletion.language,
          },
          {
            name: 'Publications',
            key: 7,
            active: this.data.currentProfileTab === 7 ? true : false,
            completion: this.data.profileCompletion.publication,
          },
          {
            name: 'Awards',
            key: 8,
            active: this.data.currentProfileTab === 8 ? true : false,
            completion: this.data.profileCompletion.awards,
          },
          {
            name: 'Projects',
            key: 9,
            active: this.data.currentProfileTab === 9 ? true : false,
            completion: this.data.profileCompletion.projects,
          },
          {
            name: 'Research',
            key: 10,
            active: this.data.currentProfileTab === 10 ? true : false,
            completion: this.data.profileCompletion.research,
          },
          {
            name: 'Patents',
            key: 11,
            active: this.data.currentProfileTab === 11 ? true : false,
            completion: this.data.profileCompletion.patent,
          },
          {
            name: 'Academic Ancestry',
            key: 12,
            active: this.data.currentProfileTab === 12 ? true : false,
            completion: this.data.profileCompletion.academicAncestry,
          },
          {
            name: 'Mentoring Relation',
            key: 13,
            active: this.data.currentProfileTab === 13 ? true : false,
            completion: this.data.profileCompletion.mentoringRelation,
          },
          {
            name: 'Social Media',
            key: 14,
            active: this.data.currentProfileTab === 14 ? true : false,
            completion: this.data.profileCompletion.socialMedia,
          },
          {
            name: 'Impact Story',
            key: 15,
            active: this.data.currentProfileTab === 15 ? true : false,
            completion: this.data.profileCompletion.impactStory,
          }
        ];
      }, 1000);
    })
    
  }

  updateImpactStory(){
    const apiUrl = `${this.root}/userProfileUpdateBasic?operation=updateImpactStatement`;
    this.http.post<any>(
      apiUrl,
      {
        data: {bio: this.bio},
      },
      { headers: this.headers }
    ).subscribe(res => {
      console.log(res)
      this.updateTabs()
      this._toaster.successToastr(res.message, 'Success')
    },
    (err) => {
      this._toaster.errorToastr("Error")
    })
  }

}
