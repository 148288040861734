<main-i *ngIf="!offlineStatus">
    <button (click)="getSignature()" [ngClass]="{zoomDisabled: !authentication}" [disabled]="joinMeeting"
        [ngClass]="{'bg-light text-dark': (joinMeeting), 'bg-primary text-white': joinButton}">
        {{buttonText}}
    </button>
    <p class="text-danger mt-4">{{msg}}</p>
</main-i>

<main *ngIf="offlineStatus">
    <img class="event-image" onError="this.onerror=null;this.src='assets/images/pure-white-background.jpg'"
        src="https://{{bucket}}.s3.amazonaws.com/live-stream-uploads/{{offlineImage}}"><br>
    <button (click)="getResgister()" [ngClass]="{zoomDisabled: !authentication}" [disabled]="joinMeeting"
        [ngClass]="{'bg-light text-dark': (joinMeeting), 'bg-primary text-white': joinButton}" style="padding-left: 23px;padding-right: 23px;text-decoration: underline;">
        Register Now
    </button> 
</main>