<div id="profile-section-skills" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Skills</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add skill" role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div class="profile__section__empty"
        *ngIf="!viewmodel.specialized_in_Skill || viewmodel.specialized_in_Skill.length === 0">
        Click + to add first skill.
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="skillsList">
        <div class="profile__section__list--item" *ngFor="let item of skillsList; index as i;">
          <div *ngIf="item.name != 'NONE' || skillsList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-12" role="list item property value">
                <span>Skill:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12" role="list item property value">
                <div *ngIf="item.yearsOfExperience">
                  <span>Experience:</span>
                  <span class="fw500 pl05r">
                    {{ item.yearsOfExperience }}
                    {{ item.yearsOfExperience > 1 ? 'years' : 'year' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || skillsList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit skill button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete skill button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Skills</div>
        <div *ngIf="skillModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- SKILL -->
        <div class="profile__form--skill">
          <label class="form__label">Skill <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name"
            [ngModel]="skillModel.name" placeholder="Ex. Project Management"
            (ngModelChange)="skillModel.name = this.escape.it($event)" #toNode_name="ngModel" [disabled]="deleteMode"
            aria-label="skill - required"   />
        </div>
        <!-- EXPERIENCE -->
        <div class="profile__form--experience">
          <label class="form__label">Years of Experience <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_edge_yearsOfExperience" type="number"
            min="1" max="99"
            name="currentItem_edge_yearsOfExperience" placeholder="Ex. 15 (1 - 99)"
            [(ngModel)]="skillModel.yearsOfExperience" (ngModelChange)="checkExperience($event)" #yearsOfExperience="ngModel"
            [disabled]="deleteMode"
            aria-label="years of experience - required"  
            [ngClass]="{'ng-invalid': (yearsOfExperience.dirty || yearsOfExperience.touched) && invalidExperience}" />
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && skillModel.dataSource === 'User') ||
                (thisForm.form.invalid && skillModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-skills" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Skill is required</strong>
        </div>
        <div class="row tac profile__form--error-experience-req" *ngIf="yearsOfExperience.invalid && (yearsOfExperience.dirty || yearsOfExperience.touched)">
          <strong *ngIf="yearsOfExperience.errors.required">Experience is required</strong>
        </div>
        <div class="row tac profile__form--error-experience-range" *ngIf="(yearsOfExperience.dirty || yearsOfExperience.touched)">
          <strong *ngIf="invalidExperience">
            Experience should be in between 1 and 99.
          </strong>
        </div>
      </form>
    </div>
  </div>
</div>
