import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ResearchEdge } from 'src/app/shared/utilities/app-db-edges';
import { AreaOfResearch } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants]
})
export class ResearchComponent implements OnInit {

  @ViewChild('card') card: ElementRef;
  viewmodel = this.data.viewmodel;
  researchList = [];
  flipCard = false;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  noneDisabled: boolean;
  titles: any;
  names: any;
  invalidExperience: boolean;
  unverified: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    private escape: Escape,
    public data: DataService,
    private api: HttpService,
    private util: UtilityService
  ) { }

  status: ServiceStatus;
  newItem: {
    edge: ResearchEdge;
    toNodeRef: NodeRef;
    toNode: AreaOfResearch;
  };
  currentItem: {
    edge: ResearchEdge;
    toNodeRef: NodeRef;
    toNode: AreaOfResearch;
  };
  researchModel: any = {
    name: '',
    yearsOfExperience: null,
    title: '',
    category: 'Select',
    position: 'Select',
    principalInvestigator: '',
    coInvestigator: '',
    currentlyWorking: true,
    startDate: {
      year: '',
      month: '',
      day: '',
    },
    endDate: {
      year: '',
      month: '',
      day: '',
    },
    startDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any;
  root: string;
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  startDayValue: any[] = [];
  endDayValue: any[] = [];
  startYearValue: any[] = [];
  endYearValue: any[] = [];
  endMonthValue: any[] = [];
  currentlyWorking = true;
  isMobile: boolean;
  categories: any;
  positions: any;
  deleteMode = false;
  isStartYearRequired = false;
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.getSuggestions();

    this.api.getDropdowns('ResearchCategory').subscribe(
      (data) => {
        const response: any = data;
        this.categories = response.data;
      },
      (error) => {
        this.util.logger(error, 'Research Category Dropdown Error');
      }
    );

    this.api.getDropdowns('ResearchPosition').subscribe(
      (data) => {
        const response: any = data;
        this.positions = response.data;
      },
      (error) => {
        this.util.logger(error, 'Research Position Dropdown Error');
      }
    );

    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getResearchList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
  }

  flip() {
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getSuggestions() {
    this.api.getSuggestions('research').subscribe((data) => {
      const response: any = data;
      this.titles = response.data.title;
      this.names = response.data.name;
    });
  }

  titleSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.titles
          : this.titles.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  nameSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.names
          : this.names.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  checkExperience(experience: any) {
    console.log(experience);
    const experienceString = experience + '';
    // tslint:disable-next-line: max-line-length
    if (experience !== null) {
      if (
        experienceString.includes('-') ||
        experience >= 100 ||
        experience <= 0
      ) {
        this.invalidExperience = true;
      } else {
        this.invalidExperience = false;
      }
    } else {
      this.invalidExperience = true;
    }
  }

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.researchModel.title = '';
    this.researchModel.name = 'NONE';
    this.researchModel.yearsOfExperience = '';
    this.researchModel.category = '';
    this.researchModel.position = '';
    this.researchModel.principalInvestigator = '';
    this.researchModel.coInvestigator = '';
    this.researchModel.currentlyWorking = false;
    this.researchModel.startDate.year = '';
    this.researchModel.startDate.month = '';
    this.researchModel.startDate.day = '';
    this.researchModel.endDate.year = '';
    this.researchModel.endDate.month = '';
    this.researchModel.endDate.day = '';
    this.saveClick();
  }

  onDateChange(field: any, input: any, startYear) {
    if (startYear === '') {
      this.isStartYearRequired = true;
    } else {
      this.isStartYearRequired = false
    }
    const thisYear = new Date().getFullYear();
    if (field === 'startDate') {
      if (input === 'year') {
        if (this.researchModel.startDate.year !== '') {
          this.researchModel.endDate.year = '';
          this.researchModel.endDate.month = '';
          this.researchModel.endDate.day = '';
          this.researchModel.startDate.month = '';
          this.researchModel.startDate.day = '';
          this.endYearValue = [];
          for (let i = this.researchModel.startDate.year; i <= thisYear; i++) {
            this.endYearValue.push({ value: i });
          }
        } else {
          console.log('START DATE YEAR', this.researchModel.startDate.year);
          this.yearValue = [];
          this.endYearValue = [];
          this.monthValue = [];
          this.endMonthValue = [];
          this.endDayValue = [];
          this.startDayValue = [];
          this.dayValue = [];
          for (let i = this.currentYear; i >= 1930; i--) {
            this.yearValue.push({ value: i });
            this.endYearValue.push({ value: i });
          }
          for (let i = 1; i <= 12; i++) {
            this.monthValue.push({ value: i });
            this.endMonthValue.push({ value: i });
          }
          for (let i = 1; i <= 31; i++) {
            this.dayValue.push({ value: i });
            this.startDayValue.push({ value: i });
            this.endDayValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.startDayValue = [];
        for (
          let i = 1;
          i <= this.data.days[this.researchModel.startDate.month - 1];
          i++
        ) {
          this.startDayValue.push({ value: i });
        }
        if (
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.researchModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
        this.researchModel.startDate.day = '';
      }
      if (input === 'day') {
        // tslint:disable-next-line: max-line-length
        if (
          this.researchModel.startDate.month ===
          this.researchModel.endDate.month &&
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.researchModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.researchModel.startDate.day;
            i < this.data.days[this.researchModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (field === 'endDate') {
      if (input === 'year') {
        this.researchModel.endDate.month = '';
        this.researchModel.endDate.day = '';
        if (
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (
            let i = this.researchModel.startDate.month
              ? this.researchModel.startDate.month
              : 1;
            i <= 12;
            i++
          ) {
            this.endMonthValue.push({ value: i });
          }
        } else {
          this.endMonthValue = [];
          for (let i = 1; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.endDayValue = [];
        // tslint:disable-next-line: max-line-length
        if (
          this.researchModel.startDate.month ===
          this.researchModel.endDate.month &&
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.researchModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.researchModel.startDate.day;
            i < this.data.days[this.researchModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        } else {
          this.endDayValue = [];
          for (
            let i = 1;
            i <= this.data.days[this.researchModel.endDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i });
          }
        }
      }
    }
    const flag = this.researchModel[field][input] !== '' ? 1 : 0;
    this.researchModel[field + 'Flag'][input] = flag;
  }

  toggleCurrentlyWorking() {
    this.currentlyWorking = !this.currentlyWorking;
    this.researchModel.currentlyWorking = this.currentlyWorking;
  }

  getResearchList() {
    this.researchList = [];
    const apiUrl = `${this.root}/userProfileUpdateAreaOfResearch?operation=list`;
    const getResearchList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getResearchList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORD
        this.viewmodel.focus_on_AreaOfResearch = [];
        const tempCurrentlyWorking = [];
        const tempEndDate = [];
        const tempExperience = [];
        for (let i = 0; i < response.record.length; i++) {
          if (response.record[i].row[0].currentlyWorking) {
            tempCurrentlyWorking.push(response.record[i]);
          } else {
            tempEndDate.push(response.record[i]);
          }
        }
        tempCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        tempEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
            (a.row[0].endDate.year !== b.row[0].endDate.year
              ? b.row[0].endDate.year
              : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of tempCurrentlyWorking) {
          tempExperience.push(item);
        }
        for (const item of tempEndDate) {
          tempExperience.push(item);
        }
        response.record = tempExperience;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            yearsOfExperience: response.record[item].row[0].yearsOfExperience,
            title: response.record[item].row[0].title
              ? response.record[item].row[0].title
              : '',
            category: response.record[item].row[0].category
              ? response.record[item].row[0].category
              : '',
            position: response.record[item].row[0].position
              ? response.record[item].row[0].position
              : '',
            // tslint:disable-next-line: max-line-length
            principalInvestigator: response.record[item].row[0]
              .principalInvestigator
              ? response.record[item].row[0].principalInvestigator
              : '',
            coInvestigator: response.record[item].row[0].coInvestigator
              ? response.record[item].row[0].coInvestigator
              : '',
            // tslint:disable-next-line: max-line-length
            startDate: response.record[item].row[0].startDate
              ? new Date(
                response.record[item].row[0].startDate.year,
                response.record[item].row[0].startDate.month - 1,
                response.record[item].row[0].startDate.day
              )
              : '',
            // tslint:disable-next-line: max-line-length
            endDate: response.record[item].row[0].endDate
              ? new Date(
                response.record[item].row[0].endDate.year,
                response.record[item].row[0].endDate.month - 1,
                response.record[item].row[0].endDate.day
              )
              : '',
            // tslint:disable-next-line: max-line-length
            startDateFlag: response.record[item].row[0].startDate
              ? {
                year: response.record[item].row[0].startDate.year,
                month: response.record[item].row[0].startDate.month,
                day: response.record[item].row[0].startDate.day,
              }
              : '',
            // tslint:disable-next-line: max-line-length
            endDateFlag: response.record[item].row[0].endDate
              ? {
                year: response.record[item].row[0].endDate.year,
                month: response.record[item].row[0].endDate.month,
                day: response.record[item].row[0].endDate.day,
              }
              : '',
            dataSource: response.record[item].row[0].dataSource,
            currentlyWorking: response.record[item].row[0].currentlyWorking
              ? response.record[item].row[0].currentlyWorking
              : false,
            status: 'verified'
          };
          if (temp.currentlyWorking) {
            this.viewmodel.focus_on_AreaOfResearch.unshift(temp);
            this.researchList.unshift(temp);
          } else {
            this.viewmodel.focus_on_AreaOfResearch.push(temp);
            this.researchList.push(temp);
          }
        }
        // UNVERIFIED IMPORTED RECORDS
        this.viewmodel.unverified_Focus_on_AreaOfResearch = [];
        const UNVERIFIEDCurrentlyWorking = [];
        const UNVERIFIEDEndDate = [];
        const UNVERIFIEDExperience = [];
        console.log('RESPONSE UNVERIFIED', response.unverified);
        for (let i = 0; i < response.unverified.length; i++) {
          if (response.unverified[i].row[0].currentlyWorking) {
            UNVERIFIEDCurrentlyWorking.push(response.unverified[i]);
          } else {
            UNVERIFIEDEndDate.push(response.unverified[i]);
          }
        }
        UNVERIFIEDCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        UNVERIFIEDEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
            (a.row[0].endDate.year !== b.row[0].endDate.year
              ? b.row[0].endDate.year
              : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of UNVERIFIEDCurrentlyWorking) {
          UNVERIFIEDExperience.push(item);
        }
        for (const item of UNVERIFIEDEndDate) {
          UNVERIFIEDExperience.push(item);
        }
        response.unverified = UNVERIFIEDExperience;
        console.log('RESPONSE UNVERIFIED', response.unverified);
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const unverifiedTemp: any = {
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            yearsOfExperience:
              response.unverified[item].row[0].yearsOfExperience,
            title: response.unverified[item].row[0].title
              ? response.unverified[item].row[0].title
              : '',
            category: response.unverified[item].row[0].category
              ? response.unverified[item].row[0].category
              : '',
            position: response.unverified[item].row[0].position
              ? response.unverified[item].row[0].position
              : '',
            // tslint:disable-next-line: max-line-length
            principalInvestigator: response.unverified[item].row[0]
              .principalInvestigator
              ? response.unverified[item].row[0].principalInvestigator
              : '',
            coInvestigator: response.unverified[item].row[0].coInvestigator
              ? response.unverified[item].row[0].coInvestigator
              : '',
            // tslint:disable-next-line: max-line-length
            startDate: response.unverified[item].row[0].startDate
              ? new Date(
                response.unverified[item].row[0].startDate.year,
                response.unverified[item].row[0].startDate.month - 1,
                response.unverified[item].row[0].startDate.day
              )
              : '',
            // tslint:disable-next-line: max-line-length
            endDate: response.unverified[item].row[0].endDate
              ? new Date(
                response.unverified[item].row[0].endDate.year,
                response.unverified[item].row[0].endDate.month - 1,
                response.unverified[item].row[0].endDate.day
              )
              : '',
            // tslint:disable-next-line: max-line-length
            startDateFlag: response.unverified[item].row[0].startDate
              ? {
                year: response.unverified[item].row[0].startDate.year,
                month: response.unverified[item].row[0].startDate.month,
                day: response.unverified[item].row[0].startDate.day,
              }
              : '',
            // tslint:disable-next-line: max-line-length
            endDateFlag: response.unverified[item].row[0].endDate
              ? {
                year: response.unverified[item].row[0].endDate.year,
                month: response.unverified[item].row[0].endDate.month,
                day: response.unverified[item].row[0].endDate.day,
              }
              : '',
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            currentlyWorking: response.unverified[item].row[0].currentlyWorking
              ? response.unverified[item].row[0].currentlyWorking
              : false,
            status: 'unverified'
          };
          if (unverifiedTemp.currentlyWorking) {
            this.viewmodel.unverified_Focus_on_AreaOfResearch.unshift(unverifiedTemp);
            this.researchList.unshift(unverifiedTemp);
          } else {
            this.viewmodel.unverified_Focus_on_AreaOfResearch.push(unverifiedTemp);
            this.researchList.push(unverifiedTemp);
          }
        }
      }
      console.log('VIEW MODEL', this.viewmodel);
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        yearsOfExperience: null,
        title: '',
        name: '',
        category: 'Select',
        position: 'Select',
        principalInvestigator: '',
        coInvestigator: '',
        currentlyWorking: true,
        startDate: '',
        endDate: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        logoUrl: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log(
      'VIEW MODEL RESEARCH: ',
      this.viewmodel.focus_on_AreaOfResearch
    );
    if (
      this.viewmodel.focus_on_AreaOfResearch.length === 1 &&
      this.viewmodel.focus_on_AreaOfResearch[0].name === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.currentlyWorking = true;
    this.researchModel = {
      name: '',
      yearsOfExperience: null,
      title: '',
      category: 'Select',
      position: 'Select',
      principalInvestigator: '',
      coInvestigator: '',
      currentlyWorking: true,
      startDate: {
        year: '',
        month: '',
        day: '',
      },
      endDate: {
        year: '',
        month: '',
        day: '',
      },
      startDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
      endDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    this.deleteMode = false;
    if (item.importStatus) {
      this.researchModel.importStatus = item.importStatus;
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.researchModel = { ...item };
    this.researchModel.edgeUuid = item.uuid;
    this.researchModel.currentlyWorking = item.currentlyWorking;
    this.researchModel.dataSource = item.dataSource;
    this.currentlyWorking = item.currentlyWorking;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = '';
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = '';
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.researchModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.researchModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    (this.researchModel.category = item.category ? item.category : 'Select'),
      (this.researchModel.position = item.position ? item.position : 'Select'),
      (this.currentUIAction = UIAction.Edit);
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.researchModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (
        this.researchModel.startDate.year === this.researchModel.endDate.year
      ) {
        this.endMonthValue = [];
        for (let i = this.researchModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.researchModel.startDate.month ===
        this.researchModel.endDate.month &&
        this.researchModel.startDate.year === this.researchModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.researchModel.startDate.day;
          i < this.data.days[this.researchModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
  }

  saveClick() {
    if (this.researchModel.currentlyWorking) {
      this.researchModel.endDate = {
        day: '',
        month: '',
        year: '',
      };
    }
    if (this.researchModel.name === 'NONE') {
      this.researchModel.title = '';
      this.researchModel.yearsOfExperience = '';
      this.researchModel.category = '';
      this.researchModel.position = '';
      this.researchModel.principalInvestigator = '';
      this.researchModel.coInvestigator = '';
      this.researchModel.currentlyWorking = false;
      this.researchModel.startDate.year = '';
      this.researchModel.startDate.month = '';
      this.researchModel.startDate.day = '';
      this.researchModel.endDate.year = '';
      this.researchModel.endDate.month = '';
      this.researchModel.endDate.day = '';
    }
    if (this.researchModel.category === 'Select') {
      this.researchModel.category = '';
    }
    if (this.researchModel.position === 'Select') {
      this.researchModel.position = '';
    }
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateAreaOfResearch?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.researchModel, edgeUuid: this.researchModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(
              'Area of Research updated successfully',
              'Success'
            );
            this.flipDiv = false;
            this.getResearchList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(
              'Area of Research failed to update',
              'Error'
            );
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const data: any = { data: this.researchModel };
      const apiUrl = `${this.root}/userProfileUpdateAreaOfResearch?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.researchModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(
              'Area of Research added successfully',
              'Success'
            );
            this.flipDiv = false;
            this.getResearchList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(
              'Area of Research failed to add',
              'Error'
            );
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    if (this.researchModel.name != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.focus_on_AreaOfResearch[0]);
    // }
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.researchModel.importStatus = item.importStatus;
    }
    this.currentItem = item;
    this.researchModel = { ...item };
    this.researchModel.edgeUuid = item.uuid;
    if (!this.noneExists) {
      this.currentlyWorking = item.currentlyWorking;
      this.researchModel.startDate = {
        day: item.startDateFlag.day ? item.startDate.getDate() : '',
        month: item.startDateFlag.month ? item.startDateFlag.month : '',
        year: item.startDateFlag.year ? item.startDateFlag.year : '',
      };
      this.researchModel.endDate = {
        day: item.endDateFlag.day ? item.endDate.getDate() : '',
        month: item.endDateFlag.month ? item.endDateFlag.month : '',
        year: item.endDateFlag.year ? item.endDateFlag.year : '',
      };
      this.researchModel.currentlyWorking = item.currentlyWorking
        ? item.currentlyWorking
        : false;
      (this.researchModel.category = item.category ? item.category : 'Select'),
        (this.researchModel.position = item.position
          ? item.position
          : 'Select'),
        (this.currentUIAction = UIAction.Delete);
      this.nodeEditable = false;
      this.edgeEditable = false;
      this.displaySaveButton = false;
      this.displayDeleteButton = true;
      this.enableDeleteButton = true;
      this.yearValue = [];
      this.endYearValue = [];
      this.monthValue = [];
      this.endMonthValue = [];
      this.dayValue = [];
      this.startDayValue = [];
      this.endDayValue = [];
      for (let i = this.currentYear; i >= 1930; i--) {
        this.yearValue.push({ value: i });
        this.endYearValue.push({ value: i });
      }
      for (let i = 1; i <= 12; i++) {
        this.monthValue.push({ value: i });
        this.endMonthValue.push({ value: i });
      }
      for (let i = 1; i <= 31; i++) {
        this.dayValue.push({ value: i });
        this.startDayValue.push({ value: i });
        this.endDayValue.push({ value: i });
      }
      const thisYear = new Date().getFullYear();
      if (!item.currentlyWorking) {
        this.endYearValue = [];
        for (let i = this.researchModel.startDate.year; i <= thisYear; i++) {
          this.endYearValue.push({ value: i });
        }
        if (
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.researchModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
        // tslint:disable-next-line: max-line-length
        if (
          this.researchModel.startDate.month ===
          this.researchModel.endDate.month &&
          this.researchModel.startDate.year === this.researchModel.endDate.year
        ) {
          this.endDayValue = [];
          for (
            let i = +this.researchModel.startDate.day;
            i < this.data.days[this.researchModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (this.noneExists) {
      this.deleteClick();
    }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateAreaOfResearch?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.researchModel, edgeUuid: this.researchModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(
            'Area of Research removed successfully',
            'Success'
          );
          this.flipDiv = false;
          this.getResearchList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(
            'Area of Research failed to remove',
            'Error'
          );
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 500);
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  clearData() {
    console.log('IM IN CLEAR DATA');
    this.researchModel.name = '';
    this.researchModel.yearsOfExperience = 0;
    this.researchModel.title = '';
    this.researchModel.category = 'Select';
    this.researchModel.position = 'Select';
    this.researchModel.principalInvestigator = '';
    this.researchModel.coInvestigator = '';
    this.researchModel.currentlyWorking = true;
    console.log('NONE EXISTS: ', this.noneExists);
    if (!this.noneExists) {
      this.researchModel.startDate.year = '';
      this.researchModel.startDate.month = '';
      this.researchModel.startDate.day = '';
      this.researchModel.endDate.year = '';
      this.researchModel.endDate.month = '';
      this.researchModel.endDate.day = '';
    }
    this.noneExists = false;
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }
}
