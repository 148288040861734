<div id="profile-section-projects" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Projects</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add project button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!projectList || projectList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your project or click the
          [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()" aria-label="verify none button"
              role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="projectList">
        <div class="profile__section__list--item" *ngFor="let item of projectList; index as i;">
          <div *ngIf="item.name != 'NONE' || projectList.length == 1"  class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span>Project:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span style="font-weight: 600; word-wrap: normal">Short Name:</span>
                <span class="fw500 pl05r"></span>{{ item.shortName }}
              </div>
              <div *ngIf="item.webSite && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span style="font-weight: 600; word-wrap: normal">Website:</span>
                <span class="fw500 pl05r"></span>{{ item.webSite }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.startDateFlag">
                  <div *ngIf="item.startDateFlag.year">
                    <span style="font-weight: 600">Start Date:</span>
                    <span class="fw500 pl05r">
                      {{ item.startDateFlag.day ? item.startDate.getDate() : '' }}
                      {{ item.startDateFlag.month ? months[+item.startDateFlag.month - 1] : '' }}
                      {{ item.startDateFlag.year ? item.startDateFlag.year : '' }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.endDateFlag && !item.currentlyWorking">
                  <span *ngIf="item.endDateFlag.year">End Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.endDateFlag.day ? item.endDate.getDate() : '' }}
                    {{ item.endDateFlag.month ? months[+item.endDateFlag.month - 1] : '' }}
                    {{ item.endDateFlag.year ? item.endDateFlag.year : '' }}
                  </span>
                </div>
                <div *ngIf="item.currentlyWorking && item.name !== 'NONE'">
                  <span>End Date:</span>
                  <span class="fw500 pl05r">Currently working</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || projectList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit volunteer button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete volunteer button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Projects</div>
        <div *ngIf="projectModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- PROJECT -->
        <div class="profile__form--project">
          <label class="form__label">Project <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name"
            [ngModel]="projectModel.name | neo4junescape" #toNode_name="ngModel" placeholder="Ex. Microsoft Visual Studio Code"
            (ngModelChange)="projectModel.name = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="project - required"   />
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="currentItem_toNode_shortName" name="currentItem_toNode_shortName"
            [ngModel]="projectModel.shortName | neo4junescape" placeholder="Ex. VSCode"
            (ngModelChange)="projectModel.shortName = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="short name"   />
        </div>
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="currentItem_toNode_webSite" name="currentItem_toNode_webSite"
            [pattern]="websiteRegExp"
            #website="ngModel"
            [ngModel]="projectModel.webSite | neo4junescape" placeholder="Ex. https://code.visualstudio.com/"
            (ngModelChange)="projectModel.webSite = this.escape.it($event)"
            [disabled]="deleteMode" aria-label="website"   
            [ngClass]="{'ng-invalid': !website.valid }"/>
        </div>
        <!-- CURRENT -->
        <div class="profile__form--current">
          <label class="content-verify form__label"><span>I am currently working on this project</span>
            <input name="current" type="checkbox" [(ngModel)]="currentlyWorking" (click)="toggleCurrentlyWorking()"
              [disabled]="deleteMode" aria-label="currently working"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- START YEAR -->
        <div class="profile__form--start-year">
          <label class="form__label">Start Year <span class="form__required">*</span></label>
          <select class="form__control" #startYear (change)="onDateChange('startDate', 'year', startYear.value)" required
            [(ngModel)]="projectModel.startDate.year" name="volunteer_year" [disabled]="deleteMode"
            aria-label="start year - required"  
            [ngClass]="{'ng-invalid': isStartYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="projectModel.startDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- START MONTH -->
        <div class="profile__form--start-month" *ngIf="projectModel.startDate.year">
          <label class="form__label">Start Month</label>
          <select class="form__control" (change)="onDateChange('startDate', 'month')"
            [(ngModel)]="projectModel.startDate.month" name="volunteer_month" [disabled]="deleteMode"
            aria-label="start month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- START DAY -->
        <div class="profile__form--start-day" *ngIf="projectModel.startDate.year && projectModel.startDate.month">
          <label class="form__label">Start Day</label>
          <select class="form__control" (change)="onDateChange('startDate', 'day')"
            [(ngModel)]="projectModel.startDate.day" name="volunteer_day" [disabled]="deleteMode" aria-label="start day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of startDayValue" [selected]="projectModel.startDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END YEAR -->
        <div class="profile__form--end-year" *ngIf="!currentlyWorking || projectModel.currentlyWorking === 'false'">
          <label class="form__label">End Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('endDate', 'year')" required
            [(ngModel)]="projectModel.endDate.year" name="volunteer_year2" [disabled]="deleteMode"
            aria-label="end year - required"  >
            <option value="">Select Year</option>
            <option *ngFor="let item of endYearValue" [selected]="projectModel.endDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END MONTH -->
        <div class="profile__form--end-month"
          *ngIf="(!currentlyWorking || projectModel.currentlyWorking === 'false') && projectModel.endDate.year">
          <label class="form__label">End Month</label>
          <select class="form__control" (change)="onDateChange('endDate', 'month')"
            [(ngModel)]="projectModel.endDate.month" name="volunteer_month2" [disabled]="deleteMode"
            aria-label="end month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of endMonthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- END DAY -->
        <div class="profile__form--end-day"
          *ngIf="(!currentlyWorking || projectModel.currentlyWorking === 'false') && projectModel.endDate.year && projectModel.endDate.month">
          <label class="form__label">End Day</label>
          <select class="form__control" (change)="onDateChange('endDate', 'day')" [(ngModel)]="projectModel.endDate.day"
            name="volunteer_day2" [disabled]="deleteMode" aria-label="end day"  >
            <option value="">Select Day</option>
            <option *ngFor="let item of endDayValue" [selected]="projectModel.endDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && projectModel.dataSource === 'User') ||
                (thisForm.form.invalid && projectModel.dataSource !== 'User')" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-project" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Project is required</strong>
        </div>
        <div class="row tac profile__form--error-start-year" *ngIf="isStartYearRequired">
          <strong>Start Year is required</strong>
        </div>
        <ng-container *ngIf="!displayDeleteButton">
          <div class="row tac profile__form--error-invalid-website-url" *ngIf="!website.valid">
            <strong>Not a valid website url</strong>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>