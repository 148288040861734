import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { ObtainedDateTimeEdge } from 'src/app/shared/utilities/app-db-edges';
import { Patent } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-patents',
  templateUrl: './patents.component.html',
  styleUrls: ['./patents.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants]
})
export class PatentsComponent implements OnInit {

  viewmodel = this.data.viewmodel;
  patentList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  noneDisabled: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService
  ) {}

  status: ServiceStatus;
  newItem: { edge: ObtainedDateTimeEdge; toNodeRef: NodeRef; toNode: Patent };
  currentItem: {
    edge: ObtainedDateTimeEdge;
    toNodeRef: NodeRef;
    toNode: Patent;
  };
  patentModel: any = {
    title: '',
    summary: '',
    patentNumber: '',
    obtainedDateTime: {
      year: '',
      month: '',
      day: '',
    },
    obtainedDateTimeFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  unverified: any;
  isAwardYearRequired = false;
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.refreshNewItem();
    this.getpatentList();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip(){
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.patentModel.title = 'NONE';
    this.patentModel.patentNumber = '';
    this.patentModel.obtainedDateTime.year = '';
    this.patentModel.obtainedDateTime.month = '';
    this.patentModel.obtainedDateTime.day = '';
    this.patentModel.summary = '';
    this.saveClick();
  }

  onDateChange(field: any, input: any, awardYear) {
    if(awardYear === '') {
      this.isAwardYearRequired = true;
    } else {
      this.isAwardYearRequired = false
    }
    if (input === 'month') {
      this.dayValue = [];
      for (
        let i = 1;
        i <= this.data.days[this.patentModel.obtainedDateTime.month - 1];
        i++
      ) {
        this.dayValue.push({ value: i });
      }
      this.patentModel.obtainedDateTime.day = '';
    } else if (input === 'year') {
      if (this.patentModel.obtainedDateTime.month === '') {
        this.patentModel.obtainedDateTime.month = '';
        this.patentModel.obtainedDateTime.day = '';
      }
    }
    const flag = this.patentModel[field][input] !== '' ? 1 : 0;
    this.patentModel[field + 'Flag'][input] = flag;
  }

  getpatentList() {
    this.patentList = [];
    const apiUrl = `${this.root}/userProfileUpdatePatent?operation=list`;
    const getpatentList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getpatentList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        console.log('PATENT: ', response);
        this.viewmodel.have_Patent = [];
        const tempItem = response.record.sort((a: any, b: any) =>
          // tslint:disable-next-line: max-line-length
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? a.row[0].obtainedDateTime.year
            : +a.row[0].obtainedDateTime.month) <
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? b.row[0].obtainedDateTime.year
            : +b.row[0].obtainedDateTime.month)
            ? 1
            : -1
        );
        response.record = tempItem;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.record[item].row[0].obtainedDateTime.year,
              response.record[item].row[0].obtainedDateTime.month - 1,
              response.record[item].row[0].obtainedDateTime.day
            ),
            uuid: response.record[item].row[0].uuid,
            title: response.record[item].row[0].title,
            patentNumber: response.record[item].row[0].patentNumber,
            summary:
              response.record[item].row[0].summary &&
              response.record[item].row[0].summary !== 'null'
                ? response.record[item].row[0].summary
                : '',
            // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.record[item].row[0].obtainedDateTime.year,
              month: response.record[item].row[0].obtainedDateTime.month,
              day: response.record[item].row[0].obtainedDateTime.day,
            },
            status: 'verified'
          };
          this.viewmodel.have_Patent.push(temp);
          this.patentList.push(temp);
        }
        this.viewmodel.unverified_Have_Patent = [];
        const tempItem2 = response.unverified.sort((a: any, b: any) =>
          // tslint:disable-next-line: max-line-length
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? a.row[0].obtainedDateTime.year
            : +a.row[0].obtainedDateTime.month) <
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? b.row[0].obtainedDateTime.year
            : +b.row[0].obtainedDateTime.month)
            ? 1
            : -1
        );
        response.unverified = tempItem2;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.unverified[item].row[0].obtainedDateTime.year,
              response.unverified[item].row[0].obtainedDateTime.month - 1,
              response.unverified[item].row[0].obtainedDateTime.day
            ),
            uuid: response.unverified[item].row[0].uuid,
            title: response.unverified[item].row[0].title,
            patentNumber: response.unverified[item].row[0].patentNumber,
            summary:
              response.unverified[item].row[0].summary &&
              response.unverified[item].row[0].summary !== 'null'
                ? response.unverified[item].row[0].summary
                : '',
            // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.unverified[item].row[0].obtainedDateTime.year,
              month: response.unverified[item].row[0].obtainedDateTime.month,
              day: response.unverified[item].row[0].obtainedDateTime.day,
            },
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            status: 'unverified'
          };
          this.viewmodel.unverified_Have_Patent.push(temp);
          this.patentList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        obtainedDateTime: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        title: '',
        summary: '',
        patentNumber: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log('VIEW MODEL PATENT: ', this.viewmodel.have_Patent);
    if (
      this.viewmodel.have_Patent.length === 1 &&
      this.viewmodel.have_Patent[0].title === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.editMode = false;
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.patentModel = {
      title: '',
      summary: '',
      patentNumber: '',
      obtainedDateTime: {
        year: '',
        month: '',
        day: '',
      },
      obtainedDateTimeFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    console.log('ITEM CLICKED: ', item);
    if (item.importStatus) {
      this.patentModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    if (item.dataSource === 'User') {
      item.canEdit ? (this.deleteMode = false) : (this.deleteMode = true);
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.patentModel = { ...item };
    this.patentModel.edgeUuid = item.uuid;
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.patentModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '',
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : '',
      year: item.obtainedDateTimeFlag.year
        ? item.obtainedDateTimeFlag.year
        : '',
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  saveClick() {
    this.editMode = false;
    if (this.patentModel.title === 'NONE') {
      this.patentModel.patentNumber = '';
      this.patentModel.obtainedDateTime.year = '';
      this.patentModel.obtainedDateTime.month = '';
      this.patentModel.obtainedDateTime.day = '';
      this.patentModel.summary = '';
    }
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdatePatent?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.patentModel, edgeUuid: this.patentModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getpatentList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdatePatent?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.patentModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getpatentList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.have_Patent[0]);
    // }
    if (this.patentModel.title != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7000);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.patentModel.importStatus = item.importStatus;
    }
    this.editMode = false;
    this.currentItem = item;
    this.patentModel = { ...item };
    this.patentModel.edgeUuid = item.uuid;
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.patentModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '',
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : '',
      year: item.obtainedDateTimeFlag.year
        ? item.obtainedDateTimeFlag.year
        : '',
    };
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdatePatent?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.patentModel, edgeUuid: this.patentModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getpatentList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7000);
  }

  clearData() {
    this.patentModel.title = '';
    this.patentModel.patentNumber = '';
    this.patentModel.obtainedDateTime.year = '';
    this.patentModel.obtainedDateTime.month = '';
    this.patentModel.obtainedDateTime.day = '';
    this.patentModel.summary = '';
    this.deleteMode = false;
    this.editMode = false;
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
    this.editMode = false;
  }
}
