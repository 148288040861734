<div id="profile-section-publications" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Publications</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()"
          aria-label="add publication button" role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!publicationList || publicationList == 0">
        <div class="profile__section__empty">
          Click [+] above to add your publications or click the [NONE] if
          you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" type="button" [disabled]="noneDisabled" class="button button--dark-grey"
              aria-label="verify none button" role="button" (click)="saveNone()">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="publicationList">
        <div class="profile__section__list--item" *ngFor="let item of publicationList; index as i;">
          <div *ngIf="item.title != 'NONE' || publicationList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.verified == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span>Publication:</span>
                <span class="fw500 pl05r">{{ item.title }}</span>
              </div>
              <div *ngIf="item.publicationType && item.title !== 'NONE'" class="col-md-6">
                <span>Type:</span>
                <span class="fw500 pl05r"></span>{{ item.publicationType }}
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.category && item.title !== 'NONE'" class="col-md-6">
                <span>Category:</span>
                <span class="fw500 pl05r"></span>{{ item.category }}
              </div>
              <div *ngIf="item.status && item.status !== 'Select' && item.title !== 'NONE'" class="col-md-6">
                <span>Status:</span>
                <span class="fw500 pl05r"></span>{{ item.status }}
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.version && item.title !== 'NONE'" class="col-md-6">
                <span>Version:</span>
                <span class="fw500 pl05r"></span>{{ item.version }}
              </div>
              <div *ngIf="item.publisher && item.title !== 'NONE'" class="col-md-6">
                <span>Publisher:</span>
                <span class="fw500 pl05r"></span>{{ item.publisher }}
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.coPublishers && item.title !== 'NONE'" class="col-md-6">
                <span>Co-Authors:</span>
                <ng-container *ngFor="let cop of item.coPublishers; let j = index" class="fw500 pl05r">
                  {{ cop.firstName }} {{cop.lastName}}<span *ngIf="j < item.coPublishers.length - 1">, </span>
                </ng-container>
              </div>

              <div *ngIf="item.title !== 'NONE'" class="col-md-6">
                <span>Refereed:</span>
                <span class="fw500 pl05r">{{ item.isRefereed ? 'Yes' : 'No' }}</span>
              </div>
              <div *ngIf="item.publishedDateFlag && item.title !== 'NONE'" class="col-md-6">
                <div *ngIf="item.publishedDateFlag.year">
                  <span>Publish Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.publishedDateFlag.day ? item.publishedDate.getDate() : "" }}
                    {{ item.publishedDateFlag.month ? months[+item.publishedDateFlag.month - 1] : "" }}
                    {{ item.publishedDateFlag.year ? item.publishedDateFlag.year : "" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.title != 'NONE' || publicationList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.title !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit publication button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete publication button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.verified == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Publications</div>
        <div *ngIf="publicationModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- PUBLICATION -->
        <div class="profile__form--publication">
          <label class="form__label">Publication <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_title" name="currentItem_toNode_title"
            [ngModel]="publicationModel.title" [ngbTypeahead]="titleSuggestions"
            placeholder="Ex. How to setup a startup in 30 days or less"
            (ngModelChange)="publicationModel.title = this.escape.it($event)" #toNode_name="ngModel"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="publication - required" />
        </div>
        <!-- TYPE -->
        <div class="profile__form--type">
          <label class="form__label">Type</label>
          <select class="form__control" id="currentItem_toNode_publicationType"
            [ngModel]="publicationModel.publicationType" name="currentItem_toNode_publicationType"
            (ngModelChange)="publicationModel.publicationType = this.escape.it($event)"
            value="publicationModel.publicationType" aria-label="type"
            [disabled]="deleteMode || !publicationModel.canEdit">
            <option value="">Select</option>
            <option *ngFor="let item of publicationTypes" value="{{item}}"> {{item}}</option>
          </select>
        </div>
        <!-- CATEGORY -->
        <div class="profile__form--category">
          <label class="form__label">Category</label>
          <input class="form__control" id="currentItem_toNode_category" name="currentItem_toNode_category"
            [ngModel]="publicationModel.category" [ngbTypeahead]="categorySuggestions"
            placeholder="Ex. IEEE Security & Privacy, Designs / Codes and Cryptography"
            (ngModelChange)="publicationModel.category = this.escape.it($event)"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="category" />
        </div>
        <!-- STATUS -->
        <div class="profile__form--status">
          <label class="form__label">Status <span class="form__required">*</span></label>
          <select class="form__control" id="currentItem_toNode_status" name="currentItem_toNode_status"
            [ngModel]="publicationModel.status"
            (ngModelChange)="publicationModel.status = this.escape.it($event); onStatusChange($event)"
            value="publicationModel.status" [disabled]="deleteMode || !publicationModel.canEdit" aria-label="status"
            [ngClass]="{'ng-invalid': isStatusRequired}">
            <option>Select</option>
            <option *ngFor="let status of statusList" [value]="status">
              {{ status }}
            </option>
          </select>
        </div>
        <!-- VERSION -->
        <div class="profile__form--version">
          <label class="form__label">Version</label>
          <input class="form__control" id="currentItem_toNode_version" name="currentItem_toNode_version"
            [ngModel]="publicationModel.version" placeholder="Ex. 64: 1707-1714, 77: 587-610"
            (ngModelChange)="publicationModel.version = this.escape.it($event)"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="version" />
        </div>
        <!-- PUBLISHER -->
        <div class="profile__form--publisher">
          <label class="form__label">Publisher</label>
          <input class="form__control" id="currentItem_toNode_publisher" [ngModel]="publicationModel.publisher"
            name="currentItem_toNode_publisher" [ngbTypeahead]="publisherSuggestions"
            placeholder="Ex. CRC Press, Springer-Verlag"
            (ngModelChange)="publicationModel.publisher = this.escape.it($event)"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="publisher" />
        </div>
        <!-- COUNTRY -->
        <div class="profile__form--country">
          <label class="form__label">Country</label>
          <select class="form__control" name="country" [ngModel]="publicationModel.country"
            [disabled]="deleteMode || !publicationModel.canEdit" (ngModelChange)="getStates($event); publicationModel.state = -1; currentState = '';
            cityDisabled = true; publicationModel.city = ''; currentCity = '';" type="text" placeholder="country"
            aria-label="country">
            <option *ngFor="let c of countries" [ngValue]="c.name">{{ c.name }}</option>
          </select>
        </div>
        <!-- STATE -->
        <div class="profile__form--state">
          <label class="form__label">Province/State</label>
          <select class="form__control" name="provinceOrState" [disabled]="deleteMode || !publicationModel.canEdit"
            [ngModel]="publicationModel.state"
            (ngModelChange)="getCities($event); cityDisabled = false; publicationModel.city = ''; currentCity = '';"
            type="text" placeholder="Province/State" aria-label="province/state">
            <option value="-1">Select</option>
            <option *ngFor="let s of states" [ngValue]="s.name" [selected]="i == 0">{{ s.name }}</option>
          </select>
        </div>
        <!-- CITY -->
        <div class="profile__form--city">
          <label class="form__label">City</label>
          <input class="form__control" name="city" type="text" maxlength="75" placeholder="city"
            [ngModel]="publicationModel.city" (ngModelChange)="setCity($event);" [ngbTypeahead]="search"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="city" />
        </div>
        <!-- PUBLISH YEAR -->
        <div class="profile__form--publish-year">
          <label class="form__label">Publish Year</label>
          <select class="form__control" (change)="onDateChange('publishedDate', 'year')"
            [(ngModel)]="publicationModel.publishedDate.year" name="awards_year"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="year">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="publicationModel.publishedDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- PUBLISH MONTH -->
        <div class="profile__form--publish-month" *ngIf="publicationModel.publishedDate.year">
          <label class="form__label">Publish Month</label>
          <select class="form__control" (change)="onDateChange('publishedDate', 'month')"
            [(ngModel)]="publicationModel.publishedDate.month" name="awards_month"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="month">
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- PUBLISH DAY -->
        <div class="profile__form--publish-day"
          *ngIf="publicationModel.publishedDate.year && publicationModel.publishedDate.month">
          <label class="form__label">Publish Day</label>
          <select class="form__control" (change)="onDateChange('publishedDate', 'day')"
            [(ngModel)]="publicationModel.publishedDate.day" name="awards_day"
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="day">
            <option value="">Select Day</option>
            <option *ngFor="let item of dayValue" [selected]="
                publicationModel.publishedDate.day == item.value
              ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- REFEREED -->
        <div class="profile__form--refereed">
          <label class="content-verify form__label"><span>Refereed</span>
            <input name="isRefereed" type="checkbox" [(ngModel)]="publicationModel.isRefereed"
              [disabled]="deleteMode || !publicationModel.canEdit" aria-label="refereed" />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- SUMMARY -->
        <div class="profile__form--summary">
          <label class="form__label"><span>Summary</span></label>
          <textarea class="form__control text-area" id="currentItem_toNode_summary" name="currentItem_toNode_summary"
            [ngModel]="publicationModel.summary"
            placeholder="Ex. This books helps walk through all of the core processes needed to successfully create a solid foundation for a startup in 30 days or less"
            (ngModelChange)="publicationModel.summary = this.escape.it($event)" autosize
            [disabled]="deleteMode || !publicationModel.canEdit" aria-label="summary"></textarea>
        </div>
        <!-- CO-AUTHORS -->
        <div class="profile__form--co-authors">
          <div *ngFor="let item of publicationModel.coPublishers; let i = index">
            <div class="profile__form--co-authors--container" *ngIf="publicationModel.coPublishers.length > 0">
              <div class="profile__form--co-authors--email">
                <label class="form__label" *ngIf="i === 0">Co-Author Email</label>
                <input class="form__control" id="currentItem_toNode_email{{item.id}}" type="email"
                  name="currentItem_toNode_email{{item.id}}" email placeholder="Ex. jd@email.com"
                  [(ngModel)]="item.email" (selectItem)="autofill(i, $event)"
                  [ngbTypeahead]="usersSuggestions" [resultFormatter]="resultFormatter"
                  (ngModelChange)="item.email = this.escape.it($event); checkEmail(); getUserSuggestions(item)"
                  [disabled]="deleteMode || item.personDisabled || !publicationModel.canEdit || !item.canEditPerson"
                  aria-label="co-author email" />
              </div>
              <div class="profile__form--co-authors--fname">
                <label class="form__label" *ngIf="i === 0">Co-Author First Name <span
                    class="form__required">*</span></label>
                <input class="form__control" required id="currentItem_toNode_firstName{{item.id}}"
                  name="currentItem_toNode_firstName{{item.id}}" (selectItem)="autofill(i, $event)"
                  [ngbTypeahead]="usersSuggestions" [resultFormatter]="resultFormatter" placeholder="Ex. John"
                  [(ngModel)]="item.firstName"
                  (ngModelChange)="item.firstName = this.escape.it($event); getUserSuggestions(item)"
                  [disabled]="deleteMode || item.personDisabled || !publicationModel.canEdit ||  !item.canEditPerson"
                  aria-label="co-author first name" />
              </div>
              <div class="profile__form--co-authors--lname">
                <label class="form__label" *ngIf="i === 0">Co-Author Last Name</label>
                <input class="form__control" id="currentItem_toNode_lastName{{item.id}}"
                  name="currentItem_toNode_lastName{{item.id}}" [ngbTypeahead]="usersSuggestions"
                  [resultFormatter]="resultFormatter" placeholder="Ex. Doe" [(ngModel)]="item.lastName"
                  (selectItem)="autofill(i, $event)"
                  (ngModelChange)="item.lastName = this.escape.it($event); getUserSuggestions(item);"
                  [disabled]="deleteMode || item.personDisabled || !publicationModel.canEdit || !item.canEditPerson"
                  aria-label="co-author last name" />
              </div>
              <!-- <input type="text" [value]="item.uuid"/>  -->
              <div class="profile__form--co-authors--actions">
                <button type="button" class="button button--edit" aria-label="co-author edit button" role="button"
                  title="record owner" *ngIf="item.isOwner" [disabled]="deleteMode || !publicationModel.canEdit">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </button>
                <button type="button" class="button button--delete" aria-label="co-author delete button" role="button"
                  (click)="removePublication(i)" *ngIf="item.uuid !== uuidPerson"
                  [disabled]="deleteMode || !publicationModel.canEdit">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
                <button type="button" [disabled]="!item.firstName || !publicationModel.canEdit || deleteMode"
                  aria-label="co-author add" role="button" *ngIf="i === publicationModel.coPublishers.length - 1"
                  class="button button--add" (click)="addCoPublisher()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <ng-container class="profile__form--co-authors--add" *ngIf="publicationModel.coPublishers.length <= 0">
            <label class="form__label">Add Co-Authors</label>
            <button type="button" class="button button--add" style="margin: .5rem 0 0 .5rem;"
              aria-label="co-author add button" role="button" [disabled]="deleteMode || !publicationModel.canEdit"
              (click)="addCoPublisher()">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </ng-container>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && publicationModel.dataSource === 'User') ||
                (thisForm.form.invalid && publicationModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-publication"
          *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Publication is required</strong>
        </div>
        <div class="row tac profile__form--error-status" *ngIf="isStatusRequired">
          <strong>Status is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>