<div id="profile-page">
  <div class="profile__container" *ngIf="data.currentUser && !isLoading && profileCompletion">
    <div class="profile__sidebar">
      <div class="profile__completion" role="region" aria-label="profile completion section">
        <a class="profile__image-container">
          <img class="profile__image rounded-circle" *ngIf="imagePreview"
            onError="this.src='assets/images/uidesign/user.png';" alt="user profile image" role="img"
            [src]="imagePrevUrl != null ? imagePrevUrl : viewmodel.person && viewmodel.person.logoUrl != '' ? viewmodel.person.logoUrl : 'assets/images/uidesign/user.png'" />
          <img class="profile__image rounded-circle" *ngIf="!imagePreview"
            onError="this.src='assets/images/uidesign/user.png';" alt="user profile image" role="img"
            [src]="viewmodel.person && viewmodel.person.logoUrl != '' ? 'https://' + bucket + '.s3.amazonaws.com/profile-pictures/' + viewmodel.person.logoUrl : 'assets/images/uidesign/user.png'" />
          <img src="assets/images/uidesign/plus200.png" class="profile__plus rounded-circle" (click)="openDialog()"
            alt="image plus icon to change image" role="img" />
        </a>
        <div class="profile__user-container">
          <h2 class="profile__user-name">
            {{ data.currentUser.firstName }}
            {{
                data.currentUser.lastName !== '' &&
                data.currentUser.lastName !== undefined &&
                data.currentUser.lastName !== 'undefined' &&
                data.currentUser.lastName !== 'null' &&
                data.currentUser.lastName !== null
                  ? data.currentUser.lastName
                  : ''
              }}
          </h2>
          <p class="profile__user-progress">
            Your profile is {{ data.profileCompletion.totalCompleted }}%
            complete
          </p>
          <div class="progress profile__user-progress-bar__container">
            <div class="progress-bar progress-bar-success profile__user-progress-bar" style="font-size: 12px;" role="progressbar"
              aria-valuemin="0" aria-valuemax="100" aria-label="progressbar" [ngStyle]="{ width: data.completionWidth }">
              <span
                *ngIf="data.profileCompletion.totalCompleted > 25">{{ data.profileCompletion.totalCompleted }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="profile__tabs-container">
        <ul class="nav nav-tabs tabs-left" *ngIf="data.profileTabs" role="list">
          <li class="profile__tab" (click)="tabChange(tab)" (keyup.enter)="tabChange(tab)" [tabindex]="i + 40" [ngClass]="{ active: tab.active }" *ngFor="let tab of data.profileTabs; let i = index;" 
            aria-label="navigation">
            <a style="padding-top: 8px" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
              <span *ngIf="
              tab.name === 'BASIC INFO' &&
              data.profileCompletion.unverified.basic &&
              importedItemUsed
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Experience' &&
              data.profileCompletion.unverified.experience
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Education' &&
              data.profileCompletion.unverified.education
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Certifications' &&
              data.profileCompletion.unverified.certification
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Volunteer Experience' &&
              data.profileCompletion.unverified.volunteer
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Skills' && data.profileCompletion.unverified.skill
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Languages' &&
              data.profileCompletion.unverified.languages
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Publications' &&
              data.profileCompletion.unverified.publications
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Awards' && data.profileCompletion.unverified.awards
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Projects' &&
              data.profileCompletion.unverified.projects
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Research' &&
              data.profileCompletion.unverified.research
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Patents' && data.profileCompletion.unverified.patent
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Academic Ancestry' &&
              data.profileCompletion.unverified.academicAncestry
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Mentoring Relation' &&
              data.profileCompletion.unverified.mentoringRelation
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Social Media' &&
              data.profileCompletion.unverified.socialMedia
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              <span *ngIf="
              tab.name === 'Impact Story' &&
              data.profileCompletion.unverified.impactStory
            " class="unverified-badge-blue unverified-tab"
                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : '#2196f3' }">unverified</span>
              {{ tab.name }}&nbsp;&nbsp;
              <i *ngIf="tab.completion" class="fa fa-check-circle-o tab-completed"
                aria-label="complete"></i>
              <i *ngIf="!tab.completion" class="fa fa-circle-o" style="font-size: 16px"
                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#868686' }" aria-label="incomplete"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <mat-tab-group [selectedIndex]="data.currentProfileTab" (selectedIndexChange)="data.currentProfileTab = $event"
      role="region" class="profile__tab__container" mat-align-tabs="center" (swipeLeft)="data.currentProfileTab = data.currentProfileTab - 1" (swiperight)="data.currentProfileTab = data.currentProfileTab + 1">
      <mat-tab *ngIf="!data.LT_MD && !data.isMobile && data.currentUser && !isLoading && profileCompletion" label="Profile" (swipeLeft)="data.currentProfileTab = data.currentProfileTab - 1" (swiperight)="data.currentProfileTab = data.currentProfileTab + 1">
        <div *ngIf="data.currentUser && !isLoading && profileCompletion">
          <div [ngClass]="selectedUserTab == 0 ? 'showTab' : 'hideTab'">
            <app-basic></app-basic>
          </div>
          <div [ngClass]="selectedUserTab == 1 ? 'showTab' : 'hideTab'">
            <app-experience></app-experience>
          </div>
          <div [ngClass]="selectedUserTab == 2 ? 'showTab' : 'hideTab'">
            <app-education></app-education>
          </div>
          <div [ngClass]="selectedUserTab == 3 ? 'showTab' : 'hideTab'">
            <app-certifications></app-certifications>
          </div>
          <div [ngClass]="selectedUserTab == 4 ? 'showTab' : 'hideTab'">
            <app-volunteer></app-volunteer>
          </div>
          <div [ngClass]="selectedUserTab == 5 ? 'showTab' : 'hideTab'">
            <app-skills></app-skills>
          </div>
          <div [ngClass]="selectedUserTab == 6 ? 'showTab' : 'hideTab'">
            <app-languages></app-languages>
          </div>
          <div [ngClass]="selectedUserTab == 7 ? 'showTab' : 'hideTab'">
            <app-publications></app-publications>
          </div>
          <div [ngClass]="selectedUserTab == 8 ? 'showTab' : 'hideTab'">
            <app-awards></app-awards>
          </div>
          <div [ngClass]="selectedUserTab == 9 ? 'showTab' : 'hideTab'">
            <app-projects></app-projects>
          </div>
          <div [ngClass]="selectedUserTab == 10 ? 'showTab' : 'hideTab'">
            <app-research></app-research>
          </div>
          <div [ngClass]="selectedUserTab == 11 ? 'showTab' : 'hideTab'">
            <app-patents></app-patents>
          </div>
          <div [ngClass]="selectedUserTab == 12 ? 'showTab' : 'hideTab'">
            <app-academic-ancestry></app-academic-ancestry>
          </div>
          <div [ngClass]="selectedUserTab == 13 ? 'showTab' : 'hideTab'">
            <app-mentoring-relation></app-mentoring-relation>
          </div>
          <div [ngClass]="selectedUserTab == 14 ? 'showTab' : 'hideTab'">
            <app-social-media></app-social-media>
          </div>
          <div [ngClass]="selectedUserTab == 15 ? 'showTab' : 'hideTab'">
            <app-impact-story></app-impact-story>
          </div>
        </div>
      </mat-tab>
      <div *ngIf="data.LT_MD || data.isMobile">
        <mat-tab label="Basic Info">
          <app-basic *ngIf="data.currentUser && !isLoading && profileCompletion"></app-basic>
        </mat-tab>
        <mat-tab label="Experience">
          <app-experience></app-experience>
        </mat-tab>
        <mat-tab label="Education">
          <app-education></app-education>
        </mat-tab>
        <mat-tab label="Certifications">
            <app-certifications></app-certifications>
        </mat-tab>
        <mat-tab label="Volunteer">
            <app-volunteer></app-volunteer>
        </mat-tab>
        <mat-tab label="Skills">
            <app-skills></app-skills>
        </mat-tab>
        <mat-tab label="Languages">
            <app-languages></app-languages>
        </mat-tab>
        <mat-tab label="Publications">
            <app-publications></app-publications>
        </mat-tab>
        <mat-tab label="Awards">
            <app-awards></app-awards>
        </mat-tab>
        <mat-tab label="Projects">
            <app-projects></app-projects>
        </mat-tab>
        <mat-tab label="Research">
            <app-research></app-research>
        </mat-tab>
        <mat-tab label="Patents">
            <app-patents></app-patents>
        </mat-tab>
        <mat-tab label="Academic Ancestry">
            <app-academic-ancestry></app-academic-ancestry>
        </mat-tab>
        <mat-tab label="Mentoring Relation">
            <app-mentoring-relation></app-mentoring-relation>
        </mat-tab>
        <mat-tab label="Social Media">
            <app-social-media></app-social-media>
        </mat-tab>
        <mat-tab label="Impact Story">
          <app-impact-story></app-impact-story>
      </mat-tab>
      </div>
    </mat-tab-group>
  </div>
</div>