<div title="event schedule"  class="w-100 h-100">
  <div *ngIf="t.multiDay" class="row jc-c">
    <button aria-label="event day toggle button" role="button" class="custom-btn bold fs-20 pl-30 pr-30" *ngFor="let days of t.days; let dayNum = index"
      (click)="changeDay(dayNum)" [ngClass]="{'inactive' : dayNum != currentDayNum}">
      Day {{dayNum + 1}}</button>
  </div>
  <div class="row jc-c p-20">
    <div class="big-circle">
      <div class="big-circle-day">{{t.startDate | date: 'd'}}</div>
      <div class="big-circle-month">{{t.startDate | date: 'MMM' | uppercase}}</div>
    </div>

  </div>

  <!--  <div class="ta-c">{{currentDay.panelistDescription}}?</div> -->

  <div style="margin-top: -1rem" *ngFor="let session of schedule; let i = index">
    <div *ngIf="(i % 2 == 0) || data.LT_SM; else evenSession">

      <div class="session-card-odd">
        <div class="column odd-columns gap-10">
          <div class="session-hosts session-hosts-odd">{{ session?.panelistText }}</div>
          <div class="session-hosts-image-container session-hosts-image-container-odd">
            <img role="img" *ngFor="let panelist of session.panelists" #tooltip="matTooltip" matTooltip="{{ panelist.name }}"
              matTooltipPosition="below" (click)="openPersonInfoDialog(panelist)"
              class="session-hosts-image grow pointer" src="{{environment.publicBucketUrl}}event-panelists/{{ panelist.image ? panelist.image : 'no-logo.jpeg' }}" alt="panelist image">
          </div>
        </div>

        <div class="circle"></div>

        <div class="column odd-columns odd-columns-right">
          <div title="session start time"  class="time-container"><span class="session-start-time">{{ util.startTimeConversion(session.startTime) }}</span><span class="session-start-time-suffix">{{
              util.startTimeAM(session.startTime) ? 'AM' : 'PM' }} EST</span></div>
          <div title="session title" class="session-title upper">{{session.name}}</div>
          <div class="more-details-container">
            <button type="button" role="button" aria-label="more details for session button" *ngIf="session.details && session.details != null" class="custom-btn grow"
              (click)="openSessionInfoDialog(session)">More Details</button>
            
            <button type="button" role="button" aria-label="play recorded session video button" *ngIf="session.archiveLink && session.archiveLink != null" class="custom-btn grow ml-4"
            (click)="openVideoDialog(session)">Watch Session</button>
          </div>
        </div>
      </div>

    </div>

    <ng-template #evenSession>
      <div class="session-card-even">
        <div class="column even-columns jc-fs ai-fe p-20 ta-r">
          <div title="session start time"><span class="session-start-time">{{ util.startTimeConversion(session.startTime) }}</span><span class="session-start-time-suffix">{{
            util.startTimeAM(session.startTime) ? 'AM' : 'PM' }} EST</span></div>
          <div title="session title" class="session-title upper">{{session.name}}</div>
          <div style="width: 100%; display: flex; justify-content: flex-end;">
            <button type="button" aria-label="more details for session button" role="button" *ngIf="session.details && session.details != null" class="custom-btn grow"
              (click)="openSessionInfoDialog(session)">More Details</button>

            <button type="button" aria-label="play recorded session video button" role="button" *ngIf="session.archiveLink && session.archiveLink != null" class="custom-btn grow ml-4"
            (click)="openVideoDialog(session)">Watch Session</button>
          </div>
        </div>

        <div class="circle" style="z-index: 1000 !important;"></div>

        <div class="column even-columns even-columns-right gap-10">
          <div class="session-hosts session-hosts-even">{{ session.panelistText }}</div>
          <div class="session-hosts-image-container">
            <img role="img" *ngFor="let panelist of session.panelists" #tooltip="matTooltip" matTooltip="{{ panelist.name }}"
              matTooltipPosition="below" (click)="openPersonInfoDialog(panelist)"
              class="session-hosts-image grow pointer" src="{{environment.publicBucketUrl}}event-panelists/{{ panelist.image ? panelist.image : 'no-logo.jpeg' }}" alt="panelist image">
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>

<!-- ORIGINAL STYLE
      <div *ngIf="i % 2 == 0; else evenSession">
  
        <div class="row jc-sb">
          <div class="column even-columns jc-fe p-20 gap-10">
            <div class="session-hosts ta-r">Angela Margo</div>
            <div class="session-title upper ta-r">{{session.sessionTitle}}</div>
            <div class="session-description ta-r">{{session.sessionDescription}}</div>
          </div>
  
          <div class="circle"></div>
  
          <div class="column even-columns p-20">
            <div class="row">
              <div>{{session.startTime}}</div>
              <div>Picture</div>
            </div>
            <div class="row">
              <button class="custom-btn">{{session.startTime}} to {{session.endTime}}</button>
              <button class="custom-btn">More Details</button>
            </div>
          </div>
        </div>
  
      </div>
  
      <ng-template #evenSession>
        <div class="row jc-sb">
          <div class="column even-columns jc-fs p-20">
            <div class="row jc-fe">
              <div>Picture</div>
              <div>{{session.startTime}}</div>
            </div>
            <div class="row jc-fe">
              <button class="custom-btn">More Details</button>
              <button class="custom-btn">{{session.startTime}} to {{session.endTime}}</button>
            </div>
          </div>
  
          <div class="circle"></div>
  
          <div class="column even-columns p-20 gap-10">
            <div class="session-hosts">Angela Margo</div>
            <div class="session-title upper">{{session.sessionTitle}}</div>
            <div class="session-description">{{session.sessionDescription}}</div>
          </div>
        </div>
      </ng-template>
   -->