<div id="pi-page" class="fill-view" role="region" aria-label="pi page">
    <app-branding-separator
      bgc1="rgb(63,138,77)"
      bgc2="rgb(63,138,77)"
      bgc3="rgb(63,138,77)"
      bgc4="rgb(63,138,77)"
    ></app-branding-separator>
    <app-slider id="7"></app-slider>
    <div class="pi-container">
      <div class="pi-card-1" title="event agenda">
        <app-card
          title="Impact and Beyond"
          backgroundColorClass="blue-pi-day-agenda-background-color"
          type="scroll"
        >
          <div class="p2r">
            <div class="pi-card--content">
              <!-- <div class="row mb-4">
                <app-zoom
                  style="width: 100%"
                  [uuid]="uuid"
                  [eventID]="zoomLiveStreamEventID"
                ></app-zoom>
              </div> -->
              <div class="card--h1">September 14, 2022 @ 12:00 noon EST</div>
              <br />
            </div>
            <ul class="pi-card--timezone-list">
              <li>
                <div class="row">
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Waterloo/NY</div>
                    <div class="card--time-zone-badge--yellow">12:00 PM</div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Van./SF</div>
                    <div class="card--time-zone-badge--yellow">9:00 AM</div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">London</div>
                    <div class="card--time-zone-badge--yellow">5:00 PM</div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">New Delhi</div>
                    <div class="card--time-zone-badge--yellow">9:30 PM</div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Singapore</div>
                    <div class="card--time-zone-badge--yellow">12:00 AM</div>
                  </div>
                </div>
                
                <hr />
              </li>
              <br />
              <div class="card--h5 fw600 pi-card--date-text">
                Profound Impact Day-2022 Recording and Download Slides
              </div>
              <div
                class="row"
                style="
                  position: relative;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  margin: 0 0 17px 0;
                "
              >
                <div>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/222O6STSj4g"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    role="figure"
                    aria-label="A youtube video of episode 1 part 2 webinar event"
                  ></iframe>
                </div>
                <div
                  style="
                    width: 1px;
                    height: 120px;
                    border: solid rgb(170, 170, 170) 1px;
                    margin-right: -25px;
                  "
                ></div>
                <div>
                  <a
                    href="javascript:void(0)"
                    (click)="getPPT('Pi Day 22.pptx')"
                  >
                    <img
                      src="../../../assets/images/powerpoint.png"
                      style="
                        height: 50px;
                        width: 50px;
                        cursor: pointer;
                        margin-left: 22px;
                      "
                      role="img"
                      aria-label="power-point logo"
                    />
                    <span
                      style="
                        display: block;
                        width: 100px;
                        font-size: 0.6rem;
                        color: rgb(95, 95, 95);
                        text-align: center;
                      "
                      >Download Slides</span
                    >
                  </a>
                </div>
              </div>
              <hr />
  
              <li>
                <div class="card--h1">AGENDA</div>
              </li>
              <li class="agenda-card">
                <div class="card--h3 ts">Welcome</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/sherry.jpeg"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Sherry Shannon-Vanstone</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      President and CEO of Profound Impact
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/fred.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Dr. Feridun Hamdullahpur</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                    2021 Impactful Actions Award Winner
                    </div>
                  </div>
                </div>
                <br/>
                  <div class="row">
                    <div class="col-md-2">
                      <img
                        src="../../../../assets/images/people/brian.png"
                        alt="Avatar"
                        class="avatar"
                      />
                    </div>
                    <div class="col-md-10">
                      <div class="panellist">Brian Romansky</div>
                      <div
                        class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                      >
                        Chief Strategic Advisor, Profound Impact Corporation
                      </div>
                    </div>
                  </div>
              </li>
              <li class="agenda-card">
                <div class="card--h3 ts"> Impactful Actions Award Finalists</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/basu1.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Kehkashan Basu</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                    M.S.M., Founder-President, Green Hope Foundation
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/mona1.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Dr. Mona Nemer</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                    Chief Science Advisor to Canada’s Prime Minister
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/neil.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <br />
                  <div class="col-md-10">
                    <div class="panellist">Dr. Neil Turok</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                    Higgs Chair of Theoretical Physics, University of Edinburgh; African Institute for Mathematical Sciences
                    </div>
                  </div>
                </div>

              </li>
              <li class="agenda-card">
                <div class="card--h3 ts">Wrap Up and Call to Action</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/sherry.jpeg"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Sherry Shannon-Vanstone</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      President and CEO of Profound Impact
                    </div>
                  </div>
                </div>
              </li> 
              
              <!-- <li class="agenda-card">
                <div class="card--h3 ts">Discussion</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/fred.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Dr. Feridun Hamdullahpur</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      Former President, University of Waterloo 10x10x10 Impact
                      Champion, United Nations’ HeForShe Alliance
                    </div>
                  </div>
                </div>
              </li>

              <li class="agenda-card">
                <div class="card--h3 ts">Hack the North Pre-Hack</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/brian.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Brian Romansky</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      Chief Strategic Advisor, Profound Impact Corporation
                    </div>
                  </div>
                </div>
              </li>
              <li class="agenda-card">
                <div class="card--h3 ts">Q&A/Demonstration</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/rob.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Rob Darling</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      Strategy and Business Development Advisor, Profound Impact
                      Corporation
                    </div>
                  </div>
                </div>
              </li>
              <li class="agenda-card">
                <div class="card--h3 ts">Wrap Up and Call to Action</div>
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="../../../../assets/images/people/sherry.jpeg"
                      alt="Avatar"
                      class="avatar"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="panellist">Sherry Shannon-Vanstone</div>
                    <div
                      class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color"
                    >
                      President and CEO of Profound Impact
                    </div>
                  </div>
                </div>
              </li> -->
  <!---till here first card-->
              <!-- <li>
                <div class="card--h3 ts">Welcome</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Sherry Shannon-Vanstone                
                  <br/>
                  President and CEO of Profound Impact
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Greeting</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  City of Waterloo Mayor Dave Jaworsky
                  <br/>
                  University of Waterloo BMath ‘88
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Introduction to Profound Impact day</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Sherry Shannon-Vanstone
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Walk through of Profound Impact Platform</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Brian Romansky
                  <br/>
                  Profound Impact Chief Strategic Advisor
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Metrics and Alumni and Faculty Engagement</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Barney Ellis-Perry
                  <br/>
                  Renowned Fundraising and Alumni Engagement Strategist
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Q&A</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Brian Romansky
                </div>
              </li>
              
              <li>
                <hr />
                <div class="card--h3 ts">Wrap Up and Call to Action</div>
                <div class="card--h4-alt-secondary-text lh pi-card--agenda-subtext-color">
                  Sherry Shannon-Vanstone           
                </div>
              </li> -->
            </ul>
          </div>
        </app-card>
      </div>
  
      <div class="card pi-card-2" title="commuity discussion board">
        <app-card
          title="Welcome to the Profound Impact Day Community Discussion Board"
          subtitle="Conversations and connections start here"
          backgroundColorClass="light-grey-gradient-background"
          type="scroll"
        >
          <!-- <div class="h2r">
          <div *ngIf="!dataService.authenticated" class="card--warning">
            You must be logged in to comment
          </div>
        </div> -->
          <app-discussion-board> </app-discussion-board>
        </app-card>
      </div>
      <div class="card pi-card-3" title="Decade">
        <app-card
          style="overflow-x: hidden"
          title="IMPACTFUL ACTIONS AWARD FINALISTS "
          subtitle=""
          backgroundColorClass="green-profound-insights-presents-gradient-background"
          type="scroll"
        >
          <div class="row" style="padding: 4%; color: black">
            <div><img
              src="assets/images/people/KBasu.png"
              class="img-responsive"
              role="img"
              alt="mentor user image"
              style="height: 40vh; width: 100%; margin: auto; padding-left: 40px; padding-bottom: 11px"
            />
          </div>
        <br />
          <div> 
             <img
              src="assets/images/people/MNemar.png"
              class="img-responsive"
              role="img"
              alt="mentor user image"
              style="height: 40vh; width: 100%; margin: auto; padding-left: 40px; padding-bottom: 11px"
            />
          </div>
        <br />
           <div>
            <img
              src="assets/images/people/NTurok.png"
              class="img-responsive"
              role="img"
              alt="mentor user image"
              style="height: 40vh; width: 100%; margin: auto; padding-left: 40px; padding-bottom: 11px"
            />
          </div>
            <!-- <div style="height: 100%; margin-top: 15px">
              <p
                class="fw500"
                style="color: #595959; line-height: 2rem; font-size: 1.4rem"
              >
                In late May this year, Dr. Feridun Hamdullahpur was honored by the
                University of Waterloo with two Decade of Impact celebrations. The
                first was on May 27, 2021 with Community partners, alumni, friends
                and the second one was held on May 31, 2021 with Faculty, staff
                and students. More details about the celebrations can be found on
                <a
                  href="https://uwaterloo.ca/decade-of-impact/farewell-events."
                  target="_blank"
                  style="text-transform: initial; font-weight: 700"
                  >https://uwaterloo.ca/decade-of-impact/farewell-events.</a
                >
              </p>
  
              <div style="margin-top: 15px; margin-bottom: 15px">
                <iframe
                  width="49%"
                  height="100%"
                  src="https://www.youtube.com/embed/oT51xyVnP-c"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  role="figure"
                ></iframe
                >&nbsp;
                <iframe
                  width="49%"
                  height="50%"
                  src="https://www.youtube.com/embed/H0_kMu12ZAE"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  role="figure"
                ></iframe>
              </div>
  
              <p
                class="fw500"
                style="color: #595959; line-height: 2rem; font-size: 1.4rem"
              >
                Additionally, the University of Waterloo made a call to action to
                give to students in Feridun's honour (link to
                https://uwaterloo.ca/decade-of-impact/give-feriduns-honour).
                Providing an opportunity to give in one or more areas:
              </p>
              <ol style="padding-left: 9%">
                <li
                  style="
                    padding-left: 2px;
                    color: #595959;
                    line-height: 2rem;
                    font-size: 1.4rem;
                  "
                >
                  Catherine and Feridun Hamdullahpur Graduate Scholarship: Awarded
                  annually to a student registered full time in any graduate
                  program at the University of Waterloo
                </li>
                <li
                  style="
                    padding-left: 2px;
                    color: #595959;
                    line-height: 2rem;
                    font-size: 1.4rem;
                  "
                >
                  Waterloo Fund: Provides Waterloo with the ability to fund the
                  current year's most pressing needs
                </li>
                <li
                  style="
                    padding-left: 2px;
                    color: #595959;
                    line-height: 2rem;
                    font-size: 1.4rem;
                  "
                >
                  Student Wellness: Supports student success by ensuring students
                  have access to resources that promote their physical and mental
                  well-being
                </li>
              </ol>
              <p
                class="fw500"
                style="color: #595959; line-height: 2rem; font-size: 1.4rem"
              >
                Profound Impact wishes to amplify this call to action by asking
                you to give to students in Feridun's honour at
                <a
                  href=" https://waterloo-sec.imodules.com/s/1802/17/giving-form.aspx?sid=1802&gid=2&pgid=2175&cid=5214"
                  target="_blank"
                  style="text-transform: initial; font-weight: 700"
                >
                  https://waterloo-sec.imodules.com/s/1802/17/giving-form.aspx?sid=1802&gid=2&pgid=2175&cid=5214</a
                >
              </p>
            </div> -->
          </div>
        </app-card>
      </div>
      <div class="card pi-card-4" title="instagram feed">
        <app-card
          title="Instagram Feed"
          backgroundColorClass="red-instagram-feed-gradient-background"
          type="scroll"
        >
          <div class="p2r">
            <app-instagram-feed></app-instagram-feed>
          </div>
        </app-card>
      </div>
      <div class="card pi-card-5" title="How to Contribute">
        <app-card
          title="How to Contribute"
          backgroundColorClass="blue-pi-day-agenda-background-color"
          type="scroll"
        >
          <div style="padding: 2% 4%">
            <div class="row contribution-card" style="padding: 4%">
              <div>
                <!-- <h4 class="text-center fw600" style="margin: 5px !important">
                <span class="card--small-alt contribution-sub-heading"
                  >HOW TO CONTRIBUTE</span
                >
              </h4> -->
                <br />
                <p class="card--p-alt-contribution fw500">
                  Our community is filled with innovators, thinkers and doers
                  whose individual and collective actions and legacies are
                  changing the world. It’s time we put real effort into
                  celebrating our #ProfoundImpact. Here is how you can participate
                  in Profound Impact Day:
                  <br />
                  <br />
                  <span
                    class="card--p-alt-secondary fw600 text-center pi-card--minutes"
                  >
                    Got 30 seconds, 3 minutes, 5 minutes or 15 minutes?
                  </span>
                  <br />
                  <br />
                  <span
                    class="card--p fw600 contribution-sub-heading pi-card--minutes"
                  >
                    30 Seconds
                  </span>
                  <br />
                  <br />
                  Follow Profound Impact on social media @aprofoundimpact!
                  <br />
                  <br />
                  <span
                    class="card--p fw600 contribution-sub-heading pi-card--minutes"
                  >
                    3 Minutes
                  </span>
                  <br />
                  <br />
                  Create a social post sharing your stories of how individuals in
                  your network and beyond have positively influenced your personal
                  or professional journey, and don’t forget to use
                  #ProfoundImpactDay.
                  <br />
                  <br />
                  <span
                    class="card--p fw600 contribution-sub-heading pi-card--minutes"
                  >
                    5 Minutes
                  </span>
                  <br />
                  <br />
                  Please consider a donation to your favorite charity to help make a profound impact!
                  <br />
                  <br />
                  <span
                    class="card--p fw600 contribution-sub-heading pi-card--minutes"
                  >
                    15 Minutes
                  </span>
                  <br />
                  <br />
                  Everyone else, log in and document your Profound Impact story at
                  https://demo.profoundimpact.com.
                  <br />
                  <br />
                  <span
                    class="card--p fw600 contribution-sub-heading pi-card--minutes"
                  >
                    Ongoing
                  </span>
                  <br />
                  <br />
                  Introduce Profound Impact to your friends, peers and colleagues.
                </p>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="card pi-card-6" title="event activity graph">
        <app-card
          title="Profound Impact Day Activity"
          backgroundColorClass="light-pink-gradient-background"
          type="hidden"
        >
          <app-event-graph id="402"></app-event-graph>
        </app-card>
      </div>
    </div>
  </div>
  