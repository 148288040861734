import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { YearsOfExperienceEdge } from 'src/app/shared/utilities/app-db-edges';
import { Skill } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class SkillsComponent implements OnInit {
  
  viewmodel = this.data.viewmodel;
  skillsList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();
  // noneExists: boolean;
  unverified: any;
  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    private escape: Escape,
    public data: DataService,
    private api: HttpService
  ) {}

  status: ServiceStatus;
  // @Input() viewmodel: UserProfile;
  newItem: { edge: YearsOfExperienceEdge; toNodeRef: NodeRef; toNode: Skill };
  currentItem: {
    edge: YearsOfExperienceEdge;
    toNodeRef: NodeRef;
    toNode: Skill;
  };

  skillModel: any = { name: '', dataSource: '', yearsOfExperience: '' , status: ''};
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  invalidExperience = false;
  isExperienceInValid = false;

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getSkillList();
    this.refreshNewItem();
    for (let i = 2020; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip(){
     
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  checkExperience(experience: any) {
    const experienceString = experience + '';
    if (experience !== null) {
      if (
        experienceString.includes('-') ||
        experience > 99 ||
        experience < 1
      ) {
        this.invalidExperience = true;
      } else {
        this.invalidExperience = false;
      }
    } else {
      this.invalidExperience = true;
    }
  }

  getSkillList() {
    this.skillsList = [];
    const apiUrl = `${this.root}/userProfileUpdateSkill?operation=list`;
    const getCertificateList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getCertificateList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        this.viewmodel.specialized_in_Skill = [];
        const tempItem = response.record.sort((a: any, b: any) =>
          a.row[0].yearsOfExperience < b.row[0].yearsOfExperience ? 1 : -1
        );
        response.record = tempItem;
        // UNVERIFIED IMPORTED RECORDS
        this.viewmodel.specialized_in_Skill = [];
        const UNVERIFIEDExperience = [];
        for (let i = 0; i < response.unverified.length; i++) {
          const temp: any = {
                  uuid: response.unverified[i].row[0].uuid,
                  name: response.unverified[i].row[0].name,
                  dataSource: response.unverified[i].row[0].dataSource,
                  yearsOfExperience: response.unverified[i].row[0].yearsOfExperience,
                  status : 'unverified'
                };
                this.viewmodel.specialized_in_Skill.push(temp);
                this.skillsList.push(temp);
          // this.viewmodel.specialized_in_Skill.push(response.unverified[i]);
        }
    
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            yearsOfExperience: response.record[item].row[0].yearsOfExperience,
          };
          this.viewmodel.specialized_in_Skill.push(temp);
          this.skillsList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        yearsOfExperience: 0,
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    this.deleteMode = false;
    this.editMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.skillModel = { name: '', yearsOfExperience: '' };
  }

  gotoEditClick(item: any) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    this.deleteMode = false;
    this.editMode = true;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.skillModel = { ...item };
    this.skillModel.edgeUuid = item.uuid;
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    console.log('EDIT MODE', this.editMode);
    // console.log('Invalid Experience', this.invalidExperience);
    console.log(
      'skillModel.yearsOfExperience',
      this.skillModel.yearsOfExperience
    );
  }

  saveClick() {
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateSkill?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.skillModel, edgeUuid: this.skillModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getSkillList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateSkill?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.skillModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.editMode = false;
            this.getSkillList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    if (this.skillModel.name != 'NONE') {
    setTimeout(() => {
      this.flip();
    }, 1000);
  }
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    this.deleteMode = true;
    this.editMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.skillModel = { ...item };
    this.skillModel.edgeUuid = item.uuid;
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
  }

  deleteClick() {
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateSkill?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.skillModel, edgeUuid: this.skillModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getSkillList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  // isExperienceInvalid(form, exp) {
  //   // console.log(`isExperienceInvalid: ${exp}, condition: ${form.dirty && (exp < 0)}`);
  //   return form.dirty && (exp < 1 || exp > 99);
  // }

  cancelClick() {
    setTimeout(() => {
      this.flip();
    }, 500);
    this.httpClientCallMessage = null;
    this.deleteMode = false;
    this.editMode = false;
  }
}
