import { Component} from "@angular/core"
import { NgxSpinnerService } from "ngx-spinner"
import { ZoomMtg } from "@zoomus/websdk"
import { AmplifyService } from "aws-amplify-angular"
import { HttpClient } from "@angular/common/http"
import { CookieService } from "ngx-cookie"
import { ToastrManager } from "ng6-toastr-notifications"
import { take, first } from 'rxjs/operators'
import { BehaviorSubject, Subscription } from "rxjs"
import { environment } from "src/environments/environment"
import { HttpService } from "src/app/services/http.service"
import { WebSocketService } from "src/app/services/websocket.service"
import { DataService } from "src/app/services/data.service"
import jwt_decode from "jwt-decode";

ZoomMtg.preLoadWasm()
ZoomMtg.prepareJssdk()

@Component({
  selector: "app-zoom",
  templateUrl: "./zoom.component.html",
  styleUrls: ["./zoom.component.scss"],
})
export class ZoomService {

  uuid = new BehaviorSubject('');
  eventID = new BehaviorSubject('');

  joinMeeting = false
  joinButton = false
  apiKey = environment.zoomApiKey
  apiSecret = environment.zoomApiSecret
  meetingNumber: any
  role: any = 0
  leaveUrl = "./"
  userName = "Guest"
  userEmail = ""
  passWord = ""
  buttonText: string = 'Join Live Stream'
  bucket: string = environment.publicBucket
  offlineImage: string
  offlineStatus: boolean = true
  signature: any
  msg: any
  authentication: boolean
  subscription: Subscription
  token: any
  
  constructor(public api: HttpService,
    private amplifyService: AmplifyService,
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    private websocket: WebSocketService,
    private cookieService: CookieService,
    private dataService: DataService,
    public _toaster: ToastrManager
  ) { }


  liveStreamStats(id) {
    if (id !== 'NONE') {
      if (!this.cookieService.get(`eventID${id}`)) {
        this.cookieService.put(`eventID${id}`, id)
        let uuid = ''
        if (this.dataService.authenticated) {
          uuid = this.dataService.uuidPerson
          const decodedJWT = jwt_decode(this.dataService.jwt);
          uuid = decodedJWT["custom:uuidPerson"];
        } else {
          uuid = null
        }
        this.api.groupMeetingStats(id, this.dataService.currentCountryCode, this.dataService.currentCity, this.dataService.currentIPAddress, uuid).subscribe((data) => {
          console.log('Live STREAM STATS DATA', data)
        });
      } else {
        //console.log('event id exists')
      }
    }
  }

  async getZoomConfig() {
    try {
        this.authentication = this.dataService.authenticated

        ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
            console.log(data);
        });

        ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
            console.log(data);
            console.log('leaving meeting.......')
        });

        ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
            console.log(data);
        });

        ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data) => {
            // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
            console.log('Status of Zoom meeting', data)
            if (data.meetingStatus === 3) {
            document.getElementById("wc-content").style.display = "none"
            this.joinMeeting = false
            window.location.reload()
            }
        });
        // console.log(this.joinButton)

       this.userName = 'Guest';
       this.api.getZoomConfig(this.uuid.value)
        .subscribe(res => {
          if(!res.data.offlineStatus && this.authentication) {
            this._toaster.successToastr('please click on join event button to join the webinar', 'Webinar has been started');
          }
          this.signature = ZoomMtg.generateSignature({
            meetingNumber: res.data.meetingId,
            apiKey: this.apiKey,
            apiSecret: this.apiSecret,
            role: this.role,
            success: (res) => {
              console.log(res)
            },
          })
          this.offlineImage = res.data.offlineImage
          this.meetingNumber = res.data.meetingId
          this.passWord = res.data.password
          this.buttonText = res.data.buttonText
          this.offlineStatus = res.data.offlineStatus
          this.dataService.iwdEventOffline = res.data.offlineStatus
          this.leaveUrl = window.location.href

        })
    } catch (err) {
      console.log(err)
    }
    //})
  }

  getSignature() {
    if (this.signature) {
      this.startMeeting(this.signature)
    } else {
      console.log("wrong signature")
    }
  }

  // start and connect to join meeting/webinar 
  startMeeting(signature) {
    this.spinner.show()
    document.getElementById("zmmtg-root").style.display = "block"
    document.getElementById("zmmtg-root").style.position = "relative"
    document.getElementById("zmmtg-root").style.zIndex = "9"
    //document.getElementsByTagName("footer")[0].style.display = "none"

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      isSupportQA: true,
      disableInvite: true,
      showMeetingHeader: true,
      screenShare: true,
      isSupportChat: true, //optional,
      videoDrag: true, //optional,
      sharingMode: "fit",
      isShowJoiningErrorDialog: false, // optional,
      success: (success) => {
        ZoomMtg.join({
          signature: this.signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          userEmail: 'xyz@gmail.com',
          apiKey: this.apiKey,
          passWord: this.passWord,
          success: (res) => {
            const wcFooter = document.getElementById('wc-footer');
            const footer = wcFooter.childNodes[0];
            footer.childNodes[2].remove();

            const content = document.createElement('div')
            content.innerHTML = '<button class="mt-2 zmu-btn footer__leave-btn ax-outline ellipsis zmu-btn--danger zmu-btn__outline--blue" onclick="leaveMeeting()" type="button"><span class="footer__leave-btn-text">Leave</span></button>'
            footer.appendChild(content)
            this.joinMeeting = true
            this.spinner.hide()
            this.liveStreamStats(this.eventID.value)
          },
          error: (error) => {
            this.spinner.hide()
            //alert("Meeting has not started yet")
            console.log(error)
            //this.msg = "t"
            this._toaster.errorToastr('Please wait while host starts the webinar');
            setTimeout(() => {
              this.msg = null
            }, 3000);
          },
        })
      },
      error: (error) => {
        this.spinner.hide()
        console.log(error)
      },
    })

  }

  //checking if user left
  leaveMeeting() {
    console.log("leaving meeting...")
    ZoomMtg.leaveMeeting({
      success: () => {
        this.joinMeeting = false
        document.getElementById("zmmtg-root").style.display = "none"
        window.location.reload()
        // some logic
      },
      error: err => {
        console.log(err);
        // some logic
      }
    });
  }

  //Checking status of the meeting
  checkMeetingStatus(meetingId, offline) {
    if (!offline) {
      this.api.getZoomMeeting(meetingId).subscribe(res => {
        if (res.data.body.status === 'started') {
          // alert(true)
          this.joinButton = true
        } else {
          alert(false)
          // this.joinButton = false
          //return false
        }
      })
    }
  }

}
