<mat-tab-group role="region" id="import" mat-align-tabs="center">
  <mat-tab label="Import Profile">
    <div class="settings-tab-container" fxLayout="row" fxLayoutAlign="center">
      <div class="row import__row" fxLayoutAlign="center" ngStyle.gt-sm="width: 100%" ngStyle.sm="width: 100%"
        ngStyle.lt-sm="width: 100%">
        <div class="col-12">
          <div [ngClass]="{
              'tab-content': !isMobile && !data.LT_LG,
              'mobile-tab-content': isMobile || data.LT_LG
            }">
            <!--
              ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
              ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
              ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
              ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
              ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
              ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
            -->
            <div role="region" class="row" *ngIf="!isMobile && !data.LT_MD">
              <div role="navigation" aria-label="tabs" class="col-3 p-0 import__tabs">
                <ul class="nav nav-tabs tabs-left flex-column user-dtls" role="list">
                  <li [ngClass]="{ active: tab.active }" *ngFor="let tab of tabs">
                    <a [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#b9b9b9' }" (click)="tabChange(tab)">
                      <img *ngIf="tab.icon !== ''" alt="import tab icons" roler="img" src="{{ tab.icon }}" />{{ tab.name
                      }}</a>
                  </li>
                </ul>
              </div>
              <div class="col-9">
                <h3 *ngIf="this.tabStatus !== 'N/A'" class="m-2" style="text-align: center;color: rgb(33, 150, 243);;"> You Have Already Started {{this.tabStatus}}. You Need To Complete That First In <a class="display-3" (tap)="route('/profile')" routerLinkActive="menu-item-active" [routerLink]="['/profile']" aria-label="profile"
                  role="link"> <h3 class="d-inline text-capitalize" style="text-align: center;color: rgb(33, 150, 243)!important;"> <u>Profile Section.</u></h3> 
                  </a>
                </h3>
                <div class="upload-content">
                  <!-- 
                     ██████╗ ██████╗ ███╗   ███╗███╗   ███╗ ██████╗ ███╗   ██╗     ██████╗██╗   ██╗
                    ██╔════╝██╔═══██╗████╗ ████║████╗ ████║██╔═══██╗████╗  ██║    ██╔════╝██║   ██║
                    ██║     ██║   ██║██╔████╔██║██╔████╔██║██║   ██║██╔██╗ ██║    ██║     ██║   ██║
                    ██║     ██║   ██║██║╚██╔╝██║██║╚██╔╝██║██║   ██║██║╚██╗██║    ██║     ╚██╗ ██╔╝
                    ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║    ╚██████╗ ╚████╔╝ 
                      ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝     ╚═════╝  ╚═══╝  
                  -->
                  <div class="tab-pane active" [ngClass]="{
                      showTab: selectedSettingTab == 1,
                      hideTab: selectedSettingTab != 1
                    }">
                    <div>
                      <div fxLayout="row" fxLayoutAlign="space-between">
                        <div class="card-instructions text-center">
                          <span>Please select your Canadian Common CV (CCV)
                            file.</span><br />
                          <span>We support the following formats.</span>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="import__instructions">
                        <div class="text-center">
                          <img class="file-type" alt="we support XML" role="img"
                            src="../../assets/images/XML-Recommended.png" (click)="xmlSelected = true" />
                          <br />
                          <span class="upload-info-link" (click)="openXMLModal()">How to export XML</span><span
                            class="fa" (click)="openXMLModal()">
                            &#xf05a;</span>
                        </div>
                        <img class="file-type" alt="we support PDF" role="img" src="../../assets/images/pdf.png"
                          (click)="pdfSelected = true" />
                      </div>
                      <div class="import__info" role="region" aria-label="upload info">
                        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                          <div class="inputWrapper" style="cursor: pointer;" *ngIf="!currentCommonCV && !loading">
                            <p class="card--h5-small" style="cursor: pointer;"
                              [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                              SELECT FILE
                            </p>
                            <input [disabled]="currentCommonCV || loading" class="fileInput" #fileUpload id="image"
                              type="file" (click)="fileUpload.value = null"
                              (change)="fileChangeEvent($event,'commonCV')" accept="application/pdf,.pdf,.xml" required
                              style="cursor: pointer;" aria-label="file import button" role="button" />
                          </div>
                          <div class="inputWrapper"
                            [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : 'rgb(204, 204, 204)' }"
                            *ngIf="currentCommonCV || loading" style="cursor: pointer;">
                            <p class="card--h5-small" style="cursor: pointer;"
                              [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                              SELECT FILE
                            </p>
                            <input [disabled]="currentCommonCV || loading" style="cursor: pointer;" class="fileInput"
                              #fileUpload id="image" type="file" (click)="fileUpload.value = null"
                              (change)="fileChangeEvent($event,'commonCV')" accept="application/pdf,.pdf,.xml" required
                              aria-label="file import button" role="button" />
                          </div>
                          <button [disabled]="currentCommonCV || loading" type="button" class="button button--dark-grey"
                            (click)="upload('CommonCV'); uploaded = true" aria-label="file upload button" role="button">
                            UPLOAD
                          </button>
                        </div>
                        <div>
                          <p *ngIf="tempImportFile && !currentCommonCV" class="click-upload-text">Click [UPLOAD] to
                            extract and import file into your
                            profile</p>
                          <div *ngIf="tempImportFile && !currentCommonCV" class="import__file-instructions">
                            <div fxLayout="row" fxLayoutAlign="space-between">
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">File Name:</span>
                                {{ tempImportFile.name }}
                              </div>
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">File Size:</span>
                                {{ tempImportFile.size / 1000 }}KB
                              </div>
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">Last Modified:</span>
                                {{ tempImportFile.lastModifiedDate | date }}
                              </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between">
                              <div>&nbsp;</div>
                              <div>&nbsp;</div>
                              <div class="fileDesc row"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700 upload-status">
                                  Upload Status:
                                </span>
                                {{ uploadCompletionStatus }}
                                <mat-spinner *ngIf="
                                    uploadCompletionStatus === 'Uploading' ||
                                    uploadCompletionStatus === 'Extracting'" [diameter]="30" style="margin-left: 10px">
                                </mat-spinner>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="currentCommonCV && importedItems[0].dataSource === 'CommonCV'"
                            class="import__info">
                            <div fxLayout="row" fxLayoutAlign="space-between">
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">File Name:</span>
                                {{ currentCommonCV.uploadedFileName }}
                              </div>
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">File Size:</span>
                                {{ currentCommonCV.uploadedFileSize }}
                              </div>
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">Last Modified:</span>
                                {{
                                currentCommonCV.uploadedFileLastModified
                                | date
                                }}
                              </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between">
                              <div class="fileDesc"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700">Uploaded:</span>
                                {{ currentCommonCV.createdDateTime | date }}
                              </div>
                              <div class="fileDesc row"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                <span class="fw700 upload-status">
                                  Upload Status:
                                </span>
                                Completed
                              </div>
                            </div>
                            <div class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                              <p class="m-0" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#2196f3' }">
                                The following sections have been updated in
                                profile. Please visit each section, verify and
                                save.
                              </p>
                            </div>
                          </div>
                          <div role="region" *ngIf="currentCommonCV && importedItems[0].dataSource === 'CommonCV'">
                            <hr />
                            <div role="group" class="fileDesc" fxLayout="row" fxLayoutAlign="space-around">
                              <div role="list" aria-label="unverified item list" class="import__list" fxLayout="column"
                                fxLayoutAlign="center center">
                                <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                  UNVERIFIED
                                </p>
                                <mat-selection-list style="
                                    width: 100%;
                                    border-radius: 5px;
                                    border: rgb(225, 225, 225) 1px solid;
                                    padding-top: 0px;
                                    min-height: 338px;
                                    max-height: 375px;
                                    overflow: auto;
                                  ">
                                  <mat-list-option *ngFor="
                                      let tab of currentCommonCV.unverifiedTabs
                                    " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                    {{ tab.section }}
                                  </mat-list-option>
                                </mat-selection-list>
                              </div>
                              <div role="list" aria-label="verified item list" class="import__list" fxLayout="column"
                                fxLayoutAlign="center center">
                                <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                  VERIFIED
                                </p>
                                <mat-selection-list style="
                                    width: 100%;
                                    border-radius: 5px;
                                    border: rgb(225, 225, 225) 1px solid;
                                    padding-top: 0px;
                                    max-height: 375px;
                                    min-height: 338px;
                                    overflow: auto;
                                  ">
                                  <mat-list-option *ngFor="
                                      let tab of currentCommonCV.verifiedTabs
                                    " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                    {{ tab.section }}
                                  </mat-list-option>
                                </mat-selection-list>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 
                    ▀███▀▀▀██▄                                                    ▀███       ▄▄█▀▀▀█▄█████▀   ▀███▀
                      ██   ▀██▄                                                     ██     ▄██▀     ▀█ ▀██     ▄█  
                      ██   ▄██  ▄▄█▀██▀███▄███ ▄██▀███ ▄██▀██▄▀████████▄  ▄█▀██▄    ██     ██▀       ▀  ██▄   ▄█   
                      ███████  ▄█▀   ██ ██▀ ▀▀ ██   ▀▀██▀   ▀██ ██    ██ ██   ██    ██     ██            ██▄  █▀   
                      ██       ██▀▀▀▀▀▀ ██     ▀█████▄██     ██ ██    ██  ▄█████    ██     ██▄           ▀██ █▀    
                      ██       ██▄    ▄ ██     █▄   ████▄   ▄██ ██    ██ ██   ██    ██     ▀██▄     ▄▀    ▄██▄     
                    ▄████▄      ▀█████▀████▄   ██████▀ ▀█████▀▄████  ████▄████▀██▄▄████▄     ▀▀█████▀      ██      
                   -->
                  <div class="tab-pane active" [ngClass]="{
                    showTab: selectedSettingTab == 2,
                    hideTab: selectedSettingTab != 2
                  }">
                    <div fxLayout="row" fxLayoutAlign="space-between">
                      <div class="card-instructions text-center">
                        <span>Please select your Personal CV (pdf)
                          file.</span><br />
                        <span>We support the following formats.</span>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="import__instructions">
                      <div class="text-center">
                        <img class="file-type" alt="we support PDF" role="img" src="../../assets/images/pdf.png"
                          (click)="pdfSelected = true" />
                        <br />
                        <span class="upload-info-link" (click)="openPDFModal()">How to upload PDF </span><span
                          class="fa" (click)="openPDFModal()">
                          &#xf05a;</span>
                      </div>
                    </div>
                    <br>

                    <div class="import__info" role="region" aria-label="upload info">
                      <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                        <div class="inputWrapper" style="cursor: pointer;" *ngIf="!personalCV && !loading">
                          <p class="card--h5-small" style="cursor: pointer;"
                            [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                            SELECT FILE
                          </p>
                          <input [disabled]="personalCV || loading" class="fileInput" #fileUpload id="image" type="file"
                            (click)="fileUpload.value = null" (change)="fileChangeEvent($event,'personalCV')"
                            accept="application/pdf,.pdf,.xml" required style="cursor: pointer;"
                            aria-label="file import button" role="button" />
                        </div>
                        <div class="inputWrapper"
                          [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : 'rgb(204, 204, 204)' }"
                          *ngIf="personalCV || loading" style="cursor: pointer;">
                          <p class="card--h5-small" style="cursor: pointer;"
                            [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                            SELECT FILE
                          </p>
                          <input [disabled]="personalCV || loading" style="cursor: pointer;" class="fileInput"
                            #fileUpload id="image" type="file" (click)="fileUpload.value = null"
                            (change)="fileChangeEvent($event,'personalCV')" accept="application/pdf,.pdf" required
                            aria-label="file import button" role="button" />
                        </div>
                        <button [disabled]="personalCV || loading" type="button" class="button button--dark-grey"
                          (click)="upload('CustomCV'); uploaded = true" aria-label="file upload button" role="button">
                          UPLOAD
                        </button>
                      </div>
                      <!-- File Showing temperory Information -->
                      <div>
                        <p *ngIf="tempImportFilePersonalCV && !personalCV" class="click-upload-text">Click [UPLOAD] to
                          extract and import file into your
                          profile</p>
                        <div *ngIf="tempImportFilePersonalCV && !personalCV" class="import__file-instructions">
                          <div fxLayout="row" fxLayoutAlign="space-between">
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">File Name:</span>
                              {{ tempImportFilePersonalCV.name }}
                            </div>
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">File Size:</span>
                              {{ tempImportFilePersonalCV.size / 1000 }}KB
                            </div>
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">Last Modified:</span>
                              {{ tempImportFilePersonalCV.lastModifiedDate | date }}
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="space-between">
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div class="fileDesc row"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700 upload-status">
                                Upload Status:
                              </span>
                              {{ uploadCompletionStatus }}
                              <mat-spinner *ngIf="
                                  uploadCompletionStatus === 'Uploading' ||
                                  uploadCompletionStatus === 'Extracting'
                                " [diameter]="30" style="margin-left: 10px"></mat-spinner>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="personalCV && importedItems[0].dataSource === 'Custom_CV'" class="import__info">
                          <div fxLayout="row" fxLayoutAlign="space-between">
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">File Name:</span>
                              {{ personalCV.uploadedFileName }}
                            </div>
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">File Size:</span>
                              {{ personalCV.uploadedFileSize }}
                            </div>
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">Last Modified:</span>
                              {{
                              personalCV.uploadedFileLastModified
                              | date
                              }}
                            </div>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="space-between">
                            <div class="fileDesc" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700">Uploaded:</span>
                              {{ personalCV.createdDateTime | date }}
                            </div>
                            <div class="fileDesc row"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span class="fw700 upload-status">
                                Upload Status:
                              </span>
                              Completed
                            </div>
                          </div>
                          <div class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                            <p class="m-0" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#2196f3' }">
                              The following sections have been updated in
                              profile. Please visit each section, verify and
                              save.
                            </p>
                          </div>
                        </div>
                        <div role="region" *ngIf="personalCV && importedItems[0].dataSource === 'Custom_CV'">
                          <hr />
                          <div role="group" class="fileDesc" fxLayout="row" fxLayoutAlign="space-around">
                            <div role="list" aria-label="unverified item list" class="import__list" fxLayout="column"
                              fxLayoutAlign="center center">
                              <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                UNVERIFIED
                              </p>
                              <mat-selection-list style="
                                  width: 100%;
                                  border-radius: 5px;
                                  border: rgb(225, 225, 225) 1px solid;
                                  padding-top: 0px;
                                  min-height: 338px;
                                  max-height: 375px;
                                  overflow: auto;
                                ">
                                <mat-list-option *ngFor="
                                    let tab of personalCV.unverifiedTabs
                                  " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                  {{ tab.section }}
                                </mat-list-option>
                              </mat-selection-list>
                            </div>
                            <div role="list" aria-label="verified item list" class="import__list" fxLayout="column"
                              fxLayoutAlign="center center">
                              <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                VERIFIED
                              </p>
                              <mat-selection-list style="
                                  width: 100%;
                                  border-radius: 5px;
                                  border: rgb(225, 225, 225) 1px solid;
                                  padding-top: 0px;
                                  max-height: 375px;
                                  min-height: 338px;
                                  overflow: auto;
                                ">
                                <mat-list-option *ngFor="
                                    let tab of personalCV.verifiedTabs
                                  " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                  {{ tab.section }}
                                </mat-list-option>
                              </mat-selection-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 

                █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗    ████████╗██████╗  █████╗ ███╗   ██╗███████╗███████╗███████╗██████╗ 
                ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝    ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔════╝██╔════╝██╔══██╗
                ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║          ██║   ██████╔╝███████║██╔██╗ ██║███████╗█████╗  █████╗  ██████╔╝
                ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║          ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔══╝  ██╔══╝  ██╔══██╗
                ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║          ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║     ███████╗██║  ██║
                ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝          ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝

                -->
                  <div class="tab-pane active" [ngClass]="{
                    showTab: selectedSettingTab == 3,
                    hideTab: selectedSettingTab != 3
                  }">
                    <div fxLayout="row" fxLayoutAlign="space-between">
                      <div class="card-instructions text-center">
                        <br>
                        <span>You can import your existing profile from different profound impact sites.</span>
                        <br>
                        <span>Our system indicate that your profile exists in the following websites. Please select the website from drop down to start the import process.</span><br />
                      </div>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="transer-section">
                      <div class="text-center transer-contain">
                        <mat-form-field class="requestField">
                          <mat-label *ngIf="transferSelected == 'N/A' ? true: false">Website</mat-label>
                          <mat-select name="transferVaraible" [disabled]="profoundImpactCV || loading" [(value)]="transferSelected">
                            <mat-option *ngFor="let transfer of transferDataSource; let i = index" [value]="transfer">
                              {{transfer.sourceClientName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        
                        <button [disabled]="profoundImpactCV || loading" type="button" class="m-3 button button--dark-grey"
                        (click)="transferAccount(transferSelected); uploaded = true" aria-label="file upload button"
                        role="button">
                        START TRANSFER
                        </button>
                      </div>
                    </div>
                    <br/>

                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                      <div class="fileDesc row" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                        <span id="transfer-status" class="fw700 upload-status">
                          Transfer Status:
                        </span>
                        {{ transferCompletionStatus }}
                        <mat-spinner *ngIf="
                        transferCompletionStatus === 'InProcess'
                          " [diameter]="30" style="margin-left: 10px"></mat-spinner>
                      </div>
                    </div>

                    <br>
                    
                    <div>
                      
                      <!-- accountLocator -->
                      <div *ngIf="transferDataOtherClient.length !== 0">
                        <hr />
                        <div fxLayout="row" fxLayoutAlign="space-between">
                          <div class="card-instructions text-center">
                            <br>
                            <span>Didnt find your profile in the above list? let us help you find.</span>
                            <br>
                            <span>Please select the website from the drop down below and provide your email
                              address.</span><br />
                          </div>
                        </div>
  
                        <br />
  
                        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="transer-section">
                          <div class="text-center transer-contain">
                            <mat-form-field class="requestField">
                              <mat-label *ngIf="otherClientSelected == 'N/A' ? true: false">Website</mat-label>
                              <mat-select name="otherClientVaraible" [(value)]="otherClientSelected">
                                <mat-option *ngFor="let transfer of transferDataOtherClient; let i = index" [value]="transfer">
                                  {{transfer.sourceClientName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
  
                            <mat-form-field class="example-full-width" appearance="fill">
                              <mat-label *ngIf="email == '' ? true : false">Email</mat-label>
                              <input type="email" matInput required [(ngModel)]="email" [value]="email" placeholder="Ex. pat@profoundimpact.com">
                            </mat-form-field>
  
                            <button type="button" class="m-3 button button--dark-grey"
                              (click)="accountLocator(email,otherClientSelected); uploaded = true" aria-label="file upload button"
                              role="button">
                              Request
                            </button>
                            
                            <div *ngIf="transferoutgoingRequests.length !== 0">
                              <H2>Request Status</H2>
                              <br>
                              <div *ngFor="let request of transferoutgoingRequests.slice(0,1); let i = index">
                                <span>{{'WEBSITE:' + request.requestClient+' | EMAIL:'+request.requestorEmail + ' | STATUS:' + request.status}}</span>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>

                      <!-- accountVerifier -->
                      <div *ngIf="transferincomingRequests.length !== 0 && transferincomingRequests[0].status ==='Pending'">
                        <br>
                        <hr />
                        <div fxLayout="row" fxLayoutAlign="space-between">
                          <div class="card-instructions text-center">
                            <br>
                            <span>If you have sent a request to verify your account for this website</span>
                            <br>
                            <span>Please select the email and confirm your request.</span><br />
                          </div>
                        </div>
                        
                        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="transer-section">
                          <div class="text-center transer-contain">

                            <div *ngFor="let request of transferincomingRequests; let i = index">
                              <span>{{'WEBSITE:' + request.requestClient+' | EMAIL:'+request.requestorEmail + ' | STATUS:' + request.status}}</span>
                              <button *ngIf="request.status ==='Pending'" type="button" class="m-1 button button--dark-grey"
                                (click)="accountVerifier(request,'Approved'); uploaded = true" aria-label="file upload button"
                                role="button">
                                Approve
                              </button>
                              <button *ngIf="request.status ==='Pending'" type="button" class="m-1 button button--dark-grey"
                                (click)="accountVerifier(request,'Denied'); uploaded = true" aria-label="file upload button"
                                role="button">
                                Deny
                              </button>
                            </div>
                            
  
                          </div>
                        </div>
                      </div>

                    </div>

                    <br />

                    <div class="import__info" role="region" aria-label="upload info">
                      
                      <br />
                      <!-- File Showing temperory Information -->
                      <div>
                        <!-- Verified and UnVerified -->
                        <div role="region" *ngIf="profoundImpactCV && importedItems[0].dataSource === 'PI-Profile'">
                          <hr />
                          <br>
                          <div id="PI-Import-Anchor" class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                            <p class="m-0" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#2196f3' }">
                              The following sections have been updated in
                              profile. Please visit each section, verify and
                              save.
                            </p>
                          </div>
                          <br>
                          
                          <div role="group" class="fileDesc" fxLayout="row" fxLayoutAlign="space-around">
                            <div role="list" aria-label="unverified item list" class="import__list" fxLayout="column"
                              fxLayoutAlign="center center">
                              <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                UNVERIFIED
                              </p>
                              <mat-selection-list style="
                                  width: 100%;
                                  border-radius: 5px;
                                  border: rgb(225, 225, 225) 1px solid;
                                  padding-top: 0px;
                                  min-height: 338px;
                                  max-height: 375px;
                                  overflow: auto;
                                ">
                                <mat-list-option *ngFor="
                                    let tab of profoundImpactCV.unverifiedTabs
                                  " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                  {{ tab.section }}
                                </mat-list-option>
                              </mat-selection-list>
                            </div>
                            <div role="list" aria-label="verified item list" class="import__list" fxLayout="column"
                              fxLayoutAlign="center center">
                              <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                VERIFIED
                              </p>
                              <mat-selection-list style="
                                  width: 100%;
                                  border-radius: 5px;
                                  border: rgb(225, 225, 225) 1px solid;
                                  padding-top: 0px;
                                  max-height: 375px;
                                  min-height: 375px;
                                  overflow: auto;
                                ">
                                <mat-list-option *ngFor="
                                    let tab of profoundImpactCV.verifiedTabs
                                  " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                  {{ tab.section }}
                                </mat-list-option>
                              </mat-selection-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 
                    ██╗   ██╗██████╗ ██╗      ██████╗  █████╗ ██████╗     ██╗  ██╗██╗███████╗████████╗ ██████╗ ██████╗ ██╗   ██╗
                    ██║   ██║██╔══██╗██║     ██╔═══██╗██╔══██╗██╔══██╗    ██║  ██║██║██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗╚██╗ ██╔╝
                    ██║   ██║██████╔╝██║     ██║   ██║███████║██║  ██║    ███████║██║███████╗   ██║   ██║   ██║██████╔╝ ╚████╔╝ 
                    ██║   ██║██╔═══╝ ██║     ██║   ██║██╔══██║██║  ██║    ██╔══██║██║╚════██║   ██║   ██║   ██║██╔══██╗  ╚██╔╝  
                    ╚██████╔╝██║     ███████╗╚██████╔╝██║  ██║██████╔╝    ██║  ██║██║███████║   ██║   ╚██████╔╝██║  ██║   ██║   
                    ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝  ╚═╝╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝   ╚═╝   
                  -->
                  <div role="region" aria-label="upload history" class="tab-pane h-100 active" [ngClass]="{
                      showTab: selectedSettingTab == 4,
                      hideTab: selectedSettingTab != 4
                    }">
                    <div>
                      <div *ngIf="noHistory" fxLayout="row" fxLayoutAlign="center center" class="w100">
                        <div class="fileDesc">
                          <span class="fw700">There is no upload history</span>
                        </div>
                      </div>
                      <mat-accordion *ngIf="importedItems" role="history list">
                        <mat-expansion-panel *ngFor="let item of getsortByDate(importedItems)" role="list item"
                          aria-label="history list item">
                          <mat-expansion-panel-header>
                            <div fxLayout="row" fxLayoutAlign="space-between" class="import__expansion-panel-header">
                              <div class="fileDesc">
                                <span class="fw700">Source:</span>
                                {{ item.dataSource }}
                              </div>
                              <div class="fileDesc">
                                <span class="fw700">File Name:</span>
                                {{ item.uploadedFileName }}
                              </div>
                              <div class="fileDesc">
                                <span class="fw700">File Size:</span>
                                {{ item.uploadedFileSize }}
                              </div>
                              <div class="fileDesc">
                                <span class="fw700">Uploaded:</span>
                                {{ item.createdDateTime | date }}
                              </div>
                            </div>
                          </mat-expansion-panel-header>
                          <hr />
                          <div fxLayout="column" role="details" aria-label="list item details">
                            <div fxLayout="row wrap" fxFlex="100">
                              <div *ngFor="let section of item.importStatus" fxFlex="33.33">
                                <div class="fileDesc">
                                  <span class="fw700">{{ section.section }}:
                                  </span>
                                  {{ section.status }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 
              ███╗   ███╗ ██████╗ ██████╗ ██╗██╗     ███████╗
              ████╗ ████║██╔═══██╗██╔══██╗██║██║     ██╔════╝
              ██╔████╔██║██║   ██║██████╔╝██║██║     █████╗  
              ██║╚██╔╝██║██║   ██║██╔══██╗██║██║     ██╔══╝  
              ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║███████╗███████╗
              ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚══════╝╚══════╝
            -->
            <div role="region" *ngIf="isMobile || data.LT_MD">
              <div role="navigation" aria-label="tabs">
                <div class="row">
                  <div class="col-11 import__tabs-mobile">
                    <ul class="nav nav-tabs tabs-left flex-column user-dtls">
                      <li [ngClass]="{ active: tab.active }" *ngFor="let tab of tabs">
                        <a [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#b9b9b9' }"
                          (click)="tabChange(tab)">{{
                          tab.name
                          }}</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12">
                      <h3 *ngIf="this.tabStatus !== 'N/A'" class="m-2" style="text-align: center;color: rgb(33, 150, 243);;"> You Have Already Started {{this.tabStatus}}. You Need To Complete That First In <a class="display-3" (tap)="route('/profile')" routerLinkActive="menu-item-active" [routerLink]="['/profile']" aria-label="profile"
                        role="link"> <h3 class="d-inline text-capitalize" style="text-align: center;color: rgb(33, 150, 243)!important;"> <u>Profile Section.</u></h3> 
                        </a>
                      </h3>
                    <div>
                      <!-- 
                        ██████╗ ██████╗ ███╗   ███╗███╗   ███╗ ██████╗ ███╗   ██╗     ██████╗██╗   ██╗
                        ██╔════╝██╔═══██╗████╗ ████║████╗ ████║██╔═══██╗████╗  ██║    ██╔════╝██║   ██║
                        ██║     ██║   ██║██╔████╔██║██╔████╔██║██║   ██║██╔██╗ ██║    ██║     ██║   ██║
                        ██║     ██║   ██║██║╚██╔╝██║██║╚██╔╝██║██║   ██║██║╚██╗██║    ██║     ╚██╗ ██╔╝
                        ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║    ╚██████╗ ╚████╔╝ 
                          ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝     ╚═════╝  ╚═══╝  
                      -->
                      <div class="active" [ngClass]="{
                          showTab: selectedSettingTab == 1,
                          hideTab: selectedSettingTab != 1
                        }">
                        <div>
                          <div class="card-instructions">
                            Please select your Canadian Common CV (CCV) file.<br /> <br />
                            We support the following formats.
                          </div>
                          <div class="import__info-mobile">
                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="import__instructions">
                              <div class="text-center">
                                <img class="file-type" alt="we support XML" role="img"
                                  src="../../assets/images/XML-Recommended.png" (click)="xmlSelected = true" />
                                <br />
                                <span class="upload-info-link" (click)="openXMLModal()">How to export XML</span><span
                                  class="fa" (click)="openXMLModal()">
                                  &#xf05a;</span>
                              </div>
                              <img class="file-type" alt="we support PDF" role="img" src="../../assets/images/pdf.png"
                                (click)="pdfSelected = true" />
                            </div>
                          </div>
                          <div class="import__info-mobile pt-3" aria-label="upload info">
                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px">
                              <div class="inputWrapper-mobile cp" *ngIf="!currentCommonCV && !loading">
                                <p class="cp card--h5-small"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                                  SELECT FILE
                                </p>
                                <input [disabled]="currentCommonCV || loading" class="fileInput-mobile cp" #fileUpload
                                  id="image" type="file" (click)="fileUpload.value = null"
                                  (change)="fileChangeEvent($event,'commonCV')" accept="application/pdf,.pdf,.xml"
                                  required style="cursor: pointer;" aria-label="file import button" role="button" />
                              </div>
                              <div class="inputWrapper-mobile fileInput-wrapper-common-cv-pr"
                                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : 'rgb(204, 204, 204)' }"
                                *ngIf="currentCommonCV || loading" style="cursor: pointer;">
                                <p class="fileInput-common-cv-pr card--h5-small" style="cursor: pointer;"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                                  SELECT FILE
                                </p>
                                <input [disabled]="currentCommonCV || loading" class="fileInput-mobile ca"
                                  style="cursor: pointer;" #fileUpload id="image" type="file"
                                  (click)="fileUpload.value = null" (change)="fileChangeEvent($event,'commonCV')"
                                  accept="application/pdf,.pdf,.xml" required aria-label="file import button"
                                  role="button" />
                              </div>
                              <button [disabled]="currentCommonCV || loading" type="button"
                                class="button button--dark-grey" (click)="upload('CommonCV'); uploaded = true"
                                aria-label="file upload button" role="button">
                                UPLOAD
                              </button>
                              <span *ngIf="tempImportFile && !currentCommonCV" class="click-upload-text">Click [UPLOAD]
                                to extract and import file into
                                your profile</span>
                            </div>
                            <div role="region" *ngIf="tempImportFile && !currentCommonCV"
                              class="import__file-instructions">
                              <div fxLayout="column">
                                <div class="fileDesc">
                                  <span class="fw700">File Name:</span>
                                  {{ tempImportFile.name }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">File Size:</span>
                                  {{ tempImportFile.size / 1000 }}KB
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">Last Modified:</span>
                                  {{ tempImportFile.lastModifiedDate | date }}
                                </div>
                              </div>
                              <div fxLayout="row">
                                <div class="fileDesc">
                                  <span class="fw700">
                                    Upload Status:
                                  </span>
                                  {{ uploadCompletionStatus }}
                                  <mat-spinner *ngIf="
                                      uploadCompletionStatus === 'Uploading' ||
                                      uploadCompletionStatus === 'Extracting'
                                    " [diameter]="30" style="margin-left: 10px"></mat-spinner>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="currentCommonCV && importedItems[0].dataSource === 'CommonCV'"
                              class="import__file-instructions">
                              <div fxLayout="column" fxLayoutAlign="space-between">
                                <div class="fileDesc">
                                  <span class="fw700">File Name:</span>
                                  {{ currentCommonCV.uploadedFileName }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">File Size:</span>
                                  {{ currentCommonCV.uploadedFileSize }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">Last Modified:</span>
                                  {{
                                  currentCommonCV.uploadedFileLastModified
                                  | date
                                  }}
                                </div>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="space-between">
                                <div class="fileDesc">
                                  <span class="fw700">Uploaded:</span>
                                  {{ currentCommonCV.createdDateTime | date }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">
                                    Upload Status:
                                  </span>
                                  Completed
                                </div>
                              </div>
                              <div class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                                <p class="blue-para-text">
                                  The following sections have been updated in
                                  profile. Please visit each section, verify and
                                  save.
                                </p>
                              </div>
                            </div>
                            <div *ngIf="currentCommonCV && importedItems[0].dataSource === 'CommonCV'">
                              <hr />
                              <div role="group" class="fileDesc" fxLayout="column" fxLayoutAlign="space-around">
                                <div role="list" aria-label="unverified item list" class="w100 import__list-mobile"
                                  fxLayout="column" fxLayoutAlign="center center">
                                  <p>
                                    UNVERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      max-height: 375px;
                                      min-height: 338px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of currentCommonCV.unverifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                                <div role="list" aria-label="verified item list" class="w100" fxLayout="column"
                                  fxLayoutAlign="center center" class="w100 import__list-mobile">
                                  <p class="verified">
                                    VERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      max-height: 375px;
                                      min-height: 338px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of currentCommonCV.verifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- 
                        ▀███▀▀▀██▄                                                    ▀███       ▄▄█▀▀▀█▄█████▀   ▀███▀
                          ██   ▀██▄                                                     ██     ▄██▀     ▀█ ▀██     ▄█  
                          ██   ▄██  ▄▄█▀██▀███▄███ ▄██▀███ ▄██▀██▄▀████████▄  ▄█▀██▄    ██     ██▀       ▀  ██▄   ▄█   
                          ███████  ▄█▀   ██ ██▀ ▀▀ ██   ▀▀██▀   ▀██ ██    ██ ██   ██    ██     ██            ██▄  █▀   
                          ██       ██▀▀▀▀▀▀ ██     ▀█████▄██     ██ ██    ██  ▄█████    ██     ██▄           ▀██ █▀    
                          ██       ██▄    ▄ ██     █▄   ████▄   ▄██ ██    ██ ██   ██    ██     ▀██▄     ▄▀    ▄██▄     
                        ▄████▄      ▀█████▀████▄   ██████▀ ▀█████▀▄████  ████▄████▀██▄▄████▄     ▀▀█████▀      ██      
                      -->
                      <div class="active" [ngClass]="{
                          showTab: selectedSettingTab == 2,
                          hideTab: selectedSettingTab != 2
                        }">
                        <div>
                          <div class="card-instructions">
                            <span>Please select your Personal CV (pdf)
                              file.</span><br />
                            <span>We support the following formats.</span>
                          </div>
                          <div class="import__info-mobile">
                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="import__instructions">
                              <div class="text-center">
                                <img class="file-type" alt="we support XML" role="img" src="../../assets/images/pdf.png"
                                  (click)="pdfSelected = true" />
                                <br />
                                <span class="upload-info-link" (click)="openPDFModal()">How to upload PDF</span><span
                                  class="fa" (click)="openPDFModal()">
                                  &#xf05a;</span>
                              </div>
                            </div>
                          </div>
                          <div class="import__info-mobile pt-3" aria-label="upload info">
                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px">
                              <div class="inputWrapper-mobile cp" *ngIf="!personalCV && !loading">
                                <p class="cp card--h5-small"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                                  SELECT FILE
                                </p>
                                <input [disabled]="currentCommonCV || loading" class="fileInput-mobile cp" #fileUpload
                                  id="image" type="file" (click)="fileUpload.value = null"
                                  (change)="fileChangeEvent($event,'personalCV')" accept="application/pdf,.pdf,.xml"
                                  required style="cursor: pointer;" aria-label="file import button" role="button" />
                              </div>
                              <div class="inputWrapper-mobile fileInput-wrapper-common-cv-pr"
                                [ngStyle]="{'background-color': data.accessibilityMode ? 'black' : 'rgb(204, 204, 204)' }"
                                *ngIf="personalCV || loading" style="cursor: pointer;">
                                <p class="fileInput-common-cv-pr card--h5-small" style="cursor: pointer;"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'white' }">
                                  SELECT FILE
                                </p>
                                <input [disabled]="currentCommonCV || loading" class="fileInput-mobile ca"
                                  style="cursor: pointer;" #fileUpload id="image" type="file"
                                  (click)="fileUpload.value = null" (change)="fileChangeEvent($event,'personalCV')"
                                  accept="application/pdf,.pdf,.xml" required aria-label="file import button"
                                  role="button" />
                              </div>
                              <button [disabled]="personalCV || loading" type="button" class="button button--dark-grey"
                                (click)="upload('CustomCV'); uploaded = true" aria-label="file upload button"
                                role="button">
                                UPLOAD
                              </button>
                              <span *ngIf="tempImportFilePersonalCV && !personalCV" class="click-upload-text">Click
                                [UPLOAD]
                                to extract and import file into
                                your profile</span>
                            </div>
                            <div role="region" *ngIf="tempImportFilePersonalCV && !personalCV"
                              class="import__file-instructions">
                              <div fxLayout="column">
                                <div class="fileDesc">
                                  <span class="fw700">File Name:</span>
                                  {{ tempImportFilePersonalCV.name }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">File Size:</span>
                                  {{ tempImportFilePersonalCV.size / 1000 }}KB
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">Last Modified:</span>
                                  {{ tempImportFilePersonalCV.lastModifiedDate | date }}
                                </div>
                              </div>
                              <div fxLayout="row">
                                <div class="fileDesc">
                                  <span class="fw700">
                                    Upload Status:
                                  </span>
                                  {{ uploadCompletionStatus }}
                                  <mat-spinner *ngIf="
                                      uploadCompletionStatus === 'Uploading' ||
                                      uploadCompletionStatus === 'Extracting'
                                    " [diameter]="30" style="margin-left: 10px"></mat-spinner>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="personalCV && importedItems[0].dataSource === 'Custom_CV'"
                              class="import__file-instructions">
                              <div fxLayout="column" fxLayoutAlign="space-between">
                                <div class="fileDesc">
                                  <span class="fw700">File Name:</span>
                                  {{ personalCV.uploadedFileName }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">File Size:</span>
                                  {{ personalCV.uploadedFileSize }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">Last Modified:</span>
                                  {{
                                  personalCV.uploadedFileLastModified
                                  | date
                                  }}
                                </div>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="space-between">
                                <div class="fileDesc">
                                  <span class="fw700">Uploaded:</span>
                                  {{ personalCV.createdDateTime | date }}
                                </div>
                                <div class="fileDesc">
                                  <span class="fw700">
                                    Upload Status:
                                  </span>
                                  Completed
                                </div>
                              </div>
                              <div class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                                <p class="blue-para-text">
                                  The following sections have been updated in
                                  profile. Please visit each section, verify and
                                  save.
                                </p>
                              </div>
                            </div>
                            <div *ngIf="personalCV && importedItems[0].dataSource === 'Custom_CV'">
                              <hr />
                              <!-- Verified and UnVerified -->
                              <div role="group" class="fileDesc" fxLayout="column" fxLayoutAlign="space-around">
                                <div role="list" aria-label="unverified item list" class="w100 import__list-mobile"
                                  fxLayout="column" fxLayoutAlign="center center">
                                  <p>
                                    UNVERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      max-height: 375px;
                                      min-height: 338px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of personalCV.unverifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                                <div role="list" aria-label="verified item list" class="w100" fxLayout="column"
                                  fxLayoutAlign="center center" class="w100 import__list-mobile">
                                  <p class="verified">
                                    VERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      max-height: 375px;
                                      min-height: 338px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of personalCV.verifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 

                      █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗    ████████╗██████╗  █████╗ ███╗   ██╗███████╗███████╗███████╗██████╗ 
                      ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝    ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔════╝██╔════╝██╔══██╗
                      ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║          ██║   ██████╔╝███████║██╔██╗ ██║███████╗█████╗  █████╗  ██████╔╝
                      ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║          ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔══╝  ██╔══╝  ██╔══██╗
                      ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║          ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║     ███████╗██║  ██║
                      ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝          ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝

                      -->
                      <div class="active" [ngClass]="{
                        showTab: selectedSettingTab == 3,
                        hideTab: selectedSettingTab != 3
                      }">
                        <div fxLayout="row" fxLayoutAlign="space-between">
                          <div class="card-instructions text-center">
                            <h2> Overview</h2>
                            <br>
                            <span>Step 1: Import Profile from Profound Impact Sites </span>
                            <br>
                            <span>You can import your existing profile from different profound impact site.</span>
                            <br>
                            <span>Our system indicate that your profile exists in to the following websites. </span>
                            <br>
                            <br>
                            <span>Please select the website from drop down to start the import process.</span><br />
                          </div>
                        </div>
                        <div class="import__info-mobile">
                          <div class="text-center transer-contain">
                            <mat-form-field class="requestField">
                              <mat-label *ngIf="transferSelected == 'N/A' ? true: false">Website</mat-label>
                              <mat-select name="transferVaraible" [disabled]="profoundImpactCV || loading" [(value)]="transferSelected">
                                <mat-option *ngFor="let transfer of transferDataSource; let i = index" [value]="transfer">
                                  {{transfer.sourceClientName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            
                            <button [disabled]="profoundImpactCV || loading" type="button" class="m-3 button button--dark-grey"
                            (click)="transferAccount(transferSelected); uploaded = true" aria-label="file upload button"
                            role="button">
                            START TRANSFER
                            </button>
                          </div>
                          <br>
                          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                            <div class="fileDesc row" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                              <span id="transfer-status" class="fw700 upload-status">
                                Transfer Status:
                              </span>
                              {{ transferCompletionStatus }}
                              <mat-spinner *ngIf="
                              transferCompletionStatus === 'InProcess'
                                " [diameter]="30" style="margin-left: 10px"></mat-spinner>
                            </div>
                          </div>
                          <br>
                          <div>
                      
                            <!-- accountLocator -->
                            <div *ngIf="transferDataOtherClient.length !== 0">
                              <hr />
                              <div fxLayout="row" fxLayoutAlign="space-between">
                                <div class="card-instructions text-center">
                                  <br>
                                  <span>Didnt find your profile in the above list? let us help you find.</span>
                                  <br>
                                  <span>Please select the website from the drop down below and provide your email
                                    address.</span><br />
                                </div>
                              </div>
        
                              <br />
        
                              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="transer-section">
                                <div class="text-center transer-contain">
                                  <mat-form-field class="requestField">
                                    <mat-label *ngIf="otherClientSelected == 'N/A' ? true: false">Website</mat-label>
                                    <mat-select name="otherClientVaraible" [(value)]="otherClientSelected">
                                      <mat-option *ngFor="let transfer of transferDataOtherClient; let i = index" [value]="transfer">
                                        {{transfer.sourceClientName}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
        
                                  <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label *ngIf="email == '' ? true : false">Email</mat-label>
                                    <input type="email" matInput required [(ngModel)]="email" [value]="email" placeholder="Ex. pat@profoundimpact.com">
                                  </mat-form-field>
        
                                  <button type="button" class="m-3 button button--dark-grey"
                                    (click)="accountLocator(email,otherClientSelected); uploaded = true" aria-label="file upload button"
                                    role="button">
                                    Request
                                  </button>
                                  
                                  <div *ngIf="transferoutgoingRequests.length !== 0">
                                    <H2>Request Status</H2>
                                    <br>
                                    <div *ngFor="let request of transferoutgoingRequests.slice(0,1); let i = index">
                                      <span>{{'WEBSITE:' + request.requestClient+' | EMAIL:'+request.requestorEmail + ' | STATUS:' + request.status}}</span>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
      
                            <!-- accountVerifier -->
                            <div *ngIf="transferincomingRequests.length !== 0 && transferincomingRequests[0].status ==='Pending'">
                              <br>
                              <hr />
                              <div fxLayout="row" fxLayoutAlign="space-between">
                                <div class="card-instructions text-center">
                                  <br>
                                  <span>If you have sent a request to verify your account for this website</span>
                                  <br>
                                  <span>Please select the email and confirm your request.</span><br />
                                </div>
                              </div>
                              
                              <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="30px" class="transer-section">
                                <div class="text-center transer-contain">
      
                                  <div *ngFor="let request of transferincomingRequests; let i = index">
                                    <span>{{'WEBSITE:' + request.requestClient+' | EMAIL:'+request.requestorEmail + ' | STATUS:' + request.status}}</span>
                                    <button *ngIf="request.status ==='Pending'" type="button" class="m-1 button button--dark-grey"
                                      (click)="accountVerifier(request,'Approved'); uploaded = true" aria-label="file upload button"
                                      role="button">
                                      Approve
                                    </button>
                                    <button *ngIf="request.status ==='Pending'" type="button" class="m-1 button button--dark-grey"
                                      (click)="accountVerifier(request,'Denied'); uploaded = true" aria-label="file upload button"
                                      role="button">
                                      Deny
                                    </button>
                                  </div>
                                  
        
                                </div>
                              </div>
                            </div>
      
                          </div>
                        </div>

                        <div class="import__info-mobile pt-3" aria-label="upload info">
                          
                          <br />
                          <!-- File Showing temperory Information -->
                          <div>
                            <!-- Verified and UnVerified -->
                            <div role="region" *ngIf="profoundImpactCV && importedItems[0].dataSource === 'PI-Profile'">
                              <hr />
                              <br>
                              <div id="PI-Import-Anchor" class="fileDesc p-0" fxLayout="row" fxLayoutAlign="space-around">
                                <p class="m-0" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#2196f3' }">
                                  The following sections have been updated in
                                  profile. Please visit each section, verify and
                                  save.
                                </p>
                              </div>
                              <br>
                              
                              <div role="group" class="fileDesc" fxLayout="row" fxLayoutAlign="space-around">
                                <div role="list" aria-label="unverified item list" class="import__list" fxLayout="column"
                                  fxLayoutAlign="center center">
                                  <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                    UNVERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      min-height: 338px;
                                      max-height: 375px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of profoundImpactCV.unverifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                                <div role="list" aria-label="verified item list" class="import__list" fxLayout="column"
                                  fxLayoutAlign="center center">
                                  <p [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#808080' }">
                                    VERIFIED
                                  </p>
                                  <mat-selection-list style="
                                      width: 100%;
                                      border-radius: 5px;
                                      border: rgb(225, 225, 225) 1px solid;
                                      padding-top: 0px;
                                      max-height: 375px;
                                      min-height: 375px;
                                      overflow: auto;
                                    ">
                                    <mat-list-option *ngFor="
                                        let tab of profoundImpactCV.verifiedTabs
                                      " (click)="navigateToProfileTab(tab.key)" [value]="tab.key">
                                      {{ tab.section }}
                                    </mat-list-option>
                                  </mat-selection-list>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--
                        ██╗   ██╗██████╗ ██╗      ██████╗  █████╗ ██████╗     ██╗  ██╗██╗███████╗████████╗ ██████╗ ██████╗ ██╗   ██╗
                        ██║   ██║██╔══██╗██║     ██╔═══██╗██╔══██╗██╔══██╗    ██║  ██║██║██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗╚██╗ ██╔╝
                        ██║   ██║██████╔╝██║     ██║   ██║███████║██║  ██║    ███████║██║███████╗   ██║   ██║   ██║██████╔╝ ╚████╔╝ 
                        ██║   ██║██╔═══╝ ██║     ██║   ██║██╔══██║██║  ██║    ██╔══██║██║╚════██║   ██║   ██║   ██║██╔══██╗  ╚██╔╝  
                        ╚██████╔╝██║     ███████╗╚██████╔╝██║  ██║██████╔╝    ██║  ██║██║███████║   ██║   ╚██████╔╝██║  ██║   ██║   
                        ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝     ╚═╝  ╚═╝╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝   ╚═╝   
                      -->
                      <div role="region" aria-label="upload history" class="active" [ngClass]="{
                        showTab: selectedSettingTab == 4,
                        hideTab: selectedSettingTab != 4
                      }">
                        <div>
                          <div *ngIf="noHistory" fxLayout="row" fxLayoutAlign="center center" class="w100">
                            <div class="fileDesc">
                              <span class="fw700">There is no upload history</span>
                            </div>
                          </div>
                          <mat-accordion *ngIf="importedItems" role="history list">
                            <mat-expansion-panel *ngFor="let item of getsortByDate(importedItems)" role="list item"
                              aria-label="history list item">
                              <mat-expansion-panel-header>
                                <div fxLayout="row" fxLayoutAlign="space-between"
                                  class="import__expansion-panel-header">
                                  <div class="fileDesc">
                                    <span class="fw700">Source:</span>
                                    {{ item.dataSource }}
                                  </div>
                                  <div class="fileDesc">
                                    <span class="fw700">File Name:</span>
                                    {{ item.uploadedFileName }}
                                  </div>
                                  <!-- <div class="fileDesc">
                                  <span class="fw700">File Size:</span>
                                  {{ item.uploadedFileSize }}
                                </div> -->
                                  <div class="fileDesc">
                                    <span class="fw700">Uploaded:</span>
                                    {{ item.createdDateTime | date }}
                                  </div>
                                </div>
                              </mat-expansion-panel-header>
                              <hr />
                              <div fxLayout="column" role="details" aria-label="list item details">
                                <div fxLayout="row wrap" fxFlex="100">
                                  <div *ngFor="let section of item.importStatus" fxFlex="33.33">
                                    <div class="fileDesc">
                                      <span class="fw700">{{ section.section }}:
                                      </span>
                                      {{ section.status }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>