<div class="mini-card__container {{ backgroundColorClass }}">
  <div *ngIf="type != 'custom'" class="mini-card__container">
    <div class="mini-card__image--container--{{ imageContainerWidth }}">
      <img class="mini-card__image" [src]="imageSrc" alt="about profact impact" aria-label="card image" role="img" />
    </div>
    <div class="mini-card__content--{{ type }}">
      <div aria-label="card title" class="mini-card__title--{{ type }}">{{ title }}</div>
      <p aria-label="card text" class="mini-card__text--{{ type }}">{{ text }}</p>
      <button type="button" aria-label="card button" (click)="route()" class="mini-card__button--{{ type }} {{ buttonBackgroundClass }}">{{ buttonText }}</button>
    </div>
  </div>
  <div *ngIf="type == 'custom'">
    <ng-content></ng-content>
  </div>
</div>