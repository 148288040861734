import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})

export class SliderComponent implements OnInit {

  @Input() id: number = 1;
  slides = [
    {
      description: "loading...",
      header: "",
      imagealt: "loading",
      imagesrc: "",
      navText: "",
      navlink: ""
    }
  ];
  tempSlides = [];

  constructor(
    public ds: DataService,
    private api: HttpService,
    private router: Router
  ) { }

  ngOnInit() {
    this.api.getBanner(this.id).subscribe((res: any) => {
      console.log('RESPONSE: ', res);
      if (res['status']) {
        const data = res['record'];
        // tslint:disable-next-line: forin
        for (const row in data) {
          const temp = {};
          temp[
            'imagesrc'
          ] = `${environment.publicBucketUrl}'banner-images/'${data[row].slidepic}`;
          temp['imagealt'] = data[row].title;
          temp['navText'] = data[row].title;
          temp['navlink'] = data[row].slug;
          temp['header'] = data[row].title;
          temp['description'] = data[row].description;
          this.tempSlides.push(temp);
        }
        this.slides = this.tempSlides;
        console.log('SLIDES: ', this.slides);
      }
    });
  }

  goToStory(slide: any) {
    this.router.navigate(['/stories/' + slide.navlink]);
  }

}