import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AwsCognitoService } from './aws-cognito.service';
import { AmplifyService } from 'aws-amplify-angular';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable()
export class TokenResolverService implements Resolve<any> {
  decoded: any
  user: any

  constructor(private location: Location,
              private awsCognitoService: AwsCognitoService,
              private router: Router,
              private amplify: AmplifyService,
              private util: UtilityService,
              public _toaster: ToastrManager
              ) { }

  resolve(): Observable<any | null> {

    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: string = urlParams.get('code');
    const error: string = urlParams.get('error');
    
    if(error === 'invalid_request') {
      this._toaster.errorToastr('Please contact admin to resolve the issue', 'Oops!', {
        toastTimeout: 60000
      });
    }

    if (!code) {
      return of(null);
    }

    this.getLinkedinProfile()
    this.location.replaceState(window.location.pathname);
    this.amplify.auth().currentAuthenticatedUser().then((user) => {
      if(user.attributes['custom:uuidPerson']){
        this.util.setUser(user);
        this.router.navigate(['./'])
      } else {
        localStorage.setItem('linkedinUsername', user.username)
        localStorage.setItem('linkeinEmail', user.attributes.email)
      }
    })
    

    // return this.getTokenDetailsFromCognito(code).pipe(
    //   finalize(() => {
    //     this.location.replaceState(window.location.pathname);
    //     this.amplify.auth().currentAuthenticatedUser().then((user) => {
    //       this.util.setUser(user);
    //     })
    //   })
    // );
    
  }


   async getLinkedinProfile() {
    
    // const response = axios.get('https://linkedin.com/in/');
    // console.log(response);

    
      // let url  = 'https://linkedin.com/in/';

      // let response = await fetch(url, {
      //   method: 'GET',
      // });
      // console.log('response', response);
   
    // let newWindow = await open('https://linkedin.com/in/','targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=350,height=250')
    // newWindow.focus();

    // window.open('https://linkedin.com/in/','targetWindow','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=350,height=250')
    // console.log(location.href)
    //window.open('https://linkedin.com/in/')
    // this.http.get('https://linkedin.com/in/').subscribe(res => {
    //   console.log('Linkedin Login', res)
    // })
    // http.request('https://linkedin.com/in/', response => {
    //   response.on('data', chunk => {
    //     console.log(chunk);
    //   });
    // }).on('error', err => {
    //   console.error(err);
    // });
  }

  getTokenDetailsFromCognito(code: string): Observable<any | null> {
    return this.awsCognitoService.getTokenDetailsFromCognito(code).pipe(
      switchMap((response: any) => {
        console.log('Response: ', response);

        const user = this.amplify.auth().currentAuthenticatedUser();
        console.log('COGNITO SIGNIN USER', user)

        return of(response);
      }),
      catchError ((error) => {
        return error;
      })
    );
  }
}