import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToGroup() {
    this.router.navigate(['/wewdc']);
  }

}
