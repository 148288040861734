import { environment } from './environments/environment';

const awsmobile = {
  Auth: {
    identityPoolId: environment.identityPoolId,
    region: environment.region,
    identityPoolRegion: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    oauth: {
      domain: `${environment.loginUrl}`,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: environment.redirectUrl,
      redirectSignOut: environment.logout,
      responseType: "code"
    }
  },
  Storage: {
    AWSS3: {
      bucket: environment.bucket,
      region: environment.region,
    }
  }
  // Auth: {
  //   identityPoolId: 'us-east-1:03fc6078-f86d-4ada-b2de-79c84a3f66c0',
  //   region: 'us-east-1',
  //   identityPoolRegion: 'us-east-1',
  //   userPoolId: 'us-east-1_tAGiDsL0L',
  //   userPoolWebClientId: '572dhftn02pe1s6td0hib3t1k0'
  // },
  // Storage: {
  //   AWSS3: {
  //     bucket: 'profound-impact-users',
  //     region: 'us-east-1',
  //   }
  // }
  // Auth: {
  //   identityPoolId: 'us-east-1:250d531a-3607-4ed6-9b09-fd21f1c52125',
  //   region: 'us-east-1',
  //   identityPoolRegion: 'us-east-1',
  //   userPoolId: 'us-east-1_DQin8EKMN',
  //   userPoolWebClientId: '74308co6s6oagobh0t5k6oh53a'
  // },
  // Storage: {
  //   AWSS3: {
  //     bucket: 'mvpuserprod',
  //     region: 'us-east-1',
  //   }
  // }
};


export default awsmobile;
