<div id="profile-section-education" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Education</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add education button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div class="profile__section__empty" *ngIf="!educationList || educationList.length == 0">
        Click + to add first education.
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="educationList">
        <div class="profile__section__list--item" *ngFor="let item of educationList; index as i;">
          <div *ngIf="item.name != 'NONE' || educationList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <span>Institution:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName" class="col-md-6" role="list item property value">
                <span>Short Name:</span>
                <span class="fw500 pl05r">{{ item.shortName }}</span>
              </div>
              <div *ngIf="item.webSite" class="col-10" role="list item property value">
                <span>Website:</span>
                <span class="fw500 pl05r">{{item.webSite}}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.degree" class="col-md-6" role="list item property value">
                <span>Degree:</span>
                <span class="fw500 pl05r">{{ item.degree }}</span>
              </div>
              <div *ngIf="item.major" class="col-md-6" role="list item property value">
                <span>Major:</span>
                <span class="fw500 pl05r">{{ item.major }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12" role="list item property value">
                <div *ngIf="item.graduationDateFlag">
                  <span>Graduation Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.graduationDateFlag.day ? item.graduationDate.getDate() : '' }}
                    {{ item.graduationDateFlag.month ? months[+item.graduationDateFlag.month - 1] : '' }}
                    {{ item.graduationDateFlag.year ? item.graduationDateFlag.year : '' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || educationList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit education button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete education button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Education</div>
        <div *ngIf="educationModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- INSTITUTION -->
        <div class="profile__form--institution">
          <label class="form__label">Institution <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name" type="text"
            [(ngModel)]="educationModel.name" [ngbTypeahead]="search" #toNode_name="ngModel"
            placeholder="Ex. University of Waterloo" [disabled]="deleteMode" aria-label="institution - required"
              />
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="currentItem_toNode_shortName" name="currentItem_toNode_shortName"
              [ngModel]="educationModel.shortName" placeholder="Ex. UW" [disabled]="deleteMode"
            (ngModelChange)="educationModel.shortName = this.escape.it($event)"
            aria-label="short name" />
        </div>
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="currentItem_toNode_webSite" name="currentItem_toNode_webSite"
              [ngModel]="educationModel.webSite" placeholder="Ex. https://uwaterloo.ca/"
              [pattern]="websiteRegExp"
              #website="ngModel"
            (ngModelChange)="educationModel.webSite = this.escape.it($event);"
            [disabled]="deleteMode" aria-label="website" [ngClass]="{'ng-invalid': !website.valid }"/>
        </div>
        <!-- DEGREE -->
        <div class="profile__form--degree">
          <label class="form__label">Degree <span class="form__required">*</span></label>
          <select class="form__control" required id="currentItem_edge_degree" name="currentItem_edge_degree"
            [(ngModel)]="educationModel.degree" 
            (ngModelChange)="onDegreeChange($event)" #degree="ngModel" [disabled]="deleteMode" aria-label="degree - required"
            [ngClass]="{'ng-invalid': isDegreeRequired}">
            <option value="Select">Select</option>
            <option *ngFor="let degree of degrees" [value]="degree">
              {{ degree }}
            </option>
          </select>
        </div>
        <!-- MAJOR -->
        <div class="profile__form--major">
          <label class="form__label">Major</label>
          <input class="form__control" id="currentItem_edge_major" name="currentItem_edge_major"  
            [ngModel]="educationModel.major" placeholder="Ex. Computer Science" [disabled]="deleteMode"
            (ngModelChange)="educationModel.major = this.escape.it($event)"
            aria-label="major" />
        </div>
        <!-- GRAD YEAR -->
        <div class="profile__form--grad-year">
          <label class="form__label">Grad Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('graduationDate', 'year', gradYear.value)" required
            [(ngModel)]="educationModel.graduationDate.year" 
            name="grad_year" #gradYear [disabled]="deleteMode"
            aria-label="grad year - required"  
            [ngClass]="{'ng-invalid': isGradYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="
              educationModel.graduationDate.year == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- GRAD MONTH -->
        <div class="profile__form--grad-month" *ngIf="educationModel.graduationDate.year">
          <label class="form__label">Grad Month</label>
          <select class="form__control" (change)="onDateChange('graduationDate', 'month')"
            [(ngModel)]="educationModel.graduationDate.month" name="grad_month" [disabled]="deleteMode"
            aria-label="grad month">
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- GRAD DAY -->
        <div class="profile__form--grad-day"
          *ngIf="educationModel.graduationDate.year && educationModel.graduationDate.month">
          <label class="form__label">Grad Day</label>
          <select class="form__control" (change)="onDateChange('graduationDate', 'day')"
            [(ngModel)]="educationModel.graduationDate.day" name="grad_day" [disabled]="deleteMode"
            aria-label="grad day"  >
            <option value="">Select Day</option>
            <option *ngFor="let item of dayValue" [selected]="
              educationModel.graduationDate.day == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ADDITIONAL INFORMATION -->
        <div class="profile__form--additional-info">
          <label class="form__label">Additional Information</label>
          <textarea class="form__control text-area" id="currentItem_edge_summary" name="currentItem_edge_summary" [ngModel]="educationModel.summary"
            placeholder="Ex. Graduated with honors with a 4.0 GPA" autosize [minRows]="5" [maxRows]="10" [disabled]="deleteMode" aria-label="additional information"
            (ngModelChange)="educationModel.summary = this.escape.it($event)"
             ></textarea>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && educationModel.dataSource === 'User') ||
                (thisForm.form.invalid && educationModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-institution" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Institution is required</strong>
        </div>
        <div class="row tac profile__form--error-degree" *ngIf="isDegreeRequired">
          <strong>Degree is required</strong>
        </div>
        <div class="row tac profile__form--error-grad-year" *ngIf="isGradYearRequired">
          <strong>Grad Year is required</strong>
        </div>
        <ng-container *ngIf="!displayDeleteButton">
          <div class="row tac profile__form--error-invalid-website-url" *ngIf="!website.valid">
            <strong>Not a valid website url</strong>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>