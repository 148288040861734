<div class="col-md-12" id="fbheader">
    <!-- <div class="header_comment">
        <div class="row">
            <div class="col-12 text-right">
                <span class="sort_title">Sort by</span>
                <select aria-label="sort by selection" class="sort_by ml-2" #sortcomments (change)="sortComments(sortcomments.value)">
                    <option value="new">Newest</option>
                    <option value="old">Oldest</option>
                </select>
            </div>
        </div>
    </div> -->
    <div class="body_comment mt-3">
        <p *ngIf="!data.authenticated" style="font-size:14px; height: 100px; display: grid; align-items: center; color: #cc1c3d;" class="text-center badge">You must be logged in to post something</p>
        <!-- Post comment box -->
        <div class="row" *ngIf="data.authenticated">
            <div class="main_box_comment col-md-12" *ngIf="service.isActive._value">
                <textarea aria-label="commentor text area" class="commentar" [disabled]="!data.authenticated" #message placeholder="Share your thoughts..."></textarea>
                <div class="box_post">
                    <div class="pull-right">
                        <button type="button" *ngIf="data.authenticated" [disabled]="!data.authenticated || service.isPosting" (click)="post_comment(message.value)" aria-label="post button">Post <i class="far fa-paper-plane"></i>
                            <span *ngIf="service.isPosting" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <p *ngIf="!service.isActive._value"><i class="fa fa-lock" aria-hidden="true"></i> Discussion Board has been locked by admin</p>
        </div>
        <!-- Post comment box -->
    </div>
</div>

<div class="col-md-12" id="fbcomment" style="overflow-y: auto;" tabindex="0">
    <div class="body_comment">
        <!-- comments section -->
        <div class="row">
            <ul id="list_comment" class="col-md-12" *ngIf="comments">
                <ng-template #recursiveList let-comments>
                    <ng-container *ngFor="let item of comments">
                        <li class="box_result row ml-0 mr-0" *ngIf="!item.isDeleted">
                            <div class="wrap-comment">
                                <div class="avatar_comment float-left" *ngFor="let user of commenter | filter : 'uuid' : item.commenterUuid">
                                    <img *ngIf="user.logoUrl != null && user.profileStatus === 'Public'" src="https://{{profilePicBucket}}.s3.amazonaws.com/profile-pictures/{{user.logoUrl}}" alt="avatar" role="img" />
                                    <i *ngIf="user.logoUrl != null && item.profileStatus === 'Private'" class="fa fa-user user-private-pic" aria-hidden="true"></i>
                                    <i *ngIf="user.logoUrl == null && user.profileStatus === 'Public'" class="fa fa-user user-private-pic" aria-hidden="true"></i>
                                    <i *ngIf="user.logoUrl == null && user.profileStatus === 'Private'" class="fa fa-user user-private-pic" aria-hidden="true"></i>
                                    <p class="moderator" title="MODERATOR" *ngIf="user.isModerator"><i class="fas fa-star"></i></p>
                                    <h4 *ngFor="let user of commenter | filter : 'uuid' : item.commenterUuid" title="{{user.name}}" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                        {{ (user.name.length>50)? (user.name | slice:0:50)+'...':(user.name) }}
                                    </h4>
                                </div>
                                <div class="result_comment float-left">
                                    <!-- [innerHTML]="urlify(item.html)" -->
                                    <p *ngIf="!item.isEditing && !item.isDeleted" >{{item.html}}</p>
                                    <p *ngIf="item.isDeleted">[Deleted]</p>
                                    <div class="box_comment mb-2" *ngIf="item.isEditing">
                                        <textarea aria-label="commentor reply text area" class="comment_replay" #savemsg>{{item.html}}</textarea>
                                        <div class="box_post">
                                            <div class="pull-right">
                                                <button class="cancel" (click)="cancelEditing(item)" type="button" aria-label="cancel button">Cancel</button>
                                                <button type="button" (click)="saveEditing(savemsg.value, item)" aria-label="update button">Update <i class="fas fa-pen-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tools_comment" *ngIf="data.authenticated && service.isActive._value">
                                        <div class="float-left mr-1" *ngIf="!item.isDeleted && data.authenticated && service.userID === item.commenterUuid || service.isModerator._value && data.authenticated && !item.isDeleted">
                                            <a class="delete" (click)="deleteComment(item)" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Delete</a>
                                            <span aria-hidden="true"> | </span>
                                        </div>
                                        <div class="float-left mr-1" *ngIf="!item.isDeleted && data.authenticated && service.userID === item.commenterUuid || service.isModerator._value  && data.authenticated && !item.isDeleted">
                                            <a class="edit " (click)="editComment(item)" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Edit</a>
                                            <span aria-hidden="true"> | </span>
                                        </div>
                                        <div class="float-left mr-1 " *ngIf="!item.isDeleted && data.authenticated ">
                                            <a class="replay " (click)="reply(item)" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }" #comment>Reply</a>
                                            <span aria-hidden="true"> | </span>
                                        </div>
                                        <div class="float-left mr-1 " *ngIf="!item.isDeleted && data.authenticated ">
                                            <i class="fa fa-thumbs-o-up " (click)="likeComment(item) "></i> <span class="count ml-1 " *ngIf="item.likes> 0">{{item.likes}}</span>
                                            <span aria-hidden="true"> | </span>
                                        </div>
                                        <span *ngIf="!item.modifiedDateTime" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#90949C' }">{{item.createdDateTime | date: 'd MMM, yy - H:m'}}</span>
                                        <span *ngIf="item.modifiedDateTime" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#90949C' }">{{item.modifiedDateTime | date: 'd MMM, yy - H:m'}}</span>
                                        <span aria-hidden="true">  </span>
                                        <span *ngIf="item.isModerated" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#90949C' }">[Edited By Moderator]</span>
                                    </div>
                                    <li class="box_reply row col-12 ml-0 mr-0 p-0" *ngIf="item.expanded">
                                        <div class="col-md-12 reply_comment p-0">
                                            <div class="wrap-reply-box">
                                                <div class="avatar_comment float-left">
                                                    <img *ngIf="data.authenticated && data.currentUser.logoUrl" alt="avatar" role="img" src="https://{{profilePicBucket}}.s3.amazonaws.com/profile-pictures/{{data.currentUser.logoUrl}}">
                                                    <i *ngIf="data.authenticated && !data.currentUser.logoUrl" class="fa fa-user user-private-pic" aria-hidden="true"></i>
                                                    <i *ngIf="!data.authenticated" class="fa fa-user user-private-pic" aria-hidden="true"></i>
                                                </div>
                                                <div class="box_comment float-left">
                                                    <textarea aria-label="comment reply text area" class="comment_replay" #replymsg placeholder="Reply to {{item.name}}"></textarea>
                                                    <div class="box_post">
                                                        <div class="pull-right text-center">
                                                            <button class="cancel" (click)="reply(item)" type="button" aria-label="cancel button">Cancel</button>
                                                            <button type="button" (click)="post_reply(replymsg.value, item)" aria-label="reply button">Reply <i class="fas fa-reply"></i>
                                                                <span *ngIf="service.isReplying" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </div>
                            <ul class="child_replay" *ngIf="item.replies.length> 0" #postcomment>
                                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.replies }"></ng-container>
                            </ul>
                        </li>
                    </ng-container>   
                </ng-template>
                <ng-container class="parent-comment" *ngTemplateOutlet="recursiveList; context:{ $implicit: comments }"></ng-container>
            </ul>
        </div>
        <!-- comments section -->
    </div>
</div>