import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpService } from './http.service';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private data: DataService,
    private api: HttpService,
    private router: Router,
    public amplifyService: AmplifyService,
  ) { }

  logger(log: any, title: string) {
    if (this.data.debug) {
      console.log(`----------  ${title}  ----------`);
      console.log(log);
    }
  }

  checkSpaces(str: string) {
    this.logger(/\S/.test(str), 'Check Spaces');
    return /\S/.test(str);
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname: string, value: string) {
    document.cookie = `${cname}=${value}`;
  }

  setUser(user: any) {
    if(user.attributes['custom:uuidPerson']){
      this.logger(user, 'CURRENT USER: ');
      localStorage.setItem('cognitoUsername', user.username);
      this.data.authenticated = true;
      this.data.authenticatedSubject = true;
      console.log('U Auth:', this.data.authenticated)
      localStorage.setItem('authenticated', 'true');
      this.data.cognitoUsername = user.username;
      localStorage.setItem('uuidPerson', user.attributes['custom:uuidPerson']);
      this.data.uuidPerson = user.attributes['custom:uuidPerson']
      this.getUser();
    }
  }

  getUser() {
    this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then((data) => {
        localStorage.setItem('jwt', JSON.stringify(data.signInUserSession.idToken.jwtToken));
        this.data.jwt = data.signInUserSession.idToken.jwtToken;
        this.refreshUser();
      }
    );
  }

  refreshUser() {
    if (this.data.authenticated) {
      
      // SETS PAGES AVAILABLE FOR SEARCH FUNCTIONALITY
      this.data.pages = [
        'Home',
        'Faculty',
        'Alumni',
        'Academic Family Tree',
        'Mentoring Relation',
        'Publication',
        'Profound Impact Day',
        'Profound Insight',
        'Profile',
        'Import',
        'Settings',
        'Contact us',
        'Privacy Policy',
        'About us',
        'Join us',
        'Assessment Tool',
        'System Requirements'
      ];

      // GETS USER PROFILE COMPLETION DATA
      this.api
        .getProfileCompletion(this.data.jwt)
        .subscribe((response: any) => {

          // FORCE LOGOUT IF REQUIRED
          if (response.status === 2 && response.message === 'ForceLogout') {
            this.data.forceLogout = true;
          }

          // SET GLOBAL PROPERTIES
          this.data.currentUser = response.data.person;
          this.logger(response.data, 'PROFILE COMPLETION');
          this.data.profileCompletion = response.data;
          this.data.userProfileStatus = response.data.person.profileStatus;
          this.data.userProfileStatusFlag = response.data.person.profileStatus == 'public' ? true : false;
          this.data.completionWidth = this.data.profileCompletion.totalCompleted + '%';
          this.data.ariaValue = this.data.profileCompletion.totalCompleted + 1;
          this.data.connectionType = response.data.person.connectionType ? response.data.person.connectionType : 'email';

          this.getUserProfile();

          this.data.profileTabs = [
            {
              name: 'BASIC INFO',
              key: 0,
              active: true,
              completion: this.data.profileCompletion.basic,
            },
            {
              name: 'Experience',
              key: 1,
              active: false,
              completion: this.data.profileCompletion.experience,
            },
            {
              name: 'Education',
              key: 2,
              active: false,
              completion: this.data.profileCompletion.education,
            },
            {
              name: 'Certifications',
              key: 3,
              active: false,
              completion: this.data.profileCompletion.certification,
            },
            {
              name: 'Volunteer',
              key: 4,
              active: false,
              completion: this.data.profileCompletion.volunteer,
            },
            {
              name: 'Skills',
              key: 5,
              active: false,
              completion: this.data.profileCompletion.skill,
            },
            {
              name: 'Languages',
              key: 6,
              active: false,
              completion: this.data.profileCompletion.language,
            },
            {
              name: 'Publications',
              key: 7,
              active: false,
              completion: this.data.profileCompletion.publication,
            },
            {
              name: 'Awards',
              key: 8,
              active: false,
              completion: this.data.profileCompletion.awards,
            },
            {
              name: 'Projects',
              key: 9,
              active: false,
              completion: this.data.profileCompletion.projects,
            },
            {
              name: 'Research',
              key: 10,
              active: false,
              completion: this.data.profileCompletion.research,
            },
            {
              name: 'Patents',
              key: 11,
              active: false,
              completion: this.data.profileCompletion.patent,
            },
            {
              name: 'Academic Ancestry',
              key: 12,
              active: false,
              completion: this.data.profileCompletion.academicAncestry,
            },
            {
              name: 'Mentoring Relation',
              key: 13,
              active: false,
              completion: this.data.profileCompletion.mentoringRelation,
            },
            {
              name: 'Social Media',
              key: 14,
              active: false,
              completion: this.data.profileCompletion.socialMedia,
            },
          ];

          this.data.isLoading = false;
        });
    } else {
      // SETS PAGES AVAILABLE TO SEARCH FUNCTIONALITY
      this.data.pages = [
        'Home',
        'Profound Impact Day',
        'Profound Insight',
        'Contact us',
        'Privacy Policy',
        'About us',
        'Join us',
        'System Requirements'
      ];
    }
  }

  getUserProfile() {
    this.api.getUserProfile().subscribe((data) => {
      const res: any = data;
      this.data.logger(res, 'User Profile Response');
      this.data.userProfileData = res;
      this.data.authenticatedSubject = true;
      this.data.connectionType = res.data.person.connectionType ? res.data.person.connectionType : 'email';
    });
  }

  startTimeConversion(time: string) {
    let split = time.split(':');
    let timeString = '';
    let hour = +split[0]-5;
    if (hour >= 1 && hour <= 9) {
      timeString = (hour + '').substring(1, split[0].length) + ':' + split[1];
    }
    if (hour >= 10 && hour <= 12) {
      timeString = hour + ':' + split[1];
    }
    if (hour >= 13) {
      timeString = hour % 12 + ':' + split[1];
    }
    if (hour < 0) {
      timeString = (24 + hour) % 12 + ':' + split[1];
    }
    if (hour == 8) {
      timeString = '8' + ':' + split[1];
    }
    if (hour == 9) {
      timeString = '9' + ':' + split[1];
    }
    if (hour == 0) {
      timeString = '12' + ':' + split[1];
    }
    return timeString;
  }

  startTimeAM(time: string) {
    let split = time.split(':');
    let hour = +split[0]-5;
    if (hour >= 0 && hour <= 11) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    let username;
    let password;
    localStorage.getItem('pi|username')
      ? (username = localStorage.getItem('pi|username'))
      : (username = '');
    localStorage.getItem('pi|password')
      ? (password = localStorage.getItem('pi|password'))
      : (password = '');
    Auth.signOut({ global: true }).then((data) => { });
    localStorage.clear();
    sessionStorage.clear();
    this.data.authenticated = false;
    this.data.authenticatedSubject = false;
    localStorage.setItem('pi|username', username);
    localStorage.setItem('pi|password', password);
    console.log('DATA SERVICE', this.data);
    this.router.navigate(['/']);
  }

}
