<div aria-label="event calendar" role="dialog" class="event-calendar">
    <!-- <a href="https://myemail.constantcontact.com/Special-Edition.html?soid=1104263747568&aid=gEBmrauRYZQ" -->
        <!-- target="_blank"> -->
        <div aria-label="event calendar item" role="group" class="event-card">
            <div class="event-card-date">
                <div class="event-card-date-day">26</div>
                <div class="event-card-date-month">FEB</div>
                <div class="event-card-date-year">2021</div>
            </div>
            <div class="event-card-content">
                <div class="event-card-content-time">8:00AM</div>
                <div class="event-card-content-host">The Rotary Clubs of Waterloo Region</div>
                <div class="event-card-content-title">STATE OF THE REGION BREAKFAST</div>
                <div class="event-card-content-description">The Rotary Clubs of Waterloo Region are hosting a State of
                    the Region event with Regional Chair Karen Redman as the keynote.
                </div>
                <a href="https://myemail.constantcontact.com/Special-Edition.html?soid=1104263747568&aid=gEBmrauRYZQ" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                    <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
                </a>
            </div>
        </div>
    <!-- </a> -->
    <div class="event-card event-card-odd">
        <div class="event-card-date-odd">
            <div class="event-card-date-day">4</div>
            <div class="event-card-date-month">MAR</div>
            <div class="event-card-date-year">2021</div>
        </div>
        <div class="event-card-content">
            <div class="event-card-content-time">11:30AM</div>
            <!-- <div class="event-card-content-time">11:30AM - 1:00PM</div> -->
            <div class="event-card-content-host">Laurier and the International Women’s Forum</div>
            <div class="event-card-content-title">Women’s Leadership and Resiliency Amidst a Global Pandemic</div>
            <div class="event-card-content-description">Throughout the COVID-19 pandemic, women have demonstrated
                remarkable resiliency. On March 4, we invite you to join us as we celebrate the successes and reflect on
                the struggles of the last 12 months.
            </div>
            <a href="https://secureca.imodules.com/s/1681/15/news-events.aspx?sid=1681&gid=2&pgid=4224&cid=11830" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
            </a>
        </div>
    </div>
    <div class="event-card">
        <div class="event-card-date">
            <div class="event-card-date-day">8</div>
            <div class="event-card-date-month">MAR</div>
            <div class="event-card-date-year">2021</div>
        </div>
        <div class="event-card-content">
            <div class="event-card-content-time">9:00AM</div>
            <!-- <div class="event-card-content-time">9:00AM - 10:30AM</div> -->
            <div class="event-card-content-host">University of Waterloo</div>
            <div class="event-card-content-title">International Women's Day Breakfast</div>
            <div class="event-card-content-description">Join us for a very special University of Waterloo 2021
                International Women’s Day breakfast as we discuss how diversity and equity are critical to helping power
                shifts in science, technology, entrepreneurship, and social change.
            </div>
            <a href="https://hopin.com/events/international-womens-day-breakfast-2021" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
            </a>
        </div>
    </div>
    <div class="event-card  event-card-odd">
        <div class="event-card-date-odd">
            <div class="event-card-date-day">9</div>
            <div class="event-card-date-month">MAR</div>
            <div class="event-card-date-year">2021</div>
        </div>
        <div class="event-card-content">
            <div class="event-card-content-time">4:00PM</div>
            <!-- <div class="event-card-content-time">4:00PM - 5:30PM</div> -->
            <div class="event-card-content-host">The Bank of Monreal</div>
            <div class="event-card-content-title">BMO Celebrating Women On Boards</div>
            <div class="event-card-content-description">Join us for an exclusive virtual event to inspire women in
                leadership and business, and to announce BMO Celebrating Women On Boards five Honourees during
                International Women’s week #ChooseToChallenge.
            </div>
            <a href="https://app.glueup.com/event/bmo-celebrating-women-on-boards-30432/" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
            </a>
        </div>
    </div>
    <div class="event-card">
        <div class="event-card-date">
            <div class="event-card-date-day">10</div>
            <div class="event-card-date-month">MAR</div>
            <div class="event-card-date-year">2021</div>
        </div>
        <div class="event-card-content">
            <div class="event-card-content-time">11:30AM</div>
            <!-- <div class="event-card-content-time">9:00AM - 10:30AM</div> -->
            <div class="event-card-content-host">YW Kitchener-Waterloo</div>
            <div class="event-card-content-title">Choose to Change YW Women's Day Virtual Luncheon Fundraiser</div>
            <div class="event-card-content-description">Guest speaker @carolinecodsi, founder of Women in Governance and
                listed in the Top 100 most powerful women in Canada, will discuss her thoughts on what is hindering
                women’s economic potential.
            </div>
            <a href="https://www.canadahelps.org/en/charities/yw-kitchener-waterloo/events/choose-to-change/" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
            </a>
        </div>
    </div>
    <div class="event-card  event-card-odd">
        <div class="event-card-date-odd">
            <div class="event-card-date-day">11</div>
            <div class="event-card-date-month">MAR</div>
            <div class="event-card-date-year">2021</div>
        </div>
        <div class="event-card-content">
            <div class="event-card-content-time">1:00PM</div>
            <!-- <div class="event-card-content-time">4:00PM - 5:30PM</div> -->
            <div class="event-card-content-host">Kitchener Waterloo Community Foundation</div>
            <div class="event-card-content-title">Do More Good Dialogues</div>
            <div class="event-card-content-description">Join us as we examine the Intersectionality between Affordable Housing and Gender. Panelists will share perspectives on issues faced by women and gender-diverse individuals that intersect with their ability to feel safe and secure in a place they call home. Through local, national and international examples, participants will gain insights through a variety of lenses, to further understand the importance of taking action to make affordable housing a reality in Waterloo Region.
            </div>
            <a href="https://mailchi.mp/8698697342a0/kwcf-dialogue-9-2?e=67c64d8ed5" target="_blank" style="width: 100%; display: flex; justify-content: flex-end; padding-right: .5rem;">
                <button type="button" aria-label="open panelist bio button" class="custom-btn grow" style="padding: .5rem 2rem; font-size: 14px">Details</button>
            </a>
        </div>
    </div>
</div>