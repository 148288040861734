import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgxTwitterTimelineModule } from "ngx-twitter-timeline"

@Component({
  selector: 'app-twitterfeed',
  templateUrl: './twitterfeed.component.html',
  styleUrls: ['./twitterfeed.component.scss'],
})
export class TwitterfeedComponent implements OnInit {
  // twitterUrl: string = environment.twitterUrl;
  twitterUrl="https://twitter.com/WaterlooMath";
  constructor() {}

  ngOnInit() {}
}
