import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-job-detail-dialog',
  templateUrl: './job-detail-dialog.component.html',
  styleUrls: ['./job-detail-dialog.component.scss'],
})
export class JobDetailDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<JobDetailDialogComponent>,
  ) { }

  job: any;

  ngOnInit() {
    this.job = this.data.job;
    console.log('CURRENT JOB', this.job);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
