import { CookieModule } from 'ngx-cookie';
import { ZoomService } from 'src/app/shared/components/widgets/zoom/zoom.service';
import { ZoomComponent } from './shared/components/widgets/zoom/zoom.component';
import { BrowserModule, Title, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/components/structural/navbar/navbar.component';
import { FooterComponent } from './shared/components/structural/footer/footer.component';
import { UserProfileStaticComponent } from './shared/components/dialogs/user-profile-static/user-profile-static.component';
import { JobDetailDialogComponent } from './shared/components/dialogs/job-detail-dialog/job-detail-dialog.component';

import {
  AmplifyAngularModule,
  AmplifyService,
  AmplifyModules,
} from 'aws-amplify-angular';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';
//import { DeviceDetectorService } from 'ngx-device-detector';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { GroupsComponent } from './groups/groups.component';
import { SiteConstants } from './services/site-constants.service';
import { UserProfileService } from './pages/profile/user-profile.service';
import { FeedService } from './pages/dashboard/feed.service';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RecaptchaModule } from 'ng-recaptcha';
import { Neo4jEscapeUnescapeModule } from './shared/utilities/neo4j-escape-unescape/neo4j-escape-unescape.module';
import { FlipModule } from 'ngx-flip';
import { AutosizeModule } from 'ngx-autosize';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng5SliderModule } from 'ng5-slider';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './shared/components/dialogs/login/login.component';
import { VideoViewComponent } from './shared/components/dialogs/video-view/video-view.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidebarComponent } from './shared/components/structural/sidebar/sidebar.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio'
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ContactComponent } from './pages/contact/contact.component';
import { UploadComponent } from './pages/upload/upload.component';
import { HomeComponent } from './pages/home/home.component';
import { ImagePreviewDialogComponent } from './shared/components/dialogs/image-preview-dialog/image-preview-dialog.component';
import { ImageCropperComponent } from './shared/components/widgets/image-cropper/image-cropper/image-cropper.component';
import { XmlInfoComponent } from './shared/components/dialogs/xml-info/xml-info.component';
import { PdfInfoComponent } from './shared/components/dialogs/pdf-info/pdf-info.component';
import { MatTableModule } from '@angular/material/table';
import { RequirementsComponent } from './pages/requirements/requirements.component';
import { ComponentsModule } from './shared/components/components.module';
import { BasicComponent } from './pages/profile/sections/basic/basic.component';
import { ExperienceComponent } from './pages/profile/sections/experience/experience.component';
import { EducationComponent } from './pages/profile/sections/education/education.component';
import { CertificationsComponent } from './pages/profile/sections/certifications/certifications.component';
import { VolunteerComponent } from './pages/profile/sections/volunteer/volunteer.component';
import { SkillsComponent } from './pages/profile/sections/skills/skills.component';
import { LanguagesComponent } from './pages/profile/sections/languages/languages.component';
import { PublicationsComponent } from './pages/profile/sections/publications/publications.component';
import { AwardsComponent } from './pages/profile/sections/awards/awards.component';
import { ProjectsComponent } from './pages/profile/sections/projects/projects.component';
import { ResearchComponent } from './pages/profile/sections/research/research.component';
import { PatentsComponent } from './pages/profile/sections/patents/patents.component';
import { AcademicAncestryComponent } from './pages/profile/sections/academic-ancestry/academic-ancestry.component';
import { MentoringRelationComponent } from './pages/profile/sections/mentoring-relation/mentoring-relation.component';
import { SocialMediaComponent } from './pages/profile/sections/social-media/social-media.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImpactStoryComponent } from './pages/profile/sections/impact-story/impact-story.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { HttpRequestInterceptor } from './shared/utilities/interceptors';
import { TokenResolverService } from './services/token-resolver.service';
import { Piday2020Component } from './groups/public-groups/piday2020/piday2020.component';
import { TwitterfeedComponent } from './groups/public-groups/twitterfeed/twitterfeed.component';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { Piday2022Component } from './groups/public-groups/piday2022/piday2022.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    UserProfileStaticComponent,
    GroupsComponent,
    VideoViewComponent,
    SidebarComponent,
    ProfileComponent,
    SettingsComponent,
    ContactComponent,
    UploadComponent,
    ImagePreviewDialogComponent,
    ImageCropperComponent,
    XmlInfoComponent,
    PdfInfoComponent,
    JobDetailDialogComponent,
    RequirementsComponent,
    HomeComponent,
    BasicComponent,
    ExperienceComponent,
    EducationComponent,
    CertificationsComponent,
    VolunteerComponent,
    SkillsComponent,
    LanguagesComponent,
    PublicationsComponent,
    AwardsComponent,
    ProjectsComponent,
    ResearchComponent,
    PatentsComponent,
    AcademicAncestryComponent,
    MentoringRelationComponent,
    SocialMediaComponent,
    AssessmentComponent,
    ImpactStoryComponent,
    Piday2020Component,
    TwitterfeedComponent,
    Piday2022Component,
  ],
  imports: [
    ComponentsModule,
    Neo4jEscapeUnescapeModule,
    AutosizeModule,
    Ng5SliderModule,
    NgxEchartsModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    DragDropModule,
    MatSnackBarModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCardModule,
    MatBadgeModule,
    MatSliderModule,
    MatInputModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatStepperModule,
    MatTabsModule,
    BrowserModule,
    MatIconModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    NgxTwitterTimelineModule,
    ImageCropperModule,
    MaterialFileInputModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    NgbModalModule,
    FlipModule,
    MatTableModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    AmplifyAngularModule,
    Ng2ImgMaxModule,
    FontAwesomeModule,
    MatCheckboxModule,
    //DeviceDetectorService,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RecaptchaModule,
    HammerModule,
    CookieModule.forRoot(),
  ],
  providers: [
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
        });
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    UserProfileService,
    FeedService,
    SiteConstants,
    Title,
    TokenResolverService,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ZoomService,
    ZoomComponent
  ],
 
  bootstrap: [AppComponent],
  entryComponents: [
    LoginComponent,
    UserProfileStaticComponent,
    VideoViewComponent,
    ImagePreviewDialogComponent,
    XmlInfoComponent,
    JobDetailDialogComponent
  ],
})
export class AppModule {}
