import { Injectable } from "@angular/core"
import { AmplifyService } from "aws-amplify-angular"
import { NgxSpinnerService } from "ngx-spinner"
import { DataService } from "./data.service"
import { HttpService } from "./http.service"
import { webSocket } from 'rxjs/webSocket';
import { environment } from "../../environments/environment"
import { BehaviorSubject } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  socket: any
  onWebsocketMessage = new BehaviorSubject<any>('')

  constructor(
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public dataService: DataService,
    public api: HttpService
  ) { }


  // on load main-ui
  createConnection() {
    this.socket = webSocket(environment.commonWebSocket)
    console.log('new websocket')
    this.webSocketMethods()
  }

  // Websocket Methods like onopen, onmessage, onclose
  webSocketMethods() {
    setInterval(() => {
      this.socket.complete();
    }, 480000)

    this.socket.subscribe(
      msg => {
        this.onWebsocketMessage.next(msg)
      },
      err => console.log('Error', err),
      () => {
        console.log('websocket closed');
        setTimeout(() => {
          this.createConnection()
        }, 1000)
      }
    )
  }

}
