import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ElementRef,
  ViewChild
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { UtilityService } from 'src/app/services/utility.service';
import { PublicationEdge } from 'src/app/shared/utilities/app-db-edges';
import { Publication } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
  encapsulation: ViewEncapsulation.Emulated
})
export class PublicationsComponent implements OnInit {

  publicationList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;
  viewmodel = this.data.viewmodel;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  noneDisabled: boolean;
  titles: any;
  types: any;
  categories: any;
  publishers: any;
  unverified: boolean;
  noneExists = false;
  editMode: boolean;
  verified: any;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService,
    private util: UtilityService
  ) { }

  status: ServiceStatus;
  newItem: { edge: PublicationEdge; toNodeRef: NodeRef; toNode: Publication };
  currentItem: { edge: PublicationEdge; toNodeRef: NodeRef; toNode: Publication };
  publicationModel: any = {
    publicationType: '',
    title: '',
    category: '',
    status: 'Select',
    version: '',
    publisher: '',
    canEdit: true,
    coPublishers: [],
    summary: '',
    country: '',
    state: '',
    city: '',
    isRefereed: false,
    publishedDate: {
      year: '',
      month: '',
      day: '',
    },
    publishedDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    }
  };
  users = [];
  userStrings = [];
  previousUsers = [];
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  isMobile: boolean;
  statusList: any;
  deleteMode = false;
  emailValid = true;
  currentCountry: any;
  currentState: any;
  currentCity: any;
  cityDisabled: boolean;
  currentStateID: any;
  countryID: any;
  countries: any;
  states: any;
  cities: any;
  uuidPerson: any;
  publicationTypes: any;
  isStatusRequired = false;
  suggestedUserDetails = [];
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.getSuggestions();
    this.getDropDownList();

    this.api.getDropdowns('PublicationStatus').subscribe(data => {
      const response: any = data;
      this.statusList = response.data;
    }, error => {
      this.util.logger(error, 'Publication Dropdown Error');
    });

    this.api.getCountries().subscribe((data) => {
      const response: any = data;
      this.countries = response.data;
      this.countries.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      this.data.logger(this.countries, 'COUNTRIES RESPONSE');
      this.initStates(this.data.currentUser.country);
    });

    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.refreshNewItem();
    this.getPublicationList();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip() {
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getSuggestions() {
    this.api.getSuggestions('publication').subscribe((data) => {
      const response: any = data;
      this.titles = response.data.title;
      this.types = response.data.publicationType;
      this.categories = response.data.category;
      this.publishers = response.data.publisher;
    });
  }

  getDropDownList() {
    this.api.getDropdowns('PublicationType').subscribe((res) => {
      this.publicationTypes = res['data']
    });
  }

  titleSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.titles
          : this.titles.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  typeSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.types
          : this.types.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  categorySuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.categories
          : this.categories.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  publisherSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.publishers
          : this.publishers.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.publicationModel.title = 'NONE';
    this.publicationModel.publicationType = '';
    this.publicationModel.category = '';
    this.publicationModel.status = '';
    this.publicationModel.version = '';
    this.publicationModel.publisher = '';
    this.publicationModel.canEdit = true;
    this.publicationModel.coPublishers = [];
    this.publicationModel.publishedDate.year = '';
    this.publicationModel.publishedDate.month = '';
    this.publicationModel.publishedDate.day = '';
    this.publicationModel.isRefereed = false;
    this.publicationModel.summary = '';
    this.saveClick();
  }

  onDateChange(field: any, input: any) {
    if (input === 'month') {
      this.dayValue = [];
      for (
        let i = 1;
        i <= this.data.days[this.publicationModel.publishedDate.month - 1];
        i++
      ) {
        this.dayValue.push({ value: i });
      }
      this.publicationModel.publishedDate.day = '';
    } else if (input === 'year') {
      if (this.publicationModel.publishedDate.month === '') {
        this.publicationModel.publishedDate.month = '';
        this.publicationModel.publishedDate.day = '';
      }
    }
  }

  getPublicationList() {
    this.publicationList = [];
    const apiUrl = `${this.root}/userProfileUpdatePublication?operation=list`;
    const getPublicationList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getPublicationList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORDS
        this.viewmodel.wrote_Publication = [];
        let removeDuplicates = [];
        const tempPublication = response.record.sort((a: any, b: any) =>
          a.row[0].publishedDate ?
            ((a.row[0].publishedDate.year !== b.row[0].publishedDate.year
              ? a.row[0].publishedDate.year
              : +a.row[0].publishedDate.month) <
              (a.row[0].publishedDate.year !== b.row[0].publishedDate.year
                ? b.row[0].publishedDate.year
                : +b.row[0].publishedDate.month)
              ? 1
              : -1)
            : a.row[0].title < b.row[0].title ? 1 : -1
        );
        // const tempEducation = response.record.sort((a: any, b: any) =>
        //   a.row[0].publishedDate.year < b.row[0].publishedDate.year ? 1 : -1
        // );
        response.record = tempPublication;
        // tslint:disable-next-line: forin
        let i = 0
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            publishedDate: new Date(
              response.record[item].row[0].publishedDate.year,
              response.record[item].row[0].publishedDate.month - 1,
              response.record[item].row[0].publishedDate.day
            ),
            uuid: response.record[item].row[0].uuid,
            title: response.record[item].row[0].title,
            category: response.record[item].row[0].category ? response.record[item].row[0].category : '',
            status: response.record[item].row[0].status ? response.record[item].row[0].status : '',
            version: response.record[item].row[0].version ? response.record[item].row[0].version : '',
            publisher: response.record[item].row[0].publisher ? response.record[item].row[0].publisher : '',
            coPublishers: response.record[item].row[0].coPublishers ? response.record[item].row[0].coPublishers : '',
            country: response.record[item].row[0].country ? response.record[item].row[0].country : '',
            state: response.record[item].row[0].state ? response.record[item].row[0].state : '',
            city: response.record[item].row[0].city ? response.record[item].row[0].city : '',
            canEdit: response.record[item].row[0].canEdit,
            createdBy: response.record[item].row[0].createdBy,
            summary: response.record[item].row[0].summary,
            isRefereed: response.record[item].row[0].isRefereed ? response.record[item].row[0].isRefereed : false,
            publicationType: response.record[item].row[0].publicationType ? response.record[item].row[0].publicationType : '',
            dataSource: response.record[item].row[0].dataSource,
            // tslint:disable-next-line: max-line-length
            publishedDateFlag: {
              year: response.record[item].row[0].publishedDate.year,
              month: response.record[item].row[0].publishedDate.month,
              day: response.record[item].row[0].publishedDate.day,
            },
            verified: 'verified'
          };
          this.viewmodel.wrote_Publication.push(temp);
          this.publicationList.push(temp);
          removeDuplicates.push(temp);
        }
        // UNVERIFIED IMPORTED RECORDS
        this.viewmodel.unverified_Wrote_Publication = [];
        const UNVERIFIEDPublication = response.unverified.sort((a: any, b: any) =>
          a.row[0].publishedDate ?
            ((a.row[0].publishedDate.year !== b.row[0].publishedDate.year
              ? a.row[0].publishedDate.year
              : +a.row[0].publishedDate.month) <
              (a.row[0].publishedDate.year !== b.row[0].publishedDate.year
                ? b.row[0].publishedDate.year
                : +b.row[0].publishedDate.month)
              ? 1
              : -1)
            : a.row[0].title < b.row[0].title ? 1 : -1
        );
        response.unverified = UNVERIFIEDPublication;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            publishedDate: new Date(
              response.unverified[item].row[0].publishedDate.year,
              response.unverified[item].row[0].publishedDate.month - 1,
              response.unverified[item].row[0].publishedDate.day
            ),
            uuid: response.unverified[item].row[0].uuid,
            title: response.unverified[item].row[0].title,
            category: response.unverified[item].row[0].category ? response.unverified[item].row[0].category : '',
            status: response.unverified[item].row[0].status ? response.unverified[item].row[0].status : '',
            version: response.unverified[item].row[0].version ? response.unverified[item].row[0].version : '',
            publisher: response.unverified[item].row[0].publisher ? response.unverified[item].row[0].publisher : '',
            coPublishers: response.unverified[item].row[0].coPublishers ? response.unverified[item].row[0].coPublishers : '',
            canEdit: true,
            summary: response.unverified[item].row[0].summary,
            country: response.unverified[item].row[0].country ? response.unverified[item].row[0].country : '',
            state: response.unverified[item].row[0].state ? response.unverified[item].row[0].state : '',
            city: response.unverified[item].row[0].city ? response.unverified[item].row[0].city : '',
            isRefereed: response.unverified[item].row[0].isRefereed ? response.unverified[item].row[0].isRefereed : false,
            publicationType: response.unverified[item].row[0].publicationType ? response.unverified[item].row[0].publicationType : '',
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            publishedDateFlag: {
              year: response.unverified[item].row[0].publishedDate.year,
              month: response.unverified[item].row[0].publishedDate.month,
              day: response.unverified[item].row[0].publishedDate.day,
            },
            verified: 'unverified'
          };
          this.viewmodel.unverified_Wrote_Publication.push(temp);
          // this.publicationList.push(temp);
          removeDuplicates.push(temp);
        }
        const result = removeDuplicates.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.title === thing.title && t.version === thing.version && t.category === thing.category
          ))
        )
        this.publicationList = this.publicationList.concat(result);
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        createdDateTime: null,
        modifiedDateTime: null,
        publicationType: '',
        title: '',
        category: '',
        status: 'Select',
        version: '',
        publisher: '',
        coPublishers: '',
        summary: '',
        isRefereed: false,
        publishedDate: {
          year: '',
          month: '',
          day: '',
        }
      },
      toNodeRef: {
        labels: null,
        uuid: '',
        name: '',
      },
      toNode: {
        uuid: '',
        publicationType: '',
        logoUrl: '',
        title: '',
        summary: '',
        publishedDate: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    if (this.viewmodel.wrote_Publication.length === 1 && this.viewmodel.wrote_Publication[0].title === 'NONE') {
      this.noneExists = true;
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.enableDeleteButton = true;
    this.publicationModel = {
      createdDateTime: null,
      modifiedDateTime: null,
      publicationType: '',
      title: '',
      category: '',
      status: 'Select',
      version: '',
      publisher: '',
      canEdit: true,
      coPublishers: [],
      summary: '',
      country: '',
      state: '',
      city: '',
      isRefereed: false,
      publishedDate: {
        year: '',
        month: '',
        day: '',
      },
      publishedDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      }
    };
  }

  addCoPublisher() {
    this.publicationModel.coPublishers.push({
      id: Math.floor(1000 + Math.random() * 9000),
      email: '',
      firstName: '',
      lastName: '',
      uuid: '',
      personDisabled: false,
      canEditPerson: true,
      isOwner: false
    });
  }

  removePublication(index) {
    this.publicationModel.coPublishers.splice(index, 1)
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.uuidPerson = this.data.uuidPerson;
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    }, 1000);
    document.documentElement.scrollTop = 0;
    // this.unverified = item.verified;
    this.verified = item.verified;
    if (item.importStatus) {
      this.publicationModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    this.currentItem = item;
    // this.publicationModel = { ...item };
    this.publicationModel.title = item.title;
    this.publicationModel.category = item.category;
    this.publicationModel.edgeUuid = item.uuid;
    this.publicationModel.dataSource = item.dataSource;
    this.publicationModel.canEdit = item.canEdit
    this.publicationModel.isRefereed = item.isRefereed
    this.publicationModel.publicationType =
      item.publicationType && item.publicationType !== 'null'
        ? item.publicationType
        : '';
    this.publicationModel.summary =
      item.summary && item.summary !== 'null' ? item.summary : '';
    let month;
    if (item.publishedDateFlag.day === null) {
      if (item.publishedDateFlag.month === null) {
        month = '';
      } else {
        month = item.publishedDate.getMonth() + 2;
      }
    } else {
      month = item.publishedDate.getMonth() + 1;
    }
    this.publicationModel.publishedDate = {
      day: item.publishedDateFlag.day ? item.publishedDate.getDate() : '',
      month: item.publishedDateFlag.month ? item.publishedDateFlag.month : '',
      year: item.publishedDateFlag.year ? item.publishedDateFlag.year : '',
    };
    this.publicationModel.category = item.category ? item.category : '';
    this.publicationModel.status = item.status ? item.status : 'Select';
    this.publicationModel.version = item.version ? item.version : '';
    this.publicationModel.publisher = item.publisher ? item.publisher : '';
    this.publicationModel.coPublishers = item.coPublishers
    this.publicationModel.country = item.country
    this.publicationModel.state = item.state
    this.publicationModel.city = item.city
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  saveClick() {
    if (this.publicationModel.title === 'NONE') {
      this.publicationModel.publicationType = '';
      this.publicationModel.category = '';
      this.publicationModel.status = '';
      this.publicationModel.version = '';
      this.publicationModel.publisher = '';
      this.publicationModel.coPublishers = [];
      this.publicationModel.publishedDate.year = '';
      this.publicationModel.publishedDate.month = '';
      this.publicationModel.publishedDate.day = '';
      this.publicationModel.isRefereed = false;
      this.publicationModel.summary = '';
    }

    if (this.editMode && this.verified == 'verified') {
      const apiUrl = `${this.root}/userProfileUpdatePublication?operation=update`;
      // tslint:disable-next-line: max-line-length
      const publicationSave = this.http.post<any>(
        apiUrl,
        {
          data: this.publicationModel,
          edgeUuid: this.publicationModel.edgeUuid,
        },
        { headers: this.headers }
      );
      publicationSave.subscribe((response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getPublicationList();
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      }, error => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      });
    } else {
      const apiUrl = `${this.root}/userProfileUpdatePublication?operation=create`;
      // tslint:disable-next-line: max-line-length
      const publicationSave = this.http.post<any>(
        apiUrl,
        { data: this.publicationModel },
        { headers: this.headers }
      );
      publicationSave.subscribe((response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getPublicationList();
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      }, error => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      });
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.wrote_Publication[0]);
    // }
    if (this.publicationModel.title != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000)
    }
    this.editMode = false;
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.publicationModel.importStatus = item.importStatus;
    }
    this.currentItem = item;
    this.publicationModel = { ...item };
    this.publicationModel.edgeUuid = item.uuid;
    this.publicationModel.publicationType =
      item.publicationType && item.publicationType !== 'null'
        ? item.publicationType
        : '';
    this.publicationModel.isRefereed =
      item.isRefereed && item.isRefereed !== 'null'
        ? item.isRefereed
        : false;
    this.publicationModel.summary =
      item.summary && item.summary !== 'null' ? item.summary : '';
    this.publicationModel.publishedDate = {
      day: item.publishedDateFlag.day ? item.publishedDate.getDate() : '',
      month: item.publishedDateFlag.month ? item.publishedDateFlag.month : '',
      year: item.publishedDateFlag.year ? item.publishedDateFlag.year : '',
    };
    this.publicationModel.category = item.category ? item.category : '';
    this.publicationModel.status = item.status ? item.status : 'Select';
    this.publicationModel.version = item.version ? item.version : '';
    this.publicationModel.publisher = item.publisher ? item.publisher : '';
    this.publicationModel.coPublishers = item.coPublishers ? item.coPublishers : '';
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdatePublication?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const publicationSave = this.http.post<any>(
      apiUrl,
      { data: this.publicationModel, edgeUuid: this.publicationModel.uuid },
      { headers: this.headers }
    );
    publicationSave.subscribe((response) => {
      this.api.refreshProfileCompletion();
      if (response !== undefined && response.status === 1) {
        this._toaster.successToastr(response.message, 'Success');
        this.flipDiv = false;
        this.getPublicationList();
        this.deleteMode = false;
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
        this.flipDiv = false;
      }
    }, error => {
      console.log({ error });
      this._toaster.errorToastr(error.error.message, 'Error');
      this.flipDiv = false;
    });
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  clearData() {
    this.publicationModel.title = '';
    this.publicationModel.publicationType = '';
    this.publicationModel.isRefereed = false;
    this.publicationModel.publishedDate.year = '';
    this.publicationModel.publishedDate.month = '';
    this.publicationModel.publishedDate.day = '';
    this.publicationModel.summary = '';
    this.publicationModel.category = '';
    this.publicationModel.country = '';
    this.publicationModel.state = '';
    this.publicationModel.city = '';
    this.publicationModel.status = 'Select';
    this.publicationModel.version = '';
    this.publicationModel.publisher = '';
    this.publicationModel.coPublishers = [];
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }

  hideFullEmail(email: string, type: string) {
    if (email !== null && email !== undefined && email !== '') {
      const firstLetter = email.substring(0, 1);
      const ending = email.substring(email.indexOf('@'), email.length);
      return type === 'suggestion' ? `(${firstLetter}********${ending})` : `${firstLetter}********${ending}`;
    } else {
      return '';
    }
  }

  checkEmail() {
    if (this.publicationModel.coPublishers.email) {
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      this.emailValid = pattern.test(this.publicationModel.coPublishers.email);
    } else {
      this.emailValid = true;
    }
  }

  usersSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(700)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.userStrings
          : this.userStrings.filter(
            (v) => v.details.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  getUserSuggestions(item) {
    this.previousUsers = this.users;
    const filters = {
      filterAttributes: item
    };
    this.api.getUsers(filters).subscribe(
      (data) => {
        const response: any = data;
        this.users = response.data;
        const temp = [];
        const tempWithMoreDetails = [];
        this.users.forEach(element => {
          // tslint:disable-next-line: max-line-length
          temp.push({
            details: `${element.firstName} ${(element.lastName) ? element.lastName : ''} ${this.publicationModel.coPublishers.email === '' ? element.email : element.email}`,
            uuid: element.uuid
          });
          tempWithMoreDetails.push({
            firstName: element.firstName,
            lastName: element.lastName,
            email: element.email,
            uuid: element.uuid
          });
        });
        this.userStrings = temp;
        this.suggestedUserDetails = tempWithMoreDetails;
      }
    );
  }

  resultFormatter(value: any) {
    return value.details;
  }

  autofill(index: number, event) {
    const uuid = event.item.uuid;
    const selectedCoAuthor = this.suggestedUserDetails.filter((coAuthor) => coAuthor.uuid === uuid)[0];
    setTimeout(() => {
      this.publicationModel.coPublishers[index].firstName = selectedCoAuthor.firstName;
      this.publicationModel.coPublishers[index].lastName = selectedCoAuthor.lastName;
      this.publicationModel.coPublishers[index].email = selectedCoAuthor.email;
      this.publicationModel.coPublishers[index].uuid = selectedCoAuthor.uuid;
      this.publicationModel.canEditPerson = false;
      this.publicationModel.coPublishers[index].personDisabled = true
    }, 0);
  }

  clear(i) {
    this.publicationModel.coPublishers[i].firstName = '';
    this.publicationModel.coPublishers[i].lastName = '';
    this.publicationModel.coPublishers[i].email = '';
    this.publicationModel.coPublishers[i].uuid = '';
    this.publicationModel.coPublishers[i].canEdit = true;
    this.publicationModel.coPublishers[i].personDisabled = false;
  }

  getStates(e: any) {
    this.cityDisabled = true;
    this.data.logger(e, 'GET STATES PARAM');
    this.data.logger(this.countries, 'COUNTRIES ARRAY');
    this.publicationModel.country = e;
    this.countries.forEach((element) => {
      if (element.name === e) {
        this.api.getStates(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'STATES RESPONSE');
          this.states = response.data;
          this.states.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        });
      }
    });
    this.data.logger(this.publicationModel.country, 'CURRENT COUNTRY');
  }

  initStates(e: any) {
    this.data.logger(e, 'GET STATES PARAM');
    this.publicationModel.country = e;
    this.countries.forEach((element) => {
      if (element.name === e) {
        this.countryID = element.id;
        this.api.getStates(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'STATES RESPONSE');
          this.states = response.data;
          this.states.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          this.initCities(this.publicationModel.state);
        });
      }
    });
    this.data.logger(this.publicationModel.country, 'CURRENT COUNTRY');
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(1000)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.cities
          : this.cities.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  getCities(e: any) {
    this.data.logger(e, 'GET CITIES PARAM');
    this.publicationModel.state = e;
    this.currentState = e;
    this.states.forEach((element) => {
      if (element.name === e) {
        this.currentStateID = element.id;
        this.api.getCities(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'CITIES RESPONSE');
          this.cities = response.data;
          this.cities.sort();
          this.cityDisabled = false;
        });
      }
    });
  }

  initCities(e: any) {
    this.data.logger(e, 'GET CITIES PARAM');
    this.states.forEach((element) => {
      if (element.name === e) {
        this.api.getCities(element.id).subscribe((data) => {
          const response: any = data;
          this.data.logger(response, 'CITIES RESPONSE');
          this.cities = response.data;
          this.cities.sort();
          this.cityDisabled = false;
        });
      }
    });
  }

  setCity(e: any) {
    this.getCities(this.publicationModel.state)
    this.publicationModel.city = e;
    this.currentCity = e;
  }

  onStatusChange(event) {
    if (event === 'Select') {
      this.isStatusRequired = true;
    } else {
      this.isStatusRequired = false;
    }
  }
}

