import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../../dialogs/login/login.component';
import { Auth } from 'aws-amplify';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() closeSideBar: EventEmitter<any> = new EventEmitter();

  bucket: string;
  person: any;
  userTooltip: string;

  constructor(
    public data: DataService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.bucket = environment.publicBucket;
  }

  ngOnInit() {}

  login() {
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then((data) => {
      this.data.logger(data, 'OPEN LOGIN');
    });
  }

  logout() {
    Auth.signOut({ global: true }).then((data) => {});
    localStorage.clear()
    this.person = null;
    this.data.authenticated = false;
    this.data.authenticatedSubject = false;
    this.router.navigate(['/']);
  }

  togglePublicGroups() {
    this.data.publicGroupsOpen = !this.data.publicGroupsOpen;
  }

  togglePrivateGroups() {
    this.data.privateGroupsOpen = !this.data.privateGroupsOpen;
  }

  toggleGraphs() {
    this.data.graphsOpen = !this.data.graphsOpen;
  }

  closeSidebar() {
    this.data.graphsOpen = false;
    this.data.publicGroupsOpen = false;
    this.data.privateGroupsOpen = false;
    this.closeSideBar.emit();
  }
}
