import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xml-info',
  templateUrl: './xml-info.component.html',
  styleUrls: ['./xml-info.component.scss'],
})
export class XmlInfoComponent implements OnInit {
  constructor() {}

  displayedColumns: string[] = ['section', 'xml', 'pdf'];

  dataSource = [
    { section: 'Basic Info', xml: 'yes', pdf: 'yes' },
    { section: 'Experience', xml: 'yes', pdf: 'yes' },
    { section: 'Education', xml: 'yes', pdf: 'yes' },
    { section: 'Certifications', xml: 'no', pdf: 'no' },
    { section: 'Volunteer', xml: 'no', pdf: 'no' },
    { section: 'Skills', xml: 'no', pdf: 'no' },
    { section: 'Languages', xml: 'yes', pdf: 'yes' },
    { section: 'Publications', xml: 'yes', pdf: '4/21 (19%)' },
    { section: 'Awards', xml: 'no', pdf: 'no' },
    { section: 'Project', xml: 'no', pdf: 'no' },
    { section: 'Research', xml: 'yes', pdf: 'yes' },
    { section: 'Patents', xml: 'no', pdf: 'no' },
    { section: 'Academic Ancestry', xml: 'yes', pdf: 'Testing (80%)' },
    { section: 'Mentoring Relation', xml: 'no', pdf: 'no' },
    { section: 'Social Media', xml: 'no', pdf: 'no' },
  ];

  ngOnInit() {}
}
