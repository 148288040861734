import { Component,OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { DataService } from 'src/app/services/data.service';
// import { VideoViewComponent } from 'src/app/shared/components/dialogs/video-view/video-view.component';
// import { LoginComponent } from 'src/app/shared/components/dialogs/login/login.component';
import { DiscussionBoardService } from 'src/app/shared/components/widgets/discussion-board/discussion-board.service';

@Component({
  selector: 'app-pi-day',
  templateUrl: './piday2020.component.html',
  styleUrls: ['./piday2020.component.scss'],
})
export class Piday2020Component implements OnInit {
  data: any;
  selectedsuggestedFilter = '';

  constructor(
    private modalService: NgbModal,
    private httpService: HttpService,
    public dataService: DataService,
    private disucssionBoardService: DiscussionBoardService
  ) {}

  ngOnInit() {
    this.dataService.setPageTitle('Profound Impact Day')
    this.disucssionBoardService.discussionBoardID.next(
      '855dcddd-b055-4a47-bfd5-b0a7c38b7656'
    );
  }  

  connectToZoom(id: any) {
    // this.httpService
    //   .connectToZoom(id, this.dataService.currentCountryCode)
    //   .subscribe((data) => {});
  }

  connectToZoomPublic(id: any) {
    // this.httpService
    //   .connectToZoomPublic(id, this.dataService.currentCountryCode)
    //   .subscribe((data) => {});
  }

  openLogin() {
    // const modalRef = this.modalService.open(LoginComponent, {
    //   windowClass: 'mdModal',
    // });
    // modalRef.result.then((data) => {
    //   this.data.logger(data, 'OPEN LOGIN');
    // });
  }

  watchYoutube(url: string) {
    // this.dataService.youtubeURL = url;
    // this.modalService.open(VideoViewComponent, { windowClass: 'smModal' });
  }

  onSelectSuggestedFilter(selectedsuggestedFilter: string) {
    this.selectedsuggestedFilter = selectedsuggestedFilter;
  }
}
