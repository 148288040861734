import { Injectable, ChangeDetectionStrategy } from "@angular/core"
import { AmplifyService } from "aws-amplify-angular"
import { NgxSpinnerService } from "ngx-spinner"
import { DataService } from "../../../../services/data.service"
import { HttpService } from "../../../../services/http.service"
import { BehaviorSubject } from "rxjs"
import { environment } from "../../../../../environments/environment"
import { webSocket } from 'rxjs/webSocket';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class DiscussionBoardService {
  logCognitoUser: any
  changeDetection: ChangeDetectionStrategy.OnPush
  webSocket: any
  isClosed: boolean = false
  userID: any
  token: any
  did: any
  timerID = 0
  isPosting = false
  isReplying = false
  discussionBoardID: BehaviorSubject<any> = new BehaviorSubject(null)
  chatList: any = []
  isModerator: BehaviorSubject<any> = new BehaviorSubject(false)
  isActive: BehaviorSubject<any> = new BehaviorSubject(true)
  discussionBoardConfig: any = []
  item: any
  isUpdatingChatList: BehaviorSubject<any> = new BehaviorSubject(this.chatList)
  constructor(
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public dataService: DataService,
    public api: HttpService
  ) {}

  // checking if user is authenticated or not
  userAuth = async (did) => {
    if (this.dataService.authenticated) {
      await this.amplifyService
        .auth()
        .currentAuthenticatedUser()
        .then((data) => {
          try {
            this.logCognitoUser = data.username
            if (data) {
              
              this.token = data.signInUserSession.idToken.jwtToken
              const decoded = jwt_decode(this.token)

              this.userID = decoded["custom:uuidPerson"]
              this.did = did
              if(did){
                this.createConnection(did)
                this.discussionBoard(did)
              }
            } else {
              this.spinner.hide()
            }
          } catch (err) {
            this.spinner.hide()
          }
        })
    } else {
      // if user not authenticated
      this.discussionBoard(this.discussionBoardID.value)
    }
  }

  // on load main-ui
  createConnection(did) {
    this.webSocket = webSocket(`${environment.webSocketUrl}?token=${this.token}&id=${did}`)
    this.webSocketMethods()
  }

  // loading discussion-board
  discussionBoard = async (id: string) => {
    try {
      this.api.getDiscussionBoard(id).subscribe(
        (res) => {
          this.isUpdatingChatList.next(res.data)
          this.chatList = res.data
          this.discussionBoardConfig = res.data["DiscussionBoardConfig"]
          if (this.discussionBoardConfig.moderatorsUuid != null) {
            if (
              this.discussionBoardConfig.moderatorsUuid
                .split(",")
                .includes(this.userID)
            ) {
              this.isModerator.next(true)
            } else {
              this.isModerator.next(false)
            }
          }
          if (this.discussionBoardConfig.isActive === 0) {
            this.isActive.next(false)
          } else {
            this.isActive.next(true)
          }
        },
        (err) => {
          this.discussionBoard(this.discussionBoardID.value)
          this.spinner.hide()
        },
        () => {
          this.spinner.hide()
        }
      )
    } catch (err) {
      this.spinner.hide()
    }
  }
  

  
  // Websocket Methods like onopen, onmessage, onclose
  webSocketMethods() {
    setInterval(() => {
      this.webSocket.complete()
    },480000)

    this.webSocket.subscribe(
      msg => {
        this.spinner.hide()
        const data = msg
        if (data.Action === "postMessage") {
          // push commenter data to commenter
          if (this.chatList["Commenter"].length < 1) {
            this.chatList["Commenter"].push(data.Commenter[0])
          } else {
            const checkUserExist = this.chatList["Commenter"].filter((x) => {
              return x.uuid === data.Commenter[0].uuid
            })
            if (checkUserExist.length === 0) {
              this.chatList["Commenter"].push(data.Commenter[0])
            }
          }
          // checking if comment is post or reply
          if (data.Comments[0].parentId === "root") {
            this.isPosting = false
            this.chatList["Comments"].unshift(data.Comments[0])
          } else {
            this.isReplying = false
            const updateComment = this.filterCommentID(
              this.chatList["Comments"],
              data.Comments[0].parentId
            )
            updateComment.replies.push(data.Comments[0])
            this.isUpdatingChatList.next(this.chatList)
          }
          // updating comments array
          this.isUpdatingChatList.next(this.chatList)
        } else if (data.Action === "editMessage") {
          const updateComment = this.filterCommentID(
            this.chatList["Comments"],
            data.Comments[0].id
          )
          updateComment.html = data.Comments[0].html
          updateComment.isModerated = data.Comments[0].isModerated
          updateComment.modifiedDateTime = data.Comments[0].modifiedDateTime
          updateComment.lastEditedBy = data.Comments[0].lastEditedBy
          updateComment.logoUrl = data.Commenter[0].logoUrl
          // updating comments array
          this.isUpdatingChatList.next(this.chatList)
        } else if (data.Action === "likeMessage") {
          const updateComment = this.filterCommentID(
            this.chatList["Comments"],
            data.Comments[0].id
          )
          updateComment.likes = data.Comments[0].likes
          updateComment.likedBy = data.Comments[0].likedBy
  
          this.isUpdatingChatList.next(this.chatList)
        } else if (data.Action === "deleteMessage") {
          const updateComment = this.filterCommentID(
            this.chatList["Comments"],
            data.Comments[0].id
          )
          updateComment.html = data.Comments[0].html
          updateComment.deletedBy = data.Comments[0].deletedBy
          updateComment.isDeleted = data.Comments[0].isDeleted
  
          this.isUpdatingChatList.next(this.chatList)
        } else {
          console.log("some other action")
        }
      },
      err => console.log('Error', err),
      () => {
        setTimeout(() => {
          this.createConnection(this.did)
        }, 1000)
      })
  }

  //Service call to perform certain actions on comment

  // poast a message
  sendMessage(msg: any, item: any) {
    this.webSocket.next(msg)
    if (item.expanded) {
      this.item = item
    }
  }

  // delete a message
  deleteComment(msg: any) {
    this.webSocket.next(msg)
  }

  // edit a message
  editComment(msg: any) {
    this.webSocket.next(msg)
  }

  // like a message
  likeComment(msg: any) {
    this.webSocket.next(msg)
  }

  //find and filter a certain comment based on it's id
  filterCommentID(comments, id) {
    if (comments) {
      for (var i = 0; i < comments.length; i++) {
        if (comments[i].id === id) {
          return comments[i]
        }
        var found = this.filterCommentID(comments[i].replies, id)
        if (found) return found
      }
    }
  }
}
