import { Pipe, PipeTransform } from '@angular/core';
import { Unescape } from '../unescape';

@Pipe({
  name: 'neo4junescape'
})
export class UnescapePipe implements PipeTransform {

  constructor(private unescape: Unescape) {}

/**
 * Pipe method to HTML unescape. Use method it(string, string|number, object) instead.
 * @param value The value
 * @param arg The argument
 * @return Returns the value
 */
  transform(value: any, arg?: number | string): string {
    if (value === null || value === undefined) {
      // throw `The input value to the pipe must be a string or a string convertible value like number, booleans, etc.`;
      return value;
    }

    return this.unescape.it(value.toString(), arg);
  }

}
