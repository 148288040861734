import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { ObtainedDateTimeEdge } from 'src/app/shared/utilities/app-db-edges';
import { Award } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';
@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants]
})
export class AwardsComponent implements OnInit {

  viewmodel = this.data.viewmodel;
  awardList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  noneDisabled: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _constantApi: SiteApiConstants,
    public _toaster: ToastrManager,
    public data: DataService,
    private escape: Escape,
    private api: HttpService
  ) {}

  root: string;
  status: ServiceStatus;
  newItem: { edge: ObtainedDateTimeEdge; toNodeRef: NodeRef; toNode: Award };
  currentItem: {
    edge: ObtainedDateTimeEdge;
    toNodeRef: NodeRef;
    toNode: Award;
  };
  awardsModel: any = {
    name: '',
    shortName: '',
    officialUrl: '',
    obtainedDateTime: {
      year: '',
      month: '',
      day: '',
    },
    obtainedDateTimeFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  unverified: any;
  isAwardYearRequired = false;
  currentYear = new Date().getFullYear();

  onDateChange(field: any, input: any, awardYear) {
    if(awardYear === '') {
      this.isAwardYearRequired = true;
    } else {
      this.isAwardYearRequired = false;
    }

    if (input === 'month') {
      this.dayValue = [];
      for (
        let i = 1;
        i <= this.data.days[this.awardsModel.obtainedDateTime.month - 1];
        i++
      ) {
        this.dayValue.push({ value: i });
      }
      this.awardsModel.obtainedDateTime.day = '';
    } else if (input === 'year') {
      if (this.awardsModel.obtainedDateTime.month === '') {
        this.awardsModel.obtainedDateTime.month = '';
        this.awardsModel.obtainedDateTime.day = '';
      }
    }
    const flag = this.awardsModel[field][input] !== '' ? 1 : 0;
    this.awardsModel[field + 'Flag'][input] = flag;
  }

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.refreshNewItem();
    this.getAwardList();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip(){
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.awardsModel.name = 'NONE';
    this.awardsModel.shortName = '';
    this.awardsModel.officialUrl = '';
    this.awardsModel.obtainedDateTime.year = '';
    this.awardsModel.obtainedDateTime.month = '';
    this.awardsModel.obtainedDateTime.day = '';
    this.saveClick();
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        obtainedDateTime: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        shortName: '',
        logoUrl: '',
        officialUrl: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log('VIEW MODEL OBTAINED AWARD: ', this.viewmodel.earns_Award);
    if (
      this.viewmodel.earns_Award.length === 1 &&
      this.viewmodel.earns_Award[0].name === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.editMode = false;
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.awardsModel = {
      name: '',
      shortName: '',
      officialUrl: '',
      obtainedDateTime: {
        year: '',
        month: '',
        day: '',
      },
      obtainedDateTimeFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    console.log('ITEM CLICKED: ', item);
    if (item.importStatus) {
      this.awardsModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    if (item.dataSource === 'User') {
      item.canEdit ? (this.deleteMode = false) : (this.deleteMode = true);
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.awardsModel = { ...item };
    this.awardsModel.edgeUuid = item.uuid;
    this.awardsModel.officialUrl = item.officialUrl;
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.awardsModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '',
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : '',
      year: item.obtainedDateTimeFlag.year
        ? item.obtainedDateTimeFlag.year
        : '',
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  saveClick() {
    this.editMode = false;
    if (this.awardsModel.name === 'NONE') {
      this.awardsModel.shortName = '';
      this.awardsModel.officialUrl = '';
      this.awardsModel.obtainedDateTime.year = '';
      this.awardsModel.obtainedDateTime.month = '';
      this.awardsModel.obtainedDateTime.day = '';
    }
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateAward?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.awardsModel, edgeUuid: this.awardsModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getAwardList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateAward?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.awardsModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getAwardList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.earns_Award[0]);
    // }
    if (this.awardsModel.name != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  getAwardList() {
    this.awardList = [];
    const apiUrl = `${this.root}/userProfileUpdateAward?operation=list`;
    const getAwards = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getAwards.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        console.log('AWARD: ', response);
        this.viewmodel.earns_Award = [];
        const tempItem = response.record.sort((a: any, b: any) =>
          // tslint:disable-next-line: max-line-length
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? a.row[0].obtainedDateTime.year
            : +a.row[0].obtainedDateTime.month) <
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? b.row[0].obtainedDateTime.year
            : +b.row[0].obtainedDateTime.month)
            ? 1
            : -1
        );
        response.record = tempItem;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.record[item].row[0].obtainedDateTime.year,
              response.record[item].row[0].obtainedDateTime.month - 1,
              response.record[item].row[0].obtainedDateTime.day
            ),
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            shortName: response.record[item].row[0].shortName,
            officialUrl: response.record[item].row[0].officialUrl,
            // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.record[item].row[0].obtainedDateTime.year,
              month: response.record[item].row[0].obtainedDateTime.month,
              day: response.record[item].row[0].obtainedDateTime.day,
            },
            status: 'verified'
          };
          this.viewmodel.earns_Award.push(temp);
          this.awardList.push(temp);
        }
        this.viewmodel.unverified_Earns_Award = [];
        const tempItem2 = response.unverified.sort((a: any, b: any) =>
          // tslint:disable-next-line: max-line-length
          (a.row[0].obtainedDateTimeFlag.year !==
          b.row[0].obtainedDateTimeFlag.year
            ? a.row[0].obtainedDateTimeFlag.year
            : +a.row[0].obtainedDateTimeFlag.month) <
          (a.row[0].obtainedDateTimeFlag.year !==
          b.row[0].obtainedDateTimeFlag.year
            ? b.row[0].obtainedDateTimeFlag.year
            : +b.row[0].obtainedDateTimeFlag.month)
            ? 1
            : -1
        );
        response.unverified = tempItem2;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.unverified[item].row[0].obtainedDateTimeFlag.year,
              response.unverified[item].row[0].obtainedDateTimeFlag.month - 1,
              response.unverified[item].row[0].obtainedDateTimeFlag.day
            ),
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            shortName: response.unverified[item].row[0].shortName,
            officialUrl: response.unverified[item].row[0].officialUrl,
            // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.unverified[item].row[0].obtainedDateTimeFlag.year,
              month:
                response.unverified[item].row[0].obtainedDateTimeFlag.month,
              day: response.unverified[item].row[0].obtainedDateTimeFlag.day,
            },
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            status: 'unverified'
          };
          this.viewmodel.unverified_Earns_Award.push(temp);
          this.awardList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.awardsModel.importStatus = item.importStatus;
    }
    this.editMode = false;
    this.currentItem = item;
    this.awardsModel = { ...item };
    this.awardsModel.edgeUuid = item.uuid;
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.awardsModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '',
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : '',
      year: item.obtainedDateTimeFlag.year
        ? item.obtainedDateTimeFlag.year
        : '',
    };
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateAward?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.awardsModel, edgeUuid: this.awardsModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getAwardList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  clearData() {
    this.editMode = false;
    this.deleteMode = false;
    this.awardsModel.name = '';
    this.awardsModel.shortName = '';
    this.awardsModel.officialUrl = '';
    this.awardsModel.obtainedDateTime.year = '';
    this.awardsModel.obtainedDateTime.month = '';
    this.awardsModel.obtainedDateTime.day = '';
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
    this.editMode = false;
  }
}

