import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Dimensions,
  ImageTransform,
} from '../../widgets/image-cropper/interfaces/index';
import { base64ToFile } from '../../widgets/image-cropper/utils/blob.utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SiteConstants } from '../../../../services/site-constants.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SiteApiConstants } from '../../../../services/site-api-constants.service';
import { HttpService } from '../../../../services/http.service';
import { DataService } from '../../../../services/data.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.scss'],
})
export class ImagePreviewDialogComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  userBasicModel: any = {};
  root: string;
  bucket: string;
  jwtToken = '';
  headers: HttpHeaders;
  preSignedurl: any;
  imageName = '';
  imageFile: File;
  imageOnLoad: any;
  backgroundColor = '#808080';
  imageUrl: any;
  currentImage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
    public _constant: SiteConstants,
    private http: HttpClient,
    public _constantApi: SiteApiConstants,
    private httpService: HttpService,
    public dataService: DataService,
    public _toaster: ToastrManager,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private util: UtilityService
  ) {
    this.bucket = environment.publicBucket;
    this.jwtToken = this._constantApi.getLoginToken();
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${this.dataService.jwt}`,
    });
  }

  ngOnInit() {
    this.userBasicModel = this.data.userBasicModel;
    if (this.userBasicModel.logoUrl !== '') {
      this.currentImage = this.userBasicModel.logoUrl;
      // tslint:disable-next-line: max-line-length
      this.imageUrl =
        'https://' +
        this.bucket +
        '.s3.amazonaws.com/profile-pictures/' +
        this.userBasicModel.logoUrl;
      this.toDataURL(this.imageUrl, (base64Img) => {
        this.imageOnLoad = base64Img;
      });
      this.imageName = this.userBasicModel.logoUrl;
    } else {
      this.imageOnLoad = 'assets/images/uidesign/user.png';
    }
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    url += '?' + new Date().getTime();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  fileChangeEvent(event: any) {
    console.log(event);
    const file = event.target.files[0];
    this.dataService.logger(typeof file, 'TYPE OF FILE');
    this.dataService.logger(file, 'FILE');
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    this.imageName = window.btoa(new Date().getTime() + '_profile.' + ext);
    console.log(this.imageOnLoad);
    console.log('file change event', this.imageName);
    if (file.size > 5000000) {
      this._toaster.errorToastr('Image size should be 5MB or less', 'Error');
      return false;
    } else if (!this.validateFileExtension(ext)) {
      this._toaster.errorToastr('Image Type should be jpg, png, jpeg', 'Error');
      return false;
    } else {
      this.imageChangedEvent = event;
    }
  }

  validateFileExtension(ext: String) {
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

  imageCropped(event) {
    if (this.userBasicModel.logoUrl !== '') {
      const file = new File([base64ToFile(event.base64)], this.imageName, {
        type: '\'image/png\'',
      });
      this.imageFile = file;
    } else {
      const file = new File(
        [base64ToFile(event.base64)],
        this.userBasicModel.logoUrl,
        {
          type: '\'image/png\'',
        }
      );
      this.imageFile = file;
      console.log(this.imageFile);
    }
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  onSubmit() {
    this.userBasicModel.avatar = this.imageName;
    this.userBasicModel.logoUrl = this.imageName;
    console.log('On Submit', this.userBasicModel);

    const apiUrl = `${this.root}/userProfileUpdateBasic?operation=update`;
    const userProfileBasicData = this.http.post<any>(
      apiUrl,
      {
        data: this.userBasicModel,
      },
      { headers: this.headers }
    );
    // gets the signed url and uploads the user profile to s3
    userProfileBasicData.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        if (this.imageName) {
          this.httpService.uploadImage(this.imageName).subscribe(
            (result) => {
              this.deleteFromS3Bucket(this.currentImage);
              this.preSignedurl = result['data'];
              this.httpService
                .uploadImageSignedUrl(this.imageFile, this.preSignedurl)
                // tslint:disable-next-line: no-shadowed-variable
                .subscribe((data) => {
                  this.dataService.logger(data, 'SIGNED URL RESPONSE');
                  // window.location.reload();
                  this.util.getUser();
                });
            },
            (error) => {
              this.dataService.logger(error, 'IMAGE UPLOAD ERROR');
            }
          );
        }
        this._toaster.successToastr(response.message, 'Success');
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.body, 'Error');
      }
    });
    this.dialogRef.close(this.imageFile);
  }

  deleteFromS3Bucket(imageName: string) {
    this.httpService.deleteImage(imageName, 'profile-pictures').subscribe((data) => {
      console.log('Successfully deleted file.', data);
      return true;
    });
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
}
