<app-navbar 
  *ngIf="client == null"
  (toggleSideBar)="sideBarToggler()"
  role="navigation"
  aria-label="header"
></app-navbar>
<app-footer *ngIf="client == null" role="navigation" aria-label="footer"></app-footer>
<mat-drawer-container
  style="width: 100%"
  role="navigation"
  aria-label="sidebar"
>
  <mat-drawer
    *ngIf="LT_LG || data.isMobile"
    mode="over"
    [opened]="data.sideBarOpen"
  >
    <app-sidebar *ngIf="client == null" (closeSideBar)="sideBarToggler()"></app-sidebar>
  </mat-drawer>
  <mat-drawer
    *ngIf="!LT_LG && data.isDesktopDevice"
    mode="side"
    [disableClose]="true"
    opened
    [ngClass]="{
      'sidenav-folded': !data.sideBarOpen,
      'sidenav-unfolded': data.sideBarOpen
    }"
    (mouseenter) ="openSideBar()"  (mouseleave) ="closeSidebar()"
  >
    <app-sidebar *ngIf="client == null" (closeSideBar)="sideBarToggler()"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content
    [ngClass]="{
      'main-content-folded':
        !data.sideBarOpen && !LT_LG && data.isDesktopDevice,
      'main-content-unfolded':
        data.sideBarOpen && !LT_LG && data.isDesktopDevice
    }"
    style="overflow-x: hidden"
  >
    <router-outlet style="width: 100%" fxLayoutAlign="start center" (activate)="onActivate($event)">
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="large"
        color="#fff"
        type="ball-scale-multiple"
      >
      </ngx-spinner>
      <div
        *ngIf="!LT_LG && data.isDesktopDevice && data.sideBarOpen"
        (click)="data.sideBarOpen = !data.sideBarOpen"
        style="
          z-index: 99;
          position: absolute;
          top: 0px;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.459);
        "
      >
        &nbsp;
      </div>
    </router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
