import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-branding-separator',
  templateUrl: './branding-separator.component.html',
  styleUrls: ['./branding-separator.component.scss']
})
export class BrandingSeparatorComponent implements OnInit {

  @Input() bgc1: string = '#ffbeef';
  @Input() bgc2: string = '#ff63aa';
  @Input() bgc3: string = '#de2398';
  @Input() bgc4: string = '#c60078';

  constructor() { }

  ngOnInit() {
  }

}
