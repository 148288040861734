<div id="user-polls">
    <div class="h2r" *ngIf="!auth && !pollTaken">
        <div class="card--polls-info">
            Please <a (tap)="openLogin()"
            style="cursor: pointer; color: rgb(132, 163, 255); padding: 0 5px">Login</a> or provide your name and email address
        </div>
    </div>
    <div class="card poll-card col-12 text-center" *ngIf="!auth && !pollTaken">
        <form [formGroup]="userInfoForm" class="polls-user-info" (ngSubmit)="onSubmit()">
            <div class="form-group row">
                <label class="form__label col-sm-12 col-md-3 text-md-right text-center">First name</label>
                <div class="col-sm-12 col-md-8">
                    <input class="form__control text-md-left text-center col-10 float-md-left" type="text" [ngClass]="{'is-invalid': isSubmitted && f.firstName.errors}"  formControlName="firstName" placeholder="first name">
                </div>
            </div>
            <div class="form-group row">
                <label class="form__label col-sm-12 col-md-3 text-md-right text-center">Last name</label>
                <div class="col-sm-12 col-md-8">
                    <input class="form__control text-md-left text-center col-10 float-md-left" type="text" [ngClass]="{'is-invalid': isSubmitted && f.lastName.errors}"  formControlName="lastName" placeholder="last name">
                </div>
            </div>
            <div class="form-group row">
                <label class="form__label col-sm-12 col-md-3 text-md-right text-center">Email</label>
                <div class="col-sm-12 col-md-8">
                    <input class="form__control text-md-left text-center col-10 float-md-left" type="email" [ngClass]="{'is-invalid': isSubmitted && f.email.errors}"  formControlName="email" placeholder="email@gmail.com">
                </div>
            </div>
            <div class="form-group row">
                <label class="form__label col-sm-12 col-md-3 text-md-right text-center"></label>
                <div class="button-container col-sm-12 col-md-8">
                    <button type="submit" aria-label="continue button"  role="button" class="mt-4 col-10 float-md-left button button--dark-grey contact-us-page__form-btn"> Continue </button>
                </div>
            </div>
        </form>
    </div>

   
    <div *ngIf="!pollTaken && auth || submitted" class="quiz-container" id="quiz">
        <div class="quiz-header">
            <h2 id="question">{{question}}</h2> 
            <ul [formGroup]="options">
                <li *ngFor="let option of qa.controls; let i = index">
                    <ng-container [formGroup]="option">
                        <input type="checkbox" [checked]="option.value.Resp" [title]="option.value.Text" formControlName="Resp" class="answer mt-1 mr-4" />
                        <label for="answer">{{option.value.Text}}</label> 
                        <textarea *ngIf="option.value.isDescriptive" rows="4" cols="50" placeholder="Ask anything" formControlName="Desc" class="form__control col-8 ml-3"></textarea>
                    </ng-container>
                </li>
            </ul>
        </div>
        <button *ngIf="!pollTaken" id="submit" [ngClass]="{'poll-button-diabled': !pollStarted}" [disabled]="!pollStarted" (click)="submitPoll()">Submit</button>
    </div>

    <div *ngIf="pollTaken" class="col-12 poll-thankyou">
        <p>Thank you for providing your valuable feedback</p>
    </div>
</div>
