export const environment = {
  // not used directly
  production: true,
  debug: true,
  baseUrl: 'https://bcg3xy30k0.execute-api.us-east-1.amazonaws.com/Default',
  centralServiceBaseUrl: 'https://whgdsma1v4.execute-api.us-east-1.amazonaws.com/Default',
  
  identityPoolId: 'us-east-1:814e864f-bd91-4d42-a5bb-2c382f11a8f1',
  region: 'us-east-1',
  identityPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_LngScwtih',
  userPoolWebClientId: '726d4hun9ju4ppvhg3vfuu472e',
  webSocketUrl: 'wss://ehs0qjzfh9.execute-api.us-east-1.amazonaws.com/Default',
  commonWebSocket: 'wss://wi0ynek1n1.execute-api.us-east-1.amazonaws.com/Default',
  zoomApiKey: 'gjtuM69GTgeX7Av7vXQ9MA',
  zoomApiSecret: 'LaRWi0OurbrciFzKERKzpoOhvohPpynZk7cv',
  enPointApiKey: 'c4d0e6f696e617108d6fc12e73242b0b1757635450087c1926',
  enPointHash: '0062ab73ecb1e7b9c1963fcc51c11510',
  publicBucketUrl: 'https://pi-demo-dev-public-content-yb6qvaphi34xvtcmoipy.s3.amazonaws.com/',
  // public2020BucketUrl: 'https://pi-uw-dev-public-content-whswtvlq45nb5v7lqriq.s3.amazonaws.com/',
  publicBucket: 'pi-demo-dev-public-content-yb6qvaphi34xvtcmoipy',
  privateBucket: 'pi-demo-dev-private-content-0pq62qdfaai4ttgxn7e5',
  tokenEndpoint: 'https://linkedin.auth.us-east-1.amazoncognito.com/oauth2/token',
  loginUrl: 'linkedin.auth.us-east-1.amazoncognito.com',
  twitterUrl: 'https://twitter.com/WaterlooMath',
  redirectUrl:  window.location.origin + '/login/callback',
  logout: `https://pidemo.us.auth0.com/v2/logout?client_id=726d4hun9ju4ppvhg3vfuu472e&returnTo=${window.location.origin}`,
  verifyUWaterlooClientUrl: 'https://uwaterloo.dev.profoundimpact.com/',
  verifyStJeromeClientUrl: 'https://stjeromesdev.profoundimpact.com/',
  // verifyStJeromeClientUrl: 'http://localhost:8555/'
};
