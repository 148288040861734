import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AmplifyService } from 'aws-amplify-angular';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { LoginComponent } from '../../dialogs/login/login.component';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.scss']
})
export class PollsComponent implements OnInit {
  @Input() pollID: string
  @Output() pollTitle = new EventEmitter<string>();
  userInfo: boolean = false
  userInfoForm: FormGroup
  submitted: boolean = false;
  isSubmitted = false
  pollData: any
  auth: boolean = false
  options: FormGroup
  question: any
  firstName: string
  lastName: string
  email: string
  pollStarted: boolean = false
  pollTaken: boolean = false

  constructor(public api: HttpService, 
    private modalService: NgbModal, 
    public amplifyService: AmplifyService,
    private dataService: DataService, 
    private fb: FormBuilder,
    public _toaster: ToastrManager
    ) { }

  ngOnInit() {
    console.log('IN POLLS INIT');
    this.dataService.authenticatedSubject.subscribe((data: any) => {
      // get poll data
      this.getUserPoll()
    })
     
    // get poll data
    this.getUserPoll()

    // initialize user form
    this.userInfoForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]) 
    })

    // initialize QA form
    this.options = new FormGroup({
      O: new FormArray([])
    })


    this.options.valueChanges.subscribe(res => {
       const result = res.O.filter(res => { return res.Resp })
        if(result.length > 0){
          this.pollStarted = true
        } else {
          this.pollStarted = false
        }
    })
  }

  
  // get userInfoForm
  get f() { return this.userInfoForm.controls; }

  // get options
  get qa() { return this.options.get('O') as FormArray }
  
  // get poll
  async getUserPoll() {
    this.dataService.showPoll = true;
    await this.amplifyService
        .auth()
        .currentAuthenticatedUser()
        .then((data) => {
          try {
            if (data) {
              this.auth = true

              this.email = data.attributes.email
              this.firstName = this.dataService.currentUser.firstName
              this.lastName = this.dataService.currentUser.lastName
              
              this.api.getUserPolls(this.pollID, this.email).subscribe(pollData => {

                const res = pollData.data

                console.log('POLL: ', res);
          
                this.pollTitle.emit(res.title)
          
                this.pollData = res
          
                this.question = res.qna['Q1'].Text
          
                const qna = res.qna['Q1'].O

                this.dataService.pollTaken = false;
                this.dataService.showPoll = true;
                
                if(this.qa.length <= 0 ){
                  qna.map(res => {
                    this.qa.push(this.fb.group(res))
                  })
                }
                
              },
              (err) => {
                if(err.error.error === 'User Response Already Exists') {
                    this.pollTaken = true
                    this.dataService.pollTaken = true;
                    this.dataService.showPoll = false;
                }
              },
              () => {
          
              })
            }
          } catch (error) {
            
          }
        })    
  }


  // when user submits his/her information
  onSubmit() {
    this.isSubmitted = true

    // stop here if form is invalid
    if (this.userInfoForm.invalid) {
        return;
    }

    this.firstName = this.userInfoForm.value.firstName
    this.lastName = this.userInfoForm.value.lastName
    this.email = this.userInfoForm.value.email

    this.api.getUserPolls(this.pollID, this.email).subscribe(pollData => {

      this.submitted = true;
      this.auth = true

      const res = pollData.data

      this.pollTitle.emit(res.title)

      this.pollData = res

      this.question = res.qna['Q1'].Text

      const qna = res.qna['Q1'].O

      qna.map(res => {
        this.qa.push(this.fb.group(res))
      })
    },
    (err) => {
      if(err.error.error === 'User Response Already Exists') {
        this._toaster.errorToastr('Looks like you have already participated in this poll')
        this.pollTaken = true
      }
    })
  }
  
  // when user submits poll
  submitPoll() {

    this.pollData.firstName = this.firstName
    this.pollData.lastName = this.lastName
    this.pollData.email = this.email
    this.pollData.qna['Q1'].O = this.options.value.O

    this.api.submitUserPolls(this.pollData).subscribe(res => {
      // console.log(res)
      this.pollTaken = true
      this.submitted = false
      this._toaster.successToastr('Thank you for your participation', 'Response submited succesfully');
    },
    (err) => {
      //  console.log(err.error.error)
       if(err.error.error === 'User Response Already Exists') {
        this._toaster.errorToastr('Looks like you have already participated in this poll')
        this.pollTaken = true
       } else {
        this._toaster.errorToastr('Something is wrong!')
       }
    },
    () => {

    })
  }

  // open login modal
  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then((data) => {
      //this.data.logger(data, 'OPEN LOGIN');
    });
  }
}
