import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() title: string = 'Card Title';
  @Input() subtitle: string = '';
  @Input() backgroundColorClass: string = 'bg-white';
  @Input() type: 'scroll' | 'hidden' | 'none' | 'full' = 'scroll';

  constructor() { }

  ngOnInit() {
    console.log('TYPE: ', this.type);
  }

}
