import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AmplifyService } from 'aws-amplify-angular';
import { NgxSpinnerService } from "ngx-spinner"
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatStepper } from '@angular/material/stepper';
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { LoginComponent } from 'src/app/shared/components/dialogs/login/login.component';

const TABLE_DATA = [
  { program: 'Advocacy', index: 1, name: 'advocacy' },
  { program: 'Alumni Hiring Alumni', index: 2, name: 'alumniHiringAlumni' },
  { program: 'Alumni Chapters', index: 3, name: 'alumniChapters' },
  { program: 'Career Support', index: 4, name: 'careerSupport' },
  { program: 'Giving Day', index: 5, name: 'givingDay' },
  { program: 'eNews', index: 6, name: 'eNews' },
  { program: 'Homecoming', index: 7, name: 'homecoming' },
  { program: 'Mentoring', index: 8, name: 'mentoring' },
  { program: 'Scholarships', index: 9, name: 'scholarships' },
  { program: 'Webinars', index: 10, name: 'webinars' }
];

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AssessmentComponent implements OnInit {

  scale = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' }
  ];

  impact = [
    { value: 'high/high', viewValue: 'High/High' },
    { value: 'high/low', viewValue: 'High/Low' },
    { value: 'low/high', viewValue: 'Low/High' },
    { value: 'low/low', viewValue: 'Low/Low' }
  ]

  displayedColumns: string[] = ['program', 'institutional', 'association', 'alumni', 'staff', 'budget', 'partnership', 'technology', 'measurable', 'score', 'impact'];
  dataSource = TABLE_DATA;

  q0: any = [
    'Advocacy',
    'Alumni Chapters',
    'Alumni Hiring',
    'Career Support',
    'eNews',
    'Giving Day',
    'Homecoming',
    'Mentoring',
    'Schaloarships',
    'Webinars'
  ]
  q1: any = [];
  q2: any = [];
  q3: any = [];
  q4: any = [];

  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild("overview") overview: ElementRef;

  accecptedTerms = false;
  assessment: FormGroup
  questions: any
  data: FormGroup;
  oldPlan: string;
  newPlan: string;
  oldElements: string;
  newElements: string;
  oldFactors: string;
  newFactors: string;
  userToken: string;
  isSubmitted: boolean = true
  uploadCompletionStatus: any
  preSignedurl: any
  fileName: any
  singleFile: any
  acceptedTerms = false
  completed: any
  createdDateTime: any

  constructor(
    public ds: DataService,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder,
    private amplifyService: AmplifyService,
    public api: HttpService,
    private spinner: NgxSpinnerService,
    public toaster: ToastrManager,
    public dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.setPageTitle('Self Assessment Tool');
    this.getAssessmentForm()
    this.assessmentForm()
    this.ds.authenticatedSubject.subscribe(() => {
      this.getAssessmentForm()
      this.assessmentForm()
    });
  }



  // initialize assessment form 
  assessmentForm() {
    this.assessment = new FormGroup({
      strategicAlignment: new FormGroup({
        questions: new FormArray([])
      }),
      budgetJustification: new FormGroup({
        questions: new FormArray([])
      }),
      integrationOpportunities: new FormGroup({
        questions: new FormArray([])
      }),
      data: new FormGroup({
        questions: new FormArray([])
      }),
      demonstrableNeed: new FormGroup({
        questions: new FormArray([]),
        programChart: new FormGroup({
          advocacy: new FormGroup({
            alignmentWithInstitutional: new FormControl(null),
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          alumniChapters: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          alumniHiringAlumni: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          careerSupport: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          eNews: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          givingDay: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          homecoming: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          mentoring: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          scholarships: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          }),
          webinars: new FormGroup({
            alignmentWithAlumniAssociationPriorities: new FormControl(null),
            alignmentWithInstitutional: new FormControl(null),
            avgScore: new FormControl(null),
            budgetPartnershipOpportunitiesTechnology: new FormControl(null),
            effortImpact: new FormControl(null),
            measurable: new FormControl(null),
            numberOfAlumniEngaged: new FormControl(null),
            priorities: new FormControl(null),
            staffTime: new FormControl(null),
            utilization: new FormControl(null),
          })
        }),
        priorityMatrix: new FormGroup({
          highHigh: new FormControl(),
          highLow: new FormControl(),
          lowHigh: new FormControl(),
          lowLow: new FormControl(),
          options: new FormControl()
        })
      })
    })

    console.log(this.assessment.value)
  }

  get alignmentQuestion() {
    return this.assessment.get('strategicAlignment').get('questions') as FormArray;
  }

  get budgetQuestion() {
    return this.assessment.get('budgetJustification').get('questions') as FormArray;
  }

  get dataQuestion() {
    return this.assessment.get('data').get('questions') as FormArray;
  }

  get integrationQuestion() {
    return this.assessment.get('integrationOpportunities').get('questions') as FormArray;
  }

  get needQuestion() {
    return this.assessment.get('demonstrableNeed').get('questions') as FormArray;
  }

  get advocacy() { return this.assessment.get('demonstrableNeed').get('programChart').get('advocacy').value }
  get alumniChapters() { return this.assessment.get('demonstrableNeed').get('programChart').get('alumniChapters').value }
  get alumniHiring() { return this.assessment.get('demonstrableNeed').get('programChart').get('alumniHiringAlumni').value }
  get careerSupport() { return this.assessment.get('demonstrableNeed').get('programChart').get('careerSupport').value }
  get eNews() { return this.assessment.get('demonstrableNeed').get('programChart').get('eNews').value }
  get givingDay() { return this.assessment.get('demonstrableNeed').get('programChart').get('givingDay').value }
  get homecoming() { return this.assessment.get('demonstrableNeed').get('programChart').get('homecoming').value }
  get mentoring() { return this.assessment.get('demonstrableNeed').get('programChart').get('mentoring').value }
  get scholarships() { return this.assessment.get('demonstrableNeed').get('programChart').get('scholarships').value }
  get webinars() { return this.assessment.get('demonstrableNeed').get('programChart').get('webinars').value }

  // get assesment from from backend
  async getAssessmentForm() {
    await this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then((data) => {
        try {
          const token = data.signInUserSession.idToken.jwtToken
          this.userToken = data.signInUserSession.idToken.jwtToken
          this.api
            .getAssessment(
              token
            )
            .subscribe(
              (res) => {
                console.log('Assessment Data:', res.data)
                this.accecptedTerms = true;
                this.createdDateTime = res.data.createdDateTime
                // check isSubmitted
                this.isSubmitted = res.data.isSubmitted
                if (this.isSubmitted) {
                  this.completed = true
                  this.acceptedTerms = true
                  if (this.isSubmitted) {
                    setTimeout(() => {
                      this.myStepper.linear = false;
                      this.myStepper.selectedIndex = 5;
                      this.myStepper.linear = true;
                    }, 100);
                  }
                  // this.accecptedTerms = true
                  // this.myStepper.linear = false;
                  // this.myStepper.selectedIndex = 5;
                  setTimeout(() => {
                    // this.myStepper.linear = true;
                  });
                }
                // pushing alignment questions
                const alignmentQues = res.data['strategicAlignment'].questions
                alignmentQues.map(res => {
                  this.alignmentQuestion.push(this.fb.group(res))
                })
                // pushing budget questions
                const budgetQues = res.data['budgetJustification'].questions
                budgetQues.map(res => {
                  this.budgetQuestion.push(this.fb.group(res))
                })
                //pushing integration questions
                const integrationQues = res.data['integrationOpportunities'].questions
                integrationQues.map(res => {
                  this.integrationQuestion.push(this.fb.group(res))
                })
                // pushing data questions
                const dataQues = res.data['data'].questions
                dataQues.map(res => {
                  this.dataQuestion.push(this.fb.group(res))
                })

                // pushing need questions
                const needQues = res.data['demonstrableNeed'].questions
                needQues.map(res => {
                  this.needQuestion.push(this.fb.group(res))
                })

                // program chart
                const programChart = res.data['demonstrableNeed'].programChart
                this.assessment.get('demonstrableNeed').get('programChart').patchValue(programChart)

                // get q0 options
                const q0Options = res.data['demonstrableNeed'].priorityMatrix.options
                this.q0 = q0Options
                // get q1 options
                const q1Options = res.data['demonstrableNeed'].priorityMatrix.highHigh
                this.q1 = q1Options
                // get q2 options
                const q2Options = res.data['demonstrableNeed'].priorityMatrix.highLow
                this.q2 = q2Options
                // get q3 options
                const q3Options = res.data['demonstrableNeed'].priorityMatrix.lowHigh
                this.q3 = q3Options
                // get q4 options
                const q4Options = res.data['demonstrableNeed'].priorityMatrix.lowLow
                this.q4 = q4Options

                this.assessment.get('demonstrableNeed').get('priorityMatrix').setValue({
                  highHigh: this.q1,
                  highLow: this.q2,
                  lowHigh: this.q3,
                  lowLow: this.q4,
                  options: this.q0,
                })
              },
              (err) => {
              },
              () => {
              }
            )
        } catch (err) {
        }
      })
  }

  //save assessment
  saveAssessment() {
    this.api.saveAssessment(this.userToken, this.assessment.value).subscribe(res => {
      console.log(res)
      // this.myStepper.next();
      this.toaster.successToastr(
        'Assessment saved'
      );
    })
  }

  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then(res => {
      this.ds.logger(res, 'OPEN LOGIN');
    });
  }

  goToJoin() {
    this.router.navigate(['/join-us']);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.assessment.get('demonstrableNeed').get('priorityMatrix').setValue({
      highHigh: this.q1,
      highLow: this.q2,
      lowHigh: this.q3,
      lowLow: this.q4,
      options: this.q0,
    })
  }

  log(item: any) {
    console.log(item);
  }

  calculateAverage(item) {
    const data = this.assessment.get('demonstrableNeed').get('programChart').get(item).value
    let average = 0
    let i = 0
    for (let key in data) {
      //console.log(`${key} => ${data[key]}`)
      if (data[key] !== undefined && data[key] !== 'null' && data[key] != null && data[key] !== "" && key !== 'avgScore' && key !== 'effortImpact') {
        //console.log(`Entered IF: ${key} => ${data[key]}`)
        average += parseInt(data[key]);
        i++
      }
    }
    if (average === 0) {
      average = 0
    } else {
      average = parseFloat((average / i).toFixed(2));
    }

    this.assessment.get('demonstrableNeed').get('programChart').get(item).patchValue({
      avgScore: average
    })
  }

  submitAssessment() {
    this.downloadAsPdf()
  }

  upload() {
    if (this.fileName) {
      this.uploadCompletionStatus = 'Uploading';
      console.log('FILENAME', this.fileName);
      this.api.uploadAssessmentPDF(this.fileName).subscribe(
        (data) => {
          console.log(data);
          const res: any = data;
          //this.data.logger(data, 'FILE UPLOAD');
          this.preSignedurl = res.data;
          this.api
            .uploadImageSignedUrl(this.singleFile, this.preSignedurl)
            .subscribe((response) => {
              console.log(response)
              const fileData = { fileName: this.fileName }
              this.api.submitAssessment(this.userToken, fileData).subscribe(res => {
                console.log(res)
                this.toaster.successToastr(
                  'Assessment successfully submitted',
                  'SUCCESS'
                );
                this.isSubmitted = true
              })
            });
        },
        (error) => {
          this.uploadCompletionStatus = 'Failed';
          this.toaster.errorToastr(
            'Something is wrong! assessment not submitted',
            'ERROR'
          );
          //this.data.logger(error, 'FILE UPLOAD ERROR');
        }
      );
    }
  }
  // download as pdf
  downloadAsPdf() {
    const content = this.overview.nativeElement;
    let pdf = new jsPDF()

    pdf.setFontSize(14)
    pdf.setTextColor(105, 105, 105)

    pdf.text(15, 10, `Full Name: ${this.ds.currentUser.firstName} ${this.ds.currentUser.lastName}`);
    pdf.text(15, 16, `Username: ${this.ds.currentUser.cognitoUserName}`);
    pdf.text(15, 22, `Email: ${this.ds.currentUser.email}`);
    pdf.text(15, 28, `Country: ${this.ds.currentUser.country}`);
    const createdDate = new Date(this.createdDateTime)
    pdf.text(15, 34, `Created: ${createdDate.getFullYear()}-${createdDate.getUTCMonth()}-${createdDate.getDate()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`);
    pdf.setDrawColor(105, 105, 105);
    pdf.line(0, 36, pdf.internal.pageSize.width, 36);
    const logoURL = new Image()
    logoURL.src = "assets/images/pi-logo-full.png"
    pdf.setFontSize(10)
    pdf.setTextColor(105, 105, 105)
    const pages = 6;
    const pageWidth = pdf.internal.pageSize.width;  //Optional
    const pageHeight = pdf.internal.pageSize.height;  //Optional
    for (let j = 1; j < pages + 1; j++) {
      if (j === 1) {
        pdf.fromHTML(content, 15, 35, { 'width': pdf.internal.pageSize.width - 20 });
      }
      pdf.addPage(j)
      let horizontalPos = pageWidth / 2;  //Can be fixed number
      let verticalPos = pageHeight - 5;  //Can be fixed number
      pdf.setPage(j);
      pdf.text(`${j} of ${pages}`, horizontalPos, verticalPos, { align: 'center' });
      pdf.text(pdf.internal.pageSize.width - 89, pdf.internal.pageSize.height - 5, " Online Assessment Tool | Fueled By")
      pdf.addImage(
        logoURL,
        "PNG",
        pdf.internal.pageSize.width - 30,
        pdf.internal.pageSize.height - 8,
        25,
        4,
        "",
        "FAST"
      )
      pdf.deletePage(7)
    }
    pdf.deletePage(7)
    pdf.deletePage(8)
    pdf.deletePage(9)
    pdf.deletePage(10)

    // Assessment_[FirstName]_[LastName]_YYYY_MM_DD_HH_mm
    function pad2(n) { return n < 10 ? '0' + n : n }

    var date = new Date();
    const format_date = date.getFullYear().toString() + '_' + pad2(date.getMonth() + 1) + '_' + pad2(date.getDate()) + '_' + pad2(date.getHours() + '_' + pad2(date.getMinutes()));

    const fileName = `Assessment_${this.ds.currentUser.firstName}_${this.ds.currentUser.lastName}_${format_date}`

    var blobPDF = new File([pdf.output('blob')], fileName, {
      type: pdf.output('blob').type,
      lastModified: pdf.output('blob').lastModified,
    });
    this.fileName = fileName
    this.singleFile = blobPDF

    this.upload()
  }

  pdfDownload() {
    let content = this.overview.nativeElement;

    let pdf = new jsPDF('p', 'mm', 'a4')

    pdf.setFontSize(12)
    pdf.setTextColor(105, 105, 105)

    pdf.text(15, 10, `Full Name: ${this.ds.currentUser.firstName} ${this.ds.currentUser.lastName}`);
    pdf.text(15, 16, `Username: ${this.ds.currentUser.cognitoUserName}`);
    pdf.text(15, 22, `Email: ${this.ds.currentUser.email}`);
    pdf.text(15, 28, `Country: ${this.ds.currentUser.country}`);
    const createdDate = new Date(this.createdDateTime)
    pdf.text(15, 34, `Created: ${createdDate.getFullYear()}-${createdDate.getUTCMonth()}-${createdDate.getDate()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`);
    pdf.setDrawColor(105, 105, 105);
    pdf.line(0, 36, pdf.internal.pageSize.width, 36);
    const logoURL = new Image()
    logoURL.src = "assets/images/pi-logo-full.png"
    pdf.setFontSize(10)
    pdf.setTextColor(105, 105, 105)
    const pages = 6;
    const pageWidth = pdf.internal.pageSize.width;  //Optional
    const pageHeight = pdf.internal.pageSize.height;  //Optional

    for (let j = 1; j < pages + 1; j++) {
      if (j === 1) {
        pdf.fromHTML(content, 15, 35, { 'width': pdf.internal.pageSize.width - 20 });
      }
      pdf.addPage(j)
      let horizontalPos = pageWidth / 2;  //Can be fixed number
      let verticalPos = pageHeight - 5;  //Can be fixed number
      pdf.setPage(j);
      pdf.text(`${j} of ${pages}`, horizontalPos, verticalPos, { align: 'center' });
      pdf.text(pdf.internal.pageSize.width - 89, pdf.internal.pageSize.height - 5, " Online Assessment Tool | Fueled By")
      pdf.addImage(
        logoURL,
        "PNG",
        pdf.internal.pageSize.width - 30,
        pdf.internal.pageSize.height - 8,
        25,
        4,
        "",
        "FAST"
      )
      pdf.deletePage(7)
    }
    pdf.deletePage(7)
    pdf.deletePage(8)
    pdf.deletePage(9)
    pdf.deletePage(10)

    // Assessment_[FirstName]_[LastName]_YYYY_MM_DD_HH_mm
    function pad2(n) { return n < 10 ? '0' + n : n }

    var date = new Date();
    const format_date = date.getFullYear().toString() + '_' + pad2(date.getMonth() + 1) + '_' + pad2(date.getDate()) + '_' + pad2(date.getHours() + '_' + pad2(date.getMinutes()));

    const fileName = `Assessment_${this.ds.currentUser.firstName}_${this.ds.currentUser.lastName}_${format_date}`

    var blobPDF = new File([pdf.output('blob')], fileName, {
      type: pdf.output('blob').type,
      lastModified: pdf.output('blob').lastModified,
    });

    const blobUrl = window.URL.createObjectURL(blobPDF);
    window.open(blobUrl);  // will open a new tab

  }

  agreementClick() {
    this.acceptedTerms = true
    if (this.isSubmitted) {
      setTimeout(() => {
        this.myStepper.linear = false;
        this.myStepper.selectedIndex = 5;
        this.myStepper.linear = true;
      }, 1000);
    }
  }

}
