import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

  environment = environment;
  split: any;
  vignette = false;
  videoUrl: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    public util: UtilityService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.log('DIALOG DATA', this.data);
    if(this.data.session.archiveLink) {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.session.archiveLink)
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
