<ngb-carousel id="slider" [showNavigationArrows]="true" [showNavigationIndicators]="true" [pauseOnHover]="false">
  <ng-template relative *ngFor="let slide of slides" ngbSlide>
    <div class="slide" (click)="goToStory(slide)">
      <mat-card ngClass.gt-sm="banner-gt-sm" ngClass.sm="banner-sm" ngClass.xs="banner-xs"
        [ngStyle]="{ 'background-image': 'url(' + slide.imagesrc + ')'}" class="slider__slide"></mat-card>
      <div class="carousel-caption">
        <h3  *ngIf="slide.header !== 'hide'" class="slideTitle">{{ slide.header }}</h3>
        <p *ngIf="slide.description !== 'hide'" class="slideDescription" fxShow.gt-sm="true" fxShow.lt-md="false">{{ slide.description }}</p>
        <p *ngIf="slide.description !== 'hide'" class="slideDescription" fxShow.gt-sm="false" fxShow.lt-md="true">{{ slide.description }}</p>
      </div>
      </div>
  </ng-template>
</ngb-carousel>