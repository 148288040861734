import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
})
export class RequirementsComponent implements OnInit {
  constructor(
    private data: DataService,
  ) { }

  versionRegEx = /(\d*)(\.)(.*)/g;
  deviceInfo: any;
  displayWarning = false;
  browserInfo: any;
  message =
    // tslint:disable-next-line: max-line-length
    'Your current browser or your computer cannot run our content, please make sure your browser is fully updated or try a different browser. If that doesn\'t work then please try a different computer.';

  displayedColumns: string[] = ['requirement', 'windows', 'mac', 'linux'];
  dataSource = [
    {
      requirement: 'OPERATING SYSTEM VERSION',
      windows: 'WINDOWS 8 OR LATER',
      mac: 'MAC OS SIERRA 10.12 OR LATER',
      linux: '64-BIT UBUNTU 14.04+, DEBIAN 8+, OPENSUSE 13.3+, FEDORA LINUX 24+'
    },
    {
      requirement: 'MEMORY',
      windows: '2GB MINIMUM, 4GB RECOMMENDED',
      mac: '2GB MINIMUM, 4GB RECOMMENDED',
      linux: '2GB MINIMUM, 4GB RECOMMENDED'
    },
    {
      requirement: 'BROWSER',
      windows: 'CHROME V.57 OR LATER',
      mac: 'SAFARI V.10 OR LATER',
      linux: 'FIREFOX V.56 OR LATER'
    },
    {
      requirement: 'SCREEN RESOLUTION',
      windows: '414 X 736 OR LARGER',
      mac: '414 X 736 OR LARGER',
      linux: '414 X 736 OR LARGER'
    },
    {
      requirement: 'APP WINDOW SIZE',
      windows: '414 X 736 OR LARGER',
      mac: '414 X 736 OR LARGER',
      linux: '414 X 736 OR LARGER'
    },
    {
      requirement: 'INTERNET CONNECTIVITY',
      windows: 'REQUIRED',
      mac: 'REQUIRED',
      linux: 'REQUIRED'
    }
  ];

  ngOnInit() {
    this.data.setPageTitle('System Requirements');
    this.deviceInfo = this.data.device;
    console.log(this.deviceInfo);
    let version;
    if (
      this.deviceInfo.browser !== 'Chrome' &&
      this.deviceInfo.browser !== 'Firefox' &&
      this.deviceInfo.browser !== 'Safari'
    ) {
      this.displayWarning = true;
    }
    if (this.deviceInfo.browser === 'Chrome') {
      version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      if (version === null) {
        version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      }
      if (version[1] < 57) {
        this.displayWarning = true;
      }
    }
    if (this.deviceInfo.browser === 'Firefox') {
      version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      if (version === null) {
        version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      }
      if (version[1] < 56) {
        this.displayWarning = true;
      }
    }
    if (this.deviceInfo.browser === 'Safari') {
      version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      if (version === null) {
        version = this.versionRegEx.exec(this.deviceInfo.browser_version);
      }
      if (version[1] < 10) {
        this.displayWarning = true;
      }
    }
  }
}
