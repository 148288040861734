import { BAD_REQUEST_ERR_MSG } from './config';

export class Unescape {

    optionsObject: any = null;

    constructor() {
    }

    /**
    * Unescapes a string of escaped html values.
    * @param value The value
    * @param arg The argument
    * @return The string returned
    */
    it(value: any, arg?: string | number, options?: any) {
        if (value === null || value === undefined) {
            throw BAD_REQUEST_ERR_MSG;
        }
        value = value.toString();
        return this.unescapeString(value);

        throw BAD_REQUEST_ERR_MSG;
    }

    /**
    * Unescapes HTML entities from a string.
    * @param rawInput The raw input
    * @return The string returned
    */
    unescapeString(rawInput: string): string {
        let unescaped = rawInput;
        unescaped = unescaped.replace(/&#x0A;/g, '\n');
        unescaped = unescaped.replace(/&#x5C;/g, '\\');
        unescaped = unescaped.replace(/&#x2F;/g, '/');
        unescaped = unescaped.replace(/&#x22;/g, '"');
        unescaped = unescaped.replace(/&#x27;/g, '\'');
        unescaped = unescaped.replace(/&#x60;/g, '`');
        unescaped = unescaped.replace(/&#x26;/g, '&');
        return unescaped;
    }
}
