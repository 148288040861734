import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  environment = environment;
  split: any;
  vignette = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    public util: UtilityService
  ) { }

  ngOnInit(): void {
    console.log('DIALOG DATA', this.data);
    if (this.data.session?.name.includes('vignettes')) {
      this.split = this.data.session.detailsLink.split(',');
      console.log('SPLIT: ', this.split);
      this.vignette = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
