import { Component, OnInit } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../../services/data.service';
import { HttpService } from '../../../../services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserProfileService } from 'src/app/pages/profile/user-profile.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';

enum PageState {
  NormalSignin,
  Verification,
  PasswordVerification,
  CompleteSignupPasswordChange,
  ForgotPassword,
  ForgotUsername,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  // LOGIN COMPONENT PROPERTIES
  userVerification: any = {};
  changePassword: any = {};
  changePasswordForm: any = {};
  pageStates = PageState;
  currentPageState = PageState.NormalSignin;
  email = '';
  username = '';
  password = '';
  confirmPassword = '';
  errorMsg = '';
  successMsg = '';
  verificationCode = '';
  user: any;
  person: any;
  isMobile: boolean;
  rememberMe = false;
  captcha = '';
  captchaStatus : boolean = true;
  FORCE_CHANGE_PASSWORD: boolean = false
  // LOGIN COMPONENT CONSTRUCTOR
  constructor(
    public amplifyService: AmplifyService,
    public activeModal: NgbActiveModal,
    public userProfileService: UserProfileService,
    public data: DataService,
    private api: HttpService,
    private util: UtilityService,
    public _spinnerLoader: NgxSpinnerService,
    public router : Router
  ) { }

  // LOGIN PAGE INITIALIZER
  ngOnInit() {

    // DISABLE CAPTCHA ON DEVELOPMENT ENVIRONMENTS
    this.data.debug ? (this.captcha = 'disabled') : (this.captcha = '');
    this.data.debug ? (this.captchaStatus = true) : (this.captchaStatus = false);

    // SET FORM DEFAULT VALUES
    this.userVerification['verification_code'] = '';
    this.userVerification['username'] = '';
    this.userVerification['password'] = 'Profound@2020';
    this.changePassword['old_password'] = 'Profound@2020';
    this.changePassword['confirm_password'] = '';
    this.changePasswordForm.passwordStrength = {};
    this.changePasswordForm.passwordStrength['newPassword'] = {};
    this.changePasswordForm.passwordStrength['confirmPassword'] = {};

    // GETS USERNAME AND PASSWORD IF THE USER CHOSE TO REMEMBER
    localStorage.getItem('pi|username')
      ? (this.username = localStorage.getItem('pi|username'))
      : (this.username = '');
    localStorage.getItem('pi|password')
      ? (this.password = localStorage.getItem('pi|password'))
      : (this.password = '');
      if (this.username && this.password) {
        this.rememberMe = true;
      }

  }

  // CHECKS PASSWORD VALIDITY
  checkPassword(event: any, type: string) {

    // SETS PASSWORD VALIDITY CHECK PROPERTIES
    const theObject = event;
    const theValue = theObject.target.value;
    this.changePasswordForm.passwordStrength[type] = {};
    this.changePasswordForm.passwordStrength[type]['pcLengthValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcUpperValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcLowerValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcNumberValue'] = 0;
    this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 0;
    const lengthCharacters = event.target.value.length >= 8;
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numberCharacters = /[0-9]+/g;
    const specialCharacters = /[\^!@#$%&*()_\[\]{};:"\\|,\.<>/?~`]+/;

    if (lengthCharacters) {
      this.changePasswordForm.passwordStrength[type]['pcLengthValue'] = 1;
    }
    if (upperCaseCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcUpperValue'] = 1;
    }
    if (lowerCaseCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcLowerValue'] = 1;
    }
    if (numberCharacters.test(theValue) === true) {
      this.changePasswordForm.passwordStrength[type]['pcNumberValue'] = 1;
    }
    if (specialCharacters.test(theValue) === true) {
      if (
        event.target.value.includes('-') ||
        // tslint:disable-next-line: quotemark
        event.target.value.includes("'") ||
        event.target.value.includes('-') ||
        event.target.value.includes('+') ||
        event.target.value.includes('=')
      ) {
        this.errorMsg = 'Invalid Special Character Used';
        this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 0;
      } else {
        if (this.errorMsg === 'Invalid Special Character Used') {
          this.errorMsg = 'Your verification code has been sent to your email';
        }
        this.changePasswordForm.passwordStrength[type]['pcSpecialValue'] = 1;
        this.changePasswordForm.passwordStrength[type]['checkPassword'] = 1;
      }
    }

  }

  // RESOLVES THE CAPTCHA RESPONSE
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    console.log(this.captcha);
    this.captchaStatus = true;
  }

  // TOGGLE REMEMBER ME
  toggleRememberMe() {
    this.rememberMe = !this.rememberMe;
  }

  // SAVE REMEMBER ME
  rememberMeSave() {
    localStorage.setItem('pi|username', this.username);
    localStorage.setItem('pi|password', this.password);
  }

  // UPDATES NEW PASSWORD STATE
  setNewPassword(event: any) {
    this.password = event;
  }

  // UPDATES CONFIRM PASSWORD STATE
  setConfirmPassword(event: any) {
    this.confirmPassword = event;
  }

  // TOGGLES FORGOT PASSWORD VIEW
  toggleForgotPassword(event) {
    this.errorMsg = '';
    event.preventDefault();
    this.currentPageState = PageState.ForgotPassword;
  }

  // TOGGLES FORGOT USERNAME VIEW
  toggleForgotUsername(event) {
    this.errorMsg = '';
    event.preventDefault();
    this.currentPageState = PageState.ForgotUsername;
  }

  // SWITCHES VIEW BACK TO LOGIN
  goBackToLogin(event) {
    this.errorMsg = '';
    this.email = '';
    event.preventDefault();
    this.currentPageState = PageState.NormalSignin;
  }

  // STARTS USER SIGN UP FLOW
  signup() {

    this.errorMsg = '';
    const username = this.username;
    const password = this.password;
    const email = this.email;

    this.amplifyService
      .auth()
      .signUp({
        username,
        password,
        attributes: {
          email,
        },
        validationData: [],
      })
      .then((data) => {
        console.log(data);
        if (data.user) {
          this.email = '';
          this.password = '';
          this.currentPageState = PageState.Verification;
        }
      })
      .catch((err) => {
        this.errorMsg = err.message;
      });

  }

  // VERIFIES USER SIGNUP AND DISPLAYS LOGIN PAGE
  verify() {
    this.errorMsg = '';
    this.amplifyService
      .auth()
      .confirmSignUp(this.email, this.verificationCode)
      .then((data) => {
        this.currentPageState = PageState.NormalSignin;
      })
      .catch((e) => { });
  }

  // TRIGGERS THE FORGOT USERNAME API TO EMAIL THE USER WITH INSTRUCTIONS
  forgotUsername() {
    if (this.email === '') {
      this.errorMsg = 'Email is required';
    } else {
      this.api.forgotUsername(this.email).subscribe(
        (data) => {
          this.errorMsg = '';
          this.successMsg = 'An email with your Username has been sent!';
          setTimeout(() => {
            this.successMsg = '';
          }, 5000);
          this.currentPageState = PageState.NormalSignin;
        },
        (error) => {
          this.errorMsg =
            'If you have given the correct Email Address, an email will be sent with your Username';
        }
      );
    }
  }

  // TRIGGERS THE RESET PASSWORD API
  resetForgotPassword() {
    let emptyCount = 0;
    if (this.email === '') {
      emptyCount++;
    }
    if (this.verificationCode === '') {
      emptyCount++;
    }
    if (this.password === '') {
      emptyCount++;
    }
    if (this.confirmPassword === '') {
      emptyCount++;
    }
    if (emptyCount >= 2) {
      this.errorMsg = 'Please fill in the required fields';
    }
    if (
      this.email === '' &&
      this.verificationCode !== '' &&
      this.password !== '' &&
      this.confirmPassword !== ''
    ) {
      this.errorMsg = 'Username is required';
    }
    if (
      this.email !== '' &&
      this.verificationCode === '' &&
      this.password !== '' &&
      this.confirmPassword !== ''
    ) {
      this.errorMsg = 'Verification Code is required';
    }
    if (
      this.email !== '' &&
      this.verificationCode !== '' &&
      this.password === '' &&
      this.confirmPassword !== ''
    ) {
      this.errorMsg = 'New Password is required';
    }
    if (
      this.email !== '' &&
      this.verificationCode !== '' &&
      this.password !== '' &&
      this.confirmPassword === ''
    ) {
      this.errorMsg = 'Confirm Password is required';
    }
    if (
      this.email !== '' &&
      this.verificationCode !== '' &&
      this.password !== '' &&
      this.confirmPassword !== ''
    ) {
      if (
        this.changePasswordForm.passwordStrength['newPassword']
          .pcLengthValue !== 1 ||
        this.changePasswordForm.passwordStrength['newPassword'].pcLowerValue !==
        1 ||
        this.changePasswordForm.passwordStrength['newPassword'].pcUpperValue !==
        1 ||
        this.changePasswordForm.passwordStrength['newPassword']
          .pcNumberValue !== 1 ||
        this.changePasswordForm.passwordStrength['newPassword']
          .pcSpecialValue !== 1
      ) {
        this.errorMsg = 'Password does not meet the requirements below';
      } else {
        if (this.password !== this.confirmPassword) {
          this.errorMsg = 'Passwords do not match';
        } else {
          this.amplifyService
            .auth()
            .forgotPasswordSubmit(
              this.email,
              this.verificationCode,
              this.password
            )
            .then(() => {
              setTimeout(() => {
                this.successMsg = 'Your Password has been changed sucessfully';
              }, 500);
              this.errorMsg = '';
              this.currentPageState = PageState.NormalSignin;
            })
            .catch((error) => {
              this.errorMsg =
                'Something went wrong, please try again or contact us';
            });
        }
      }
    }
  }
  
  // CHECK IF USERNAME EXISTS AND ALSO CHECK THE STATUS OF COGNTIO USER
  checkUserStatus() { 
    this.api.cognitoUserStatus(this.email).subscribe((res) => {
      if(res.message) {
        this.currentPageState = PageState.NormalSignin;
        this.errorMsg = res.message;
      } else {
        this.sendForgetPaswordVerificationCode();
      }
    },
    (error) => {
      if(error.error.message) {
        this.errorMsg = error.error.message;
      }
    })
  }

  // TRIGGERS THE FORGOT PASSWORD API TO EMAIL THE USER WITH INSTRUCTIONS
  sendForgetPaswordVerificationCode() {
    if (this.email === '') {
      this.errorMsg = 'Username is required';
    } else {
      this.amplifyService
        .auth()
        .forgotPassword(this.email)
        .then((result) => {
          this.password = '';
          this.verificationCode = '';
          setTimeout(() => {
            this.errorMsg =
              `Your reset request has been sent via ${result.CodeDeliveryDetails.DeliveryMedium} to ${result.CodeDeliveryDetails.Destination}`;
          }, 500);
          this.currentPageState = PageState.PasswordVerification;
        })
        .catch((err) => {
          console.log(err);
          if (
            err.message ===
            'Attempt limit exceeded, please try after some time.'
          ) {
            this.errorMsg = err.message;
          } else {
            this.errorMsg = 'Username does not exist';
          }
        });
    }
  }

  // COMPLETES THE CREATION OF THE NEW USER PASSWORD SETUP
  async completeSignupNewPassword() {
    this._spinnerLoader.hide();
    try {
      await this.amplifyService
        .auth()
        .completeNewPassword(
          this.user,
          this.password,
          this.user.challengeParam.requiredAttributes
        );
      this._spinnerLoader.hide();
      const user = await this.amplifyService
        .auth()
        .signIn(this.username, this.password);
      this._spinnerLoader.hide();
      this.user = user;
      this.activeModal.close({ isAuthenticated: true, user });
      this.util.setUser(user);
    } catch (err) {
      this.errorMsg = err.message;
    }
  }

  cognitoMigration() {
    //cognitoMigration
    this.api.checkCognitoMigration(this.username).subscribe(res => {
      console.log(res)
      if(res.status === 'OK') {
        this.errorMsg = res.message
      }
      
      if(res.status === 'FORCE_CHANGE_PASSWORD') {
        this.FORCE_CHANGE_PASSWORD = true;
      }

      if(res.status === 'FORCE_CHANGE_PASSWORD' || res.status === 1) {
        this.login()
      }
    })
    
  }

  // LOGS IN THE USER
  login() {
    if (this.rememberMe) {
      this.rememberMeSave();
    } else {
      localStorage.removeItem('pi|username');
      localStorage.removeItem('pi|password');
    }
    this._spinnerLoader.show();
    if (this.username === '' && this.password === '') {
      this.errorMsg = 'Username and Password are required';
      this._spinnerLoader.hide();
    }
    if (this.username === '' && this.password !== '') {
      this.errorMsg = 'Username is required.';
      this._spinnerLoader.hide();
    }
    if (this.username !== '' && this.password === '') {
      this.errorMsg = 'Password is required';
      this._spinnerLoader.hide();
    }
    if (this.username !== '' && this.password !== ''&&this.captchaStatus===false) {
      this.errorMsg = 'Captcha is Required';
      this._spinnerLoader.hide();
    }
    if (this.username !== '' && this.password !== ''&&this.captchaStatus) {
      this.errorMsg = '';
      this.successMsg = '';
      this.amplifyService
        .auth()
        .signIn(this.username, this.password)
        .then((user) => {
          this.user = user;
          console.log('USER: ', this.user);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.currentPageState = PageState.CompleteSignupPasswordChange;
            this._spinnerLoader.hide();
            this.captchaStatus=false;
          } else {
            if(user.attributes['custom:uuidPerson']){
              this.util.setUser(user);
              this.activeModal.close({ isAuthenticated: true, user });
              this._spinnerLoader.hide();
            }
          }
        })
        .catch((err) => {
          if (err.message === 'User is not confirmed') {
            this.currentPageState = PageState.Verification;
            this._spinnerLoader.hide();
          }else {
            if(this.FORCE_CHANGE_PASSWORD) {
              this.errorMsg = 'Check your email for temporary password';
            } else{
              this.errorMsg = 'Invalid Username or Password';
            }
            this._spinnerLoader.hide();
          }
        });
    }
  }

  joinUs() {
    this.router.navigate(['/join-us']);
    this.activeModal.close()
  }

  linkedinLogin() {
    this.api.linkedinLogin()
  }
}
