<div id="profile-section-volunteer" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Volunteer Experience</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add volunteer button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!volunteerList || volunteerList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your volunteer experience or click the
          [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()"
              aria-label="verify none button" role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="volunteerList">
        <div class="profile__section__list--item" *ngFor="let item of volunteerList; index as i;">
          <div *ngIf="item.name != 'NONE' || volunteerList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <span *ngIf="item.name !== 'NONE'">Organization:</span>
                <span *ngIf="item.name === 'NONE'">Volunteering:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Short Name:</span>
                <span class="fw500 pl05r">{{ item.shortName }}</span>
              </div>
              <div *ngIf="item.webSite  && item.name !== 'NONE'" class="col-10" role="list item property value">
                <span>Website:</span>
                <span class="fw500 pl05r">{{item.webSite}}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.cause  && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Cause:</span>
                <span class="fw500 pl05r">{{ item.cause }}</span>
              </div>
              <div *ngIf="item.position  && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Role:</span>
                <span class="fw500 pl05r">{{ item.position }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.startDateFlag  && item.name !== 'NONE'">
                  <span>Start Date:</span>
                  <span class="fw500 pl05r">
                    {{item.startDateFlag.day ? item.startDate.getDate() : ''}}
                    {{item.startDateFlag.month ? months[+item.startDateFlag.month - 1] : ''}}
                    {{item.startDateFlag.year ? item.startDateFlag.year : ''}}
                  </span>
                </div>
              </div>
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.endDateFlag && !item.currentlyWorking  && item.name !== 'NONE'">
                  <span>End Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.endDateFlag.day ? item.endDate.getDate() : '' }}
                    {{ item.endDateFlag.month ? months[+item.endDateFlag.month - 1] : '' }}
                    {{ item.endDateFlag.year ? item.endDateFlag.year : '' }}
                  </span>
                </div>
                <div *ngIf="item.currentlyWorking  && item.name !== 'NONE'">
                  <span>End Date:</span>
                  <span class="fw500 pl05r">Currently working</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || volunteerList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit volunteer button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete volunteer button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Volunteer Experience</div>
        <div *ngIf="volunteerModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- ORGANIZATION -->
        <div class="profile__form--organization">
          <label class="form__label">Orgnaization <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name" type="text"
            [(ngModel)]="volunteerModel.name" #toNode_name="ngModel" [ngbTypeahead]="search"
            placeholder="Ex. Society for the Prevention of Cruelty to Animals" [disabled]="deleteMode"
            aria-label="organization - required"   />
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="currentItem_toNode_shortName" name="currentItem_toNode_shortName"
            [ngModel]="volunteerModel.shortName | neo4junescape"
            (ngModelChange)="volunteerModel.shortName = this.escape.it($event)" #toNode_shortName="ngModel"
            placeholder="Ex. SPCA" [disabled]="deleteMode" aria-label="short name"   />
        </div>
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="currentItem_toNode_webSite" name="currentItem_toNode_webSite" type="url"
            [ngModel]="volunteerModel.webSite | neo4junescape"
            [pattern]="websiteRegExp"
            #website="ngModel"
            (ngModelChange)="volunteerModel.webSite = this.escape.it($event)" placeholder="Ex. https://hbspca.com/"
            [disabled]="deleteMode" aria-label="website"   
            [ngClass]="{'ng-invalid': !website.valid }"/>
        </div>
        <!-- CAUSE -->
        <div class="profile__form--cause">
          <label class="form__label">Cause</label>
          <input class="form__control" id="currentItem_edge_industry" name="currentItem_edge_industry" type="text"
          [(ngModel)]="volunteerModel.cause" #toNode_cause="ngModel" [ngbTypeahead]="searchCauses"
          placeholder="Ex. Animal Welfare" [disabled]="deleteMode"
          aria-label="cause"   />
          <!-- <select class="form__control" id="currentItem_edge_industry" name="currentItem_edge_industry"
              [(ngModel)]="volunteerModel.cause" aria-placeholder="cause" [disabled]="deleteMode"
            aria-label="cause">
            <option value="Select">Select</option>
            <option value="Animal Welfare">Animal Welfare</option>
            <option value="Arts and Culture">Arts and Culture</option>
            <option value="Children">Children</option>
            <option value="Civil Rights and Social Action">Civil Rights and Social Action</option>
            <option value="Disaster and Humanitarial Relief">Disaster and Humanitarial Relief</option>
            <option value="Economic Empowerment">Economic Empowerment</option>
            <option value="Education">Education</option>
            <option value="Environment">Environment</option>
            <option value="Health">Health</option>
            <option value="Human Rights">Human Rights</option>
            <option value="Politics">Politics</option>
            <option value="Poverty Alleviation">Poverty Alleviation</option>
            <option value="Science and Technology">Science and Technology</option>
            <option value="Social Service">Social Service</option>
          </select> -->
        </div>
        <!-- ROLE -->
        <div class="profile__form--role">
          <label class="form__label">Role</label>
          <input class="form__control" id="currentItem_edge_position" name="currentItem_edge_position"
            [ngModel]="volunteerModel.position | neo4junescape"
            (ngModelChange)="volunteerModel.position = this.escape.it($event)" placeholder="Ex. Animal Care Assistant"
            [disabled]="deleteMode" aria-label="role"   />
        </div>
        <!-- SUMMARY -->
        <div class="profile__form--summary">
          <label class="form__label">Summary</label>
          <textarea class="form__control text-area" id="currentItem_edge_summary" name="currentItem_edge_summary"
            [ngModel]="volunteerModel.summary | neo4junescape"
            placeholder="Ex. Duties included feeding and cleaning up after the animals until they found a home"
            (ngModelChange)="volunteerModel.summary = this.escape.it($event)" autosize [minRows]="5" [maxRows]="10"
            [disabled]="deleteMode" aria-label="description"  ></textarea>
        </div>
        <!-- CURRENT -->
        <div class="profile__form--current">
          <label class="content-verify form__label"><span>I am currently working in this role</span>
            <input name="current" type="checkbox" [(ngModel)]="currentlyWorking" (click)="toggleCurrentlyWorking()"
              [disabled]="deleteMode" aria-label="currently working"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- START YEAR -->
        <div class="profile__form--start-year">
          <label class="form__label">Start Year <span class="form__required">*</span></label>
          <select class="form__control" #startYear (change)="onDateChange('startDate', 'year', startYear.value)" required
            [(ngModel)]="volunteerModel.startDate.year" name="volunteer_year" [disabled]="deleteMode"
            aria-label="start year - required"  
            [ngClass]="{'ng-invalid': isStartYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="volunteerModel.startDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- START MONTH -->
        <div class="profile__form--start-month" *ngIf="volunteerModel.startDate.year">
          <label class="form__label">Start Month</label>
          <select class="form__control" (change)="onDateChange('startDate', 'month')"
            [(ngModel)]="volunteerModel.startDate.month" name="volunteer_month" [disabled]="deleteMode"
            aria-label="start month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- START DAY -->
        <div class="profile__form--start-day" *ngIf="volunteerModel.startDate.year && volunteerModel.startDate.month">
          <label class="form__label">Start Day</label>
          <select class="form__control" (change)="onDateChange('startDate', 'day')"
            [(ngModel)]="volunteerModel.startDate.day" name="volunteer_day" [disabled]="deleteMode"
            aria-label="start day"  >
            <option value="">Select Day</option>
            <option *ngFor="let item of startDayValue" [selected]="volunteerModel.startDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END YEAR -->
        <div class="profile__form--end-year" *ngIf="!currentlyWorking || volunteerModel.currentlyWorking === 'false'">
          <label class="form__label">End Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('endDate', 'year')" required
            [(ngModel)]="volunteerModel.endDate.year" name="volunteer_year2" [disabled]="deleteMode"
            aria-label="end year - required"  >
            <option value="">Select Year</option>
            <option *ngFor="let item of endYearValue" [selected]="volunteerModel.endDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END MONTH -->
        <div class="profile__form--end-month"
          *ngIf="(!currentlyWorking || volunteerModel.currentlyWorking === 'false') && volunteerModel.endDate.year">
          <label class="form__label">End Month</label>
          <select class="form__control" (change)="onDateChange('endDate', 'month')"
            [(ngModel)]="volunteerModel.endDate.month" name="volunteer_month2" [disabled]="deleteMode"
            aria-label="end month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of endMonthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- END DAY -->
        <div class="profile__form--end-day"
          *ngIf="(!currentlyWorking || volunteerModel.currentlyWorking === 'false') && volunteerModel.endDate.year && volunteerModel.endDate.month">
          <label class="form__label">End Day</label>
          <select class="form__control" (change)="onDateChange('endDate', 'day')"
            [(ngModel)]="volunteerModel.endDate.day" name="volunteer_day2" [disabled]="deleteMode" aria-label="end day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of endDayValue" [selected]="volunteerModel.endDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && volunteerModel.dataSource === 'User') ||
                (thisForm.form.invalid && volunteerModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-organization" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Orgnaization is required</strong>
        </div>
        <div class="row tac profile__form--error-start-year" *ngIf="isStartYearRequired">
          <strong>Start Year is required</strong>
        </div>
        <ng-container *ngIf="!displayDeleteButton">
          <div class="row tac profile__form--error-invalid-website-url" *ngIf="!website.valid">
            <strong>Not a valid website url</strong>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>