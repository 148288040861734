<div id="profile-section-certifications" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Certifications</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add certificate"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!certificationList || certificationList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your certificates or click the [NONE] if
          you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()"
              aria-label="verify none button" role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="certificationList">
        <div class="profile__section__list--item" *ngFor="let item of certificationList; index as i;">
          <div *ngIf="item.name != 'NONE' || certificationList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <span>Certificate:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName" class="col-md-6" role="list item property value">
                <span>Short Name:</span>
                <span class="fw500 pl05r">{{ item.shortName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12" role="list item property value">
                <div *ngIf="item.obtainedDateTimeFlag && item.name !== 'NONE' ">
                  <span>Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '' }}
                    {{ item.obtainedDateTimeFlag.month ? months[+item.obtainedDateTimeFlag.month - 1] : '' }}
                    {{ item.obtainedDateTimeFlag.year ? item.obtainedDateTimeFlag.year : '' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || certificationList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit certifications button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete certifications button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Certifications</div>
        <div *ngIf="certificateModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- CERTIFICATE -->
        <div class="profile__form--certificate">
          <label class="form__label">Certificate <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name"
            [ngModel]="certificateModel.name" placeholder="Ex. Project Management Professional Certification"
            #toNode_name="ngModel" (ngModelChange)="certificateModel.name = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="certificate - required" />
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="currentItem_toNode_shortName" name="currentItem_toNode_shortName"
            (ngModelChange)="certificateModel.shortName = this.escape.it($event)" [ngModel]="certificateModel.shortName"
            placeholder="Ex. PMP" [disabled]="deleteMode" aria-label="short name" />
        </div>
        <!-- YEAR -->
        <div class="profile__form--year">
          <label class="form__label">Year <span class="form__required">*</span></label>
          <select class="form__control" #year (change)="onDateChange('obtainedDateTime', 'year', year.value)" required
            [(ngModel)]="certificateModel.obtainedDateTime.year" name="certificate_year" [disabled]="deleteMode"
            aria-label="year - requirement"
            [ngClass]="{'ng-invalid': isYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="
              certificateModel.obtainedDateTime.year == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- MONTH -->
        <div class="profile__form--month" *ngIf="certificateModel.obtainedDateTime.year">
          <label class="form__label">Month</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'month')"
            [(ngModel)]="certificateModel.obtainedDateTime.month" name="certificate_month" [disabled]="deleteMode"
            aria-label="month">
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- DAY -->
        <div class="profile__form--day"
          *ngIf="certificateModel.obtainedDateTime.year && certificateModel.obtainedDateTime.month">
          <label class="form__label">Day</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'day')"
            [(ngModel)]="certificateModel.obtainedDateTime.day" name="certificate_day" [disabled]="deleteMode"
            aria-label="day">
            <option value="">Select Day</option>
            <option *ngFor="let item of dayValue" [selected]="
              certificateModel.obtainedDateTime.day == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && certificateModel.dataSource === 'User') ||
                (thisForm.form.invalid && certificateModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-certificate" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Certificate is required</strong>
        </div>
        <div class="row tac profile__form--error-year" *ngIf="isYearRequired">
          <strong>Year is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>