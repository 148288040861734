<div class="col-md-12 col-sm-12 o-a" style="max-height: 600px;">
  <div>
    <div class="modal-header pt-2 pb-2 z8000">
      <button
        type="button"
        class="close"
        aria-label="Close button"
        role="button"
        ngbAutofocus
        (click)="closeDialog()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-header-lg">
      <div class="container h-100 p-0">
        <div
          class="row m-0"
          style="
            margin: 0px;
            padding: 10px 0px;
            height: 70px;
          "
        >
          <div class="col-8" style="padding: 8px">
            <h5
              class="card--job-board-title text-truncate fw600"
            >
              {{ job.company }}
            </h5>
            <h5
              class="card--job-board-title text-truncate fw400">
              {{ job.location }}
            </h5>
          </div>
          <div
            class="col-4"
            style="padding: 0px 0px 0px 10px"
          >
            <img
              *ngIf="job.imageURL"
              src="{{ job.imageURL }}"
              style="height: 80px; width: 80px; position: absolute; right: 20px; top: -10px; border: solid rgb(160, 160, 160) 1px;"
            />
          </div>
        </div>
        <div
          class="row"
          style="
            margin: 0px;
            padding: 0px;
          "
        >
          <div class="col-12" style="padding: -8px 0px 8px 0px; margin-left: -3px;">
            <h5
              class="card--job-board-title text-truncate fw600"
              style="color: rgb(80, 80, 80)"
            >
              {{ job.title }}
            </h5>
            <h5
              class="card--job-board-desc fw400 taj">
              {{ job.fullDescription }}
            </h5>
          </div>
        </div>
        <div
          class="row m-0"
          style="
            margin: 0px;
            padding: 20px 8px 10px 8px;
            height: 40px;
          "
        >
          <h5
            class="card--job-board-title fw600">
            {{ job.compensation }}
          </h5>
          <h5
          class="card-text text-nowrap text-truncate"
          style="position: absolute; right: 20px"
          >
            {{ job.pubDate }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>