import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { isNgTemplate } from '@angular/compiler';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EmploymentEdge } from 'src/app/shared/utilities/app-db-edges';
import { Organization } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants]
})
export class ExperienceComponent implements OnInit {

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();
  @ViewChild('card') card: ElementRef;

  experienceList = [];

  companies: any;
  nameSpaceValid: boolean;
  unverified: boolean;
  editMode: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService,
    public util: UtilityService
  ) {}

  flipCard = false;

  status: ServiceStatus;
  viewmodel = this.data.viewmodel;
  newItem: { edge: EmploymentEdge; toNodeRef: NodeRef; toNode: Organization };
  currentItem: {
    edge: EmploymentEdge;
    toNodeRef: NodeRef;
    toNode: Organization;
  };
  experienceModel: any = {
    name: '',
    shortName: '',
    summary: '',
    position: '',
    industry: 'Select',
    webSite: '',
    department: '',
    type: 'Select',
    currentlyWorking: true,
    startDate: {
      year: '',
      month: '',
      day: '',
    },
    endDate: {
      year: '',
      month: '',
      day: '',
    },
    startDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  work_in_Organization: any = {};
  months: any = [];
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  startDayValue: any[] = [];
  endDayValue: any[] = [];
  startYearValue: any[] = [];
  endYearValue: any[] = [];
  endMonthValue: any[] = [];
  currentlyWorking = true;
  isMobile: boolean;
  types: any;
  deleteMode = false;
  isStartYearRequired = false;
  currentYear = new Date().getFullYear();
  websiteRegExp = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  isZeroRegExp = /^0[0-9].*$/;
  
  ngOnInit() {
    this.getCompanySuggestions();
    this.api.getDropdowns('EmploymentType').subscribe(
      (data) => {
        const response: any = data;
        this.types = response.data;
      },
      (error) => {
        this.util.logger(error, 'GET EXPERIENCE DROPDOWNS ERROR');
      }
    );

    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getWorkList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.startDate.month) ? "0" + i : i});
      this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
  }

  flip(){
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  checkSpaces(str: string, field: string) {
    if (field === 'name') {
      this.nameSpaceValid = this.util.checkSpaces(str);
    }
  }

  getCompanySuggestions() {
    this.api.getSuggestions('experience').subscribe((data) => {
      const response: any = data;
      this.companies = response.data.name;
    });
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.companies
          : this.companies.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  onDateChange(field: any, input: any, startYear) {
    if (startYear === '') {
      this.isStartYearRequired = true;
    } else {
      this.isStartYearRequired = false;
    }
    const thisYear = new Date().getFullYear();
    if (field === 'startDate') {
      if (input === 'year') {
        if (this.experienceModel.startDate.year !== '') {
          this.experienceModel.endDate.year = '';
          this.experienceModel.endDate.month = '';
          this.experienceModel.endDate.day = '';
          this.experienceModel.startDate.month = '';
          this.experienceModel.startDate.day = '';
          this.endYearValue = [];
          for (
            let i = this.experienceModel.startDate.year;
            i <= thisYear;
            i++
          ) {
            this.endYearValue.push({ value: i });
          }
        } else {
          console.log('START DATE YEAR', this.experienceModel.startDate.year);
          this.yearValue = [];
          this.endYearValue = [];
          this.monthValue = [];
          this.endMonthValue = [];
          this.endDayValue = [];
          this.startDayValue = [];
          this.dayValue = [];
          for (let i = this.currentYear; i >= 1930; i--) {
            this.yearValue.push({ value: i });
            this.endYearValue.push({ value: i });
          }
          for (let i = 1; i <= 12; i++) {
            this.monthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.startDate.month) ? "0" + i : i });
            this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
          }
          for (let i = 1; i <= 31; i++) {
            this.dayValue.push({ value: i });
            this.startDayValue.push({ value: i });
            this.endDayValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.startDayValue = [];
        for (
          let i = 1;
          i <= this.data.days[this.experienceModel.startDate.month - 1];
          i++
        ) {
          this.startDayValue.push({ value: i });
        }
        if (
          this.experienceModel.startDate.year ===
          this.experienceModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.experienceModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
          }
        }
        this.experienceModel.startDate.day = '';
      }
      if (input === 'day') {
        if (
          this.experienceModel.startDate.month ===
            this.experienceModel.endDate.month &&
          this.experienceModel.startDate.year ===
            this.experienceModel.endDate.year
        ) {
          this.experienceModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.experienceModel.startDate.day;
            i < this.data.days[this.experienceModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (field === 'endDate') {
      if (input === 'year') {
        this.experienceModel.endDate.month = '';
        this.experienceModel.endDate.day = '';
        if (
          this.experienceModel.startDate.year ===
          this.experienceModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (
            let i = this.experienceModel.startDate.month
              ? this.experienceModel.startDate.month
              : 1;
            i <= 12;
            i++
          ) {
            this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
          }
        } else {
          this.endMonthValue = [];
          for (let i = 1; i <= 12; i++) {
            this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
          }
        }
      }
      if (input === 'month') {
        this.endDayValue = [];
        if (
          this.experienceModel.startDate.month ===
            this.experienceModel.endDate.month &&
          this.experienceModel.startDate.year ===
            this.experienceModel.endDate.year
        ) {
          this.experienceModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.experienceModel.startDate.day;
            i < this.data.days[this.experienceModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        } else {
          this.endDayValue = [];
          for (
            let i = 1;
            i <= this.data.days[this.experienceModel.endDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i });
          }
        }
      }
    }
    const flag = this.experienceModel[field][input] !== '' ? 1 : 0;
    this.experienceModel[field + 'Flag'][input] = flag;
  }

  toggleCurrentlyWorking() {
    this.currentlyWorking = !this.currentlyWorking;
    this.experienceModel.currentlyWorking = this.currentlyWorking;
  }

  async getWorkList() {
    this.experienceList = [];
    const apiUrl = `${this.root}/userProfileUpdateExperience?operation=list`;
    const getWorkList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getWorkList.subscribe((response) => {
      console.log('GET WORK LIST', response);
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORDS
        this.viewmodel.work_in_Organization = [];
        let tempCurrentlyWorking = [];
        const tempEndDate = [];
        let tempExperience = [];
        let removeDuplicates = [];

        tempCurrentlyWorking = [...response.record]
        tempCurrentlyWorking.forEach((value, index) => {
          if(value.row[0].startDate === undefined){
            tempCurrentlyWorking[index].row[0].startDate=JSON.parse(JSON.stringify({month:null,year:null,day:null}))
          }
          if(value.row[0].endDate === undefined){
            tempCurrentlyWorking[index].row[0].endDate=JSON.parse(JSON.stringify({month:null,year:null,day:null}))
          }
          if(value.row[0].currentlyWorking !== true){
            tempEndDate.push(value);
          }
        });
       
        tempCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        tempEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        tempExperience = [...tempCurrentlyWorking]
        response.record = tempExperience;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            startDate: new Date(
              response.record[item].row[0].startDate.year,
              response.record[item].row[0].startDate.month - 1,
              response.record[item].row[0].startDate.day
            ),
            endDate: new Date(
              response.record[item].row[0].endDate.year,
              response.record[item].row[0].endDate.month - 1,
              response.record[item].row[0].endDate.day
            ),
            startDateFlag: {
              year: response.record[item].row[0].startDate.year,
              month: response.record[item].row[0].startDate.month,
              day: response.record[item].row[0].startDate.day,
            },
            endDateFlag: {
              year: response.record[item].row[0].endDate.year,
              month: response.record[item].row[0].endDate.month,
              day: response.record[item].row[0].endDate.day,
            },
            currentlyWorking: response.record[item].row[0].currentlyWorking
              ? response.record[item].row[0].currentlyWorking
              : false,
            industry:
              response.record[item].row[0].industry !== 'undefined'
                ? response.record[item].row[0].industry
                : 'Select',
            position:
              response.record[item].row[0].position !== 'undefined'
                ? response.record[item].row[0].position
                : '',
            uuid: response.record[item].row[0].uuid,
            name:
              response.record[item].row[0].name !== 'undefined'
                ? response.record[item].row[0].name
                : '',
            department:
              response.record[item].row[0].department !== 'undefined'
                ? response.record[item].row[0].department
                : '',
            type:
              response.record[item].row[0].type !== 'undefined'
                ? response.record[item].row[0].type
                : 'Select',
            shortName: response.record[item].row[0].shortName,
            summary:
              response.record[item].row[0].summary !== 'undefined'
                ? response.record[item].row[0].summary
                : '',
            webSite: response.record[item].row[0].webSite,
            dataSource: response.record[item].row[0].dataSource,
            status : 'verified'
          };
          if (temp.currentlyWorking) {
            this.viewmodel.work_in_Organization.unshift(temp);
            this.experienceList.unshift(temp);
            removeDuplicates.unshift(temp);
          } else {
            this.viewmodel.work_in_Organization.push(temp);
            this.experienceList.push(temp);
            removeDuplicates.push(temp);
          }
        }
        this.util.logger(
          this.viewmodel.work_in_Organization,
          'CONFIRMED/USER CREATED EXPERIENCES'
        );

        // UNVERIFIED IMPORTED RECORDS
        this.viewmodel.unverified_Work_in_Organization = [];
        let UNVERIFIEDCurrentlyWorking = [];
        const UNVERIFIEDEndDate = [];
        let UNVERIFIEDExperience = [];
        
        UNVERIFIEDCurrentlyWorking = [...response.unverified]
        UNVERIFIEDCurrentlyWorking.forEach((value, index) => {
          if(value.row[0].startDate === undefined){
            UNVERIFIEDCurrentlyWorking[index].row[0].startDate=JSON.parse(JSON.stringify({month:null,year:null,day:null}))
          }
          if(value.row[0].endDate === undefined){
            UNVERIFIEDCurrentlyWorking[index].row[0].endDate=JSON.parse(JSON.stringify({month:null,year:null,day:null}))
          }
          if(value.row[0].currentlyWorking !== true){
            UNVERIFIEDEndDate.push(value);
          }
        });
        
        UNVERIFIEDCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        UNVERIFIEDEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        
        UNVERIFIEDExperience = [...UNVERIFIEDCurrentlyWorking]
        response.unverified = UNVERIFIEDExperience;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            startDate: new Date(
              response.unverified[item].row[0].startDate.year,
              response.unverified[item].row[0].startDate.month - 1,
              response.unverified[item].row[0].startDate.day
            ),
            endDate: new Date(
              response.unverified[item].row[0].endDate.year,
              response.unverified[item].row[0].endDate.month - 1,
              response.unverified[item].row[0].endDate.day
            ),
            startDateFlag: {
              year: response.unverified[item].row[0].startDate.year,
              month: response.unverified[item].row[0].startDate.month,
              day: response.unverified[item].row[0].startDate.day,
            },
            endDateFlag: {
              year: response.unverified[item].row[0].endDate.year,
              month: response.unverified[item].row[0].endDate.month,
              day: response.unverified[item].row[0].endDate.day,
            },
            currentlyWorking: response.unverified[item].row[0].currentlyWorking
              ? response.unverified[item].row[0].currentlyWorking
              : false,
            industry:
              response.unverified[item].row[0].industry !== 'undefined'
                ? response.unverified[item].row[0].industry
                : 'Select',
            position:
              response.unverified[item].row[0].position !== 'undefined'
                ? response.unverified[item].row[0].position
                : '',
            uuid: response.unverified[item].row[0].uuid,
            name:
              response.unverified[item].row[0].name !== 'undefined'
                ? response.unverified[item].row[0].name
                : '',
            department:
              response.unverified[item].row[0].department !== 'undefined'
                ? response.unverified[item].row[0].department
                : '',
            type:
              response.unverified[item].row[0].type !== 'undefined'
                ? response.unverified[item].row[0].type
                : 'Select',
            shortName: response.unverified[item].row[0].shortName,
            summary:
              response.unverified[item].row[0].summary !== 'undefined'
                ? response.unverified[item].row[0].summary
                : '',
            webSite: response.unverified[item].row[0].webSite,
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            status : 'unverified'
          };
          if (temp.currentlyWorking) {
            this.viewmodel.unverified_Work_in_Organization.unshift(temp);
            // this.experienceList.unshift(temp);
            removeDuplicates.unshift(temp);
          } else {
            this.viewmodel.unverified_Work_in_Organization.push(temp);
            // this.experienceList.push(temp);
            removeDuplicates.push(temp);
          }
        }
        const result = removeDuplicates.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.name === thing.name && t.shortName === thing.shortName && t.position === thing.position && t.department === thing.department && t.type === thing.type
          ))
        )
        console.log("🚀 ~ file: experience.component.ts ~ line 579 ~ ExperienceComponent ~ getWorkList.subscribe ~ result", JSON.stringify(result))
        this.experienceList = this.experienceList.concat(result);
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
      this.util.logger(
        this.viewmodel.unverified_Work_in_Organization,
        'UNVERIFIED IMPORTED EXPERIENCES'
      );
    });
    // this.viewmodel.unverified_Work_in_Organization.forEach(element => {
    //   element.type = 'unverified';
    //   this.experienceList.push(element);
    // });
    // this.viewmodel.work_in_Organization.forEach(element => {
    //   element.type = 'verified';
    //   this.experienceList.push(element);
    // });
    console.log('EXPERIENCES: ', this.experienceList)
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        industry: 'Select',
        position: '',
        summary: '',
        startDate: '',
        endDate: '',
        department: '',
        type: 'Select',
        currentlyWorking: true,
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: {
        labels: null,
        uuid: '',
        name: '',
      },
      toNode: {
        uuid: '',
        name: '',
        shortName: '',
        logoUrl: '',
        webSite: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.startDate.month) ? "0" + i : i });
      this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.editMode = false;
    this.currentlyWorking = true;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.experienceModel = {
      name: '',
      shortName: '',
      summary: '',
      position: '',
      industry: 'Select',
      webSite: '',
      type: 'Select',
      department: '',
      currentlyWorking: true,
      startDate: {
        year: '',
        month: '',
        day: '',
      },
      endDate: {
        year: '',
        month: '',
        day: '',
      },
      startDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
      endDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    console.log('ITEM');
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    this.editMode = true;
    if (item.importStatus) {
      this.experienceModel.importStatus = item.importStatus;
    }
    console.log({ item });
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.experienceModel = { ...item };
    this.experienceModel.edgeUuid = item.uuid;
    this.experienceModel.currentlyWorking = item.currentlyWorking;
    this.experienceModel.dataSource = item.dataSource;
    this.currentlyWorking = item.currentlyWorking;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = '';
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = '';
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.experienceModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.experienceModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.experienceModel.industry = item.industry ? item.industry : 'Select';
    this.experienceModel.type = item.type ? item.type : 'Select';
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.startDate.month) ? "0" + i : i });
      this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.experienceModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (
        this.experienceModel.startDate.year ===
        this.experienceModel.endDate.year
      ) {
        this.endMonthValue = [];
        for (let i = this.experienceModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
        }
      }
      if (
        this.experienceModel.startDate.month ===
          this.experienceModel.endDate.month &&
        this.experienceModel.startDate.year ===
          this.experienceModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.experienceModel.startDate.day;
          i < this.data.days[this.experienceModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
    console.log('EXPERIENCE MODEL', this.experienceModel);
  }

  async saveClick() {
    if (this.experienceModel.currentlyWorking) {
      this.experienceModel.endDate = {
        day: '',
        month: '',
        year: '',
      };
    }

    if (this.experienceModel.industry === 'Select') {
      this.experienceModel.industry = '';
    }

    if (this.experienceModel.type === 'Select') {
      this.experienceModel.type = '';
    }

    console.log('EDIT MODE: ', this.editMode);
    console.log('UNVERIFIED: ', this.unverified);

    if (this.editMode && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateExperience?operation=update`;
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.experienceModel, edgeUuid: this.experienceModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          // this.api.refreshProfileCompletion();
          this.util.getUserProfile();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.getWorkList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateExperience?operation=create`;
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.experienceModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          // this.api.refreshProfileCompletion();
          this.util.getUserProfile();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.getWorkList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
        }
      );
    }
    this.updateTabs.emit();
    this.getCompanySuggestions();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    if (item.importStatus) {
      this.experienceModel.importStatus = item.importStatus;
    }
    this.deleteMode = true;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.currentlyWorking = item.currentlyWorking;
    this.experienceModel = { ...item };
    this.experienceModel.edgeUuid = item.uuid;
    this.experienceModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.experienceModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.experienceModel.currentlyWorking = item.currentlyWorking
      ? item.currentlyWorking
      : false;
    this.experienceModel.industry = item.industry ? item.industry : 'Select';
    this.experienceModel.type = item.type ? item.type : 'Select';
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.startDate.month) ? "0" + i : i });
      this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.experienceModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (
        this.experienceModel.startDate.year ===
        this.experienceModel.endDate.year
      ) {
        this.endMonthValue = [];
        for (let i = this.experienceModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: this.isZeroRegExp.test(this.experienceModel.endDate.month) ? "0" + i : i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.experienceModel.startDate.month ===
          this.experienceModel.endDate.month &&
        this.experienceModel.startDate.year ===
          this.experienceModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.experienceModel.startDate.day;
          i < this.data.days[this.experienceModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
  }

  deleteClick() {
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateExperience?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.experienceModel, edgeUuid: this.experienceModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getWorkList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  cancelClick() {
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }

  options = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel/Fashion",
    "Architecture/Planning",
    "Arts/Crafts",
    "Automotive",
    "Aviation/Aerospace",
    "Banking/Mortgage",
    "Biotechnology/Greentech",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies/Equipment",
    "Capital Markets/Hedge Fund/Private Equity",
    "Chemicals",
    "Civic/Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software/Engineering",
    "Computer/Network Security",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Design",
    "E-Learning",
    "Education Management",
    "Electrical/Electronic Manufacturing",
    "Entertainment/Movie Production",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food Production",
    "Food/Beverages",
    "Fundraising",
    "Furniture",
    "Gambling/Casinos",
    "Glass/Ceramics/Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design/Web Design",
    "Health/Fitness",
    "Higher Education/Acadamia",
    "Hospital/Health Care",
    "Hospitality",
    "Human Resources/HR",
    "Import/Export",
    "Individual/Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology/IT",
    "Insurance",
    "International Affairs",
    "International Trade/Development",
    "Internet",
    "Investment Banking/Venture",
    "Investment Management/Hedge Fund/Private Equity",
    "Judiciary",
    "Law Enforcement",
    "Law Practice/Law Firms",
    "Legal Services",
    "Legislative Office",
    "Leisure/Travel",
    "Library",
    "Logistics/Procurement",
    "Luxury Goods/Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing/Advertising/Sales",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Equipmeform-row pl-3 pr-3Care",
    "Military Industry",
    "Mining/Metals",
    "Motion Pictures/Film",
    "Museums/Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers/Journalism",
    "Non-Profit/Volunteering",
    "Oil/Energy/Solar/Greentech",
    "Online Publishing",
    "Other Industry",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging/Containers",
    "Paper/Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training",
    "Program Development",
    "Public Relations/PR",
    "Public Safety",
    "Publishing Industry",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate/Mortgage",
    "Recreational Facilities/Services",
    "Religious Institutions",
    "Renewables/Environment",
    "Research Industry",
    "Restaurants",
    "Retail Industry",
    "Security/Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing/Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation/Localization",
    "Transportation",
    "Utilities",
    "Venture Capital/VC",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine/Spirits",
    "Wireless",
    "Writing/Editing"
  ]
}

