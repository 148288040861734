import { Injectable, NgZone } from "@angular/core"
import { AmplifyService } from "aws-amplify-angular"
import { NgxSpinnerService } from "ngx-spinner"
import { DataService } from "src/app/services/data.service"
import { HttpService } from "src/app/services/http.service"

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as icons from "rendered-country-flags";

am4core.useTheme(am4themes_animated);

@Injectable({
  providedIn: "root",
})
export class EventGraphService {

  chart: am4plugins_forceDirected.ForceDirectedTree;
  graphData: any;
  smallGraphData: any[];
  graphDataLoaded: boolean;

  constructor(
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public data: DataService,
    public api: HttpService,
    private zone: NgZone,
  ) { 
  }

  eventGraph (id) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.zone.runOutsideAngular(() => {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create(
          'event-graph',
          am4plugins_forceDirected.ForceDirectedTree
        );
        chart.tooltip.pointerOrientation = 'up';
        chart.tooltip.background.pointerLength = 0;
        const networkSeries = chart.series.push(
          new am4plugins_forceDirected.ForceDirectedSeries()
        );
        this.api.getGroupEventGraph(id).subscribe((data) => {
          const response: any = data;
          this.graphData = response.data;
          this.smallGraphData = [];
          for (const node of this.graphData) {
            if (node.color === '#DF2498') {
              this.smallGraphData.push({
                name: !node.image ? node.name : '',
                label: node.label,
                image: node.image ? node.image : icons[node.name],
                id: node.id,
                tooltip: node.tooltip,
                color: am4core.color(this.data.accessibilityMode ? '#490a31' : '#DF2498'),
                linkWith: node.linkWith,
                // value: node.value ? node.value : 20,
                value: node.image ? 100 :  node.value ? node.value : 20,
                width: node.width ? node.width : 120,
                height: node.height ? node.height : 120
              });
            }
            if (node.color === '#FFD54F') {
              this.smallGraphData.push({
                name: !icons[node.name] ? node.name : '',
                label: node.label,
                image: node.image ? node.image : icons[node.name],
                id: node.id,
                tooltip: node.tooltip,
                color: am4core.color('#FFD54F'),
                linkWith: node.linkWith,
                // value: node.value ? node.value : 9,
                value: icons[node.name] ? 0 :  node.value ? node.value : 9,
                width:  node.width ? node.width : 35,
                height: node.height ? node.height : 25,
              });
            }
            if (node.color === '#FFBEEF') {
              this.smallGraphData.push({
                name: !node.image ? node.name : '',
                label: node.label,
                image: node.image ? node.image : icons[node.name],
                id: node.id,
                tooltip: node.tooltip,
                color: am4core.color('#FFBEEF'),
                linkWith: node.linkWith,
                // value: node.value ? node.value : 9,
                value: node.image ? 0 :  node.value ? node.value : 9,
                width:  node.width ? node.width : 10,
                height: node.height ? node.height : 10
              });
            }
            if (node.color === '#DFDFDF') {
              this.smallGraphData.push({
                name: 'Private',
                label: node.label,
                image: node.image ? node.image : icons[node.name],
                series: [{
                  nodes: {
                    circle: {
                      disabled: node.circle ? node.circle.disabled : false
                    },
                  }
                }],
                id: node.id,
                tooltip: node.tooltip,
                color: am4core.color('#DFDFDF'),
                linkWith: node.linkWith,
                // value: node.value ? node.value : 9,
                value: (node.image != '') ? 0 :  node.value ? node.value : 9,
              });
            }
          }
          networkSeries.data = this.smallGraphData;
          this.graphDataLoaded = true;
        });
        
        networkSeries.dataFields.linkWith = 'linkWith';
        networkSeries.dataFields.name = 'name';
        networkSeries.dataFields.id = 'id';
        networkSeries.dataFields.value = 'value';
        networkSeries.dataFields.children = 'children';
        networkSeries.dataFields.color = 'color';
        
        networkSeries.colors.list = [
          am4core.color('#FFD54F'),
          am4core.color('#FFBEEF'),
          am4core.color('#DF2498'),
        ];
        networkSeries.nodes.template.tooltipText = '{tooltip}';
        networkSeries.nodes.template.fillOpacity = 1;
        networkSeries.nodes.template.label.text = '[font-size: 14px]{name}[/]';
        networkSeries.fontSize = 8;
        networkSeries.maxLevels = 2;
        networkSeries.maxRadius = am4core.percent(5);
        networkSeries.manyBodyStrength = -20;
        networkSeries.nodes.template.label.hideOversized = true;
        networkSeries.nodes.template.label.truncate = true;
        networkSeries.links.template.strokeWidth = 2;
        const hoverState = networkSeries.links.template.states.create('hover');
        hoverState.properties.strokeWidth = 3;
        hoverState.properties.strokeOpacity = 1;
       
        // Configure circles
        //networkSeries.nodes.template.circle.disabled = true;
        //networkSeries.dataItem.children.template.node.circle.disabled = true

        // Configure icons
        var icon = networkSeries.nodes.template.createChild(am4core.Image);
        icon.propertyFields.href = "image";
        icon.horizontalCenter = "middle";
        icon.verticalCenter = "middle";
        icon.width = 35
        icon.height = 25
        
        networkSeries.nodes.template.events.on('ready', (event) => {
           // console.log("LINK", event.target.dataItem)
            if(event.target.dataItem.node.children.values[3]) {
              event.target.dataItem.node.children.values[3].width = event.target.dataItem.dataContext['width']
              event.target.dataItem.node.children.values[3].height = event.target.dataItem.dataContext['height']
            }
        })

        networkSeries.nodes.template.events.on('over', function (event) {
          event.target.dataItem.childLinks.each(function (link) {
            link.isHover = true;
          });
          if (event.target.dataItem.parentLink) {
            event.target.dataItem.parentLink.isHover = true;
          }
        });

        networkSeries.nodes.template.events.on('out', function (event) {
          event.target.dataItem.childLinks.each(function (link) {
            link.isHover = false;
          });
          if (event.target.dataItem.parentLink) {
            event.target.dataItem.parentLink.isHover = false;
          }
        });

        this.chart = chart
      });

    });
  }

}
