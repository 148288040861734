<div id="pdf-dialog">

    <div
      role="region"
      aria-label="xml help instructions to export"
      class="xml-image-container"
    >
      <p class="pdf-info-heading">Importing Personal CV Instructions</p>
      <div class="pdf-steps">
        <mat-icon
          style="font-size: 16px; color: rgb(232, 161, 54); margin-top: 3px"
          >star_rate</mat-icon
        >
        <span style="color: rgb(69, 69, 69); font-weight: 600; font-size: 16px">
          Benefits: Using the PDF format to import your profile will give you the
          best chance of extracting everything you have input
        </span>
        <br /><br />
        <p class="wrapper">
            <span class="card--p-alt" *ngFor="let item of dataSource"> {{item.title +': '}} {{item.description}} </span>
        </p>
      </div>
      
    </div>
    <br>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let item of dataSource">
              <div class="wrapper">
                <img
                    class="pdf-image"
                    [src]="item.src"
                    [alt]="item.description"
                    role="img"
                />
              </div>
              <div class="carousel-caption carouselHeader">
                <h3 class="carouselHeader">{{item.title}}</h3>
                <p> {{item.description}} </p>
              </div>
            </ng-template>
        </ngb-carousel>    
    </div>
  </div>
