<div id="profile-section-research" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Research</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add research button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!researchList || researchList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your research or click the [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()" aria-label="verify none button"
              role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="researchList">
        <div class="profile__section__list--item" *ngFor="let item of researchList; index as i;">
          <div *ngIf="item.name != 'NONE' || researchList.length == 1"  class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div *ngIf="item.title && item.name !== 'NONE'" class="col-12" role="list item property value">
                <span>Title:</span>
                <span class="fw500 pl05r">{{ item.title }}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.name && item.title !== item.name" class="col-md-6" role="list item property value">
                <span *ngIf="item.name !== 'NONE'">Area:</span>
                <span *ngIf="item.name === 'NONE'">Research:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.yearsOfExperience && item.name !== 'NONE'" class="col-md-6"
                role="list item property value">
                <span>Experience:</span>
                <div class="fw500 pl05r">
                  {{ item.yearsOfExperience }}
                  <span *ngIf="item.yearsOfExperience > 1">years</span>
                  <span *ngIf="item.yearsOfExperience === 1">year</span>
                </div>
              </div>
            </div>
            <div *ngIf="item.name !== 'NONE'">
              <div class="row">
                <div *ngIf="item.category" class="col-md-6" role="list item property value">
                  <span>Category:</span>
                  <span class="fw500 pl05r">{{ item.category }}</span>
                </div>
                <div *ngIf="item.position" class="col-md-6" role="list item property value">
                  <span>Position:</span>
                  <span class="fw500 pl05r">{{ item.position }}</span>
                </div>
              </div>
              <div class="row">
                <div *ngIf="item.principalInvestigator" class="col-md-6" role="list item property value">
                  <span>Principal Investigator:</span>
                  <span class="fw500 pl05r">{{ item.principalInvestigator }}</span>
                </div>
                <div *ngIf="item.coInvestigator" class="col-md-6" role="list item property value">
                  <span>Co-Investigator:</span>
                  <span class="fw500 pl05r">{{ item.coInvestigator }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" role="list item property value">
                  <div *ngIf="item.startDateFlag">
                    <span>Start Date:</span>
                    <span class="fw500 pl05r">
                      {{ item.startDateFlag.day ? item.startDate.getDate() : '' }}
                      {{ item.startDateFlag.month ? months[+item.startDateFlag.month - 1] : '' }}
                      {{ item.startDateFlag.year ? item.startDateFlag.year : '' }}
                    </span>
                  </div>
                </div>
                <div class="col-md-6" role="list item property value">
                  <div *ngIf="item.endDateFlag && !item.currentlyWorking">
                    <span>End Date:</span>
                    <span class="fw500 pl05r">
                      {{ item.endDateFlag.day ? item.endDate.getDate() : '' }}
                      {{ item.endDateFlag.month ? months[+item.endDateFlag.month - 1] : '' }}
                      {{ item.endDateFlag.year ? item.endDateFlag.year : '' }}
                    </span>
                  </div>
                  <div *ngIf="item.currentlyWorking">
                    <span>End Date:</span>
                    <span class="fw500 pl05r">Currently researching</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || researchList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit research button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete research button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Research</div>
        <div *ngIf="researchModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- RESEARCH TITLE -->
        <div class="profile__form--title">
          <label class="form__label">Research Title <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_title"  
            [ngModel]="researchModel.title | neo4junescape" name="currentItem_toNode_title"
            placeholder="Ex. High-performance medicine: the convergence of human and artificial intelligence"
            [ngbTypeahead]="titleSuggestions" (ngModelChange)="researchModel.title = this.escape.it($event)"
            #researchTitle="ngModel" [disabled]="deleteMode" aria-label="research title - required" />
        </div>
        <!-- AREA OF RESEARCH -->
        <div class="profile__form--research">
          <label class="form__label">Area of Research</label>
          <input class="form__control" id="currentItem_toNode_name" name="currentItem_toNode_name"
            [ngModel]="researchModel.name | neo4junescape" [ngbTypeahead]="nameSuggestions"
            placeholder="Ex. Artificial Intelligence" (ngModelChange)="researchModel.name = this.escape.it($event)"
            #toNode_name="ngModel" [disabled]="deleteMode" aria-label="area of research"   />
        </div>
        <!-- YEARS OF EXPERIENCE -->
        <div class="profile__form--experience">
          <label class="form__label">Years of Experience</label>
          <input class="form__control" id="currentItem_edge_yearsOfExperience" type="number"
            name="currentItem_edge_yearsOfExperience" placeholder="Ex. 15 (1 - 99)" [ngClass]="{'ng-invalid': (thisForm.form.dirty && researchModel.yearsOfExperience > 99 &&
              researchModel.yearsOfExperience !== null) || researchModel.yearsOfExperience <= 0}"
            (ngModelChange)="checkExperience($event)" [(ngModel)]="researchModel.yearsOfExperience"
            aria-label="years of experience"   [disabled]="deleteMode" />
        </div>
        <!-- CATEGORY -->
        <div class="profile__form--category">
          <label class="form__label">Category</label>
          <select class="form__control" id="currentItem_edge_category" name="currentItem_edge_category"
            [(ngModel)]="researchModel.category" aria-placeholder="category" value="researchModel.category"
            [disabled]="deleteMode" aria-label="category"  >
            <option value="Select">Select</option>
            <option *ngFor="let category of categories" [value]="category">
              {{ category }}
            </option>
          </select>
        </div>
        <!-- POSITION -->
        <div class="profile__form--position">
          <label class="form__label">Position</label>
          <select class="form__control" id="currentItem_edge_position" name="currentItem_edge_position"
            [(ngModel)]="researchModel.position" aria-placeholder="position" value="researchModel.position"
            [disabled]="deleteMode" aria-label="position"  >
            <option value="Select">Select</option>
            <option *ngFor="let position of positions" [value]="position">
              {{ position }}
            </option>
          </select>
        </div>
        <!-- PRINCIPAL INVESTIGATOR -->
        <div class="profile__form--principal-investigator">
          <label class="form__label">Principal Investigator</label>
          <input class="form__control" id="currentItem_toNode_principalInvestigator"
            name="currentItem_toNode_principalInvestigator"
            [ngModel]="researchModel.principalInvestigator | neo4junescape" placeholder="Ex. John Doe"
            (ngModelChange)="researchModel.principalInvestigator = this.escape.it($event)"  
            #toNode_principalInvestigator="ngModel" [disabled]="deleteMode" aria-label="principal inverstigator" />
        </div>
        <!-- CO-INVESTIGATOR -->
        <div class="profile__form--co-investigator">
          <label class="form__label">Co-Investigator</label>
          <input class="form__control"  id="currentItem_toNode_coInvestigator" name="currentItem_toNode_coInvestigator"
            [ngModel]="researchModel.coInvestigator | neo4junescape" placeholder="Ex. Jane Doe"
            (ngModelChange)="researchModel.coInvestigator = this.escape.it($event)"
            #toNode_coInvestigator="ngModel" [disabled]="deleteMode" aria-label="co-investigator"   />
        </div>
        <!-- CURRENT -->
        <div class="profile__form--current">
          <label class="content-verify form__label"><span>I am currently working in this role</span>
            <input name="current" type="checkbox" [(ngModel)]="currentlyWorking" (click)="toggleCurrentlyWorking()"
              [disabled]="deleteMode" aria-label="currently working"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- START YEAR -->
        <div class="profile__form--start-year">
          <label class="form__label">Start Year <span class="form__required">*</span></label>
          <select class="form__control" #startYear (change)="onDateChange('startDate', 'year', startYear.value)" required
            [(ngModel)]="researchModel.startDate.year" name="certif_year" [disabled]="deleteMode"
            aria-label="start year - required" 
            [ngClass]="{'ng-invalid': isStartYearRequired}" >
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="researchModel.startDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- START MONTH -->
        <div class="profile__form--start-month" *ngIf="researchModel.startDate.year">
          <label class="form__label">Start Month</label>
          <select class="form__control" (change)="onDateChange('startDate', 'month')"
            [(ngModel)]="researchModel.startDate.month" name="certif_month" [disabled]="deleteMode"
            aria-label="start month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- START DAY -->
        <div class="profile__form--start-day" *ngIf="researchModel.startDate.year && researchModel.startDate.month">
          <label class="form__label">Start Day</label>
          <select class="form__control" (change)="onDateChange('startDate', 'day')"
            [(ngModel)]="researchModel.startDate.day" name="certif_day" [disabled]="deleteMode" aria-label="start day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of startDayValue" [selected]="researchModel.startDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END YEAR -->
        <div class="profile__form--end-year" *ngIf="!currentlyWorking || researchModel.currentlyWorking === 'false'">
          <label class="form__label">End Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('endDate', 'year')" required
            [(ngModel)]="researchModel.endDate.year" name="certif_year2" [disabled]="deleteMode"
            aria-label="end year - required"  >
            <option value="">Select Year</option>
            <option *ngFor="let item of endYearValue" [selected]="researchModel.endDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END MONTH -->
        <div class="profile__form--end-month"
          *ngIf="(!currentlyWorking || researchModel.currentlyWorking === 'false') && researchModel.endDate.year">
          <label class="form__label">End Month</label>
          <select class="form__control" (change)="onDateChange('endDate', 'month')"
            [(ngModel)]="researchModel.endDate.month" name="certif_month2" [disabled]="deleteMode"
            aria-label="end month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of endMonthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- END DAY -->
        <div class="profile__form--end-day"
          *ngIf="(!currentlyWorking || researchModel.currentlyWorking === 'false') && researchModel.endDate.year && researchModel.endDate.month">
          <label class="form__label">End Day</label>
          <select class="form__control" (change)="onDateChange('endDate', 'day')"
            [(ngModel)]="researchModel.endDate.day" name="certif_day2" [disabled]="deleteMode" aria-label="end day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of endDayValue" [selected]="researchModel.endDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && researchModel.dataSource === 'User') ||
                (thisForm.form.invalid && researchModel.dataSource !== 'User')" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-research-title" *ngIf="researchTitle.invalid && (researchTitle.dirty || researchTitle.touched)">
          <strong *ngIf="researchTitle.errors.required">Research Title is required</strong>
        </div>
        <div class="row tac profile__form--error-start-year" *ngIf="isStartYearRequired">
          <strong>Start Year is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>