<div aria-label="panelist bio dialog" *ngIf="data?.type == 'person'" class="info-person relative">
    <button type="button" class="close" aria-label="Close button" role="button" ngbAutofocus (click)="closeDialog()"
        style="position: absolute; top: -1rem; right: 0;">
        <span aria-hidden="true" style="font-size: 4rem;">&times;</span>
    </button>
    <img aria-label="panelist image" class="info-image"
        src="{{environment.publicBucketUrl}}event-panelists/{{ data?.person?.image ? data.person.image : 'no-logo.jpeg' }}">
    <div aria-label="panelist name" class="info-name">
        {{ data?.person?.name }}
    </div>
    <div aria-label="panelist title" class="info-title">
        {{ data?.person?.designation }}
    </div>
    <pre aria-label="panelist bio" *ngIf="data?.person?.description" class="info-description">
        {{ data?.person?.description }}
    </pre>
    <div aria-label="panelist social media links" *ngIf="data?.person?.social" class="info-social">
        <div *ngFor="let platform of data?.person?.social">
            <a *ngIf="platform?.type == 'twitter' && platform?.url != null" href="{{ platform?.url }}"
                target="_blank"><i class="fab fa-twitter" style="color: #1da1f2"></i></a>
            <a *ngIf="platform?.type == 'facebook' && platform?.url != null" href="{{ platform?.url }}"
                target="_blank"><i class="fab fa-facebook-square" style="color: #3b5998"></i></a>
            <a *ngIf="platform?.type == 'instagram' && platform?.url != null" href="{{ platform?.url }}"
                target="_blank"><i class="fab fa-instagram" style="color: #404040"></i></a>
            <a *ngIf="platform?.type == 'linkedin' && platform?.url != null" href="{{ platform?.url }}"
                target="_blank"><i class="fab fa-linkedin" style="color: #0e76a8"></i></a>
            <a *ngIf="platform?.type == 'youtube' && platform?.url != null" href="{{ platform?.url }}"
                target="_blank"><i class="fab fa-youtube" style="color: #c4302b"></i></a>
        </div>
    </div>
</div>
<div aria-label="session info dialog" *ngIf="data?.type == 'session'" class="info-session relative">
    <button type="button" class="close" aria-label="Close button" role="button" ngbAutofocus (click)="closeDialog()"
        style="position: absolute; top: -1rem; right: 0;">
        <span aria-hidden="true" style="font-size: 4rem;">&times;</span>
    </button>
    <div aria-label="session time" class="info-title-session">
        {{ util.startTimeConversion(data?.session?.startTime) }}{{ util.startTimeAM(data?.session?.startTime) ? 'AM' :
        'PM' }} EST - {{ util.startTimeConversion(data?.session?.endTime) }}{{ util.startTimeAM(data?.session?.endTime)
        ? 'AM' : 'PM' }} EST
    </div>
    <div aria-label="session title" class="info-name-session">
        {{ data?.session?.name }}
    </div>
    <pre aria-label="session details" *ngIf="data?.session?.details" class="info-description-session">
        {{ data?.session?.details }}
    </pre>
    <div *ngIf="vignette">
        <a href="https://perimeterinstitute.ca/sites/default/files/PI_Escape_the_Forces_of_Nature_Museum_Start.pdf" target="_blank" style="margin-right: 2rem">
            <button type="button" aria-label="more details link for session button"
                class="custom-btn grow">ESCAPE ROOM</button>
        </a>
        <a href="https://video.vidyard.com/watch/6n29UYVBCmM5XPwDSNAd8z"
        target="_blank" style="margin-right: 2rem">
            <button type="button" aria-label="more details link for session button"
                class="custom-btn grow">BOOTCAMP WORKOUT</button>
        </a>
    </div>
    <div *ngIf="!vignette && data?.session?.detailsLink">
        <a href="{{ data?.session?.detailsLink }}" target="_blank" style="margin-right: 2rem">
            <button type="button" aria-label="more details link for session button"
                class="custom-btn grow">Link</button>
        </a>
    </div>
</div>