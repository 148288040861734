export interface NodeRef {
  labels: string[]
  uuid?: string
  name: string
}

export enum ServiceStatus {
  OK = "OK",
  NoData = "NoData",
  Failed = "Failed",
}

export enum UIAction {
  Add,
  Edit,
  Delete,
}

export interface ServiceResponseMessage {
  status: ServiceStatus
  data: any
  message: string
}

export interface RelationServiceResponseMessage {
  status: ServiceStatus
  data: { fromNode: any; edge: any; toNode: any; edgeNode?: any }[]
  message: string
}

export interface DeepRelationServiceResponseMessage {
  status: ServiceStatus
  data: any
  message: string
}

export interface GraduationChartDataItem {
  year: number
  industry: string //
  percentage: number
  count: number
  extraInfo: string
}

export interface GraduationChartServiceResponseMessage {
  status: ServiceStatus
  data: GraduationChartDataItem[]
  message: string
}

export interface GraduationEmploymentChartDataItem {
  year: number
  employedThisyear: number //
  unemployedThisyear: number
  totalThisyear: number
  employedHistory: number //
  unemployedHistory: number
  totalHistory: number
  extraInfo: string
}

export interface GraduationEmploymentServiceResponseMessage {
  status: ServiceStatus
  data: GraduationEmploymentChartDataItem[]
  message: string
}

export interface GraduationGeoChartDataItem {
  city: string //
  latitude: number
  longitude: number
  count: number
  extraInfo: string
}

export interface GraduationGeoChartServiceResponseMessage {
  status: ServiceStatus
  data: GraduationGeoChartDataItem[]
  message: string
}

export interface AcademicFamilyTreeDataItem {
  nodes: []
  links: []
}
export interface AcademicFamilyTreeSrviceResponseMessage {
  status: ServiceStatus
  data: AcademicFamilyTreeDataItem[]
  message: string
}
