<ngx-spinner></ngx-spinner>
<div id="contact" class="fill-view" role="region" aria-label="contact page">
  <mat-tab-group mat-align-tabs="center" role="region" class="h100">
    <mat-tab label="Contact-Help">
      <div *ngIf="data.currentUser && !isLoading && profileCompletion" class="row m-0 contact__row" fxLayoutAlign="center"
        role="form">
        <div *ngIf="!isLoading" class="row contact__form-content" fxLayoutAlign="center" ngStyle.gt-sm="width: 90%" ngStyle.sm="width: 90%"
          ngStyle.lt-sm="width: 120%">
          <div [ngClass]="{
              'col-9': !isMobile && !data.LT_LG,
              'col-12': isMobile || data.LT_LG
            }">
            <div [ngClass]="{
                'tab-content': !isMobile && !data.LT_LG,
                'mobile-tab-content': isMobile || data.LT_LG
              }">
              <div class="tab-pane active" [ngClass]="{
                  showTab: selectedUserTab == 18,
                  hideTab: selectedUserTab != 18
                }">
                <ngx-flip [flip]="flipDiv" id="contact-user-profile-help">
                  <div front>
                    <div>
                      <div class="contact-card" role="contact">
                        <div>
                          <div class="email-add">
                            <div class="contact__pre-info">
                              <span class="primary-label"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : 'rgb(133, 133, 133)' }"><span
                                  class="header-label">Sherry Shannon-Vanstone</span><br />
                                  Profound Impact - President and CEO<br />
                                  sshannon@profoundimpact.com<br />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <form (ngSubmit)="onSubmit()">
                            <div>
                              <div class="row">
                                <div class="col-md-6">
                                  <input disabled type="text" class="form-control dark-border" [(ngModel)]="helpForm.name"
                                    placeholder="name *" name="name" aria-label="name - required"   />
                                </div>
                                <div class="col-md-6 contact__form-email">
                                  <input disabled type="text" class="form-control dark-border" [(ngModel)]="helpForm.email"
                                    placeholder="email *" name="email" aria-label="email - required"   />
                                </div>
                                <div class="col-md-12 contact__form-message">
                                  <textarea class="form-control message dark-border" name="message" [ngStyle]="{ 'min-height': '130px' }"
                                    [(ngModel)]="helpForm.message" placeholder="How can we help?" row="10" required #message="ngModel"
                                    aria-label="message - required"  
                                    ></textarea>
                                    <ng-container *ngIf="message.invalid && (message.dirty || message.touched)">
                                      <p class="card--h4 form__required text-center" *ngIf="message.errors.required"> Message is required.</p>
                                    </ng-container>
                                </div>
                              </div>
                            </div>
                            <div >
                              <div class="row">
                                <div class="col-md-12">
                                  <button type="submit" class="button button--dark-grey pull-right contact__form-button" 
                                    [disabled]="!helpForm.message" aria-label="submit button" role="button">
                                    SUBMIT
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </ngx-flip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>