<div id="profile-section-awards" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Awards</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add certificate"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!awardList || awardList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your awards or click the [NONE] if
          you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" type="button" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()" aria-label="verify none button"
              role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="awardList">
        <div class="profile__section__list--item" *ngFor="let item of awardList; index as i;">
          <div *ngIf="item.name != 'NONE' || awardList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <span>Award:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Short Name:</span>
                <span class="fw500 pl05r">{{ item.shortName }}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.officialUrl && item.name !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Website:</span>
                <span class="fw500 pl05r">{{ item.officialUrl }}</span>
              </div>
              <div *ngIf="item.obtainedDateTimeFlag.year" class="col-md-6" role="list item property value">
                <span>Date:</span>
                <span class="fw500 pl05r">
                  {{ item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '' }}
                  {{ item.obtainedDateTimeFlag.month ? months[+item.obtainedDateTimeFlag.month - 1] : '' }}
                  {{ item.obtainedDateTimeFlag.year ? item.obtainedDateTimeFlag.year : '' }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || awardList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit" type="submit"
                (click)="gotoEditClick(item, true)" aria-label="edit certifications" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete certifications" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Awards</div>
        <div *ngIf="awardsModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- AWARD -->
        <div class="profile__form--award">
          <label class="form__label">Award <span class="form__required">*</span></label>
          <textarea class="form__control specialty-text-area" required id="awardsModel_name" name="awardsModel_name"
            [ngModel]="awardsModel.name" (ngModelChange)="awardsModel.name = this.escape.it($event)" maxlength="300"
            #toNode_name="ngModel" placeholder="Ex. the British Academy of Film and Television Arts"
            [disabled]="deleteMode" autosize [minRows]="5" [maxRows]="10" aria-label="award - required"
             ></textarea>
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="awardsModel_shortName" name="awardsModel_shortName"
            [ngModel]="awardsModel.shortName" (ngModelChange)="awardsModel.shortName = this.escape.it($event)"
            #toNode_shortName="ngModel" placeholder="Ex. BAFTA" [disabled]="deleteMode" aria-label="short name"
              />
        </div>
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="awardsModel_officialUrl" name="awardsModel_officialUrl"
            [ngModel]="awardsModel.officialUrl | neo4junescape"
            (ngModelChange)="awardsModel.officialUrl = this.escape.it($event)" placeholder="Ex. http://www.bafta.org/"
            [disabled]="deleteMode" aria-label="website"   />
        </div>
        <!-- YEAR -->
        <div class="profile__form--year">
          <label class="form__label">Award Year <span class="form__required">*</span></label>
          <select class="form__control" #awardYear (change)="onDateChange('obtainedDateTime', 'year', awardYear.value)" required
            [(ngModel)]="awardsModel.obtainedDateTime.year" name="certificate_year" [disabled]="deleteMode"
            aria-label="year - requirement"  
            [ngClass]="{'ng-invalid': isAwardYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="awardsModel.obtainedDateTime.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- MONTH -->
        <div class="profile__form--month" *ngIf="awardsModel.obtainedDateTime.year">
          <label class="form__label">Award Month</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'month')"
            [(ngModel)]="awardsModel.obtainedDateTime.month" name="certificate_month" [disabled]="deleteMode"
            aria-label="month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- DAY -->
        <div class="profile__form--day" *ngIf="awardsModel.obtainedDateTime.year && awardsModel.obtainedDateTime.month">
          <label class="form__label">Award Day</label>
          <select class="form__control" (change)="onDateChange('obtainedDateTime', 'day')"
            [(ngModel)]="awardsModel.obtainedDateTime.day" name="certificate_day" [disabled]="deleteMode"
            aria-label="day"  >
            <option value="">Select Day</option>
            <option *ngFor="let item of dayValue" [selected]="
              awardsModel.obtainedDateTime.day == item.value
            ">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && awardsModel.dataSource === 'User') ||
                (thisForm.form.invalid && awardsModel.dataSource !== 'User')" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-award" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Award is required</strong>
        </div>
        <div class="row tac profile__form--error-award-year" *ngIf="isAwardYearRequired">
          <strong>Award Year is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>