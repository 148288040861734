<div id="user-profile-static-page">
  <div class="modal-header pt-2 pb-2 z8000">
    <button
      type="button"
      class="close"
      aria-label="Close button"
      role="button"
      ngbAutofocus
      (click)="activeModal.close('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div role="region" class="modal-body p-0">
    <div class="container" *ngIf="!isLoading">
      <div class="row">
        <div class="col-12">
          <div class="user__profile-content">
            <img
              class="img-xs rounded-circle"
              [src]="
                viewmodel.person && viewmodel.person.logoUrl != ''
                  ? 'https://' +
                    bucket +
                    '.s3.amazonaws.com/profile-pictures/' +
                    viewmodel.person.logoUrl
                  : 'assets/images/uidesign/user.png'
              "
              onError="this.src='assets/images/uidesign/user.png';"
              alt="user image"
              aria-label="user image"
              role="img"
            />
          </div>
          <div *ngIf="viewmodel.person.profileStatus">
            <h3
              *ngIf="viewmodel.person.profileStatus === 'public'"
              class="col-12 text-center user-details-text"
            >
              {{
                viewmodel.person.displayName
                  ? viewmodel.person.displayName
                  : viewmodel.person.firstName
              }}
              {{
                viewmodel.person.lastName !== "" &&
                viewmodel.person.lastName !== undefined &&
                viewmodel.person.lastName !== "undefined" &&
                viewmodel.person.lastName !== "null" &&
                viewmodel.person.lastName !== null &&
                !viewmodel.person.displayName
                  ? viewmodel.person.lastName
                  : ""
              }}
            </h3>
            <h3
              class="col-12 text-center user-details-text"
              *ngIf="viewmodel.person.profileStatus === 'private'"
            >
              Private User
            </h3>
          </div>
          <div *ngIf="!viewmodel.person.profileStatus">
            <h3 class="col-12 text-center user-details-text">
              Private User
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>