<div id="profile-section-basic" class="profile__content">
  <div class="profile__section__card card">
    <div class="profile__section__list--header">
      <div class="profile__section__list--heading">Basic Info</div>
    </div>
    <!-- FORM -->
    <form #form="ngForm" class="profile__form__container" *ngIf="viewmodel && viewmodel.person">
      <!-- TITLE -->
      <div class="profile__form--title">
        <label class="form__label">Title</label>
        <select class="form__control caret" name="title" [(ngModel)]="userBasicModel.title" type="text"
          placeholder="Ex. Mr." aria-label="title">
          <option>Mr.</option>
          <option>Mrs.</option>
          <option>Ms.</option>
          <option>Dr.</option>
        </select>
      </div>
      <!-- FIRST NAME -->
      <div class="profile__form--fname">
        <label class="form__label">First Name <span class="form__required">*</span></label>
        <input class="form__control" name="firstName" [(ngModel)]="userBasicModel.firstName" type="text"
          placeholder="Ex. John" required #firstName="ngModel" aria-label="first name - required"
          (ngModelChange)="data.currentUser.firstName = $event" />
      </div>
      <!-- LAST NAME -->
      <div class="profile__form--lname">
        <label class="form__label">Last Name <span class="form__required">*</span></label>
        <input class="form__control" name="lastName" [(ngModel)]="userBasicModel.lastName" type="text"
          placeholder="Ex. Doe" required #lastName="ngModel" aria-label="last name - required"
          (ngModelChange)="data.currentUser.lastName = $event" />
      </div>
      <!-- DISPLAY NAME -->
      <div class="profile__form--dname">
        <label class="form__label">Display Name</label>
        <input class="form__control" name="displayName" [(ngModel)]="userBasicModel.displayName" type="text"
          placeholder="Ex. Professor John Doe" #displayName="ngModel" aria-label="display name" />
      </div>
      <!-- IMPACT STORY -->
      <!-- <div class="profile__form--impact">
        <label class="form__label">Your Impact Story</label>
        <textarea class="form__control impact-text-area" name="bio" [(ngModel)]="userBasicModel.bio" type="text"
          placeholder="An Impact Story can be your bio, how you wish to be introduced to others, specific volunteer or other activities, etc. that capture and demonstrate your impact whether locally or globally."
          autosize [minRows]="5" [maxRows]="10" aria-label="your impact story"></textarea>
      </div> -->
      <!-- SPECIALITY -->
      <div class="profile__form--speciality">
        <label class="form__label">Speciality</label>
        <textarea class="form__control specialty-text-area" name="speciality" [(ngModel)]="userBasicModel.speciality"
          placeholder="Ex. Project Management" autosize [minRows]="5" [maxRows]="10" aria-label="speciality"></textarea>
      </div>
      <!-- COUNTRY -->
      <div class="profile__form--country">
        <label class="form__label">Country <span class="form__required">*</span></label>
        <select class="form__control" name="country" [(ngModel)]="userBasicModel.country" (ngModelChange)="
      getStates($event);
      userBasicModel.state = -1;
      currentState = '';
      cityDisabled = true;
      userBasicModel.city = '';
      currentCity = ''" type="text" required placeholder="Ex. Canada" aria-label="country - required">
          <option *ngFor="let c of countries" [ngValue]="c.name">
            {{ c.name }}
          </option>
        </select>
      </div>
      <!-- PROVINCE/STATE -->
      <div class="profile__form--state">
        <label class="form__label">Province/State <span class="form__required">*</span></label>
        <select class="form__control" name="provinceOrState" [(ngModel)]="userBasicModel.state" (ngModelChange)="
      getCities($event);
      onStateChange($event);
      cityDisabled = false;
      userBasicModel.city = '';
      currentCity = ''" type="text" required placeholder="Ex. Ontario" aria-label="province/state - required"
      [ngClass]="{'ng-invalid': isStateRequired}">
          <option value="-1">Select</option>
          <option *ngFor="let s of states" [ngValue]="s.name" [selected]="i == 0">
            {{ s.name }}
          </option>
        </select>
      </div>
      <!-- CITY -->
      <div class="profile__form--city">
        <label class="form__label">City <span class="form__required">*</span></label>
        <input class="form__control" required name="city" type="text" [(ngModel)]="userBasicModel.city"
          #city="ngModel" #cityText
          (ngModelChange)="setCity($event)"  maxlength="75" placeholder="Ex. Hamilton"
          aria-label="city - required" role="text" />
      </div>
      <!-- POSTAL CODE -->
      <div class="profile__form--postal">
        <label class="form__label">Postal Code</label>
        <input class="form__control" name="postalCode" type="text" [(ngModel)]="userBasicModel.postalCode"
          placeholder="Ex. L8J 4A9" aria-label="postal code" />
      </div>
      <!-- PHONE NUMBER -->
      <div class="profile__form--phone">
        <label class="form__label">Phone Number</label>
        <input class="form__control" name="phone" type="text" [(ngModel)]="userBasicModel.phone"
          placeholder="Ex. (905) 555-1234 ex.311" aria-label="phone number" />
      </div>
      <!-- USER IDENTIY -->
      <div class="profile__form--identity">
        <div class="d-flex">
          <label class="form__label col-7">Do you identify as BIPOC (Black, Indigenousm People of Color) ?</label>
          <div>
            <div class="form-check form-check-inline ml-2">
              <input class="form__control form-check-input" aria-labelledby="Yes" type="radio" value='Yes' (ngModelChange)="chooseBIPOC($event)" [(ngModel)]="userBasicModel.bipoc" name="biopoc"> 
              <label class="form__control border-0" id="Yes">Yes</label>

              <input class="form__control form-check-input ml-3" aria-labelledby="No" type="radio" value='No' (ngModelChange)="chooseBIPOC($event)" [(ngModel)]="userBasicModel.bipoc" name="biopoc">
              <label class="form__control border-0" id="No">No</label>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <label class="form__label col-7">Do you identify as LGBT (Lesbian, Gay, Bisexual, Transgender) ?</label>
          <div>
            <div class="form-check form-check-inline ml-2">
              <input class="form__control form-check-input" aria-labelledby="lgbtYes" value='Yes' (ngModelChange)="chooseLGBT($event)" [(ngModel)]="userBasicModel.lgbt" type="radio" name="lgbt"> 
              <label class="form__control border-0" id="lgbtYes">Yes</label>

              <input class="form__control form-check-input ml-3" aria-labelledby="lgbtNo" value='No' (ngModelChange)="chooseLGBT($event)" [(ngModel)]="userBasicModel.lgbt" type="radio" name="lgbt">
              <label class="form__control border-0" id="lgbtNo">No</label>
            </div>
          </div>
        </div>
      </div>
      <!-- SAVE -->
      <div class="profile__form--save">
        <button class="button button--dark-grey" type="submit" (click)="
          saveBasicInfo();
          form.form.markAsUntouched();
          form.form.markAsPristine();
          imageUploadMessage = ''" [disabled]="
          (((form.form.pristine ||
            form.form.invalid ||
            saveDisabled) &&
            !fileChange) ||
            currentCity === '' ||
            currentState === '-1') &&
          !importedItemUsed" aria-label="save button" role="button">
          SAVE
        </button>
      </div>
      <div class="row tac profile__form--error-first-name" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
        <strong *ngIf="firstName.errors.required">First Name is required</strong>
      </div>
      <div class="row tac profile__form--error-last-name" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
        <strong *ngIf="lastName.errors.required">Last Name is required</strong>
      </div>
      <div class="row tac profile__form--error-state" *ngIf="isStateRequired">
        <strong>Province/State is required</strong>
      </div>
      <div class="row tac profile__form--error-city" *ngIf="city.invalid && (city.dirty || city.touched)">
        <strong *ngIf="city.errors.required">City is required</strong>
      </div>
    </form>
  </div>
</div>