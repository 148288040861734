import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss'],
})
export class VideoViewComponent implements OnInit {
  constructor(
    public data: DataService,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {}

  url: any;

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.youtubeURL
    );
  }
}
