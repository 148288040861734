import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './shared/utilities/auth-guard.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ContactComponent } from './pages/contact/contact.component';
import { UploadComponent } from './pages/upload/upload.component';
import { RequirementsComponent } from './pages/requirements/requirements.component';
import { HomeComponent } from './pages/home/home.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { TokenResolverService } from './services/token-resolver.service';
import { Piday2020Component } from './groups/public-groups/piday2020/piday2020.component';
import { Piday2022Component } from './groups/public-groups/piday2022/piday2022.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'profound-impact-day-2020',
    component: Piday2020Component
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'import',
    component: UploadComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'alumni-old',
    loadChildren: './graphs/alumni/alumni.module#AlumniModule',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'alumni',
    loadChildren: './graphs/alumni2/alumni2.module#Alumni2Module',
    //canActivate: [AuthGuardGuard],
  },
  {
    path: 'entrepreneur',
    loadChildren: './graphs/entrepreneur-graph/entrepreneur-graph.module#EntrepreneurGraphModule',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'faculty',
    loadChildren: './graphs/faculty/faculty.module#FacultyModule',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'academic-family-tree',
    loadChildren:
      './graphs/academic-family-tree/academic-family-tree.module#AcademicFamilyTreeModule',
    // canActivate: [AuthGuardGuard],
  },
  {
    path: 'volunteer',
    loadChildren: './graphs/voulunteer-graph/voulunteer-graph.module#VoulunteerGraphModule',
    // canActivate: [AuthGuardGuard],
  },
  {
    path: 'mentoring-relation',
    loadChildren:
      './graphs/mentoring-relation-graph/mentoring-relation-graph.module#MentoringRelationGraphModule',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'publication',
    loadChildren:
      './graphs/publication-common/publication-common.module#PublicationCommonModule',
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'discussion-board/:id',
    loadChildren:
      './shared/components/widgets/discussion-board/discussion-board.module#DiscussionBoardModule',
  },
  {
    path: 'join-us',
    loadChildren: './pages/join-us/join-us.module#JoinUsModule',
  },
  {
    path: 'about',
    loadChildren: './pages/about-us/about-us.module#AboutUsModule',
  },
  {
    path: 'contact-us',
    loadChildren: './pages/contact-us/contact-us.module#ContactUsModule',
  },
  {
    path: 'privacy-policy',
    loadChildren:
      './pages/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
  },
  {
    path: 'requirements',
    component: RequirementsComponent,
  },
  {
    path: 'assessment',
    component: AssessmentComponent
  },
  {
    path: 'user-invitation/:any',
    loadChildren:
      './pages/user-invitation/user-invitation.module#UserInvitationModule',
  },
  {
    path: 'stories/:slug',
    loadChildren: './pages/banner-details/banner-details.module#BannerDetailsModule',
  },
  {
    path: 'enpoint-login',
    loadChildren: './pages/enpoint-login/enpoint-login.module#EnpointLoginModule',
  },
  {
    path: 'profound-impact-day-2022',
    component:Piday2022Component
    // loadChildren: './groups/public-groups/pi-day/pi-day.module#PiDayModule',
  },
  {
    path: 'profound-impact-day-2021',
    loadChildren: './groups/public-groups/pi-day/pi-day.module#PiDayModule',
  },
  
  {
    path: 'profound-insights',
    loadChildren:
      './groups/public-groups/profound-insights/profound-insights.module#ProfoundInsightsModule',
  },
  {
    path: 'iwd2021wr',
    loadChildren:
      './groups/public-groups/iwd/iwd.module#IwdModule',
  },
  {
    path: 'wewdc',
    loadChildren:
      './groups/public-groups/wewdc/wewdc.module#WEWDCModule',
  },
  {
    path: 'wewdc2',
    loadChildren:
      './groups/public-groups/wewdc2/wewdc2.module#WEWDCModule2',
  },
  // {
  //   path: 'profound-insights-old',
  //   loadChildren:
  //     './groups/public-groups/profound-insights-old/profound-insights.module#ProfoundInsightsModule',
  // },
  {
    path: 'test-private-group',
    loadChildren:
      './groups/private-groups/test-private-group/test-private-group.module#TestPrivateGroupModule',
  },
  {
    path: '2020convocation',
    loadChildren: './pages/2020-convocation/join.module#JoinModule',
  },
  {
    path: 'login/validate/:client/:email',
    loadChildren: './pages/client-login-verification/client-login-verification.module#ClientLoginVerificationModule',
  },
  {
    path: 'login/callback',
    loadChildren: './pages/validate-linkedin-user/validate-linkedin-user.module#ValidateLinkedinModule',
    pathMatch: 'full',
    resolve: {
      access: TokenResolverService
    }
  },
  {
    path: '404',
    loadChildren: './pages/not-found/not-found.module#NotFoundModule',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
