<div id="home-page" class="fill-view" role="region" aria-label="home page">
  <app-branding-separator bgc1="rgb(63,138,77)" bgc2="rgb(63,138,77)" bgc3="rgb(63,138,77)" bgc4="rgb(63,138,77)"></app-branding-separator>
  <app-slider></app-slider>
  <div class="mini-card-container">
    <!-- note that this image src is the relative route to  -->
    <app-mini-card
      backgroundColorClass="gr-grd-bg"
      buttonBackgroundClass="button--green"
      title="Profound Impact Digital Community"
      text="This is your community where you can document your individual journey and impact story"
      buttonText="Learn More"
      buttonRoute="/about"
      imageSrc="../../assets/images/icon-pi.png"
    ></app-mini-card>
  </div>
</div>