import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { isNgTemplate } from '@angular/compiler';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { VolunteerEdge } from 'src/app/shared/utilities/app-db-edges';
import { Organization } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class VolunteerComponent implements OnInit {

  volunteerList = [];
  @ViewChild('card') card: ElementRef;
  flipCard = false;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  companies: any;
  causes: any;
  noneDisabled: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService
  ) {}

  status: ServiceStatus;
  viewmodel = this.data.viewmodel;
  // @Input() viewmodel: UserProfile;
  newItem: { edge: VolunteerEdge; toNodeRef: NodeRef; toNode: Organization };
  currentItem: {
    edge: VolunteerEdge;
    toNodeRef: NodeRef;
    toNode: Organization;
  };
  volunteerModel: any = {
    name: '',
    shortName: '',
    summary: '',
    position: '',
    cause: '',
    webSite: '',
    currentlyWorking: true,
    startDate: {
      year: '',
      month: '',
      day: '',
    },
    endDate: {
      year: '',
      month: '',
      day: '',
    },
    startDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  volunteer_in_Organization: any = {};
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
    // 'Authorization': `Bearer ${this.jwtoken.split('|')[0]}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  startDayValue: any[] = [];
  endDayValue: any[] = [];
  startYearValue: any[] = [];
  endYearValue: any[] = [];
  endMonthValue: any[] = [];
  currentlyWorking = true;
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  unverified: any;
  isStartYearRequired = false;
  currentYear = new Date().getFullYear();
  websiteRegExp = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this.getCompanySuggestions();
    this.getCauseSuggestions();
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getVolunteerList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
  }

  flip(){
     
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getCompanySuggestions() {
    this.api.getSuggestions('experience').subscribe((data) => {
      const response: any = data;
      this.companies = response.data.name;
    });
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.companies
          : this.companies.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  getCauseSuggestions() {
    this.api.getSuggestions('volunteer').subscribe((data) => {
      const response: any = data;
      this.causes = response.data.cause;
    });
  }  

  searchCauses = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((cause) =>
        (cause === ''
          ? this.causes
          : this.causes.filter(
            (v) => v.toLowerCase().indexOf(cause.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )

  onDateChange(field: any, input: any, startYear) {
    if (startYear === '') {
      this.isStartYearRequired = true;
    } else {
      this.isStartYearRequired = false;
    }

    const thisYear = new Date().getFullYear();
    if (field === 'startDate') {
      if (input === 'year') {
        if (this.volunteerModel.startDate.year !== '') {
          this.volunteerModel.endDate.year = '';
          this.volunteerModel.endDate.month = '';
          this.volunteerModel.endDate.day = '';
          this.volunteerModel.startDate.month = '';
          this.volunteerModel.startDate.day = '';
          this.endYearValue = [];
          for (let i = this.volunteerModel.startDate.year; i <= thisYear; i++) {
            this.endYearValue.push({ value: i });
          }
        } else {
          console.log('START DATE YEAR', this.volunteerModel.startDate.year);
          this.yearValue = [];
          this.endYearValue = [];
          this.monthValue = [];
          this.endMonthValue = [];
          this.endDayValue = [];
          this.startDayValue = [];
          this.dayValue = [];
          for (let i = this.currentYear; i >= 1930; i--) {
            this.yearValue.push({ value: i });
            this.endYearValue.push({ value: i });
          }
          for (let i = 1; i <= 12; i++) {
            this.monthValue.push({ value: i });
            this.endMonthValue.push({ value: i });
          }
          for (let i = 1; i <= 31; i++) {
            this.dayValue.push({ value: i });
            this.startDayValue.push({ value: i });
            this.endDayValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.startDayValue = [];
        for (
          let i = 1;
          i <= this.data.days[this.volunteerModel.startDate.month - 1];
          i++
        ) {
          this.startDayValue.push({ value: i });
        }
        if (
          this.volunteerModel.startDate.year ===
          this.volunteerModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.volunteerModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
        this.volunteerModel.startDate.day = '';
      }
      if (input === 'day') {
        // tslint:disable-next-line: max-line-length
        if (
          this.volunteerModel.startDate.month ===
            this.volunteerModel.endDate.month &&
          this.volunteerModel.startDate.year ===
            this.volunteerModel.endDate.year
        ) {
          this.volunteerModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.volunteerModel.startDate.day;
            i < this.data.days[this.volunteerModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (field === 'endDate') {
      if (input === 'year') {
        this.volunteerModel.endDate.month = '';
        this.volunteerModel.endDate.day = '';
        if (
          this.volunteerModel.startDate.year ===
          this.volunteerModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (
            let i = this.volunteerModel.startDate.month
              ? this.volunteerModel.startDate.month
              : 1;
            i <= 12;
            i++
          ) {
            this.endMonthValue.push({ value: i });
          }
        } else {
          this.endMonthValue = [];
          for (let i = 1; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.endDayValue = [];
        // tslint:disable-next-line: max-line-length
        if (
          this.volunteerModel.startDate.month ===
            this.volunteerModel.endDate.month &&
          this.volunteerModel.startDate.year ===
            this.volunteerModel.endDate.year
        ) {
          this.volunteerModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.volunteerModel.startDate.day;
            i < this.data.days[this.volunteerModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        } else {
          this.endDayValue = [];
          for (
            let i = 1;
            i <= this.data.days[this.volunteerModel.endDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i });
          }
        }
      }
    }
    const flag = this.volunteerModel[field][input] !== '' ? 1 : 0;
    this.volunteerModel[field + 'Flag'][input] = flag;
  }

  toggleCurrentlyWorking() {
    this.currentlyWorking = !this.currentlyWorking;
    this.volunteerModel.currentlyWorking = this.currentlyWorking;
  }

  getVolunteerList() {
    this.volunteerList = [];
    const apiUrl = `${this.root}/userProfileUpdateVolunteer?operation=list`;
    const getVolunteerList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getVolunteerList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        console.log('VOLUNTEER: ', response);
        this.viewmodel.volunteer_in_Organization = [];
        const tempCurrentlyWorking = [];
        const tempEndDate = [];
        const tempExperience = [];
        for (let i = 0; i < response.record.length; i++) {
          if (response.record[i].row[0].currentlyWorking) {
            tempCurrentlyWorking.push(response.record[i]);
          } else {
            tempEndDate.push(response.record[i]);
          }
        }
        tempCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        tempEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of tempCurrentlyWorking) {
          tempExperience.push(item);
        }
        for (const item of tempEndDate) {
          tempExperience.push(item);
        }
        response.record = tempExperience;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              response.record[item].row[0].startDate.year,
              response.record[item].row[0].startDate.month - 1,
              response.record[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              response.record[item].row[0].endDate.year,
              response.record[item].row[0].endDate.month - 1,
              response.record[item].row[0].endDate.day
            ),
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.record[item].row[0].startDate.year,
              month: response.record[item].row[0].startDate.month,
              day: response.record[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.record[item].row[0].endDate.year,
              month: response.record[item].row[0].endDate.month,
              day: response.record[item].row[0].endDate.day,
            },
            currentlyWorking: response.record[item].row[0].currentlyWorking
              ? response.record[item].row[0].currentlyWorking
              : false,
            cause: response.record[item].row[0].cause
              ? response.record[item].row[0].cause
              : '',
            position:
              response.record[item].row[0].position !== 'undefined'
                ? response.record[item].row[0].position
                : '',
            uuid: response.record[item].row[0].uuid,
            name:
              response.record[item].row[0].name !== 'undefined'
                ? response.record[item].row[0].name
                : '',
            shortName: response.record[item].row[0].shortName,
            summary:
              response.record[item].row[0].summary !== 'undefined'
                ? response.record[item].row[0].summary
                : '',
            webSite: response.record[item].row[0].webSite,
            status : 'verified'
          };
          if (temp.currentlyWorking) {
            this.viewmodel.volunteer_in_Organization.unshift(temp);
            this.volunteerList.unshift(temp);
          } else {
            this.viewmodel.volunteer_in_Organization.push(temp);
            this.volunteerList.push(temp);
          }
        }
        this.viewmodel.unverified_Volunteer_in_Organization = [];
        const unverifiedCurrentlyWorking = [];
        const unverifiedEndDate = [];
        const unverifiedExperience = [];
        for (let i = 0; i < response.unverified.length; i++) {
          if (response.unverified[i].row[0].currentlyWorking) {
            unverifiedCurrentlyWorking.push(response.unverified[i]);
          } else {
            unverifiedEndDate.push(response.unverified[i]);
          }
        }
        unverifiedCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        unverifiedEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of unverifiedCurrentlyWorking) {
          unverifiedExperience.push(item);
        }
        for (const item of unverifiedEndDate) {
          unverifiedExperience.push(item);
        }
        response.unverified = unverifiedExperience;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              response.unverified[item].row[0].startDate.year,
              response.unverified[item].row[0].startDate.month - 1,
              response.unverified[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              response.unverified[item].row[0].endDate.year,
              response.unverified[item].row[0].endDate.month - 1,
              response.unverified[item].row[0].endDate.day
            ),
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.unverified[item].row[0].startDate.year,
              month: response.unverified[item].row[0].startDate.month,
              day: response.unverified[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.unverified[item].row[0].endDate.year,
              month: response.unverified[item].row[0].endDate.month,
              day: response.unverified[item].row[0].endDate.day,
            },
            currentlyWorking: response.unverified[item].row[0].currentlyWorking
              ? response.unverified[item].row[0].currentlyWorking
              : false,
            cause: response.unverified[item].row[0].cause
              ? response.unverified[item].row[0].cause
              : '',
            position:
              response.unverified[item].row[0].position !== 'undefined'
                ? response.unverified[item].row[0].position
                : '',
            uuid: response.unverified[item].row[0].uuid,
            name:
              response.unverified[item].row[0].name !== 'undefined'
                ? response.unverified[item].row[0].name
                : '',
            shortName: response.unverified[item].row[0].shortName,
            summary:
              response.unverified[item].row[0].summary !== 'undefined'
                ? response.unverified[item].row[0].summary
                : '',
            webSite: response.unverified[item].row[0].webSite,
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            status : 'unverified'
          };
          if (temp.currentlyWorking) {
            this.viewmodel.unverified_Volunteer_in_Organization.unshift(temp);
            this.volunteerList.unshift(temp);
          } else {
            this.viewmodel.unverified_Volunteer_in_Organization.push(temp);
            this.volunteerList.push(temp);
          }
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        cause: '',
        position: '',
        summary: '',
        startDate: '',
        endDate: '',
        currentlyWorking: true,
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: {
        labels: null,
        uuid: '',
        name: '',
      },
      toNode: {
        uuid: '',
        name: '',
        shortName: '',
        logoUrl: '',
        webSite: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log(
      'VIEW MODEL VOLUNTEER: ',
      this.viewmodel.volunteer_in_Organization
    );
    if (
      this.viewmodel.volunteer_in_Organization.length === 1 &&
      this.viewmodel.volunteer_in_Organization[0].name === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.editMode = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.currentlyWorking = true;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.volunteerModel = {
      name: '',
      shortName: '',
      summary: '',
      position: '',
      cause: '',
      webSite: '',
      currentlyWorking: true,
      startDate: {
        year: '',
        month: '',
        day: '',
      },
      endDate: {
        year: '',
        month: '',
        day: '',
      },
      startDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
      endDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    console.log('ITEM CLICKED: ', item);
    if (item.importStatus) {
      this.volunteerModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    if (item.dataSource === 'User') {
      item.canEdit ? (this.deleteMode = false) : (this.deleteMode = true);
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.volunteerModel = { ...item };
    this.volunteerModel.edgeUuid = item.uuid;
    this.volunteerModel.currentlyWorking = item.currentlyWorking;
    this.volunteerModel.cause = item.cause;
    this.currentlyWorking = item.currentlyWorking;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = '';
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = '';
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.volunteerModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.volunteerModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.volunteerModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (
        this.volunteerModel.startDate.year === this.volunteerModel.endDate.year
      ) {
        this.endMonthValue = [];
        for (let i = this.volunteerModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.volunteerModel.startDate.month ===
          this.volunteerModel.endDate.month &&
        this.volunteerModel.startDate.year === this.volunteerModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.volunteerModel.startDate.day;
          i < this.data.days[this.volunteerModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
  }

  saveNone() {
    this.editMode = false;
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.volunteerModel.name = 'NONE';
    this.volunteerModel.shortName = '';
    this.volunteerModel.webSite = '';
    this.volunteerModel.cause = '';
    this.volunteerModel.position = '';
    this.volunteerModel.summary = '';
    this.volunteerModel.currentlyWorking = '';
    this.volunteerModel.startDate.year = '';
    this.volunteerModel.startDate.month = '';
    this.volunteerModel.startDate.end = '';
    this.volunteerModel.endDate.year = '';
    this.volunteerModel.endDate.month = '';
    this.volunteerModel.endDate.day = '';
    this.saveClick();
  }

  saveClick() {
    if (this.volunteerModel.currentlyWorking) {
      this.volunteerModel.endDate = {
        day: '',
        month: '',
        year: '',
      };
    }

    if (this.volunteerModel.name === 'NONE') {
      this.volunteerModel.shortName = '';
      this.volunteerModel.webSite = '';
      this.volunteerModel.cause = '';
      this.volunteerModel.position = '';
      this.volunteerModel.summary = '';
      this.volunteerModel.currentlyWorking = '';
      this.volunteerModel.startDate.year = '';
      this.volunteerModel.startDate.month = '';
      this.volunteerModel.startDate.end = '';
      this.volunteerModel.endDate.year = '';
      this.volunteerModel.endDate.month = '';
      this.volunteerModel.endDate.day = '';
    }

    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateVolunteer?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.volunteerModel, edgeUuid: this.volunteerModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getVolunteerList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateVolunteer?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.volunteerModel },
        { headers: this.headers }
      );
      console.log('VOLUNTEER MODEL', this.volunteerModel);
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getVolunteerList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.volunteer_in_Organization[0]);
    // }
    if (this.volunteerModel.name != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    // this.getCompanySuggestions();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.volunteerModel.importStatus = item.importStatus;
    }
    this.editMode = false;
    this.currentItem = item;
    this.currentlyWorking = item.currentlyWorking;
    this.volunteerModel = { ...item };
    this.volunteerModel.edgeUuid = item.uuid;
    this.volunteerModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.volunteerModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.volunteerModel.currentlyWorking = item.currentlyWorking
      ? item.currentlyWorking
      : false;
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.volunteerModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (
        this.volunteerModel.startDate.year === this.volunteerModel.endDate.year
      ) {
        this.endMonthValue = [];
        for (let i = this.volunteerModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.volunteerModel.startDate.month ===
          this.volunteerModel.endDate.month &&
        this.volunteerModel.startDate.year === this.volunteerModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.volunteerModel.startDate.day;
          i < this.data.days[this.volunteerModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateVolunteer?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.volunteerModel, edgeUuid: this.volunteerModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getVolunteerList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  clearData() {
    this.volunteerModel.name = '';
    this.volunteerModel.shortName = '';
    this.volunteerModel.webSite = '';
    this.volunteerModel.cause = '';
    this.volunteerModel.position = '';
    this.volunteerModel.summary = '';
    this.volunteerModel.startDate.year = '';
    this.volunteerModel.startDate.month = '';
    this.volunteerModel.startDate.day = '';
    this.volunteerModel.endDate.year = '';
    this.volunteerModel.endDate.month = '';
    this.volunteerModel.endDate.day = '';
    this.deleteMode = false;
    this.editMode = false;
  }

  cancelClick() {
    this.deleteMode = false;
    this.editMode = false;
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
  }
}