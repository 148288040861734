import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss']
})
export class MiniCardComponent implements OnInit {

  @Input() type: 'default' | 'scroll' | 'custom' = 'default';
  @Input() imageSrc: string = '../../assets/images/find-new-connections.png';
  @Input() title: string = 'Card Title';
  @Input() text: string = 'card text';
  @Input() backgroundColorClass: string = 'cyan-gradient-background';
  @Input() buttonBackgroundClass: string = 'button--cyan';
  @Input() buttonText: string = 'Learn More';
  @Input() buttonRoute: string = '/home';
  @Input() imageContainerWidth: 'width25' | 'width17' | 'custom' = 'width25';

  constructor(private router: Router) { }

  ngOnInit() {}

  route() {
    if (this.buttonRoute != 'disabled'){
      this.router.navigate([this.buttonRoute]);
    }
  }

}
