import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UtilityService } from 'src/app/services/utility.service';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private utils: UtilityService, private spinner: NgxSpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
        if(localStorage.getItem('jwt')) {     
            let token = localStorage.getItem('jwt')
            const decoded = jwt_decode(token);

            // getting current epoch time
            let now = new Date();
            let currentTime: number  = Math.round(now.getTime() / 1000)

            // jwt expiration epoch time
            const exp: number = decoded['exp']

            //console.log(`${currentTime} - ${exp}`);
            if(currentTime > exp) {
                this.utils.logout()
            }
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.error instanceof ErrorEvent) {
                // client-side error or network error
              } else {
                // TODO: Clean up following by introducing method
                if (error.status === 498) { 
                  // TODO: Destroy local session; redirect to /login
                }
                if (error.status === 400) { 
                    this.spinner.hide()
                  // TODO: Permission denied; show toast
                }
              }
              return throwError(error);
            })
        );
        
    }
}
