<mat-tab-group role="region" id="settings" mat-align-tabs="center">
  <mat-tab label="Settings">
    <div *ngIf="data.currentUser && !data.isLoading && data.profileCompletion" class="settings-tab-container"
      fxLayout="row" fxLayoutAlign="center" role="contentinfo" aria-label="settings page">
      <div class="row settings__content" fxLayoutAlign="center" ngStyle.gt-sm="width: 90%" ngStyle.sm="width: 90%"
        ngStyle.lt-sm="width: 100%">
        <div [ngClass]="{
            'col-9': !data.isMobile && !data.LT_LG,
            'col-12': data.isMobile || data.LT_LG
          }">
          <div [ngClass]="{
              'settings__tab-content': !data.isMobile && !data.LT_LG,
              'mobile-tab-content': data.isMobile || data.LT_LG
            }">
            <div class="tab-pane active">
              <!--
                ██████╗ ███████╗███████╗██╗  ██╗████████╗ ██████╗ ██████╗ 
                ██╔══██╗██╔════╝██╔════╝██║ ██╔╝╚══██╔══╝██╔═══██╗██╔══██╗
                ██║  ██║█████╗  ███████╗█████╔╝    ██║   ██║   ██║██████╔╝
                ██║  ██║██╔══╝  ╚════██║██╔═██╗    ██║   ██║   ██║██╔═══╝ 
                ██████╔╝███████╗███████║██║  ██╗   ██║   ╚██████╔╝██║     
                ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     
              -->
              <ngx-flip *ngIf="!data.isMobile && !data.LT_MD" [flip]="flipDiv" class="settings__flip" role="region"
                aria-label="settings page">
                <div front>
                  <div class="row">
                    <div class="col-3 p-0" aria-label="settings tabs">
                      <ul class="nav nav-tabs tabs-left flex-column settings__user-details" role="list">
                        <li [ngClass]="{ active: tab.active }" *ngFor="let tab of tabs">
                          <a (click)="tabChange(tab)"
                            [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#b9b9b9' }">{{
                            tab.name
                            }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-9">
                      <div class="settings__tab-content">
                        <!-----Connect Accounts----->
                        <div class="tab-pane active h100" [ngClass]="{
                                                          showTab: selectedSettingTab == 1,
                                                          hideTab: selectedSettingTab != 1
                                                        }" role="region">
                          <div class="settings__card" aria-label="add secondary email" style="min-height: 400px;
                          max-height: 400px;
                          overflow-y: auto;">
                            <div class="settings__form-privacy-container" style="padding-bottom: 7%;">
                              <!-------old-->
                              <div class="settings__form-alternate-email-container mb-4">
                                <span class="fw600"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                  Add your secondary and social account here
                                </span>
                              </div>
                              <div *ngIf="currentEmailStatus === settingStatus.Default"
                                class="settings__form-alternate-email">
                                <form [formGroup]="socialConnectForm">
                                  <div class="row">
                                    <div class="col-4">
                                      <mat-form-field [floatLabel]="'never'" class="pb-2">
                                        <mat-label>Account Type</mat-label>
                                        <mat-select formControlName="connectionType" *ngIf="socialMedia">
                                          <mat-option *ngFor="let account of socialMedia" [value]="account.name">
                                            <fa-icon *ngIf="account.name !== 'Email'" [icon]="account.logo" size="2x"
                                              class="social-media-logo" [style.color]="account.color" style="position: absolute;
                                                                            margin-top: 5px;"></fa-icon>
                                            <fa-icon *ngIf="account.name === 'Email'" [icon]="account.logo"
                                              [style.color]="account.color" style="font-size: 27px;position: absolute;">
                                            </fa-icon>
                                            <span class="account-type"
                                              style="margin-left: 35px; font-size: 16px; font-weight: 400;">
                                              {{account.name}}
                                            </span>
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                      <input type="email" class="form__control" aria-label="alternate email - required"
                                        placeholder="youremail@gmail.com *" formControlName="email" />
                                      <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)">
                                        <p *ngIf="f.email.errors.required" class="card--h4 form__required pl-1">Please
                                          enter an email</p>
                                        <p *ngIf="f.email.errors.email" class="card--h4 form__required pl-1">Please
                                          enter a valid email
                                          address
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-2 px-0">
                                      <button [disabled]="!socialConnectForm.valid" type="submit"
                                        class="button button--dark-grey pull-right" (click)="addAccount()"
                                        aria-label="submit button" role="button">
                                        ADD
                                      </button>
                                    </div>
                                    <!-- <div class="row"> -->
                                      <!-- <div class="col-12">
                                      <p class="card--h4 form__required" style="margin-top: -25px;
                                        font-size: 11px;
                                        font-weight: 500;"
                                        [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                        {{infoMessageOnConnectionChange}}</p>
                                      </div>
                                    </div> -->
                                  </div>
                                </form>
                              </div>
                            </div>
                            <!----end of input----->

                            <!-----------dropdown change event message ------------->
                            <div class="settings__form-alternate-email-container" style="padding-bottom: 3%; position: absolute;
                            top: 122px;">
                              <span class="fw500" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }" style="font-size: 14px;">
                                {{infoMessageOnConnectionChange}}</span>
                            </div>

                            <!------------- unverified account message ---------->
                            <!-- <div class="settings__form-alternate-email-container" style="padding-bottom: 3%; position: fixed;
                            top: 204px;" *ngIf="showInfoMsg">
                              <i class="fas fa-info-circle"></i>
                              <span class="fw500" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                Please Login with your social account to verify.</span>
                            </div> -->
                            <!----table with data ------>
                            <div class="pb-4" style="position: fixed;
                            top: 180px;
                            width: 70%;">
                              <div class="mat-elevation-z8 home-card--table" style="max-height: 200px;
                              overflow-y: auto;" *ngIf="isTableDataLoaded">
                                <table mat-table [dataSource]="dataSource" style="width: 100%;">
                                  <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef aria-label="department table header"
                                      class="accounts-table-header" style="padding-left: 13px; width: 57%;"> Email </th>
                                    <td mat-cell *matCellDef="let element" aria-label="department table data"
                                      style="padding-left: 13px;"> {{element.email}} 
                                      <span *ngIf="!element.isVerified"
                                      class="unverified-badge-blue profile__section__list--sidebar--unverified" 
                                      matTooltip="Please Login with your social account to verify">unverified
                                    </span></td>
                                  </ng-container>

                                  <ng-container matColumnDef="connectionType">
                                    <th mat-header-cell *matHeaderCellDef aria-label="first name table header"
                                      style="width: 28%;">
                                      Connection Type </th>

                                    <td mat-cell *matCellDef="let element" aria-label="first name table data"
                                      style="padding-left: 29px;">
                                      <ng-container *ngIf="element.icon">
                                        <fa-icon [icon]="element.icon" size="2x" [style.color]="element.color">
                                        </fa-icon>
                                      </ng-container>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef aria-label="status table header"
                                      style="width: 15%;"> Verified
                                    </th>
                                    <td mat-cell *matCellDef="let element" aria-label="status table data"
                                      style="padding-left: 8px;">
                                      <span *ngIf="element.isVerified">Yes</span>
                                      <span *ngIf="!element.isVerified">No</span>
                                      <button *ngIf="!element.isVerified" mat-icon-button
                                        (click)="deleteEmail(element.email, element.connectionType)">
                                        <mat-icon *ngIf="!element.isVerified" matTooltip="Delete Account"
                                          class="delete-icon">delete</mat-icon>
                                      </button>
                                    </td>
                                  </ng-container>
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                              </div>
                            </div>
                            <!----end of the table ----->
                          </div>
                        </div>
                        <!-----Connect Accounts END----->

                        <!--CHANGE EMAIL-->
                        <div class="tab-pane h-100 active" [ngClass]="{
                                                          showTab: selectedSettingTab == 2,
                                                          hideTab: selectedSettingTab != 2
                                                          }" role="region">
                          <div class="settings__card" aria-label="change email">
                            <div></div>
                            <label class="primary-label"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">System
                              Username:
                              <span class="secondary-label">{{
                                data.currentUser.cognitoUserName
                                }}
                              </span>
                            </label>
                            <label class="primary-label"
                            [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">System
                            Email Address:
                            <span class="secondary-label">{{
                              systemEmailAddress
                              }}
                            </span>
                          </label>
                            <label class="settings__form-label mt-0"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Account Email
                              Address:
                              <span class="fw500">{{
                                data.currentUser.email
                                }}</span>
                            </label>
                            <span *ngIf="systemEmailAddress !== data.currentUser.email"
                            class="unverified-badge-blue profile__section__list--sidebar--unverified">
                            You are not allowed to change email
                            </span>
                            <ng-container *ngIf="systemEmailAddress === data.currentUser.email">
                              <label class="settings__form-label form__required" style="font-size: 14px;"
                              *ngIf="data.connectionType !== 'email'">
                              Change email is not allowed because your primary email account is connected with your social media account login
                            </label>
                            <div *ngIf="currentEmailStatus === settingStatus.Default">
                              <form #changeEmailForm="ngForm">
                                <div class="settings__form-change-email">
                                  <div class="row">
                                    <div class="col-8">
                                      <input type="email" required class="form__control"
                                        [disabled]="data.connectionType !== 'email'" name="changeEmail"
                                        aria-label="email - required" placeholder="youremail@gmail.com *"
                                        [(ngModel)]="changeNewEmail" (ngModelChange)="checkEmail()"
                                        #changeEmail="ngModel" />
                                    </div>
                                    <div class="col-4 pr-0">
                                      <button
                                        [disabled]="
                                                                      !changeNewEmail ||
                                                                      changeEmailForm.form.pristine ||
                                                                      changeEmailForm.form.invalid ||
                                                                      !newEmailValid || data.connectionType !== 'email'" 
                                        type="button" class="button button--dark-grey pull-right"
                                        (click)="updateEmail()" aria-label="submit button" role="button">
                                        SUBMIT
                                      </button>
                                    </div>
                                    <div class="col-12"
                                      *ngIf="changeEmail.errors && (changeEmail.dirty || changeEmail.touched) ">
                                      <p class="card--h4 form__required" *ngIf="changeEmail.errors.required">Email is
                                        required</p>
                                    </div>
                                    <div class="col-12" *ngIf="(changeEmail.dirty || changeEmail.touched) ">
                                      <p class="card--h4 form__required" *ngIf="!newEmailValid">Not a valid email</p>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            </ng-container>
                            <div *ngIf="currentEmailStatus === settingStatus.VerifyEmail">
                              <label class="settings__form-label"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Enter Verification
                                Code</label>
                              <div class="settings__form-change-email">
                                <div class="row">
                                  <div class="col-8">
                                    <input type="text" class="form__control" aria-label="code - required"
                                      placeholder="Verification Code *" [(ngModel)]="code" />
                                  </div>
                                  <div class="col-4 pr-0">
                                    <button [disabled]="!code" type="submit" class="button button--dark-grey pull-right"
                                      (click)="verifyEmail()" aria-label="submit button" role="button">
                                      SUBMIT
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--CHANGE EMAIL END -->

                        <!-- CHANGE PASSWORD -->
                        <div class="tab-pane h-100 active"
                          [ngClass]="{showTab: selectedSettingTab == 3, hideTab: selectedSettingTab !=3 }"
                          role="region">
                          <div class="settings__card" aria-label="change password">
                            <form>
                              <div class="pwd-body">
                                <label class="settings__form-label form__required mt-0 mb-3" style="font-size: 14px;"
                                  *ngIf="data.connectionType !== 'email'">
                                  Change Password is not allowed because your primary email account is connected with your social media account login
                                </label>
                                <div class="row">
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group change-password">
                                      <input type="password" class="form__control"
                                        [(ngModel)]="changePasswordForm.oldPassword"
                                        [disabled]="data.connectionType !== 'email'"
                                        placeholder="{{ siteConstant.changePasswordOldpasswordlabel}}" required
                                        name="oldPassword" class="form__control" aria-label="old password - required"
                                        #oldPassword="ngModel"
                                        [ngClass]="{'ng-invalid': oldPassword.dirty && oldPassword.errors }" />
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-6"
                                    *ngIf="oldPassword.errors && (oldPassword.dirty || oldPassword.touched) ">
                                    <p class="card--h4 form__required" *ngIf="oldPassword.errors.required">Current
                                      Password is
                                      required</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 login-box">
                                    <div class="row">
                                      <div class="form-group change-password col-md-6 col-sm-12">
                                        <input type="password" class="form__control" placeholder="{{
                                                            siteConstant.changePasswordNewpasswordlabel
                                                          }}" (keyup)="checkPassword($event, 'newPassword')"
                                          [disabled]="data.connectionType !== 'email'"
                                          [(ngModel)]="changePasswordForm.newPassword" name="newPassword"
                                          class="form__control" aria-label="new password - required" />
                                        <ul class="password-suggestion pl-0">
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'newPassword'
                                                              ].pcLengthValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.newPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>8+</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'newPassword'
                                                              ].pcLowerValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.newPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>a-z</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'newPassword'
                                                              ].pcUpperValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.newPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>A-Z</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'newPassword'
                                                              ].pcNumberValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.newPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>0-9</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'newPassword'
                                                              ].pcSpecialValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.newPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>!#?</div>
                                          </li>
                                        </ul>
                                        <span [ngClass]="validCheckPassword('newPassword')">
                                          <i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                      </div>
                                      <div class="form-group change-password col-md-6 col-sm-12">
                                        <input type="password" class="form__control"
                                          placeholder="{{siteConstant.changePasswordRetypepasswordlabel}}"
                                          (keyup)="checkPassword($event, 'confirmPassword')"
                                          [disabled]="data.connectionType !== 'email'"
                                          [(ngModel)]="changePasswordForm.confirmPassword" name="confirmPassword"
                                          class="form__control" aria-label="confirm password - required" />
                                        <ul class="password-suggestion pl-0">
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'confirmPassword'
                                                              ].pcLengthValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.confirmPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>8+</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'confirmPassword'
                                                              ].pcLowerValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.confirmPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>a-z</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'confirmPassword'
                                                              ].pcUpperValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.confirmPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>A-Z</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'confirmPassword'
                                                              ].pcNumberValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.confirmPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>0-9</div>
                                          </li>
                                          <li [ngClass]="
                                                              changePasswordForm.passwordStrength[
                                                                'confirmPassword'
                                                              ].pcSpecialValue == 1
                                                                ? 'green'
                                                                : changePasswordForm.confirmPassword == ''
                                                                ? ''
                                                                : 'red'
                                                            ">
                                            <div>!#?</div>
                                          </li>
                                        </ul>
                                        <span [ngClass]="validCheckPassword('confirmPassword')">
                                          <i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="pwd-footer">
                                <div class="row">
                                  <div class="col-12 pr-0">
                                    <button (click)="onSubmit()" [disabled]="
                                                        !changePasswordForm.oldPassword ||
                                                        !changePasswordForm.confirmPassword ||
                                                        !changePasswordForm.newPassword || data.connectionType !== 'email'
                                                      "
                                      class="button button--dark-grey pull-right settings__form-button" type="submit"
                                      aria-label="submit button" role="button">
                                      SUBMIT
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <!-- CHANGE PASSWORD END-->

                        <!-- CHANGE PRIVACY MODE -->
                        <div class="tab-pane h-100 active" [ngClass]="{
                                                          showTab: selectedSettingTab == 4,
                                                          hideTab: selectedSettingTab != 4
                                                        }" role="region">
                          <div class="settings__card float-left" aria-label="change privacy mode">
                            <div class="settings__form-privacy-container">
                              <div *ngIf="data.currentUser">
                                <div fxLayout="row">
                                  <div class="fw600 settings__form-privacy-label"
                                    [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                    Your current status:
                                    <span class="fw500" *ngIf="data.userProfileStatusFlag">PUBLIC</span>
                                    <span class="fw500" *ngIf="!data.userProfileStatusFlag">PRIVATE</span>
                                  </div>
                                  <div class="settings__form-privacy-switch">
                                    <label class="switch" *ngIf="!hide">
                                      <input type="checkbox" (change)="statusChange()"
                                        [(ngModel)]="data.userProfileStatusFlag" aria-label="privacy status toggle" />
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                                <div class="settings__form-privacy-info">
                                  <span class="settings__form-privacy-label fw500"
                                    [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">You can make
                                    your information
                                    visible to other users in
                                    the platform by selecting this option</span>
                                </div>
                                <div class="card" style="margin:15px 0">
                                  <!-- Default panel contents -->

                                  <div class="card-header h-50">
                                    <p class="col-8 mt-3 float-left fw500 grey-color">You can control what information
                                      you want to share
                                    </p>
                                  </div>
                                  <ul class="list-group list-group-flush">
                                    <li class="list-group-item col-12" *ngFor="let item of publicFieldsPrivacy">
                                      <div class="col-5 float-left fw500 grey-color" [attr.aria-label]="item.name">
                                        {{item.name}}</div>
                                      <!-- <ng-container *ngIf="!data.userProfileStatusFlag">
                                                                      <label class="switch  col-3 mt-1 float-left settings__form-privacy-label">
                                                                        <input type="checkbox" [attr.aria-label]=" item.name + ' toggle'" [disabled]="!data.userProfileStatusFlag" class="default">
                                                                        <span class="slider round"></span>
                                                                      </label>
                                                                      <p  class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500">PRIVATE gg</p>
                                                                    </ng-container>  -->
                                      <ng-container>
                                        <label class="switch  col-6 mt-1 float-left">
                                          <input type="checkbox" (change)="statusChange(item, publicFieldsPrivacy)"
                                            [attr.aria-label]=" item.name + ' toggle'" [(ngModel)]="item.value"
                                            class="default">
                                          <span class="slider round"></span>
                                        </label>
                                        <p class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500"
                                          aria-label="public status" *ngIf="item.value">PUBLIC</p>
                                        <p class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500"
                                          aria-label="private status" *ngIf="!item.value">PRIVATE</p>
                                      </ng-container>
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-12 p-0">
                                  <p class="col-10 float-left settings__form-privacy-label fw500 mt-1 grey-color text-danger"
                                    *ngIf="ifChange">Please click submit to reflect the changes in your privacy
                                    settings.</p>
                                  <button aria-label="submit button" [disabled]="!ifChange"
                                    class="button button--dark-grey float-right" (click)="updatePrivacyInfo()"
                                    role="button">Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- CHANGE PRIVACY MODE END-->

                        <!-- ADD SECONDARY EMAIL -->
                        <!-- <div class="tab-pane active h100" [ngClass]="{
                            showTab: selectedSettingTab == 2,
                            hideTab: selectedSettingTab != 2
                          }" role="region">
                            <div class="settings__card" aria-label="add secondary email">
                              <label *ngIf="data.currentUser.secondaryEmail" class="settings__form-alternate-email-label"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Current Alternate
                                Email Address:
                                <span class="fw500">{{
                                  data.currentUser.secondaryEmail
                                  }}</span>
                              </label>
                              <div class="settings__form-alternate-email-container">
                                <span class="fw500"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">You can provide
                                  alternate email
                                  address that will help others
                                  to find you</span>
                              </div>
                              <div *ngIf="currentEmailStatus === settingStatus.Default"
                                class="settings__form-alternate-email">
                                <form #alternateEmailForm="ngForm">
                                  <div class="row">
                                    <div class="col-8">
                                      <input type="email" name="alternateEmail" class="form__control"
                                        aria-label="alternate email - required" placeholder="youremail@gmail.com *"
                                        [(ngModel)]="alternateEmail" (ngModelChange)="checkEmail()" required
                                        #alternateEmailValue="ngModel" />
                                    </div>
                                    <div class="col-4 pr-0">
                                      <button [disabled]="
                          !alternateEmail ||
                          alternateEmailForm.form.pristine ||
                          alternateEmailForm.form.invalid ||
                          !alternateEmailValid
                        " type="submit" class="button button--dark-grey pull-right" (click)="addSecondaryEmail()"
                                        aria-label="submit button" role="button">
                                        SUBMIT
                                      </button>
                                    </div>
                                    <div class="col-12"
                                      *ngIf="alternateEmailValue.errors && (alternateEmailValue.dirty || alternateEmailValue.touched) ">
                                      <p class="card--h4 form__required" *ngIf="alternateEmailValue.errors.required">
                                        Alternate Email is
                                        required</p>
                                    </div>
                                    <div class="col-12"
                                      *ngIf="(alternateEmailValue.dirty || alternateEmailValue.touched) ">
                                      <p class="card--h4 form__required" *ngIf="!alternateEmailValid">Not a valid
                                        alternate email</p>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-flip>
              <!-- 
                ███╗   ███╗ ██████╗ ██████╗ ██╗██╗     ███████╗
                ████╗ ████║██╔═══██╗██╔══██╗██║██║     ██╔════╝
                ██╔████╔██║██║   ██║██████╔╝██║██║     █████╗  
                ██║╚██╔╝██║██║   ██║██╔══██╗██║██║     ██╔══╝  
                ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║███████╗███████╗
                ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚══════╝╚══════╝
              -->
              <ngx-flip *ngIf="data.isMobile || data.LT_MD" [flip]="flipDiv" class="h100 settings__flip">
                <div front [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#646464' }" class="h100">
                  <div class="card settings-profile-card">
                    <div class="settings-card-body">
                      <div class="settings h100" role="region" aria-label="settings">
                        <div class="settings__mobile-info">
                          <div>
                            <h5 class="header-label"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">User
                              Information</h5>
                          </div>
                          <div class="email-add" *ngIf="data.currentUser">
                            <label class="primary-label"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">System
                              Username:
                              <span class="secondary-label">{{
                                data.currentUser.cognitoUserName
                                }}</span></label>
                          </div>
                        </div>
                        <hr class="settings__mobile-hr" />
                        <!------------------------ Connect Accounts --------------------->
                        <div>
                          <h5 class="header-label" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                            Add Your Secondary And Social Account Here
                          </h5>
                          <form [formGroup]="socialConnectForm" style="padding-bottom: 5%;">
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field [floatLabel]="'never'" class="pb-2">
                                  <mat-label>Account Type</mat-label>
                                  <mat-select formControlName="connectionType">
                                    <mat-option *ngFor="let account of socialMedia" [value]="account.name">
                                      <fa-icon *ngIf="account.name !== 'Email'" [icon]="account.logo" size="2x"
                                        class="social-media-logo" [style.color]="account.color" style="position: absolute;
                                                                      margin-top: 5px;"></fa-icon>
                                      <fa-icon *ngIf="account.name === 'Email'" [icon]="account.logo"
                                        [style.color]="account.color" style="font-size: 27px;position: absolute;">
                                      </fa-icon>
                                      <span class="account-type"
                                        style="margin-left: 35px; font-size: 16px; font-weight: 400;">
                                        {{account.name}}
                                      </span>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <p class="card--h4 form__required" style="margin-top: -25px;
                                  font-size: 11px;
                                  font-weight: 500;"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                  {{infoMessageOnConnectionChange}}</p>
                              </div>
                              <div class="col-8">
                                <input type="email" class="form__control" aria-label="alternate email - required"
                                  placeholder="youremail@gmail.com *" formControlName="email" />
                                <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)">
                                  <p *ngIf="f.email.errors.required" class="card--h4 form__required pl-1">Please enter
                                    an email</p>
                                  <p *ngIf="f.email.errors.email" class="card--h4 form__required pl-1">Please enter a
                                    valid email
                                    address
                                  </p>
                                </div>
                              </div>
                              <div class="col-4 px-0">
                                <button [disabled]="!socialConnectForm.valid" type="submit"
                                  class="button button--dark-grey pull-right" (click)="addAccount()"
                                  aria-label="submit button" role="button">
                                  ADD
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <!----end of input----->

                        <div class="settings__form-alternate-email-container" style="padding-bottom: 5%"
                          *ngIf="showInfoMsg">
                          <span class="fw500" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                            Please login with your social account to verify</span>
                        </div>

                        <!----table with data ------>
                        <div class="pb-4">
                          <div class="mat-elevation-z8 home-card--table" style="max-height: 200px;
                        overflow-y: auto;" *ngIf="isTableDataLoaded">
                            <table mat-table [dataSource]="dataSource" style="width: 100%;">
                              <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef aria-label="department table header"
                                  class="accounts-table-header" style="padding-left: 13px; width: 40%;"> Email </th>
                                <td mat-cell *matCellDef="let element" aria-label="department table data"
                                  style="padding-left: 13px;"> {{element.email}} </td>
                              </ng-container>

                              <ng-container matColumnDef="connectionType">
                                <th mat-header-cell *matHeaderCellDef aria-label="first name table header"
                                  style="width: 30%;">
                                  Connection Type </th>

                                <td mat-cell *matCellDef="let element" aria-label="first name table data"
                                  style="padding-left: 29px;">
                                  <ng-container *ngIf="element.icon">
                                    <fa-icon [icon]="element.icon" size="2x" [style.color]="element.color"></fa-icon>
                                  </ng-container>
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef aria-label="status table header"
                                  style="width: 12%;"> Verified
                                </th>
                                <td mat-cell *matCellDef="let element" aria-label="status table data"
                                  style="padding-left: 8px;">
                                  <span *ngIf="element.isVerified">Yes</span>
                                  <span *ngIf="!element.isVerified">No</span>
                                  <button *ngIf="!element.isVerified"
                                    (click)="deleteEmail(element.email, element.connectionType)">
                                    <mat-icon *ngIf="!element.isVerified" matTooltip="Delete Account"
                                      class="delete-icon" style="position: absolute; margin-top: -14px;">delete
                                    </mat-icon>
                                  </button>
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                          </div>
                        </div>
                        <!----end of the table ----->

                        <!---------------------------Change Secondary Email-->
                        <!-- <div>
                          <h5 class="header-label" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                            Add Secondary
                            Email</h5>
                        </div>
                        <label *ngIf="data.currentUser.secondaryEmail" class="settings__form-label"
                          [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Current:
                          <span class="fw500">{{
                            data.currentUser.secondaryEmail
                            }}</span>
                        </label>
                        <div class="settings__form-alternate-email-container">
                          <span class="fw500" [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">You
                            can provide
                            alternate email
                            address or a secondary email
                            address that will help others to find you</span>
                        </div>
                        <form #alternateEmailForm="ngForm">
                          <div *ngIf="currentEmailStatus === settingStatus.Default"
                            class="settings__mobile-change-email">
                            <div class="row">
                              <div class="col-8">
                                <input type="email" class="form__control" name="alternateEmail"
                                  aria-label="alternate email - required" placeholder="youremail@gmail.com *"
                                  [(ngModel)]="alternateEmail" required (ngModelChange)="checkEmail()"
                                  #alternateEmailValue="ngModel" />
                              </div>
                              <div class="col-4 pr-0 settings__form-alternate-email-container-button-container">
                                <button [disabled]="
                    !alternateEmail ||
                    alternateEmailForm.form.pristine ||
                    alternateEmailForm.form.invalid ||
                    !alternateEmailValid
                  " type="submit" class="button button--dark-grey pull-right" (click)="addAccount()"
                                  aria-label="submit button" role="button">
                                  SUBMIT
                                </button>
                              </div>
                              <div class="col-12"
                                *ngIf="alternateEmailValue.errors && (alternateEmailValue.dirty || alternateEmailValue.touched) ">
                                <p class="card--h4 form__required" *ngIf="alternateEmailValue.errors.required">
                                  Alternate Email is
                                  required</p>
                              </div>
                              <div class="col-12" *ngIf="(alternateEmailValue.dirty || alternateEmailValue.touched) ">
                                <p class="card--h4 form__required" *ngIf="!alternateEmailValid">Not a valid alternate
                                  email</p>
                              </div>
                            </div>
                          </div>
                        </form> -->
                        <hr class="settings__mobile-hr" />
                        <!------------------------ Change Email --------------------->
                        <div class="container" class="settings__mobile-info-container">
                          <div>
                            <h5 class="header-label"
                              [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Change Email
                            </h5>
                          </div>
                          <label class="primary-label"
                          [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">System:
                          <span class="secondary-label">{{
                            systemEmailAddress
                            }}</span></label>
                          <label class="primary-label"
                            [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Account:
                            <span class="secondary-label">{{
                              data.currentUser.email
                              }}</span></label>
                              <span *ngIf="systemEmailAddress !== data.currentUser.email"
                              class="unverified-badge-blue profile__section__list--sidebar--unverified">
                              You are not allowed to change email
                              </span>
                          <ng-coontainer *ngIf="systemEmailAddress === data.currentUser.email">
                            <h5 class="warning-label" *ngIf="data.connectionType !== 'email'">
                              Change Email is not allowed because your primary email account is connected with your social media account login.
                            </h5>
                            <div *ngIf="currentEmailStatus === settingStatus.Default">
                              <form #changeEmailForm="ngForm">
                                <div class="settings__mobile-change-email">
                                  <div class="row">
                                    <div class="col-8">
                                      <input type="email" class="form__control" name="changeEmail"
                                        [disabled]="data.connectionType !== 'email'" aria-label="email - required"
                                        placeholder="youremail@gmail.com *" [(ngModel)]="changeNewEmail"
                                        (ngModelChange)="checkEmail()" required #changeEmail="ngModel" />
                                    </div>
                                    <div class="col-4 pr-0">
                                      <button [disabled]="
                          !changeNewEmail ||
                          changeEmailForm.form.pristine ||
                          changeEmailForm.form.invalid ||
                          !newEmailValid || data.connectionType !== 'email'" type="button" class="button button--dark-grey pull-right" (click)="updateEmail()"
                                        aria-label="submit button" role="button">
                                        SUBMIT
                                      </button>
                                    </div>
                                    <div class="col-12"
                                      *ngIf="changeEmail.errors && (changeEmail.dirty || changeEmail.touched) ">
                                      <p class="card--h4 form__required" *ngIf="changeEmail.errors.required">Email is
                                        required</p>
                                    </div>
                                    <div class="col-12" *ngIf="(changeEmail.dirty || changeEmail.touched) ">
                                      <p class="card--h4 form__required" *ngIf="!newEmailValid">Not a valid email</p>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </ng-coontainer>
                          <label *ngIf="currentEmailStatus === settingStatus.VerifyEmail" class="settings__mobile-label"
                            [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Enter {{ email }}
                            Verification
                            Code</label>
                          <div *ngIf="currentEmailStatus === settingStatus.VerifyEmail"
                            class="settings__mobile-change-email">
                            <div class="row">
                              <div class="col-8">
                                <input type="text" class="form__control" aria-label="code - required"
                                  placeholder="Verification Code *" [(ngModel)]="code" />
                              </div>
                              <div class="col-4 pr-0">
                                <button [disabled]="!code" type="submit" class="button button--dark-grey pull-right"
                                  (click)="verifyEmail()" aria-label="submit button" role="button">
                                  SUBMIT
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="settings__mobile-hr" />
                        <!------------------------ Change Password --------------------->
                        <div class="container" class="settings__mobile-password-container">
                          <form>
                            <div class="pwd-heading">
                              <div>
                                <h5 class="header-label"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Change
                                  Password</h5>
                                  <h5 class="warning-label" *ngIf="data.connectionType !== 'email'">
                                    Change Password is not allowed because your primary email account is connected with your social media account login.
                                  </h5>
                              </div>
                            </div>
                            <div class="pwd-body">
                              <div class="row">
                                <div class="col-lg-6 col-md-12">
                                  <div class="form-group change-password">
                                    <input type="password" class="form__control"
                                      [(ngModel)]="changePasswordForm.oldPassword"
                                      [disabled]="data.connectionType !== 'email'" placeholder="{{
                        siteConstant.changePasswordOldpasswordlabel
                      }} *" name="oldPassword" class="form__control" aria-label="old password - required"
                                      #oldPassword="ngModel" required [ngClass]="{
        'ng-invalid': oldPassword.dirty && oldPassword.errors
      }" />
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6"
                                  *ngIf="oldPassword.errors && (oldPassword.dirty || oldPassword.touched) ">
                                  <p class="card--h4 form__required" *ngIf="oldPassword.errors.required">Current
                                    Password is
                                    required</p>
                                </div>

                              </div>
                              <div class="row">
                                <div class="col-md-12 login-box">
                                  <div class="row">
                                    <div class="form-group change-password col-md-6 col-sm-12">
                                      <input type="password" class="form__control"
                                        [disabled]="data.connectionType !== 'email'" placeholder="{{
                          siteConstant.changePasswordNewpasswordlabel
                        }} *" (keyup)="checkPassword($event, 'newPassword')"
                                        [(ngModel)]="changePasswordForm.newPassword" name="newPassword"
                                        class="form__control" aria-label="new password - required" />
                                      <ul class="password-suggestion pl-0">
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength['newPassword']
                              .pcLengthValue == 1
                              ? 'green'
                              : changePasswordForm.newPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>8+</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength['newPassword']
                              .pcLowerValue == 1
                              ? 'green'
                              : changePasswordForm.newPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>a-z</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength['newPassword']
                              .pcUpperValue == 1
                              ? 'green'
                              : changePasswordForm.newPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>A-Z</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength['newPassword']
                              .pcNumberValue == 1
                              ? 'green'
                              : changePasswordForm.newPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>0-9</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength['newPassword']
                              .pcSpecialValue == 1
                              ? 'green'
                              : changePasswordForm.newPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>!#?</div>
                                        </li>
                                      </ul>
                                      <span [ngClass]="validCheckPassword('newPassword')">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <div class="form-group change-password col-md-6 col-sm-12">
                                      <input type="password" class="form__control"
                                        [disabled]="data.connectionType !== 'email'" placeholder="{{
                          siteConstant.changePasswordRetypepasswordlabel
                        }} *" (keyup)="checkPassword($event, 'confirmPassword')"
                                        [(ngModel)]="changePasswordForm.confirmPassword" name="confirmPassword"
                                        class="form__control" aria-label="confirm password - required" />
                                      <ul class="password-suggestion pl-0">
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength[
                              'confirmPassword'
                            ].pcLengthValue == 1
                              ? 'green'
                              : changePasswordForm.confirmPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>8+</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength[
                              'confirmPassword'
                            ].pcLowerValue == 1
                              ? 'green'
                              : changePasswordForm.confirmPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>a-z</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength[
                              'confirmPassword'
                            ].pcUpperValue == 1
                              ? 'green'
                              : changePasswordForm.confirmPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>A-Z</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength[
                              'confirmPassword'
                            ].pcNumberValue == 1
                              ? 'green'
                              : changePasswordForm.confirmPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>0-9</div>
                                        </li>
                                        <li [ngClass]="
                            changePasswordForm.passwordStrength[
                              'confirmPassword'
                            ].pcSpecialValue == 1
                              ? 'green'
                              : changePasswordForm.confirmPassword == ''
                              ? ''
                              : 'red'
                          ">
                                          <div>!#?</div>
                                        </li>
                                      </ul>
                                      <span [ngClass]="validCheckPassword('confirmPassword')">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="pwd-footer">
                              <div class="row">
                                <div class="col-12" class="pr-0 " class="settings__mobile-password-button-container">
                                  <button (tap)="onSubmit()" [disabled]="
                      !changePasswordForm.oldPassword ||
                      !changePasswordForm.confirmPassword ||
                      !changePasswordForm.newPassword || data.connectionType !== 'email'
                    " class="button button--dark-grey pull-right" type="submit" aria-label="submit button"
                                    role="button">
                                    SUBMIT
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <hr class="settings__mobile-hr" />
                        <!------------------------ Change Privacy --------------------->
                        <div class="container" class="settings__mobile-privacy-container">
                          <div class="settings__mobile-privacy-content">
                            <div>
                              <h5 class="header-label"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">Change
                                Privacy Mode</h5>
                            </div>
                            <div *ngIf="data.currentUser">
                              <div fxLayout="row">
                                <label class="primary-label"
                                  [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">
                                  Your current status:
                                  <span class="fw500" *ngIf="data.userProfileStatusFlag">PUBLIC</span>
                                  <span class="fw500" *ngIf="!data.userProfileStatusFlag">PRIVATE</span>
                                </label>
                                <div class="settings__mobile-switch-container">
                                  <label class="settings__mobile-switch-label" class="switch" *ngIf="!hide">
                                    <input type="checkbox" (change)="statusChange()" [(ngModel)]="profileStatusFlag"
                                      aria-label="privacy status toggle" />
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <span class="secondary-label"
                                [ngStyle]="{'color': data.accessibilityMode ? 'black' : '#858585' }">You
                                can make your information visible to other users in the
                                platform by selecting this option</span>
                            </div>
                            <div class="card" style="margin:20px 0">
                              <div class="card-header h-50">
                                <p class="col-8 mt-3 float-left settings__form-privacy-label fw500 grey-color">You can
                                  control what information you want to share</p>
                              </div>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item col-12" *ngFor="let item of publicFieldsPrivacy">
                                  <div class="col-5 float-left fw500 grey-color" [attr.aria-label]="item.name">
                                    {{item.name}}</div>
                                  <!-- <ng-container *ngIf="!data.userProfileStatusFlag">
                                      <label class="switch  col-3 mt-1 float-left">
                                        <input type="checkbox" [attr.aria-label]=" item.name + ' toggle'" [disabled]="!data.userProfileStatusFlag" class="default">
                                        <span class="slider round"></span>
                                      </label>
                                      <p  class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500">PRIVATE</p>
                                    </ng-container>  -->
                                  <ng-container>
                                    <label class="switch  col-6 mt-1 float-left">
                                      <input type="checkbox" (change)="statusChange(item, publicFieldsPrivacy)"
                                        [attr.aria-label]=" item.name + ' toggle'" [(ngModel)]="item.value"
                                        class="default">
                                      <span class="slider round"></span>
                                    </label>
                                    <p class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500"
                                      aria-label="public status" *ngIf="item.value">PUBLIC</p>
                                    <p class="col-5 grey-color float-left settings__form-privacy-label ml-4 fw500"
                                      aria-label="private status" *ngIf="!item.value">PRIVATE</p>
                                  </ng-container>
                                </li>
                              </ul>
                              <div class="col-12 p-0 text-center">
                                <p class="col-12 text-left settings__form-privacy-label fw500 mt-1 grey-color mt-4 text-danger"
                                  *ngIf="ifChange">Please click submit to reflect the changes in your privacy settings.
                                </p>
                                <button aria-label="submit button" [disabled]="!ifChange"
                                  class="button button--dark-grey mx-auto mt-4 mb-4" (click)="updatePrivacyInfo()"
                                  role="button">Submit</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-flip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>