import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { LanguageEdge } from 'src/app/shared/utilities/app-db-edges';
import { Language } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class LanguagesComponent implements OnInit {

  languageList = [];
  @ViewChild('card') card: ElementRef;
  flipCard = false;
  viewmodel = this.data.viewmodel;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();
  unverified: boolean;

  constructor(
    public _constant: SiteConstants,
    public _constantApi: SiteApiConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    private escape: Escape,
    public data: DataService,
    private api: HttpService
  ) {}

  status: ServiceStatus;
  // @Input() viewmodel: UserProfile;
  newItem: { edge: LanguageEdge; toNodeRef: NodeRef; toNode: Language };
  currentItem: { edge: LanguageEdge; toNodeRef: NodeRef; toNode: Language };
  languageModel: any = {
    name: 'Select',
    level: '',
    read: false,
    write: false,
    speak: false,
    understand: false,
    peerReview: false,
  };
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  isMobile: boolean;
  deleteMode = false;
  isLanguageRequired = false;
  isOneOptionSelected = false;
  enableSaveBtn = false;

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getLanguageList();
    this.refreshNewItem();
  }

  flip(){
     
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getLanguageList() {
    this.languageList = [];
    const apiUrl = `${this.root}/userProfileUpdateLanguage?operation=list`;
    const getLanguageList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getLanguageList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORDS
        this.viewmodel.speaks_Language = [];
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            read: response.record[item].row[0].read,
            write: response.record[item].row[0].write,
            speak: response.record[item].row[0].speak,
            understand: response.record[item].row[0].understand,
            peerReview: response.record[item].row[0].peerReview,
            dataSource: response.record[item].row[0].dataSource,
            level: response.record[item].row[0].level,
            status : 'verified'
          };
          this.viewmodel.speaks_Language.push(temp);
          this.languageList.push(temp);
        }
        // UNVERIFIED RECORDS
        this.viewmodel.unverified_Speaks_Language = [];
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            read: response.unverified[item].row[0].read,
            write: response.unverified[item].row[0].write,
            speak: response.unverified[item].row[0].speak,
            understand: response.unverified[item].row[0].understand,
            peerReview: response.unverified[item].row[0].peerReview,
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            level: response.unverified[item].row[0].level,
            status : 'unverified'
          };
          this.viewmodel.unverified_Speaks_Language.push(temp);
          this.languageList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        level: 0,
        read: false,
        write: false,
        speak: false,
        understand: false,
        peerReview: false,
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: 'Select' },
      toNode: {
        uuid: 'Select',
        name: 0,
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.languageModel = {
      name: 'Select',
      level: '',
      read: false,
      write: false,
      speak: false,
      understand: false,
      peerReview: false,
    };
    this.isOneOptionSelected = false;
    this.whenToEnableSaveButton();
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    if (item.importStatus) {
      this.languageModel.importStatus = item.importStatus;
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    if (item.read || item.speak || item.understand || item.write || item.peerReview) {
      this.isOneOptionSelected = true;
    } else if (!item.read && !item.speak && !item.understand && !item.write && !item.peerReview) {
      this.isOneOptionSelected = false;
    }
    this.languageModel = { ...item };
    this.languageModel.edgeUuid = item.uuid;
    this.languageModel.dataSource = item.dataSource;
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.whenToEnableSaveButton();
  }

  saveClick() {
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateLanguage?operation=update`;
      // tslint:disable-next-line: max-line-length
      const LanguageUpdate = this.http.post<any>(
        apiUrl,
        { data: this.languageModel, edgeUuid: this.languageModel.edgeUuid },
        { headers: this.headers }
      );
      LanguageUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getLanguageList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateLanguage?operation=create`;
      // tslint:disable-next-line: max-line-length
      const LanguageUpdate = this.http.post<any>(
        apiUrl,
        { data: this.languageModel },
        { headers: this.headers }
      );
      LanguageUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getLanguageList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    if (item.importStatus) {
      this.languageModel.importStatus = item.importStatus;
    }
    this.deleteMode = true;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.languageModel = { ...item };
    this.languageModel.edgeUuid = item.uuid;
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
  }

  deleteClick() {
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateLanguage?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const LanguageUpdate = this.http.post<any>(
      apiUrl,
      { data: this.languageModel, edgeUuid: this.languageModel.edgeUuid },
      { headers: this.headers }
    );
    LanguageUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getLanguageList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  cancelClick() {
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }

  onLanguageChange(event) {
    if(event === 'Select') {
      this.isLanguageRequired = true;
    } else {
      this.isLanguageRequired = false;
    }
    this.whenToEnableSaveButton();
  }

  onLanguageLevelSelection(languageModel) {
    if(languageModel.read || languageModel.speak || languageModel.understand || languageModel.write || languageModel.peerReview) {
      this.isOneOptionSelected = true;
    } else if(!languageModel.read && !languageModel.speak && !languageModel.understand && !languageModel.write && !languageModel.peerReview) {
      this.isOneOptionSelected = false;
    }
    this.whenToEnableSaveButton();
  }

  whenToEnableSaveButton() {
    if(this.languageModel.name === 'Select' && !this.isOneOptionSelected) {
      this.enableSaveBtn = false;
    } else if(this.languageModel.name !== 'Select' && !this.isOneOptionSelected) {
      this.enableSaveBtn = false;
    } else if(this.languageModel.name !== 'Select' && this.isOneOptionSelected) {
      this.enableSaveBtn = true;
    } else if(this.languageModel.name === 'Select' && this.isOneOptionSelected) {
      this.enableSaveBtn = false;
    }
  }
}
