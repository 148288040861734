<div id="profile-section-experience" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Experience</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add experience button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div class="profile__section__empty" *ngIf="!experienceList || experienceList.length == 0">
        Click + to add first working experience.
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="experienceList">
        <div class="profile__section__list--item" *ngFor="let item of experienceList; index as i;">
          <div *ngIf="item.name != 'NONE' || experienceList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <span *ngIf="item.name !== 'NONE'">Company:</span>
                <span *ngIf="item.name === 'NONE'">Experience:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
              <div *ngIf="item.shortName" class="col-md-6" role="list item property value">
                <span>Short Name:</span>
                <span class="fw500 pl05r">{{ item.shortName }}</span>
              </div>
              <div *ngIf="item.webSite" class="col-10" role="list item property value">
                <span>Website:</span>
                <span class="fw500 pl05r">{{item.webSite}}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.industry" class="col-md-6" role="list item property value">
                <span>Industry:</span>
                <span class="fw500 pl05r">{{ item.industry }}</span>
              </div>
              <div *ngIf="item.position" class="col-md-6" role="list item property value">
                <span>Role:</span>
                <span class="fw500 pl05r">{{ item.position }}</span>
              </div>
            </div>
            <div class="row">
              <div *ngIf="item.department" class="col-md-6" role="list item property value">
                <span>Department:</span>
                <span class="fw500 pl05r">{{ item.department }}</span>
              </div>
              <div *ngIf="item.type" class="col-md-6" role="list item property value">
                <span>Type:</span>
                <span class="fw500 pl05r">{{ item.type }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.startDateFlag">
                  <span>Start Date:</span>
                  <span class="fw500 pl05r">
                    {{item.startDateFlag.day ? item.startDate.getDate() : ''}}
                    {{item.startDateFlag.month ? months[+item.startDateFlag.month - 1] : ''}}
                    {{item.startDateFlag.year ? item.startDateFlag.year : ''}}
                  </span>
                </div>
              </div>
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.endDateFlag && !item.currentlyWorking">
                  <span>End Date:</span>
                  <span class="fw500 pl05r">
                    {{ item.endDateFlag.day ? item.endDate.getDate() : '' }}
                    {{ item.endDateFlag.month ? months[+item.endDateFlag.month - 1] : '' }}
                    {{ item.endDateFlag.year ? item.endDateFlag.year : '' }}
                  </span>
                </div>
                <div *ngIf="item.currentlyWorking">
                  <span>End Date:</span>
                  <span class="fw500 pl05r">Currently working</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || experienceList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit experience button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete experience button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Experience</div>
        <div *ngIf="experienceModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- COMPANY -->
        <div class="profile__form--company">
          <label class="form__label">Company <span class="form__required">*</span></label>
          <input required id="currentItem_toNode_name" name="currentItem_toNode_name" type="text" class="form__control"
            [(ngModel)]="experienceModel.name" [ngbTypeahead]="search" #toNode_name="ngModel"
            placeholder="Ex. Profound Impact" [disabled]="deleteMode" aria-label="company - required" />
        </div>
        <!-- SHORT NAME -->
        <div class="profile__form--sname">
          <label class="form__label">Short Name</label>
          <input class="form__control" id="currentItem_toNode_shortName" name="currentItem_toNode_shortName"
            [ngModel]="experienceModel.shortName" #toNode_shortName="ngModel" placeholder="Ex. PI"
            (ngModelChange)="experienceModel.shortName = this.escape.it($event)"
            [disabled]="deleteMode" aria-label="short name" />
        </div>
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="currentItem_toNode_webSite" name="currentItem_toNode_webSite" type="url"
            [ngModel]="experienceModel.webSite" placeholder="Ex. https://www.profoundimpact.com/"
            [pattern]="websiteRegExp"
            #website="ngModel"
            (ngModelChange)="experienceModel.webSite = this.escape.it($event)"
            [disabled]="deleteMode" aria-label="website" 
            [ngClass]="{'ng-invalid': !website.valid }"/>
        </div>
        <!-- INDUSTRY -->
        <div class="profile__form--industry">
          <label class="form__label">Industry</label>
          <select class="form__control" id="currentItem_edge_industry" name="currentItem_edge_industry"
            [(ngModel)]="experienceModel.industry" aria-placeholder="industry"
            [disabled]="deleteMode" aria-label="industry"  >
            <option value="Select">Select</option>
            <option *ngFor="let option of options" [value]="option">{{ option }}</option>
          </select>
        </div>
        <!-- ROLE -->
        <div class="profile__form--role">
          <label class="form__label">Role</label>
          <input class="form__control" id="currentItem_edge_position" name="currentItem_edge_position"
            [ngModel]="experienceModel.position" placeholder="Ex. Data Analyst" [disabled]="deleteMode"
            (ngModelChange)="experienceModel.position = this.escape.it($event)"
            aria-label="role"   />
        </div>
        <!-- DEPARTMENT -->
        <div class="profile__form--department">
          <label class="form__label">Department</label>
          <input class="form__control" id="currentItem_edge_department" name="currentItem_edge_department"
            [ngModel]="experienceModel.department" placeholder="Ex. Combinatorics & Optimization"
            (ngModelChange)="experienceModel.department = this.escape.it($event)"
            [disabled]="deleteMode" aria-label="department"   />
        </div>
        <!-- TYPE -->
        <div class="profile__form--type">
          <label class="form__label">Type</label>
          <select class="form__control" id="currentItem_edge_type" name="currentItem_edge_type"
            [(ngModel)]="experienceModel.type" aria-placeholder="type" value="experienceModel.type"
            [disabled]="deleteMode" aria-label="type"  >
            <option value="Select">Select</option>
            <option *ngFor="let type of types" [value]="type">
              {{ type }}
            </option>
          </select>
        </div>
        <!-- SUMMARY -->
        <div class="profile__form--summary">
          <label class="form__label">Summary</label>
          <textarea class="form__control text-area" id="currentItem_edge_summary" [ngModel]="experienceModel.summary"
            name="currentItem_edge_summary"
            placeholder="Ex. My duties included analyzing the complex data models and then cleaning/formatting the large datasets to merge into existing data structure"
            (ngModelChange)="experienceModel.summary = this.escape.it($event)"
            autosize [minRows]="5" [maxRows]="10" [disabled]="deleteMode" aria-label="summary"
             ></textarea>
        </div>
        <!-- CURRENT -->
        <div class="profile__form--current">
          <label class="content-verify form__label"><span>I am currently working in this role</span>
            <input name="current" type="checkbox" [(ngModel)]="currentlyWorking" (click)="toggleCurrentlyWorking()"
              [disabled]="deleteMode" aria-label="currently working" />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- START YEAR -->
        <div class="profile__form--start-year">
          <label class="form__label">Start Year <span class="form__required">*</span></label>
          <select class="form__control" #startYear (change)="onDateChange('startDate', 'year', startYear.value)" required
            [(ngModel)]="experienceModel.startDate.year" name="certif_year" [disabled]="deleteMode"
            aria-label="start year - required"  
            [ngClass]="{'ng-invalid': isStartYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="experienceModel.startDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- START MONTH -->
        <div class="profile__form--start-month" *ngIf="experienceModel.startDate.year">
          <label class="form__label">Start Month</label>
          <select class="form__control" (change)="onDateChange('startDate', 'month')"
            [(ngModel)]="experienceModel.startDate.month" name="certif_month" [disabled]="deleteMode"
            aria-label="start month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- START DAY -->
        <div class="profile__form--start-day" *ngIf="experienceModel.startDate.year && experienceModel.startDate.month">
          <label class="form__label">Start Day</label>
          <select class="form__control" (change)="onDateChange('startDate', 'day')"
            [(ngModel)]="experienceModel.startDate.day" name="certif_day" [disabled]="deleteMode" aria-label="start day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of startDayValue" [selected]="experienceModel.startDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END YEAR -->
        <div class="profile__form--end-year" *ngIf="!currentlyWorking || experienceModel.currentlyWorking === 'false'">
          <label class="form__label">End Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('endDate', 'year')" required
            [(ngModel)]="experienceModel.endDate.year" name="certif_year2" [disabled]="deleteMode"
            aria-label="end year - required"  >
            <option value="">Select Year</option>
            <option *ngFor="let item of endYearValue" [selected]="experienceModel.endDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END MONTH -->
        <div class="profile__form--end-month"
          *ngIf="(!currentlyWorking || experienceModel.currentlyWorking === 'false') && experienceModel.endDate.year">
          <label class="form__label">End Month</label>
          <select class="form__control" (change)="onDateChange('endDate', 'month')"
            [(ngModel)]="experienceModel.endDate.month" name="certif_month2" [disabled]="deleteMode"
            aria-label="end month"  >
            <option value="">Select Month</option>
            <option *ngFor="let item of endMonthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- END DAY -->
        <div class="profile__form--end-day"
          *ngIf="(!currentlyWorking || experienceModel.currentlyWorking === 'false') && experienceModel.endDate.year && experienceModel.endDate.month">
          <label class="form__label">End Day</label>
          <select class="form__control" (change)="onDateChange('endDate', 'day')"
            [(ngModel)]="experienceModel.endDate.day" name="certif_day2" [disabled]="deleteMode" aria-label="end day"
             >
            <option value="">Select Day</option>
            <option *ngFor="let item of endDayValue" [selected]="experienceModel.endDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && experienceModel.dataSource === 'User') ||
                (thisForm.form.invalid && experienceModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-company" *ngIf="toNode_name.invalid && (toNode_name.dirty || toNode_name.touched)">
          <strong *ngIf="toNode_name.errors.required">Company is required</strong>
        </div>
        <div class="row tac profile__form--error-start-year" *ngIf="isStartYearRequired">
          <strong>Grad Year is required</strong>
        </div>
        <ng-container *ngIf="!displayDeleteButton">
          <div class="row tac profile__form--error-invalid-website-url" *ngIf="!website.valid">
            <strong>Not a valid website url</strong>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>