import { Http } from '@angular/http';
// ---------------------------------------------------------------------------------------------------------------------- //
// ---------------------------------------------- CONTROLLING REGIONS --------------------------------------------------- //
// ---------------------------- Fold All (Ctrl+K Ctrl+0) | folds all regions in the editor ------------------------------ //
// -------------------------- Unfold All (Ctrl+K Ctrl+J) | unfolds all regions in the editor ---------------------------- //
// ------ Unfold Recursively (Ctrl+K Ctrl+]) unfolds the region at the cursor and all regions inside that region -------- //
// ------- fold Recursively (Ctrl+K Ctrl+[) unfolds the region at the cursor and all regions inside that region --------- //
// ---------------------------------------------------------------------------------------------------------------------- //

// ██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗███████╗
// ██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝██╔════╝
// ██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║   ███████╗
// ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║   ╚════██║
// ██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║   ███████║
// ╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';
import { catchError, map } from 'rxjs/operators';
import * as xml2js from '../../../node_modules/xml2js';
import { AmplifyService } from 'aws-amplify-angular';
import { Observable, throwError } from 'rxjs';
import { Auth } from 'aws-amplify';
import {
  RelationServiceResponseMessage,
  AcademicFamilyTreeSrviceResponseMessage,
} from '../shared/utilities/app-framework';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../shared/components/dialogs/login/login.component';

// ██████╗ ███████╗ ██████╗██╗      █████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔══██╗██╔════╝██╔════╝██║     ██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║  ██║█████╗  ██║     ██║     ███████║██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║  ██║██╔══╝  ██║     ██║     ██╔══██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ██████╔╝███████╗╚██████╗███████╗██║  ██║██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚═════╝ ╚══════╝ ╚═════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  //  ██████╗ ██████╗ ███╗   ██╗███████╗████████╗██████╗ ██╗   ██╗ ██████╗████████╗ ██████╗ ██████╗
  // ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗██║   ██║██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗
  // ██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ██████╔╝██║   ██║██║        ██║   ██║   ██║██████╔╝
  // ██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██╗██║   ██║██║        ██║   ██║   ██║██╔══██╗
  // ╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║╚██████╔╝╚██████╗   ██║   ╚██████╔╝██║  ██║
  //  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝  ╚═════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝

  constructor(
    private http: HttpClient,
    private data: DataService,
    public amplifyService: AmplifyService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.data.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.centralRoot  = environment.centralServiceBaseUrl
  }

  // ██████╗ ██████╗  ██████╗ ██████╗ ███████╗██████╗ ████████╗██╗███████╗███████╗
  // ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔════╝██╔════╝
  // ██████╔╝██████╔╝██║   ██║██████╔╝█████╗  ██████╔╝   ██║   ██║█████╗  ███████╗
  // ██╔═══╝ ██╔══██╗██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗   ██║   ██║██╔══╝  ╚════██║
  // ██║     ██║  ██║╚██████╔╝██║     ███████╗██║  ██║   ██║   ██║███████╗███████║
  // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

  root: string;
  centralRoot: string;
  jwtoken: string;
  header: HttpHeaders;
  base64textString: string;
  bucket = environment.privateBucket;
  fileBucket = environment.publicBucket

  // ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
  // ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝

  /**
   * sets up and returns the http headers used in the REST calls
   * @param jwtToken The users json web token
   * @returns The http headers
   */
  private GetHttpHeaders(jwtToken: string) {
    return new HttpHeaders({
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    });
  }

  private EnPointHeaders(apiKey: string) {
    return new HttpHeaders({
      Authorization: apiKey,
      'Content-Type': 'multipart/form-data;',
    });
  }

  //  ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗
  // ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║
  // ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║
  // ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║
  // ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗
  //  ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝

  // #region general REST services

  /**
   * gets the anonymous user data
   * @url https://extreme-ip-lookup.com/json/
   * @method GET
   * @returns The anonymous user data
   */
  getAnonymousUserData() {
    return this.http.get(`https://ipapi.co/json/`, {
      headers: this.header,
    });
  }

  /**
   * gets a list of suggestions for the desired field
   * @url /getSuggestions
   * @method GET
   * @param key the component that the suggestions are to be used
   * @returns The list of suggestions for the desired field
   */
  getSuggestions(key: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getSuggestions?key=${key}`, {
      headers: this.header,
    });
  }

  /**
 * gets list slides
 * @url /getUsersList
 * @method POST
 * @param filters The filters used to get the list
 * @returns The list of users
 */
  getBanner(id: any): Observable<any> {
    const apiURL = `${this.root}/bannerManagement?type=front_banners&bannerId=${id}`;
    return this.http
      .get<any>(apiURL)
      .pipe((data) => data, catchError(this.handleError));
  }

  get2020Banner(id: any): Observable<any> {
    const apiURL = `https://crlsemimy9.execute-api.us-east-1.amazonaws.com/Default/bannerManagement?type=front_banners&bannerId=${id}`;
    return this.http
      .get<any>(apiURL)
      .pipe((data) => data, catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }

  /**
   * gets a list of dropdowns for the desired category
   * @url /getDropdownList
   * @method GET
   * @param type the category that the dropdown lists are to be used
   * @returns The list of items for the desired dropdown
   */
  getDropdowns(category: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getDropdownList?key=${category}`, {
      headers: this.header,
    });
  }

  /**
   * gets list of users
   * @url /getUsersList
   * @method POST
   * @param filters The filters used to get the list
   * @returns The list of users
   */
  getUsers(filters: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(`${this.root}/getUserSearch?limit=100`, filters, {
      headers: this.header,
    });
  }

  // #endregion

  //  █████╗ ██╗   ██╗████████╗██╗  ██╗
  // ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
  // ███████║██║   ██║   ██║   ███████║
  // ██╔══██║██║   ██║   ██║   ██╔══██║
  // ██║  ██║╚██████╔╝   ██║   ██║  ██║
  // ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝

  // #region auth REST services

  /**
   * sends the user an email with their username
   * @url /mailForgotUsername
   * @method ANY
   * @param operation The operation that we want to execute
   * @param fileName The file name of the file we are trying to operate on
   * @param bucketName The bucket where the file exists
   * @param folder The folder name inside the bucket where the file lives (if exists)
   * @returns The server response / or file
   */
  S3BucketOperations(operation: string, fileName: string, bucketName: string, folder?: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    if (folder) {
      // tslint:disable-next-line: max-line-length
      api = `${this.root}/bucketOperations?operation=${operation}&key=${fileName}&bucket=${bucketName}&folder=${folder}`;
    } else {
      api = `${this.root}/bucketOperations?operation=${operation}&key=${fileName}&bucket=${bucketName}`;
    }
    // tslint:disable-next-line: max-line-length
    // return this.http.get(api);
    return this.http.get(api, {
      headers: this.header,
    });
  }

  /**
   * sends the user an email with their username
   * @url /mailForgotUsername
   * @method ANY
   * @param operation The operation that we want to execute
   * @param fileName The file name of the file we are trying to operate on
   * @param bucketName The bucket where the file exists
   * @param folder The folder name inside the bucket where the file lives (if exists)
   * @returns The server response / or file
   */
  S3BucketOperationsPublic(operation: string, fileName: string, bucketName: string, folder?: string) {
    let api;
    if (folder) {
      // tslint:disable-next-line: max-line-length
      api = `${this.root}/bucketOperationsPublic?operation=${operation}&key=${fileName}&bucket=${bucketName}&folder=${folder}`;
    } else {
      api = `${this.root}/bucketOperationsPublic?operation=${operation}&key=${fileName}&bucket=${bucketName}`;
    }
    // tslint:disable-next-line: max-line-length
    // return this.http.get(api);
    return this.http.get(api);
  }

  /**
   * sends the user an email with their username
   * @url /mailForgotUsername
   * @method GET
   * @param email The email provided
   * @returns The server response
   */
  forgotUsername(email: string) {
    return this.http.get(`${this.root}/mailForgotUsername?key=${email}`);
  }

  /**
   * gets the current users full profile object
   * @url /getUserProfile
   * @method GET
   * @returns The current users full profile object
   */
  getUserProfile() {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getUserProfile`, {
      headers: this.header,
    });
  }

  // refreshed the profile completion object after any changes to the profile are made
  refreshProfileCompletion() {
    this.getProfileCompletion(this.data.jwt).subscribe((res) => {
      const response: any = res;
      if (response.status === 2 && response.message === 'ForceLogout') {
        Auth.signOut({ global: true }).then((data) => { });
        this.data.currentUser = null;
        this.data.authenticated = false;
        console.log('REFRESH FORCED LOGOUT');
        this.router.navigate(['/']);
      }
      this.data.logger(res, 'REFRESHED PROFILE COMPLETION');
      this.data.currentUser = response.data.person;
      console.log('current user', this.data.currentUser);
      this.data.profileCompletion = response.data;
      console.log('PROFILE COMPLETION', this.data.profileCompletion);
      this.data.completionWidth = response.data.totalCompleted + '%';
      this.data.ariaValue = response.data.totalCompleted + 1;
      this.data.profileTabs[0].completion = this.data.profileCompletion.basic;
      this.data.profileTabs[1].completion = this.data.profileCompletion.experience;
      this.data.profileTabs[2].completion = this.data.profileCompletion.education;
      this.data.profileTabs[3].completion = this.data.profileCompletion.certification;
      this.data.profileTabs[4].completion = this.data.profileCompletion.volunteer;
      this.data.profileTabs[5].completion = this.data.profileCompletion.skill;
      this.data.profileTabs[6].completion = this.data.profileCompletion.language;
      this.data.profileTabs[7].completion = this.data.profileCompletion.publication;
      this.data.profileTabs[8].completion = this.data.profileCompletion.awards;
      this.data.profileTabs[9].completion = this.data.profileCompletion.projects;
      this.data.profileTabs[10].completion = this.data.profileCompletion.research;
      this.data.profileTabs[11].completion = this.data.profileCompletion.patent;
      this.data.profileTabs[12].completion = this.data.profileCompletion.academicAncestry;
      this.data.profileTabs[13].completion = this.data.profileCompletion.mentoringRelation;
      this.data.profileTabs[14].completion = this.data.profileCompletion.socialMedia;
      console.log('DATA.TABS: ', this.data.profileTabs);
    });
  }

  /**
   * gets the user profile completion object
   * @url /getUserProfileCompletionStatus
   * @method GET
   * @param jwt The users json web token
   * @param username The loged in user's username
   * @returns The current users profile completion status object
   */
  getProfileCompletion(jwt: string) {
    this.data.jwt = jwt;
    this.header = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    return this.http.get(`${this.root}/getUserProfileCompletionStatus`, {
      headers: this.header,
    });
  }

  // #endregion

  // ███████╗███████╗████████╗████████╗██╗███╗   ██╗ ██████╗ ███████╗
  // ██╔════╝██╔════╝╚══██╔══╝╚══██╔══╝██║████╗  ██║██╔════╝ ██╔════╝
  // ███████╗█████╗     ██║      ██║   ██║██╔██╗ ██║██║  ███╗███████╗
  // ╚════██║██╔══╝     ██║      ██║   ██║██║╚██╗██║██║   ██║╚════██║
  // ███████║███████╗   ██║      ██║   ██║██║ ╚████║╚██████╔╝███████║
  // ╚══════╝╚══════╝   ╚═╝      ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  // #region settings REST services

  /**
   * Sets the user's privacy setting
   * @url /userProfileStatusUpdate
   * @method POST
   * @param status The status the privacy is changing to
   * @returns The server response
   */
  updateProfileStatus(status: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(
      `${this.root}/userProfileStatusUpdate`,
      status,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Adds a secondary email to user's profile
   * @url /userProfileEmailUpdate
   * @method POST
   * @param email The email to be added
   * @returns The server response
   */

  addAccount(account: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(
      `${this.centralRoot}/userProfileEmailUpdate?operation=Add`,
      account,
      {
        headers: this.header,
      }
    );
  }

  getAccountsData(uuidPerson: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
      'Content-Type': 'application/json'
    });
    return this.http.get(
      `${this.centralRoot}/userProfileEmailUpdate?operation=Get&uuidPerson=${uuidPerson}`,
      {
        headers: this.header,
      }
    );
  }

  deleteAccount(email, connectionType) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
      'Content-Type': 'application/json'
    });
    return this.http.delete(
      `${this.centralRoot}/userProfileEmailUpdate?operation=Delete&email=${email}&connectionType=${connectionType}`,
      {
        headers: this.header,
      }
    );
  }


  // #endregion

  // ██╗  ██╗ ██████╗ ███╗   ███╗███████╗
  // ██║  ██║██╔═══██╗████╗ ████║██╔════╝
  // ███████║██║   ██║██╔████╔██║█████╗
  // ██╔══██║██║   ██║██║╚██╔╝██║██╔══╝
  // ██║  ██║╚██████╔╝██║ ╚═╝ ██║███████╗
  // ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝

  // #region home REST services

  /**
   * gets the data to fill the geo map
   * @url /geographical-chart-get
   * @method GET
   * @returns The data to fill the geo map
   */
  getGeoMapData() {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/geographical-chart-get`, {
      headers: this.header,
    });
  }

  /**
   * gets the rss feed data from the s3 bucket
   * @url ${url}
   * @param url The url pointing to the rss s3 bucket
   * @returns The rss feed data from the s3 bucket
   */
  getRSSFeed(url: string) {
    return this.http
      .get(url, { responseType: 'text' })
      .pipe(map(this.extractFeeds));
  }

  /**
   * extracts the feed data to be usable by UI
   * @param response The feed object received from s3
   * @returns The usable feed object
   */
  private extractFeeds(response: any) {
    const parser = new xml2js.Parser({
      explicitArray: false,
      mergeAttrs: true,
    });
    let feed;
    parser.parseString(response, function (err, result) {
      if (err) {
        console.warn(err);
      }
      feed = result;
    });
    return feed || {};
  }

  // Join-new user

  inviteNewUser(userDetails: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(`${this.root}/userSelfRegistration`, userDetails, {
      headers: this.header,
    });
  }
  // #endregion

  // ██████╗ ██████╗  ██████╗ ███████╗██╗██╗     ███████╗
  // ██╔══██╗██╔══██╗██╔═══██╗██╔════╝██║██║     ██╔════╝
  // ██████╔╝██████╔╝██║   ██║█████╗  ██║██║     █████╗
  // ██╔═══╝ ██╔══██╗██║   ██║██╔══╝  ██║██║     ██╔══╝
  // ██║     ██║  ██║╚██████╔╝██║     ██║███████╗███████╗
  // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚══════╝

  // #region profile REST services

  /**
   * gets the signed url to use for s3 image upload
   * @url /getUploadS3Url
   * @method POST
   * @param fileName The name of the file to be uploaded
   * @returns The signed url to use to upload image to s3
   */
  uploadImage(fileName: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(
      `${this.root}/bucketOperations?operation=getUploadUrl&bucket=${environment.publicBucket}&folder=profile-pictures&key=${fileName}`,
      {},
      {
        headers: this.header,
      }
    );
  }

  /**
   * used the signed url obtained to upload the image to amazon s3
   * @url ${signedUrl}
   * @method PUT
   * @param file The file to be uploaded
   * @param signedUrl The temporary signed url used for authentication
   * @returns The server response
   */
  uploadImageSignedUrl(file: any, signedUrl: string) {
    this.header = new HttpHeaders({
      contentType: 'image/*',
    });
    return this.http.put(`${signedUrl}`, file, {
      headers: this.header,
    });
  }

  /**
   * used the signed url obtained to upload the image to amazon s3
   * @url ${signedUrl}
   * @method DELETE
   * @param file The file to be deleted
   * @param bucket Bucket url from which previous file needs to be deleted
   * @returns The server response
   */

  deleteImage(fileName: string, folderName: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`
    });
    return this.http.delete(
      `${this.root}/bucketOperations?operation=delete&bucket=${environment.publicBucket}&folder=${folderName}&key=${fileName}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * gets the country list for profile component
   * @url /getLocationSuggestions
   * @method GET
   * @returns The country list for profile component
   */
  getCountries() {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getLocationSuggestions`, {
      headers: this.header,
    });
  }

  /**
   * gets the state list for profile component
   * @url /getLocationSuggestions
   * @method GET
   * @param id The selected country id
   * @returns The state list for profile component
   */
  getStates(id: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getLocationSuggestions?country=${id}`, {
      headers: this.header,
    });
  }

  /**
   * gets the city list for profile component
   * @url /getLocationSuggestions
   * @method GET
   * @param id The selected state id
   * @returns The city list for profile component
   */
  getCities(id: any) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getLocationSuggestions?state=${id}`, {
      headers: this.header,
    });
  }

  // //#endregion

  // ██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗
  // ██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  // ██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║
  // ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║
  // ██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║
  // ╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  /**
   * gets the profile import status data for upload component
   * @url /getUserProfileImportStatus
   * @method GET
   * @returns The profile import status for upload component
   */
  getUserProfileImportStatus() {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getUserProfileImportStatus`, {
      headers: this.header,
    });
  }

  /**
   * gets the signed url to use for s3 image upload
   * @url /getUploadS3Url
   * @method POST
   * @param fileName The name of the file to be uploaded
   * @returns The signed url to use to upload image to s3
   */
  uploadFile(fileName: string, CVtype: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(
      `${this.root}/bucketOperations?operation=getUploadUrl&bucket=${environment.privateBucket}&folder=${CVtype}&key=${fileName}`,
      {},
      {
        headers: this.header,
      }
    );
  }

  /**
   * grabs the file from s3,
   * @url /extractCustomCV
   * @param fileName
   * @param fileSize
   * @param lastModified 
   * @returns The server response
   */

  linkCustomFile(fileName: String, fileRealName: string, fileSize: number, lastModified: any, type: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    if (type === 'pdf'){
      api = `${this.root}/extractCustomCV?key=${fileName}&uploadedFileName=${fileRealName}&fileSize=${fileSize}&lastModified=${lastModified}`;
      // api = `${this.root}/extractCustomCV?key=${fileName}&fileSize=${fileSize}&lastModified=${lastModified}`;
    }

    return this.http.post(
      api,
      {},
      {
        headers: this.header,
      }
    );

  }
  /**
   * grabs the file from s3, extracts the data and inserts it into dynamo db
   * @url /extractCommonCV
   * @method POST
   * @param fileName The name of the file to be extracted and imported
   * @returns The server response
   */
  linkFile(fileName: String, fileRealName: string, fileSize: number, lastModified: any, type: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    if (type === 'pdf') {
      // tslint:disable-next-line: max-line-length
      api = `${this.root}/extractCommonCV?key=${fileName}&uploadedFileName=${fileRealName}&fileSize=${fileSize}&lastModified=${lastModified}`;
    }
    if (type === 'xml') {
      // tslint:disable-next-line: max-line-length
      api = `${this.root}/extractCommonCVXML?key=${fileName}&uploadedFileName=${fileRealName}&fileSize=${fileSize}&lastModified=${lastModified}`;
    }
    return this.http.post(
      api,
      {},
      {
        headers: this.header,
      }
    );
  }
  /**
   * fetch the tranfer account data 
   * @url /piProfileImportPre
   * @method GET
   * @returns The server response
   */
  transferData(){
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    api = `${this.centralRoot}/piProfileImportPre`;
    return this.http.get(
      api, 
      {
        headers: this.header,
      }
    )
  }
  /**
   * Request Account data
   * @url /piProfileImportRequest
   * @method GET
   * @return The server response
  */
  transferRequestData(){
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    api = `${this.centralRoot}/piProfileImportRequest?operation=GetRequests`;
    return this.http.get(
      api, 
      {
        headers: this.header,
      }
    )
  }
  /**
   * @url /piProfileImportRequest
   * @param email 
   * @param source
   * @method POST 
   * @returns The server response
   */
  transferSendRequest(email: String,source:String){
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    api = `${this.centralRoot}/piProfileImportRequest?operation=SendRequest`;
    return this.http.post(
      api,
      {
        email: email + '',
        source: source + ''
      },
      {
        headers: this.header,
      }
    )
  }
  /**
   * @url /piProfileImportRequest
   * @param requestUuid 
   * @param status 
   * @method POST 
   * @returns The server response
   */
  transferUpdateRequest(requestUuid: String, status: String){
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    api = `${this.centralRoot}/piProfileImportRequest?operation=UpdateRequest`;
    return this.http.post(
      api,
      {
        requestUuid: requestUuid,
        status: status
      },
      {
        headers: this.header,
      }
    )
  }
  /**
   * extracts the data and inserts it into dynamo db
   * @url /piProfileImport
   * @method POST
   * @param dataSource The name of the source
   * @returns The server response
   */
  transferProfile(dataSource: String,uuidPerson: String){
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    let api;
    api = `${this.centralRoot}/piProfileImport?source=${dataSource}&uuid=${uuidPerson}`;
    return this.http.post(
      api,
      {},
      {
        headers: this.header,
      }
    )
  }
  //  ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗ ███████╗
  // ██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗██╔════╝
  // ██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝███████╗
  // ██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝ ╚════██║
  // ╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║     ███████║
  //  ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝     ╚══════╝

  // #region groups REST services

  /**
   * gets the event data for the graph
   * @url /graphEventUser
   * @method GET
   * @param eventName the event name
   * @returns The server response
   */
  getGroupEventGraph(eventName: string) {
    return this.http.get(`${this.root}/graphEventUser?key=${eventName}`);
  }

  /**
   * lets the back end know that the current ananymous user attented this zoom event
   * @url /setUserMeetingPublic
   * @method POST
   * @param id the zoom meeting id
   * @returns The server response
   */
  groupMeetingStats(id: any, country: string, city: string, ipAddress: string, uuid: string) {
    this.header = new HttpHeaders({
    });
    return this.http.post(
      `${this.root}/setGroupMeetingStatistics`,
      {
        id: id + '',
        country: country + '',
        city: city + '',
        ipAddress: ipAddress + '',
        uuidPerson: uuid
      },
      {
        headers: this.header,
      }
    );
  }

  /**
   * lets the back end know that the current ananymous user attented this zoom event
   * @url /setUserMeetingPublic
   * @method GET
   * @param id the group event id
   * @returns The server response
   */
  getGroupEventId(eventID: string) {
    return this.http.get(`${this.root}/eventScheduleGet?operation=GetSessionId&id=${eventID}`);
  }

  /**
   * lets the back end know that the current ananymous user attented this zoom event
   * @url /eventScheduleGet?operation=GetSchedule&id=500
   * @method GET
   * @param id the group event id
   * @returns The group event schedule
   */
  getGroupEventSchedule(eventID: string) {
    return this.http.get(`${this.root}/eventScheduleGet?operation=GetSchedule&id=${eventID}`);
  }

  // #endregion

  // ██╗    ██╗██╗    ██╗██╗███╗   ██╗
  // ██║    ██║██║    ██║██║████╗  ██║
  // ██║ █╗ ██║██║ █╗ ██║██║██╔██╗ ██║
  // ██║███╗██║██║███╗██║██║██║╚██╗██║
  // ╚███╔███╔╝╚███╔███╔╝██║██║ ╚████║
  //  ╚══╝╚══╝  ╚══╝╚══╝ ╚═╝╚═╝  ╚═══╝

  // #region wwin REST services

  /**
   * lets the back end know that the current user attented this zoom event
   * @url /setUserMeeting
   * @method POST
   * @param id the zoom meeting id
   * @returns The server response
   */
  connectToZoom(id: any, country: string, city: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(
      `${this.root}/setUserMeeting`,
      {
        id: id + '',
        country: country + '',
        city: city
      },
      {
        headers: this.header,
      }
    );
  }

  /**
   * lets the back end know that the current ananymous user attented this zoom event
   * @url /setUserMeetingPublic
   * @method POST
   * @param id the zoom meeting id
   * @returns The server response
   */
  connectToZoomPublic(id: any, country: string, city: string) {
    return this.http.post(
      `${this.root}/setUserMeetingPublic`,
      {
        id: id + '',
        country: country + '',
        city: city
      },
      {
        headers: this.header,
      }
    );
  }

  // #endregion

  //  ██████╗██╗      █████╗ ███████╗███████╗     ██████╗ ███████╗    ██████╗  ██████╗ ██████╗  ██████╗
  // ██╔════╝██║     ██╔══██╗██╔════╝██╔════╝    ██╔═══██╗██╔════╝    ╚════██╗██╔═████╗╚════██╗██╔═████╗
  // ██║     ██║     ███████║███████╗███████╗    ██║   ██║█████╗       █████╔╝██║██╔██║ █████╔╝██║██╔██║
  // ██║     ██║     ██╔══██║╚════██║╚════██║    ██║   ██║██╔══╝      ██╔═══╝ ████╔╝██║██╔═══╝ ████╔╝██║
  // ╚██████╗███████╗██║  ██║███████║███████║    ╚██████╔╝██║         ███████╗╚██████╔╝███████╗╚██████╔╝
  //  ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝     ╚═════╝ ╚═╝         ╚══════╝ ╚═════╝ ╚══════╝ ╚═════╝

  // #region class of 2020 REST services

  /**
   * lets the back end know that the current user attented this zoom event
   * @url /setUserMeeting
   * @method POST
   * @param id the zoom meeting id
   * @param linkKey the zoom link key
   * @returns The server response
   */
  connectToZoom2020(id: any, linkKey: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.post(
      `${this.root}/setUserMeeting`,
      { id: id + '', linkKey: linkKey },
      {
        headers: this.header,
      }
    );
  }

  /**
   * gets the user meeting details for graph
   * @url /getUserMeetingDetails
   * @method GET
   * @param key the zoom link key
   * @returns The server response
   */
  getZoom2020(key: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    return this.http.get(`${this.root}/getUserMeetingDetails?key=${key}`, {
      headers: this.header,
    });
  }

  // #endregion

  //  ██████╗ ██╗██╗   ██╗██╗███╗   ██╗ ██████╗     ████████╗██╗   ██╗███████╗███████╗██████╗  █████╗ ██╗   ██╗
  // ██╔════╝ ██║██║   ██║██║████╗  ██║██╔════╝     ╚══██╔══╝██║   ██║██╔════╝██╔════╝██╔══██╗██╔══██╗╚██╗ ██╔╝
  // ██║  ███╗██║██║   ██║██║██╔██╗ ██║██║  ███╗       ██║   ██║   ██║█████╗  ███████╗██║  ██║███████║ ╚████╔╝ 
  // ██║   ██║██║╚██╗ ██╔╝██║██║╚██╗██║██║   ██║       ██║   ██║   ██║██╔══╝  ╚════██║██║  ██║██╔══██║  ╚██╔╝  
  // ╚██████╔╝██║ ╚████╔╝ ██║██║ ╚████║╚██████╔╝       ██║   ╚██████╔╝███████╗███████║██████╔╝██║  ██║   ██║   
  //  ╚═════╝ ╚═╝  ╚═══╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝        ╚═╝    ╚═════╝ ╚══════╝╚══════╝╚═════╝ ╚═╝  ╚═╝   ╚═╝   

  // #region giving tuesday REST services

  /**
   * gets the giving tuesday fund data
   * @url /getGivingTuesdayFundData
   * @method GET
   * @returns The giving tuesday fund data
   */
  getGivingTuesdayFundData() {
    return this.http.get(`${this.root}/getGivingTuesdayFundData?operation=Get`);
  }

  // #endregion

  //  █████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗██╗
  // ██╔══██╗██║     ██║   ██║████╗ ████║████╗  ██║██║
  // ███████║██║     ██║   ██║██╔████╔██║██╔██╗ ██║██║
  // ██╔══██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║██║
  // ██║  ██║███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║██║
  // ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚═╝

  // #region alumni REST services

  /**
   * gets the alumni graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @param city The current city
   * @returns The alumni graph data
   */
  getAlumni(
    jwtToken: string,
    searchTerms: string,
    selectedgroupAlumniBy: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    city: string
  ): Observable<RelationServiceResponseMessage> {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      person: searchTerms['Persons'],
      companies: searchTerms['Company']
    };

    const API_URL = `${this.root}/graphAlumniExperience?group=${selectedgroupAlumniBy}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&city=${city}`;
    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // Alumni2

  getAlumni2(
    jwtToken: string,
    searchTerms: string,
    selectedgroupAlumniBy: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    city: string
  ): Observable<RelationServiceResponseMessage> {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      person: searchTerms['Persons'],
      companies: searchTerms['Company']
    };

    const API_URL = `${this.root}/graphAlumniExperience2?group=${selectedgroupAlumniBy}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&city=${city}`;
    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }
  // #endregion                                                                                           

// ███████╗███╗   ██╗████████╗██████╗ ███████╗██████╗ ██████╗ ███████╗███╗   ██╗███████╗██╗   ██╗██████╗ 
// ██╔════╝████╗  ██║╚══██╔══╝██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝████╗  ██║██╔════╝██║   ██║██╔══██╗
// █████╗  ██╔██╗ ██║   ██║   ██████╔╝█████╗  ██████╔╝██████╔╝█████╗  ██╔██╗ ██║█████╗  ██║   ██║██████╔╝
// ██╔══╝  ██║╚██╗██║   ██║   ██╔══██╗██╔══╝  ██╔═══╝ ██╔══██╗██╔══╝  ██║╚██╗██║██╔══╝  ██║   ██║██╔══██╗
// ███████╗██║ ╚████║   ██║   ██║  ██║███████╗██║     ██║  ██║███████╗██║ ╚████║███████╗╚██████╔╝██║  ██║
// ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝
  
getEntrepreneur(
  jwtToken: string,
  searchTerms: string,
  selectedgroupAlumniBy: string,
  yearSliderMinValue: number,
  yearSliderMaxValue: number,
  city: string
): Observable<RelationServiceResponseMessage> {
  const headers = this.GetHttpHeaders(jwtToken);

  const data = {
    person: searchTerms['Persons'],
    companies: searchTerms['Company']
  };

  const API_URL = `${this.root}/graphExperienceEntrepreneur?group=${selectedgroupAlumniBy}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&city=${city}`;
  return this.http.post<any>(API_URL, data, {
    headers,
  });
} 

  // ███████╗ █████╗  ██████╗██╗   ██╗██╗  ████████╗██╗   ██╗
  // ██╔════╝██╔══██╗██╔════╝██║   ██║██║  ╚══██╔══╝╚██╗ ██╔╝
  // █████╗  ███████║██║     ██║   ██║██║     ██║    ╚████╔╝
  // ██╔══╝  ██╔══██║██║     ██║   ██║██║     ██║     ╚██╔╝
  // ██║     ██║  ██║╚██████╗╚██████╔╝███████╗██║      ██║
  // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚═╝      ╚═╝

  // #region faculty REST services

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @param city The current city
   * @returns The faculty graph data
   */
  getFaculty(
    jwtToken: string,
    legendFilter: string,
    searchTerms: string,
    selectedgroupAlumniBy: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    city: string
  ): Observable<RelationServiceResponseMessage> {
    const headers = this.GetHttpHeaders(jwtToken);
    
    const data = {
      person: searchTerms["Persons"],
      departments: searchTerms["Department"]
    }

    const API_URL = `${this.root}/graphFacultyDepartment?key=${legendFilter}&group=${selectedgroupAlumniBy}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&city=${city}`;
    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // #endregion

  // ███████╗ █████╗ ███╗   ███╗██╗██╗  ██╗   ██╗     ██████╗ ██████╗  █████╗ ██████╗ ██╗  ██╗
  // ██╔════╝██╔══██╗████╗ ████║██║██║  ╚██╗ ██╔╝    ██╔════╝ ██╔══██╗██╔══██╗██╔══██╗██║  ██║
  // █████╗  ███████║██╔████╔██║██║██║   ╚████╔╝     ██║  ███╗██████╔╝███████║██████╔╝███████║
  // ██╔══╝  ██╔══██║██║╚██╔╝██║██║██║    ╚██╔╝      ██║   ██║██╔══██╗██╔══██║██╔═══╝ ██╔══██║
  // ██║     ██║  ██║██║ ╚═╝ ██║██║███████╗██║       ╚██████╔╝██║  ██║██║  ██║██║     ██║  ██║
  // ╚═╝     ╚═╝  ╚═╝╚═╝     ╚═╝╚═╝╚══════╝╚═╝        ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝
  // #region family graph REST services

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The academic family graph data
   */
  getAcademicFamilyGraph(
    // jwtToken: string,
    selectedLevel: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    role: string
  ): Observable<AcademicFamilyTreeSrviceResponseMessage> {
    // const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      person: searchTerms["Persons"],
    }
    
    const API_URL = `${this.root}/graphAcademicAncestry?levels=${selectedLevel}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&role=${role}`;

    return this.http.post<any>(API_URL, data,  {
      // headers,
    });
  }

  // #endregion

  // ███╗░░░███╗███████╗███╗░░██╗████████╗░█████╗░██████╗░██╗███╗░░██╗░██████╗░
  // ████╗░████║██╔════╝████╗░██║╚══██╔══╝██╔══██╗██╔══██╗██║████╗░██║██╔════╝░
  // ██╔████╔██║█████╗░░██╔██╗██║░░░██║░░░██║░░██║██████╔╝██║██╔██╗██║██║░░██╗░
  // ██║╚██╔╝██║██╔══╝░░██║╚████║░░░██║░░░██║░░██║██╔══██╗██║██║╚████║██║░░╚██╗
  // ██║░╚═╝░██║███████╗██║░╚███║░░░██║░░░╚█████╔╝██║░░██║██║██║░╚███║╚██████╔╝
  // ╚═╝░░░░░╚═╝╚══════╝╚═╝░░╚══╝░░░╚═╝░░░░╚════╝░╚═╝░░╚═╝╚═╝╚═╝░░╚══╝░╚═════╝░
   // #region family graph REST services

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The mentoring relation graph data
   */

  getMentoringRelationGraph(
    jwtToken: string,
    selectedLevel: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    role: string
  ): Observable<AcademicFamilyTreeSrviceResponseMessage> {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      person: searchTerms["Persons"],
    }
    
    const API_URL = `${this.root}/graphMentoringAncestry?levels=${selectedLevel}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&role=${role}`;

    return this.http.post<any>(API_URL, data,  {
      headers,
    });
  }

  // #endregion

  // ██████╗ ██╗███████╗ ██████╗██╗   ██╗███████╗███████╗██╗ ██████╗ ███╗   ██╗    ██████╗  ██████╗  █████╗ ██████╗ ██████╗
  // ██╔══██╗██║██╔════╝██╔════╝██║   ██║██╔════╝██╔════╝██║██╔═══██╗████╗  ██║    ██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
  // ██║  ██║██║███████╗██║     ██║   ██║███████╗███████╗██║██║   ██║██╔██╗ ██║    ██████╔╝██║   ██║███████║██████╔╝██║  ██║
  // ██║  ██║██║╚════██║██║     ██║   ██║╚════██║╚════██║██║██║   ██║██║╚██╗██║    ██╔══██╗██║   ██║██╔══██║██╔══██╗██║  ██║
  // ██████╔╝██║███████║╚██████╗╚██████╔╝███████║███████║██║╚██████╔╝██║ ╚████║    ██████╔╝╚██████╔╝██║  ██║██║  ██║██████╔╝
  // ╚═════╝ ╚═╝╚══════╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @returns The discussion board data
   */
  getDiscussionBoard(id: string) {
    const API_URL = `${this.root}/getDiscussionBoardComments?id=${id}`;

    return this.http.get<any>(API_URL);
  }

  // #endregion

  //   ███████╗ ██████╗  ██████╗ ███╗   ███╗     ██████╗ ██████╗ ███╗   ██╗███████╗██╗ ██████╗ 
  // ╚══███╔╝██╔═══██╗██╔═══██╗████╗ ████║    ██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔════╝ 
  //   ███╔╝ ██║   ██║██║   ██║██╔████╔██║    ██║     ██║   ██║██╔██╗ ██║█████╗  ██║██║  ███╗
  //  ███╔╝  ██║   ██║██║   ██║██║╚██╔╝██║    ██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██║   ██║
  // ███████╗╚██████╔╝╚██████╔╝██║ ╚═╝ ██║    ╚██████╗╚██████╔╝██║ ╚████║██║     ██║╚██████╔╝
  // ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝     ╚═╝     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝ ╚═════╝ 

  /**
   * gets the zoom live stream config
   * @param jwtToken The current user's json web token
   * @returns The zoom live stream config
   */
  getZoomConfig(id: string) {
    //const headers = this.GetHttpHeaders(jwtToken);
    const API_URL = `${this.root}/getLiveStreamConfigs?id=${id}&operation=Get`;
    return this.http.get<any>(API_URL);
  }

  getZoomMeeting(zoomMeetingId: string) {
    //const headers = this.GetHttpHeaders(jwtToken);
    const API_URL = `${this.root}/getLiveStreamConfigs?zoomMeetingId=${zoomMeetingId}&operation=Zoom`;
    return this.http.get<any>(API_URL);
  }

  // |       \         |  \     |  \  \                   |  \   |  \
  // | ▓▓▓▓▓▓▓\__    __| ▓▓____ | ▓▓\▓▓ _______  ______  _| ▓▓_   \▓▓ ______  _______
  // | ▓▓__/ ▓▓  \  |  \ ▓▓    \| ▓▓  \/       \|      \|   ▓▓ \ |  \/      \|       \
  // | ▓▓    ▓▓ ▓▓  | ▓▓ ▓▓▓▓▓▓▓\ ▓▓ ▓▓  ▓▓▓▓▓▓▓ \▓▓▓▓▓▓\\▓▓▓▓▓▓ | ▓▓  ▓▓▓▓▓▓\ ▓▓▓▓▓▓▓\
  // | ▓▓▓▓▓▓▓| ▓▓  | ▓▓ ▓▓  | ▓▓ ▓▓ ▓▓ ▓▓      /      ▓▓ | ▓▓ __| ▓▓ ▓▓  | ▓▓ ▓▓  | ▓▓
  // | ▓▓     | ▓▓__/ ▓▓ ▓▓__/ ▓▓ ▓▓ ▓▓ ▓▓_____|  ▓▓▓▓▓▓▓ | ▓▓|  \ ▓▓ ▓▓__/ ▓▓ ▓▓  | ▓▓
  // | ▓▓      \▓▓    ▓▓ ▓▓    ▓▓ ▓▓ ▓▓\▓▓     \\▓▓    ▓▓  \▓▓  ▓▓ ▓▓\▓▓    ▓▓ ▓▓  | ▓▓
  //  \▓▓       \▓▓▓▓▓▓ \▓▓▓▓▓▓▓ \▓▓\▓▓ \▓▓▓▓▓▓▓ \▓▓▓▓▓▓▓   \▓▓▓▓ \▓▓ \▓▓▓▓▓▓ \▓▓   \▓▓
  // #publication graph REST services

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The academic family graph data
   */
  getPublicationGraph(
    jwtToken: string,
    operation: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    type: string,
    institutions: boolean
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      authors: searchTerms["Persons"],
      publications: searchTerms["Entities"]
    }

    const API_URL = `${this.root}/graphPublicationType?operation=${operation}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&type=${type}&perimeter=${institutions}`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  /**
   * gets the publication author graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The publication authors graph data
   */
  getPublicationAuthorsGraph(
    jwtToken: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    institutions: boolean
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      authors: searchTerms["Persons"],
      publications: searchTerms["Entities"]
    }

    const API_URL = `${this.root}/graphPublicationCoPublishers?start=${yearSliderMinValue}&end=${yearSliderMaxValue}&perimeter=${institutions}`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  /**
   * gets the publication author graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param selectedgroupAlumniBy The selected group alumni by value
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The publication authors2 graph data
   */
  getPublicationAuthors2Graph(
    jwtToken: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    institutions: boolean
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      authors: searchTerms["Persons"],
      publications: searchTerms["Entities"]
    }

    const API_URL = `${this.root}/graphPublicationPublishers?&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&perimeter=${institutions}`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // #publication graph REST services

  /**
   * gets the publication by subjet graph data
   * @param jwtToken The current user's json web token
   * @param searchTerms The selected search terms
   * @param yearSliderMinValue The current year min value
   * @param yearSliderMaxValue The current year max value
   * @returns The publication subject graph data
   */     
  // ███████╗██╗   ██╗██████╗      ██╗███████╗ ██████╗████████╗
  // ██╔════╝██║   ██║██╔══██╗     ██║██╔════╝██╔════╝╚══██╔══╝
  // ███████╗██║   ██║██████╔╝     ██║█████╗  ██║        ██║   
  // ╚════██║██║   ██║██╔══██╗██   ██║██╔══╝  ██║        ██║   
  // ███████║╚██████╔╝██████╔╝╚█████╔╝███████╗╚██████╗   ██║   
  // ╚══════╝ ╚═════╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝   

  getPublicationSubjectGraph(
    operation: string,
    subject: string,
    jwtToken: string,
    searchTerms: string,
    yearSliderMinValue: number,
    yearSliderMaxValue: number,
    type: string
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      authors: searchTerms["Persons"],
      publications: searchTerms["Entities"],
      subject: searchTerms["Subject"]
    }

    const API_URL = `${this.root}/graphPublicationSubject?operation=${operation}&subject=${subject}&start=${yearSliderMinValue}&end=${yearSliderMaxValue}&type=${type}`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }
                                                          
  

  //  █████╗ ███████╗███████╗███████╗███████╗███████╗███╗   ███╗███████╗███╗   ██╗████████╗
  // ██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
  // ███████║███████╗███████╗█████╗  ███████╗███████╗██╔████╔██║█████╗  ██╔██╗ ██║   ██║   
  // ██╔══██║╚════██║╚════██║██╔══╝  ╚════██║╚════██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   
  // ██║  ██║███████║███████║███████╗███████║███████║██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   
  // ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
  //                                                                                       

  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @returns The assessment data
   */
  getAssessment(
    jwtToken: string,
  ) {
    const headers = this.GetHttpHeaders(jwtToken);
    // tslint:disable-next-line: max-line-length
    const API_URL = `${this.root}/assessmentData?operation=Get`;

    return this.http.get<any>(API_URL, {
      headers,
    });
  }

  saveAssessment(
    jwtToken: string,
    data: []
  ) {
    const headers = this.GetHttpHeaders(jwtToken);
    // tslint:disable-next-line: max-line-length
    const API_URL = `${this.root}/assessmentData?operation=Update`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  submitAssessment(
    jwtToken: string,
    data: any
  ) {
    const headers = this.GetHttpHeaders(jwtToken);
    // tslint:disable-next-line: max-line-length
    const API_URL = `${this.root}/assessmentData?operation=Submit`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }


  uploadAssessmentPDF(fileName: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    // console.log(`${this.root}/getUploadS3Url?bucket=${this.assessmentBucket}&key=${fileName}`);
    return this.http.post(
      `${this.root}/bucketOperations?operation=getUploadUrl&bucket=${environment.privateBucket}&folder=assessments&key=${fileName}`,
      {},
      {
        headers: this.header,
      }
    );
  }

  // ██████╗ ██████╗ ██╗██╗   ██╗ █████╗ ████████╗███████╗     ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗ ███████╗
  // ██╔══██╗██╔══██╗██║██║   ██║██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗██╔════╝
  // ██████╔╝██████╔╝██║██║   ██║███████║   ██║   █████╗      ██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝███████╗
  // ██╔═══╝ ██╔══██╗██║╚██╗ ██╔╝██╔══██║   ██║   ██╔══╝      ██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝ ╚════██║
  // ██║     ██║  ██║██║ ╚████╔╝ ██║  ██║   ██║   ███████╗    ╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║     ███████║
  // ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═══╝  ╚═╝  ╚═╝   ╚═╝   ╚══════╝     ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝     ╚══════╝
  // #private group REST services

  /**
     * gets the faculty graph data
     * @param jwtToken The current user's json web token
     * @returns List of private groups
     */
  getPrivateGroups(
    jwtToken: string,
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const API_URL = `${this.root}/groupManagement?operation=GetMembership`;

    return this.http.get<any>(API_URL, {
      headers,
    });
  }

  joinPrivateGroups(
    jwtToken: string,
    groupid: string
  ) {
    const headers = this.GetHttpHeaders(jwtToken);
    const data = {
      groupId: groupid
    }
    const API_URL = `${this.root}/groupManagement?operation=RequestMembership`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // #private group REST services


  // ███████╗███╗░░██╗██████╗░░█████╗░██╗███╗░░██╗████████╗  ██╗░░░░░░█████╗░░██████╗░██╗███╗░░██╗
  // ██╔════╝████╗░██║██╔══██╗██╔══██╗██║████╗░██║╚══██╔══╝  ██║░░░░░██╔══██╗██╔════╝░██║████╗░██║
  // █████╗░░██╔██╗██║██████╔╝██║░░██║██║██╔██╗██║░░░██║░░░  ██║░░░░░██║░░██║██║░░██╗░██║██╔██╗██║
  // ██╔══╝░░██║╚████║██╔═══╝░██║░░██║██║██║╚████║░░░██║░░░  ██║░░░░░██║░░██║██║░░╚██╗██║██║╚████║
  // ███████╗██║░╚███║██║░░░░░╚█████╔╝██║██║░╚███║░░░██║░░░  ███████╗╚█████╔╝╚██████╔╝██║██║░╚███║
  // ╚══════╝╚═╝░░╚══╝╚═╝░░░░░░╚════╝░╚═╝╚═╝░░╚══╝░░░╚═╝░░░  ╚══════╝░╚════╝░░╚═════╝░╚═╝╚═╝░░╚══╝
  // #enpoint login service

  /**
     * gets the faculty graph data
     * @param enPointApiKey The api key of enpoint
     * @param enPointHash The hash of enpoint
     * @returns Redirect login url if validated
     */


  async enPointLogin(
    email: string
  ) {
    let postData = new FormData()

    const data = `{\"request\":\"generate-url\",\"request_data\":{\"email\":\"${email}\"}}`

    postData.append("data", data)
    postData.append("hash", environment.enPointHash)

    const API_URL = 'https://enpoint.twistbits.com/sso_api';

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Authorization': environment.enPointApiKey,
      },
      body: postData,
    });

    let result = await response.json();
    console.log('enpoint response', result);

    return result
  }

  // #private group REST services


  // ███████╗██╗██╗     ███████╗    ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ 
  // ██╔════╝██║██║     ██╔════╝    ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗
  // █████╗  ██║██║     █████╗      ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║
  // ██╔══╝  ██║██║     ██╔══╝      ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║
  // ██║     ██║███████╗███████╗    ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝
  // ╚═╝     ╚═╝╚══════╝╚══════╝    ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ 
  // #file download

  /**
   * gets the faculty graph data
   * @returns The file download url
   */

  downloadFile(fileName: string) {
    this.header = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
    // console.log(`${this.root}/getUploadS3Url?bucket=${this.assessmentBucket}&key=${fileName}`);
    return this.http.get(
      `${this.root}/bucketOperations?operation=getDownloadUrl&key=${fileName}&bucket=${this.bucket}&folder=webinar-archives`,
      {
        headers: this.header,
      }
    );
  }

  // get request to fetch the image 
  getImageDownloadSignedUrl(signedUrl: string) {
    this.header = new HttpHeaders({
      contentType: 'image/*',
      Accept: 'image/*'
    });
    return this.http.get(`${signedUrl}`, {
      headers: this.header,
      responseType: 'blob'
    });
  }

  // get request to fetch the image 
  getJSONDownloadSignedUrl(signedUrl: string) {
    this.header = new HttpHeaders({
      contentType: 'application/json',
      Accept: 'application/json'
    });
    return this.http.get(`${signedUrl}`, {
      headers: this.header,
      responseType: 'json'
    });
  }

  // get request to download the file 
  getFileDownloadSignedUrl(signedUrl: string) {
    this.header = new HttpHeaders({
      contentType: 'application/json',
      Accept: 'application/vnd.openxmlformats-officedocument'
    });
    return this.http.get(`${signedUrl}`, {
      headers: this.header,
      responseType: 'arraybuffer'
    });
  }

  // #private group REST services

  // ██████╗ ██████╗ ██╗██╗   ██╗ █████╗  ██████╗██╗   ██╗    ███████╗███████╗████████╗████████╗██╗███╗   ██╗ ██████╗ ███████╗
  // ██╔══██╗██╔══██╗██║██║   ██║██╔══██╗██╔════╝╚██╗ ██╔╝    ██╔════╝██╔════╝╚══██╔══╝╚══██╔══╝██║████╗  ██║██╔════╝ ██╔════╝
  // ██████╔╝██████╔╝██║██║   ██║███████║██║      ╚████╔╝     ███████╗█████╗     ██║      ██║   ██║██╔██╗ ██║██║  ███╗███████╗
  // ██╔═══╝ ██╔══██╗██║╚██╗ ██╔╝██╔══██║██║       ╚██╔╝      ╚════██║██╔══╝     ██║      ██║   ██║██║╚██╗██║██║   ██║╚════██║
  // ██║     ██║  ██║██║ ╚████╔╝ ██║  ██║╚██████╗   ██║       ███████║███████╗   ██║      ██║   ██║██║ ╚████║╚██████╔╝███████║
  // ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═══╝  ╚═╝  ╚═╝ ╚═════╝   ╚═╝       ╚══════╝╚══════╝   ╚═╝      ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝
  
  // #private settings REST services
                                                                                                            
  /**
   * gets the faculty graph data
   * @param jwtToken The current user's json web token
   * @returns List of user privacy settings 
   */
  getPrivateSettings(
    jwtToken: string,
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const API_URL = `${this.root}/privacyManagement?operation=GetPrivacySettings`;

    return this.http.get<any>(API_URL, {
      headers,
    });
  }

  updatePrivateSettings(
    jwtToken: string,
    data: any
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const API_URL = `${this.root}/privacyManagement?operation=UpdatePrivacySettings`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // #private settings REST services


  // ██╗░░░██╗░██████╗███████╗██████╗░  ██████╗░░█████╗░██╗░░░░░██╗░░░░░░██████╗
  // ██║░░░██║██╔════╝██╔════╝██╔══██╗  ██╔══██╗██╔══██╗██║░░░░░██║░░░░░██╔════╝
  // ██║░░░██║╚█████╗░█████╗░░██████╔╝  ██████╔╝██║░░██║██║░░░░░██║░░░░░╚█████╗░
  // ██║░░░██║░╚═══██╗██╔══╝░░██╔══██╗  ██╔═══╝░██║░░██║██║░░░░░██║░░░░░░╚═══██╗
  // ╚██████╔╝██████╔╝███████╗██║░░██║  ██║░░░░░╚█████╔╝███████╗███████╗██████╔╝
  // ░╚═════╝░╚═════╝░╚══════╝╚═╝░░╚═╝  ╚═╝░░░░░░╚════╝░╚══════╝╚══════╝╚═════╝░
  // #user poll

  /**
   * gets the poll data
   * @returns poll data
   */

  getUserPolls(
    pollID: string,
    email: string
  ) {

    const API_URL = `${this.root}/pollManagement?operation=GetPoll&id=${pollID}&email=${email}`;

    return this.http.get<any>(API_URL);
  }

  submitUserPolls(
    pollData: any
  ) {

    const API_URL = `${this.root}/pollManagement?operation=SubmitPoll`;

    return this.http.post<any>(API_URL, pollData);
  }
 // volunteer 2 graph
 getVolunteerGraph(
  jwtToken: string,
  searchTerms: string,
  yearSliderMinValue: number,
  yearSliderMaxValue: number
  ) {
    const headers = this.GetHttpHeaders(jwtToken);

    const data = {
      person: searchTerms["Persons"],
      companies: searchTerms["Company"]
    }

    const API_URL = `${this.root}/graphVolunteerWork?&start=${yearSliderMinValue}&end=${yearSliderMaxValue}`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }


  // #private settings REST services

  // ██╗░░░░░██╗███╗░░██╗██╗░░██╗███████╗██████╗░██╗███╗░░██╗
  // ██║░░░░░██║████╗░██║██║░██╔╝██╔════╝██╔══██╗██║████╗░██║
  // ██║░░░░░██║██╔██╗██║█████═╝░█████╗░░██║░░██║██║██╔██╗██║
  // ██║░░░░░██║██║╚████║██╔═██╗░██╔══╝░░██║░░██║██║██║╚████║
  // ███████╗██║██║░╚███║██║░╚██╗███████╗██████╔╝██║██║░╚███║
  // ╚══════╝╚═╝╚═╝░░╚══╝╚═╝░░╚═╝╚══════╝╚═════╝░╚═╝╚═╝░░╚══╝

  linkedinLogin() {
    // const API_URL = `https://linkin-auth.auth.us-east-1.amazoncognito.com/login?client_id=${environment.userPoolWebClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${environment.redirectUrl}`
    const API_URL = `https://${environment.loginUrl}/authorize?identity_provider=Auth0&client_id=${environment.userPoolWebClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${environment.redirectUrl}`
    
    window.location.assign(API_URL)
  }

  validateLinkedinUser(jwtToken: string, data: any) {
    const headers = this.GetHttpHeaders(jwtToken);

    const API_URL = `${this.root}/userSignUpSync`;

    return this.http.post<any>(API_URL, data, {
      headers,
    });
  }

  // ░█████╗░░█████╗░░██████╗░███╗░░██╗██╗████████╗░█████╗░  ███╗░░░███╗██╗░██████╗░██████╗░░░░
  // ██╔══██╗██╔══██╗██╔════╝░████╗░██║██║╚══██╔══╝██╔══██╗  ████╗░████║██║██╔════╝░██╔══██╗░░░
  // ██║░░╚═╝██║░░██║██║░░██╗░██╔██╗██║██║░░░██║░░░██║░░██║  ██╔████╔██║██║██║░░██╗░██████╔╝░░░
  // ██║░░██╗██║░░██║██║░░╚██╗██║╚████║██║░░░██║░░░██║░░██║  ██║╚██╔╝██║██║██║░░╚██╗██╔══██╗░░░
  // ╚█████╔╝╚█████╔╝╚██████╔╝██║░╚███║██║░░░██║░░░╚█████╔╝  ██║░╚═╝░██║██║╚██████╔╝██║░░██║██╗
  // ░╚════╝░░╚════╝░░╚═════╝░╚═╝░░╚══╝╚═╝░░░╚═╝░░░░╚════╝░  ╚═╝░░░░░╚═╝╚═╝░╚═════╝░╚═╝░░╚═╝╚═╝
  // #migrate cognito users

  /**
   * gets the migration status
   * @returns migration status
   */

  checkCognitoMigration(
    username: string
  ) {

    const API_URL = `${this.centralRoot}/cognitoMigration?username=${username}`;

    return this.http.get<any>(API_URL);
  }

  
  // ██╗░░░██╗░██████╗███████╗██████╗░  ░██████╗████████╗░█████╗░████████╗██╗░░░██╗░██████╗
  // ██║░░░██║██╔════╝██╔════╝██╔══██╗  ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║░░░██║██╔════╝
  // ██║░░░██║╚█████╗░█████╗░░██████╔╝  ╚█████╗░░░░██║░░░███████║░░░██║░░░██║░░░██║╚█████╗░
  // ██║░░░██║░╚═══██╗██╔══╝░░██╔══██╗  ░╚═══██╗░░░██║░░░██╔══██║░░░██║░░░██║░░░██║░╚═══██╗
  // ╚██████╔╝██████╔╝███████╗██║░░██║  ██████╔╝░░░██║░░░██║░░██║░░░██║░░░╚██████╔╝██████╔╝
  // ░╚═════╝░╚═════╝░╚══════╝╚═╝░░╚═╝  ╚═════╝░░░░╚═╝░░░╚═╝░░╚═╝░░░╚═╝░░░░╚═════╝░╚═════╝░
  // #check cognito user status

  /**
   * gets the user status
   * @returns user status
   */
  cognitoUserStatus(username: string) {
    const API_URL = `${this.centralRoot}/tempPasswordReset?username=${username}`;

    return this.http.get<any>(API_URL)
  }
}
