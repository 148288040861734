import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    public data: DataService,
    private util: UtilityService) {}
  lt900 = document.body.clientWidth <= 900;
  ngOnInit() {
    const onresize = () => {
      this.data.screenWidth = document.body.clientWidth;
      console.log(document.body.clientWidth);
    };
    window.addEventListener('resize', onresize);
  }

  toggleAccessibilityMode() {
    if (this.data.accessibilityMode) {
      this.data.theme = 'acc';
      this.util.setCookie('accessibilityMode', 'enabled');
    } else {
      this.data.theme = 'light';
      this.util.setCookie('accessibilityMode', 'disabled');
    }
  }
}
