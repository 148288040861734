<ngx-spinner></ngx-spinner>
<div class="modal-header pt-2 pb-2">
  <h4 class="modal-title" id="modal-basic-title"></h4>
  <button type="button" class="close" aria-label="Close" ngbAutofocus (click)="activeModal.dismiss('Cross click')"
    aria-label="login dialog close button" role="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pt-2 pb-2 ml-4" id="login-page" [ngClass]="{
    'mobile-login-body': data.isMobile
  }">
  <div class="container" [ngClass]="{
      'mobile-login-container': data.isMobile
    }">
    <div class="row">
      <div *ngIf="currentPageState === pageStates.NormalSignin" class="col-sm-12 text-center pl-0">
        <div class="jumbotron" [ngClass]="{
            'mobile-jumbotron': data.isMobile,
            'web-jumbotron': !data.isMobile
          }">
          <div class="col-sm-12">
            <img src="./assets/images/pi-logo-full.png" class="w100" alt="profound impact logo" role="img" />
          </div>
          <div *ngIf="successMsg !== ''" class="p10">
            <span class="card--h4 form__success">{{ successMsg }}</span>&nbsp;
          </div>
          <div *ngIf="successMsg === ''" class="p10">
            <span class="card--h4 form__required">{{ errorMsg }}</span>&nbsp;
          </div>
          <div class="login__form-label-container">
            <label class="login__form-label-text form__label">Username</label>
          </div>
          <input type="text" class="form-control form__control" aria-label="username - required" placeholder="username"
            [(ngModel)]="username" (keyup.enter)="stopCaptchaPass = true" />
          <div class="tal login__form-password">
            <label class="login__form-label-text form__label">Password</label>
          </div>
          <input type="password" class="form-control form__control" aria-label="password  - required" [(ngModel)]="password"
            placeholder="password" (keyup.enter)="stopCaptchaPass = true" />
          <div *ngIf="data.isMobile || data.LT_MD" class="login__form-recaptcha-mobile">
            <re-captcha aria-label="login dialog captcha" (resolved)="resolved($event)"
              siteKey="6LdVpRwcAAAAAAHh_wDbPxFIHVYSW8V98vCOH0d5"></re-captcha>
          </div>
          <div *ngIf="!data.isMobile && !data.LT_MD" class="login__form-recaptcha-web">
            <re-captcha aria-label="login dialog captcha" (resolved)="resolved($event)"
              siteKey="6LdVpRwcAAAAAAHh_wDbPxFIHVYSW8V98vCOH0d5"></re-captcha>
          </div>
          <button [disabled]="captcha === ''" type="submit" class="button button--blue-login login__form-btn" (click)="cognitoMigration()"
            aria-label="login button" role="button">
            Log in
          </button>
          <div class="row login__form-remember-me">
            <label class="content-verify mx-auto fw600 form__label">
              <input name="remember" type="checkbox" [(ngModel)]="rememberMe" (click)="toggleRememberMe()"
                aria-label="login dialog remember me" />
              <span class="checkmark"></span>&nbsp;
              Remember me
            </label>
          </div>
          <a>
            Forgot
            <span (tap)="toggleForgotUsername($event)" class="cp"><a href="#"
                class="card--h5-small-alt-primary">username</a></span>
            |
            <span (tap)="toggleForgotPassword($event)" class="cp"><a href="#"
              class="card--h5-small-alt-primary">password</a></span>
          </a>
          <p class="p-0 card--login-info login__form-contact-info">
            No Login Credentials? 
            <a class="card--login-info" style="cursor:pointer; color:#0056B3; text-decoration:underline" (click)="joinUs()" aria-label="login dialog get credentials">Please join</a>
          </p>
          <div class="col-12 text-center mb-3 p-0">
            <div class="sig btn-linkedin mt-4" (click)="linkedinLogin()">
              <div class='ico-wrapper'>
                <svg class="ico" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                    <g id="LinkedIn" sketch:type="MSArtboardGroup" fill="#FFFFFF">
                      <g id="Fill-6-+-Fill-7" sketch:type="MSLayerGroup" transform="translate(6.000000, 5.000000)">
                        <path d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
                        id="Fill-6" sketch:type="MSShapeGroup"></path>
                        <path d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
                        id="Fill-7" sketch:type="MSShapeGroup"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              Sign in with Linkedin
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentPageState === pageStates.ForgotPassword" class="col-sm-12 mx-auto text-center pl-0">
        <div class="jumbotron" [ngClass]="{
            'mobile-jumbotron': data.isMobile,
            'web-jumbotron': !data.isMobile
          }">
          <div class="col-sm-12">
            <img src="./assets/images/pi-logo-full.png" class="w100" alt="profound impact logo" role="img" />
          </div>
          <div class="col-sm-12">
            <img src="./assets/images/Profound-Icon-Green.png" class="w50" alt="profound impact icon green"
              role="img" />
          </div>
          <div class="p10">
            <span class="card--h4 form__required" *ngIf="errorMsg != ''">{{ errorMsg }}</span>&nbsp;
          </div>
          <div class="login__form-label-container">
            <label class="login__form-label-text form__label">Username</label>
          </div>
          <input type="text" class="form-control form__control" aria-label="username - required" placeholder="username"
            [(ngModel)]="email" (keyup.enter)="checkUserStatus()" />
          <br />
          <button type="button" class="button button--blue-login login__form-btn" (click)="checkUserStatus()"
            aria-label="reset password button" role="button">
            Reset Password
          </button>
          <a class="card--login-info login__form-forgot-password" href="#" (click)="goBackToLogin($event)" aria-label="back to login button"
            role="navigation">
            Back to login
          </a>
        </div>
      </div>
      <div *ngIf="currentPageState === pageStates.ForgotUsername" class="col-sm-12 mx-auto text-center pl-0">
        <div class="jumbotron" [ngClass]="{
            'mobile-jumbotron': data.isMobile,
            'web-jumbotron': !data.isMobile
          }">
          <div class="col-sm-12">
            <img src="./assets/images/pi-logo-full.png" class="w100" alt="profound impact logo" role="img" />
          </div>
          <div class="col-sm-12">
            <img src="./assets/images/Profound-Icon-Green.png" alt="profound impact icon green" class="w50"
              role="img" />
          </div>
          <div class="text-info">
            <span class="card--h4-alt-primary">Please enter your email address associated with your
              account.
              You
              will get your username in your email</span>
          </div>
          <div *ngIf="errorMsg != ''" class="login__form-username-error">
            <span class="card--h4 form__required">{{ errorMsg }}</span>
          </div>
          <div class="login__form-label-container">
            <label class="login__form-label-text form__label">Email</label>
          </div>
          <input type="email" class="form-control form__control" aria-label="email - required" placeholder="email" [(ngModel)]="email"
            (keyup.enter)="forgotUsername()" />
          <br />
          <button type="button" class="button button--blue-login login__form-btn" (click)="forgotUsername()"
            aria-label="forgot username button" role="button">
            Get Username
          </button>
          <a href="#" (click)="goBackToLogin($event)" class="card--login-info login__form-forgot-password"
            aria-label="back to login button" role="navigation">
            Back to login
          </a>
        </div>
      </div>
      <div *ngIf="currentPageState === pageStates.CompleteSignupPasswordChange"
        class="col-sm-12 mx-auto text-center pl-0">
        <div class="jumbotron" [ngClass]="{
            'mobile-jumbotron': data.isMobile,
            'web-jumbotron': !data.isMobile
          }">
          <div class="col-sm-12">
            <img src="./assets/images/pi-logo-full.png" class="w100" aria-label="profound impact logo" role="img" />
          </div>
          <div *ngIf="errorMsg != ''" class="form__required">
            Password does not meet the requirements below
          </div>
          <br />
          <label class="form__label">Enter New Password to Complete Signup</label>
          <input type="password" class="form-control form__control" aria-label="password - required" [(ngModel)]="password"
            placeholder="password" (ngModelChange)="checkPassword($event, 'newPassword')"
            (keyup.enter)="completeSignupNewPassword()" />
          <label class="p-0 card--h4-alt-primary" >Must have at least 8 characters that contains at least one
            uppercase, lowercase, number and one of the following special
            characters:
            <span class="card--login-info">{{
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < : ; | _ ~ `' }}</span></label>
          <br />
          <button type="button" class="button button--blue-login login__form-btn" (click)="completeSignupNewPassword()"
            aria-label="reset password button" role="button">
            Reset Password
          </button>
        </div>
      </div>
      <div *ngIf="currentPageState === pageStates.PasswordVerification" class="col-sm-12 mx-auto text-center pl-0">
        <div class="jumbotron pt20" [ngClass]="{
            'mobile-jumbotron': data.isMobile,
            'web-jumbotron': !data.isMobile
          }">
          <div class="col-sm-12">
            <img src="./assets/images/pi-logo-full.png" class="w100" aria-label="profound impact logo" role="img" />
          </div>
          <div >
            <span class="card--h4 form__required" *ngIf="errorMsg != ''">{{ errorMsg }}</span>&nbsp;<br />
          </div>
          <div class="login__form-label-container">
            <label class="login__form-label-text form__label">Username</label>
          </div>
          <input type="text" class="form-control form-control" aria-label="username - required" placeholder="username"
            [(ngModel)]="email" (keyup.enter)="resetForgotPassword()" />
          <br />
          <div class="login__form-label-container">
            <label class="login__form-label-text form__label">Verification Code</label>
          </div>
          <input type="text" class="form-control form__control" aria-label="verification code - required" placeholder="######"
            [(ngModel)]="verificationCode" (keyup.enter)="resetForgotPassword()" />
          <br />
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <div class="login__form-label-container">
                <label class="login__form-label-text form__label">New Password <span class="form__required">*</span></label>
              </div>
              <input type="password" class="form-control form__control" placeholder="New Password"
                (keyup)="checkPassword($event, 'newPassword')" [(ngModel)]="changePasswordForm.newPassword"
                (ngModelChange)="setNewPassword($event)" (keyup.enter)="resetForgotPassword()" name="newPassword"
                aria-label="login dialog password input - required" />
              <ul class="password-suggestion pl-0">
                <li [ngClass]="
                    changePasswordForm.passwordStrength['newPassword']
                      .pcLengthValue == 1
                      ? 'green'
                      : changePasswordForm.newPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>8+</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['newPassword']
                      .pcLowerValue == 1
                      ? 'green'
                      : changePasswordForm.newPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>a-z</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['newPassword']
                      .pcUpperValue == 1
                      ? 'green'
                      : changePasswordForm.newPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>A-Z</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['newPassword']
                      .pcNumberValue == 1
                      ? 'green'
                      : changePasswordForm.newPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>0-9</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['newPassword']
                      .pcSpecialValue == 1
                      ? 'green'
                      : changePasswordForm.newPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>!#?</div>
                </li>
              </ul>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <div class="login__form-label-container">
                <label class="login__form-label-text form__label">Confirm Password <span class="form__required">*</span></label>
              </div>
              <input type="password" class="form-control form__control" placeholder="Confirm Password"
                (keyup)="checkPassword($event, 'confirmPassword')" [(ngModel)]="changePasswordForm.confirmPassword"
                (ngModelChange)="setConfirmPassword($event)" name="confirmPassword"
                (keyup.enter)="resetForgotPassword()" aria-label="login dialog confirm password - required" />
              <ul class="password-suggestion pl-0">
                <li [ngClass]="
                    changePasswordForm.passwordStrength['confirmPassword']
                      .pcLengthValue == 1
                      ? 'green'
                      : changePasswordForm.confirmPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>8+</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['confirmPassword']
                      .pcLowerValue == 1
                      ? 'green'
                      : changePasswordForm.confirmPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>a-z</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['confirmPassword']
                      .pcUpperValue == 1
                      ? 'green'
                      : changePasswordForm.confirmPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>A-Z</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['confirmPassword']
                      .pcNumberValue == 1
                      ? 'green'
                      : changePasswordForm.confirmPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>0-9</div>
                </li>
                <li [ngClass]="
                    changePasswordForm.passwordStrength['confirmPassword']
                      .pcSpecialValue == 1
                      ? 'green'
                      : changePasswordForm.confirmPassword == ''
                      ? ''
                      : 'red'
                  ">
                  <div>!#?</div>
                </li>
              </ul>
            </div>
          </div>
          <label class="p-0 card--h4-alt-primary">Must have at least 8 characters that
            contains at least one
            uppercase, lowercase, number and one of the following special
            characters:
            <span class="card--login-info">{{
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < : ; | _ ~ `' }}</span></label>
          <br />
          <button type="button" class="button button--blue-login login__form-btn reset-password" (click)="resetForgotPassword()"
            aria-label="reset password button" role="button">
            Reset Password
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
