import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceStatus, ServiceResponseMessage } from '../../shared/utilities/app-framework';
import { UserProfile } from '../../shared/utilities/user-profile.viewmodel';
import { UserProfileService } from '../profile/user-profile.service';
import { Escape } from '../../shared/utilities/neo4j-escape-unescape/escape';
import { Unescape } from '../../shared/utilities/neo4j-escape-unescape/unescape';
import { environment } from '../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { SiteConstants } from '../../services/site-constants.service';
import { SiteApiConstants } from '../../services/site-api-constants.service';
import { Neo4jEscapeUnescapeModule } from '../../shared/utilities/neo4j-escape-unescape';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoginComponent } from '../../shared/components/dialogs/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    UserProfileService,
    Escape,
    Unescape,
    Ng2ImgMaxService,
    NgxSpinnerService,
    SiteConstants,
    SiteApiConstants,
  ],
})
export class ContactComponent implements OnInit {
  // @Input() viewmodel: UserProfile;
  profileCompletion: any;
  completionWidth: string;
  ariaValue: any;
  imageUploadMessage: string;
  saveDisabled: boolean;
  preSignedurl: any;
  base64textString: string;
  currentCountry: any;
  currentState: any;
  currentCity: any;
  cityDisabled: boolean;
  currentStateID: any;
  countryID: any;
  userFullName: string;
  userEmail: any;
  userProfileStatus: any;
  helpForm: any = {};
  private userLoginData: any;
  flipDiv = false;
  submittedContact = false;
  root: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  countries: any;
  states: any;
  cities: any;

  constructor(
    private userProfileService: UserProfileService,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    private spinner: NgxSpinnerService,
    public _toaster: ToastrManager,
    public _constant: SiteConstants,
    public _constantApi: SiteApiConstants,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private ng2ImgMax: Ng2ImgMaxService,
    private escape: Escape,
    private neo4junescape: Neo4jEscapeUnescapeModule,
    public data: DataService,
    private api: HttpService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.isMobile = this.data.isMobile;
    this.isTablet = this.data.isTablet;
    this.isDesktop = this.data.isDesktopDevice;
    this.bucket = environment.publicBucketUrl;
    this.jwtToken = this._constantApi.getLoginToken();
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${this.data.jwt}`,
    });
  }

  bucket: string;
  cognitoUserSub: string;
  idPoolUID: string;
  status: ServiceStatus;
  viewmodel: UserProfile;
  isLoading = true;
  userBasicModel: any = {};
  formData: FormData = new FormData();
  imagePreview: any;
  imagePreviewLoader = false;
  uploadedImage: File;
  imagePrevUrl: any = null;
  uploadFileSel: string;
  files: FileList;
  singleFile: File;
  fileChange = false;
  fileName = '';
  jwtToken = '';
  headers: HttpHeaders;
  fileData: File = null;
  httpClientCallMessage = '';
  selectedUserTab = 18;
  // tabs = [
  //   {
  //     name: 'BASIC INFO',
  //     key: 1,
  //     active: false,
  //   },
  //   {
  //     name: 'Experience',
  //     key: 2,
  //     active: false,
  //   },
  //   {
  //     name: 'Education',
  //     key: 5,
  //     active: false,
  //   },
  //   {
  //     name: 'Certifications',
  //     key: 9,
  //     active: false,
  //   },
  //   {
  //     name: 'Skills',
  //     key: 12,
  //     active: false,
  //   },
  //   {
  //     name: 'Languages',
  //     key: 13,
  //     active: false,
  //   },
  //   {
  //     name: 'Publications',
  //     key: 7,
  //     active: false,
  //   },
  //   {
  //     name: 'Awards',
  //     key: 3,
  //     active: false,
  //   },
  //   {
  //     name: 'Projects',
  //     key: 8,
  //     active: false,
  //   },
  //   {
  //     name: 'Research',
  //     key: 10,
  //     active: false,
  //   },
  //   {
  //     name: 'Patents',
  //     key: 14,
  //     active: false,
  //   },
  //   {
  //     name: 'Mentor',
  //     key: 4,
  //     active: false,
  //   },
  //   {
  //     name: 'Mentoree',
  //     key: 16,
  //     active: false,
  //   },
  //   {
  //     name: 'Social Media',
  //     key: 15,
  //     active: false,
  //   },
  //   {
  //     name: 'Settings',
  //     key: 17,
  //     active: true,
  //   },
  //   {
  //     name: 'Contact',
  //     key: 18,
  //     active: false,
  //   },
  // ];

  async ngOnInit() {
    this.data.setPageTitle('Contact');
    // this.isMobile = this.data.isMobile;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    window.scrollTo(0, 0);
    this.isLoading = true;
    this.spinner.show();
    if (!this.data.currentUser) {
      await this.amplifyService
        .auth()
        .currentAuthenticatedUser()
        .then((data) => {
          const cognitoUsername = data.username;
          // const cognitoUUID = data.attributes.sub;
          const token = data.signInUserSession.idToken.jwtToken;
          this.data.authenticated =
            data.signInUserSession !== undefined &&
            data.signInUserSession !== null
              ? true
              : false;

          if (this.data.authenticated) {
            this.api.getProfileCompletion(token).subscribe((res) => {
              const response: any = res;
              if (response.status === 2 && response.message === 'ForceLogout') {
                Auth.signOut({ global: true }).then(() => {});
                this.data.currentUser = null;
                this.data.authenticated = false;
                console.log('CONTACT FORCED LOGOUT');
                this.router.navigate(['/']);
                // this.modalService.open(LoginComponent, {
                //   windowClass: 'mdModal',
                // });
              }
              this.data.currentUser = response.data.person;
              this.data.logger(response.data.person, 'PROFILE COMPLETION');
              this.profileCompletion = response.data;
              this.data.profileCompletion = this.profileCompletion;
              this.completionWidth =
                this.profileCompletion.totalCompleted + '%';
              this.data.completionWidth = this.completionWidth;
              this.ariaValue = this.profileCompletion.totalCompleted;
              this.data.ariaValue = this.ariaValue + 1;
              this.userFullName = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
              this.userEmail = this.profileCompletion.person.email;
              this.userProfileStatus = this.profileCompletion.person.profileStatus;
              this.helpForm.name = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
              this.helpForm.email = this.profileCompletion.person.email;
              this.data.jwt = token;
              this.getUserProfile();
            });
          }
        });
    } else {
      this.profileCompletion = this.data.profileCompletion;
      this.completionWidth = this.profileCompletion.totalCompleted + '%';
      this.ariaValue = this.profileCompletion.totalCompleted;
      this.userFullName = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
      this.userEmail = this.profileCompletion.person.email;
      this.userProfileStatus = this.profileCompletion.person.profileStatus;
      this.viewmodel = this.data.viewmodel;
      this.selectedUserTab = 18;
      this.isLoading = false;
      this.helpForm.name = `${this.data.currentUser.firstName} ${this.data.currentUser.lastName}`;
      this.helpForm.email = this.data.currentUser.email;
      this.spinner.hide();
    }
  }

  init() {
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.isLoading = true;
    const res: any = this.data.userProfileData;
    let personData: any;
    this.status = res.status;
    personData = res.data.person;
    if (res.status === ServiceStatus.OK) {
      const viewMode = this.userProfileService.assign(
        res.data.person,
        res.data.reside_in_Location,
        res.data.work_in_Organization,
        res.data.volunteer_in_Organization,
        res.data.earns_Award,
        res.data.academic_ancestry,
        res.data.mentoring_relation,
        res.data.study_in_University,
        res.data.work_in_University,
        res.data.wrote_Publication,
        res.data.work_in_Project,
        res.data.obtained_Certificate,
        res.data.focus_on_AreaOfResearch,
        res.data.obtained_Degree,
        res.data.specialized_in_Skill,
        res.data.speaks_Language,
        res.data.have_Patent,
        res.data.connect_to_SocialMedia
      );
      this.viewmodel = viewMode;
      this.data.viewmodel = viewMode;
    }
    this.userBasicModel.title = this.viewmodel.person.title;
    this.userBasicModel.firstName = this.viewmodel.person.firstName;
    this.userBasicModel.lastName = this.viewmodel.person.lastName;
    this.userBasicModel.displayName = this.viewmodel.person.displayName;
    this.userBasicModel.bio = personData.bio;
    this.userBasicModel.speciality = personData.speciality;
    this.userBasicModel.city = '';
    this.userBasicModel.state = '';
    this.userBasicModel.country = '';
    this.data.userBasicModel = this.userBasicModel;

    this.selectedUserTab = 18;
    this.isLoading = false;
    this.spinner.hide();
  }

  getUserProfile() {
    this.api.getUserProfile().subscribe((data) => {
      const res: any = data;
      this.data.logger(res, 'User Profile Response');
      this.data.userProfileData = res;
      this.init();
    });
  }

  isValid() {
    if (
      !this._constant.checkInput('onlyName', this.helpForm.name) ||
      !this._constant.checkInput('blank', this.helpForm.message) ||
      !this._constant.checkInput('email', this.helpForm.email)
    ) {
      return false;
    }
    return true;
  }

  onSubmit = async () => {
    if (this.isValid()) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.data.jwt}`,
      });
      this.submittedContact = true;
      const API_URL = `${this.root}/mailHelpContact`;
      const resPost = await this.http.post<any>(API_URL, this.helpForm, {
        headers,
      });
      resPost.subscribe((response) => {
        if (
          response !== undefined &&
          typeof response === 'object' &&
          response.status === 'OK'
        ) {
          this.helpForm.subject = '';
          this.helpForm.message = '';
          this._toaster.successToastr(
            'Thanks for submitting your request. We will get back to you shortly.',
            'Success'
          );
        }
        if (
          response !== undefined &&
          typeof response === 'object' &&
          response.error === 1
        ) {
          this._toaster.errorToastr(response.message, 'Error');
        }
      });
    } else {
      this._toaster.errorToastr(this._constant.enterRequiredField, 'Error');
    }
  }
}
