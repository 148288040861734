<div id="profile-section-languages" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Languages</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add languages button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div class="profile__section__empty" *ngIf="!languageList || languageList.length == 0">
        Click + to add first language.
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="languageList">
        <div class="profile__section__list--item" *ngFor="let item of languageList; index as i;">
          <div *ngIf="item.name != 'NONE' || languageList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div class="col-md-12" role="list item property value">
                <span>Language:</span>
                <span class="fw500 pl05r">{{ item.name }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6" role="list item property value">
                <span>Read:</span>
                <span class="fw500 pl05r">{{ item.read ? 'Yes' : 'No' }}</span>
              </div>
              <div class="col-md-4 col-sm-6" role="list item property value">
                <span>Write:</span>
                <span class="fw500 pl05r">{{ item.write ? 'Yes' : 'No' }}</span>
              </div>
              <div class="col-md-4 col-sm-6" role="list item property value">
                <span>Speak:</span>
                <span class="fw500 pl05r">{{ item.speak ? 'Yes' : 'No' }}</span>
              </div>
              <div class="col-md-4 col-sm-6" role="list item property value">
                <span>Understand:</span>
                <span class="fw500 pl05r">{{ item.understand ? 'Yes' : 'No' }}</span>
              </div>
              <div class="col-md-4 col-sm-6" role="list item property value">
                <span>Peer Review:</span>
                <span class="fw500 pl05r">{{ item.peerReview ? 'Yes' : 'No' }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="item.name != 'NONE' || languageList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.name !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit language button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete language button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Languages</div>
        <div *ngIf="languageModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- LANGUAGE -->
        <div class="profile__form--language">
          <label class="form__label">Language <span class="form__required">*</span></label>
          <select class="form__control" required id="currentItem_toNode_name" name="currentItem_toNode_name"
            [ngModel]="languageModel.name | neo4junescape" (ngModelChange)="languageModel.name = this.escape.it($event); onLanguageChange($event)"
            value="languageModel.name" [disabled]="deleteMode" aria-label="language - required"  
            [ngClass]="{'ng-invalid': isLanguageRequired}">
            <option>Select</option>
            <option>English</option>
            <option>French</option>
            <option>Spanish</option>
            <option>Portuguee</option>
            <option>Cantonese</option>
            <option>Mandarin</option>
            <option>Hindi</option>
            <option>Russian</option>
            <option>Arabic</option>
            <option>Bengali</option>
            <option>Afrikaans</option>
            <option>Akan</option>
            <option>Albanian</option>
            <option>Amharic</option>
            <option>Arabic</option>
            <option>Armenian</option>
            <option>ASL</option>
            <option>Assamese</option>
            <option>Assyrian</option>
            <option>Azerbaijani</option>
            <option>Bahdini</option>
            <option>Bambara</option>
            <option>Bashkir</option>
            <option>Basque</option>
            <option>Belarusian</option>
            <option>Bengali</option>
            <option>Bosnian</option>
            <option>Bravanese</option>
            <option>Bulgarian</option>
            <option>Burmese</option>
            <option>Cambodian</option>
            <option>Cantonese</option>
            <option>Catalan</option>
            <option>Cebuano</option>
            <option>Chaldean</option>
            <option>Chamorro</option>
            <option>Chaozhou</option>
            <option>Chavacano</option>
            <option>Chin</option>
            <option>Chuukese</option>
            <option>Cree</option>
            <option>Croatian</option>
            <option>Czech</option>
            <option>Dakota</option>
            <option>Danish</option>
            <option>Dari</option>
            <option>Dinka</option>
            <option>Dioula</option>
            <option>Dutch</option>
            <option>Dzongkha</option>
            <option>English</option>
            <option>Estonian</option>
            <option>Ewe</option>
            <option>Fante</option>
            <option>Faroese</option>
            <option>Farsi</option>
            <option>Fijian Hindi</option>
            <option>Finnish</option>
            <option>Flemish</option>
            <option>French</option>
            <option>French Canadian</option>
            <option>Frisian</option>
            <option>Fujianese</option>
            <option>Fukienese</option>
            <option>Fula</option>
            <option>Fulani</option>
            <option>Fuzhou</option>
            <option>Ga</option>
            <option>Gaelic</option>
            <option>Galician</option>
            <option>Ganda</option>
            <option>Georgian</option>
            <option>German</option>
            <option>Gorani</option>
            <option>Greek</option>
            <option>Gujarati</option>
            <option>Haitian Creole</option>
            <option>Hakka</option>
            <option>Hassaniyya</option>
            <option>Hausa</option>
            <option>Hebrew</option>
            <option>Hiligaynon</option>
            <option>Hindi</option>
            <option>Hmong</option>
            <option>Hungarian</option>
            <option>Ibanag</option>
            <option>Icelandic</option>
            <option>Igbo</option>
            <option>Ilocano</option>
            <option>Ilonggo</option>
            <option>Indian</option>
            <option>Indonesian</option>
            <option>Inuktitut</option>
            <option>Irish</option>
            <option>Italian</option>
            <option>Jakartanese</option>
            <option>Japanese</option>
            <option>Javanese</option>
            <option>Kanjobal</option>
            <option>Kannada</option>
            <option>Karen</option>
            <option>Kashmiri</option>
            <option>Kazakh</option>
            <option>Khalkha</option>
            <option>Khmer</option>
            <option>Kikuyu</option>
            <option>Kinyarwanda</option>
            <option>Kirundi</option>
            <option>Korean</option>
            <option>Kosovan</option>
            <option>Kotokoli</option>
            <option>Krio</option>
            <option>Kurdish</option>
            <option>Kurmanji</option>
            <option>Kyrgyz</option>
            <option>Lakota</option>
            <option>Laotian</option>
            <option>Latin</option>
            <option>Latvian</option>
            <option>Lingala</option>
            <option>Lithuanian</option>
            <option>Luganda</option>
            <option>Luo</option>
            <option>Lusoga</option>
            <option>Luxembourgeois</option>
            <option>Maay</option>
            <option>Macedonian</option>
            <option>Malagasy</option>
            <option>Malay</option>
            <option>Malayalam</option>
            <option>Maldivian</option>
            <option>Maltese</option>
            <option>Mandarin</option>
            <option>Mandingo</option>
            <option>Mandinka</option>
            <option>Maori</option>
            <option>Marathi</option>
            <option>Marshallese</option>
            <option>Mien</option>
            <option>Mirpuri</option>
            <option>Mixteco</option>
            <option>Moldovan</option>
            <option>Mongolian</option>
            <option>Navajo</option>
            <option>Neapolitan</option>
            <option>Nepali</option>
            <option>Norwegian</option>
            <option>Nuer</option>
            <option>Nyanja</option>
            <option>Ojibaway</option>
            <option>Oriya</option>
            <option>Oromo</option>
            <option>Ossetian</option>
            <option>Pahari</option>
            <option>Pampangan</option>
            <option>Pashto</option>
            <option>Patois</option>
            <option>Pidgin English</option>
            <option>Polish</option>
            <option>Portuguese</option>
            <option>Pothwari</option>
            <option>Pulaar</option>
            <option>Punjabi</option>
            <option>Putian</option>
            <option>Quanxi</option>
            <option>Quechua</option>
            <option>Romani</option>
            <option>Romanian</option>
            <option>Romansch</option>
            <option>Rundi</option>
            <option>Russian</option>
            <option>Samoan</option>
            <option>Sango</option>
            <option>Sanskrit</option>
            <option>Serbian</option>
            <option>Shanghainese</option>
            <option>Shona</option>
            <option>Sichuan</option>
            <option>Sicilian</option>
            <option>Sindhi</option>
            <option>Sinhala</option>
            <option>Sinhalese</option>
            <option>Siswati/Swazi</option>
            <option>Slovak</option>
            <option>Slovene</option>
            <option>Slovenian</option>
            <option>Somali</option>
            <option>Soninke</option>
            <option>Sorani</option>
            <option>Sotho</option>
            <option>Spanish</option>
            <option>Sundanese</option>
            <option>Susu</option>
            <option>Swahili</option>
            <option>Swedish</option>
            <option>Sylhetti</option>
            <option>Tagalog</option>
            <option>Taiwanese</option>
            <option>Tajik</option>
            <option>Tamil</option>
            <option>Telugu</option>
            <option>Thai</option>
            <option>Tibetan</option>
            <option>Tigrinya</option>
            <option>Tongan</option>
            <option>Tshiluba</option>
            <option>Tsonga</option>
            <option>Tswana</option>
            <option>Turkish</option>
            <option>Turkmen</option>
            <option>Uighur</option>
            <option>Ukrainian</option>
            <option>Urdu</option>
            <option>Uzbek</option>
            <option>Venda</option>
            <option>Vietnamese</option>
            <option>Visayan</option>
            <option>Welsh</option>
            <option>Wolof</option>
            <option>Xhosa</option>
            <option>Yao</option>
            <option>Yiddish</option>
            <option>Yoruba</option>
            <option>Yupik</option>
            <option>Zulu</option>
            <option>Others</option>
          </select>
        </div>
        <!-- READ -->
        <div class="profile__form--read">
          <label class="content-verify form__label"><span>Read</span>
            <input name="read" type="checkbox" [(ngModel)]="languageModel.read" [disabled]="deleteMode"
            (change)="onLanguageLevelSelection(languageModel)" aria-label="read - required"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- WRITE -->
        <div class="profile__form--write">
          <label class="content-verify form__label"><span>Write</span>
            <input name="write" type="checkbox" [(ngModel)]="languageModel.write" [disabled]="deleteMode"
            (change)="onLanguageLevelSelection(languageModel)" aria-label="write - required"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- SPEAK -->
        <div class="profile__form--speak">
          <label class="content-verify form__label"><span>Speak</span>
            <input name="speak" type="checkbox" [(ngModel)]="languageModel.speak" [disabled]="deleteMode"
            (change)="onLanguageLevelSelection(languageModel)" aria-label="speak - required"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- UNDERSTAND -->
        <div class="profile__form--understand">
          <label class="content-verify form__label"><span>Understand</span>
            <input name="understand" type="checkbox" [(ngModel)]="languageModel.understand" [disabled]="deleteMode"
            (change)="onLanguageLevelSelection(languageModel)" aria-label="understand - required"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- PEER REVIEW -->
        <div class="profile__form--peer-review">
          <label class="content-verify form__label"><span>Peer Review</span>
            <input name="peerReview" type="checkbox" [(ngModel)]="languageModel.peerReview" [disabled]="deleteMode"
            (change)="onLanguageLevelSelection(languageModel)" aria-label="peer review - required"   />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- REQUIRED MESSAGE -->
        <div class="profile__form--required">
          <label class="form__label"><span class="form__required">*</span> At least one is required</label>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick();" 
            [disabled]= "!enableSaveBtn" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-language" *ngIf="isLanguageRequired">
          <strong>Language is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>