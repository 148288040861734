import { map,take } from 'rxjs/operators';
import { Component, OnInit, ViewChildren, QueryList,ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { interval } from 'rxjs';
import { DataService } from '../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UtilityService } from '../../services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { XmlInfoComponent } from '../../shared/components/dialogs/xml-info/xml-info.component';
import { PdfInfoComponent } from 'src/app/shared/components/dialogs/pdf-info/pdf-info.component';
import * as _ from "lodash";
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  constructor(
    public data: DataService,
    private api: HttpService,
    private util: UtilityService,
    private router: Router,
    public toaster: ToastrManager,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller
  ) {
    this.isMobile = this.data.isMobile;
  }

  panelOpenState = false;

  selectedSettingTab = 1;
  tabs = [
    {
      name: 'CANADIAN COMMON CV',
      key: 1,
      active: true,
      icon: '../assets/images/maple.png',
      status:''
    },
    {
      name: 'PERSONAL CV',
      key: 2,
      active: false,
      icon: '../../assets/images/pdf.png',
      status:''
    },
    {
      name: 'PROFOUND IMPACT PROFILE',
      key: 3,
      active: false,
      icon: '../../../assets/images/icon-pi.png',
      status:''
    },
    {
      name: 'UPLOAD HISTORY',
      key: 4,
      active: false,
      icon: '../assets/images/history.png',
      status:''
    },
  ];

  unverifiedTabs: any = [
    { desc: 'Experience', key: 2 },
    { desc: 'Languages', key: 13 },
    { desc: 'Patents', key: 14 },
    { desc: 'Research', key: 10 },
    { desc: 'Academic Ancestry', key: 20 },
    { desc: 'Mentoring Relation', key: 21 },
  ];
  verifiedTabs: any = [
    { desc: 'Education', key: 5 },
    { desc: 'Certifications', key: 9 },
    { desc: 'Volunteer', key: 17 },
    { desc: 'Skills', key: 12 },
    { desc: 'Publications', key: 7 },
    { desc: 'Awards', key: 3 },
    { desc: 'Project', key: 8 },
  ];

  
  isMobile: boolean;
  uploadFileSel: string;
  files: FileList;
  singleFile: File;
  fileChange = false;
  fileName = '';
  // 10GB bits is base value
  fileLimitSize: any = 100000000000;
  preSignedurl: any;
  today = new Date();
  importedItems: any = [];
  currentCommonCV: any;
  personalCV:any;
  profoundImpactCV:any;
  noHistory = true;
  loading = true;
  uploadCompletionStatus = 'Ready to upload';
  transferCompletionStatus = 'Ready to transfer';
  tempImportFile: any;
  tempImportFileCustom: any;
  tempImportFilePersonalCV: any;
  xmlSelected = false;
  pdfSelected = false;
  docxSelected = false;
  transferIntiated = false;
  tabStatus = 'N/A';
  transferSelected = 'N/A';
  otherClientSelected = 'N/A';
  requestClientSelected='N/A';
  transferincomingRequests:any = [];
  transferoutgoingRequests:any = [];
  transferDataSource: any = [];
  transferDataOtherClient: any = [];
  

  email='';
  regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  submitted = false;

  ngOnInit() {
    this.data.setPageTitle('Import Profile');
    this.tranferData();
    this.transferRequest();
    this.getImportStatus();
  }

  getImportStatus() {
    this.api.getUserProfileImportStatus().subscribe((data) => {
      const response: any = data;
      this.util.logger(response, 'IMPORT STATUS');
      this.importedItems = response.data;
      console.log('IMPORTED ITEMS: ', this.importedItems);
      this.getsortByDate(this.importedItems).slice(0.1).forEach((element) => {
        let currentCommonCV = false;
        let personalCV = false;
        let profoundImpactCV = false;
        this.noHistory = false;
        if (
          element.dataSource === 'CommonCV' &&
          (element.importStatus.basic === false ||
            element.importStatus.experience === false ||
            element.importStatus.education === false ||
            element.importStatus.certificate === false ||
            element.importStatus.languages === false ||
            element.importStatus.publications === false ||
            element.importStatus.research === false ||
            element.importStatus.academinAncestry === false ||
            element.importStatus.mentoringRelation === false ||
            element.importStatus.patents === false ||
            element.importStatus.volunteer === false ||
            element.importStatus.skills === false ||
            element.importStatus.socialMedia === false ||
            element.importStatus.awards === false
            
            )
        ) {
          currentCommonCV = true;
          personalCV = true
          profoundImpactCV = true;
          console.log('CURRENT ELEMENT:', element);
        }
        const importStatus = [];
        // tslint:disable-next-line: max-line-length
        element.importStatus.basic === true
          ? importStatus.push({ section: 'Basic', status: 'Verified', key: 1 })
          : element.importStatus.basic === false
            ? importStatus.push({
              section: 'Basic',
              status: 'Unverified',
              key: 0,
            })
            : this.util.logger('no basic info uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.experience === true
          ? importStatus.push({
            section: 'Experience',
            status: 'Verified',
            key: 1,
          })
          : element.importStatus.experience === false
            ? importStatus.push({
              section: 'Experience',
              status: 'Unverified',
              key: 1,
            })
            : this.util.logger('no experiences uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.education === true
          ? importStatus.push({
            section: 'Education',
            status: 'Verified',
            key: 2,
          })
          : element.importStatus.education === false
            ? importStatus.push({
              section: 'Education',
              status: 'Unverified',
              key: 2,
            })
            : this.util.logger('no education uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.certificate === true
          ? importStatus.push({
            section: 'Certifications',
            status: 'Verified',
            key: 3,
          })
          : element.importStatus.certificate === false
            ? importStatus.push({
              section: 'Certifications',
              status: 'Unverified',
              key: 3,
            })
            : this.util.logger('no certifications uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.skills === true
          ? importStatus.push({
            section: 'Skills',
            status: 'Verified',
            key: 5,
          })
          : element.importStatus.skills === false
            ? importStatus.push({
              section: 'Skills',
              status: 'Unverified',
              key: 5,
            })
            : this.util.logger('no skills uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.languages === true
          ? importStatus.push({
            section: 'Languages',
            status: 'Verified',
            key: 6,
          })
          : element.importStatus.languages === false
            ? importStatus.push({
              section: 'Languages',
              status: 'Unverified',
              key: 6,
            })
            : this.util.logger('no languages uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.publications === true
          ? importStatus.push({
            section: 'Publications',
            status: 'Verified',
            key: 7,
          })
          : element.importStatus.publications === false
            ? importStatus.push({
              section: 'Publications',
              status: 'Unverified',
              key: 7,
            })
            : this.util.logger('no publications uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.research === true
          ? importStatus.push({
            section: 'Research',
            status: 'Verified',
            key: 10,
          })
          : element.importStatus.research === false
            ? importStatus.push({
              section: 'Research',
              status: 'Unverified',
              key: 10,
            })
            : this.util.logger('no research uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.academicAncestry === true
          ? importStatus.push({
            section: 'Academic Ancestry',
            status: 'Verified',
            key: 12,
          })
          : element.importStatus.academicAncestry === false
            ? importStatus.push({
              section: 'Academic Ancestry',
              status: 'Unverified',
              key: 12,
            })
            : this.util.logger('no academic ancestry uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.mentoringRelation === true
          ? importStatus.push({
            section: 'Mentoring Relation',
            status: 'Verified',
            key: 13,
          })
          : element.importStatus.mentoringRelation === false
            ? importStatus.push({
              section: 'Mentoring Relation',
              status: 'Unverified',
              key: 13,
            })
            : this.util.logger('no mentoring relation uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.awards === true
          ? importStatus.push({ section: 'Awards', status: 'Verified', key: 8 })
          : element.importStatus.awards === false
            ? importStatus.push({
              section: 'Awards',
              status: 'Unverified',
              key: 8,
            })
            : this.util.logger('no awards uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.patents === true
          ? importStatus.push({
            section: 'Patents',
            status: 'Verified',
            key: 11,
          })
          : element.importStatus.patents === false
            ? importStatus.push({
              section: 'Patents',
              status: 'Unverified',
              key: 11,
            })
            : this.util.logger('no patents uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.volunteer === true
          ? importStatus.push({
            section: 'Volunteer',
            status: 'Verified',
            key: 4,
          })
          : element.importStatus.volunteer === false
            ? importStatus.push({
              section: 'Volunteer',
              status: 'Unverified',
              key: 4,
            })
            : this.util.logger('no volunteer uploaded', '');
        // tslint:disable-next-line: max-line-length
        element.importStatus.socialMedia === true
          ? importStatus.push({
            section: 'Social Media',
            status: 'Verified',
            key: 14,
          })
          : element.importStatus.socialMedia === false
            ? importStatus.push({
              section: 'Social Media',
              status: 'Unverified',
              key: 14,
            })
            : this.util.logger('no social media uploaded', '');
        // console.log('IMPORT STATUS: ', importStatus);
        element.importStatus = importStatus;
        // console.log('NEW IMPORTED ITEMS: ', element.importStatus);
        const verifiedTabs = [];
        const unverifiedTabs = [];
        element.importStatus.forEach((item) => {
          if (item.status === 'Verified') {
            verifiedTabs.push(item);
          }
          if (item.status === 'Unverified') {
            unverifiedTabs.push(item);
            currentCommonCV = true;
            personalCV = true;
            profoundImpactCV = true
          }
        });
        element.verifiedTabs = verifiedTabs;
        element.unverifiedTabs = unverifiedTabs;
        if (currentCommonCV) {
          this.currentCommonCV = element;
          this.uploadCompletionStatus = 'Complete';
          this.currentCommonCV.dataSource === 'CommonCV' ? this.tabStatus = 'Canadian Common CV' : '';
          console.log('CURRENT COMMON CV: ', this.currentCommonCV);
        }
        if (personalCV) {
          this.personalCV = element;
          this.uploadCompletionStatus = 'Complete';
          this.personalCV.dataSource === 'Custom_CV' ? this.tabStatus = 'Personal CV' : '';
          console.log('CURRENT Custom CV: ', this.personalCV);
        }
        if(profoundImpactCV) {
          this.profoundImpactCV = element;
          if(this.profoundImpactCV.dataSource === 'PI-Profile'){
            this.transferCompletionStatus = 'Completed'
            this.tabStatus = 'Profound Impact Profile'
          }else{
            this.transferCompletionStatus = 'Unavaliable'
          }
          console.log('Current ProfoundImpact CV:', this.profoundImpactCV);
        }
      });
      this.loading = false;
    });
  }

  navigateToProfileTab(tabNumber: number) {
    console.log();
    if (tabNumber !== 0) {
      this.data.currentProfileTab = tabNumber;
    }
    console.log(this.data.currentProfileTab);
    this.router.navigate(['/profile']);
  }

  tabChange(selectedTab) {
    this.selectedSettingTab = selectedTab.key;
    for (const tab of this.tabs) {
      if (tab.key === selectedTab.key) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    }
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  fileChangeEvent(event: any,importType:string) {
    this.uploadFileSel = '';
    this.fileChange = true;
    if (event.target.files.length > 0 && event.target.files.length < this.fileLimitSize) {
      const file = event.target.files[0];
      this.data.logger(typeof file, 'TYPE OF FILE');
      this.data.logger(file, 'FILE');
      this.files = file;
      this.singleFile = file;
      if(importType === 'commonCV'){
        this.tempImportFile = file;
      }else if (importType === 'personalCV'){
        this.tempImportFilePersonalCV = file;
      }
      const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
      this.fileName = window.btoa(new Date().getTime() + '_upload.' + ext);
      this.uploadFileSel = file;
    }
  }
  

  openXMLModal() {
    console.log('OPENING XML MODAL');
    const dialogRef = this.dialog.open(XmlInfoComponent, {
      width: '80%',
    });
  }

  openPDFModal() {
    console.log('OPENING PDF MODAL');
    const dialogRef = this.dialog.open(PdfInfoComponent, {
      width: '80%',
    });
  }

  upload(CVType: string) {
    if (this.fileName) {
      this.uploadCompletionStatus = 'Uploading';
      console.log('FILENAME', this.fileName);
  
      this.api.uploadFile(this.fileName, CVType === 'CommonCV' ? 'common-cv': (CVType === 'CustomCV') ? 'custom-cv': 'error').subscribe(
        (data) => {
          const res: any = data;
          this.data.logger(data, 'FILE UPLOAD');
          this.preSignedurl = res.data;
          this.api
            .uploadImageSignedUrl(this.singleFile, this.preSignedurl)
            .subscribe((response) => {
              this.uploadCompletionStatus = 'Extracting';
              this.linkFile(CVType);
            });
        },
        (error) => {
          this.uploadCompletionStatus = 'Failed';
          this.toaster.errorToastr(
            'FILE UPLOAD ERROR (upload), we will look into it and get back to you',
            'ERROR'
          );
          this.data.logger(error, 'FILE UPLOAD ERROR');
        }
      );
    }
  }

  linkFile(CVType: string) {
    let type;
    if (this.singleFile.name.includes('.pdf')) {
      type = 'pdf';
    }
    if (this.singleFile.name.includes('.xml')) {
      type = 'xml';
    }
    if (this.singleFile.name.includes('.doc')) {
      type = 'doc';
    }
    // tslint:disable-next-line: max-line-length
    // Common CV api
    if(CVType === "CommonCV"){
      this.api
        .linkFile(
          this.fileName,
          this.singleFile.name,
          this.singleFile.size,
          this.singleFile.lastModified,
          type
        )
        .subscribe(
          (linkResponse) => {
            this.getImportStatus();
            console.log('CommonCV LINK RESPONSE', linkResponse);
            this.toaster.successToastr('File uploaded successfully for CommonCV', 'SUCCESS');
          },
          (error) => {
            this.uploadCompletionStatus = 'Failed';
            console.log(error);
            this.uploadFileHandleError(error)
          }
        );
    }else if (CVType === "CustomCV"){
      this.api
        .linkCustomFile(
          this.fileName,
          this.singleFile.name,
          this.singleFile.size,
          this.singleFile.lastModified,
          type
        )
        .subscribe(
          (linkResponse) => {
            this.getImportStatus();
            console.log('CustomCV LINK RESPONSE', linkResponse);
            this.toaster.successToastr('File uploaded successfully for CustomCV', 'SUCCESS');
          },
          (error) => {
            this.uploadCompletionStatus = 'Failed';
            console.log(error);
            this.uploadFileHandleError(error)
          }
        );
    }
  }
  tranferData(){
    this.api.transferData().subscribe(
      (data) => {
        this.transferDataSource = data['data']
        this.transferDataOtherClient = data['otherClients']
        console.log("🚀 ~ file: upload.component.ts ~ line 536 ~ UploadComponent ~ tranferData ~ transferDataOtherClient", JSON.stringify(this.transferDataOtherClient))
        
      },
      (error) => {
        this.transferCompletionStatus = 'Failed';
        console.log(JSON.parse(JSON.stringify(error)));
        this.uploadFileHandleError(error)
      }
    )
  }
  transferRequest(){
    this.api.transferRequestData().subscribe(
      (data) => {
      this.transferincomingRequests = this.getsortByDate(data['data']['incomingRequests'])
      console.log("🚀 ~ file: upload.component.ts ~ line 559 ~ UploadComponent ~ transferRequest ~ this.transferincomingRequests", JSON.stringify(this.transferincomingRequests))
      this.transferoutgoingRequests = this.getsortByDate(data['data']['outgoingRequests'])  
      console.log("🚀 ~ file: upload.component.ts ~ line 546 ~ UploadComponent ~ transferRequest ~ data", JSON.stringify(this.transferoutgoingRequests))
      },
      (error) =>{
        this.transferCompletionStatus = 'Failed';
        console.log(JSON.parse(JSON.stringify(error)));
        this.uploadFileHandleError(error)
      }
    )
  }
  transferSendRequest(email:any,source:any){
    this.api.transferSendRequest(
      email,
      source
    ).subscribe(
      (data) => {
      console.log("🚀 ~ file: upload.component.ts ~ line 565 ~ UploadComponent ~ transferSendRequest ~ data", JSON.stringify(data))
      this.toaster.successToastr(data['message'], 'SUCCESS');
      this.transferRequest()
      },
      (error) =>{
        this.transferCompletionStatus = 'Failed';
        console.log(JSON.parse(JSON.stringify(error)));
        this.uploadFileHandleError(error)
      }
    )
  }

  transferUpdateRequest(requestUuid: any, status: any){
    this.api.transferUpdateRequest(
      requestUuid,
      status
    ).subscribe(
      (data) => {
        this.toaster.successToastr(data['message'], 'SUCCESS');
        console.log("🚀 ~ file: upload.component.ts ~ line 581 ~ UploadComponent ~ transferUpdateRequest ~ data", JSON.stringify(data))
        this.transferRequest()
        },
        (error) =>{
          this.transferCompletionStatus = 'Failed';
          console.log(JSON.parse(JSON.stringify(error)));
          this.uploadFileHandleError(error)
        }
    )
  }

  accountLocator(email:any,otherClient:any){
    console.log("🚀 ~ file: upload.component.ts ~ line 542 ~ UploadComponent ~ accountLocator ~ source", otherClient.source)
    console.log("🚀 ~ file: upload.component.ts ~ line 540 ~ UploadComponent ~ accountLocator ~ email", email)
    
    if(email.match(this.regexEmail) && otherClient.source !== undefined){
      if(this.transferoutgoingRequests.length < 1){
        this.transferSendRequest(email,otherClient.source)
      }else if(this.transferoutgoingRequests[0].status !== 'Pending'){
        this.transferSendRequest(email,otherClient.source)
      }else{
        this.toaster.errorToastr('Please Approve the first Request','ERROR');
      }
    }else{
      this.toaster.errorToastr('Invalid Request Email','ERROR');
    }
  }

  accountVerifier(incomingRequests:any,status:any){
    this.transferRequest()
    incomingRequests !== 'N/A' ?
      this.transferUpdateRequest(incomingRequests.uuid,status)
        : this.toaster.errorToastr('Please Select An Email','ERROR');
  }

  transferAccount(transferSelected:any){
    this.loading = true;
    transferSelected = this.transferSelected
    this.transferIntiated = true;
    this.transferCompletionStatus = 'InProcess';
    this.api.transferProfile(
      transferSelected.source,
      transferSelected.personUuid
    )
    .subscribe(
      (linkResponse) => {
        this.getImportStatus();
        console.log('ProfoundImpact LINK RESPONSE', linkResponse);
        this.toaster.successToastr('Transfer Account successfully', 'SUCCESS');
        // setTimeout(() => {
        //   this.onClick('#PI-Import-Anchor')
        // }, 5000);
        
      },
      (error) => {
        this.transferCompletionStatus = 'Failed';
        this.loading = false;
        console.log(JSON.parse(JSON.stringify(error)));
        this.uploadFileHandleError(error)
      }
    )
    
  }
  
  onClick(elementId: string): void { 
    document.querySelector(elementId).scrollIntoView({ 
      behavior: 'smooth' 
    });
  }

  // upload File response code error handler
  uploadFileHandleError(err:HttpErrorResponse){
    console.log("uploadFileHandleError" + JSON.stringify(err.error));
    if(err.status === 504){
      this.toaster.errorToastr('Please fix the following: </br>Experience is required</br>Education is required</br>Language is required</br>', '', { closeButton: true, timeOut: 40000, progressBar: true, enableHTML: true });;
    }else if(err.status === 400){
      this.toaster.errorToastr('Something Went Wrong', '', { closeButton: true, timeOut: 40000, progressBar: true, enableHTML: true });;
    }else{
      this.toaster.errorToastr(
        'File can not be parsed (CustomCV), we will look into it and get back to you',
        'ERROR'
      );
    }
  }

  // Sort Uploaded Files by date
  getsortByDate(importData:any){
    return importData.sort((a, b) => {
      return <any>new Date(b.createdDateTime) - <any>new Date(a.createdDateTime);
    });
  }

}