<div id="profile-section-mentoring" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Mentoring Relation</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add mentoring relation button"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!mentoringList || mentoringList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your mentoring relation or click the [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()" aria-label="verify none button"
              role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="mentoringList">
        <div class="profile__section__list--item" *ngFor="let item of mentoringList; index as i;">
          <div *ngIf="item.firstName != 'NONE' || mentoringList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.verification == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div class="row">
              <div *ngIf="item.role && item.firstName !== 'NONE'" class="col-md-6" role="list item property value">
                <span>Role:</span>
                <span class="fw500 pl05r">{{ item.role }}</span>
              </div>
              <div *ngIf="item.email && item.firstName !== 'NONE'" class="col-md-6" role="list item property value">
                <span style="font-weight: 600">Email:</span>
                <span class="fw500 pl05r">{{ item.email }}</span>
              </div>
              <div class="col-12" role="list item property value">
                <span *ngIf="item.firstName !== 'NONE'">Name:</span>
                <span *ngIf="item.firstName === 'NONE'">Mentoring Relation:</span>
                <span class="fw500 pl05r">{{ item.firstName }}</span>
                <span *ngIf="item.lastName !== 'NONE'" class="fw500 pl05r">{{ item.lastName }}</span>
              </div>
            </div>
            <div class="row" *ngIf="item.firstName !== 'NONE' && (item.supervisionLevel || item.coSupervisor || item.title)">
              <div *ngIf="item.supervisionLevel" class="col-md-6" role="list item property value">
                <span>Supervision Level:</span>
                <span class="fw500 pl05r">{{ item.supervisionLevel }}</span>
              </div>
              <div *ngIf="item.coSupervisor" class="col-md-6" role="list item property value">
                <span>Co-Supervisor:</span>
                <span class="fw500 pl05r">{{ item.coSupervisor }}</span>
              </div>
              <div *ngIf="item.title" class="col-12" role="list item property value">
                <span>Title:</span>
                <span class="fw500 pl05r">{{ item.title }}</span>
              </div>
            </div>
            <div class="row" *ngIf="item.firstName !== 'NONE'">
              <div *ngIf="item.status && item.status != 'Select'" class="col-md-6" role="list item property value">
                <span>Status:</span>
                <span class="fw500 pl05r">{{ item.status }}</span>
              </div>
              <div *ngIf="item.place" class="col-md-6" role="list item property value">
                <span>Place:</span>
                <span class="fw500 pl05r">{{ item.place }}</span>
              </div>
            </div>
            <div class="row" *ngIf="item.firstName !== 'NONE'">
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.startDateFlag">
                  <div *ngIf="item.startDateFlag.year">
                    <span>Start Date:</span>
                    <span class="fw500 pl05r">
                      {{ item.startDateFlag.day ? item.startDate.getDate() : '' }}
                      {{ item.startDateFlag.month ? months[+item.startDateFlag.month - 1] : '' }}
                      {{ item.startDateFlag.year ? item.startDateFlag.year : '' }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6" role="list item property value">
                <div *ngIf="item.endDateFlag">
                  <div *ngIf="item.endDateFlag.year">
                    <span>End Date:</span>
                    <span class="fw500 pl05r">
                      {{ item.endDateFlag.day ? item.endDate.getDate() : '' }}
                      {{ item.endDateFlag.month ? months[+item.endDateFlag.month - 1] : '' }}
                      {{ item.endDateFlag.year ? item.endDateFlag.year : '' }}
                    </span>
                  </div>
                </div>
                <div *ngIf="item.isCurrent">
                  <div>
                    <span>End Date:</span>
                    <span class="fw500 pl05r">Current Mentorship</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.firstName != 'NONE' || mentoringList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.firstName !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit mentoring relation button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete mentoring relation button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.verification == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Mentoring Relation</div>
        <div *ngIf="mentoringModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- CURRENT -->
        <div class="profile__form--current">
          <label class="content-verify form__label"><span>Current Mentorship</span>
            <input name="current" type="checkbox" [(ngModel)]="currentlyWorking" (click)="toggleCurrentlyWorking()"
              [disabled]="deleteMode" aria-label="current mentorship" />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- START YEAR -->
        <div class="profile__form--start-year">
          <label class="form__label">Start Year <span class="form__required">*</span></label>
          <select class="form__control" #startYear (change)="onDateChange('startDate', 'year', startYear.value)" required
            [(ngModel)]="mentoringModel.startDate.year" name="certif_year" [disabled]="deleteMode"
            aria-label="start year - required"
            [ngClass]="{'ng-invalid': isStartYearRequired}">
            <option value="">Select Year</option>
            <option *ngFor="let item of yearValue" [selected]="mentoringModel.startDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- START MONTH -->
        <div class="profile__form--start-month" *ngIf="mentoringModel.startDate.year">
          <label class="form__label">Start Month</label>
          <select class="form__control" (change)="onDateChange('startDate', 'month')"
            [(ngModel)]="mentoringModel.startDate.month" name="certif_month" [disabled]="deleteMode"
            aria-label="start month">
            <option value="">Select Month</option>
            <option *ngFor="let item of monthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- START DAY -->
        <div class="profile__form--start-day" *ngIf="mentoringModel.startDate.year && mentoringModel.startDate.month">
          <label class="form__label">Start Day</label>
          <select class="form__control" (change)="onDateChange('startDate', 'day')"
            [(ngModel)]="mentoringModel.startDate.day" name="certif_day" [disabled]="deleteMode" aria-label="start day">
            <option value="">Select Day</option>
            <option *ngFor="let item of startDayValue" [selected]="mentoringModel.startDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END YEAR -->
        <div class="profile__form--end-year" *ngIf="!currentlyWorking || mentoringModel.currentlyWorking === 'false'">
          <label class="form__label">End Year <span class="form__required">*</span></label>
          <select class="form__control" (change)="onDateChange('endDate', 'year')" required
            [(ngModel)]="mentoringModel.endDate.year" name="certif_year2" [disabled]="deleteMode"
            aria-label="end year - required">
            <option value="">Select Year</option>
            <option *ngFor="let item of endYearValue" [selected]="mentoringModel.endDate.year == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- END MONTH -->
        <div class="profile__form--end-month"
          *ngIf="(!currentlyWorking || mentoringModel.currentlyWorking === 'false') && mentoringModel.endDate.year">
          <label class="form__label">End Month</label>
          <select class="form__control" (change)="onDateChange('endDate', 'month')"
            [(ngModel)]="mentoringModel.endDate.month" name="certif_month2" [disabled]="deleteMode"
            aria-label="end month">
            <option value="">Select Month</option>
            <option *ngFor="let item of endMonthValue" [value]="item.value">
              {{ months[item.value - 1] }}
            </option>
          </select>
        </div>
        <!-- END DAY -->
        <div class="profile__form--end-day"
          *ngIf="(!currentlyWorking || mentoringModel.currentlyWorking === 'false') && mentoringModel.endDate.year && mentoringModel.endDate.month">
          <label class="form__label">End Day</label>
          <select class="form__control" (change)="onDateChange('endDate', 'day')"
            [(ngModel)]="mentoringModel.endDate.day" name="certif_day2" [disabled]="deleteMode" aria-label="end day">
            <option value="">Select Day</option>
            <option *ngFor="let item of endDayValue" [selected]="mentoringModel.endDate.day == item.value">
              {{ item.value }}
            </option>
          </select>
        </div>
        <!-- ROLE -->
        <div class="profile__form--role">
          <label class="form__label">Role <span class="form__required">*</span></label>
          <select class="form__control" required id="currentItem_edge_role" name="currentItem_edge_role"
            [(ngModel)]="mentoringModel.role" aria-placeholder="position" [disabled]="deleteMode"
            (ngModelChange)="onRoleChange($event)"
            aria-label="role - required"
            [ngClass]="{'ng-invalid': isRoleRequired}">
            <option value="Select">Select</option>
            <option value="Mentor">
              <span *ngIf="currentlyWorking">I am a </span><span *ngIf="!currentlyWorking">I was a </span>Mentor
            </option>
            <option value="Mentee">
              <span *ngIf="currentlyWorking">I am a </span><span *ngIf="!currentlyWorking">I was a </span>Mentee
            </option>
          </select>
        </div>
        <!-- EMAIL -->
        <div class="profile__form--email">
          <label class="form__label">Email</label>
          <input class="form__control" id="currentItem_toNode_email" type="email" name="currentItem_toNode_email" email
            [(ngModel)]="mentoringModel.email" (selectItem)="autofill($event)" [ngbTypeahead]="usersSuggestions"
            placeholder="Ex. jd@email.com" #toNode_email="ngModel" [resultFormatter]="resultFormatter"
            (ngModelChange)="mentoringModel.email = this.escape.it($event); checkEmail(); getUserSuggestions()"
            [disabled]="deleteMode || personDisabled || !mentoringModel.canEditPerson" aria-label="email" />
        </div>
        <!-- FIRST NAME -->
        <div class="profile__form--fname">
          <label class="form__label">First Name <span class="form__required">*</span></label>
          <input class="form__control" required id="currentItem_toNode_firstName" name="currentItem_toNode_firstName"
            [(ngModel)]="mentoringModel.firstName" (selectItem)="autofill($event)" placeholder="Ex. John"
            [ngbTypeahead]="usersSuggestions" aria-label="first name - required" #firstName="ngModel"
            [resultFormatter]="resultFormatter"
            (ngModelChange)="mentoringModel.firstName = this.escape.it($event); getUserSuggestions()"
            [disabled]="deleteMode || personDisabled || !mentoringModel.canEditPerson" />
        </div>
        <!-- LAST NAME -->
        <div class="profile__form--lname">
          <label class="form__label">Last Name</label>
          <input class="form__control" id="currentItem_toNode_lastName" name="currentItem_toNode_lastName"
            [(ngModel)]="mentoringModel.lastName" (selectItem)="autofill($event)" [ngbTypeahead]="usersSuggestions"
            [resultFormatter]="resultFormatter"
            placeholder="Ex. Doe" (ngModelChange)="mentoringModel.lastName = this.escape.it($event); getUserSuggestions()"
            [disabled]="deleteMode || personDisabled || !mentoringModel.canEditPerson"
            aria-label="last name" />
        </div>
        <!-- CLEAR -->
        <div class="profile__form--clear">
          <button type="button" class="button button--dark-grey" (click)="clear()" aria-label="clear button"
            role="button">
            Clear
          </button>
        </div>
        <!-- SUPERVISION LEVEL -->
        <div class="profile__form--supervision-level">
          <label class="form__label">Supervision Level</label>
          <input class="form__control" id="currentItem_edge_supervisionLevel" name="currentItem_edge_supervisionLevel"
            [(ngModel)]="mentoringModel.supervisionLevel" [disabled]="deleteMode"
            [ngbTypeahead]="supervisionLevelSuggestions" placeholder="Ex. Principal Supervisor"
            (ngModelChange)="mentoringModel.supervisionLevel = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="supervision level" />
        </div>
        <!-- CO-SUPERVISOR -->
        <div class="profile__form--co-supervisor">
          <label class="form__label">Co-Supervisor</label>
          <input class="form__control" id="currentItem_toNode_coSupervisor" name="currentItem_toNode_coSupervisor"
            [ngModel]="mentoringModel.coSupervisor" (ngModelChange)="mentoringModel.coSupervisor = this.escape.it($event)"
            [disabled]="deleteMode" aria-label="co-supervisor" placeholder="Ex. Jane Doe" />
        </div>
        <!-- TITLE -->
        <div class="profile__form--title">
          <label class="form__label">Title</label>
          <input class="form__control" id="currentItem_toNode_title" name="currentItem_toNode_title"
            [ngModel]="mentoringModel.title" [ngbTypeahead]="titleSuggestions"
            placeholder="Ex. Cryptocurrencies and Decentralized Applications" aria-label="title"
            (ngModelChange)="mentoringModel.title = this.escape.it($event)" [disabled]="deleteMode" />
        </div>
        <!-- STATUS -->
        <div class="profile__form--status">
          <label class="form__label">Status</label>
          <select class="form__control" id="currentItem_edge_status" name="currentItem_edge_status"
            [(ngModel)]="mentoringModel.status" [disabled]="deleteMode" aria-label="status">
            <option value="Select">Select</option>
            <option *ngFor="let status of statuses" [value]="status">
              {{ status }}
            </option>
          </select>
        </div>
        <!-- PLACE -->
        <div class="profile__form--place">
          <label class="form__label">Place</label>
          <input class="form__control" id="currentItem_toNode_place" name="currentItem_toNode_place"
            [ngModel]="mentoringModel.place" [ngbTypeahead]="placeSuggestions"
            placeholder="Ex. University of Waterloo, CINVESTAV, Mexico"
            (ngModelChange)="mentoringModel.place = this.escape.it($event)" [disabled]="deleteMode" aria-label="place" />
        </div>
        <!-- IMPACT STATEMENT -->
        <div class="profile__form--impact-statement">
          <label class="form__label">Impact Statement</label>
          <textarea class="form__control impact-text-area" name="story" [(ngModel)]="mentoringModel.story" type="text"
            placeholder="Ex. The Impact Statement about your mentor/mentoree experience" autosize [minRows]="5"
            [maxRows]="10" [disabled]="deleteMode" aria-label="impact statement"></textarea>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && mentoringModel.dataSource === 'User') ||
                (thisForm.form.invalid && mentoringModel.dataSource !== 'User')" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <div class="row tac profile__form--error-start-year" *ngIf="isStartYearRequired">
          <strong>Start Year is required</strong>
        </div>
        <div class="row tac profile__form--error-role" *ngIf="isRoleRequired">
          <strong>Role is required</strong>
        </div>
        <div class="row tac profile__form--error-first-name" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
          <strong *ngIf="firstName.errors.required">First Name is required</strong>
        </div>
      </form>
    </div>
  </div>
</div>