import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appWebServiceUrls } from '../../shared/utilities/app-webservice-urls';
import { NodeRef, ServiceResponseMessage } from '../../shared/utilities/app-framework';
import {
  UserProfile,
  UserProfileMessage,
} from '../../shared/utilities/user-profile.viewmodel';
import { environment } from '../../../environments/environment';
import { SiteConstants } from '../../services/site-constants.service';
import {
  Person,
  Location,
  Organization,
  Award,
  University,
  Publication,
  Project,
  Certificate,
  AreaOfResearch,
  Degree,
  Skill,
  Language,
  Patent,
  SocialMedia,
} from '../../shared/utilities/app-db-nodes';
import {
  PeriodEdge,
  EmploymentEdge,
  ObtainedDateTimeEdge,
  EducationEdge,
  RegularEdge,
  YearsOfExperienceEdge,
  LanguageEdge,
  SocialMediaEdges,
  VolunteerEdge,
  AncestryEdge,
  MentoringEdge,
} from '../../shared/utilities/app-db-edges';

@Injectable()
export class UserProfileService {
  getToken = '';
  root: string;

  constructor(
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _constant: SiteConstants
  ) {
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
  }

  getLoginToken() {
    if (this.getToken !== '') {
      return this.getToken;
    }
  }

  doSearch(jwtToken: string): Observable<UserProfileMessage> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    });
    if (jwtToken !== '') {
      this.getToken = jwtToken;
    }
    const apiUrl = `${this.root}/getUserProfile`;
    return this.http.get<UserProfileMessage>(apiUrl, { headers });
  }

  doSearchProfile(jwtToken: string): Observable<UserProfileMessage> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    });
    if (jwtToken !== '') {
      this.getToken = jwtToken;
    }
    const apiUrl = `${this.root}/getUserProfile`;
    return this.http.get<UserProfileMessage>(apiUrl, { headers });
  }

  createNew(
    cognitoUuid: string,
    cognitoUserName: string,
    email: string
  ): UserProfile {
    return {
      person: {
        cognitoUuid: cognitoUuid,
        password: '',
        cognitoUserName: cognitoUserName,
        uuid: cognitoUserName,
        email: email,
        title: '',
        firstName: '',
        lastName: '',
        displayName: '',
        bio: '',
        bipoc: '',
        lgbt: '',
        logoUrl: '',
        speciality: '',
        currentStatus: '',
        createdDateTime: null,
        modifiedDateTime: null,
        publicationCount: 0,
        awardCount: 0,
      },
      work_in_Organization: [],
      unverified_Work_in_Organization: [],
      volunteer_in_Organization: [],
      unverified_Volunteer_in_Organization: [],
      academic_ancestry: [],
      unverified_Academic_ancestry: [],
      mentoring_relation: [],
      unverified_Mentoring_relation: [],
      earns_Award: [],
      unverified_Earns_Award: [],
      study_in_University: [],
      unverified_Study_in_University: [],
      wrote_Publication: [],
      unverified_Wrote_Publication: [],
      work_in_Project: [],
      unverified_Work_in_Project: [],
      obtained_Certificate: [],
      unverified_Obtained_Certificate: [],
      focus_on_AreaOfResearch: [],
      unverified_Focus_on_AreaOfResearch: [],
      specialized_in_Skill: [],
      unverified_Specialized_in_Skill: [],
      speaks_Language: [],
      unverified_Speaks_Language: [],
      have_Patent: [],
      unverified_Have_Patent: [],
      connect_to_SocialMedia: [],
      unverified_Connect_to_SocialMedia: [],
    };
  }

  assign(
    person: Person,
    work_in_Organization: {
      edge: EmploymentEdge;
      toNodeRef: NodeRef;
      toNode: Organization;
    }[],
    volunteer_in_Organization: {
      edge: VolunteerEdge;
      toNodeRef: NodeRef;
      toNode: Organization;
    }[],
    earns_Award: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Award;
    }[],
    academic_ancestry: {
      edge: AncestryEdge;
      toNodeRef: NodeRef;
      toNode: Person;
    }[],
    mentoring_relation: {
      edge: MentoringEdge;
      toNodeRef: NodeRef;
      toNode: Person;
    }[],
    study_in_University: {
      edge: EducationEdge;
      toNodeRef: NodeRef;
      toNode: University;
    }[],
    wrote_Publication: {
      edge: RegularEdge;
      toNodeRef: NodeRef;
      toNode: Publication;
    }[],
    work_in_Project: {
      edge: PeriodEdge;
      toNodeRef: NodeRef;
      toNode: Project;
    }[],
    obtained_Certificate: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Certificate;
    }[],
    focus_on_AreaOfResearch: {
      edge: YearsOfExperienceEdge;
      toNodeRef: NodeRef;
      toNode: AreaOfResearch;
    }[],
    specialized_in_Skill: {
      edge: YearsOfExperienceEdge;
      toNodeRef: NodeRef;
      toNode: Skill;
    }[],
    speaks_Language: {
      edge: LanguageEdge;
      toNodeRef: NodeRef;
      toNode: Language;
    }[],
    have_Patent: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Patent;
    }[],
    connect_to_SocialMedia: {
      edge: SocialMediaEdges;
      toNodeRef: NodeRef;
      toNode: SocialMedia;
    }[],
    unverified_Work_in_Organization?: {
      edge: EmploymentEdge;
      toNodeRef: NodeRef;
      toNode: Organization;
    }[],
    unverified_Volunteer_in_Organization?: {
      edge: VolunteerEdge;
      toNodeRef: NodeRef;
      toNode: Organization;
    }[],
    unverified_Earns_Award?: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Award;
    }[],
    unverified_Academic_ancestry?: {
      edge: AncestryEdge;
      toNodeRef: NodeRef;
      toNode: Person;
    }[],
    unverified_Mentoring_relation?: {
      edge: MentoringEdge;
      toNodeRef: NodeRef;
      toNode: Person;
    }[],
    unverified_Study_in_University?: {
      edge: EducationEdge;
      toNodeRef: NodeRef;
      toNode: University;
    }[],
    unverified_Wrote_Publication?: {
      edge: RegularEdge;
      toNodeRef: NodeRef;
      toNode: Publication;
    }[],
    unverified_Work_in_Project?: {
      edge: PeriodEdge;
      toNodeRef: NodeRef;
      toNode: Project;
    }[],
    unverified_Obtained_Certificate?: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Certificate;
    }[],
    unverified_Focus_on_AreaOfResearch?: {
      edge: YearsOfExperienceEdge;
      toNodeRef: NodeRef;
      toNode: AreaOfResearch;
    }[],
    unverified_Specialized_in_Skill?: {
      edge: YearsOfExperienceEdge;
      toNodeRef: NodeRef;
      toNode: Skill;
    }[],
    unverified_Speaks_Language?: {
      edge: LanguageEdge;
      toNodeRef: NodeRef;
      toNode: Language;
    }[],
    unverified_Have_Patent?: {
      edge: ObtainedDateTimeEdge;
      toNodeRef: NodeRef;
      toNode: Patent;
    }[],
    unverified_Connect_to_SocialMedia?: {
      edge: SocialMediaEdges;
      toNodeRef: NodeRef;
      toNode: SocialMedia;
    }[],
    importBasic?: any
  ): UserProfile {
    const vm = {
      person: person,
      work_in_Organization: work_in_Organization,
      volunteer_in_Organization: volunteer_in_Organization,
      earns_Award: earns_Award,
      study_in_University: study_in_University,
      wrote_Publication: wrote_Publication,
      academic_ancestry: academic_ancestry,
      mentoring_relation: mentoring_relation,
      work_in_Project: work_in_Project,
      obtained_Certificate: obtained_Certificate,
      focus_on_AreaOfResearch: focus_on_AreaOfResearch,
      specialized_in_Skill: specialized_in_Skill,
      speaks_Language: speaks_Language,
      have_Patent: have_Patent,
      connect_to_SocialMedia: connect_to_SocialMedia,
      unverified_Work_in_Organization: unverified_Work_in_Organization,
      unverified_Volunteer_in_Organization: unverified_Volunteer_in_Organization,
      unverified_Earns_Award: unverified_Earns_Award,
      unverified_Study_in_University: unverified_Study_in_University,
      unverified_Wrote_Publication: unverified_Wrote_Publication,
      unverified_Academic_ancestry: unverified_Academic_ancestry,
      unverified_Mentoring_relation: unverified_Mentoring_relation,
      unverified_Work_in_Project: unverified_Work_in_Project,
      unverified_Obtained_Certificate: unverified_Obtained_Certificate,
      unverified_Focus_on_AreaOfResearch: unverified_Focus_on_AreaOfResearch,
      unverified_Specialized_in_Skill: unverified_Specialized_in_Skill,
      unverified_Speaks_Language: unverified_Speaks_Language,
      unverified_Have_Patent: unverified_Have_Patent,
      unverified_Connect_to_SocialMedia: unverified_Connect_to_SocialMedia,
      importBasic: importBasic
    };

    return vm;
  }
}
