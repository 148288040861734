import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SiteConstants } from './site-constants.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SiteApiConstants {

    constructor(
        private http: HttpClient,
        public siteConstant: SiteConstants
    ) {}

    public serverUrl = 'http://192.168.3.36:8085/api/admin/';
    public auth: string;
    public authenticated: boolean;
    public isAuthenticated(): boolean { return this.authenticated; }
    public setAuthenticated(state: boolean) { this.authenticated = state; }
    getHeaders(jwtToken: string) {
        return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` });
    }

    getLoginToken() {
        const savedToken = atob(sessionStorage.getItem('loginToken'));
        if (savedToken != null) {
            return savedToken;
        }
    }
    clearLoginToken() {
        sessionStorage.setItem('loginToken', '');
        return true;
    }
    setLoginToken(authToken: any, userData: any) {
        let currentUserData: any = '';
        currentUserData = window.btoa((authToken) + '|' + window.btoa(userData.id));
        sessionStorage.setItem('loginToken', (currentUserData));
    }

    createLogin(postBody: any) {
        const loginURL = this.serverUrl + 'admin-login';
        const body = {};
        body['email'] = postBody.email;
        body['password'] = postBody.password;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(loginURL, postBody, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        return throwError(err.error);
    }
}
