<div title="event call to action" class="call-to-action">
    <div class="section-1">
        <div style="padding: 5px; border-radius: 9px; background-color: white; width: 35rem; position: relative; margin-left: 5px">
            <img src="../../../../../assets/images/wewdc.png" alt="women empowering" style="max-height: 9.5rem; width: auto">
            <button class="visit-group-button" (click)="goToGroup()">More<br>Information</button>
        </div>
    </div>
    <div class="column ai-c section-2">
        <div class="cta-heading" style="width: 100%">LET'S ALL CHOOSE TO CHALLENGE</div>
        <div class="cta-subheading" style="width: 100%">#ChooseToChallenge #IWD2021</div>
    </div>
    <div class="section-3">
        <a href="https://www.kwcf.ca/wct-iwd" target="_blank">
            <button class="donate-button">KWCF Fund for Gender Equality<br>Donate Now</button>
        </a>
    </div>
</div>
