import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstagramFeedComponent implements OnInit, OnDestroy {
  data: any = [];
  instaFeedRefresh: any;
  bucket = environment.privateBucket;
  constructor(private http: HttpClient, private httpService: HttpService) {}

  ngOnInit() {
    this.getInstaFeed();
    this.instaFeedRefresh = setInterval(this.getInstaFeed, 300000);
  }

  ngOnDestroy() {
    this.stop();
  }

  stop() {
    clearInterval(this.instaFeedRefresh);
  }

  // getInstaFeed = () => {
  //   this.http.get(`${environment.instagramFeedBucket}`).subscribe((res) => {
  //     this.data = res["data"]
  //   })
  // }

  getInstaFeed() {
    this.httpService.S3BucketOperationsPublic('getObject', 'instagram.json', this.bucket, 'feeds').subscribe((response: any) => {
      if (response.data) {
         const instaFeed = JSON.parse(response.data);
         this.data = instaFeed.data;
         console.log('Insta Feed', this.data);
        // this.httpService.getJSONDownloadSignedUrl(response.data).subscribe(res => {
        //   this.data = res["data"]
        // })
      }
    });
  }
}
