<div id="image-preview-page">
  <div aria-label="image preview dialog" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="15px" role="region">
    <div class="inputWrapper fileBtnWrapper">
      <p class="card--h5-small" [ngStyle]="{'color': data.accessibilityMode ? 'white' : 'rgb(163, 163, 163)' }">
        SELECT FILE
      </p>
      <input class="fileInput ca" id="image" type="file" (change)="fileChangeEvent($event)" accept="image/*" required
        aria-label="file select input" />
    </div>
    <button type="button" class="button button--dark-grey saveBtn" (click)="onSubmit()" aria-label="save file button"
      role="button">
      SAVE
    </button>
  </div>
  <br />
  <div class="image-preview">
    <div fxLayout="row" fxLayoutAlign="center">
      <app-image-cropper [imageBase64]="imageOnLoad" [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true" [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3"
        [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
        [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
        aria-label="image cropper">
      </app-image-cropper>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5px">
      <div class="button-container">
        <button type="button" mat-icon-button (click)="rotateLeft()" title="rotate left" aria-label="rotate left button"
          role="button">
          <mat-icon>rotate_left</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="rotateRight()" title="rotate right" aria-label="rotate right button"
          role="button">
          <mat-icon>rotate_right</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="flipHorizontal()" title="flip-horizontal" aria-label="flip horizontal button"
          role="button">
          <mat-icon>flip_to_back</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="flipVertical()" title="flip-vertical" aria-label="flip vertical button"
          role="button">
          <mat-icon>flip_to_front</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="toggleContainWithinAspectRatio()" title="aspectRatio"
          aria-label="aspect ratio button" role="button">
          <mat-icon>image_aspect_ratio</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="resetImage()" title="reset image" aria-label="reset image button"
          role="button">
          <mat-icon>autorenew</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="zoomOut()" title="zoom out" aria-label="zoom out button" role="button">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button type="button" mat-icon-button (click)="zoomIn()" title="zoom in" aria-label="zoom in button" role="button">
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>