<div id="profile-section-story" class="profile__content">
    <div class="profile__section__card card">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Impact Story</div>
      </div>
      <!-- FORM -->
      <form #form="ngForm" class="profile__form__container">

        <!-- IMPACT STORY -->
        <div class="profile__form--impact">
          <label class="form__label">Your Impact Story</label>
          <textarea class="form__control impact-text-area" name="bio" [(ngModel)]="bio" type="text"
            placeholder="An Impact Story can be your bio, how you wish to be introduced to others, specific volunteer or other activities, etc. that capture and demonstrate your impact whether locally or globally."
            autosize [minRows]="5" [maxRows]="10" aria-label="your impact story"></textarea>
        </div>

        <!-- SAVE -->
        <div class="profile__form--save">
          <button class="button button--dark-grey" type="submit" (click)="
            updateImpactStory();
            form.form.markAsUntouched();
            form.form.markAsPristine();
            imageUploadMessage = ''" [disabled]="
            (((form.form.pristine ||
              form.form.invalid ||
              saveDisabled) &&
              !fileChange) ||
              currentCity === '' ||
              currentState === '-1') &&
            !importedItemUsed" aria-label="save button" role="button">
            SAVE
          </button>
        </div>
      </form>
    </div>
  </div>