<div class="navbar-container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <button type="button" tabindex="1" (keyup.enter)="toggleFoldingSidebar()" mat-icon-button aria-label="hamburger menu toggle" role="button">
      <mat-icon (tap)="toggleFoldingSidebar()" class="menu-icon">menu</mat-icon>
    </button>
    <div *ngIf="!data.isMobile && !data.LT_MD">
      <a class="navbar-brand brand-logo-mini" (tap)="goHome()" role="link">
        <img src="assets/images/pi-logo-full.png" class="nav-image" alt="profound impact logo" role="img" aria-label="profound impact logo"/>
      </a>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="flex-end">
    <ul fxLayout="row" fxLayoutAlign="center center">
      <li>
        <div class="search-bar" style="margin-right: -2.5rem;">
          <div style="padding-right: 2rem; border-radius: 0.5rem;">            
            <input id="searchbar-input" #mainSearch class="form__control search-field" type="text" tabindex="2" placeholder="Search" [(ngModel)]="searchTerm"
            (selectItem)="search()" [ngbTypeahead]="pageSuggestions" aria-controls="owned_listbox" (keyup.enter)="search()" aria-label="search">
          </div>
          <div *ngIf="mobileSearchBarActive" class="mobile-search-background" (click)="mobileSearchBarActive = false;">
            &nbsp;</div>
          <input id="searchbar-input" #mainSearch *ngIf="mobileSearchBarActive" class="form__control mobile-search-field" type="text" placeholder="Search"
            [(ngModel)]="searchTerm" (selectItem)="search()" [ngbTypeahead]="pageSuggestions" (keyup.enter)="search()"  aria-label="search field">
          <mat-icon matSuffix class="search-icon">search</mat-icon>
          <mat-icon matSuffix class="mobile-search-icon" (click)="toggleMobileSearchbar()">search</mat-icon>
        </div>
      </li>
      
      <li class="nav-separator">|</li>
      <li class="accessibiliy-toggle" fxShow.gt-xs="false">
        <div fxLayout="row">
          <div class="footer-text">
            <mat-icon class="material-icons-outlined">accessible</mat-icon>
          </div>
          <div style="margin-top: 2px">
            <label class="switch">
              <input tabindex="3" (keyup.enter)="data.accessibilityMode = !data.accessibilityMode; toggleAccessibilityMode()" type="checkbox" [(ngModel)]="data.accessibilityMode" (change)="toggleAccessibilityMode()"
                aria-label="privacy status toggle" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </li>
      <li class="nav-separator" fxShow.gt-xs="false">|</li>
      <li class="nav-item p-0 m-0" *ngIf="!data.authenticated">
        <a style="cursor: pointer; color: white" tabindex="4" class="nav-link" (keyup.enter)="goToJoin()" (tap)="goToJoin()" aria-label="link to join us" role="link">
          JOIN
        </a>
      </li>
      <li class="nav-separator" *ngIf="!data.authenticated">|</li>
      <li class="nav-item p-0 m-0"  *ngIf="!data.authenticated">
        <a style="cursor: pointer; color: white" tabindex="5" class="nav-link nav-login" (keyup.enter)="openLogin()" (tap)="openLogin()" aria-label="link to login dialog" role="link">
          LOGIN
        </a>
      </li>
      <li class="nav-item dropdown" ngbDropdown *ngIf="data.authenticated" #drop="ngbDropdown">
        <a class="nav-link dropdown-toggle" id="UserDropdown" tabindex="3" (keyup.enter)="drop.open()" ngbDropdownToggle>
          <img class="image-circle-sm" [alt]="username" onError="this.src='assets/images/uidesign/user.png';" [src]="
          data.currentUser && data.currentUser.logoUrl != ''
            ? 'https://' +
              bucket +
              '.s3.amazonaws.com/profile-pictures/' +
              data.currentUser.logoUrl
            : 'assets/images/uidesign/user.png'" role="img" aria-label="user image"/>
        </a>
        <div ngbDropdownMenu aria-labelledby="UserDropdown" id="user-dropdown-menu" (click)="drop.close()" >
          <div class="image-top-unfolded"></div>
          <div class="profile-card-dropdown">
            <div class="image-circle-unfolded">
              <img *ngIf="data.currentUser" [src]="
                data.currentUser && data.currentUser.logoUrl != ''
                  ? 'https://' +
                    bucket +
                    '.s3.amazonaws.com/profile-pictures/' +
                    data.currentUser.logoUrl
                  : 'assets/images/uidesign/user.png'
              " [alt]="username" onError="this.src='assets/images/uidesign/user.png';" role="img"
                aria-label="user image" />
            </div>
            <div *ngIf="data.currentUser" class="header">
              <h4 class="profile-name" *ngIf="data.currentUser.firstName != ''">
                {{ data.currentUser.firstName }}
                {{
                data.currentUser.lastName !== '' &&
                data.currentUser.lastName !== undefined &&
                data.currentUser.lastName !== 'undefined' &&
                data.currentUser.lastName !== 'null' &&
                data.currentUser.lastName !== null
                ? data.currentUser.lastName
                : ''
                }}
              </h4>
              <h4 class="email-gray">{{ data.currentUser.email }}</h4>
            </div>
            <div *ngIf="data.profileCompletion">
              <p style="
              text-align: center;
              color: #d6d6d6;
              padding: 0px 5px 10px 5px;
              margin: 0px;
            " role="status">
                Your profile is {{ data.profileCompletion.totalCompleted }}% complete
              </p>

              <div class="progress" style="
              width: 80%;
              margin-left: 10%;
              border-radius: 10px;
              margin-bottom: 10px;
            ">
                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: data.completionWidth }"
                  style="height: 20px; background-color: #2abb25; text-align: center; font-size: 12px;">
                  <span *ngIf="data.profileCompletion.totalCompleted > 25">{{ data.profileCompletion.totalCompleted
                    }}%</span>
                </div>
              </div>
            </div>

            <div>
              <p style="
              text-align: center;
              color: rgb(80, 80, 80);
              background-color: #f8f8f8;
              padding: 6px;
              margin: 0px;
              font-weight: 550;
            ">
                PRIVACY MODE
              </p>

              <div *ngIf="data.currentUser" style="cursor: pointer !important;" (click)="goToSettings()">
                <div *ngIf="data.currentUser.profileStatus === 'private'" style="text-align: center">
                  <p style="
                  display: inline-block;
                  color: #d6d6d6;
                  font-size: 14px;
                  padding: 5px 5px 0px 5px;
                  margin-bottom: 0px;
                " role="status">
                    Your current status:
                    <i class="fa fa-lock pull-right" style="margin-top: 2px"></i> Private
                  </p>
                  <p style="
                  color: #d6d6d6;
                  padding: 5px 10px 10px 10px;
                  margin: 0px;
                  word-wrap: normal;
                  font-size: 11px;
                ">
                    You can make your information visible to other users in the platform
                    in the settings tab
                  </p>
                </div>
                <div *ngIf="data.currentUser.profileStatus === 'public'" style="text-align: center">
                  <p style="
                  display: inline-block;
                  color: #2abb25;
                  font-size: 14;
                  padding: 5px 5px 0px 5px;
                  margin-bottom: 0px;
                " role="status">
                    <span style="color: #d6d6d6">Your current status: </span><i class="fa fa-unlock-alt pull-right"
                      style="margin-top: 2px"></i>
                    Public
                  </p>
                  <p style="
                  color: #d6d6d6;
                  padding: 5px 10px 10px 10px;
                  margin: 0px;
                  word-wrap: normal;
                  font-size: 11px;
                ">
                    You can make your information hidden to other users in the platform in
                    the settings tab
                  </p>
                </div>
              </div>
            </div>

            <p style="
              text-align: center;
              color: rgb(80, 80, 80);
              background-color: #f8f8f8;
              padding: 6px;
              margin: 0px;
              font-weight: 550;
            ">
              ACCOUNT SETTINGS
            </p>

            <a tabindex="6" mat-list-item (tap)="route('/profile')" routerLinkActive="menu-item-active" [routerLink]="['/profile']" aria-label="profile"
              role="link">
              <mat-icon class="material-icons-outlined">account_box</mat-icon>
              <span class="menu-text">Profile</span>
            </a>

            <a tabindex="7" mat-list-item (tap)="route('/import')" routerLinkActive="menu-item-active" [routerLink]="['/import']"
              aria-label="import profile" role="link">
              <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
              <span class="menu-text">Import Profile</span>
            </a>

            <a tabindex="8" mat-list-item (tap)="route('/settings')" routerLinkActive="menu-item-active" [routerLink]="['/settings']"
              aria-label="settings" role="link">
              <mat-icon>settings</mat-icon>
              <span class="menu-text">Settings</span>
            </a>

            <a tabindex="9" mat-list-item (tap)="route('/contact')" routerLinkActive="menu-item-active" [routerLink]="['/contact']"
              aria-label="contact" role="link">
              <mat-icon>mail</mat-icon>
              <span class="menu-text">Contact</span>
            </a>

            <a tabindex="10" (keyup.enter)="util.logout()" mat-list-item (tap)="util.logout()" aria-label="logout" role="link">
              <mat-icon>logout</mat-icon>
              <span class="menu-text">Logout</span>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>