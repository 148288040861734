import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BannerComponent } from './banners/banner/banner.component';
import { BrandingSeparatorComponent } from './separators/branding-separator/branding-separator.component';
import { DiscussionBoardComponent } from './widgets/discussion-board/discussion-board.component';
import { FilterPipe, SortByPipe, SafeHtmlPipe } from './widgets/discussion-board/discussion-board.pipe';
import { SliderComponent } from './banners/slider/slider.component';
import { CardComponent } from './cards/card/card.component';
import { EventGraphComponent } from './graphs/event-graph/event-graph.component';
import { MiniCardComponent } from './cards/mini-card/mini-card.component';
import { ZoomComponent } from './widgets/zoom/zoom.component';
import { InstagramFeedComponent } from './widgets/instagram-feed/instagram-feed.component';
import { PollsComponent } from './widgets/polls/polls.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownTimerComponent } from './widgets/countdown-timer/countdown-timer.component';
import { JoinDiscussionComponent } from './widgets/join-discussion/join-discussion.component';
import { SocialComponent } from './widgets/social/social.component';
import { PanelistsComponent } from './widgets/panelists/panelists.component';
import { CallToActionComponent } from './widgets/call-to-action/call-to-action.component';
import { EventCalendarComponent } from './widgets/event-calendar/event-calendar.component';
import { EventScheduleComponent } from './widgets/event-schedule/event-schedule.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { EventCalenderWewdcComponent } from './widgets/event-calender-wewdc/event-calender-wewdc.component';
import { TitleCasePipe } from './../pipes/pipe.component';
import { Slider2020Component } from './banners/slider2020/slider2020.component';

@NgModule({
  imports: [
    CommonModule, 
    NgbModule,
    MatCardModule, 
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  declarations: [
    DiscussionBoardComponent,
    FilterPipe,
    SortByPipe,
    SafeHtmlPipe,
    BrandingSeparatorComponent,
    BannerComponent,
    SliderComponent,
    CardComponent,
    EventGraphComponent,
    MiniCardComponent,
    ZoomComponent,
    InstagramFeedComponent,
    PollsComponent,
    CountdownTimerComponent,
    JoinDiscussionComponent,
    SocialComponent,
    PanelistsComponent,
    CallToActionComponent,
    EventCalendarComponent,
    EventCalenderWewdcComponent,
    EventScheduleComponent,
    InfoDialogComponent,
    TitleCasePipe,
    Slider2020Component
  ],
  exports: [
    CommonModule,
    DiscussionBoardComponent,
    BrandingSeparatorComponent,
    BannerComponent,
    SliderComponent,
    CardComponent,
    EventGraphComponent,
    MiniCardComponent,
    ZoomComponent,
    InstagramFeedComponent,
    PollsComponent,
    CountdownTimerComponent,
    JoinDiscussionComponent,
    SocialComponent,
    PanelistsComponent,
    CallToActionComponent,
    EventCalendarComponent,
    EventScheduleComponent,
    EventCalenderWewdcComponent,
    TitleCasePipe
  ],
  providers: [
    TitleCasePipe
  ]
})
export class ComponentsModule { }