import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Certificate } from '../../../../shared/utilities/app-db-nodes';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { ObtainedDateTimeEdge } from 'src/app/shared/utilities/app-db-edges';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class CertificationsComponent implements OnInit {
  
  viewmodel = this.data.viewmodel;
  certificationList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();
  noneExists: boolean;
  unverified: any;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService
  ) {}

  status: ServiceStatus;
  // @Input() viewmodel: UserProfile;
  newItem: {
    edge: ObtainedDateTimeEdge;
    toNodeRef: NodeRef;
    toNode: Certificate;
  };
  currentItem: {
    edge: ObtainedDateTimeEdge;
    toNodeRef: NodeRef;
    toNode: Certificate;
  };
  certificateModel: any = {
    name: '',
    shortName: '',
    obtainedDateTime: {
      year: '',
      month: '',
      day: '',
    },
    dataSource: '',
    obtainedDateTimeFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  isMobile: boolean;
  noneDisabled = false;
  deleteMode = false;
  isYearRequired = false;
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getCertificateList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip(){
     
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.certificateModel.name = 'NONE';
    this.certificateModel.shortName = '';
    this.certificateModel.dataSource = '';
    this.certificateModel.obtainedDateTime.year = '';
    this.certificateModel.obtainedDateTime.month = '';
    this.certificateModel.obtainedDateTime.day = '';
    this.saveClick();
  }

  onDateChange(field: any, input: any, year) {
    console.log(year);
    if (year === '' ) {
      this.isYearRequired = true;
    } else {
      this.isYearRequired = false;
    }

    if (input === 'month') {
      this.dayValue = [];
      for (
        let i = 1;
        i <= this.data.days[this.certificateModel.obtainedDateTime.month - 1];
        i++
      ) {
        this.dayValue.push({ value: i });
      }
      this.certificateModel.obtainedDateTime.day = '';
    } else if (input === 'year') {
      if (this.certificateModel.obtainedDateTime.month === '') {
        this.certificateModel.obtainedDateTime.month = '';
        this.certificateModel.obtainedDateTime.day = '';
      }
    }
    const flag = this.certificateModel[field][input] !== '' ? 1 : 0;
    this.certificateModel[field + 'Flag'][input] = flag;
  }

  getCertificateList() {
    this.certificationList = [];
    const apiUrl = `${this.root}/userProfileUpdateCertificate?operation=list`;
    const getCertificateList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getCertificateList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        this.viewmodel.obtained_Certificate = [];
        const tempItem = response.record.sort((a: any, b: any) =>
          // tslint:disable-next-line: max-line-length
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? a.row[0].obtainedDateTime.year
            : +a.row[0].obtainedDateTime.month) <
          (a.row[0].obtainedDateTime.year !== b.row[0].obtainedDateTime.year
            ? b.row[0].obtainedDateTime.year
            : +b.row[0].obtainedDateTime.month)
            ? 1
            : -1
        );
        // const tempItem = response.record.sort((a: any, b: any) =>
        //   a.row[0].obtainedDateTime.year < b.row[0].obtainedDateTime.year
        //     ? 1
        //     : -1
        // );
        response.record = tempItem;
        
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.record[item].row[0].obtainedDateTime.year,
              response.record[item].row[0].obtainedDateTime.month - 1,
              response.record[item].row[0].obtainedDateTime.day
            ),
            dataSource: response.record[item].row[0].dataSource,
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            shortName: response.record[item].row[0].shortName,
            // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.record[item].row[0].obtainedDateTime.year,
              month: response.record[item].row[0].obtainedDateTime.month,
              day: response.record[item].row[0].obtainedDateTime.day,
            },
            status : 'verified'
          };
          this.viewmodel.obtained_Certificate.push(temp);
          this.certificationList.push(temp);
        }

        let UNVERIFIEDCurrentlyWorking = [];
        UNVERIFIEDCurrentlyWorking = [...response.unverified]
        let UNVERIFIEDCertification= [];
        UNVERIFIEDCurrentlyWorking.forEach((value, index) => {
          if(value.row[0].obtainedDateTime === undefined){
            UNVERIFIEDCurrentlyWorking[index].row[0].obtainedDateTime=new Date(0,0,0)
          }
          if(value.row[0].obtainedDateTime === undefined){
            UNVERIFIEDCurrentlyWorking[index].row[0].obtainedDateTimeFlag=JSON.parse(JSON.stringify({year:null,month:null,day:null}))
          }
          
        });
        UNVERIFIEDCertification = [...UNVERIFIEDCurrentlyWorking]
        response.unverified = UNVERIFIEDCertification;
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            obtainedDateTime: new Date(
              response.unverified[item].row[0].obtainedDateTime.year,
              response.unverified[item].row[0].obtainedDateTime.month - 1,
              response.unverified[item].row[0].obtainedDateTime.day
            ),
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            dataSource: response.unverified[item].row[0].dataSource,
            shortName: response.unverified[item].row[0].shortName,
            // // tslint:disable-next-line: max-line-length
            obtainedDateTimeFlag: {
              year: response.unverified[item].row[0].obtainedDateTime.year,
              month: response.unverified[item].row[0].obtainedDateTime.month,
              day: response.unverified[item].row[0].obtainedDateTime.day,
            },
            status : 'unverified'
          };
          this.viewmodel.obtained_Certificate.push(temp);
          this.certificationList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        obtainedDateTime: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        shortName: '',
        logoUrl: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log(
      'VIEW MODEL OBTAINED CERTIFICATE: ',
      this.viewmodel.obtained_Certificate
    );
    if (
      this.viewmodel.obtained_Certificate.length === 1 &&
      this.viewmodel.obtained_Certificate[0].name === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.certificateModel = {
      name: '',
      shortName: '',
      obtainedDateTime: {
        year: '',
        month: '',
        day: '',
      },
      obtainedDateTimeFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.certificateModel = { ...item };
    this.certificateModel.edgeUuid = item.uuid;
    this.certificateModel.officialUrl = item.officialUrl;
   
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.certificateModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : 0,
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : 0,
      year: item.obtainedDateTimeFlag.year && item.obtainedDateTimeFlag.year != undefined
        ? item.obtainedDateTimeFlag.year
        : 0,
    };
    
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  isValid() {
    if (this.certificateModel.year === undefined) {
      this._toaster.errorToastr('Year is mandatory', 'Validation Error');
      return false;
    }
    return true;
  }

  saveClick() {
   
    if (this.certificateModel.name === 'NONE') {
      this.certificateModel.shortName = '';
      this.certificateModel.obtainedDateTime.year = '';
      this.certificateModel.obtainedDateTime.month = '';
      this.certificateModel.obtainedDateTime.day = '';
    }
    if (this.currentUIAction === 1&& !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateCertificate?operation=update`;
      // tslint:disable-next-line: max-line-length
      console.log();
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        {
          data: this.certificateModel,
          edgeUuid: this.certificateModel.edgeUuid,
        },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getCertificateList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr("Certification Saved Failed" + error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateCertificate?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.certificateModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getCertificateList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.obtained_Certificate[0]);
    // }
    if (this.certificateModel.name != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    this.currentItem = item;
    this.certificateModel = { ...item };
    this.certificateModel.edgeUuid = item.uuid;
    let month;
    if (item.obtainedDateTimeFlag.day === null) {
      if (item.obtainedDateTimeFlag.month === null) {
        month = '';
      } else {
        month = item.obtainedDateTime.getMonth() + 2;
      }
    } else {
      month = item.obtainedDateTime.getMonth() + 1;
    }
    this.certificateModel.obtainedDateTime = {
      day: item.obtainedDateTimeFlag.day ? item.obtainedDateTime.getDate() : '',
      month: item.obtainedDateTimeFlag.month
        ? item.obtainedDateTimeFlag.month
        : '',
      year: item.obtainedDateTimeFlag.year
        ? item.obtainedDateTimeFlag.year
        : '',
    };
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateCertificate?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.certificateModel, edgeUuid: this.certificateModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getCertificateList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  clearData() {
    this.certificateModel.name = '';
    this.certificateModel.dataSource = '';
    this.certificateModel.shortName = '';
    this.certificateModel.obtainedDateTime.year = '';
    this.certificateModel.obtainedDateTime.month = '';
    this.certificateModel.obtainedDateTime.day = '';
  }

  cancelClick() {
    this.flip();
    this.httpClientCallMessage = null;
    this.clearData();
    this.deleteMode = false;
  }
}
