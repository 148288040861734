import { Component, OnInit } from '@angular/core';
import { SiteConstants } from './services/site-constants.service';
import { environment } from '../environments/environment';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from './services/data.service';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpService } from './services/http.service';
import { Auth } from 'aws-amplify';
import { UserProfileService } from './pages/profile/user-profile.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ServiceStatus } from './shared/utilities/app-framework';
import { BehaviorSubject } from 'rxjs';
import { LoginComponent } from './shared/components/dialogs/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from './services/configuration.service';
import { UtilityService } from './services/utility.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebSocketService } from './services/websocket.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ZoomService } from './shared/components/widgets/zoom/zoom.service';
import { first } from 'rxjs/operators';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ZoomService],
})
export class AppComponent implements OnInit {
  LT_SM: boolean;
  client = 'DEMO';
  constructor(
    public data: DataService,
    private api: HttpService,
    private constants: SiteConstants,
    private deviceService: DeviceDetectorService,
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
    public amplifyService: AmplifyService,
    public router: Router,
    private configuration: ConfigurationService,
    private util: UtilityService,
    private websocket: WebSocketService,
    public _toaster: ToastrManager,
    private route: ActivatedRoute
  ) {

    this.router.events.filter(event => event instanceof NavigationEnd)
      .subscribe((event: any) => {
        const client = event.url.split('/')[3];
        if (client === 'DEMO' || client === 'UWMA' || client === 'WEWDC' || client === 'STJE') {
          this.client = client;
        } else {
          this.client = null;
        }
    });

    this.data.authenticated = JSON.parse(localStorage.getItem('authenticated'));
    this.data.cognitoUsername = localStorage.getItem('cognitoUsername');
    this.data.uuidPerson = localStorage.getItem('uuidPerson');

    if (this.data.authenticated) {
      this.data.pollTaken = true;
    } else {
      this.data.pollTaken = false;
    }
    // CHANGE COLOR THEME EVENT LISTENER
    // tslint:disable-next-line: no-shadowed-variable
    this.data.theme.subscribe((data: any) => {
      this.data.currentTheme = data;
      console.log('CURRENT THEME: ', this.data.currentTheme);
      document.documentElement.style.setProperty(
        '--navbar-background-color',
        this.configuration.loadConfiguration(data).navbarBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--navbar-text-color',
        this.configuration.loadConfiguration(data).navbarTextColor
      );
      document.documentElement.style.setProperty(
        '--footer-background-color',
        this.configuration.loadConfiguration(data).footerBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--footer-text-color',
        this.configuration.loadConfiguration(data).footerTextColor
      );
      document.documentElement.style.setProperty(
        '--primary-icon-color',
        this.configuration.loadConfiguration(data).primaryIconColor
      );
      document.documentElement.style.setProperty(
        '--secondary-icon-color',
        this.configuration.loadConfiguration(data).secondaryIconColor
      );
      document.documentElement.style.setProperty(
        '--primary-background-color',
        this.configuration.loadConfiguration(data).primaryBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--blue-background-color',
        this.configuration.loadConfiguration(data).blueBackgroundColor
      );
      document.documentElement.style.setProperty(
        '--primary-text-color',
        this.configuration.loadConfiguration(data).primaryTextColor
      );
      document.documentElement.style.setProperty(
        '--secondary-text-color',
        this.configuration.loadConfiguration(data).secondaryTextColor
      );
      document.documentElement.style.setProperty(
        '--card-text-color',
        this.configuration.loadConfiguration(data).cardTextColor
      );
      document.documentElement.style.setProperty(
        '--card-secondary-text-color',
        this.configuration.loadConfiguration(data).cardSecondaryTextColor
      );
      document.documentElement.style.setProperty(
        '--misc-page-text-color',
        this.configuration.loadConfiguration(data).miscPageTextColor
      );
      document.documentElement.style.setProperty(
        '--system-requirements-text-color',
        this.configuration.loadConfiguration(data).systemRequirementsTextColor
      );
      document.documentElement.style.setProperty(
        '--contribution-text-color',
        this.configuration.loadConfiguration(data).contributionTextColor
      );
      document.documentElement.style.setProperty(
        '--profound-insights-text-color',
        this.configuration.loadConfiguration(data).profoundInsightsTextColor
      );
      document.documentElement.style.setProperty(
        '--warning',
        this.configuration.loadConfiguration(data).warning
      );
      document.documentElement.style.setProperty(
        '--success',
        this.configuration.loadConfiguration(data).success
      );
      document.documentElement.style.setProperty(
        '--unverified',
        this.configuration.loadConfiguration(data).unverified
      );
      document.documentElement.style.setProperty(
        '--boder-color',
        this.configuration.loadConfiguration(data).borderColor
      );
      document.documentElement.style.setProperty(
        '--checkbutton-active-color',
        this.configuration.loadConfiguration(data).checkbuttonActive
      );
      document.documentElement.style.setProperty(
        '--pink-badge',
        this.configuration.loadConfiguration(data).pinkBadge
      );
      document.documentElement.style.setProperty(
        '--yellow-badge',
        this.configuration.loadConfiguration(data).yellowBadge
      );
      document.documentElement.style.setProperty(
        '--btn-magenta',
        this.configuration.loadConfiguration(data).btnMagenta
      );
      document.documentElement.style.setProperty(
        '--btn-cyan',
        this.configuration.loadConfiguration(data).btnCyan
      );
      document.documentElement.style.setProperty(
        '--btn-grey',
        this.configuration.loadConfiguration(data).btnGrey
      );
      document.documentElement.style.setProperty(
        '--btn-pink',
        this.configuration.loadConfiguration(data).btnPink
      );
      document.documentElement.style.setProperty(
        '--btn-green',
        this.configuration.loadConfiguration(data).btnGreen
      );
      document.documentElement.style.setProperty(
        '--btn-green-pi-inaugural-day-event',
        this.configuration.loadConfiguration(data).btnGreenPiInauguralDayEvent
      );
      document.documentElement.style.setProperty(
        '--btn-orange',
        this.configuration.loadConfiguration(data).btnOrange
      );
      document.documentElement.style.setProperty(
        '--btn-blue',
        this.configuration.loadConfiguration(data).btnBlue
      );
      document.documentElement.style.setProperty(
        '--btn-blue-login',
        this.configuration.loadConfiguration(data).btnBlueLogin
      );
      document.documentElement.style.setProperty(
        '--btn-red',
        this.configuration.loadConfiguration(data).btnRed
      );
      document.documentElement.style.setProperty(
        '--btn-dark-grey',
        this.configuration.loadConfiguration(data).btnDarkGrey
      );
      document.documentElement.style.setProperty(
        '--blue-pi-day-agenda-background-color',
        this.configuration.loadConfiguration(data).bluePiDayAgendaBackground
      );
      document.documentElement.style.setProperty(
        '--light-purple-gradient-background',
        this.configuration.loadConfiguration(data).lightPurpleGradientBackground
      );
      document.documentElement.style.setProperty(
        '--light-pink-gradient-background',
        this.configuration.loadConfiguration(data).lightPinkGradientBackground
      );
      document.documentElement.style.setProperty(
        '--dark-pink-gradient-background',
        this.configuration.loadConfiguration(data).darkPinkGradientBackground
      );
      document.documentElement.style.setProperty(
        '--light-grey-gradient-background',
        this.configuration.loadConfiguration(data).lightGreyGradientBackground
      );
      document.documentElement.style.setProperty(
        '--yellow-gradient-background',
        this.configuration.loadConfiguration(data).yellowGradientBackground
      );
      document.documentElement.style.setProperty(
        '--cyan-gradient-background',
        this.configuration.loadConfiguration(data).cyanGradientBackground
      );
      document.documentElement.style.setProperty(
        '--gainsboro-gradient-background',
        this.configuration.loadConfiguration(data).gainsboroGradientBackground
      );
      document.documentElement.style.setProperty(
        '--blue-twitter-gradient-background',
        this.configuration.loadConfiguration(data).blueTwitterGradientBackground
      );
      document.documentElement.style.setProperty(
        '--blue-job-board-gradient-background',
        this.configuration.loadConfiguration(data).blueJobBoardGradientBackground
      );
      document.documentElement.style.setProperty(
        '--grey-geo-map-gradient-background',
        this.configuration.loadConfiguration(data).greyGeoMapGradientBackground
      );
      document.documentElement.style.setProperty(
        '--orange-gradient-background',
        this.configuration.loadConfiguration(data).orangeGradientBackground
      );
      document.documentElement.style.setProperty(
        '--blue-gradient-background',
        this.configuration.loadConfiguration(data).blueGradientBackground
      );
      document.documentElement.style.setProperty(
        '--green-gradient-background',
        this.configuration.loadConfiguration(data).greenGradientBackground
      );
      document.documentElement.style.setProperty(
        '--green-contribution-gradient-background',
        this.configuration.loadConfiguration(data).greenContributionGradientBackground
      );
      document.documentElement.style.setProperty(
        '--red-instagram-feed-gradient-background',
        this.configuration.loadConfiguration(data).redInstagramFeedGradientbackground
      );
      document.documentElement.style.setProperty(
        '--green-pi-Inaugural-day-gradient-background',
        this.configuration.loadConfiguration(data).greenPiInauguralDayGradientBackground
      );
      document.documentElement.style.setProperty(
        '--profound-gradient-background',
        this.configuration.loadConfiguration(data).profoundGradientBackground
      );
      document.documentElement.style.setProperty(
        '--medium-light-grey',
        this.configuration.loadConfiguration(data).mediumLightGrey
      );
      document.documentElement.style.setProperty(
        '--green-profound-insights-presents-gradient-background',
        this.configuration.loadConfiguration(data).greenProfoundInsightsPresents
      );
      document.documentElement.style.setProperty(
        '--form-ctrl-border-color',
        this.configuration.loadConfiguration(data).formCtrlBorderColor
      );
      document.documentElement.style.setProperty(
        '--form-text-color',
        this.configuration.loadConfiguration(data).formTextColor
      );
      document.documentElement.style.setProperty(
        '--form-placeholder-color',
        this.configuration.loadConfiguration(data).formPlaceholderColor
      );
      document.documentElement.style.setProperty(
        '--dark-pink-legend',
        this.configuration.loadConfiguration(data).darkPinkLegend
      );
      document.documentElement.style.setProperty(
        '--banner-text-color',
        this.configuration.loadConfiguration(data).bannerTextColor
      );
      document.documentElement.style.setProperty(
        '--light-pink-secondary-gradient-background',
        this.configuration.loadConfiguration(data).lightPinkSecondaryGradientBackground
      );
    });

    // INITIALIZES LIGHT THEME
    this.data.theme = 'light';

    // ALLOWS GOOGLE ANALYTICS TO SEE ANGULAR PAGE ROUTING INFO
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    this.router.events.filter(event => event instanceof NavigationEnd)
      .subscribe((event: any) => {
        this.data.currentRoute = event.url;
        // console.log('CURRENT ROUTE', this.data.currentRoute);
      });

    this.data.screenWidth = window.innerWidth;
    window.innerWidth <= 1279
      ? (this.LT_LG = true)
      : (this.LT_LG = false);
    this.data.LT_LG = this.LT_LG;
    window.innerWidth <= 992
      ? (this.LT_MD = true)
      : (this.LT_MD = false);
    this.data.LT_MD = this.LT_MD;
    window.innerWidth <= 750
      ? (this.LT_SM = true)
      : (this.LT_SM = false);
    this.data.LT_SM = this.LT_SM;

    // WINDOW RESIZE EVENT LISTENER TO SET BREAK POINTS
    const onresize = () => {
      this.data.screenWidth = window.innerWidth;
      window.innerWidth <= 1279
        ? (this.LT_LG = true)
        : (this.LT_LG = false);
      this.data.LT_LG = this.LT_LG;
      window.innerWidth <= 992
        ? (this.LT_MD = true)
        : (this.LT_MD = false);
      this.data.LT_MD = this.LT_MD;
      window.innerWidth <= 750
        ? (this.LT_SM = true)
        : (this.LT_SM = false);
      this.data.LT_SM = this.LT_SM;
    };
    window.addEventListener('resize', onresize);

    this.device = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = true;
    this.data.device = this.deviceService.getDeviceInfo();
    this.data.isMobile = this.deviceService.isMobile();
    this.data.isTablet = this.deviceService.isTablet();
    this.data.isDesktopDevice = true;
    this.data.debug = environment.debug;


    // console.log('isMobile', isMobile)
    // console.log('isTablet', isTablet)
    // console.log('isDektop', isDesktopDevice)
    // console.log('device', this.data.device)

    this.util.getUser();

    setTimeout(() => {
      if (this.data.forceLogout) {
        this.forceLogout();
      }
    }, 1500);
  }

  // PROPERTIES
  LT_LG = true;
  status: any;
  viewmodel: any;
  userBasicModel: any;
  LT_MD: boolean;
  discussionBoardUrl: any;
  showTopMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  device: any;
  profileCompletion: any;

  // INITIALIZER
  ngOnInit() {
    this.websocket.createConnection();
    // CHECKS FOR ACCESSIBILITY COOKIE & SETS APP ACCESSIBILITY MODE BASED ON COOKIE
    if (!this.util.getCookie('accessibilityMode')) {
      this.util.setCookie('accessibilityMode', 'disabled');
    } else {
      this.data.accessibilityMode = this.util.getCookie('accessibilityMode') == 'enabled' ? true : false;
    }
    // CHANGES APP COLOR THEME BASED ON ACCESSIBILITY MODE STATE
    if (this.data.accessibilityMode) {
      this.data.theme = 'acc';
    } else {
      this.data.theme = 'light';
    }

    // GETS THE USERS LOCATION DATA
    this.api.getAnonymousUserData().subscribe((data) => {
      // console.log('USER DATA: ', data);
      const response: any = data;
      this.data.currentCountryCode = response.country_code;
      this.data.currentIPAddress = response.ip;
      this.data.currentCity = response.city;
    });

    // SETS THE BASE ENVIRONMENT IF WORKING WITH LOCAL API GATEWAY
    this.constants.local
      ? (this.constants.rooturl = 'http://127.0.0.1:3000')
      : (this.constants.rooturl = environment.baseUrl);

    this.data.debug = environment.debug;
    // console.log('ROUTE USER-INVITATION', this.data.currentRoute);


    this.websocket.onWebsocketMessage.subscribe((msg) => {
      console.log('THIS IS EBSOCKET CUSTOM MESSAGE', msg);
      if (msg.subject === 'Event_Schedule_Notification' && msg.topic === 'Reminder' || msg.topic === 'EventStarted') {
        if (msg.notification) {
          this.showCustom('bottom-right', `<span style="font-size:16px;display:flex"><i style="font-size:20px" class="fa ${msg.notification.icon} mr-4"></i>${msg.notification.message}</span>`);
        }
      }
    });
  }

  loadUserInfo = async function () {
    let token = '';
    this.amplifyService
      .auth()
      .currentAuthenticatedUser()
      .then((data) => {
        const cognitoUsername = data.username;
        // const cognitoUUID = data.attributes.sub;
        token = data.signInUserSession.idToken.jwtToken;
        this.isAuthenticated =
          data.signInUserSession !== undefined &&
            data.signInUserSession !== null
            ? true
            : false;
        this.data.authenticated = this.isAuthenticated;

        if (this.isAuthenticated) {
          this.data.pages = [
            'Home',
            'Faculty',
            'Alumni',
            'Academic Family Tree',
            'Mentoring Relation',
            'Publication',
            'WWIN',
            'Class of 2020',
            'John C. Beatty',
            'Profound Impact Day',
            'Profile',
            'Import',
            'Settings',
            'Contact UW',
            'Contact us',
            'Privacy Policy',
            'About us',
            'System Requirements'
          ];
          this.api
            .getProfileCompletion(token, cognitoUsername)
            .subscribe((res) => {
              const response: any = res;
              if (response.status === 2 && response.message === 'ForceLogout') {
                Auth.signOut({ global: true }).then(() => { });
                this.data.currentUser = null;
                this.data.authenticated = false;
                console.log('APP COMPONENT FORCED LOGOUT');
                // this.router.navigate(['/']);
                const modalRef = this.modalService.open(LoginComponent, {
                  windowClass: 'mdModal',
                });
                modalRef.result.then((result) => {
                  this.data.logger(result, 'OPEN LOGIN');
                });
              }
              this.data.currentUser = response.data.person;
              this.data.connectionType = response.data.person.connectionType ? response.data.person.connectionType : 'email';
              this.data.logger(response.data, 'PROFILE COMPLETION');
              this.profileCompletion = response.data;
              this.data.profileCompletion = this.profileCompletion;
              this.completionWidth =
                this.profileCompletion.totalCompleted + '%';
              this.data.completionWidth = this.completionWidth;
              this.ariaValue = this.profileCompletion.totalCompleted;
              this.data.ariaValue = this.ariaValue + 1;
              this.userFullName = `${this.profileCompletion.person.firstName} ${this.profileCompletion.person.lastName}`;
              this.userEmail = this.profileCompletion.person.email;
              this.userProfileStatus = this.profileCompletion.person.profileStatus;
              this.data.jwt = token;
              this.getUserProfile();
              this.data.profileTabs = [
                {
                  name: 'BASIC INFO',
                  key: 0,
                  active: true,
                  completion: this.data.profileCompletion.basic,
                },
                {
                  name: 'Experience',
                  key: 1,
                  active: false,
                  completion: this.data.profileCompletion.experience,
                },
                {
                  name: 'Education',
                  key: 2,
                  active: false,
                  completion: this.data.profileCompletion.education,
                },
                {
                  name: 'Certifications',
                  key: 3,
                  active: false,
                  completion: this.data.profileCompletion.certification,
                },
                {
                  name: 'Volunteer Experience',
                  key: 4,
                  active: false,
                  completion: this.data.profileCompletion.volunteer,
                },
                {
                  name: 'Skills',
                  key: 5,
                  active: false,
                  completion: this.data.profileCompletion.skill,
                },
                {
                  name: 'Languages',
                  key: 6,
                  active: false,
                  completion: this.data.profileCompletion.language,
                },
                {
                  name: 'Publications',
                  key: 7,
                  active: false,
                  completion: this.data.profileCompletion.publication,
                },
                {
                  name: 'Awards',
                  key: 8,
                  active: false,
                  completion: this.data.profileCompletion.awards,
                },
                {
                  name: 'Projects',
                  key: 9,
                  active: false,
                  completion: this.data.profileCompletion.projects,
                },
                {
                  name: 'Research',
                  key: 10,
                  active: false,
                  completion: this.data.profileCompletion.research,
                },
                {
                  name: 'Patents',
                  key: 11,
                  active: false,
                  completion: this.data.profileCompletion.patent,
                },
                {
                  name: 'Academic Ancestry',
                  key: 12,
                  active: false,
                  completion: this.data.profileCompletion.academicAncestry,
                },
                {
                  name: 'Mentoring Relation',
                  key: 13,
                  active: false,
                  completion: this.data.profileCompletion.mentoringRelation,
                },
                {
                  name: 'Social Media',
                  key: 14,
                  active: false,
                  completion: this.data.profileCompletion.socialMedia,
                },
                {
                  name: 'Impact Story',
                  key: 15,
                  active: this.data.currentProfileTab === 15 ? true : false,
                  completion: this.data.profileCompletion.impactStory,
                },
              ];
            });
        } else {
          this.data.pages = [
            'Home',
            'WWIN',
            'Class of 2020',
            'John C. Beatty',
            'Profound Impact Day',
            'Contact us',
            'Privacy Policy',
            'About us',
            'System Requirements'
          ];
        }
      });
    return;
  };

  getUserProfile() {
    this.api.getUserProfile().subscribe((data) => {
      const res: any = data;
      this.data.logger(res, 'User Profile Response');
      this.data.userProfileData = res;
      this.data.connectionType = res.data.person.connectionType ? res.data.person.connectionType : 'email';
    });
  }

  openSideBar() {
    this.data.sideBarOpen = true;
    this.data.publicGroupsOpen = true;
    this.data.privateGroupsOpen = true;
    this.data.graphsOpen = true;
  }

  closeSidebar() {
    this.data.sideBarOpen = false;
    this.data.publicGroupsOpen = false;
    this.data.privateGroupsOpen = false;
    this.data.graphsOpen = false;
  }

  sideBarToggler() {
    this.data.sideBarOpen = !this.data.sideBarOpen;
    if (this.data.sideBarOpen) {
      (document.querySelector(
        '.mat-drawer-container'
      ) as HTMLElement).style.overflow = 'auto';
    } else {
      (document.querySelector(
        '.mat-drawer-container'
      ) as HTMLElement).style.overflow = 'hidden';
    }
  }

  forceLogout() {
    Auth.signOut({ global: true }).then(() => { });
    this.data.currentUser = null;
    this.data.authenticated = false;
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then((result) => {
      this.data.logger(result, 'OPEN LOGIN');
    });
  }

  onActivate(event) {
    document.querySelector('mat-drawer-content').scrollTop = 0;
  }

  showCustom(position: any, msg: any) {
    this._toaster.customToastr(msg, null,
      { enableHTML: true,
        position: position,
        dismiss: 'controlled',
        messageClass: 'custom-global-alert',
        newestOnTop: true,
        showCloseButton: true,
        animate: 'fade'
       });
  }
}
