import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { DataService } from 'src/app/services/data.service';
import { VideoViewComponent } from 'src/app/shared/components/dialogs/video-view/video-view.component';
import { LoginComponent } from 'src/app/shared/components/dialogs/login/login.component';
import { DiscussionBoardService } from 'src/app/shared/components/widgets/discussion-board/discussion-board.service';
import { EventGraphService } from 'src/app/shared/components/graphs/event-graph/event-graph.service';
import { UtilityService } from 'src/app/services/utility.service';
import { take } from 'rxjs/operators';
import { WebSocketService } from 'src/app/services/websocket.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-piday2022',
  templateUrl: './piday2022.component.html',
  styleUrls: ['./piday2022.component.scss']
})
export class Piday2022Component implements OnInit {
  data: any;
  selectedsuggestedFilter = '';
  uuid = '4800c549-979e-4b76-bc9f-e49a7b84821a'
  zoomLiveStreamEventID = '401'
  subscription: any
  fileBucket: any;
  cookieService: any;

  constructor(
    private modalService: NgbModal,
    private httpService: HttpService,
    public dataService: DataService,
    private disucssionBoardService: DiscussionBoardService,
    private eg: EventGraphService,
    private util: UtilityService,
    private websocket: WebSocketService,
  ) {
    this.fileBucket = environment.privateBucket;
  }

  ngOnInit() {

    console.log('TIME CONVERSION', this.util.startTimeConversion('04:30:00'));
    console.log('AM CONVERSION', this.util.startTimeAM('04:30:00'));

    this.dataService.setPageTitle('Profound Impact Day')
    this.dataService.authenticatedSubject.pipe(take(1)).subscribe((res) => {
      this.disucssionBoardService.userAuth('9abc7f24-da05-4b4c-8473-4c011d6ad1c0')
    })
    this.disucssionBoardService.discussionBoardID.next(
      '9abc7f24-da05-4b4c-8473-4c011d6ad1c0'
    );

    this.subscription = this.websocket.onWebsocketMessage.subscribe(msg => {
      // when live stream config is changed from site-admin
      if (msg.subject === "LiveStreamConfig" && msg.associatedId.Key != '') {
        if(msg.data) {
          window.location.reload()
        }
      }
    })
  } 


  ngOnDestroy() {
    if(this.disucssionBoardService.webSocket) {
      this.disucssionBoardService.webSocket.complete()
    }

    this.subscription.unsubscribe()
  }

  connectToZoom(id: any) {
    this.httpService
      .connectToZoom(id, this.dataService.currentCountryCode, this.dataService.currentCity)
      .subscribe((data) => {});
  }

  connectToZoomPublic(id: any) {
    this.httpService
      .connectToZoomPublic(id, this.dataService.currentCountryCode, this.dataService.currentCity)
      .subscribe((data) => {});
  }

  openLogin() {
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: 'mdModal',
    });
    modalRef.result.then((data) => {
      this.data.logger(data, 'OPEN LOGIN');
    });
  }

  watchYoutube(url: string) {
    this.dataService.youtubeURL = url;
    this.modalService.open(VideoViewComponent, { windowClass: 'smModal' });
  }

  onSelectSuggestedFilter(selectedsuggestedFilter: string) {
    this.selectedsuggestedFilter = selectedsuggestedFilter;
  }
  liveStreamStats(id) {
    console.log('UUID: ', this.dataService.uuidPerson);
    if (id) {
      if (!this.cookieService.get(`eventID${id}`)) {
        this.cookieService.put(`eventID${id}`, id)
        let uuid = ''
        if (this.dataService.authenticated) {
          uuid = this.dataService.uuidPerson;
        } else {
          uuid = null
        }
        this.httpService.groupMeetingStats(id, this.dataService.currentCountryCode, this.dataService.currentCity, this.dataService.currentIPAddress, uuid).subscribe((data) => {
          // console.log(data)
        });
      } else {
        //console.log('event id exists')
      }
    }
  }

  getPPT(fileName: string) {
    this.httpService.S3BucketOperationsPublic('getDownloadUrl', fileName, this.fileBucket, 'webinar-archives').subscribe((response: any) => {
      if (response.data) {
        this.httpService.getFileDownloadSignedUrl(response.data).subscribe(data => {
          // console.log('download-file', data)
          const link = document.createElement('a');
          const blob = new Blob([data]);
          const url = URL.createObjectURL(blob)

          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      }
    });
  }
}
