<div *ngIf="panelists" role="main" aria-label="panelists" class="panelist">
    <div class="panelist-card relative" *ngFor="let panelist of panelists">
        <img class="panelist-card-image" [alt]="panelist?.description" src="{{environment.publicBucketUrl}}event-panelists/{{ panelist?.image ? panelist.image : 'no-logo.jpeg' }}">
        <div class="panelist-card-content">
            <div aria-label="panelist name" role="contentinfo" class="panelist-card-content-name">
                {{ panelist?.name }}
            </div>
            <div aria-label="panelist title" role="definition"  class="panelist-card-content-title">
                {{ panelist?.designation }}
            </div>
            <div class="row">
                <button type="button" aria-label="open panelist bio button"  *ngIf="panelist?.description != null" class="custom-btn grow"
                    (click)="openPersonInfoDialog(panelist)" style="padding-left: .5rem; padding-right: .5rem; margin-left: 1.5rem; margin-right: 0">View Bio</button>
                <div aria-label="panelist social media links" role="link" class="panelist-card-content-social">
                    <div *ngFor="let platform of panelist?.social">
                        <a *ngIf="platform?.type == 'twitter' && platform?.url != null" href="{{ platform?.url }}" target="_blank"><i
                                class="fab fa-twitter" aria-label='twitter' title='twitter' role="link" style="color: #1da1f2"></i></a>
                        <a *ngIf="platform?.type == 'facebook' && platform?.url != null" href="{{ platform?.url }}" target="_blank"><i
                                class="fab fa-facebook-square" aria-label='facebook' role="link" title='facebook' style="color: #3b5998"></i></a>
                        <a *ngIf="platform?.type == 'instagram' && platform?.url != null" href="{{ platform?.url }}" target="_blank"><i
                                class="fab fa-instagram" aria-label='instagram' role="link" title='instagram' style="color: #404040"></i></a>
                        <a *ngIf="platform?.type == 'linkedin' && platform?.url != null" href="{{ platform?.url }}" target="_blank"><i
                                class="fab fa-linkedin" aria-label='linkedin' role="link" title='linkedin'  style="color: #0e76a8"></i></a>
                        <a *ngIf="platform?.type == 'youtube' && platform?.url != null" href="{{ platform?.url }}" target="_blank"><i
                                class="fab fa-youtube" aria-label='youtube' title='youtube' role="link" style="color: #c4302b"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>