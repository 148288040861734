<!-- <div *ngIf="data.authenticated" [ngClass]="{
    'image-top-folded': !data.sideBarOpen,
    'image-top-unfolded': data.sideBarOpen
  }" role="toolbar"></div> -->
<mat-nav-list>

  <!-- 
    ██╗  ██╗ ██████╗ ███╗   ███╗███████╗
    ██║  ██║██╔═══██╗████╗ ████║██╔════╝
    ███████║██║   ██║██╔████╔██║█████╗  
    ██╔══██║██║   ██║██║╚██╔╝██║██╔══╝  
    ██║  ██║╚██████╔╝██║ ╚═╝ ██║███████╗
    ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝
   -->

  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/']"
    [routerLinkActiveOptions]="{ exact: true }" (click)="data.sideBarOpen = false" aria-label="home" role="link">
    <mat-icon class="material-icons-outlined">home</mat-icon>
    <span class="menu-text" *ngIf="data.sideBarOpen">Home</span>
  </a>

  <a *ngIf="!data.sideBarOpen" matTooltip="Home" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }"
    aria-label="home" role="link">
    <mat-icon class="material-icons-outlined">home</mat-icon>
  </a>

  <!-- <hr [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"> -->

  <!-- <div *ngIf="data.authenticated" [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"></div> -->

  <!-- 
     ██████╗ ██████╗  █████╗ ██████╗ ██╗  ██╗███████╗
    ██╔════╝ ██╔══██╗██╔══██╗██╔══██╗██║  ██║██╔════╝
    ██║  ███╗██████╔╝███████║██████╔╝███████║███████╗
    ██║   ██║██╔══██╗██╔══██║██╔═══╝ ██╔══██║╚════██║
    ╚██████╔╝██║  ██║██║  ██║██║     ██║  ██║███████║
     ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝╚══════╝
   -->
  <div style="border-top: 1px solid hsla(0, 0%, 100%, 0.5); height: 1px"></div>
  <span *ngIf="!data.authenticated">
    <a *ngIf="!data.sideBarOpen" mat-list-item [ngClass]="{
        'menu-item-active':
          data.currentRoute == '/academic-family-tree' 
      }">
      <mat-icon class="material-icons-outlined">bubble_chart</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen" mat-list-item (click)="toggleGraphs()" [ngClass]="{
        'menu-item-active':
        data.currentRoute == '/academic-family-tree'
      }">
      <mat-icon class="material-icons-outlined">bubble_chart</mat-icon>
      <span class="menu-text" *ngIf="data.sideBarOpen">
        Data Models
        <mat-icon *ngIf="data.graphsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!data.graphsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_right</mat-icon>
      </span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/academic-family-tree']" aria-label="academic family tree" role="link">
      <i style="margin: 10px 10px 10px 12px; color: white" class="fa fa-users"></i>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/academic-family-tree']" (click)="closeSidebar()"
      aria-label="academic family tree" role="link">
      <i style="margin: 10px 12px 10px 12px; color: white" class="fa fa-users"></i>
      <span class="menu-text" style="width: 100%" *ngIf="data.sideBarOpen">Academic Family Tree</span>
    </a>
    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" aria-label="volunteer" role="link"
    [routerLink]="['/volunteer']">
    <i style="margin: 10px 10px 10px 12px; color: white;" class="fa fa-handshake-o"></i>
  </a>

  <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
    class="second-branch" [routerLink]="['/volunteer']" (click)="closeSidebar()" aria-label="volunteer"
    role="link">
    <i style="margin: 10px 17px 10px 12px; color: white;" class="fa fa-handshake-o"></i>
    <span class="menu-text" style="width: 100%;" *ngIf="data.sideBarOpen">Volunteer</span>
  </a>
  </span>

  <span *ngIf="data.authenticated">
    <a *ngIf="!data.sideBarOpen" mat-list-item [ngClass]="{
        'menu-item-active':
          data.currentRoute == '/faculty' ||
          data.currentRoute == '/alumni' ||
          data.currentRoute == '/academic-family-tree' ||
          data.currentRoute == '/publication'
      }">
      <mat-icon class="material-icons-outlined">bubble_chart</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen" mat-list-item (click)="toggleGraphs()" [ngClass]="{
        'menu-item-active':
        data.currentRoute == '/faculty' ||
        data.currentRoute == '/alumni' ||
        data.currentRoute == '/academic-family-tree' ||
        data.currentRoute == '/publication'
      }">
      <mat-icon class="material-icons-outlined">bubble_chart</mat-icon>
      <span class="menu-text" *ngIf="data.sideBarOpen">
        Data Models
        <mat-icon *ngIf="data.graphsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!data.graphsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_right</mat-icon>
      </span>
    </a>

    <a *ngIf="data.authenticated && !data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/faculty']" aria-label="faculty" role="link">
      <mat-icon class="material-icons-outlined">work_outline</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/faculty']" (click)="closeSidebar()" aria-label="faculty" role="link" class="second-branch">
      <mat-icon class="material-icons-outlined">work_outline</mat-icon>
      <span class="menu-text">Faculty</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/alumni']"
      aria-label="alumni" role="link">
      <mat-icon class="material-icons-outlined">school</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/alumni']" (click)="closeSidebar()" aria-label="alumni" role="link" class="second-branch">
      <mat-icon class="material-icons-outlined">school</mat-icon>
      <span class="menu-text" *ngIf="data.sideBarOpen">Alumni</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/academic-family-tree']" aria-label="academic family tree" role="link">
      <i style="margin: 10px 10px 10px 12px; color: white" class="fa fa-users"></i>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/academic-family-tree']" (click)="closeSidebar()"
      aria-label="academic family tree" role="link">
      <i style="margin: 10px 12px 10px 12px; color: white" class="fa fa-users"></i>
      <span class="menu-text" style="width: 100%" *ngIf="data.sideBarOpen">Academic Family Tree</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/mentoring-relation']" aria-label="mentoring realtion" role="link">
      <i style="margin: 10px 10px 10px 12px; color: white" class="fa fa-people-arrows"></i>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/mentoring-relation']" (click)="closeSidebar()"
      aria-label="mentoring realtion" role="link">
      <!-- <i style="margin: 10px 17px 10px 12px; color: white" class="fa fa-people-arrows"></i> -->
      <i style="margin: 10px 12px 10px 12px; color: white" class="fa fa-people-arrows"></i>
      <span class="menu-text" style="width: 100%" *ngIf="data.sideBarOpen">Mentoring Relation</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" aria-label="publication" role="link"
      [routerLink]="['/publication']">
      <i style="margin: 10px 10px 10px 12px; color: white;" class="fa fa-book"></i>
    </a>

    <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/publication']" (click)="closeSidebar()" aria-label="publication"
      role="link">
      <i style="margin: 10px 17px 10px 12px; color: white;" class="fa fa-book"></i>
      <span class="menu-text" style="width: 100%;" *ngIf="data.sideBarOpen">Publication</span>
    </a>
    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" aria-label="volunteer" role="link"
    [routerLink]="['/volunteer']">
    <i style="margin: 10px 10px 10px 12px; color: white;" class="fa fa-handshake-o"></i>
  </a>

  <a *ngIf="data.sideBarOpen && data.graphsOpen" mat-list-item routerLinkActive="menu-item-active"
    class="second-branch" [routerLink]="['/volunteer']" (click)="closeSidebar()" aria-label="volunteer"
    role="link">
    <i style="margin: 10px 17px 10px 12px; color: white;" class="fa fa-handshake-o"></i>
    <span class="menu-text" style="width: 100%;" *ngIf="data.sideBarOpen">Volunteer</span>
  </a>
  </span>

  <!-- <div [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"></div> -->

  <!-- <hr [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"> -->

  <!-- 
      ██████╗ ██╗   ██╗██████╗ ██╗     ██╗ ██████╗     ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗ ███████╗
      ██╔══██╗██║   ██║██╔══██╗██║     ██║██╔════╝    ██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗██╔════╝
      ██████╔╝██║   ██║██████╔╝██║     ██║██║         ██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝███████╗
      ██╔═══╝ ██║   ██║██╔══██╗██║     ██║██║         ██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝ ╚════██║
      ██║     ╚██████╔╝██████╔╝███████╗██║╚██████╗    ╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║     ███████║
      ╚═╝      ╚═════╝ ╚═════╝ ╚══════╝╚═╝ ╚═════╝     ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝     ╚══════╝
     -->
  <div style="border-top: 1px solid hsla(0, 0%, 100%, 0.5); height: 1px"></div>
  <span>
    <a *ngIf="!data.sideBarOpen" mat-list-item [ngClass]="{
        'menu-item-active':
          data.currentRoute == '/profound-impact-day' ||
          data.currentRoute == '/profound-insights'
      }">
      <mat-icon class="material-icons-outlined">public</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen" mat-list-item (click)="togglePublicGroups()" [ngClass]="{
        'menu-item-active':
        data.currentRoute == '/profound-impact-day' ||
        data.currentRoute == '/profound-insights'
      }">
      <mat-icon class="material-icons-outlined">public</mat-icon>
      <span class="menu-text" *ngIf="data.sideBarOpen">
        Public Groups
        <mat-icon *ngIf="data.publicGroupsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!data.publicGroupsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_right</mat-icon>
      </span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/profound-impact-day-2022']" aria-label="profound impact day" role="link">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="profound impact logo" role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/profound-impact-day-2022']" (click)="closeSidebar()"
      aria-label="profound impact day" role="link">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="profound impact logo" role="img" />
      <span class="menu-text">Profound Impact Day-2022</span>
    </a>


    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/profound-impact-day-2021']" aria-label="profound impact day" role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="profound impact logo" role="img" />
  </a>

  <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
    class="second-branch" [routerLink]="['/profound-impact-day-2021']" (click)="closeSidebar()"
    aria-label="profound impact day" role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="profound impact logo" role="img" />
    <span class="menu-text">Profound Impact Day-2021</span>
  </a>
  <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/profound-impact-day-2020']" aria-label="profound impact day" role="link">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="profound impact logo" role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      class="second-branch" [routerLink]="['/profound-impact-day-2020']" (click)="closeSidebar()"
      aria-label="profound impact day" role="link">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="profound impact logo" role="img" />
      <span class="menu-text">Profound Impact Day-2020</span>
    </a>










    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/profound-insights']"
      aria-label="profound-insights page" role="link">
      <img class="side-bar-group-image" src="../../assets/images/LightBulb-2.png" alt="profound insights logo"
        role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/profound-insights']" (click)="closeSidebar()" aria-label="profound insights page" role="link"
      class="second-branch">
      <img class="side-bar-group-image" src="../../assets/images/LightBulb-2.png" alt="profound insights logo"
        role="img" />
      <span class="menu-text">Profound Insights</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/iwd2021wr']"
      aria-label="iwd page" role="link">
      <img class="side-bar-group-image" src="../../assets/images/IWD-logo.png" alt="iwd logo"
        role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/iwd2021wr']" (click)="closeSidebar()" aria-label="iwd page" role="link"
      class="second-branch">
      <img class="side-bar-group-image" src="../../assets/images/IWD-logo.png" alt="iwd logo"
        role="img" />
      <span class="menu-text">International<br>Women's Day</span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/wewdc']"
      aria-label="wewdc page" role="link">
      <img class="side-bar-group-image" style="width: 18px; height: 25px; margin: 0px 13px 0px 11px;" src="../../assets/images/wewdc 2.png" alt="iwd logo"
        role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.publicGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/wewdc']" (click)="closeSidebar()" aria-label="wewdc page" role="link"
      class="second-branch">
      <img class="side-bar-group-image" style="width: 18px; height: 25px;" src="../../assets/images/wewdc 2.png" alt="iwd logo"
        role="img" />
      <span class="menu-text">Women Empowering Women</span>
    </a>

  </span>

  <!-- <div [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"></div> -->

  <!-- <hr [ngClass]="{
    'divider-folded': !data.sideBarOpen,
    'divider-unfolded': data.sideBarOpen
  }"> -->

  <!-- 
      ██████╗ ██████╗ ██╗██╗   ██╗ █████╗ ████████╗███████╗     ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗ ███████╗
      ██╔══██╗██╔══██╗██║██║   ██║██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗██╔════╝
      ██████╔╝██████╔╝██║██║   ██║███████║   ██║   █████╗      ██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝███████╗
      ██╔═══╝ ██╔══██╗██║╚██╗ ██╔╝██╔══██║   ██║   ██╔══╝      ██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝ ╚════██║
      ██║     ██║  ██║██║ ╚████╔╝ ██║  ██║   ██║   ███████╗    ╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║     ███████║
      ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═══╝  ╚═╝  ╚═╝   ╚═╝   ╚══════╝     ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝     ╚══════╝
  -->
  <!-- <div style="border-top: 1px solid hsla(0, 0%, 100%, 0.5); height: 1px"></div>
  <span>
    <a *ngIf="!data.sideBarOpen" mat-list-item [ngClass]="{
          'menu-item-active':
          data.currentRoute == '/test-private-group'
        }">
      <mat-icon class="material-icons-outlined">public_off</mat-icon>
    </a>

    <a *ngIf="data.sideBarOpen" mat-list-item (click)="togglePrivateGroups()" [ngClass]="{
          'menu-item-active':
            data.currentRoute == '/test-private-group'
        }">
      <mat-icon class="material-icons-outlined">public_off</mat-icon>
      <span class="menu-text" *ngIf="data.sideBarOpen">
        Private Groups
        <mat-icon *ngIf="data.privateGroupsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!data.privateGroupsOpen" style="margin: 0px; font-size: 20px">keyboard_arrow_right</mat-icon>
      </span>
    </a>

    <a *ngIf="!data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/test-private-group']" aria-label="private test group" role="link">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="profound impact logo" role="img" />
    </a>

    <a *ngIf="data.sideBarOpen && data.privateGroupsOpen" mat-list-item routerLinkActive="menu-item-active"
      [routerLink]="['/test-private-group']" (click)="closeSidebar()" aria-label="private test group" role="link"
      class="second-branch">
      <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
        alt="private test group" role="img" />
      <span class="menu-text">Private Test Group</span>
    </a>

  </span> -->

  <!-- 
   ██████╗ ██╗     ██████╗     ███████╗██╗██████╗ ███████╗██████╗  █████╗ ██████╗ 
  ██╔═══██╗██║     ██╔══██╗    ██╔════╝██║██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔══██╗
  ██║   ██║██║     ██║  ██║    ███████╗██║██║  ██║█████╗  ██████╔╝███████║██████╔╝
  ██║   ██║██║     ██║  ██║    ╚════██║██║██║  ██║██╔══╝  ██╔══██╗██╔══██║██╔══██╗
  ╚██████╔╝███████╗██████╔╝    ███████║██║██████╔╝███████╗██████╔╝██║  ██║██║  ██║
   ╚═════╝ ╚══════╝╚═════╝     ╚══════╝╚═╝╚═════╝ ╚══════╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝
 -->

  <!-- <div *ngIf="data.authenticated" [ngClass]="{
      'profile-card-folded': !data.sideBarOpen,
      'profile-card-unfolded': data.sideBarOpen && data.authenticated,
      'profile-card-unfolded-unauthenticated':
        data.sideBarOpen && !data.authenticated
    }">
    <div [ngClass]="{
        'image-circle-folded': !data.sideBarOpen,
        'image-circle-unfolded': data.sideBarOpen
      }">
      <img matTooltip="{{ data.currentUser.firstName }} {{
          data.currentUser.lastName
        }} - {{ data.currentUser.profileStatus }}" matTooltipPosition="after"
        *ngIf="data.currentUser && data.authenticated" [src]="
          data.currentUser && data.currentUser.logoUrl != ''
            ? 'https://' +
              bucket +
              '.s3.amazonaws.com/' +
              data.currentUser.logoUrl
            : 'assets/images/uidesign/user.png'
        " [alt]="username" onError="this.src='assets/images/uidesign/user.png';" role="img" aria-label="user image" />
      <img *ngIf="!data.authenticated" src="../assets/images/icon-pi.png" alt="Profound Impact Logo"
        aria-label="default user image" role="img" />
    </div>
    <div *ngIf="data.sideBarOpen && data.authenticated && data.currentUser" class="header">
      <h4 *ngIf="data.currentUser.firstName != ''">
        {{ data.currentUser.firstName }}
        {{
          data.currentUser.lastName !== '' &&
          data.currentUser.lastName !== undefined &&
          data.currentUser.lastName !== 'undefined' &&
          data.currentUser.lastName !== 'null' &&
          data.currentUser.lastName !== null
            ? data.currentUser.lastName
            : ''
        }}
      </h4>
      <h4 class="email-gray">{{ data.currentUser.email }}</h4>
    </div>
  </div>
  <div *ngIf="data.profileCompletion && data.sideBarOpen && data.authenticated">
    <p style="
        text-align: center;
        color: #d6d6d6;
        padding: 0px 5px 10px 5px;
        margin: 0px;
      " role="status">
      Your profile is {{ data.profileCompletion.totalCompleted }}% complete
    </p>

    <div class="progress" style="
        width: 80%;
        margin-left: 10%;
        border-radius: 10px;
        margin-bottom: 10px;
      ">
      <div class="progress-bar progress-bar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"
        [ngStyle]="{ width: data.completionWidth }" style="height: 20px; background-color: #2abb25; text-align: center">
        <span *ngIf="data.profileCompletion.totalCompleted > 25">{{ data.profileCompletion.totalCompleted }}%</span>
      </div>
    </div>
  </div>

  <div *ngIf="data.sideBarOpen && data.authenticated">
    <p style="
        text-align: center;
        color: rgb(80, 80, 80);
        background-color: #f8f8f8;
        padding: 6px;
        margin: 0px;
        font-weight: 550;
      ">
      PRIVACY MODE
    </p>

    <div *ngIf="data.currentUser">
      <div *ngIf="data.currentUser.profileStatus === 'private'" style="text-align: center">
        <p style="
            display: inline-block;
            color: #d6d6d6;
            font-size: 14px;
            padding: 5px 5px 0px 5px;
            margin-bottom: 0px;
          " role="status">
          Your current status:
          <i class="fa fa-lock pull-right" style="margin-top: 3px"></i> Private
        </p>
        <p style="
            color: #d6d6d6;
            padding: 5px 10px 10px 10px;
            margin: 0px;
            word-wrap: normal;
            font-size: 11px;
          ">
          You can make your information visible to other users in the platform
          in the settings tab
        </p>
      </div>
      <div *ngIf="data.currentUser.profileStatus === 'public'" style="text-align: center">
        <p style="
            display: inline-block;
            color: #2abb25;
            font-size: 14;
            padding: 5px 5px 0px 5px;
            margin-bottom: 0px;
          " role="status">
          <span style="color: #d6d6d6">Your current status: </span><i class="fa fa-unlock-alt pull-right"
            style="margin-top: 3px"></i>
          Public
        </p>
        <p style="
            color: #d6d6d6;
            padding: 5px 10px 10px 10px;
            margin: 0px;
            word-wrap: normal;
            font-size: 11px;
          ">
          You can make your information hidden to other users in the platform in
          the settings tab
        </p>
      </div>
    </div>
  </div>

  <mat-divider *ngIf="data.authenticated" [ngClass]="{
      'divider-folded': !data.sideBarOpen,
      'divider-unfolded': data.sideBarOpen
    }" role="separator"></mat-divider> -->

  <!-- <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/']"
    [routerLinkActiveOptions]="{ exact: true }" (click)="closeSidebar()" aria-label="home" role="link">
    <mat-icon class="material-icons-outlined">home</mat-icon>
    <span class="menu-text" *ngIf="data.sideBarOpen">Home</span>
  </a>

  <a *ngIf="!data.sideBarOpen" matTooltip="Home" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }"
    aria-label="home" role="link">
    <mat-icon class="material-icons-outlined">home</mat-icon>
  </a> -->

  <!-- <mat-divider *ngIf="!data.sideBarOpen || !data.authenticated" [ngClass]="{
      'divider-folded': !data.sideBarOpen,
      'divider-unfolded': data.sideBarOpen
    }" role="separator"></mat-divider> -->
  <!-- 
  <p *ngIf="data.sideBarOpen && data.authenticated" style="
      text-align: center;
      color: rgb(80, 80, 80);
      background-color: #f8f8f8;
      padding: 6px;
      margin: 0px;
      font-weight: 550;
    ">
    DATA MODELS
  </p>

  <a matTooltip="Faculty" matTooltipPosition="after" *ngIf="data.authenticated && !data.sideBarOpen" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/faculty']" aria-label="faculty" role="link">
    <mat-icon class="material-icons-outlined">work_outline</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/faculty']" (click)="closeSidebar()" aria-label="faculty" role="link">
    <mat-icon class="material-icons-outlined">work_outline</mat-icon>
    <span class="menu-text">Faculty</span>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/alumni']" (click)="closeSidebar()" aria-label="alumni" role="link">
    <mat-icon class="material-icons-outlined">school</mat-icon>
    <span class="menu-text" *ngIf="data.sideBarOpen">alumni</span>
  </a>

  <a matTooltip="Alumni" matTooltipPosition="after" *ngIf="data.authenticated && !data.sideBarOpen" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/alumni']" aria-label="alumni" role="link">
    <mat-icon class="material-icons-outlined">school</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/academic-family-tree']" (click)="closeSidebar()" aria-label="academic family tree" role="link">
    <i style="margin: 10px 17px 10px 12px; color: white" class="fa fa-users"></i>
    <span class="menu-text" style="width: 100%" *ngIf="data.sideBarOpen">Academic Family Tree</span>
  </a>

  <a matTooltip="Academic Family Tree" matTooltipPosition="after" *ngIf="data.authenticated && !data.sideBarOpen"
    mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/academic-family-tree']"
    aria-label="academic family tree" role="link">
    <i style="margin: 10px 10px 10px 12px; color: white" class="fa fa-users"></i>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/publication']" (click)="closeSidebar()" aria-label="publication" role="link">
    <i style="margin: 10px 17px 10px 12px; color: white;" class="fa fa-book"></i>
    <span class="menu-text" style="width: 100%;" *ngIf="data.sideBarOpen">Publication</span>
  </a>

  <a matTooltip="Publication" matTooltipPosition="after" *ngIf="data.authenticated && !data.sideBarOpen" mat-list-item
    routerLinkActive="menu-item-active" aria-label="publication" role="link" [routerLink]="['/publication']">
    <i style="margin: 10px 10px 10px 12px; color: white;" class="fa fa-book"></i>
  </a>

  <mat-divider *ngIf="!data.sideBarOpen || !data.authenticated" [ngClass]="{
      'divider-folded': !data.sideBarOpen,
      'divider-unfolded': data.sideBarOpen
    }" role="separator"></mat-divider>

  <p *ngIf="data.sideBarOpen && data.authenticated" style="
      text-align: center;
      color: rgb(80, 80, 80);
      background-color: #f8f8f8;
      padding: 6px;
      margin: 0px;
      font-weight: 550;
    ">
    MY GROUPS
  </p>

  <a *ngIf="!data.sideBarOpen" matTooltip="WWIN" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/wwin']" aria-label="wwin" role="link">
    <img class="side-bar-group-image" src="../../assets/images/win.png" alt="waterloo womens impact network logo"
      role="img" />
  </a>
  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/wwin']"
    (click)="closeSidebar()" aria-label="wwin" role="link">
    <img class="side-bar-group-image" src="../../assets/images/win.png" alt="waterloo womens impact network logo"
      role="img" />
    <span class="menu-text">WWIN</span>
  </a>
  <a *ngIf="!data.sideBarOpen" matTooltip="Class of 2020" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/classof2020']" aria-label="class of 2020" role="link">
    <img class="side-bar-group-image" src="../../assets/images/graduation-cap.png" alt="class of 2020 logo"
      role="img" />
  </a>
  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/classof2020']"
    (click)="closeSidebar()" aria-label="class of 2020" role="link">
    <img class="side-bar-group-image" src="../../assets/images/graduation-cap.png" alt="class of 2020 logo"
      role="img" />
    <span class="menu-text">Class of 2020</span>
  </a>
  <a *ngIf="!data.sideBarOpen" matTooltip="John C. Beatty" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/john-beatty']" aria-label="john beatty" role="link">
    <img class="side-bar-group-image" src="../../assets/images/flamingo-1.png" alt="john beatty logo" role="img" />
  </a>
  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/john-beatty']"
    (click)="closeSidebar()" aria-label="john beatty" role="link">
    <img class="side-bar-group-image" src="../../assets/images/flamingo-1.png" alt="john beatty logo" role="img" />
    <span class="menu-text">John C. Beatty</span>
  </a>

  <a *ngIf="!data.sideBarOpen" matTooltip="Profound Impact Day" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/profound-impact-day']" aria-label="profound impact day"
    role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="profound impact logo" role="img" />
  </a>
  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/profound-impact-day']"
    (click)="closeSidebar()" aria-label="profound impact day" role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="profound impact logo" role="img" />
    <span class="menu-text">Profound Impact Day</span>
  </a> -->
  <!-- 
  <a *ngIf="!data.sideBarOpen" matTooltip="Private Test Group" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/test-private-group']" aria-label="private test group"
    role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="profound impact logo" role="img" />
  </a>
  <a *ngIf="data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/test-private-group']"
    (click)="closeSidebar()" aria-label="private test group" role="link">
    <img style="height: 20px; width: 20px; margin: 0px 15px 0px 9px" src="../../assets/images/icon-pi.png"
      alt="private test group" role="img" />
    <span class="menu-text">Private Test Group</span>
  </a> -->

  <!-- <mat-divider *ngIf="!data.sideBarOpen || !data.authenticated" [ngClass]="{
      'divider-folded': !data.sideBarOpen,
      'divider-unfolded': data.sideBarOpen
    }" role="separator"></mat-divider> -->
  <!-- 
  <p *ngIf="data.sideBarOpen && data.authenticated" style="
      text-align: center;
      color: rgb(80, 80, 80);
      background-color: #f8f8f8;
      padding: 6px;
      margin: 0px;
      font-weight: 550;
    ">
    ACCOUNT SETTINGS
  </p>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/profile']" (click)="closeSidebar()" aria-label="profile" role="link">
    <mat-icon class="material-icons-outlined">account_box</mat-icon>
    <span class="menu-text">Profile</span>
  </a>

  <a *ngIf="data.authenticated && !data.sideBarOpen" matTooltip="Profile" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/profile']" aria-label="profile" role="link">
    <mat-icon class="material-icons-outlined">account_box</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/import']" (click)="closeSidebar()" aria-label="import profile" role="link">
    <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
    <span class="menu-text">Import Profile</span>
  </a>

  <a *ngIf="data.authenticated && !data.sideBarOpen" matTooltip="Import Profile" matTooltipPosition="after"
    mat-list-item routerLinkActive="menu-item-active" [routerLink]="['/import']" aria-label="import profile"
    role="link">
    <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/settings']" (click)="closeSidebar()" aria-label="settings" role="link">
    <mat-icon>settings</mat-icon>
    <span class="menu-text">Settings</span>
  </a>

  <a *ngIf="data.authenticated && !data.sideBarOpen" matTooltip="Settings" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/settings']" aria-label="settings" role="link">
    <mat-icon>settings</mat-icon>
  </a>

  <a *ngIf="data.authenticated && !data.sideBarOpen" matTooltip="Contact" matTooltipPosition="after" mat-list-item
    routerLinkActive="menu-item-active" [routerLink]="['/contact']" aria-label="contact" role="link">
    <mat-icon>mail</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item routerLinkActive="menu-item-active"
    [routerLink]="['/contact']" (click)="closeSidebar()" aria-label="contact" role="link">
    <mat-icon>mail</mat-icon>
    <span class="menu-text">Contact</span>
  </a>

  <a *ngIf="data.authenticated && !data.sideBarOpen" matTooltip="Logout" matTooltipPosition="after" mat-list-item
    (tap)="logout()" aria-label="logout" role="link">
    <mat-icon>logout</mat-icon>
  </a>

  <a *ngIf="data.authenticated && data.sideBarOpen" mat-list-item (tap)="logout()" aria-label="logout" role="link">
    <mat-icon>logout</mat-icon>
    <span class="menu-text">Logout</span>
  </a>

  <a *ngIf="!data.authenticated && !data.sideBarOpen" matTooltip="Login" matTooltipPosition="after" mat-list-item
    (tap)="login()" aria-label="login" role="link">
    <mat-icon>login</mat-icon>
  </a>

  <a *ngIf="!data.authenticated && data.sideBarOpen" mat-list-item (tap)="login()" aria-label="login" role="link">
    <mat-icon>login</mat-icon>
    <span class="menu-text">Login</span>
  </a> -->
</mat-nav-list>