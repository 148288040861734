<div id="pi-page" class="fill-view" role="region" aria-label="pi page">
    <!-- <app-branding-separator bgc1="#df2498" bgc2="#ffbeef" bgc3="#ffd54f" bgc4="#dfdfdf"></app-branding-separator> -->
    <app-branding-separator
    bgc1="rgb(63,138,77)"
    bgc2="rgb(63,138,77)"
    bgc3="rgb(63,138,77)"
    bgc4="rgb(63,138,77)"
  ></app-branding-separator>
   <app-slider id="2"></app-slider>
    <div class="pi-container">
      <div class="pi-card-1" title="event agenda">
        <app-card title="Impact and Beyond" 
        subtitle="The profound impact of early discovery, experimentation, and disruption through research and invention"
        backgroundColorClass="blue-gradient-background" type="hidden">
        <div class="p2r">
          <!-- <div class="pi-card--content">
            <a href="https://us02web.zoom.us/j/82461270904" target="_blank"
            (click)="connectToZoom(400)" *ngIf="dataService.jwt"
            aria-label="link to join the zoom event">
              <button type="button" class="button button--pink pi-card--zoom-button" aria-label="link to join the zoom event" role="button"> Click Here To Join Zoom Event</button>
            </a>
            <a href="https://us02web.zoom.us/j/82461270904" target="_blank"
            (click)="connectToZoomPublic(400)" *ngIf="!dataService.jwt">
              <button type="button" class="button button--pink pi-card--zoom-button" aria-label="link to join the zoom event" role="button">Click Here To Join Zoom Event </button>
            </a>
           
          </div> -->
          <div class="card--h5 fw600 text-center pi-card--date-text">Date: September 14, 2020 @ 4:00 PM EST</div>
            <ul class="pi-card--timezone-list">
              <li>
                <div class="row">
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Waterloo/NY</div>
                    <div class="card--time-zone-badge--yellow">
                      4:00 PM
                    </div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Van./SF</div>
                    <div class="card--time-zone-badge--yellow">
                      1:00 PM
                    </div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">London</div>
                    <div class="card--time-zone-badge--yellow">
                      9:00 PM
                    </div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">New Delhi</div>
                    <div class="card--time-zone-badge--yellow">
                      1:30 AM
                    </div>
                  </div>
                  <div class="col-5ths">
                    <div class="card--time-zone-title">Hong Kong</div>
                    <div class="card--time-zone-badge--yellow">
                      4:00 AM
                    </div>
                  </div>
                </div>
              </li>
                <hr />
                <li>
                <p class="card--h5-small taj">
                  Researchers today build on the knowledge and
                  discoveries made by those who have come before them.
                  How can today’s researchers light the early pathways
                  and curiosities for the research breakthroughs of the
                  future? How can we demonstrate the impact and
                  potential of the yet-to-be known? And, what if any,
                  role does academia, industry, the Faculty of
                  Mathematics, and Canada play in increasing the
                  discovery journey to these new frontiers?
                  <br /><br />
                  Inspired by the late Professor Scott A. Vanstone and
                  the impact that his mentorship and guidance continue
                  to have in the world of mathematics and across various
                  fields, Profound Impact was created to engage the
                  untapped capacity of scholar and alumni networks in
                  hopes of inspiring collaborative solutions to global
                  problems.
                  <br /><br />
                  Declaring September 14th as Profound Impact Day will
                  recognize and celebrate the global impact of the
                  Faculty of Mathematics at the University of Waterloo,
                  its role as an international centre of research and
                  development, and the Waterloo Digital Math community.
                </p>
                <hr />
                <div class="card--h1"> AGENDA </div>
              </li>  
              <li>
                <div class="card--h3 ts">Welcome</div>
                <div class="card--small lh pi-card--agenda-subtext-color">
                  City of Waterloo Mayor Dave Jaworsky
                  <br/>
                  University of Waterloo BMath ‘88
                </div>
              </li>
              <hr />
              <li>
                <div class="card--h3 ts">Introduction to Profound Impact Day</div>
                <div class="card--small lh pi-card--agenda-subtext-color">
                  Sherry Shannon-Vanstone                
                  <br/>
                  President and CEO of Profound Impact
                </div>
              </li>
              <hr />
              <li>
                <div class="card--h3 ts">Faculty of Mathematics’ Innovation Agenda</div>
                <div class="card--small lh pi-card--agenda-subtext-color">
                  Camelia Nunez
                  <br/>
                  Faculty of Mathematics’ Director of Innovation and Research Partnerships              
                </div>
              </li>
              <hr />
              <li>
                <div class="card--h3 ts">Profound impact of early discovery, experimentation,
                  and disruption through research and invention</div>
                <div class="card--small lh pi-card--agenda-subtext-color">
                  Professor Michele Mosca
                  <br/>
                  BMath '95 - co-founder of Waterloo's Institute for
                  Privacy and Security and the Institute for Quantum
                  Computing, and a leader in the field of quantum
                  information              
                </div>
              </li>
              <hr />
            </ul>
        </div>
        </app-card>
      </div>
      <div class="card pi-card-2" title="commuity discussion board">
        <app-card title="Welcome to the Profound Impact Day Community Discussion Board" subtitle="Conversations and connections start here"
        backgroundColorClass="light-grey-gradient-background" type="hidden">
        <div class="h2r">
          <div *ngIf="!dataService.authenticated" class="card--warning">
            You must be logged in to comment
          </div>
        </div>
        <app-discussion-board >
        </app-discussion-board>
      </app-card>
      </div>
      <div class="card pi-card-3" title="twitter feed">
        <app-card title="UWaterloo Math Twitter Feed" backgroundColorClass="blue-twitter-gradient-background" type="scroll">
          <div class="p2r">
            <app-twitterfeed aria-label="university of waterloo math twitter feed element"></app-twitterfeed>
          </div>
        </app-card>
      </div>
      <div class="card pi-card-4" title="event activity graph">
        <app-card title="Profound Impact Day Activity" backgroundColorClass="light-pink-gradient-background" type="hidden">
          <app-event-graph id="piDay"></app-event-graph>
        </app-card> 
      </div>
    </div>
  </div>
  