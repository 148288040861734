import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EventGraphService } from './event-graph.service';

@Component({
  selector: 'app-event-graph',
  templateUrl: './event-graph.component.html',
  styleUrls: ['./event-graph.component.scss']
})
export class EventGraphComponent implements OnInit, AfterViewInit {

  @Input() id: string = 'WWIN2020';
  constructor(
    private zone: NgZone,
    private eg: EventGraphService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
    setTimeout(() => {
      this.eg.eventGraph(this.id)
    }, 200)
  }
}
