import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private amplify: AmplifyService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return this.amplify.auth().currentAuthenticatedUser().then(
      data => {
        if (data && data.attributes['custom:uuidPerson']) {
          return true;
        } else {
          this.router.navigate(['./']);
          return false;
        }
      }
    ).catch(
      error => {
        this.router.navigate(['./']);
        return false;
      }
    );
  }

}
