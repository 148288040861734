<div id="profile-section-social-media" class="profile__content">
  <div #card class="profile__section__card card">
    <!-- FRONT -->
    <div class="profile__section__list" *ngIf="!flipCard">
      <div class="profile__section__list--header">
        <div class="profile__section__list--heading">Social Media</div>
        <button tabindex="100" type="button" class="button button--add" (click)="gotoAddClick()" aria-label="add certificate"
          role="button">
          <i class="fa fa-plus text-center"></i>
        </button>
      </div>
      <div *ngIf="!socialMediaList || socialMediaList.length == 0">
        <div class="profile__section__empty">
          Click [+] above to add your social media accounts or click the
          [NONE] if you don't have any.
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; padding-top: 20px">
            <button tabindex="101" [disabled]="noneDisabled" class="button button--dark-grey" (click)="saveNone()"
              aria-label="verify none button" role="button">
              NONE
            </button>
          </div>
        </div>
      </div>
      <!-- LIST -->
      <div class="profile__section__list--content" *ngIf="socialMediaList">
        <div class="profile__section__list--item" style="padding: 0 !important" *ngFor="let item of socialMediaList; index as i;">
          <div *ngIf="item.url != 'NONE' || socialMediaList.length == 1" class="profile__section__list--item--text">
            <div class="row" *ngIf="item.status == 'unverified'">
              <span class="unverified-text">Please review and confirm unverified record. Click edit to verify</span>
            </div>
            <div *ngIf="item.url" role="list item property value">
              <i *ngIf="item.url !== 'NONE'" class="fa fa-globe"></i>
              <span *ngIf="item.url !== 'NONE'" class="pl1r">
                Website:
              </span>
              <span *ngIf="item.url === 'NONE'" class="pl1r">Social Media:
              </span>
              <span class="fw500 pl05r">{{ item.url }}</span>
            </div>
            <div *ngIf="item.linkedIn" role="list item property value">
              <i class="fa fa-linkedin"></i><span class="pl1r">LinkedIn:
              </span>
              <span class="fw500 pl05r">{{ item.linkedIn }}</span>
            </div>
            <div *ngIf="item.googlePlus" role="list item property value">
              <i class="fa fa-google-plus"></i><span style="padding-left: 5px">Google+:
              </span>
              <span class="fw500 pl05r">{{ item.googlePlus }}</span>
            </div>
            <div *ngIf="item.facebook" role="list item property value">
              <i class="fa fa-facebook"></i><span style="padding-left: 13px">Facebook:
              </span>
              <span class="fw500 pl05r">{{ item.facebook }}</span>
            </div>
            <div *ngIf="item.twitter" role="list item property value">
              <i class="fa fa-twitter"></i><span style="padding-left: 8px">Twitter:
              </span>
              <span class="fw500 pl05r">{{ item.twitter }}</span>
            </div>
            <div *ngIf="item.instagram" role="list item property value">
              <i class="fa fa-instagram"></i><span style="padding-left: 8px">Instagram:
              </span>
              <span class="fw500 pl05r">{{ item.instagram }}</span>
            </div>
          </div>
          <div *ngIf="item.url != 'NONE' || socialMediaList.length == 1" class="profile__section__list--sidebar">
            <div class="profile__section__list--sidebar--buttons">
              <button tabindex="101" *ngIf="item.url !== 'NONE'" type="button" class="button button--edit"
                (click)="gotoEditClick(item, true)" aria-label="edit certifications button" role="button">
                <i class="fa fa-edit"></i>
              </button>
              <button tabindex="102" type="button" class="button button--delete" (click)="gotoDeleteClick(item)"
                aria-label="delete certifications button" role="button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <span *ngIf="item.status == 'unverified'"
              class="unverified-badge-blue profile__section__list--sidebar--unverified">unverified</span>
          </div>
        </div>
      </div>
    </div>
    <!-- BACK -->
    <div class="profile__section__form" *ngIf="flipCard">
      <div class="profile__section__form--header">
        <div class="profile__section__form--heading">Social Media</div>
        <div *ngIf="socialMediaModel.importStatus === 'Unverified'" class="unverified-badge-blue">unverified</div>
      </div>
      <!-- FORM -->
      <form class="profile__content__form__container" #thisForm="ngForm">
        <!-- WEBSITE -->
        <div class="profile__form--website">
          <label class="form__label">Website</label>
          <input class="form__control" id="currentItem_edge_url" type="url" name="currentItem_edge_url"
            [ngModel]="socialMediaModel.url | neo4junescape" placeholder="Ex. https://www.your-website.com"
            [pattern]="websiteRegExp"
            #website="ngModel"
            (ngModelChange)="socialMediaModel.url = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="website" [ngClass]="{'ng-invalid': !website.valid }"/>
        </div>
        <!-- LINKEDIN -->
        <div class="profile__form--linkedin">
          <label class="form__label">LinkedIn</label>
          <input class="form__control" id="currentItem_edge_linkedIn" name="currentItem_edge_linkedIn"
            [ngModel]="socialMediaModel.linkedIn | neo4junescape"
            placeholder="Ex. https://www.linkedin.com/in/your-profile"
            [pattern]="websiteRegExp"
            #linkedin="ngModel"
            (ngModelChange)="socialMediaModel.linkedIn = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="linkedin" [ngClass]="{'ng-invalid': !linkedin.valid }"/>
        </div>
        <!-- GOOGLE PLUS -->
        <div class="profile__form--google-plus">
          <label class="form__label">Google Plus</label>
          <input class="form__control" id="currentItem_edge_googlePlus" name="currentItem_edge_googlePlus"
            [ngModel]="socialMediaModel.googlePlus | neo4junescape"
            placeholder="Ex. https://plus.google.com/your-profile"
            [pattern]="websiteRegExp"
            #googlePlus="ngModel"
            (ngModelChange)="socialMediaModel.googlePlus = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="google" [ngClass]="{'ng-invalid': !googlePlus.valid }"/>
        </div>
        <!-- FACEBOOK -->
        <div class="profile__form--facebook">
          <label class="form__label">Facebook</label>
          <input class="form__control" id="currentItem_edge_facebook" name="currentItem_edge_facebook"
            [ngModel]="socialMediaModel.facebook | neo4junescape"
            placeholder="Ex. https://www.facebook.com/your-profile"
            [pattern]="websiteRegExp"
            #facebook="ngModel"
            (ngModelChange)="socialMediaModel.facebook = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="facebook" [ngClass]="{'ng-invalid': !facebook.valid }"/>
        </div>
        <!-- TWITTER -->
        <div class="profile__form--twitter">
          <label class="form__label">Twitter</label>
          <input class="form__control" id="currentItem_edge_twitter" name="currentItem_edge_twitter"
            [pattern]="websiteRegExp"
            #twitter="ngModel"
            [ngModel]="socialMediaModel.twitter | neo4junescape" placeholder="Ex. https://twitter.com/your-handle"
            (ngModelChange)="socialMediaModel.twitter = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="twitter" [ngClass]="{'ng-invalid': !twitter.valid }"/>
        </div>
        <!-- INSTAGRAM -->
        <div class="profile__form--instagram">
          <label class="form__label">Instagram</label>
          <input class="form__control" id="currentItem_edge_instagram" name="currentItem_edge_instagram"
            [ngModel]="socialMediaModel.instagram | neo4junescape"
            placeholder="Ex. https://www.instagram.com/your-handle"
            [pattern]="websiteRegExp"
            #instagram="ngModel"
            (ngModelChange)="socialMediaModel.instagram = this.escape.it($event)" [disabled]="deleteMode"
            aria-label="instagram" [ngClass]="{'ng-invalid': !instagram.valid }"/>
        </div>
        <!-- ACTIONS -->
        <div class="profile__form--actions">
          <button type="button" class="button button--dark-grey" (click)="cancelClick()" aria-label="cancel button"
            role="button">
            CANCEL
          </button>
          <button type="button" class="button button--red" *ngIf="displayDeleteButton" (click)="deleteClick()"
            [disabled]="!enableDeleteButton" aria-label="delete button" role="button">
            DELETE
          </button>
          <button class="button button--dark-grey" *ngIf="displaySaveButton" type="button"
            (click)="saveClick(); thisForm.form.markAsUntouched(); thisForm.form.markAsPristine()" [disabled]="((thisForm.form.pristine || thisForm.form.invalid) && socialMediaModel.dataSource === 'User') ||
                (thisForm.form.invalid && socialMediaModel.dataSource !== 'User')" aria-label="save button"
            role="button">
            SAVE
          </button>
        </div>
        <div class="row tac profile__form--warning" *ngIf="displayDeleteButton">
          <strong>Warning!</strong> You are going to delete item!
        </div>
        <ng-container *ngIf="!displayDeleteButton">
          <div class="row tac profile__form--error-invalid-website-url" *ngIf="!website.valid">
            <strong>Not a valid website url</strong>
          </div>
          <div class="row tac profile__form--error-invalid-linkedin-url" *ngIf="!linkedin.valid">
            <strong>Not a valid LinkedIn url</strong>
          </div>
          <div class="row tac profile__form--error-invalid-googlePlus-url" *ngIf="!googlePlus.valid">
            <strong>Not a valid Google Plus url</strong>
          </div>
          <div class="row tac profile__form--error-invalid-facebook-url" *ngIf="!facebook.valid">
            <strong>Not a valid Facebook url</strong>
          </div>
          <div class="row tac profile__form--error-invalid-twitter-url" *ngIf="!twitter.valid">
            <strong>Not a valid Twitter url</strong>
          </div>
          <div class="row tac profile__form--error-invalid-instagram-url" *ngIf="!instagram.valid">
            <strong>Not a valid Instagram url</strong>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>