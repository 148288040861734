<main>
    <div class="container">
        <div class="gallery" *ngIf="data && data.length > 0">
            <div class="gallery-item" tabindex="0" *ngFor="let item of data">
                <a style="text-decoration: none; color: inherit;text-transform: lowercase;" href="{{item.permalink}}"
                    target="_blank" [title]="item?.caption" >
                    <img [src]="item.media_url"
                        *ngIf="item.media_type === 'CAROUSEL_ALBUM' || item.media_type === 'IMAGE'"
                        class="gallery-image" alt="">
                    <video loop *ngIf="item.media_type === 'VIDEO'" class="gallery-video">
                        <source [src]="item.media_url" type="video/mp4">
                    </video>
                    <div class="gallery-item-type" *ngIf="item.media_type === 'CAROUSEL_ALBUM'">
                        <span class="visually-hidden">Gallery</span><i class="fa fa-clone" aria-hidden="true"></i>
                    </div>
                    <div class="gallery-item-type" *ngIf="item.media_type === 'VIDEO'">
                        <span class="visually-hidden">Gallery</span><i class="fa fa-video-camera"
                            aria-hidden="true"></i>
                    </div>
                    <div class="gallery-item-info">
                        <ul>
                            <li class="gallery-item-likes"><span
                                    class="visually-hidden">Caption:</span>{{ (item.caption.length>100)? (item.caption | slice:0:100)+'...':(item.caption) }}
                            </li>
                        </ul>
                    </div>
                </a>
            </div>
            <!-- <div class="gallery-item" tabindex="0">
                <img src="https://images.unsplash.com/photo-1423012373122-fff0a5d28cc9?w=500&h=500&fit=crop" class="gallery-image" alt="">
                <div class="gallery-item-type">
                    <span class="visually-hidden">Video</span><i class="fa fa-video" aria-hidden="true"></i>
                </div>
                <div class="gallery-item-info">
                    <ul>
                        <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fa fa-heart" aria-hidden="true"></i> 30</li>
                        <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fa fa-comment" aria-hidden="true"></i> 2</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
    <!-- End of container -->
</main>