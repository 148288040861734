import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { DiscussionBoardService } from "./discussion-board.service"
import { DataService } from "../../../../services/data.service"
import { NgxSpinnerService } from "ngx-spinner"
import { environment } from "../../../../../environments/environment"
import { WebSocketService } from "src/app/services/websocket.service"
import { first, last, take, takeLast } from "rxjs/operators"

@Component({
  selector: "app-discussion-board",
  templateUrl: "./discussion-board.component.html",
  styleUrls: ["./discussion-board.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DiscussionBoardComponent implements OnInit, OnDestroy {
  isEditing: boolean = false
  @ViewChild("message") message: ElementRef
  discussionBoardID: any
  comments: any = []
  commenter: any = []
  currentUser: any
  profilePicBucket: string = environment.publicBucket
  constructor(
    public activatedRoute: ActivatedRoute,
    private service: DiscussionBoardService,
    public data: DataService,
    public spinner: NgxSpinnerService,
    private websocket: WebSocketService,
  ) { }

  ngOnInit() {
    this.discussionBoardID = this.service.discussionBoardID.value
    this.service.userAuth(this.discussionBoardID)
    // this.service.discussionBoardID.subscribe(value => {
    //   console.log('DISCUSSIONBOARD ID CHANGED',  value)
    //   if(value) {
    //     this.discussionBoardID = value
    //     this.service.userAuth(value)
    //   }
    // })
    this.comments = this.service.chatList
    this.service.isUpdatingChatList.subscribe((data) => {
      if (data) {
        this.comments = data.Comments
        this.commenter = data.Commenter
      }
    })

    this.websocket.onWebsocketMessage.subscribe(msg => {
      //console.log('Message', msg)
      if (msg.subject === "DiscussionBoardConfig" && msg.associatedId.Key != '') {
        this.service.userAuth(this.discussionBoardID)
      }
    })
  }

  ngOnDestroy() {
    //this.service.webSocket.complete()
  }

  // post a new comment
  post_comment = (msg) => {
    const checkWhitespace = msg.match(/^\s+$/) === null
    if (!msg || !checkWhitespace) {
      return false
    }
    this.service.isPosting = true
    const comment = {
      action: "postMessage",
      parentId: "root",
      discussionBoardId: this.discussionBoardID,
      message: msg,
    }
    this.service.sendMessage(comment, msg)
    this.message.nativeElement.value = ""
  }

  // edit existing comment
  saveEditing = (msg, item) => {
    const checkWhitespace = msg.match(/^\s+$/) === null
    if (!msg || !checkWhitespace) {
      return false
    }
    const comment = {
      action: "editMessage",
      id: item.id,
      discussionBoardId: this.discussionBoardID,
      commenterUuid: item.commenterUuid,
      message: msg,
    }
    this.service.editComment(comment)
    item.isEditing = false
  }

  //like a comment
  likeComment = (item) => {
    const comment = {
      action: "likeMessage",
      id: item.id,
      discussionBoardId: this.discussionBoardID,
    }
    this.service.likeComment(comment)
  }

  // delete a comment
  deleteComment = (item) => {
    item.isEditing = false
    if (confirm("Are you sure to delete ")) {
      const comment = {
        action: "deleteMessage",
        id: item.id,
        discussionBoardId: this.discussionBoardID,
      }
      this.service.deleteComment(comment)
    }
  }

  // post a reply on an existing post
  post_reply = (msg, item) => {
    const checkWhitespace = msg.match(/^\s+$/) === null
    if (!msg || !checkWhitespace) {
      return false
    }
    this.service.isReplying = true
    const comment = {
      action: "postMessage",
      parentId: item.id,
      discussionBoardId: this.discussionBoardID,
      message: msg,
    }
    this.service.sendMessage(comment, item)
    item.expanded = false
  }

  // Action to perform certian ui changes on click
  //opens up reply box
  reply = (item) => {
    item.expanded = !item.expanded
  }

  // comment turn to textarea
  editComment = (item) => {
    item.isEditing = true
  }

  // converts textarea back to comment
  cancelEditing = (item) => {
    item.isEditing = false
  }

  sortComments(value) {
    if (value === "old") {
      const oldComments = this.service.chatList["Comments"].reverse()
      this.service.chatList["Comments"] = oldComments
      this.service.isUpdatingChatList.next(this.service.chatList)
    } else {
      const newComments = this.service.chatList["Comments"].reverse()
      this.service.chatList["Comments"] = newComments
      this.service.isUpdatingChatList.next(this.service.chatList)
    }
  }


  // find and convert text url to link url
  urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    const hashTags = text.replace(/#(\w+)/g, '<a href="javascrip:void(#$1)" style="text-transform: inherit; color:#00376b">#$1</a>');
    return hashTags.replace(urlRegex, (url) => {
      return '<a target="_blank" href="' + url + '" style="text-transform: lowercase; color:#00376b">' + url + '</a>';
    })
  }
}
