import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { SocialMediaEdges } from 'src/app/shared/utilities/app-db-edges';
import { SocialMedia } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class SocialMediaComponent implements OnInit {

  viewmodel = this.data.viewmodel;
  socialMediaList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  facebookValid: boolean;
  errorMessage: string;
  googleValid: boolean;
  linkedInValid: boolean;
  noneDisabled: boolean;
  twitterValid: boolean;
  instagramValid: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    private escape: Escape,
    public data: DataService,
    private api: HttpService
  ) {}

  root: string;
  status: ServiceStatus;
  newItem: { edge: SocialMediaEdges; toNodeRef: NodeRef; toNode: SocialMedia };
  currentItem: {
    edge: SocialMediaEdges;
    toNodeRef: NodeRef;
    toNode: SocialMedia;
  };
  socialMediaModel: any = {
    name: '',
    officialUrl: '',
    identifier: '',
    url: '',
    twitter: '',
    instagram: '',
  };
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  unverified: any;
  websiteRegExp = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.refreshNewItem();
    this.getSocialMediaList();
  }

  flip(){  
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  checkLinkedin(event: any) {
    this.socialMediaModel.linkedIn = this.escape.it(event);
  }

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.socialMediaModel.url = 'NONE';
    this.socialMediaModel.linkedIn = '';
    this.socialMediaModel.googlePlus = '';
    this.socialMediaModel.facebook = '';
    this.socialMediaModel.twitter = '';
    this.socialMediaModel.instagram = '';
    this.saveClick();
  }

  getSocialMediaList() {
    this.socialMediaList = [];
    const apiUrl = `${this.root}/userProfileUpdateSocialMedia?operation=list`;
    const getAwards = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getAwards.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        console.log('SOCIAL MEDIA: ', response);
        this.viewmodel.connect_to_SocialMedia = [];
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            url: response.record[item].row[0].url,
            officialUrl: response.record[item].row[0].officialUrl,
            identifier: response.record[item].row[0].identifier,
            linkedIn:
              response.record[item].row[0].linkedIn !== 'undefined'
                ? response.record[item].row[0].linkedIn
                : '',
            googlePlus:
              response.record[item].row[0].googlePlus !== 'undefined'
                ? response.record[item].row[0].googlePlus
                : '',
            facebook:
              response.record[item].row[0].facebook !== 'undefined'
                ? response.record[item].row[0].facebook
                : '',
            twitter:
              response.record[item].row[0].twitter !== 'undefined'
                ? response.record[item].row[0].twitter
                : '',
            instagram:
              response.record[item].row[0].instagram !== 'undefined'
                ? response.record[item].row[0].instagram
                : '',
            status: 'verified'
          };
          this.viewmodel.connect_to_SocialMedia.push(temp);
          this.socialMediaList.push(temp);
        }
        this.viewmodel.unverified_Connect_to_SocialMedia = [];
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            url: response.unverified[item].row[0].url,
            officialUrl: response.unverified[item].row[0].officialUrl,
            identifier: response.unverified[item].row[0].identifier,
            linkedIn:
              response.unverified[item].row[0].linkedIn !== 'undefined'
                ? response.unverified[item].row[0].linkedIn
                : '',
            googlePlus:
              response.unverified[item].row[0].googlePlus !== 'undefined'
                ? response.unverified[item].row[0].googlePlus
                : '',
            facebook:
              response.unverified[item].row[0].facebook !== 'undefined'
                ? response.unverified[item].row[0].facebook
                : '',
            twitter:
              response.unverified[item].row[0].twitter !== 'undefined'
                ? response.unverified[item].row[0].twitter
                : '',
            instagram:
              response.unverified[item].row[0].instagram !== 'undefined'
                ? response.unverified[item].row[0].instagram
                : '',
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            status: 'unverified'
          };
          this.viewmodel.unverified_Connect_to_SocialMedia.push(temp);
          this.socialMediaList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        url: '',
        identifier: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        officialUrl: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log(
      'VIEW MODEL SOCIAL MEDIA: ',
      this.viewmodel.connect_to_SocialMedia
    );
    if (
      this.viewmodel.connect_to_SocialMedia.length === 1 &&
      this.viewmodel.connect_to_SocialMedia[0].url === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.editMode = false;
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.enableDeleteButton = true;
    this.socialMediaModel = {
      name: '',
      officialUrl: '',
      identifier: '',
      url: '',
      twitter: '',
      instagram: '',
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    console.log('ITEM CLICKED: ', item);
    if (item.importStatus) {
      this.socialMediaModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    if (item.dataSource === 'User') {
      item.canEdit ? (this.deleteMode = false) : (this.deleteMode = true);
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.socialMediaModel = { ...item };
    this.socialMediaModel.edgeUuid = item.uuid;
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  saveClick() {
    this.editMode = false;
    this.deleteMode = false;
    if (this.socialMediaModel.url === 'NONE') {
      this.socialMediaModel.linkedIn = '';
      this.socialMediaModel.googlePlus = '';
      this.socialMediaModel.facebook = '';
      this.socialMediaModel.twitter = '';
      this.socialMediaModel.instagram = '';
    }
    if (
      this.socialMediaModel.facebook !== '' &&
      this.socialMediaModel.facebook !== null &&
      this.socialMediaModel.facebook !== undefined
    ) {
      if (
        this.socialMediaModel.facebook.includes('facebook')
      ) {
        this.facebookValid = true;
      } else {
        this.facebookValid = false;
        this.errorMessage = 'Facebook url must be properly formatted';
      }
    } else {
      this.facebookValid = true;
    }
    if (
      this.socialMediaModel.googlePlus !== '' &&
      this.socialMediaModel.googlePlus !== null &&
      this.socialMediaModel.googlePlus !== undefined
    ) {
      if (
        this.socialMediaModel.googlePlus.includes('google')
      ) {
        this.googleValid = true;
      } else {
        this.googleValid = false;
        this.errorMessage = 'Google Plus url must be properly formatted';
      }
    } else {
      this.googleValid = true;
    }
    if (
      this.socialMediaModel.linkedIn !== '' &&
      this.socialMediaModel.linkedIn !== null &&
      this.socialMediaModel.linkedIn !== undefined
    ) {
      if (
        this.socialMediaModel.linkedIn.includes('linkedin')
      ) {
        this.linkedInValid = true;
      } else {
        this.linkedInValid = false;
        this.errorMessage = 'LinkedIn url must be properly formatted';
      }
    } else {
      this.linkedInValid = true;
    }
    if (
      this.socialMediaModel.twitter !== '' &&
      this.socialMediaModel.twitter !== null &&
      this.socialMediaModel.twitter !== undefined
    ) {
      if (this.socialMediaModel.twitter.includes('twitter')) {
        this.twitterValid = true;
      } else {
        this.twitterValid = false;
        this.errorMessage = 'Twitter url must be properly formatted';
      }
    } else {
      this.twitterValid = true;
    }
    if (
      this.socialMediaModel.instagram !== '' &&
      this.socialMediaModel.instagram !== null &&
      this.socialMediaModel.instagram !== undefined
    ) {
      if (
        this.socialMediaModel.instagram.includes('instagram')
      ) {
        this.instagramValid = true;
      } else {
        this.instagramValid = false;
        this.errorMessage = 'Instagram url must be properly formatted';
      }
    } else {
      this.instagramValid = true;
    }
    if (
      this.facebookValid &&
      this.googleValid &&
      this.linkedInValid &&
      this.twitterValid &&
      this.instagramValid
    ) {
      if (this.currentUIAction === 1 && !this.unverified) {
        const apiUrl = `${this.root}/userProfileUpdateSocialMedia?operation=update`;
        // tslint:disable-next-line: max-line-length
        const certificateUpdate = this.http.post<any>(
          apiUrl,
          {
            data: this.socialMediaModel,
            edgeUuid: this.socialMediaModel.edgeUuid,
          },
          { headers: this.headers }
        );
        certificateUpdate.subscribe(
          (response) => {
            this.api.refreshProfileCompletion();
            this.errorMessage = '';
            if (response !== undefined && response.status === 1) {
              this._toaster.successToastr(
                'Social Media accounts updated successfully',
                'Success'
              );
              this.flipDiv = false;
              this.getSocialMediaList();
            }
            if (response !== undefined && response.status === 0) {
              this._toaster.errorToastr(
                'Social Media account failed to update',
                'Error'
              );
              this.flipDiv = false;
            }
          },
          (error) => {
            console.log({ error });
            this._toaster.errorToastr(error.error.message, 'Error');
            this.flipDiv = false;
          }
        );
      } else {
        const apiUrl = `${this.root}/userProfileUpdateSocialMedia?operation=create`;
        // tslint:disable-next-line: max-line-length
        const certificateUpdate = this.http.post<any>(
          apiUrl,
          { data: this.socialMediaModel },
          { headers: this.headers }
        );
        certificateUpdate.subscribe(
          (response) => {
            this.api.refreshProfileCompletion();
            this.errorMessage = '';
            if (response !== undefined && response.status === 1) {
              this._toaster.successToastr(
                'Social Media accounts added successfully',
                'Success'
              );
              this.flipDiv = false;
              this.getSocialMediaList();
            }
            if (response !== undefined && response.status === 0) {
              this._toaster.errorToastr(
                'Social Media account failed to add',
                'Error'
              );
              this.flipDiv = false;
            }
          },
          (error) => {
            console.log({ error });
            this._toaster.errorToastr(error.error.message, 'Error');
            this.flipDiv = false;
          }
        );
      }
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.connect_to_SocialMedia[0]);
    // }
    if (this.socialMediaModel.url != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.socialMediaModel.importStatus = item.importStatus;
    }
    this.editMode = false;
    this.currentItem = item;
    this.socialMediaModel = { ...item };
    this.socialMediaModel.edgeUuid = item.uuid;
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateSocialMedia?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.socialMediaModel, edgeUuid: this.socialMediaModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(
            'Social Media accounts removed successfully',
            'Success'
          );
          this.flipDiv = false;
          this.getSocialMediaList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(
            'Social Media account failed to remove',
            'Error'
          );
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
  }

  cancelClick() {
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
    this.editMode = false;
  }
}
