<div class="footer-container" role="toolbar">
  <div class="footer-wrapper">
    <div class="footer-text">
      <a routerLink="/privacy-policy" tabindex="112" aria-label="link to privacy page" role="link">Privacy</a>
      <span class="footer-pipe">|</span>
      <a routerLink="/contact-us" tabindex="113" aria-label="link to contact page" role="link">Contact</a>
      <span class="footer-pipe">|</span>
      <a routerLink="/about" tabindex="114" aria-label="link to about page" role="link">About</a>
      <span class="footer-pipe">|</span>
      <a routerLink="/requirements" tabindex="115" aria-label="link to requirements page" role="link">
        <span class="full-text">System Requirements</span>
        <span class="short-text">Requirements</span>
      </a>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <div class="accessibiliy-toggle" fxLayout="row" fxShow.lt-sm="false">
        <div fxLayout="row">
          <div class="footer-text">
            <!-- Accessibility Mode -->
            <mat-icon class="material-icons-outlined">accessible</mat-icon>
          </div>
          <div style="margin-top: 2px">
            <label class="switch" style="margin-top: 3px">
              <input type="checkbox"  tabindex="116" (keyup.enter)="data.accessibilityMode = !data.accessibilityMode; toggleAccessibilityMode()"  [(ngModel)]="data.accessibilityMode" (change)="toggleAccessibilityMode()"
                aria-label="privacy status toggle" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="footer-text"><span class="footer-pipe acc-pipe">|</span></div>
      <div>
        <span class="footer-text">Powered by</span>
        <a href="https://profoundimpact.com/" tabindex="117" target="_blank" aria-label="link to profound impact landing page"
          role="link">
          <img src="assets/images/pi-logo-full.png" height="15px" alt="profound impact logo" role="img" />
        </a>
      </div>
    </div>
  </div>
</div>