<div fxLayout="row" id="xml-dialog">
  <div
    role="region"
    aria-label="xml help instructions to export"
    class="xml-image-container"
  >
    <p class="xml-info-heading">Exporting Canadian Common CV XML Format</p>
    <div class="xml-steps">
      <mat-icon
        style="font-size: 16px; color: rgb(232, 161, 54); margin-top: 3px"
        >star_rate</mat-icon
      >
      <span style="color: rgb(69, 69, 69); font-weight: 600; font-size: 16px">
        Benefits: Using the XML format to import your profile will give you the
        best chance of extracting everything you have input
      </span>
      <br /><br />
      <span class="card--p-alt"> Step 1: login to your account </span>
      <br />
      <span class="card--p-alt"> Step 2: fill out your common cv if you have not already </span>
      <br />
      <span class="card--p-alt">
        Step 3: click on Utilities menu option and select Export CV XML option
        from dropdown
      </span>
      <br />
      <span class="card--p-alt">
        Step 4: use this XML file to import your profile into the Profound
        Impact Platform
      </span>
      <br />
    </div>
    <img
      class="xml-image"
      src="../../../../assets/images/xml.png"
      alt="screenshot of how to export XML format from the canadian cv website"
      role="img"
    />
  </div>
</div>
