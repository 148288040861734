import { Component, Input, OnInit, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';

@Component({
  selector: 'app-join-discussion',
  templateUrl: './join-discussion.component.html',
  styleUrls: ['./join-discussion.component.scss']
})
export class JoinDiscussionComponent implements OnInit {

  @Input() iconColor: boolean = true;
  constructor() { }
  ngOnInit(): void {
    console.log(this.iconColor);
 
  }

}
