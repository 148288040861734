<div style="max-height: 6rem;" aria-label="event countdown timer" *ngIf="data.iwdEventOffline" class="countdown-timer row jc-sa ai-c">
    <div class="timer-section column ai-c">
        <div class="number">
            {{ days }}
        </div>
        <div class="title">
            Days
        </div>
    </div>
    <div class="separator">:</div>
    <div class="timer-section column ai-c">
        <div class="number">
            {{ hours }}
        </div>
        <div class="title">
            Hours
        </div>
    </div>
    <div class="separator">:</div>
    <div class="timer-section column ai-c">
        <div class="number">
            {{ minutes }}
        </div>
        <div class="title">
            Minutes
        </div>
    </div>
    <div class="separator">:</div>
    <div class="timer-section column ai-c">
        <div class="number">
            {{ seconds }}
        </div>
        <div class="title">
            Seconds
        </div>
    </div>
</div>

<div *ngIf="!data.iwdEventOffline" class="join-event row jc-c ai-c grow">
    <div class="join-event-text">
        JOIN EVENT
    </div>
</div>