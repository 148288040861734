import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrManager } from "ng6-toastr-notifications";
import { DataService } from "src/app/services/data.service";
import { HttpService } from "src/app/services/http.service";
import { SiteApiConstants } from "src/app/services/site-api-constants.service";
import { SiteConstants } from "src/app/services/site-constants.service";
import { UtilityService } from "src/app/services/utility.service";
import { PeriodEdge } from "src/app/shared/utilities/app-db-edges";
import { Project } from "src/app/shared/utilities/app-db-nodes";
import {
  ServiceStatus,
  NodeRef,
  UIAction,
} from "src/app/shared/utilities/app-framework";
import { Escape } from "src/app/shared/utilities/neo4j-escape-unescape";
import { environment } from "src/environments/environment";
import { UserProfileService } from "../../user-profile.service";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class ProjectsComponent implements OnInit {
  projectList = [];
  @ViewChild("card") card: ElementRef;
  flipCard = false;
  viewmodel = this.data.viewmodel;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  noneDisabled: boolean;
  noneExists: boolean;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService,
    public util: UtilityService
  ) {}

  status: ServiceStatus;
  newItem: { edge: PeriodEdge; toNodeRef: NodeRef; toNode: Project };
  currentItem: { edge: PeriodEdge; toNodeRef: NodeRef; toNode: Project };
  projectModel: any = {
    name: "",
    shortName: "",
    webSite: "",
    startDate: {
      year: "",
      month: "",
      day: "",
    },
    endDate: {
      year: "",
      month: "",
      day: "",
    },
    startDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  work_in_Organization: any = {};
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = "";
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  startDayValue: any[] = [];
  endDayValue: any[] = [];
  startYearValue: any[] = [];
  endYearValue: any[] = [];
  endMonthValue: any[] = [];
  currentlyWorking = true;
  isMobile: boolean;
  deleteMode = false;
  isStartYearRequired = false;
  currentYear = new Date().getFullYear();
  websiteRegExp =
    /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  ngOnInit() {
    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = "http://127.0.0.1:3000")
      : (this.root = environment.baseUrl);
    this.getProjectList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
  }

  flip() {
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  }

  toggleCurrentlyWorking() {
    this.currentlyWorking = !this.currentlyWorking;
    this.projectModel.currentlyWorking = this.currentlyWorking;
  }

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.projectModel.name = "NONE";
    this.projectModel.shortName = "";
    this.projectModel.webSite = "";
    this.projectModel.currentlyWorking = false;
    this.projectModel.startDate.year = "";
    this.projectModel.startDate.month = "";
    this.projectModel.startDate.day = "";
    this.projectModel.endDate.year = "";
    this.projectModel.endDate.month = "";
    this.projectModel.endDate.day = "";
    this.saveClick();
  }

  onDateChange(field: any, input: any, startYear) {
    if (startYear === "") {
      this.isStartYearRequired = true;
    } else {
      this.isStartYearRequired = false;
    }
    const thisYear = new Date().getFullYear();
    if (field === "startDate") {
      if (input === "year") {
        if (this.projectModel.startDate.year !== "") {
          this.projectModel.endDate.year = "";
          this.projectModel.endDate.month = "";
          this.projectModel.endDate.day = "";
          this.projectModel.startDate.month = "";
          this.projectModel.startDate.day = "";
          this.endYearValue = [];
          for (let i = this.projectModel.startDate.year; i <= thisYear; i++) {
            this.endYearValue.push({ value: i });
          }
        } else {
          console.log("START DATE YEAR", this.projectModel.startDate.year);
          this.yearValue = [];
          this.endYearValue = [];
          this.monthValue = [];
          this.endMonthValue = [];
          this.endDayValue = [];
          this.startDayValue = [];
          this.dayValue = [];
          for (let i = this.currentYear; i >= 1930; i--) {
            this.yearValue.push({ value: i });
            this.endYearValue.push({ value: i });
          }
          for (let i = 1; i <= 12; i++) {
            this.monthValue.push({ value: i });
            this.endMonthValue.push({ value: i });
          }
          for (let i = 1; i <= 31; i++) {
            this.dayValue.push({ value: i });
            this.startDayValue.push({ value: i });
            this.endDayValue.push({ value: i });
          }
        }
      }
      if (input === "month") {
        this.startDayValue = [];
        for (
          let i = 1;
          i <= this.data.days[this.projectModel.startDate.month - 1];
          i++
        ) {
          this.startDayValue.push({ value: i });
        }
        if (
          this.projectModel.startDate.year === this.projectModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.projectModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
        this.projectModel.startDate.day = "";
      }
      if (input === "day") {
        // tslint:disable-next-line: max-line-length
        if (
          this.projectModel.startDate.month ===
            this.projectModel.endDate.month &&
          this.projectModel.startDate.year === this.projectModel.endDate.year
        ) {
          this.projectModel.endDate.day = "";
          this.endDayValue = [];
          for (
            let i = +this.projectModel.startDate.day;
            i < this.data.days[this.projectModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (field === "endDate") {
      if (input === "year") {
        this.projectModel.endDate.month = "";
        this.projectModel.endDate.day = "";
        if (
          this.projectModel.startDate.year === this.projectModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (
            let i = this.projectModel.startDate.month
              ? this.projectModel.startDate.month
              : 1;
            i <= 12;
            i++
          ) {
            this.endMonthValue.push({ value: i });
          }
        } else {
          this.endMonthValue = [];
          for (let i = 1; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
      }
      if (input === "month") {
        this.endDayValue = [];
        // tslint:disable-next-line: max-line-length
        if (
          this.projectModel.startDate.month ===
            this.projectModel.endDate.month &&
          this.projectModel.startDate.year === this.projectModel.endDate.year
        ) {
          this.projectModel.endDate.day = "";
          this.endDayValue = [];
          for (
            let i = +this.projectModel.startDate.day;
            i < this.data.days[this.projectModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        } else {
          this.endDayValue = [];
          for (
            let i = 1;
            i <= this.data.days[this.projectModel.endDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i });
          }
        }
      }
    }
  }

  getProjectList() {
    this.projectList = [];
    const apiUrl = `${this.root}/userProfileUpdateProject?operation=list`;
    const getProjectList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getProjectList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        
        this.viewmodel.work_in_Project = [];
        const tempCurrentlyWorking = [];
        const tempEndDate = [];
        const tempExperience = [];
        for (let i = 0; i < response.record.length; i++) {
          if (response.record[i].row[0].currentlyWorking) {
            tempCurrentlyWorking.push(response.record[i]);
          } else {
            tempEndDate.push(response.record[i]);
          }
        }
        tempCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        tempEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of tempCurrentlyWorking) {
          tempExperience.push(item);
        }
        for (const item of tempEndDate) {
          tempExperience.push(item);
        }
        response.record = tempExperience;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              +response.record[item].row[0].startDate.year,
              response.record[item].row[0].startDate.month - 1,
              response.record[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              +response.record[item].row[0].endDate.year,
              response.record[item].row[0].endDate.month - 1,
              response.record[item].row[0].endDate.day
            ),
            currentlyWorking: response.record[item].row[0].currentlyWorking
              ? response.record[item].row[0].currentlyWorking
              : false,
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            shortName: response.record[item].row[0].shortName,
            webSite: response.record[item].row[0].webSite,
            dataSource: response.record[item].row[0].dataSource,
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.record[item].row[0].startDate.year,
              month: response.record[item].row[0].startDate.month,
              day: response.record[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.record[item].row[0].endDate.year,
              month: response.record[item].row[0].endDate.month,
              day: response.record[item].row[0].endDate.day,
            },
            status: "verified",
          };
          if (temp.currentlyWorking) {
            this.viewmodel.work_in_Project.unshift(temp);
            this.projectList.unshift(temp);
          } else {
            this.viewmodel.work_in_Project.push(temp);
            this.projectList.push(temp);
          }
        }
        this.util.logger(
          this.viewmodel.work_in_Organization,
          "CONFIRMED/USER CREATED EXPERIENCES"
        );
        

        //UNVERIFIED IMPOTED RECORDS
        this.viewmodel.unverified_work_in_Project = [];
        let UNVERIFIEDCurrentlyWorking = [];
        const UNVERIFIEDEndDate = [];
        let UNVERIFIEDProject = [];
        
        for (let i = 0; i < response.unverified.length; i++) {
          // if (response.unverified[i].row[0].currentlyWorking) {
            UNVERIFIEDCurrentlyWorking.push(response.unverified[i]);
          // } else {
            // UNVERIFIEDEndDate.push(response.unverified[i]);
          // }
        }
        UNVERIFIEDCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        UNVERIFIEDEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        UNVERIFIEDProject = [...UNVERIFIEDCurrentlyWorking];
        response.unverified = UNVERIFIEDProject;
        // tslint:disable-next-line: forin
        
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              +response.unverified[item].row[0].startDate.year,
              response.unverified[item].row[0].startDate.month - 1,
              response.unverified[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              +response.unverified[item].row[0].endDate.year,
              response.unverified[item].row[0].endDate.month - 1,
              response.unverified[item].row[0].endDate.day
            ),
            currentlyWorking: response.unverified[item].row[0].currentlyWorking
              ? response.unverified[item].row[0].currentlyWorking
              : false,
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            shortName: response.unverified[item].row[0].shortName,
            webSite: response.unverified[item].row[0].webSite,
            dataSource: response.unverified[item].row[0].dataSource,
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.unverified[item].row[0].startDate.year,
              month: response.unverified[item].row[0].startDate.month,
              day: response.unverified[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.unverified[item].row[0].endDate.year,
              month: response.unverified[item].row[0].endDate.month,
              day: response.unverified[item].row[0].endDate.day,
            },
            status: "unverified",
          };
          if (temp.currentlyWorking) {
            this.viewmodel.unverified_work_in_Project.unshift(temp);
            this.projectList.unshift(temp);
          } else {
            this.viewmodel.unverified_work_in_Project.push(temp);
            this.projectList.push(temp);
          }
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, "Error");
      }
      this.util.logger(
        this.viewmodel.work_in_Project,
        "UNVERIFIED IMPORTED EXPERIENCES"
      );
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: "",
        startDateTime: "",
        isCurrent: true,
        endDateTime: "",
        currentlyWorking: true,
        createdDateTime: null,
        modifiedDateTime: null,
        summary: null,
      },
      toNodeRef: { labels: null, uuid: "", name: "" },
      toNode: {
        uuid: "",
        name: "",
        shortName: "",
        webSite: "",
        startDate: "",
        endDate: "",
        createdDateTime: null,
        modifiedDateTime: null,
        overview: null,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    console.log("VIEW MODEL PROJECT: ", this.viewmodel.work_in_Project);
    if (
      this.viewmodel.work_in_Project.length === 1 &&
      this.viewmodel.work_in_Project[0].name === "NONE"
    ) {
      console.log("A NONE EXISTS!!!");
      this.noneExists = true;
    }
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.currentlyWorking = true;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.projectModel = {
      name: "",
      shortName: "",
      webSite: "",
      currentlyWorking: true,
      startDate: {
        year: "",
        month: "",
        day: "",
      },
      endDate: {
        year: "",
        month: "",
        day: "",
      },
      startDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
      endDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any) {
    this.flip();
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.projectModel = { ...item };
    this.projectModel.edgeUuid = item.uuid;
    this.projectModel.currentlyWorking = item.currentlyWorking;
    this.projectModel.dataSource = item.dataSource;
    this.currentlyWorking = item.currentlyWorking;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = "";
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = "";
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.projectModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : "",
      month: item.startDateFlag.month ? item.startDateFlag.month : "",
      year: item.startDateFlag.year ? item.startDateFlag.year : "",
    };
    this.projectModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : "",
      month: item.endDateFlag.month ? item.endDateFlag.month : "",
      year: item.endDateFlag.year ? item.endDateFlag.year : "",
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.projectModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (this.projectModel.startDate.year === this.projectModel.endDate.year) {
        this.endMonthValue = [];
        for (let i = this.projectModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.projectModel.startDate.month === this.projectModel.endDate.month &&
        this.projectModel.startDate.year === this.projectModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.projectModel.startDate.day;
          i < this.data.days[this.projectModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
  }

  saveClick() {
    if (this.projectModel.currentlyWorking) {
      this.projectModel.endDate = {
        day: "",
        month: "",
        year: "",
      };
    }

    if (this.projectModel.name === "NONE") {
      this.projectModel.shortName = "";
      this.projectModel.webSite = "";
      this.projectModel.currentlyWorking = false;
      this.projectModel.startDate.year = "";
      this.projectModel.startDate.month = "";
      this.projectModel.startDate.day = "";
      this.projectModel.endDate.year = "";
      this.projectModel.endDate.month = "";
      this.projectModel.endDate.day = "";
    }

    if (this.currentUIAction === 1) {
      const apiUrl = `${this.root}/userProfileUpdateProject?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.projectModel, edgeUuid: this.projectModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, "Success");
            this.flipDiv = false;
            this.getProjectList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, "Error");
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, "Error");
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateProject?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.projectModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, "Success");
            this.flipDiv = false;
            this.getProjectList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, "Error");
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, "Error");
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.work_in_Project[0]);
    // }
    if (this.projectModel.name != "NONE") {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    console.log("MADE IT TO GO TO DELETE CLICK - item: ", item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    this.currentItem = item;
    this.currentlyWorking = item.currentlyWorking;
    this.projectModel = { ...item };
    this.projectModel.edgeUuid = item.uuid;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = "";
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = "";
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.projectModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : "",
      month: item.startDateFlag.month ? item.startDateFlag.month : "",
      year: item.startDateFlag.year ? item.startDateFlag.year : "",
    };
    this.projectModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : "",
      month: item.endDateFlag.month ? item.endDateFlag.month : "",
      year: item.endDateFlag.year ? item.endDateFlag.year : "",
    };
    this.projectModel.currentlyWorking = item.currentlyWorking
      ? item.currentlyWorking
      : false;
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    if (!item.currentlyWorking) {
      this.endYearValue = [];
      for (let i = this.projectModel.startDate.year; i <= thisYear; i++) {
        this.endYearValue.push({ value: i });
      }
      if (this.projectModel.startDate.year === this.projectModel.endDate.year) {
        this.endMonthValue = [];
        for (let i = this.projectModel.startDate.month; i <= 12; i++) {
          this.endMonthValue.push({ value: i });
        }
      }
      // tslint:disable-next-line: max-line-length
      if (
        this.projectModel.startDate.month === this.projectModel.endDate.month &&
        this.projectModel.startDate.year === this.projectModel.endDate.year
      ) {
        this.endDayValue = [];
        for (
          let i = +this.projectModel.startDate.day;
          i < this.data.days[this.projectModel.startDate.month - 1];
          i++
        ) {
          this.endDayValue.push({ value: i + 1 });
        }
      }
    }
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    console.log("MADE IT TO DELETE CLICK");
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateProject?operation=delete`;
    // tslint:disable-next-line: max-line-length
    console.log("🚀 ~ file: projects.component.ts ~ line 888 ~ ProjectsComponent ~ deleteClick ~ this.projectModel.edgeUuid", this.projectModel.edgeUuid)
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.projectModel, edgeUuid: this.projectModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, "Success");
          this.flipDiv = false;
          this.getProjectList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, "Error");
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, "Error");
        this.flipDiv = false;
      }
    );
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {}, 2000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 3000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 4000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
  }

  clearData() {
    this.projectModel.name = "";
    this.projectModel.shortName = "";
    this.projectModel.webSite = "";
    this.projectModel.startDate.year = "";
    this.projectModel.startDate.month = "";
    this.projectModel.startDate.day = "";
    this.projectModel.endDate.year = "";
    this.projectModel.endDate.month = "";
    this.projectModel.endDate.day = "";
    this.projectModel.currentlyWorking = false;
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }
}
