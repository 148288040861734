import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  isLoading: boolean;
  forceLogout: boolean;

  jwt: string;
  cognitoUsername: any;
  uuidPerson: any;
  currentUser: {
    firstName: string;
    lastName: string;
    displayName?: string;
    profileStatus: string;
    uuid: string;
    cognitoUserName: string;
    country: string;
    email: string;
    logoUrl: string;
    secondaryEmail?: string;
    postalCode?: string;
    phone?: string;
  };

  connectionType: string;
  systemEmailAddress: string;
  profileCompletion: any;
  userProfileStatus: string;
  userProfileStatusFlag: boolean;
  completionWidth: any;
  ariaValue: any;

  publicGroupsOpen = false;
  privateGroupsOpen = false;
  graphsOpen = false;
  currentIPAddress: any;
  LT_SM: boolean;
  pollTaken = true;
  showPoll = true;
  iwdEventOffline:boolean;

  constructor (private titleService: Title) {}

  private _themeSubject = new Subject<any>();
  private _theme = this._themeSubject.asObservable();
  private _authenticatedSubject = new Subject<any>();
  private _authenticated =  this._authenticatedSubject.asObservable();

  currentTheme: any;

  get theme() {
    return this._theme;
  }

  set theme(value: any) {
    this._themeSubject.next(value);
  }

  get authenticatedSubject() {
    return this._authenticated;
  }

  set authenticatedSubject(value: any) {
    this._authenticatedSubject.next(value);
  }

  setPageTitle(title: string) {
    this.titleService.setTitle('Profound Impact - ' + title);
  }

  screenWidth: number;

  private _widthSubject = new Subject<any>();
  private _width = this._widthSubject.asObservable();

  get width() {
    return this._width;
  }

  set width(value: any) {
    this._widthSubject.next(value);
  }

  local = false;

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  accessibilityMode = false;
  days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  academicGraphData: any;
  publicationGraphData: any;
  alumniGraphData: any;
  facultyGraphData: any;
  dashboardBanners: any;
  newsFeed: any;
  authenticated = false
  wwinBanners: any;
  classOf2020Banners: any;
  johnBettyBanners: any;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  device: any;
  profileTabs: any;
  sideBarOpen: boolean;
  discussionBoardOpen: boolean = false;
  sideBarOpenDelay = false;
  currentRoute: string;
  viewProfile = true;
  refreshMessage = '';
  uuid: any;
  ampAuthObj: any;
  viewmodel: any;
  userBasicModel: any;
  userProfileData: any;
  youtubeURL: string;
  LT_LG: boolean;
  LT_MD: boolean;
  currentProfileTab = 0;
  currentCountryCode: any;
  currentCity: any
  pages = [
    'Home',
    'Profound Impact Day',
    'Profound Insight',
    'Contact us',
    'Privacy Policy',
    'About us',
    'Join Us',
    'System Requirements'
  ];

  debug: boolean;
  logger(log: any, title: string) {
    if (this.debug) {
      console.log(`----------  ${title}  ----------`);
      console.log(log);
    }
  }
}
