<div class="social">
    <a class="social-tab" target="_blank" href="https://twitter.com/womensday">
        <img class="social-tab-image" aria-label="twitter" title="twitter"  alt="twitter" src="../../../../../assets/images/social/twitter.png">
    </a>
    <a class="social-tab" target="_blank"   href="https://www.facebook.com/Internationalwomensday/">
        <img class="social-tab-image" aria-label="facebook" title="facebook" alt="facebook"  src="../../../../../assets/images/social/facebook.png">
    </a>
    <a class="social-tab" target="_blank"  href="https://www.instagram.com/internationalwomensday_global/">
        <img class="social-tab-image" aria-label="instagram" title="instagram"  alt="instagram" src="../../../../../assets/images/social/instagram.png">
    </a>
    <a class="social-tab" target="_blank"  href="https://www.linkedin.com/showcase/internationalwomensday">
        <img class="social-tab-image" aria-label="linkedin" title="linkedin" alt="linkedin" src="../../../../../assets/images/social/linkedin.png">
    </a>
    <a class="social-tab" target="_blank"  href="https://www.youtube.com/channel/UCO0slFdmiod2KmJztaQQV4w">
        <img class="social-tab-image" aria-label="youtube" title="youtube"  alt="youtube"  src="../../../../../assets/images/social/youtube.png">
    </a>
</div>
