import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EducationEdge } from 'src/app/shared/utilities/app-db-edges';
import { University } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { UserProfile } from 'src/app/shared/utilities/user-profile.viewmodel';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [SiteConstants, UserProfileService, SiteApiConstants],
})
export class EducationComponent implements OnInit {
  @Output() updateTabs: EventEmitter<any> = new EventEmitter();

  educationList = [];
  flipCard = false;
  @ViewChild('card') card: ElementRef;

  institutions: any;
  unverified: any;

  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService,
    private util: UtilityService
  ) {}

  status: ServiceStatus;
  // @Input() viewmodel: UserProfile;
  viewmodel = this.data.viewmodel;

  // 4. Person - STUDY_IN: EducationEdge -> University
  newItem: { edge: EducationEdge; toNodeRef: NodeRef; toNode: University };
  currentItem: { edge: EducationEdge; toNodeRef: NodeRef; toNode: University };
  educationModel: any = {
    name: '',
    shortName: '',
    webSite: '',
    degree: 'Select',
    major: '',
    summary: '',
    graduationDate: {
      year: '',
      month: '',
      day: '',
    },
    graduationDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  degrees: any;
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  deleteMode = false;
  isMobile: boolean;
  isDegreeRequired = false;
  isGradYearRequired = false;
  currentYear = new Date().getFullYear();
  websiteRegExp = /^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  ngOnInit() {
    this.api.getDropdowns('EducationDegree').subscribe(
      (data) => {
        const response: any = data;
        this.degrees = response.data;
      },
      (error) => {
        this.util.logger(error, 'Degree Dropdown Error');
      }
    );

    this.getInstitutionSuggestions();

    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.refreshNewItem();
    this.getEducationList();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
    }
  }

  flip(){
     
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getInstitutionSuggestions() {
    this.api.getSuggestions('education').subscribe((data) => {
      const response: any = data;
      this.institutions = response.data.name;
    });
  }

  onDateChange(field: any, input: any, gradYear) {
    if(gradYear === '') {
      this.isGradYearRequired = true;
    } else {
      this.isGradYearRequired = false;
    }

    if (input === 'month') {
      this.dayValue = [];
      for (
        let i = 1;
        i <= this.data.days[this.educationModel.graduationDate.month - 1];
        i++
      ) {
        this.dayValue.push({ value: i });
      }
      this.educationModel.graduationDate.day = '';
    } else if (input === 'year') {
      if (this.educationModel.graduationDate.month === '') {
        this.educationModel.graduationDate.month = '';
        this.educationModel.graduationDate.day = '';
      }
    }
    const flag = this.educationModel[field][input] !== '' ? 1 : 0;
    this.educationModel[field + 'Flag'][input] = flag;
  }

  getEducationList() {
    this.educationList = [];
    const apiUrl = `${this.root}/userProfileUpdateEducation?operation=list`;
    const getEducationList = this.http.post<any>(
      apiUrl,
      {},
      { headers: this.headers }
    );
    getEducationList.subscribe((response) => {
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORDS
        this.viewmodel.study_in_University = [];
        const tempEducation = response.record.sort((a: any, b: any) =>
          (a.row[0].graduationDate.year !== b.row[0].graduationDate.year
            ? a.row[0].graduationDate.year
            : +a.row[0].graduationDate.month) <
          (a.row[0].graduationDate.year !== b.row[0].graduationDate.year
            ? b.row[0].graduationDate.year
            : +b.row[0].graduationDate.month)
            ? 1
            : -1
        );
        response.record = tempEducation;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            graduationDate: new Date(
              response.record[item].row[0].graduationDate.year,
              response.record[item].row[0].graduationDate.month - 1,
              response.record[item].row[0].graduationDate.day
            ),
            degree: response.record[item].row[0].degree
              ? response.record[item].row[0].degree
              : 'Select',
            major: response.record[item].row[0].major,
            uuid: response.record[item].row[0].uuid,
            name: response.record[item].row[0].name,
            shortName:
              response.record[item].row[0].shortName &&
              response.record[item].row[0].shortName !== 'null'
                ? response.record[item].row[0].shortName
                : '',
            summary: response.record[item].row[0].summary,
            webSite: response.record[item].row[0].webSite,
            dataSource: response.record[item].row[0].dataSource,
            graduationDateFlag: {
              year: response.record[item].row[0].graduationDate.year,
              month: response.record[item].row[0].graduationDate.month,
              day: response.record[item].row[0].graduationDate.day,
            },
            status : 'verified'
          };
          this.viewmodel.study_in_University.push(temp);
          this.educationList.push(temp);
        }
        // UVERIFIED IMPORTED RECORDS
        this.viewmodel.unverified_Study_in_University = [];
        const UNVERIFIEDEducation = response.unverified.sort((a: any, b: any) =>
          (a.row[0].graduationDate.year !== b.row[0].graduationDate.year
            ? a.row[0].graduationDate.year
            : +a.row[0].graduationDate.month) <
          (a.row[0].graduationDate.year !== b.row[0].graduationDate.year
            ? b.row[0].graduationDate.year
            : +b.row[0].graduationDate.month)
            ? 1
            : -1
        );
        response.unverified = UNVERIFIEDEducation;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            graduationDate: new Date(
              response.unverified[item].row[0].graduationDate.year,
              response.unverified[item].row[0].graduationDate.month - 1,
              response.unverified[item].row[0].graduationDate.day
            ),
            degree: response.unverified[item].row[0].degree
              ? response.unverified[item].row[0].degree
              : 'Select',
            major: response.unverified[item].row[0].major,
            uuid: response.unverified[item].row[0].uuid,
            name: response.unverified[item].row[0].name,
            shortName:
              response.unverified[item].row[0].shortName &&
              response.unverified[item].row[0].shortName !== 'null'
                ? response.unverified[item].row[0].shortName
                : '',
            summary: response.unverified[item].row[0].summary,
            webSite: response.unverified[item].row[0].webSite,
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            graduationDateFlag: {
              year: response.unverified[item].row[0].graduationDate.year,
              month: response.unverified[item].row[0].graduationDate.month,
              day: response.unverified[item].row[0].graduationDate.day,
            },
            status : 'unverified'
          };
          this.viewmodel.unverified_Study_in_University.push(temp);
          this.educationList.push(temp);
        }
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
    console.log('EDUCATION LIST: ', this.educationList);
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        degree: 'Select',
        major: '',
        summary: '',
        graduationDate: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        uuid: '',
        name: '',
        shortName: '',
        logoUrl: '',
        webSite: '',
        createdDateTime: null,
        modifiedDateTime: null,
      },
    };
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.institutions
          : this.institutions.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  gotoAddClick() {
    this.flip();
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.educationModel = {
      name: '',
      shortName: '',
      webSite: '',
      degree: 'Select',
      major: '',
      summary: '',
      graduationDate: {
        year: '',
        month: '',
        day: '',
      },
      graduationDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = item.status == 'unverified' ? true : false;
    if (item.importStatus) {
      this.educationModel.importStatus = item.importStatus;
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.educationModel = { ...item };
    this.educationModel.edgeUuid = item.uuid;
    this.educationModel.dataSource = item.dataSource;
    this.educationModel.webSite =
      item.webSite && item.webSite !== 'null' ? item.webSite : '';
    this.educationModel.shortName =
      item.shortName && item.shortName !== 'null' ? item.shortName : '';
    this.educationModel.degree = item.degree ? item.degree : 'Select';
    this.educationModel.graduationDate = {
      day: item.graduationDateFlag.day ? item.graduationDate.getDate() : '',
      month: item.graduationDateFlag.month ? item.graduationDateFlag.month : '',
      year: item.graduationDateFlag.year ? item.graduationDateFlag.year : '',
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
  }

  saveClick() {
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateEducation?operation=update`;
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.educationModel, edgeUuid: this.educationModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getEducationList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateEducation?operation=create`;
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.educationModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(response.message, 'Success');
            this.flipDiv = false;
            this.getEducationList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(response.message, 'Error');
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    this.updateTabs.emit();
    this.getInstitutionSuggestions();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    if (item.importStatus) {
      this.educationModel.importStatus = item.importStatus;
    }
    this.deleteMode = true;
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.educationModel = { ...item };
    this.educationModel.edgeUuid = item.uuid;
    this.educationModel.webSite =
      item.webSite && item.webSite !== 'null' ? item.webSite : '';
    this.educationModel.shortName =
      item.shortName && item.shortName !== 'null' ? item.shortName : '';
    this.educationModel.degree = item.degree ? item.degree : 'Select';
    this.educationModel.graduationDate = {
      day: item.graduationDateFlag.day ? item.graduationDate.getDate() : '',
      month: item.graduationDateFlag.month ? item.graduationDateFlag.month : '',
      year: item.graduationDateFlag.year ? item.graduationDateFlag.year : '',
    };
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
  }

  deleteClick() {
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateEducation?operation=delete`;
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.educationModel, edgeUuid: this.educationModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(response.message, 'Success');
          this.flipDiv = false;
          this.getEducationList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(response.message, 'Error');
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    this.updateTabs.emit();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.flip();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  cancelClick() {
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
  }

  onDegreeChange(event) {
    if(event === 'Select') {
      this.isDegreeRequired = true;
    } else {
      this.isDegreeRequired = false;
    }
  }

}

