import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';
import { SiteApiConstants } from 'src/app/services/site-api-constants.service';
import { SiteConstants } from 'src/app/services/site-constants.service';
import { UtilityService } from 'src/app/services/utility.service';
import { AncestryEdge } from 'src/app/shared/utilities/app-db-edges';
import { Person } from 'src/app/shared/utilities/app-db-nodes';
import { ServiceStatus, NodeRef, UIAction } from 'src/app/shared/utilities/app-framework';
import { Escape } from 'src/app/shared/utilities/neo4j-escape-unescape';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-academic-ancestry',
  templateUrl: './academic-ancestry.component.html',
  styleUrls: ['./academic-ancestry.component.scss']
})
export class AcademicAncestryComponent implements OnInit {

  @ViewChild('card') card: ElementRef;
  viewmodel = this.data.viewmodel;
  ancestryList = [];
  flipCard = false;

  @Output() updateTabs: EventEmitter<any> = new EventEmitter();
  noneDisabled: boolean;
  emailValid = true;
  unverified: boolean;
  noneExists: boolean;
  constructor(
    public _constant: SiteConstants,
    private http: HttpClient,
    public amplifyService: AmplifyService,
    public _toaster: ToastrManager,
    public _constantApi: SiteApiConstants,
    public data: DataService,
    private escape: Escape,
    private api: HttpService,
    private util: UtilityService
  ) {}

  status: ServiceStatus;
  newItem: { edge: AncestryEdge; toNodeRef: NodeRef; toNode: Person };
  currentItem: { edge: AncestryEdge; toNodeRef: NodeRef; toNode: Person };
  ancestryModel: any = {
    ancestryType: 'AcademicAncestry',
    role: 'Select',
    email: '',
    firstName: '',
    lastName: '',
    uuidOtherPerson: '',
    degree: 'Select',
    supervisionLevel: '',
    coSupervisor: '',
    title: '',
    status: 'Select',
    place: '',
    story: '',
    isCurrent: true,
    startDate: {
      year: '',
      month: '',
      day: '',
    },
    endDate: {
      year: '',
      month: '',
      day: '',
    },
    startDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
    endDateFlag: {
      year: 0,
      month: 0,
      day: 0,
    },
  };
  yearValue: any[] = [];
  monthValue: any[] = [];
  dayValue: any[] = [];
  months: any = [];
  private jwtoken = this._constantApi.getLoginToken();
  private headers = new HttpHeaders({
    Authorization: `Bearer ${this.data.jwt}`,
  });
  flipDiv = false;
  currentUIAction: UIAction = UIAction.Add;
  httpClientCallMessage = '';
  nodeEditable = true;
  edgeEditable = true;
  displaySaveButton = true;
  displayDeleteButton = true;
  enableDeleteButton = true;
  root: string;
  startDayValue: any[] = [];
  endDayValue: any[] = [];
  startYearValue: any[] = [];
  endYearValue: any[] = [];
  endMonthValue: any[] = [];
  isMobile: boolean;
  deleteMode = false;
  editMode = false;
  currentlyWorking = true;
  degrees: any;
  supervisors: any;
  statuses: any;
  places: any;
  supervisionLevels: any;
  titles: any;
  users = [];
  userStrings = [];
  previousUsers = [];
  personDisabled = false;
  isStartYearRequired = false;
  isRoleRequired = false;
  suggestedUserDetails = [];
  currentYear = new Date().getFullYear();

  ngOnInit() {
    this.getSuggestions();

    this.api.getDropdowns('EducationDegree').subscribe(
      (data) => {
        const response: any = data;
        this.degrees = response.data;
      },
      (error) => {
        this.util.logger(error, 'Degree Dropdown Error');
      }
    );

    this.api.getDropdowns('MentorshipSupervision').subscribe(
      (data) => {
        const response: any = data;
        this.supervisors = response.data;
      },
      (error) => {
        this.util.logger(error, 'Degree Dropdown Error');
      }
    );

    this.api.getDropdowns('MentorshipStatus').subscribe(
      (data) => {
        const response: any = data;
        this.statuses = response.data;
      },
      (error) => {
        this.util.logger(error, 'Degree Dropdown Error');
      }
    );

    this.isMobile = this.data.isMobile;
    this.months = this.data.months;
    this._constant.local
      ? (this.root = 'http://127.0.0.1:3000')
      : (this.root = environment.baseUrl);
    this.getAncestryList();
    this.refreshNewItem();
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
  }

  flip(){
    if (this.flipCard) {
      this.card.nativeElement.style.transform = "rotateY(0deg)";
    } else {
      this.card.nativeElement.style.transform = "rotateY(180deg)";
    }
    this.flipCard = !this.flipCard;
  };

  getSuggestions() {
    this.api.getSuggestions('Ancestry').subscribe((data) => {
      const response: any = data;
      this.places = response.data.place;
      this.supervisionLevels = response.data.supervisionLevel;
      this.titles = response.data.title;
      console.log('ACADEMIC SUGGESTIONS', response);
    });
  }

  titleSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.titles
          : this.titles.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  supervisionLevelSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.supervisionLevels
          : this.supervisionLevels.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  placeSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.places
          : this.places.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  hideFullEmail(email: string, type: string) {
    console.log('EMAIL TO HIDE: ', email);
    if (email !== null && email !== undefined && email !== '') {
      const firstLetter = email.substring(0, 1);
      const ending = email.substring(email.indexOf('@'), email.length);
      return type === 'suggestion'
        ? `(${firstLetter}********${ending})`
        : `${firstLetter}********${ending}`;
    } else {
      return '';
    }
  }

  getUserSuggestions() {
    this.previousUsers = this.users;
    const filters = {
      filterAttributes: {
        email: this.ancestryModel.email,
        firstName: this.ancestryModel.firstName,
        lastName: this.ancestryModel.lastName,
      },
    };
    this.api.getUsers(filters).subscribe((data) => {
      const response: any = data;
      this.users = response.data;
      const temp = [];
      const tempWithMoreDetails = [];
      this.users.forEach((element) => {
        temp.push({
          details: `${element.firstName}  ${element.lastName}  ${this.ancestryModel.email === '' ? element.email : element.email }`,
          uuid: element.uuid
        });
        tempWithMoreDetails.push({
          firstName: element.firstName,
          lastName: element.lastName,
          email: element.email,
          uuid: element.uuid
        });
      });
      this.userStrings = temp;
      this.suggestedUserDetails = tempWithMoreDetails;
    });
  }

  usersSuggestions = (text$: Observable<string>) =>
    text$
      .debounceTime(700)
      .distinctUntilChanged()
      .map((term) =>
        (term === ''
          ? this.userStrings
          : this.userStrings.filter(
              (v) => v.details.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )

  resultFormatter(value: any) {
    return value.details;
  }

  autofill(event) {
    const uuid = event.item.uuid;
    const selectedPerson = this.suggestedUserDetails.filter((person) => person.uuid === uuid)[0];
    setTimeout(() => {
      this.ancestryModel.firstName = selectedPerson.firstName;
      this.ancestryModel.lastName = selectedPerson.lastName;
      this.ancestryModel.email = selectedPerson.email;
      this.ancestryModel.uuidOtherPerson = selectedPerson.uuid;
    }, 0);
    setTimeout(() => {
      this.personDisabled = true;
      this.checkEmail();
    }, 200);
  }

  checkEmail() {
    if (this.ancestryModel.email) {
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      this.emailValid = pattern.test(this.ancestryModel.email);
    } else {
      this.emailValid = true;
    }
  }

  saveNone() {
    this.noneDisabled = true;
    setTimeout(() => {
      this.noneDisabled = false;
    }, 2500);
    this.ancestryModel.firstName = 'NONE';
    this.ancestryModel.lastName = 'NONE';
    this.ancestryModel.email = 'none@email.com';
    this.ancestryModel.uuidOtherPerson = 'Ancestry-NONE';
    this.saveClick();
  }

  clear() {
    this.ancestryModel.firstName = '';
    this.ancestryModel.lastName = '';
    this.ancestryModel.email = '';
    this.ancestryModel.uuidOtherPerson = '';
    this.ancestryModel.canEditPerson = true;
    this.personDisabled = false;
  }

  toggleCurrentlyWorking() {
    this.currentlyWorking = !this.currentlyWorking;
    this.ancestryModel.isCurrent = this.currentlyWorking;
    if (this.currentlyWorking) {
      this.ancestryModel.endDate = {
        year: '',
        month: '',
        day: '',
      };
      this.ancestryModel.endDateFlag = {
        year: 0,
        month: 0,
        day: 0,
      };
    }
  }

  onDateChange(field: any, input: any, startYear) {
    if(startYear === '') {
      this.isStartYearRequired = true;
    } else {
      this.isStartYearRequired = false
    }
    const thisYear = new Date().getFullYear();
    if (field === 'startDate') {
      if (input === 'year') {
        if (this.ancestryModel.startDate.year !== '') {
          this.ancestryModel.endDate.year = '';
          this.ancestryModel.endDate.month = '';
          this.ancestryModel.endDate.day = '';
          this.ancestryModel.startDate.month = '';
          this.ancestryModel.startDate.day = '';
          this.endYearValue = [];
          for (let i = this.ancestryModel.startDate.year; i <= thisYear; i++) {
            this.endYearValue.push({ value: i });
          }
        } else {
          // console.log('START DATE YEAR', this.ancestryModel.startDate.year);
          this.yearValue = [];
          this.endYearValue = [];
          this.monthValue = [];
          this.endMonthValue = [];
          this.endDayValue = [];
          this.startDayValue = [];
          this.dayValue = [];
          for (let i = this.currentYear; i >= 1930; i--) {
            this.yearValue.push({ value: i });
            this.endYearValue.push({ value: i });
          }
          for (let i = 1; i <= 12; i++) {
            this.monthValue.push({ value: i });
            this.endMonthValue.push({ value: i });
          }
          for (let i = 1; i <= 31; i++) {
            this.dayValue.push({ value: i });
            this.startDayValue.push({ value: i });
            this.endDayValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.startDayValue = [];
        for (
          let i = 1;
          i <= this.data.days[this.ancestryModel.startDate.month - 1];
          i++
        ) {
          this.startDayValue.push({ value: i });
        }
        if (
          this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.ancestryModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
        this.ancestryModel.startDate.day = '';
      }
      if (input === 'day') {
        // tslint:disable-next-line: max-line-length
        if (
          this.ancestryModel.startDate.month ===
            this.ancestryModel.endDate.month &&
          this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
        ) {
          this.ancestryModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.ancestryModel.startDate.day;
            i < this.data.days[this.ancestryModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        }
      }
    }
    if (field === 'endDate') {
      if (input === 'year') {
        this.ancestryModel.endDate.month = '';
        this.ancestryModel.endDate.day = '';
        if (
          this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
        ) {
          this.endMonthValue = [];
          for (let i = this.ancestryModel.startDate.month; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        } else {
          this.endMonthValue = [];
          for (let i = 1; i <= 12; i++) {
            this.endMonthValue.push({ value: i });
          }
        }
      }
      if (input === 'month') {
        this.endDayValue = [];
        // tslint:disable-next-line: max-line-length
        if (
          this.ancestryModel.startDate.month ===
            this.ancestryModel.endDate.month &&
          this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
        ) {
          this.ancestryModel.endDate.day = '';
          this.endDayValue = [];
          for (
            let i = +this.ancestryModel.startDate.day;
            i < this.data.days[this.ancestryModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i + 1 });
          }
        } else {
          this.endDayValue = [];
          for (
            let i = 1;
            i <= this.data.days[this.ancestryModel.startDate.month - 1];
            i++
          ) {
            this.endDayValue.push({ value: i });
          }
        }
      }
    }
  }

  getAncestryList() {
    this.ancestryList = [];
    const apiUrl = `${this.root}/userProfileUpdateAncestry?operation=list`;
    const getAncestryList = this.http.post<any>(
      apiUrl,
      { data: { ancestryType: 'AcademicAncestry' } },
      { headers: this.headers }
    );
    getAncestryList.subscribe((response) => {
      this.util.logger(response, 'ANCESTRY LIST GET');
      if (response !== undefined && response.status === 1) {
        // REGULAR RECORDS
        this.viewmodel.academic_ancestry = [];
        const tempCurrentlyWorking = [];
        const tempEndDate = [];
        const tempExperience = [];
        let removeDuplicates = [];

        for (let i = 0; i < response.record.length; i++) {
          if (response.record[i].row[0].isCurrent) {
            tempCurrentlyWorking.push(response.record[i]);
          } else {
            tempEndDate.push(response.record[i]);
          }
        }
        tempCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        tempEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of tempCurrentlyWorking) {
          tempExperience.push(item);
        }
        for (const item of tempEndDate) {
          tempExperience.push(item);
        }
        response.record = tempExperience;
        // tslint:disable-next-line: forin
        for (const item in response.record) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              response.record[item].row[0].startDate.year,
              response.record[item].row[0].startDate.month - 1,
              response.record[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              response.record[item].row[0].endDate.year,
              response.record[item].row[0].endDate.month - 1,
              response.record[item].row[0].endDate.day
            ),
            email: response.record[item].row[0].email,
            uuid: response.record[item].row[0].uuid,
            firstName: response.record[item].row[0].firstName,
            lastName: response.record[item].row[0].lastName,
            role: response.record[item].row[0].role,
            degree: response.record[item].row[0].degree,
            uuidOtherPerson: response.record[item].row[0].uuidOtherPerson,
            supervisionLevel: response.record[item].row[0].supervisionLevel,
            coSupervisor: response.record[item].row[0].coSupervisor,
            title: response.record[item].row[0].title,
            status: response.record[item].row[0].status,
            place: response.record[item].row[0].place,
            canEdit: response.record[item].row[0].canEdit,
            canEditPerson: response.record[item].row[0].canEditPerson,
            story: response.record[item].row[0].story,
            isCurrent: response.record[item].row[0].isCurrent,
            dataSource: response.record[item].row[0].dataSource,
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.record[item].row[0].startDate.year,
              month: response.record[item].row[0].startDate.month,
              day: response.record[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.record[item].row[0].endDate.year,
              month: response.record[item].row[0].endDate.month,
              day: response.record[item].row[0].endDate.day,
            },
            verification: 'verified'
          };
          this.viewmodel.academic_ancestry.push(temp);
          this.ancestryList.push(temp);
          removeDuplicates.push(temp)
        }
        // UNVERIFIED IMPORTED RECORDS
        this.viewmodel.unverified_Academic_ancestry = [];
        const UNVERIFIEDCurrentlyWorking = [];
        const UNVERIFIEDEndDate = [];
        const UNVERIFIEDExperience = [];
        for (let i = 0; i < response.unverified.length; i++) {
          if (response.unverified[i].row[0].isCurrent) {
            UNVERIFIEDCurrentlyWorking.push(response.unverified[i]);
          } else {
            UNVERIFIEDEndDate.push(response.unverified[i]);
          }
        }
        UNVERIFIEDCurrentlyWorking.sort((a: any, b: any) =>
          a.row[0].startDate.year < b.row[0].startDate.year ? 1 : -1
        );
        UNVERIFIEDEndDate.sort((a: any, b: any) =>
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? a.row[0].endDate.year
            : +a.row[0].endDate.month) <
          (a.row[0].endDate.year !== b.row[0].endDate.year
            ? b.row[0].endDate.year
            : +b.row[0].endDate.month)
            ? 1
            : -1
        );
        for (const item of UNVERIFIEDCurrentlyWorking) {
          UNVERIFIEDExperience.push(item);
        }
        for (const item of UNVERIFIEDEndDate) {
          UNVERIFIEDExperience.push(item);
        }
        response.unverified = UNVERIFIEDExperience;
        // tslint:disable-next-line: forin
        for (const item in response.unverified) {
          const temp: any = {
            // tslint:disable-next-line: max-line-length
            startDate: new Date(
              response.unverified[item].row[0].startDate.year,
              response.unverified[item].row[0].startDate.month - 1,
              response.unverified[item].row[0].startDate.day
            ),
            // tslint:disable-next-line: max-line-length
            endDate: new Date(
              response.unverified[item].row[0].endDate.year,
              response.unverified[item].row[0].endDate.month - 1,
              response.unverified[item].row[0].endDate.day
            ),
            email: response.unverified[item].row[0].email,
            uuid: response.unverified[item].row[0].uuid,
            firstName: response.unverified[item].row[0].firstName,
            lastName: response.unverified[item].row[0].lastName,
            role: response.unverified[item].row[0].role,
            degree: response.unverified[item].row[0].degree,
            uuidOtherPerson: response.unverified[item].row[0].uuidOtherPerson,
            supervisionLevel: response.unverified[item].row[0].supervisionLevel,
            coSupervisor: response.unverified[item].row[0].coSupervisor,
            title: response.unverified[item].row[0].title,
            status: response.unverified[item].row[0].status,
            place: response.unverified[item].row[0].place,
            canEdit: response.unverified[item].row[0].canEdit,
            canEditPerson: response.unverified[item].row[0].canEditPerson,
            story: response.unverified[item].row[0].story,
            isCurrent: response.unverified[item].row[0].isCurrent,
            dataSource: response.unverified[item].row[0].dataSource,
            importStatus: response.unverified[item].row[0].importStatus,
            // tslint:disable-next-line: max-line-length
            startDateFlag: {
              year: response.unverified[item].row[0].startDate.year,
              month: response.unverified[item].row[0].startDate.month,
              day: response.unverified[item].row[0].startDate.day,
            },
            // tslint:disable-next-line: max-line-length
            endDateFlag: {
              year: response.unverified[item].row[0].endDate.year,
              month: response.unverified[item].row[0].endDate.month,
              day: response.unverified[item].row[0].endDate.day,
            },
            verification: 'unverified'
          };
          this.viewmodel.unverified_Academic_ancestry.push(temp);
          // this.ancestryList.push(temp);
          removeDuplicates.push(temp);
        }
        const result = removeDuplicates.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.firstName === thing.firstName && t.lastName === thing.lastName && t.email === thing.email
          ))
        )
        this.ancestryList = this.ancestryList.concat(result);
        console.log("🚀 ~ file: academic-ancestry.component.ts ~ line 684 ~ AcademicAncestryComponent ~ getAncestryList.subscribe ~ result", result)
        
      }
      if (response !== undefined && response.status === 0) {
        this._toaster.errorToastr(response.message, 'Error');
      }
    });
  }

  refreshNewItem() {
    this.newItem = {
      edge: {
        uuid: '',
        role: 'Select',
        email: '',
        firstName: '',
        lastName: '',
        degree: 'Select',
        supervisionLevel: '',
        coSupervisor: '',
        title: '',
        status: 'Select',
        place: '',
        story: '',
        isCurrent: true,
        startDate: null,
        endDate: null,
        createdDateTime: '',
        modifiedDateTime: '',
      },
      toNodeRef: { labels: null, uuid: '', name: '' },
      toNode: {
        cognitoUuid: '',
        password: '',
        cognitoUserName: '',
        uuid: '',
        email: '',
        title: '',
        firstName: '',
        lastName: '',
        displayName: '',
        bio: '',
        bipoc: '',
        lgbt: '',
        logoUrl: '',
        speciality: '',
        currentStatus: '',
        createdDateTime: null,
        modifiedDateTime: null,
        publicationCount: 0,
        awardCount: 0,
      },
    };
  }

  gotoAddClick() {
    this.flip();
    // console.log('VIEW MODEL ANCESTRY: ', this.viewmodel.academic_ancestry);
    if (
      this.viewmodel.academic_ancestry.length === 1 &&
      this.viewmodel.academic_ancestry[0].firstName === 'NONE'
    ) {
      console.log('A NONE EXISTS!!!');
      this.noneExists = true;
    }
    this.editMode = false;
    this.deleteMode = false;
    this.personDisabled = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    this.currentlyWorking = true;
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    this.deleteMode = false;
    this.flipDiv = !this.flipDiv;
    this.currentItem = this.newItem;
    this.currentUIAction = UIAction.Add;
    this.nodeEditable = true;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.ancestryModel = {
      ancestryType: 'AcademicAncestry',
      role: 'Select',
      email: '',
      firstName: '',
      lastName: '',
      degree: 'Select',
      supervisionLevel: '',
      coSupervisor: '',
      canEditPerson: true,
      title: '',
      status: 'Select',
      place: '',
      story: '',
      isCurrent: true,
      startDate: {
        year: '',
        month: '',
        day: '',
      },
      endDate: {
        year: '',
        month: '',
        day: '',
      },
      startDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
      endDateFlag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  gotoEditClick(item: any, unverified: boolean) {
    this.flip();
    this.unverified = unverified;
    // console.log('ITEM CLICKED: ', item);
    if (item.importStatus) {
      this.ancestryModel.importStatus = item.importStatus;
    }
    this.editMode = true;
    this.deleteMode = false;
    this.personDisabled = false;
    if (item.dataSource === 'User') {
      item.canEdit ? (this.deleteMode = false) : (this.deleteMode = true);
    }
    this.flipDiv = !this.flipDiv;
    this.currentItem = item;
    this.ancestryModel = { ...item };
    this.ancestryModel.edgeUuid = item.uuid;
    this.ancestryModel.role = item.role ? item.role : 'Select';
    this.ancestryModel.degree = item.degree ? item.degree : 'Select';
    this.ancestryModel.supervisionLevel = item.supervisionLevel;
    this.ancestryModel.coSupervisor = item.coSupervisor;
    this.ancestryModel.uuidOtherPerson = item.uuidOtherPerson;
    this.ancestryModel.title = item.title;
    this.ancestryModel.status = item.status ? item.status : 'Select';
    this.ancestryModel.place = item.place;
    this.ancestryModel.story = item.story;
    this.ancestryModel.isCurrent = item.isCurrent;
    this.ancestryModel.ancestryType = 'AcademicAncestry';
    this.currentlyWorking = item.isCurrent;
    this.ancestryModel.dataSource = item.dataSource;
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = '';
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = '';
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.ancestryModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.ancestryModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.currentUIAction = UIAction.Edit;
    this.nodeEditable = false;
    this.edgeEditable = true;
    this.displaySaveButton = true;
    this.displayDeleteButton = false;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    this.endYearValue = [];
    for (let i = this.ancestryModel.startDate.year; i <= thisYear; i++) {
      this.endYearValue.push({ value: i });
    }
    if (this.ancestryModel.startDate.year === this.ancestryModel.endDate.year) {
      this.endMonthValue = [];
      for (let i = this.ancestryModel.startDate.month; i <= 12; i++) {
        this.endMonthValue.push({ value: i });
      }
    }
    // tslint:disable-next-line: max-line-length
    if (
      this.ancestryModel.startDate.month === this.ancestryModel.endDate.month &&
      this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
    ) {
      this.endDayValue = [];
      for (
        let i = +this.ancestryModel.startDate.day;
        i < this.data.days[this.ancestryModel.startDate.month - 1];
        i++
      ) {
        this.endDayValue.push({ value: i + 1 });
      }
    }
  }

  saveClick() {
    this.editMode = false;
    this.deleteMode = false;
    if (this.ancestryModel.degree === 'Select') {
      this.ancestryModel.degree = '';
    }
    // if (this.ancestryModel.email) {
    //   if (this.ancestryModel.email.includes('*')) {
    //     this.ancestryModel.email = this.injectedEmail;
    //   }
    // }
    if (this.ancestryModel.status === 'Select') {
      this.ancestryModel.status = '';
    }
    if (this.currentUIAction === 1 && !this.unverified) {
      const apiUrl = `${this.root}/userProfileUpdateAncestry?operation=update`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.ancestryModel, edgeUuid: this.ancestryModel.edgeUuid },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(
              'Academic Ancestry updated successfully',
              'Success'
            );
            this.flipDiv = false;
            this.getAncestryList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(
              'Academic Ancestry failed to update',
              'Error'
            );
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    } else {
      const apiUrl = `${this.root}/userProfileUpdateAncestry?operation=create`;
      // tslint:disable-next-line: max-line-length
      const certificateUpdate = this.http.post<any>(
        apiUrl,
        { data: this.ancestryModel },
        { headers: this.headers }
      );
      certificateUpdate.subscribe(
        (response) => {
          this.api.refreshProfileCompletion();
          if (response !== undefined && response.status === 1) {
            this._toaster.successToastr(
              'Academic Ancestry added successfully',
              'Success'
            );
            this.flipDiv = false;
            this.getAncestryList();
          }
          if (response !== undefined && response.status === 0) {
            this._toaster.errorToastr(
              'Academic Ancestry failed to updated',
              'Error'
            );
            this.flipDiv = false;
          }
        },
        (error) => {
          console.log({ error });
          this._toaster.errorToastr(error.error.message, 'Error');
          this.flipDiv = false;
        }
      );
    }
    // if (this.noneExists) {
    //   this.gotoDeleteClick(this.viewmodel.academic_ancestry[0]);
    // }
    if (this.ancestryModel.firstName != 'NONE') {
      setTimeout(() => {
        this.flip();
      }, 1000);
    }
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  gotoDeleteClick(item: any) {
    this.flip();
    // console.log('MADE IT TO GO TO DELETE CLICK - item: ', item);
    if (!this.noneExists) {
      this.deleteMode = true;
      this.flipDiv = !this.flipDiv;
    }
    if (item.importStatus) {
      this.ancestryModel.importStatus = item.importStatus;
    }
    this.editMode = false;
    this.currentItem = item;
    this.ancestryModel = { ...item };
    this.ancestryModel.edgeUuid = item.uuid;
    this.ancestryModel.role = item.role;
    this.ancestryModel.degree = item.degree;
    this.ancestryModel.supervisionLevel = item.supervisionLevel;
    this.ancestryModel.title = item.title;
    this.ancestryModel.status = item.status;
    this.ancestryModel.coSupervisor = item.coSupervisor;
    this.ancestryModel.place = item.place;
    this.ancestryModel.story = item.story;
    this.ancestryModel.isCurrent = item.isCurrent;
    this.currentlyWorking = item.isCurrent;
    this.ancestryModel.ancestryType = 'AcademicAncestry';
    let startMonth;
    if (item.startDateFlag.day === null) {
      if (item.startDateFlag.month === null) {
        startMonth = '';
      } else {
        startMonth = item.startDate.getMonth() + 2;
      }
    } else {
      startMonth = item.startDate.getMonth() + 1;
    }
    let endMonth;
    if (item.endDateFlag.day === null) {
      if (item.endDateFlag.month === null) {
        endMonth = '';
      } else {
        endMonth = item.endDate.getMonth() + 2;
      }
    } else {
      endMonth = item.endDate.getMonth() + 1;
    }
    this.ancestryModel.startDate = {
      day: item.startDateFlag.day ? item.startDate.getDate() : '',
      month: item.startDateFlag.month ? item.startDateFlag.month : '',
      year: item.startDateFlag.year ? item.startDateFlag.year : '',
    };
    this.ancestryModel.endDate = {
      day: item.endDateFlag.day ? item.endDate.getDate() : '',
      month: item.endDateFlag.month ? item.endDateFlag.month : '',
      year: item.endDateFlag.year ? item.endDateFlag.year : '',
    };
    this.currentUIAction = UIAction.Delete;
    this.nodeEditable = false;
    this.edgeEditable = false;
    this.displaySaveButton = false;
    this.displayDeleteButton = true;
    this.enableDeleteButton = true;
    this.yearValue = [];
    this.endYearValue = [];
    this.monthValue = [];
    this.endMonthValue = [];
    this.dayValue = [];
    this.startDayValue = [];
    this.endDayValue = [];
    for (let i = this.currentYear; i >= 1930; i--) {
      this.yearValue.push({ value: i });
      this.endYearValue.push({ value: i });
    }
    for (let i = 1; i <= 12; i++) {
      this.monthValue.push({ value: i });
      this.endMonthValue.push({ value: i });
    }
    for (let i = 1; i <= 31; i++) {
      this.dayValue.push({ value: i });
      this.startDayValue.push({ value: i });
      this.endDayValue.push({ value: i });
    }
    const thisYear = new Date().getFullYear();
    this.endYearValue = [];
    for (let i = this.ancestryModel.startDate.year; i <= thisYear; i++) {
      this.endYearValue.push({ value: i });
    }
    if (this.ancestryModel.startDate.year === this.ancestryModel.endDate.year) {
      this.endMonthValue = [];
      for (let i = this.ancestryModel.startDate.month; i <= 12; i++) {
        this.endMonthValue.push({ value: i });
      }
    }
    // tslint:disable-next-line: max-line-length
    if (
      this.ancestryModel.startDate.month === this.ancestryModel.endDate.month &&
      this.ancestryModel.startDate.year === this.ancestryModel.endDate.year
    ) {
      this.endDayValue = [];
      for (
        let i = +this.ancestryModel.startDate.day;
        i < this.data.days[this.ancestryModel.startDate.month - 1];
        i++
      ) {
        this.endDayValue.push({ value: i + 1 });
      }
    }
    // if (this.noneExists) {
    //   this.deleteClick();
    // }
  }

  deleteClick() {
    // console.log('MADE IT TO DELETE CLICK');
    this.noneExists = false;
    this.enableDeleteButton = false;
    const apiUrl = `${this.root}/userProfileUpdateAncestry?operation=delete`;
    // tslint:disable-next-line: max-line-length
    const certificateUpdate = this.http.post<any>(
      apiUrl,
      { data: this.ancestryModel, edgeUuid: this.ancestryModel.edgeUuid },
      { headers: this.headers }
    );
    certificateUpdate.subscribe(
      (response) => {
        this.api.refreshProfileCompletion();
        if (response !== undefined && response.status === 1) {
          this._toaster.successToastr(
            'Academic Ancestry removed successfully',
            'Success'
          );
          this.flipDiv = false;
          this.getAncestryList();
          this.deleteMode = false;
        }
        if (response !== undefined && response.status === 0) {
          this._toaster.errorToastr(
            'Academic Ancestry failed to remove',
            'Error'
          );
          this.flipDiv = false;
        }
      },
      (error) => {
        console.log({ error });
        this._toaster.errorToastr(error.error.message, 'Error');
        this.flipDiv = false;
      }
    );
    setTimeout(() => {
      this.flip();
    }, 1000);
    this.updateTabs.emit();
    this.clearData();
    this.api.refreshProfileCompletion();
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 1000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 2500);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 5000);
    setTimeout(() => {
      this.api.refreshProfileCompletion();
    }, 7500);
  }

  clearData() {
    this.editMode = false;
    this.deleteMode = false;
    this.ancestryModel.email = '';
    this.ancestryModel.firstName = '';
    this.ancestryModel.lastName = '';
    this.ancestryModel.startDate.year = '';
    this.ancestryModel.startDate.month = '';
    this.ancestryModel.startDate.day = '';
    this.ancestryModel.endDate.year = '';
    this.ancestryModel.endDate.month = '';
    this.ancestryModel.endDate.day = '';
    this.ancestryModel.role = '';
    this.ancestryModel.coSupervisor = '';
    this.ancestryModel.degree = '';
    this.ancestryModel.supervisionLevel = '';
    this.ancestryModel.title = '';
    this.ancestryModel.status = '';
    this.ancestryModel.place = '';
    this.ancestryModel.story = '';
    this.ancestryModel.isCurrent = '';
  }

  cancelClick() {
    this.clearData();
    this.flip();
    this.httpClientCallMessage = null;
    this.deleteMode = false;
    this.editMode = false;
  }

  onRoleChange(event) {
    if(event === 'Select') {
      this.isRoleRequired = true;
    } else {
      this.isRoleRequired = false;
    }
  }
}
