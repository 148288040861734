import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SiteConstants {
  local = false;
  rooturl: string;

  // General Declaration
  public submitButtonlabel = ' SUBMIT ';
  public backToLogin = '<< Back to Login';

  public SERV_BASE_URL = 'http://34.236.179.22:8085/';
  public SERV_BASE_API_URL = 'http://34.236.179.22:8085/api/front/';

  // Change Password
  public changePasswordHeading = 'Change Password';
  public changePasswordOldpasswordlabel = 'Current Password';
  public changePasswordNewpasswordlabel = 'New Password';
  public changePasswordRetypepasswordlabel = 'Confirm Password';

  public changePasswordStrengthHint =
    'Password should contain uppercase, lowercase, number and special chracter';
  public changePasswordRetypeHint =
    'Retype Password should match with New Password';
  public confirmPasswordNotMatch =
    'Retype password does not match, please try again';
  public requiredLabel = 'This is required';
  public nameRequiredLabel = 'Name should be in alphabetics';
  public emailRequiredLabel = 'Email not valid';
  public validation_error_label = 'Validation Error';
  public enterRequiredField = 'Please enter required field(s) properly';
  public subjectRequiredLabel = 'Subject not valid';

  // Common Alert Dialog Declarations
  cognito_verification_title = 'Verification Success';
  cognito_verification_message =
    `Enter the Verification Code sent to your email                             `;
  public DIALOG_TIMEOUT = 1500;
  public DIALOG_SHOW_PROGRESS = false;
  public DIALOG_PAUSE_ON_HOVER = false;
  public DIALOG_CLICK_TO_CLOSE = true;
  public DIALOG_MAX_LENGTH = 100;

  // Declare Message Type
  public MESSAGE_TYPE_ERROR = 'error';
  public MESSAGE_TYPE_SUCCESS = 'success';
  public MESSAGE_TYPE_WARNING = 'warning';
  public MESSAGE_TYPE_INFO = 'info';
  passwordStrength: any = {};
  public userSubject: any = new Subject<any>();

  COGNITO_ALREADY_VERIFIED =
    'User cannot be confirmed. Current status is CONFIRMED';
  COGNITO_FORCE_CHANGE_PASSWORD =
    'User cannot be confirmed. Current status is FORCE_CHANGE_PASSWORD';
  database_error_label = 'Database Error';
  cognito_user_exists_error_label = 'Cognito User Exists Error';
  cognito_database_error_label = 'Cognito Database Error';
  cognito_database_success_label = 'Cognito Database Success';
  cognito_change_password_success_label =
    'Your Password has changed, You are now Logged In';
  cognito_verified_info_label = 'Verification Success';
  cognito_verified_err_label = 'Verification Error';
  cognito_verified_succ_message =
    'User Verified and Confirmed. Please change your password';
  database_notification_label = 'Please wait';
  alert_success_label = 'Record Success';
  alert_verification_success_label = 'Verification Success';
  onlyUsernameInput = `Please enter your desired user name. You can enter only small or capital letters,
                numeric characters (0-9) and Special Characters (- _ .)`;
  passwordStrengthLabel =
    'Password should contain Minimum 8 character,One Number,One Special Character,One Capital and One Small letter';

  isEmpty(theValue) {}

  checkValidPassword(passwordType: any, modelInput: any) {
    this.passwordStrength['pcLengthValue'] = 0;
    this.passwordStrength['pcUpperValue'] = 0;
    this.passwordStrength['pcLowerValue'] = 0;
    this.passwordStrength['pcNumberValue'] = 0;
    this.passwordStrength['pcSpecialValue'] = 0;

    const lengthCharacters = /^([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]){8,}$/;
    const upperCaseCharacters = /[A-Z]+/g;
    const lowerCaseCharacters = /[a-z]+/g;
    const numberCharacters = /[0-9]+/g;
    const specialCharacters = /[!@#$%^&*()_.+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (lengthCharacters.test(modelInput)) {
      this.passwordStrength['pcLengthValue'] = 1;
    }
    if (upperCaseCharacters.test(modelInput)) {
      this.passwordStrength['pcUpperValue'] = 1;
    }
    if (lowerCaseCharacters.test(modelInput)) {
      this.passwordStrength['pcLowerValue'] = 1;
    }
    if (numberCharacters.test(modelInput)) {
      this.passwordStrength['pcNumberValue'] = 1;
    }
    if (specialCharacters.test(modelInput)) {
      this.passwordStrength['pcSpecialValue'] = 1;
    }
    const checkedType = true;
    const typeObject = passwordType;
    for (const [key, value] of Object.entries(typeObject)) {
      if (!value && key !== undefined) {
        return false;
      }
    }
    return checkedType;
  }

  checkInput(inputParamas: string, modelInput: any) {
    let resultValidInput = false;

    switch (inputParamas) {
      case 'email':
        resultValidInput = new RegExp(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        ).test(modelInput);
        break;
      case 'password':
        if (this.checkValidPassword(this.passwordStrength, modelInput)) {
          resultValidInput = true;
        }
        break;

      case 'onlyName':
        resultValidInput = new RegExp(/^[a-zA-Z ]+$/).test(modelInput);
        break;

      case 'onlyNumber':
        resultValidInput = new RegExp(/^[0-9]+$/).test(modelInput);
        break;

      case 'onlyUsername':
        // Allow capital,small letters with _ character
        resultValidInput = new RegExp(/^[a-zA-Z+\-_.0-9]+$/).test(modelInput);
        break;

      case 'blank':
        resultValidInput =
          typeof modelInput === 'undefined' || modelInput === '' ? false : true;
        break;
    }
    return resultValidInput ? true : false;
  }
}
